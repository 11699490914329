import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import Vue from "vue";
import Shift from "@/typesold/shift";
import Able from "@/typesold/able";
import Shift_memo from "@/typesold/shift_memo";

export type Employee = {
  id: number;
  name: string;
};
export type EmployeeHash = {
  [id: number]: any;
};
export type Data = {
  shifts: Shift[];
  ables: Able[];
  shift_memo: Shift_memo | null;
  employees: Employee[];
  employee_hash: EmployeeHash;
  is_locked: boolean;
  is_valid?: boolean;
  is_set: boolean;
  fetching?: Promise<void>;
};
export type BranchlyData = {
  [branch: number]: Data;
};
export type DailyData = {
  [date: string]: BranchlyData;
};

@Module({ dynamic: true, store, name: "shift_daily", namespaced: true })
class Daily extends VuexModule {
  data: DailyData = {};

  @Mutation
  public set({ date, branch_id, data }: { date: string; branch_id: number; data: Data }) {
    Vue.set(this.data[date], branch_id, {
      ...data,
      employee_hash: util.createHashObject(data.employees, "id"),
      is_valid: true,
      is_set: true,
      fetching: null,
    });
  }
  @Mutation
  public createShift({ date, branch_id, shift }: { date: string; branch_id: number; shift }) {
    shift = {
      ...shift,
      shift_shift: shift.shift_shift.filter(
        (shift_shift) => shift_shift.started_at || shift_shift.ended_at,
      ),
      shift_punch: shift.shift_punch.filter(
        (shift_punch) => shift_punch.started_at || shift_punch.ended_at,
      ),
      shift_break: shift.shift_break.filter(
        (shift_break) => shift_break.started_at || shift_break.ended_at,
      ),
    };

    this.data[date][branch_id].shifts.push(shift);
  }
  @Mutation
  public updateShift({
    date,
    branch_id,
    shift_id,
    shift,
  }: {
    date: string;
    branch_id: number;
    shift_id: number;
    shift;
  }) {
    shift = {
      ...shift,
      shift_shift: shift.shift_shift.filter(
        (shift_shift) => shift_shift.started_at || shift_shift.ended_at,
      ),
      shift_punch: shift.shift_punch.filter(
        (shift_punch) => shift_punch.started_at || shift_punch.ended_at,
      ),
      shift_break: shift.shift_break.filter(
        (shift_break) => shift_break.started_at || shift_break.ended_at,
      ),
    };

    this.data[date][branch_id].shifts.forEach((v, i, a) => {
      if (v.id == shift_id) {
        a[i] = shift;
      }
    });
  }
  @Mutation
  public deleteShift({
    date,
    branch_id,
    shift_id,
  }: {
    date: string;
    branch_id: number;
    shift_id: number;
  }) {
    if (this.data && this.data[date] && this.data[date][branch_id]) {
      this.data[date][branch_id].shifts = this.data[date][branch_id].shifts.filter(
        (shift) => shift.id != shift_id,
      );
    }
  }
  @Mutation
  public disable({
    date,
    branch_id,
    yearmonth,
  }: {
    date?: string;
    branch_id?: number;
    yearmonth?: string;
  }) {
    if (date === undefined) {
      let date_array = util.month_date_array(yearmonth);
      date_array.forEach((date) => {
        if (branch_id === undefined || branch_id == 0) {
          if (this.data[date]) {
            Object.keys(this.data[date]).forEach((branch_id) => {
              this.data[date][branch_id].is_valid = false;
            });
          }
        } else {
          if (this.data[date] && this.data[date][branch_id]) {
            this.data[date][branch_id].is_valid = false;
          }
        }
      });
    } else {
      if (branch_id === undefined || branch_id == 0) {
        if (this.data[date]) {
          Object.keys(this.data[date]).forEach((branch_id) => {
            this.data[date][branch_id].is_valid = false;
          });
        }
      } else {
        if (this.data[date] && this.data[date][branch_id]) {
          this.data[date][branch_id].is_valid = false;
        }
      }
    }
  }
  @Mutation
  public disable_all() {
    Object.keys(this.data).forEach((date) => {
      Object.keys(this.data[date]).forEach((branch_id) => {
        this.data[date][branch_id].is_valid = false;
      });
    });
  }
  @Mutation
  public setFetching({
    date,
    branch_id,
    value,
  }: {
    date: string;
    branch_id: number;
    value: Promise<void>;
  }) {
    this.data[date][branch_id].fetching = value;
  }

  // actions
  @Action({ rawError: true })
  public async fetch({ date, branch_id }: { date: string; branch_id: number }) {
    //ないなら作る
    if (!this.data[date]) {
      Vue.set(this.data, date, {});
    }
    if (!this.data[date][branch_id]) {
      Vue.set(this.data[date], branch_id, {
        shifts: [],
        ables: [],
        shift_memo: null,
        employees: [],
        employee_hash: {},
        is_locked: false,
        is_valid: false,
        is_set: false,
        fetching: null,
      });
    }

    if (this.data[date][branch_id].fetching) {
      await this.data[date][branch_id].fetching;
    } else {
      const promise = util.post("shift/read_daily", { date, branch_id }).then((res) => {
        this.set({ date, branch_id, data: res.data });
      });
      this.setFetching({ date, branch_id, value: promise });
      await promise;
    }
  }
}

export default getModule(Daily);
