import VuexManagerControlNew from "@/vuex/VuexManagerControlNew";
import { validationEmployeeMail, validationEmployeeBase, validationEmployeePayment, validationEmployeeCode, validationMail, validationPassword, validationCurrentPassword, validationAdministrator } from "@/validations/employee";

export default class Manager extends VuexManagerControlNew {
  create_descriptor = {
    demand: {
      url: 'demand_change/create_demand_change',
      validate: [validationEmployeeMail, validationEmployeeBase],
      dialog: {title: 'demand_change_dialog_title', msg: 'demand_change_dialog_msg', detail: 'demand_change_dialog_detail', cancel_button: 'demand_change_cancel', exec_button: 'demand_change_exec'},
      processing_dialog: {},
      finish_dialog: {msg: 'demand_change_finish_dialog_msg', detail: 'demand_change_finish_dialog_detail'},
      continue_edit: true,
      routing: {
        name: 'shift',
      },
    },
  };


  update_descriptor = {
    admit: {
      url: 'demand_change/admit_demand_change',
      dialog: {title: 'demand_change_admit_dialog_title', msg: 'demand_change_admit_dialog_msg', cancel_button: 'demand_change_admit_cancel', exec_button: 'demand_change_admit_exec'},
      processing_dialog: {},
      finish_dialog: {msg: 'demand_change_admit_finish_title', detail: 'demand_change_admit_finish_msg'},
      continue_edit: true,
      routing: {
        name: 'employee',
      },
    },
    admit_change_carfare: {
      url: 'demand_change/admit_demand_change',
      dialog: {msg: 'demand_change_admit_dialog_title', detail: 'demand_change_admit_carfare_dialog_msg', cancel_button: 'demand_change_admit_cancel', exec_button: 'demand_change_admit_exec'},
      processing_dialog: {},
      finish_dialog: {msg: 'demand_change_admit_finish_title', detail: 'demand_change_admit_carfare_finish_msg'},
      continue_edit: true,
      routing: {
        name: 'employee',
      },
    },
    deny: {
      url: 'demand_change/deny_demand_change',
      dialog: {title: 'demand_change_deny_dialog_title', msg: 'demand_change_deny_dialog_msg', cancel_button: 'demand_change_deny_cancel', exec_button: 'demand_change_deny_exec'},
      processing_dialog: {},
      finish_dialog: {msg: 'demand_change_deny_finish_msg'},
      continue_edit: true,
      routing: {
        name: 'employee',
      },
    },
  };

  delete_descriptor = {

  };
}