import { Mutation, MutationAction, Action, VuexModule, getModule, Module } from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import Vue from 'vue';
import global from "@/vuex/attendance_each/global";

export type Data = {
  demand_punches: any,
  is_valid?: boolean,
  is_set: boolean,
  fetching?: Promise<void>
}
export type EmployeelyData = {
  [employee: number]: Data
}
export type MonthlyData = {
  [yearmonth: string]: EmployeelyData
}

@Module({ dynamic: true, store, name: "demand_punch_monthly_data", namespaced: true })
class AttendanceEachMonthly extends VuexModule {
  data: MonthlyData = {};

  @Mutation
  public set({yearmonth, employee_id, data}: {yearmonth: string, employee_id: number, data}) {
    Vue.set(this.data[yearmonth], employee_id, {
      demand_punches: data.demand_punches,
      is_valid: true,
      is_set: true,
      fetching: null,
    });
  }

  @Mutation
  public createDemandPunch({yearmonth, date, employee_id, demand_punch}: {yearmonth: string, date: string, employee_id: number, demand_punch}) {

    demand_punch = {
      ...demand_punch,
      demand_punch_punch: demand_punch.demand_punch_punch.filter(demand_punch_punch => demand_punch_punch.started_at || demand_punch_punch.ended_at),
      demand_punch_break: demand_punch.demand_punch_break.filter(demand_punch_break => demand_punch_break.started_at || demand_punch_break.ended_at),
    }

    if (!this.data[yearmonth][employee_id].demand_punches[date]) {
      Vue.set(this.data[yearmonth][employee_id].demand_punches, date, []);
    }

    this.data[yearmonth][employee_id].demand_punches[date].push(demand_punch);
  }
  @Mutation
  public updateDemandPunch({yearmonth, date, employee_id, demand_punch_id, demand_punch}: {yearmonth: string, date: string, employee_id: number, demand_punch_id: number, demand_punch}) {

    demand_punch = {
      ...demand_punch,
      demand_punch_punch: demand_punch.demand_punch_punch.filter(demand_punch_punch => demand_punch_punch.started_at || demand_punch_punch.ended_at),
      demand_punch_break: demand_punch.demand_punch_break.filter(demand_punch_break => demand_punch_break.started_at || demand_punch_break.ended_at),
    }

    this.data[yearmonth][employee_id].demand_punches[date].forEach((v, i, a) => {
      if (v.id == demand_punch_id) {
        a[i] = demand_punch;
      }
    });
  }
  @Mutation
  public deleteDemandPunch({yearmonth, date, employee_id, demand_punch_id}: {yearmonth: string, date: string, employee_id: number, demand_punch_id: number}) {
    this.data[yearmonth][employee_id].demand_punches[date] = this.data[yearmonth][employee_id].demand_punches[date].filter(demand_punch => demand_punch.id != demand_punch_id);
  }

  @Mutation
  public disable({yearmonth, employee_id}: {yearmonth?: string, employee_id?: number}) {
    if (employee_id === undefined) {
      if (yearmonth === undefined) {
        Object.keys(this.data).forEach(yearmonth => {
          if (this.data[yearmonth]) {
            Object.keys(this.data[yearmonth]).forEach(employee_id => {
              this.data[yearmonth][employee_id].is_valid = false;
            });
          }
        });
      } else {
        if (this.data[yearmonth]) {
          Object.keys(this.data[yearmonth]).forEach(employee_id => {
            this.data[yearmonth][employee_id].is_valid = false;
          });
        }
      }
    } else {
      if (yearmonth === undefined) {
        Object.keys(this.data).forEach(yearmonth => {
          if (this.data[yearmonth] && this.data[yearmonth][employee_id]) {
            this.data[yearmonth][employee_id].is_valid = false;
          }
        });
      } else {
        if (this.data[yearmonth] && this.data[yearmonth][employee_id]) {
          this.data[yearmonth][employee_id].is_valid = false;
        }
      }
    }
  }
  @Mutation
  public disable_all() {
    Object.keys(this.data).forEach(yearmonth => {
      Object.keys(this.data[yearmonth]).forEach(employee_id => {
        this.data[yearmonth][employee_id].is_valid = false;
      });
    });
  }
  @Mutation
  public setFetching({yearmonth, employee_id, value}: {yearmonth: string, employee_id: number, value: Promise<void>}) {
    this.data[yearmonth][employee_id].fetching = value;
  }


  // actions
  @Action({ rawError: true })
  public async fetch({yearmonth, branch_id, employee_id, payment_info_id}: {yearmonth: string, branch_id: number, employee_id: number, payment_info_id: number}) {
    //ないなら作る
    if (!this.data[yearmonth]) {
      Vue.set(this.data, yearmonth, { is_initial: true });
    }
    if (!this.data[yearmonth][employee_id]) {
      Vue.set(this.data[yearmonth], employee_id, {
        demand_punches: {},
        is_valid: false,
        is_set: false,
        fetching: null,
      });
    }

    if (this.data[yearmonth][employee_id].fetching) {
      await this.data[yearmonth][employee_id].fetching;
    } else {
      const promise = util.post('demand_punch/read_monthly', {yearmonth, employee_id, branch_id, payment_info_id}).then((res) => {
        this.set({yearmonth, employee_id, data: res.data});
      });
      this.setFetching({yearmonth, employee_id, value: promise});
      await promise;
    }
  }


  @Mutation
  public createShift({yearmonth, date, employee_id, shift}: {yearmonth: string, date: string, employee_id: number, shift}) {

    shift = {
      ...shift,
      demand_punch_punch: shift.shift_punch.filter(shift_punch => shift_punch.started_at || shift_punch.ended_at),
      demand_punch_break: shift.shift_break.filter(shift_break => shift_break.started_at || shift_break.ended_at),
      type: 0,
    }

    if (!this.data[yearmonth][employee_id].demand_punches[date]) {
      Vue.set(this.data[yearmonth][employee_id].demand_punches, date, {});
    }
    if (!this.data[yearmonth][employee_id].demand_punches[date][shift.branch_id]) {
      Vue.set(this.data[yearmonth][employee_id].demand_punches[date], shift.branch_id, []);
    }

    this.data[yearmonth][employee_id].demand_punches[date][shift.branch_id].push(shift);
  }
  @Mutation
  public updateShift({yearmonth, date, employee_id, shift}: {yearmonth: string, date: string, employee_id: number, shift}) {

    shift = {
      ...shift,
      demand_punch_punch: shift.shift_punch.filter(shift_punch => shift_punch.started_at || shift_punch.ended_at),
      demand_punch_break: shift.shift_break.filter(shift_break => shift_break.started_at || shift_break.ended_at),
      type: 1,
    }

    if (!this.data[yearmonth][employee_id].demand_punches[date]) {
      Vue.set(this.data[yearmonth][employee_id].demand_punches, date, {});
    }
    if (!this.data[yearmonth][employee_id].demand_punches[date][shift.branch_id]) {
      Vue.set(this.data[yearmonth][employee_id].demand_punches[date], shift.branch_id, []);
    }

    this.data[yearmonth][employee_id].demand_punches[date][shift.branch_id].push(shift);
  }
  @Mutation
  public deleteShift({yearmonth, date, employee_id, branch_id}: {yearmonth: string, date: string, employee_id: number, branch_id: number}) {

    var shift = {
      date,
      branch_id,
      employee_id,
      type: 2,
    }

    if (!this.data[yearmonth][employee_id].demand_punches[date]) {
      Vue.set(this.data[yearmonth][employee_id].demand_punches, date, {});
    }
    if (!this.data[yearmonth][employee_id].demand_punches[date][shift.branch_id]) {
      Vue.set(this.data[yearmonth][employee_id].demand_punches[date], shift.branch_id, []);
    }

    this.data[yearmonth][employee_id].demand_punches[date][shift.branch_id].push(shift);
  }
  @Mutation
  public cancelShift({yearmonth, date, employee_id, branch_id, demand_punch_id}: {yearmonth: string, date: string, employee_id: number, branch_id: number, demand_punch_id: number}) {
    if (this.data[yearmonth][employee_id].demand_punches[date] && this.data[yearmonth][employee_id].demand_punches[date][branch_id])
      this.data[yearmonth][employee_id].demand_punches[date][branch_id] = this.data[yearmonth][employee_id].demand_punches[date][branch_id].filter(demand_punch => demand_punch.id != demand_punch_id);
  }
}

export default getModule(AttendanceEachMonthly);