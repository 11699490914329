import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import monthly from "@/vuex/attendance_each/monthly";
import * as util from "@/util";
import validate from "@/validations/validate";
import validationAttendanceShift from "@/validations/attendance_shift";
import dialog from "@/vuex/dialog";
import Vue from "vue";
import node from "@/vuex/node";
import filter from "@/library/filter";

@Module({ dynamic: true, store, name: "attendance_each_manager", namespaced: true })
class Manager extends VuexModule {
  add_shift_flag: string | boolean = false; //追加するdateを格納 falseなら編集なし
  edit_shift_flag: number = 0; //shift_idを格納 0なら編集なし
  is_open_chart_dialog: boolean = false;
  is_open_attendance_each_dialog: boolean = false;
  chart_date: string | null = null;
  is_open_detail_dialog: boolean = false;

  shift = {
    // add や edit の際に使用 ver3 の tmp  managerにおく必要がある attendance_eachのrowspan問題
    id: null,
    date: null,
    branch_id: null,
    employee_id: null,
    shift_shift: [],
    shift_punch: [],
    shift_break: [],
  };

  carfare: null | number = null; //通勤手当のカスタム用

  mode: number = 0; //{0: 従来, 1: カード形式(sp)}

  @Mutation
  public setIsOpenDetailDialog(val: boolean) {
    this.is_open_detail_dialog = val;
  }
  @Mutation
  public setIsOpenAttendanceErrorDialog({
    val,
    holiday,
    config,
  }: {
    val: number;
    holiday: number;
    config: any;
  }) {
    console.log(config);
    const NOPUNCH = 0b1;
    const MISSPUNCH = 0b10;
    const MISSBREAK = 0b100;
    const DONTPUNCH = 0b1000;
    const DONTSHIFT = 0b10000;
    const OVERDAY = 0b100000;
    const SAMEPUNCH = 0b1000000;
    const SAMEBREAK = 0b10000000;
    const NOSHIFT = 0b100000000;
    const WRONGBREAK = 0b1000000000;
    const CONTINUOUSTIME = 0b10000000000;
    const CONTINUOUSDAY = 0b100000000000;

    let is_nopunch = NOPUNCH & val;
    let is_misspunch = MISSPUNCH & val;
    let is_missbreak = MISSBREAK & val;
    let is_dontpunch = DONTPUNCH & val;
    let is_dontshift = DONTSHIFT & val;
    let is_overday = OVERDAY & val;
    let is_samepunch = SAMEPUNCH & val;
    let is_samebreak = SAMEBREAK & val;
    let is_noshift = NOSHIFT & val;
    let is_wrongbreak = WRONGBREAK & val;
    let is_continuoustime = CONTINUOUSTIME & val;
    let is_continuousday = CONTINUOUSDAY & val;
    validate([
      !is_nopunch || "勤怠情報がありません。",
      !is_misspunch || "退勤がされていない勤怠があります。",
      !is_missbreak || "終了時刻がない休憩があります。",
      !is_dontpunch || util.work_type_opt[holiday] + "であるにも関わらず勤怠があります。",
      !is_dontshift || util.work_type_opt[holiday] + "であるにも関わらずシフトがあります。",
      !is_overday || "打刻時間が24時間を超えています。",
      !is_samepunch || "出勤時刻と退勤時刻が同じ時刻です。",
      !is_samebreak || "休憩開始時刻と休憩終了時刻が同じ時刻です。",
      !is_noshift || "打刻があるにもかかわらずシフトがありません。",
      !is_wrongbreak || "休憩が打刻時間外にあります。",
      !is_continuoustime ||
        `週${filter.time_span(config.continuous_error_time, false)}以上の勤務があります。`,
      !is_continuousday || `${config.continuous_error_day}日以上連続勤務があります。`,
    ]);
  }

  @Mutation
  setShift({ shift }) {
    Vue.set(this, "shift", shift);
  }

  @Mutation
  setCarfare({ carfare }) {
    Vue.set(this, "carfare", carfare);
  }

  @Mutation
  public setChartDate(date) {
    this.chart_date = date;
  }

  @Mutation
  public setIsOpenChartDialog(val) {
    this.is_open_chart_dialog = val;
  }
  @Mutation
  public setIsOpenAttendanceEachDialog(val) {
    this.is_open_attendance_each_dialog = val;
  }

  @Mutation
  private setAddShiftFlag(date: string | boolean) {
    this.add_shift_flag = date;
  }
  @Mutation
  private setEditShiftFlag(shift_id: number) {
    this.edit_shift_flag = shift_id;
  }

  @Action({ rawError: true })
  public cancelAll() {
    this.setAddShiftFlag(false);
    this.setEditShiftFlag(0);
  }

  @Action({ rawError: true })
  public async is_open_input() {
    return this.add_shift_flag || this.edit_shift_flag != 0;
  }

  @Action({ rawError: true })
  public async addShift(date: string) {
    //追加するdateを渡す
    if (await this.is_open_input()) return;
    this.setAddShiftFlag(date);
  }
  @Action({ rawError: true })
  public async editShift(shift_id: number) {
    if (await this.is_open_input()) return;
    this.setEditShiftFlag(shift_id);
  }

  @Action({ rawError: true })
  public createShift({
    employee_id,
    date,
    yearmonth,
    is_shift_punch_sync,
  }: {
    employee_id: number;
    date: string;
    yearmonth: string;
    is_shift_punch_sync: boolean;
  }) {
    if (validate(validationAttendanceShift(this.shift, is_shift_punch_sync))) {
      dialog.openConfirmDialog({
        msg: "シフトを作成してよろしいですか？",
        cancel_button: "作成しない",
        exec_button: "作成する",
        func: this.createShiftProcess,
        args: { employee_id, date, yearmonth },
      });
    }
  }
  @Action({ rawError: true })
  public async createShiftProcess({
    employee_id,
    date,
    yearmonth,
  }: {
    employee_id: number;
    date: string;
    yearmonth: string;
  }) {
    const res = await util.post("attendance_each/create_shift", {
      employee_id,
      date,
      shift: this.shift,
      carfare: this.carfare,
      yearmonth,
    });

    if (res !== null) {
      monthly.createShift({ yearmonth, date, employee_id, shift: this.shift });
      this.cancelAll();
      node.disable();
    }
  }

  @Action({ rawError: true })
  public updateShift({
    employee_id,
    date,
    yearmonth,
    shift_id,
    is_shift_punch_sync,
  }: {
    employee_id: number;
    date: string;
    yearmonth: string;
    shift_id: number;
    is_shift_punch_sync: boolean;
  }) {
    if (validate(validationAttendanceShift(this.shift, is_shift_punch_sync))) {
      dialog.openConfirmDialog({
        msg: "シフトを更新してよろしいですか？",
        cancel_button: "更新しない",
        exec_button: "更新する",
        func: this.updateShiftProcess,
        args: { employee_id, date, yearmonth, shift_id },
      });
    }
  }
  @Action({ rawError: true })
  public async updateShiftProcess({
    employee_id,
    date,
    yearmonth,
    shift_id,
  }: {
    employee_id: number;
    date: string;
    yearmonth: string;
    shift_id: number;
  }) {
    const res = await util.post("attendance_each/update_shift", {
      employee_id,
      date,
      shift_id,
      shift: this.shift,
      carfare: this.carfare,
      yearmonth,
    });

    if (res !== null) {
      monthly.updateShift({ yearmonth, date, employee_id, shift_id, shift: this.shift });
      this.cancelAll();
      node.disable();
    }
  }

  @Action({ rawError: true })
  public async deleteShift({
    branch_id,
    employee_id,
    date,
    yearmonth,
    shift_id,
  }: {
    branch_id: number;
    employee_id: number;
    date: string;
    yearmonth: string;
    shift_id: number;
  }) {
    dialog.openForceConfirmDialog({
      msg: "シフトを削除してよろしいですか？",
      detail: "削除したシフトは戻すことができません",
      cancel_button: "削除しない",
      exec_button: "削除する",
      func: this.deleteShiftProcess,
      args: { branch_id, employee_id, date, yearmonth, shift_id },
    });
  }
  @Action({ rawError: true })
  public async deleteShiftProcess({
    branch_id,
    employee_id,
    date,
    yearmonth,
    shift_id,
  }: {
    branch_id: number;
    employee_id: number;
    date: string;
    yearmonth: string;
    shift_id: number;
  }) {
    monthly.deleteShift({ yearmonth, date, employee_id, shift_id });
    await util.post("attendance_each/delete_shift", {
      branch_id,
      employee_id,
      shift_id,
      date,
      yearmonth,
    });
    node.disable();
  }

  @Action({ rawError: true })
  public async updateHoliday({
    work_type,
    employee_id,
    date,
    yearmonth,
  }: {
    work_type: number;
    employee_id: number;
    date: string;
    yearmonth: string;
  }) {
    this.cancelAll();

    if (work_type == 4 || work_type == 9) {
      dialog.openAlertDialog({
        title: "注意",
        msg: "有給半日や時間有給を使用する場合、シフトは【実際に勤務する時間分のみ】入力し、有給を取得している時間分は入力しないでください。",
      });
    }
    this.updateHolidayProcess({ employee_id, date, work_type, yearmonth });
  }

  @Action({ rawError: true })
  public async updateHolidayProcess({
    work_type,
    employee_id,
    date,
    yearmonth,
  }: {
    work_type: number;
    employee_id: number;
    date: string;
    yearmonth: string;
  }) {
    await util.post("attendance_each/update_holiday", { employee_id, date, work_type, yearmonth });
    node.disable();
  }

  @Action({ rawError: true })
  public async updateHourlyPaidhol({
    hourly_paidhol,
    employee_id,
    date,
    yearmonth,
  }: {
    hourly_paidhol: number;
    employee_id: number;
    date: string;
    yearmonth: string;
  }) {
    this.cancelAll();
    await util.post("attendance_each/update_hourly_paidhol", {
      employee_id,
      date,
      hourly_paidhol,
      yearmonth,
    });
    node.disable();
  }

  @Action({ rawError: true })
  public async updateCounter({
    employee_id,
    yearmonth,
    value,
  }: {
    employee_id: number;
    yearmonth: string;
    value?: number;
  }) {
    await util.post("attendance_each/update_counter", { employee_id, yearmonth, value });
    node.disable();
  }

  @Mutation
  setMode(mode: number) {
    this.mode = mode;
  }
}

export default getModule(Manager);
