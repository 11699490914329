import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import Vue from "vue";
import global from "@/vuex/fixed_tax_reduction/global";

export type EmployeeHash = {
  [id: number]: string;
};
export type LockTypeHash = {
  [id: number]: number;
};
export type NenchoErrorHash = {
  [id: number]: {};
};
export type BranchObject = {
  [id: number]: string;
};
export type Data = {
  employee: any;
  spouce: any;
  dependants: any;
  fixed_tax_reduction_master: any;
  editable: boolean;
  is_valid?: boolean;
  is_set: boolean;
  fetching?: Promise<void>;
};
export type EmployeelyData = {
  [employee_id: number]: Data;
};
export type YearlyData = {
  [fixed_tax_reduction_id: number]: EmployeelyData;
};

@Module({
  dynamic: true,
  store,
  name: "fixed_tax_reduction_employee_yearly_data",
  namespaced: true,
})
class NenchoYearly extends VuexModule {
  data: YearlyData = {};

  @Mutation
  public set({
    fixed_tax_reduction_id,
    employee_id,
    data,
  }: {
    fixed_tax_reduction_id: number;
    employee_id: number;
    data;
  }) {
    Vue.set(this.data[fixed_tax_reduction_id], employee_id, {
      employee: data.employee,
      spouce: data.spouce,
      dependants: data.dependants,
      fixed_tax_reduction_master: data.fixed_tax_reduction_master,
      editable: data.editable,
      is_valid: true,
      is_set: true,
      fetching: null,
    });
  }
  @Mutation
  public disable({
    fixed_tax_reduction_id,
    employee_id,
  }: {
    fixed_tax_reduction_id?: number;
    employee_id?: number;
  }) {
    if (fixed_tax_reduction_id === undefined) {
      if (employee_id === undefined) {
        Object.keys(this.data).forEach((fixed_tax_reduction_id) => {
          Object.keys(this.data[fixed_tax_reduction_id]).forEach((employee_id) => {
            this.data[fixed_tax_reduction_id][employee_id].is_valid = false;
          });
        });
      } else {
        Object.keys(this.data).forEach((fixed_tax_reduction_id) => {
          if (this.data[fixed_tax_reduction_id][employee_id]) {
            this.data[fixed_tax_reduction_id][employee_id].is_valid = false;
          }
        });
      }
    } else {
      if (employee_id === undefined) {
        if (this.data[fixed_tax_reduction_id]) {
          Object.keys(this.data[fixed_tax_reduction_id]).forEach((employee_id) => {
            if (this.data[fixed_tax_reduction_id][employee_id]) {
              this.data[fixed_tax_reduction_id][employee_id].is_valid = false;
            }
          });
        }
      } else {
        if (this.data[fixed_tax_reduction_id] && this.data[fixed_tax_reduction_id][employee_id]) {
          this.data[fixed_tax_reduction_id][employee_id].is_valid = false;
        }
      }
    }
  }
  @Mutation
  public disable_all() {
    Object.keys(this.data).forEach((fixed_tax_reduction_id) => {
      Object.keys(this.data[fixed_tax_reduction_id]).forEach((employee_id) => {
        this.data[fixed_tax_reduction_id][employee_id].is_valid = false;
      });
    });
  }
  @Mutation
  public setFetching({
    fixed_tax_reduction_id,
    employee_id,
    value,
  }: {
    fixed_tax_reduction_id: number;
    employee_id: number;
    value: Promise<void>;
  }) {
    this.data[fixed_tax_reduction_id][employee_id].fetching = value;
  }

  // actions
  @Action({ rawError: true })
  public async fetch({
    fixed_tax_reduction_id,
    employee_id,
  }: {
    fixed_tax_reduction_id: number;
    employee_id: number;
  }) {
    //ないなら作る
    if (!this.data[fixed_tax_reduction_id]) {
      Vue.set(this.data, fixed_tax_reduction_id, {});
    }
    if (!this.data[fixed_tax_reduction_id][employee_id]) {
      Vue.set(this.data[fixed_tax_reduction_id], employee_id, {
        employee: {},
        fixed_tax_reduction_master: {},
        editable: false,
        is_valid: false,
        is_set: false,
        fetching: null,
      });
    }

    if (this.data[fixed_tax_reduction_id][employee_id].fetching) {
      await this.data[fixed_tax_reduction_id][employee_id].fetching;
    } else {
      const promise = util
        .post("fixed_tax_reduction/read_employee_yearly", { fixed_tax_reduction_id, employee_id })
        .then((res) => {
          this.set({ fixed_tax_reduction_id, employee_id, data: res.data });
        });
      this.setFetching({ fixed_tax_reduction_id, employee_id, value: promise });
      await promise;
    }
  }
}

export default getModule(NenchoYearly);
