
  import { Component, Vue, Prop, Watch, Mixins, Emit } from "vue-property-decorator";
  import { Modal } from "ant-design-vue";
  import CommonProgress from "@/components/common/Progress.vue";

  @Component({
    components: {
      CommonProgress,
      Modal,
    },
  })
  export default class Popup extends Vue {
    @Prop({ default: false }) persistent?: boolean;
    @Prop({ default: "750px" }) width: string;
    @Prop() value: boolean;
    @Prop({ default: "cancel" }) type: string;
    @Prop({ default: "閉じる" }) cancel_button: string;
    @Prop({ default: "実行する" }) exec_button: string;
    @Prop({ default: "exec" }) exec_button_color: string;
    @Prop({ default: "none" }) cancel_button_color: string;
    @Prop({ default: true }) close_dialog: boolean;
    @Prop({ default: "white" }) backgroundcolor: string;
    @Prop() padding: string;
    @Prop() m: any;
    @Prop() dialog_index: string;
    @Prop({ default: true }) newtype: boolean;
    @Prop({ default: false }) is_loading: boolean;
    @Prop({ default: false }) hide_footer: boolean;

    created() {
      if (this.m !== undefined) {
        this.m.closeDialog({ name: this.dialog_index });
      }
    }

    get is_display() {
      if (this.m === undefined) {
        return this.value;
      } else {
        return this.m.dialog_flag[this.dialog_index];
      }
    }
    set is_display(val: boolean) {
      if (this.m === undefined) {
        this.update_is_display(val);
      } else {
        this.m.setDialogFlag({ name: this.dialog_index, val });
      }
    }

    @Emit("input")
    update_is_display(val: boolean) {
      return val;
    }

    @Emit("func")
    exec_dialog_func() {
      return true;
    }

    @Emit("cancel_func")
    emit_cancel_func() {
      return true;
    }

    cancel_dialog_func() {
      if (this.close_dialog) this.is_display = false;
      else this.emit_cancel_func();
    }
  }
