import * as util from '@/util';
import * as vali from '@/validations/validationUtil';

//validationは、OKならTrue, それ以外なら警告文を配列に入れる。
export function validationIndividualunit(self, args) {
  var error = [];

  error.push(self.tmp_objects.each_individual_unit.money === null || vali.is_number(self.tmp_objects.each_individual_unit.money) || '金額は半角数字で入力してください');
  
  return error;
}