import { Mutation, MutationAction, Action, VuexModule, getModule, Module } from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import Vue from 'vue';


export type Data = {
  shifts, 
  is_valid?: boolean, 
  is_set: boolean,
  fetching?: Promise<void>
}
export type MonthlyData = {
  [yearmonth: string]: Data
}

@Module({ dynamic: true, store, name: "monthly_employee", namespaced: true })
class Monthly_employee extends VuexModule {
  data: MonthlyData = {};

  @Mutation
  public set({yearmonth, data}: {yearmonth: string, data: Data}) {
    Vue.set(this.data, yearmonth, {
      shifts: data.shifts,
      is_valid: true,
      is_set: true,
      fetching: null,
    });
  }
  @Mutation
  public disable({yearmonth}: {yearmonth: string}) {
    if (this.data[yearmonth]) {
      this.data[yearmonth].is_valid = false;
    }
  }
  @Mutation
  public disable_all() {
    Object.keys(this.data).forEach(yearmonth => {
      this.data[yearmonth].is_valid = false;
    });
  }

  @Mutation
  public setFetching({yearmonth, value}: {yearmonth: string, value: Promise<void>}) {
    this.data[yearmonth].fetching = value;
  }


  // actions
  @Action({ rawError: true })
  public async fetch({yearmonth, employee_id}: {yearmonth: string, employee_id: number}) {
    //ないなら作る
    if (!this.data[yearmonth]) {
      Vue.set(this.data, yearmonth, {
        shifts: [],
        is_valid: false,
        is_set: false,
        fetching: null,
      });
    }
    
    if (this.data[yearmonth].fetching) {
      await this.data[yearmonth].fetching;
    } else {
      const promise = util.post('shift/read_monthly_employee', {yearmonth, employee_id}).then((res) => {
        this.set({yearmonth, data: res.data});
      });
      this.setFetching({yearmonth, value: promise});
      await promise;
    }
  }
}

export default getModule(Monthly_employee);