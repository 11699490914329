import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import Vue from "vue";

export type EmployeeData = {
  id: number;
  name: string; //nicknameの可能性あり
};
export type Data = {
  employees;
  branches;
  employee_hash;
  branch_hash;
  branch_hash_all: any;
  summery;
  started_on: string;
  ended_on: string;
  is_valid?: boolean;
  is_set: boolean;
  fetching?: Promise<void>;
};
export type BranchlyData = {
  [branch: number]: Data;
};
export type DailyData = {
  [date: string]: BranchlyData;
};

@Module({ dynamic: true, store, name: "daily_summery_data", namespaced: true })
class Monthly extends VuexModule {
  data: DailyData = {};

  @Mutation
  public set({ date, branch_id, data }: { date: string; branch_id: number; data: Data }) {
    Vue.set(this.data[date], branch_id, {
      ...data,
      employees: data.employees,
      employee_hash: util.createHashObject(data.employees, "id"),
      branches: data.branches,
      branch_hash: util.createHashReal(data.branches, "id", "name"),
      branch_hash_all: util.createHash(data.branches, "id", "name"),
      is_valid: true,
      is_set: true,
      fetching: null,
    });
  }
  @Mutation
  public disable({ date, branch_id }: { date: string; branch_id?: number }) {
    if (branch_id === undefined || branch_id == 0) {
      if (this.data[date]) {
        Object.keys(this.data[date]).forEach((branch_id) => {
          this.data[date][branch_id].is_valid = false;
        });
      }
    } else {
      if (this.data[date] && this.data[date][branch_id]) {
        this.data[date][branch_id].is_valid = false;
      }
    }
  }
  @Mutation
  public disable_all() {
    Object.keys(this.data).forEach((date) => {
      Object.keys(this.data[date]).forEach((branch_id) => {
        this.data[date][branch_id].is_valid = false;
      });
    });
  }

  @Mutation
  public setFetching({
    date,
    branch_id,
    value,
  }: {
    date: string;
    branch_id: number;
    value: Promise<void>;
  }) {
    this.data[date][branch_id].fetching = value;
  }

  // actions
  @Action({ rawError: true })
  public async fetch({ date, branch_id }: { date: string; branch_id: number }) {
    //ないなら作る
    if (!this.data[date]) {
      Vue.set(this.data, date, {});
    }
    if (!this.data[date][branch_id]) {
      Vue.set(this.data[date], branch_id, {
        employees: [],
        employee_hash: {},
        branches: [],
        branch_hash: {},
        branch_hash_all: {},
        summery: [],
        is_valid: false,
        is_set: false,
        fetching: null,
      });
    }

    if (this.data[date][branch_id].fetching) {
      await this.data[date][branch_id].fetching;
    } else {
      const promise = util.post("shift/read_daily_summery", { date, branch_id }).then((res) => {
        this.set({ date, branch_id, data: res.data });
      });
      this.setFetching({ date, branch_id, value: promise });
      await promise;
    }
  }
}

export default getModule(Monthly);
