
  import { h } from "vue";
  import { Spin } from "ant-design-vue";
  import * as util from "@/util";
  import node from "@/vuex/node";
  import header from "@/vuex/header";
  import shift_manager from "@/vuex/shift/manager";
  import vuex_manager from "@/vuex/vuex_manager";
  import CoreView from "@/components/core/View.vue";
  import CoreHeader from "@/components/core/Header.vue";
  import CoreHeaderjoin from "@/components/core/Headerjoin.vue";
  import CoreFooter from "@/components/core/Footer.vue";
  import CoreFooterjoin from "@/components/core/Footerjoin.vue";
  import FooterNenchosave from "@/components/footer/Nenchosave.vue";
  import FooterSupport from "@/components/footer/Support.vue";
  import FooterDialogMail from "@/components/footer/dialog/Mail.vue";
  import LProgress from "@/components/l/Progress.vue";
  import dialog from "@/vuex/dialog";
  import LogoSpin from "@/LogoSpin.vue";

  export default {
    metaInfo: {
      title: "ARROW",
    },
    components: {
      CoreView,
      CoreHeader,
      CoreHeaderjoin,
      CoreFooter,
      CoreFooterjoin,
      FooterNenchosave,
      FooterSupport,
      FooterDialogMail,
      LProgress,
      Spin,
    },
    data: () => ({
      util,

      theme: "",
      login_page: true,
      join_page: false,
      punch_page: false,
      shift_list_page: false,

      info_title: "",
      info_msg: "",
      info_dialog: 0,
      info_detail: "",

      done_title: "",
      done_msg: "",
      done_dialog: 0,
      done_detail: "",

      loading_msg: "",
      loading_msg_sp: "",
    }),
    computed: {
      indicator() {
        return h(LogoSpin, {
          style: {
            fontSize: "60px",
          },
          spin: true,
        });
      },
      is_set_header() {
        return header.is_set;
      },
      is_valid_header() {
        return header.is_valid;
      },
      dialog() {
        return dialog;
      },
      header() {
        if (!this.is_set_header || !this.is_valid_header) this.get_header();
        if (this.is_set_header) return header.data;
        return undefined;
      },

      s() {
        return this.$store.state;
      },
      nowpage() {
        return this.$route.name;
      },
      shift_mode() {
        return shift_manager.mode;
      },
    },
    watch: {
      shift_mode(val) {
        this.shift_list_page = this.$route.name == "shift" && val == 1;
      },
      $route(val, oldval) {
        if (
          val.name == "company" ||
          val.name == "employee" ||
          val.name == "employee_each" ||
          val.name == "employee_add" ||
          val.name == "spouce_each" ||
          val.name == "dependant_each" ||
          val.name == "spouce_add" ||
          val.name == "dependant_add" ||
          val.name == "employee_admit" ||
          val.name == "employee_change" ||
          val.name == "config" ||
          val.name == "join_employee" ||
          val.name == "change_employee" ||
          val.name == "demand_holiday" ||
          val.name == "demand_overtime" ||
          val.name == "demand_punch" ||
          val.name == "admit_holiday" ||
          val.name == "admit_overtime" ||
          val.name == "admit_punch"
        ) {
          this.theme = "other";
        } else if (val.name == "shift" || val.name == "submit") {
          this.theme = "shift";
        } else if (val.name == "attendance" || val.name == "attendance_each") {
          this.theme = "attendance";
        } else if (
          val.name == "payment" ||
          val.name == "payment_each" ||
          val.name == "bonus" ||
          val.name == "bonus_each" ||
          val.name == "nencho" ||
          val.name == "nencho_master"
        ) {
          this.theme = "payment";
        } else if (val.name == "aggregation") {
          this.theme = "aggregation";
        } else if (val.name == "notification") {
          this.theme = "notification";
        } else if (val.name == "document") {
          this.theme = "document";
        } else if (val.name == "punch") {
          this.theme = "punch";
        } else {
          this.theme = "other";
        }
        if (
          val.name == "login" ||
          val.name == "loginlegacy" ||
          val.name == "illegal" ||
          val.name == "join_illegal" ||
          val.name == "already_join" ||
          val.name == "join_employee_before" ||
          val.name == "applogin" ||
          val.name == "apploginbefore" ||
          val.name == "loginexchanged" ||
          val.name == "postlogout" ||
          val.name == "switchlogin"
        ) {
          this.login_page = true;
        } else {
          this.login_page = false;
        }
        if (val.name == "join" || val.name == "passreset") {
          this.join_page = true;
        } else {
          this.join_page = false;
        }
        if (val.name == "punch") {
          this.punch_page = true;
        } else {
          this.punch_page = false;
        }

        this.shift_list_page = val.name == "shift" && this.shift_mode == 1;

        //システム通知の判断(ページが変わっていたら)
        if (
          !this.login_page &&
          !this.join_page &&
          !this.punch_page &&
          oldval.name != val.name &&
          this.header !== undefined
        ) {
          const admin_notification = this.header.system_informs.find(
            (system_inform) => system_inform.page == val.name && system_inform.is_new,
          );
          if (admin_notification !== undefined) {
            vuex_manager.notification_manager.setTmpObjects({
              name: "admin_notification_id",
              val: admin_notification.id,
            });
            vuex_manager.notification_manager.openDialog({ name: "inform_admin_detail" });
          }
        }
      },
    },
    mounted() {
      node.connect();
      this.$store.state.dialog_state = 0;
    },
    methods: {
      scrollTop() {
        $("html, body").animate(
          {
            scrollTop: 0,
          },
          0,
        );
      },
      async get_header() {
        await header.fetch();
      },
    },
  };

  $(function () {
    let isHidden = true;
    $(window).on("scroll", function () {
      if ($(this).scrollTop() > 240) {
        if (isHidden) {
          $("#for_top a").first().addClass("show_for_top");
          isHidden = false;
        }
      } else {
        if (!isHidden) {
          $("#for_top a").first().removeClass("show_for_top");
          isHidden = true;
        }
      }
    });
  });
