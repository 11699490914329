import * as util from '@/util';
import * as vali from "@/validations/validationUtil";

//validationは、OKならTrue, それ以外なら警告文を配列に入れる。
export default function validationBranch(self, args) {
  var error = [];

  error.push(args.branch.name !== null || '店舗名を入力してください');
  error.push(args.branch.work_ins_type_id !== null || '事業の種類を選択してください');
  error.push(args.branch.work_ins_kind_id !== null || '事業の種類の分類を選択してください');
  error.push(args.branch.is_special_biz !== null || '特例措置対象事業所を選択してください');

  return error;
}

export async function validationBranchBelongEmployee(self, args) {
  var error = [];

  const res = await util.post('config/confirm_delete_branch_belong', {branch_id: args.branch_id});
  error.push(res.data.validate || '削除しようとしている店舗に所属している社員がいます。');

  return error;
}

export async function validationBranchBelongGroup(self, args) {
  var error = [];

  const res = await util.post('config/confirm_delete_branch_group', {branch_id: args.branch_id});
  error.push(res.data.validate || '削除しようとしている店舗と結びついたグループが存在します。');

  return error;
}



export function validationBranchBank(self, args) {
  var error = [];

  error.push(args.branch.is_branch_bank !== null || '使用口座を選択してください');
  if (args.branch.is_branch_bank == 1) {
    error.push(args.branch.bank_id !== null || '金融機関名を入力してください');
    error.push(args.branch.bank_branch_id !== null || '支店名を入力してください');
    error.push(args.branch.bank_type !== null || '口座の種類を選択してください');
    error.push(args.branch.bank_num !== null || '口座番号を入力してください');
    error.push(args.branch.bank_holder !== null || '口座名義を入力してください');

    error.push(args.branch.bank_num === null || vali.is_equal(args.branch.bank_num, 7) || '口座番号は7桁で入力してください');
    error.push(args.branch.bank_num === null || vali.is_number(args.branch.bank_num) || '口座番号は半角数字で入力してください');
    error.push(args.branch.bank_holder === null || !vali.is_kanji(args.branch.bank_holder) || '口座名義は漢字以外で入力してください');
    error.push(args.branch.biz_code === null || vali.is_number(args.branch.biz_code) || '振込依頼人コードは半角数字で入力してください');

    error.push(args.branch.transfer_type !== null || '業務種別を選択してください');
  }
  
  return error;
}
