import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import { Shift_time } from "@/typesold/Shift";
import Vue from "vue";

export type Data = {
  shift_shifts: Shift_time[];
  shift_shift_hash: { [id: number]: Shift_time };
  is_valid?: boolean;
  is_set: boolean;
  fetching?: Promise<void>;
};
export type BranchlyData = {
  [branch_id: number]: Data;
};
export type DailyData = {
  [date: string]: BranchlyData;
};

@Module({ dynamic: true, store, name: "demand_overtime_shiftly", namespaced: true })
class Monthly extends VuexModule {
  data: DailyData = {};

  @Mutation
  public set({ date, branch_id, data }: { date: string; branch_id: number; data: Data }) {
    Vue.set(this.data[date], branch_id, {
      shift_shifts: data.shift_shifts,
      shift_shift_hash: util.createHashObject(data.shift_shifts, "id"),
      is_valid: true,
      is_set: true,
      fetching: null,
    });
  }
  @Mutation
  public disable({
    date,
    branch_id,
    employee_id,
  }: {
    date: string;
    branch_id: number;
    employee_id?: number;
  }) {
    if (employee_id === undefined || employee_id == Number(util.getLocalStorage("employee_id"))) {
      console.log("now 1");
      if (date === undefined) {
        if (branch_id === undefined) {
          Object.keys(this.data).forEach((date) => {
            Object.keys(this.data[date]).forEach((branch_id) => {
              this.data[date][branch_id].is_valid = false;
            });
          });
        } else {
          Object.keys(this.data).forEach((date) => {
            if (this.data[date] && this.data[date][branch_id]) {
              this.data[date][branch_id].is_valid = false;
            }
          });
        }
      } else {
        console.log("now 2");
        if (branch_id === undefined) {
          if (this.data[date]) {
            Object.keys(this.data[date]).forEach((branch_id) => {
              this.data[date][branch_id].is_valid = false;
            });
          }
        } else {
          console.log("now 3", date, branch_id, this.data);
          if (this.data[date] && this.data[date][branch_id]) {
            console.log("now 4");
            this.data[date][branch_id].is_valid = false;
          }
        }
      }
    }
  }
  @Mutation
  public disable_all() {
    Object.keys(this.data).forEach((date) => {
      Object.keys(this.data[date]).forEach((branch_id) => {
        this.data[date][branch_id].is_valid = false;
      });
    });
  }
  @Mutation
  public setFetching({
    date,
    branch_id,
    value,
  }: {
    date: string;
    branch_id: number;
    value: Promise<void>;
  }) {
    this.data[date][branch_id].fetching = value;
  }

  // actions
  @Action({ rawError: true })
  public async fetch({ date, branch_id }: { date: string; branch_id: number }) {
    //ないなら作る
    if (!this.data[date]) {
      Vue.set(this.data, date, {});
    }
    if (!this.data[date][branch_id]) {
      Vue.set(this.data[date], branch_id, {
        shift_shifts: [],
        shift_shift_hash: {},
        is_valid: false,
        is_set: false,
        fetching: null,
      });
    }

    if (this.data[date][branch_id].fetching) {
      await this.data[date][branch_id].fetching;
    } else {
      const promise = util.post("demand_overtime/read_shiftly", { date, branch_id }).then((res) => {
        this.set({ date, branch_id, data: res.data });
      });
      this.setFetching({ date, branch_id, value: promise });
      await promise;
    }
  }
}

export default getModule(Monthly);
