import VuexManagerControl from "@/vuex/VuexManagerControl";
import {validationBase, validationPaymentAllowance, validationPaymentDeduction, validationPaymentOtherAllowance, validationBonusAllowance, validationBonusDeduction, validationBonusOtherAllowance, validationGroup} from "@/validations/config";
import { validationEmployeeInfo, validationSoftEmployeeInfo } from "@/validations/employee_info";

export default class Manager extends VuexManagerControl {
  create_descriptor = {
    payment_allowance: {
      url: 'config/create_payment_allowance',
      name: '給与支給項目',
      validate: validationPaymentAllowance,
      processing_dialog: {},
      finish_dialog: {},
    },
    payment_deduction: {
      url: 'config/create_payment_deduction',
      name: '給与控除項目',
      validate: validationPaymentDeduction,
      processing_dialog: {},
      finish_dialog: {},
    },
    payment_other_allowance: {
      url: 'config/create_payment_other_allowance',
      name: '給与その他支給項目',
      validate: validationPaymentOtherAllowance,
      processing_dialog: {},
      finish_dialog: {},
    },
    bonus_allowance: {
      url: 'config/create_bonus_allowance',
      name: '賞与支給項目',
      validate: validationBonusAllowance,
      processing_dialog: {},
      finish_dialog: {},
    },
    bonus_deduction: {
      url: 'config/create_bonus_deduction',
      name: '賞与控除項目',
      validate: validationBonusDeduction,
      processing_dialog: {},
      finish_dialog: {},
    },
    bonus_other_allowance: {
      url: 'config/create_bonus_other_allowance',
      name: '賞与その他支給項目',
      validate: validationBonusOtherAllowance,
      processing_dialog: {},
      finish_dialog: {},
    },

    shiftgroup: {
      url: 'config/create_shiftgroup',
      name: 'シフトグループ',
      validate: validationGroup,
      processing_dialog: {},
      finish_dialog: {},
    },
    paymentgroup: {
      url: 'config/create_paymentgroup',
      name: '手当・控除グループ',
      validate: validationGroup,
      processing_dialog: {},
      finish_dialog: {},
    },
    employee_info: {
      url: 'config/create_employee_info',
      name: 'カスタム情報',
      validate: validationEmployeeInfo,
      processing_dialog: {},
      finish_dialog: {},
    },
  };

  update_descriptor = {
    base: {
      url: 'config/update_base_config',
      name: '設定',
      validate: validationBase,
      dialog: {},
      processing_dialog: {},
      finish_dialog: {},
      continue_edit: true,
    },

    payment_allowance: {
      url: 'config/update_payment_allowance',
      name: '給与支給項目',
      validate: validationPaymentAllowance,
      processing_dialog: {},
      finish_dialog: {},
    },
    payment_deduction: {
      url: 'config/update_payment_deduction',
      name: '給与控除項目',
      validate: validationPaymentDeduction,
      processing_dialog: {},
      finish_dialog: {},
    },
    payment_other_allowance: {
      url: 'config/update_payment_other_allowance',
      name: '給与その他支給項目',
      validate: validationPaymentOtherAllowance,
      processing_dialog: {},
      finish_dialog: {},
    },
    bonus_allowance: {
      url: 'config/update_bonus_allowance',
      name: '賞与支給項目',
      validate: validationBonusAllowance,
      processing_dialog: {},
      finish_dialog: {},
    },
    bonus_deduction: {
      url: 'config/update_bonus_deduction',
      name: '賞与控除項目',
      validate: validationBonusDeduction,
      processing_dialog: {},
      finish_dialog: {},
    },
    bonus_other_allowance: {
      url: 'config/update_bonus_other_allowance',
      name: '賞与その他支給項目',
      validate: validationBonusOtherAllowance,
      processing_dialog: {},
      finish_dialog: {},
    },

    shiftgroup: {
      url: 'config/update_shiftgroup',
      name: 'シフトグループ',
      validate: validationGroup,
      processing_dialog: {},
      finish_dialog: {},
    },
    paymentgroup: {
      url: 'config/update_paymentgroup',
      name: '手当・控除グループ',
      validate: validationGroup,
      processing_dialog: {},
      finish_dialog: {},
    },

    up_payment_allowance: {
      url: 'config/up_payment_allowance',
    },
    down_payment_allowance: {
      url: 'config/down_payment_allowance',
    },
    up_payment_deduction: {
      url: 'config/up_payment_deduction',
    },
    down_payment_deduction: {
      url: 'config/down_payment_deduction',
    },
    up_payment_other_allowance: {
      url: 'config/up_payment_other_allowance',
    },
    down_payment_other_allowance: {
      url: 'config/down_payment_other_allowance',
    },
    up_bonus_allowance: {
      url: 'config/up_bonus_allowance',
    },
    down_bonus_allowance: {
      url: 'config/down_bonus_allowance',
    },
    up_bonus_deduction: {
      url: 'config/up_bonus_deduction',
    },
    down_bonus_deduction: {
      url: 'config/down_bonus_deduction',
    },
    up_bonus_other_allowance: {
      url: 'config/up_bonus_other_allowance',
    },
    down_bonus_other_allowance: {
      url: 'config/down_bonus_other_allowance',
    },

    belong_shiftgroup: {
      url: 'config/update_belong_shiftgroup',
      name: 'シフトグループ所属',
      processing_dialog: {},
      finish_dialog: {},
      close_dialog: ['belong_shiftgroup'],
    },
    belong_paymentgroup: {
      url: 'config/update_belong_paymentgroup',
      name: '手当・控除グループ所属',
      processing_dialog: {},
      finish_dialog: {},
      close_dialog: ['belong_paymentgroup'],
    },

    employee_info: {
      url: 'config/update_employee_info',
      name: 'カスタム情報',
      dialog: {},
      force_soft_dialog: {},
      soft_validate: validationSoftEmployeeInfo,
      validate: validationEmployeeInfo,
      processing_dialog: {},
      finish_dialog: {},
    }, 
  }

  delete_descriptor = {
    payment_allowance: {
      url: 'config/delete_payment_allowance',
      name: '給与支給項目',
      dialog: {msg: '給与支給項目を削除してよろしいですか？', detail: '削除した給与支給項目は戻すことができません。'},
      processing_dialog: {},
      finish_dialog: {},
    },
    payment_deduction: {
      url: 'config/delete_payment_deduction',
      name: '給与控除項目',
      dialog: {msg: '給与控除項目を削除してよろしいですか？', detail: '削除した給与控除項目は戻すことができません。'},
      processing_dialog: {},
      finish_dialog: {},
    },
    payment_other_allowance: {
      url: 'config/delete_payment_other_allowance',
      name: '給与その他支給項目',
      dialog: {msg: '給与その他支給項目を削除してよろしいですか？', detail: '削除した給与その他支給項目は戻すことができません。'},
      processing_dialog: {},
      finish_dialog: {},
    },
    bonus_allowance: {
      url: 'config/delete_bonus_allowance',
      name: '賞与支給項目',
      dialog: {msg: '賞与支給項目を削除してよろしいですか？', detail: '削除した賞与支給項目は戻すことができません。'},
      processing_dialog: {},
      finish_dialog: {},
    },
    bonus_deduction: {
      url: 'config/delete_bonus_deduction',
      name: '賞与控除項目',
      dialog: {msg: '賞与控除項目を削除してよろしいですか？', detail: '削除した賞与控除項目は戻すことができません。'},
      processing_dialog: {},
      finish_dialog: {},
    },
    bonus_other_allowance: {
      url: 'config/delete_bonus_other_allowance',
      name: '賞与その他支給項目',
      dialog: {msg: '賞与その他支給項目を削除してよろしいですか？', detail: '削除した賞与その他支給項目は戻すことができません。'},
      processing_dialog: {},
      finish_dialog: {},
    },

    shiftgroup: {
      url: 'config/delete_shiftgroup',
      name: 'シフトグループ',
      dialog: {},
      processing_dialog: {},
      finish_dialog: {},
    },
    paymentgroup: {
      url: 'config/delete_paymentgroup',
      name: '手当・控除グループ',
      dialog: {},
      processing_dialog: {},
      finish_dialog: {},
    },
    employee_info: {
      url: 'config/delete_employee_info',
      name: 'カスタム情報', 
      dialog: {detail: '各社員に登録されているこの項目の情報は全て削除され、元に戻すことはできません。'},
      force_dialog: {detail: '各社員に登録されているこの項目の情報は全て削除され、元に戻すことはできません。'},
      processing_dialog: {},
      finish_dialog: {},
    },
  }
}