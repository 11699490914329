import { EmployeeBase, EmployeePayment } from "@/typesold/employee";
import * as util from "@/util";
import * as vali from "@/validations/validationUtil";
import global from "@/vuex/employee_each/global";

//validationは、OKならTrue, それ以外なら警告文を配列に入れる。
export function validationEmployeeInfo(self, args) {
  var error = [];
  error.push(args.employee_info.name !== null || "項目名を入力してください");
  error.push(args.employee_info.type !== null || "形式を選択してください");
  if (args.employee_info.type !== null && args.employee_info.type == 3) {
    error.push(args.employee_info.option1 !== null || "選択肢1を入力してください");
    error.push(args.employee_info.option2 !== null || "選択肢2を入力してください");
  }
  error.push(args.employee_info.is_join !== null || "社員による入力の有無を選択してください");
  error.push(args.employee_info.is_employee !== null || "社員への表示の有無を選択してください");
  error.push(args.employee_info.is_branch !== null || "店舗の権限を選択してください");
  return error;
}
export function validationSoftEmployeeInfo(self, args) {
  var error = [];
  error.push(
    args.employee_info.type == args.old_employee_info.type ||
      "形式が変更されているため、各社員に登録されているこの項目の情報は全て削除され、元に戻すことはできません。<br>形式が画像の場合、各社員に登録されているこの項目の画像は全て削除され、元に戻すことはできません。",
  );
  return error;
}

export function validationEachEmployeeInfos(self, args) {
  //type = {0:日付-自由, 1:自由-自由, 2: 自由入力欄, 3: 質問-回答}
  var error = [];

  var day_error = false;
  var freehead_error = false;
  var content_error = false;

  if (args.type == 0) {
    //日付-自由
    args.each_employee_infos.forEach((each_employee_info) => {
      if (each_employee_info.day !== null || each_employee_info.content !== null) {
        if (each_employee_info.day === null) day_error = true;
        if (each_employee_info.content === null) content_error = true;
      }
    });
    error.push(!day_error || "内容が入力されていて日付が正しく入力されていない行があります");
    error.push(!content_error || "日付が入力されていて内容が入力されていない行があります");
  } else if (args.type == 1) {
    //自由-自由
    args.each_employee_infos.forEach((each_employee_info) => {
      if (each_employee_info.freehead !== null || each_employee_info.content !== null) {
        if (each_employee_info.freehead === null) freehead_error = true;
        if (each_employee_info.content === null) content_error = true;
      }
    });
    error.push(!freehead_error || "内容が入力されていて見出しが入力されていない行があります");
    error.push(!content_error || "見出しが入力されていて内容が入力されていない行があります");
  }

  return error;
}
