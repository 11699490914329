import { Mutation, MutationAction, Action, VuexModule, getModule, Module } from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import Vue from 'vue';
import global from "@/vuex/bundle/global";

export type Data = {
  employees: any,
  employee_hash: any,
  resident_taxes: any,
  resident_tax_years: any,
  is_valid?: boolean,
  is_set: boolean,
  fetching?: Promise<void>
}
export type BranchlyData = {
  [branch_id: number]: Data,
}
export type YearlyData = {
  [year: number]: BranchlyData,
}

@Module({ dynamic: true, store, name: "bundle_resident_data", namespaced: true })
class NenchoYearly extends VuexModule {
  data: YearlyData = {};

  @Mutation
  public set({year, branch_id, data}: {year: number, branch_id: number, data}) {
    Vue.set(this.data[year], branch_id, {
      employees: data.employees,
      employee_hash: util.createHashObject(data.employees, 'id'),
      resident_taxes: data.resident_taxes,
      resident_tax_years: data.resident_tax_years,
      is_valid: true,
      is_set: true,
      fetching: null,
    });
  }
  @Mutation
  public disable({year, branch_id}: {year?: number, branch_id?: number}) {
    if (year === undefined) {
      if (branch_id === undefined) {
        Object.keys(this.data).forEach(year => {
          Object.keys(this.data[year]).forEach(branch_id => {
            this.data[year][branch_id].is_valid = false;
          });
        });
      } else {
        Object.keys(this.data).forEach(year => {
          if (this.data[year][branch_id]) {
            this.data[year][branch_id].is_valid = false;
          }
        });
      }
    } else {
      if (branch_id === undefined) {
        if (this.data[year]) {
          Object.keys(this.data[year]).forEach(branch_id => {
            if (this.data[year][branch_id]) {
              this.data[year][branch_id].is_valid = false;
            }
          });
        }
      } else {
        if (this.data[year] && this.data[year][branch_id]) {
          this.data[year][branch_id].is_valid = false;
        }
      }
    }
  }
  @Mutation
  public disable_all() {
    Object.keys(this.data).forEach(year => {
      Object.keys(this.data[year]).forEach(branch_id => {
        this.data[year][branch_id].is_valid = false;
      });
    });
  }
  @Mutation
  public setFetching({year, branch_id, value}: {year: number, branch_id: number, value: Promise<void>}) {
    this.data[year][branch_id].fetching = value;
  }


  // actions
  @Action({ rawError: true })
  public async fetch({year, branch_id}: {year: number, branch_id: number}) {
    //ないなら作る
    if (!this.data[year]) {
      Vue.set(this.data, year, {});
    }
    if (!this.data[year][branch_id]) {
      Vue.set(this.data[year], branch_id, {
        employee: {},
        employee_hash: {},
        resident_taxes: [],
        resident_tax_years: [],
        is_valid: false,
        is_set: false,
        fetching: null,
      });
    }

    if (this.data[year][branch_id].fetching) {
      await this.data[year][branch_id].fetching;
    } else {
      const promise = util.post('bundle/read_resident', {year, branch_id}).then((res) => {
        this.set({year, branch_id, data: res.data});
      });
      this.setFetching({year, branch_id, value: promise});
      await promise;
    }
  }
}

export default getModule(NenchoYearly);