const ja = {
  week_prescribed_day: "労働契約や就業規則で企業が定めた労働日数です。",
  week_prescribed_time: "労働契約や就業規則で企業が定めた労働時間です。",
  month_prescribed_day: "月当たりの所定労働日数です。設定すると強制的にその値が使用されます。",
  month_prescribed_time: "月当たりの所定労働時間です。設定すると強制的にその値が使用されます。",
  retired_on: "退社から1年間はマイページにログイン可能です。",

  hourly_wage1: "曜日のチェックを外すと、その曜日に対して個別の時給を設定できます。",
  hourly_wage2: "24:00終了でない場合、入力フォームが増設されます。",
  hourly_wage3: "割増賃金は、入力した時給に対してさらに上乗せされます。",

  disp_gensentax: "源泉徴収票への摘要欄に記載する内容です。",
  restax_sum:
    "年額を入力すると自動で計算されます。編集することも可能です。<br>令和6年度の住民税は定額減税が適用されるため年額の入力では正しく各月に分配されません。月毎に入力してください。",
  social_ins_got_on: "入力された日付から自動で適用されます。",
  emp_ins_got_on: "入力された日付から自動で適用されます。",
  comp_ins_got_on: "入力された日付から自動で適用されます。",
  dependant_num: "税制上の扶養人数と実際の扶養人数は異なることがあります。",
  employee_type: "ARROWでの従業員の用途です。",
  company_name: "個人事業主の場合は事業主の氏名",
  biz_name: "法定調書合計表・給与支払報告書用",
  company_mynum: "個人事業主の場合は事業主のマイナンバー",
  socialins_seiri_code: "保険料納入告知額・領収済額通知書に記載されている記号です。",
  socialins_office_num: "保険料納入告知額・領収済額通知書に記載されている番号です。",
  empins_office_num: "雇用保険適用事業所設置届事業主控などに記載されている番号です。",
  summery_type: "シフトサマリーの計算期間を変更できます。",
  show_other_shift: "マイページで表示する他人のシフトを制限できます。",
  shift_punch_sync: "打刻からシフトを自動作成できます。",

  outhol: "法律で定められていない休日です。",
  lawhol: "法律で週1日以上与えることが義務づけられている休日です。",
  punch_round: "打刻時間をシフトに応じて丸められます。",
  break_round: "休憩時間を丸められます。",
  config_punch_gps: "マイページからGPS打刻することを許可できます。",
  config_punch_mypc: "個人のPCを登録して打刻することを許可できます。",
  config_punch_alert: "シフトなしで打刻した際のアラートを非表示にできます。",

  add_paidhol_time: "合計勤務時間に有給休暇・特別休暇時間を加算できます。",
  show_attendance_chart: "マイページで勤怠チャートを公開できます。",
  is_deem_preover: "固定残業の計算に法定内残業を含めるかどうかを選択できます。",
  no_shift_error: "打刻があるのにシフトがない場合のエラーを切り替えられます。",
  close_day: "勤怠を締める日です。",
  pay_month: "給与の振り込み月です。",
  pay_day: "給与の振り込み日です。",
  levy_month: "社会保険料を徴収する月です。",

  lawover_rate: "25%以上の設定が義務付けられています。",
  preover_rate: "割増賃金の支払い義務はなく、基本的には0%です。",
  sixtylawover_rate: "法定外残業に上乗せされます。25%以上の設定が義務付けられています。",
  outhol_rate: "割増賃金の支払い義務はなく、基本的には0%です。",
  lawhol_rate: "35%以上の設定が義務付けられています。",
  mid_rate: "25%以上の設定が義務付けられています。",
  show_zero: "マイページの給与・賞与明細で0円の項目を非表示にできます。",
  remain_paid_hol: "マイページの給与・賞与明細に有給残日数を非表示にできます。",
  transfer_unit: "店舗ごとに振り込み口座を設定できます。",
  delay_alert: "指定した遅刻時間を過ぎた時に通知を送信できます。",
  need_alert: "シフト募集を行った際にシフト提出を促す通知を送信できます。",
  order_overtime: "マイページから残業申請を行うことができます。",
  order_holiday: "マイページから休暇申請を行うことができます。",
  order_change: "マイページから個人情報の変更申請を行うことができます。",
  order_punch: "マイページから打刻修正申請を行うことができます。",
  order_join: "入社申請の承認を行う権限を設定できます。",
  use_nickname: "シフトページでニックネームが使用できます。",
  auth_gensentax: "マイページから自身の源泉徴収票を見ることを許可できます。",
  bank_count: "2つ目の振込用口座を入力することを許可できます。",
  branch_hourly_wage: "店舗ごとに時給を設定可能にできます。",
  branch_carfare: "店舗ごとに通勤手当金額を設定可能にできます。",
  is_income_tax_opt: "源泉所得税の計算をするか選択できます。",
  use_double_biz: "二以上事業所勤務者の設定ができるようになります。",
  is_day_over:
    "日の法定外残業(基本的には1日8時間超えの残業)を計算するか選択できます。基本的には必須です。",
  is_week_over:
    "週の法定外残業(基本的には週40時間超えの残業)を計算するか選択できます。基本的には必須です。",
  is_month_over:
    "月の法定外残業(月単位変形労働制やフレックスタイム制の場合に月の上限によって発生する残業)を計算するか選択できます。基本的には必須です。",
  before_join: "勤怠ページで入社以前の社員を表示するか選択できます。",
  main_branch: "勤怠や給与などはこの店舗への所属として計算されます。",
  sub_branch: "別店舗のシフトに入る場合に使用します。",
  earning:
    "年末調整で修正されるため、推定額を入力してください。<br>副業その他すべての金額を合算してください。",
  earning_nencho: "副業その他すべての金額を合算してください。",
  earning_fixed_tax_resident:
    "所得が48万円以下だと定額減税対象外になります。正確に入力してください。",
  spouce_earning_fixed_tax_resident:
    "所得が48万円を超えると定額減税対象外になります。正確に入力してください。",
  from_you_householder_relationship: "あなたから見た世帯主との関係性を選択してください。",
  individual_unit: "社員ごとに異なる単価を設定でき、その値を手当項目などで使用できます。",
  yucho_bank_num:
    '<p style="color: red">※番号には最後の一桁の「１」を除いた番号を入力してください。</p><p style="color: red">※番号が7桁に満たない場合は先頭に0を足してください。</p>',
  bank1_limit: "振込口座１の上限金額を超えた金額が振込口座２に振り込まれます。",
  carfare_way: "徒歩・自転車の場合は公共交通機関にチェックしてください。",
  retire_income: "退職所得は計算後の値を入力してください。",
  continuoustime_error: "日〜土曜の勤務時間が設定した時間以上になると勤怠エラーになります。",
  continuousday_error: "設定した日数以上連続勤務すると勤怠エラーになります。",
  attendance_error_alert: "週勤務時間と連続勤務日数のアラートを送信できます。",
};

const en = {
  carfare_way: "If you commute by walking or biking, please check public transportation.",
  bank1_limit:
    "※ Amounts exceeding the limit of transfer account 1 will be transferred to transfer account 2.",
  from_you_householder_relationship:
    "Please select your relationship to the head of household from your perspective.",
  earning: "Enter the estimated amount, as it will be corrected in the year-end adjustment.",
};

export default {
  ja,
  en: {
    ...ja,
    ...en,
  },
};
