import {
  EachIndividualUnitData,
  EachIndividualUnitValidateData,
} from "@/typesold/Each_individual_unit";
import VuexDataControl from "@/vuex/VuexDataControl";

export default class Employeely extends VuexDataControl {
  api_url = "employee_each/read_each_individual_unit_employeely";
  indexes = ["employee_id"];
}
