import * as util from "@/util";
import * as vali from "@/validations/validationUtil";

//validationは、OKならTrue, それ以外なら警告文を配列に入れる。
export function validationLaborins(self, args) {
  var error = [];

  error.push(args.laborins.started_yearmonth !== null || "開始年月が正しく入力されていません。");
  error.push(args.laborins.ended_yearmonth !== null || "終了年月が正しく入力されていません。");
  if (args.laborins.started_yearmonth !== null && args.laborins.ended_yearmonth !== null) {
    var startobj = util.date2obj(args.laborins.started_yearmonth + "-01");
    var endobj = util.date2obj(args.laborins.ended_yearmonth + "-01");
    var startint = util.date2year(startobj) * 12 + util.date2month(startobj);
    var endint = util.date2year(endobj) * 12 + util.date2month(endobj);
    if (endint - startint >= 12) {
      error.push("期間は12ヶ月以下に設定してください。");
    }
  }

  args.laborins_masters.forEach((laborins_master, i) => {
    error.push(laborins_master.comp_rate !== null || "グループ" + (i + 1) + "の確定労災保険料率を");
    error.push(
      laborins_master.comp_rate === null ||
        vali.is_float(laborins_master.comp_rate) ||
        "グループ" + (i + 1) + "の確定労災保険料率は半角数字で入力してください",
    );
    error.push(
      laborins_master.emp_rate !== null ||
        "グループ" + (i + 1) + "の確定雇用保険料率を入力してください",
    );
    error.push(
      laborins_master.emp_rate === null ||
        vali.is_float(laborins_master.emp_rate) ||
        "グループ" + (i + 1) + "の確定雇用保険料率は半角数字で入力してください",
    );
    error.push(
      laborins_master.estimate_comp_rate !== null ||
        "グループ" + (i + 1) + "の概算・増加概算労災保険料率を入力してください",
    );
    error.push(
      laborins_master.estimate_comp_rate === null ||
        vali.is_float(laborins_master.estimate_comp_rate) ||
        "グループ" + (i + 1) + "の概算・増加概算労災保険料率は半角数字で入力してください",
    );
    error.push(
      laborins_master.estimate_emp_rate !== null ||
        "グループ" + (i + 1) + "の概算・増加概算雇用保険料率を入力してください",
    );
    error.push(
      laborins_master.estimate_emp_rate === null ||
        vali.is_float(laborins_master.estimate_emp_rate) ||
        "グループ" + (i + 1) + "の概算・増加概算雇用保険料率は半角数字で入力してください",
    );
    error.push(
      laborins_master.estimated_money !== null ||
        "グループ" + (i + 1) + "の申告済み概算保険料を入力してください",
    );
    error.push(
      laborins_master.estimated_money === null ||
        vali.is_number(laborins_master.estimated_money) ||
        "グループ" + (i + 1) + "の申告済み概算保険料は半角数字で入力してください",
    );
  });
  return error;
}

export function validationLaborinsMasters(self, args) {
  var error = [];
  Object.keys(args.laborins_masters).forEach((id, i) => {
    error.push(
      args.laborins_masters.hoge !== null || "グループ" + (i + 1) + "のホゲを入力してください",
    );
  });
  return error;
}
