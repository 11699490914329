import dialog from "@/vuex/dialog";

export default function validate(error: (true | string)[]) {
  var filtered_error = error.filter(v => v !== true);
  if (filtered_error.length == 0) {
    return true;
  } else {
    const casted_error = filtered_error as string[];
    dialog.openErrorDialog({error: casted_error});
    return false;
  }
}

export function soft_validate({error, func, args, is_next_dialog = false}: {error: (true | string)[], func: any, args: any, is_next_dialog: boolean}) {
  var filtered_error = error.filter(v => v !== true);
  if (filtered_error.length == 0) {
    return true;
  } else {
    const casted_error = filtered_error as string[];
    dialog.openForceConfirmDialog({msg: '以下のエラーがありますが実行しますか？', detail: casted_error.join('<br>'), cancel_button: '実行しない', exec_button: '実行する', func, is_next_dialog, args});
    return false;
  }
}