import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import Vue from "vue";
import Branch from "@/typesold/Branch";

export type GlobalData = {
  branch: any;
  is_valid: boolean;
  is_set: boolean;
  fetching: Promise<void>;
};

export type BranchlyData = {
  [id: number]: GlobalData;
};

@Module({ dynamic: true, store, name: "config_branch_bank", namespaced: true })
class Global extends VuexModule {
  data: BranchlyData = {};

  @Mutation
  public disable({ branch_id }: { branch_id?: number }) {
    if (branch_id === undefined) {
      Object.keys(this.data).forEach((branch_id) => {
        this.data[branch_id].is_valid = false;
      });
    } else {
      if (this.data[branch_id]) {
        this.data[branch_id].is_valid = false;
      }
    }
  }
  @Mutation
  public disable_all() {
    Object.keys(this.data).forEach((branch_id) => {
      this.data[branch_id].is_valid = false;
    });
  }
  @Mutation
  public setFetching({ branch_id, value }: { branch_id: number; value: Promise<void> }) {
    this.data[branch_id].fetching = value;
  }
  @Mutation
  public set({ branch_id, data }: { branch_id: number; data: any }) {
    Vue.set(this.data, branch_id, {
      branch: data.branch,
      is_valid: true,
      is_set: true,
      fetching: null,
    });
  }

  @Action({ rawError: true })
  public async fetch({ branch_id }: { branch_id: number }) {
    if (!this.data[branch_id]) {
      Vue.set(this.data, branch_id, {
        branch: {},
        is_valid: false,
        is_set: false,
        fetching: null,
      });
    }
    if (this.data[branch_id].fetching) {
      await this.data[branch_id].fetching;
    } else {
      const promise = util.post("config/read_branch_bank", { branch_id }).then((res) => {
        this.set({ branch_id, data: res.data });
      });
      this.setFetching({ branch_id, value: promise });
      await promise;
    }
  }
}

export default getModule(Global);
