
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import utilMixins from '@/mixins';
import header from "@/vuex/header";
import dialog from "@/vuex/dialog";
import HeaderJoinLogo from "@/components/header/join/Logo.vue";

@Component({
  components: {
    HeaderJoinLogo,
  }
})
export default class Normal extends Mixins(utilMixins) {
}
