import VuexManagerControl from "@/vuex/VuexManagerControl";
import validationPaidHoliday from "@/validations/paid_holiday";
import {
  validationEmployeeMail,
  validationEmployeeBase,
  validationEmployeePayment,
  validationEmployeeCode,
  validationMail,
  validationPassword,
  validationCurrentPassword,
  validationAdministrator,
} from "@/validations/employee";
import { validationEachEmployeeInfos } from "@/validations/employee_info";
import { validationDependant, validationSpouce } from "@/validations/family";

export default class Manager extends VuexManagerControl {
  create_descriptor = {
    paidhol: {
      url: "employee_each/create_paid_holiday",
      name: "有給休暇",
      validate: validationPaidHoliday,
      dialog: {},
      processing_dialog: {},
      finish_dialog: {},
    },
    spouce: {
      url: "employee_each/create_spouce",
      name: "配偶者",
      validate: validationSpouce,
      dialog: {},
      processing_dialog: {},
      finish_dialog: {},
      routing: {
        name: "employee_each",
        plus_args: { page: "self_family" },
      },
    },
    dependant: {
      url: "employee_each/create_dependant",
      name: "扶養親族",
      validate: validationDependant,
      dialog: {},
      processing_dialog: {},
      finish_dialog: {},
      routing: {
        name: "employee_each",
        plus_args: { page: "self_family" },
      },
    },
  };

  update_descriptor = {
    base: {
      url: "employee_each/update_employee_base",
      name: "本人設定項目",
      validate: [validationEmployeeMail, validationEmployeeBase],
      dialog: {},
      processing_dialog: {},
      finish_dialog: {},
    },
    payment: {
      url: "employee_each/update_employee_payment",
      name: "会社設定項目",
      validate: [validationEmployeeCode, validationEmployeePayment],
      dialog: {},
      processing_dialog: {},
      finish_dialog: {},
    },
    paidhol: {
      url: "employee_each/update_paid_holiday",
      name: "有給休暇",
      validate: validationPaidHoliday,
      dialog: {},
      processing_dialog: {},
      finish_dialog: {},
    },
    paidhol_employee: {
      url: "employee_each/update_employee_paid_holiday",
      name: "社員情報",
      dialog: {},
      processing_dialog: {},
      finish_dialog: {},
    },
    each_employee_info: {
      url: "employee_each/update_each_employee_info",
      name: "カスタム情報",
      validate: validationEachEmployeeInfos,
      dialog: {},
      processing_dialog: {},
      finish_dialog: {},
    },
    each_employee_info_image: {
      url: "employee_each/update_each_employee_info_image",
      name: "カスタム情報",
      dialog: {},
      processing_dialog: {},
      finish_dialog: {},
    },
    spouce: {
      url: "employee_each/update_spouce",
      name: "配偶者",
      validate: validationSpouce,
      dialog: {},
      processing_dialog: {},
      finish_dialog: {},
    },
    dependant: {
      url: "employee_each/update_dependant",
      name: "扶養親族",
      validate: validationDependant,
      dialog: {},
      processing_dialog: {},
      finish_dialog: {},
    },
    card: {
      url: "employee_each/update_employee_auth",
      name: "基本情報",
      validate: [validationEmployeeCode, validationEmployeeBase],
      dialog: {},
      processing_dialog: {},
      finish_dialog: {},
      routing: {
        name: "employee_each",
        plus_args: { page: "self_general" },
      },
    },
    face: {
      url: "employee_each/update_employee_face",
      name: "顔写真",
      dialog: {},
      processing_dialog: {},
      finish_dialog: {},
    },
    belong_main_branch: {
      url: "employee_each/update_belong_main_branch",
      name: "主所属部署/店舗",
      dialog: {},
      processing_dialog: {},
      finish_dialog: {},
    },
    belong_sub_branch: {
      url: "employee_each/update_belong_sub_branch",
      name: "従所属部署/店舗",
      dialog: {},
      processing_dialog: {},
      finish_dialog: {},
    },
    belong_shiftgroup: {
      url: "employee_each/update_belong_shiftgroup",
      name: "所属シフトグループ",
      dialog: {},
      processing_dialog: {},
      finish_dialog: {},
    },
    belong_paymentgroup: {
      url: "employee_each/update_belong_paymentgroup",
      name: "所属給与グループ",
      dialog: {},
      processing_dialog: {},
      finish_dialog: {},
    },
    config: {
      url: "employee_each/update_employee_config",
      name: "例外設定",
      dialog: {},
      processing_dialog: {},
      finish_dialog: {},
    },
    config_reset: {
      url: "employee_each/update_employee_config",
      name: "例外設定",
      dialog: {
        msg: "例外設定を無効にしようとしています。<br>無効にした後に有効にすると会社の設定で上書きされ、現在の例外設定は上書きされます。<br>本当によろしいですか？",
        detail: "上書き後に元に戻すことはできません。",
      },
      processing_dialog: {},
      finish_dialog: {},
    },

    resendUrl: {
      url: "employee_each/resend_employee_url",
      dialog: {
        msg: "ログインURLを再送してよろしいですか？",
        detail: "入社手続き後に送信されたメールが再送信されます。",
        cancel_button: "送信しない",
        exec_button: "送信する",
      },
      processing_dialog: { title: "送信中" },
      finish_dialog: { msg: "送信しました。" },
    },
    resetPassword: {
      url: "employee_each/reset_password",
      dialog: {
        msg: "パスワードをリセットしてよろしいですか？",
        detail: "パスワードが0000にリセットされます。",
        cancel_button: "リセットしない",
        exec_button: "リセットする",
      },
      processing_dialog: {},
      finish_dialog: { msg: "パスワードを0000にリセットしました。" },
    },
    mail: {
      url: "employee_each/update_mail",
      name: "メールアドレス",
      validate: [validationMail, validationEmployeeMail],
      dialog: {},
      processing_dialog: {},
      finish_dialog: {},
    },
    pass: {
      url: "employee_each/update_password",
      name: "パスワード",
      validate: [validationPassword, validationCurrentPassword],
      dialog: {},
      processing_dialog: {},
      finish_dialog: {},
    },
    mypc: {
      url: "employee_each/update_mypc",
      validate: [validationAdministrator],
      dialog: {
        msg: "このPCをMyPCとして登録してよろしいですか？",
        cancel_button: "登録しない",
        exec_button: "登録する",
      },
      processing_dialog: {},
      finish_dialog: { msg: "登録が完了しました。" },
    },
  };

  delete_descriptor = {
    paidhol: {
      url: "employee_each/delete_paid_holiday",
      name: "有給休暇",
      dialog: {},
      processing_dialog: {},
      finish_dialog: {},
    },
    spouce: {
      url: "employee_each/delete_spouce",
      name: "配偶者",
      dialog: {},
      processing_dialog: {},
      finish_dialog: {},
    },
    dependant: {
      url: "employee_each/delete_dependant",
      name: "扶養親族",
      dialog: {},
      processing_dialog: {},
      finish_dialog: {},
    },
  };
}
