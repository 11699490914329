import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import Vue from "vue";
import Shift from "@/typesold/shift";
import Able from "@/typesold/able";
import Shift_memo from "@/typesold/shift_memo";

export type Employee = {
  id: number;
  name: string;
};
export type EmployeeHash = {
  [id: number]: string;
};
export type Data = {
  branches: any;
  branch_hash: any;
  is_valid?: boolean;
  is_set: boolean;
  fetching?: Promise<void>;
};
export type TypelyData = {
  [type: number]: Data;
};
export type MonthlyData = {
  [yearmonth: string]: TypelyData;
};

@Module({ dynamic: true, store, name: "document_branch_monthly", namespaced: true })
class Monthly extends VuexModule {
  data: MonthlyData = {};

  @Mutation
  public set({
    auth,
    yearmonth,
    type,
    data,
  }: {
    auth: number;
    yearmonth: string;
    type: number;
    data: Data;
  }) {
    var branch_hash = null;
    if (data.branches.length != 0) {
      branch_hash = util.createHash(data.branches, "id", "name");
      if (auth == 2 || auth == 1) branch_hash[0] = "全店舗";
    }

    Vue.set(this.data[yearmonth], type, {
      branches: data.branches,
      branch_hash: branch_hash,
      is_valid: true,
      is_set: true,
      fetching: null,
    });
  }

  @Mutation
  public disable({ yearmonth, type }: { yearmonth?: string; type?: number }) {
    if (type === undefined) {
      if (yearmonth === undefined) {
        Object.keys(this.data).forEach((yearmonth) => {
          Object.keys(this.data[yearmonth]).forEach((type) => {
            this.data[yearmonth][type].is_valid = false;
          });
        });
      } else {
        if (this.data[yearmonth]) {
          Object.keys(this.data[yearmonth]).forEach((type) => {
            this.data[yearmonth][type].is_valid = false;
          });
        }
      }
    } else {
      if (this.data[yearmonth] && this.data[yearmonth][type]) {
        this.data[yearmonth][type].is_valid = false;
      }
    }
  }
  @Mutation
  public disable_all() {
    Object.keys(this.data).forEach((yearmonth) => {
      Object.keys(this.data[yearmonth]).forEach((type) => {
        this.data[yearmonth][type].is_valid = false;
      });
    });
  }
  @Mutation
  public setFetching({
    yearmonth,
    type,
    value,
  }: {
    yearmonth: string;
    type: number;
    value: Promise<void>;
  }) {
    this.data[yearmonth][type].fetching = value;
  }

  // actions
  @Action({ rawError: true })
  public async fetch({ auth, yearmonth, type }: { auth: number; yearmonth: string; type: number }) {
    //ないなら作る
    if (!this.data[yearmonth]) {
      Vue.set(this.data, yearmonth, {});
    }
    if (!this.data[yearmonth][type]) {
      Vue.set(this.data[yearmonth], type, {
        branches: [],
        branch_hash: {},
        is_valid: false,
        is_set: false,
        fetching: null,
      });
    }

    if (this.data[yearmonth][type].fetching) {
      await this.data[yearmonth][type].fetching;
    } else {
      const promise = util.post("document/read_branch_monthly", { yearmonth, type }).then((res) => {
        this.set({ auth, yearmonth, type, data: res.data });
      });
      this.setFetching({ yearmonth, type, value: promise });
      await promise;
    }
  }
}

export default getModule(Monthly);
