import Vue, { computed } from "vue";
import i18n from "@/plugins/i18n";
import { admit_auth_opt } from "@/util";

type OptionTable = { [key: number]: string };
type AuthOptionTable = [OptionTable, OptionTable, OptionTable];
type OptionTables = { [name: string]: OptionTable | AuthOptionTable };

function toI18n(table: OptionTable) {
  const result: OptionTable = {};
  for (const key in table) {
    result[key] = i18n.t(table[key]);
  }
  return result;
}

function aggregateOptions<T extends OptionTables>(originalTables: T): T {
  const resultTables = {};
  for (const key in originalTables) {
    const v = originalTables[key];
    const table = () => (Array.isArray(v) ? toI18nAuth(v) : toI18n(v));
    const computedRef = computed(table);
    Object.defineProperty(resultTables, key, {
      get() {
        return computedRef.value;
      },
    });
  }

  return resultTables as T;
}

function toI18nAuth(table: AuthOptionTable) {
  return table.map((t) => toI18n(t));
}

const hiring_type = {
  0: "parttime_employee",
  1: "fulltime_employee",
  2: "board_employee",
  3: "other_employee",
};

const auth: AuthOptionTable = [
  {},
  { 0: "auth_employee", 1: "auth_branch" },
  { 0: "auth_employee", 1: "auth_branch", 2: "auth_company" },
];
const employee_type = {
  0: "all",
  1: "shift",
  2: "shift_attendance",
  3: "payment_bonus",
  4: "payment_bonus_nencho",
  5: "nencho",
};
const is_regist = {
  0: "no_regist",
  1: "regist",
};
const is_use = {
  0: "no_use",
  1: "yes_use",
};
const is_calc = {
  0: "no_calc",
  1: "yes_calc",
};
const bank_type = {
  0: "bank_regular",
  1: "bank_current",
  2: "bank_save",
};
const sex = {
  0: "male",
  1: "female",
};
const tax_type = {
  0: "kou",
  1: "otsu",
};
const tax_type_employee = {
  0: "kou_no",
  1: "otsu_yes",
};
const householder_relationship = {
  1: "self",
  2: "husband",
  3: "wife",
  4: "father",
  5: "mother",
  6: "other",
};
const dependant_relationship = {
  1: "eldest_son",
  2: "second_son",
  3: "third_son",
  4: "forth_son",
  5: "fifth_son",
  6: "sixth_son",
  7: "eldest_daughter",
  8: "second_daughter",
  9: "third_daughter",
  10: "forth_daughter",
  11: "fifth_daughter",
  12: "sixth_daughter",
  13: "child",
  14: "father",
  15: "mother",
  16: "stepfather",
  17: "stepmother",
  18: "grandfather",
  19: "grandmother",
  20: "stepgrandfather",
  21: "stepgrandmother",
  22: "grandson",
  23: "nephew",
  24: "niece",
  25: "uncle",
  26: "aunt",
  27: "older_brother",
  28: "younger_brother",
  29: "step_older_brother",
  30: "step_younger_brother",
  31: "older_sister",
  32: "younger_sister",
  33: "step_older_sister",
  34: "step_younger_sister",
};

const disability_type = {
  0: "not_applicable",
  1: "normal_disability",
  2: "special_disability",
};

const is_applicable = {
  0: "not_applicable",
  1: "applicable",
};

const single_child = {
  0: "not_applicable",
  4: "single_child",
};

const widow_type = {
  0: "not_applicable",
  1: "widow",
  4: "single_child",
};

const is_housemate = {
  0: "not_housemate",
  1: "yes_housemate",
};

const week_prescribed_day = {
  1: "1day",
  2: "2day",
  3: "3day",
  4: "4day",
  5: "5day",
  6: "6day",
  7: "7day",
};

const carfare_type = {
  0: "there_isnt",
  1: "daily_money",
  2: "monthly_money",
};

const is = {
  0: "no",
  1: "yes",
};

const there_is = {
  0: "there_isnt",
  1: "there_is",
};

const is_either = {
  0: "yes_either",
  1: "no_either",
};

const employee_work_type = {
  0: "normal",
  2: "monthly_modifiedtime",
  4: "monthly_flextime",
};

const wage_type = {
  0: "hourly_wage",
  1: "monthly_wage",
  2: "manual",
};

const is_manual = {
  0: "auto",
  1: "manual",
};
const is_auto = {
  0: "manual",
  1: "auto",
};

const week_holiday = {
  0: "sunday",
  1: "monday",
  2: "tuesday",
  3: "wednesday",
  4: "thursday",
  5: "friday",
  6: "saturday",
  7: "holiday",
};
const week_manual = {
  0: "sunday",
  1: "monday",
  2: "tuesday",
  3: "wednesday",
  4: "thursday",
  5: "friday",
  6: "saturday",
  7: "manual",
};

const training_hourly_wage_type = {
  0: "there_isnt",
  1: "time_count",
  2: "day_count",
};

const carfare_way = {
  0: "is_train",
  1: "is_car",
};

const over_type = {
  0: "there_isnt",
  1: "normal",
  2: "deemed_over",
};

const over_way = {
  0: "preover",
  1: "lawover",
};

const health_ins_type = {
  0: "there_isnt",
  1: "normal",
  2: "union_ins",
};

const month_prescribed_day = {
  1: "1day",
  2: "2day",
  3: "3day",
  4: "4day",
  5: "5day",
  6: "6day",
  7: "7day",
  8: "8day",
  9: "9day",
  10: "10day",
  11: "11day",
  12: "12day",
  13: "13day",
  14: "14day",
  15: "15day",
  16: "16day",
  17: "17day",
  18: "18day",
  19: "19day",
  20: "20day",
  21: "21day",
  22: "22day",
  23: "23day",
  24: "24day",
  25: "25day",
  26: "26day",
  27: "27day",
  28: "28day",
  29: "29day",
  30: "30day",
  31: "31day",
};

const pay_day = {
  1: "1day",
  2: "2day",
  3: "3day",
  4: "4day",
  5: "5day",
  6: "6day",
  7: "7day",
  8: "8day",
  9: "9day",
  10: "10day",
  11: "11day",
  12: "12day",
  13: "13day",
  14: "14day",
  15: "15day",
  16: "16day",
  17: "17day",
  18: "18day",
  19: "19day",
  20: "20day",
  21: "21day",
  22: "22day",
  23: "23day",
  24: "24day",
  25: "25day",
  26: "26day",
  27: "27day",
  28: "28day",
  30: "last_day_of_the_month",
};

const paid_holiday_type = {
  0: "initial_value",
  1: "auto_give",
  2: "manual_give",
  3: "use",
  4: "buy",
  5: "timeout",
  6: "over40",
  7: "short_of_attendance",
  8: "short_of_attendance",
};
const paid_holiday_type_manual = {
  2: "manual_give",
  3: "use",
};

const transfer_type = {
  0: "auto",
  1: "comprehensive",
};

const summery_type = {
  0: "monthly",
  1: "calc_span",
};

const show_other_shift = {
  0: "only_mine",
  1: "if_is_shift",
  2: "all_members",
};

const is_hol = {
  0: "outhol",
  1: "lawhol",
};

const is_over = {
  0: "preover",
  1: "lawover",
};

const punch_round_unit = {
  0: "there_isnt",
  5: "5num",
  10: "10num",
  15: "15num",
  20: "20num",
  30: "30num",
  60: "60num",
  100: "adjust_to_shift",
};

const break_round_unit = {
  0: "there_isnt",
  5: "5num",
  10: "10num",
  15: "15num",
  20: "20num",
  30: "30num",
  60: "60num",
};

const round_type = {
  0: "math_ceil",
  1: "math_floor",
  2: "math_round",
};

const round_show = {
  0: "punch_time",
  1: "round_time",
  2: "punch_time_and_round_time",
};

const is_add = {
  0: "no_add",
  1: "yes_add",
};

const is_show = {
  0: "not_show",
  1: "show",
};

const is_deem_preover = {
  0: "count_only_lawover",
  1: "count_preover_and_lawover",
};

const is_attendance_after_join = {
  0: "config_attendance_before_join",
  1: "config_attendance_after_join",
};

const pay_month = {
  0: "this_month",
  1: "next_month",
};

const levy_month = {
  0: "levy_from_this_month",
  1: "levy_from_next_month",
};

const is_sign = {
  0: "×",
  1: "◯",
  2: "△",
};

const init_type = {
  0: "manual",
  1: "same_as_prev_month",
  2: "unit_and_num",
  3: "fixed_value",
  4: "basepay_and_num",
  5: "individual_unit_and_num",
};
const init_type_bonus = {
  0: "manual",
  1: "same_as_prev_month",
  2: "unit_and_num",
  3: "fixed_value",
  5: "individual_unit_and_num",
};
const per = {
  0: "minute",
  1: "hour",
};
const num_type = {
  0: "manual_num",
  1: "manual_hour",
  2: "prescribed_day",
  3: "sum_day",
  4: "reg_day",
  5: "lawhol_day",
  6: "outhol_day",
  7: "mid_day",
  8: "early_day",
  9: "late_day",
  10: "abs_day",
  11: "paidhol_day",
  12: "specialhol_day",
  13: "transferhol_day",
  14: "comphol_day",
  15: "prescribed_time",
  16: "sum_time",
  17: "reg_time",
  18: "lawhol_time",
  19: "outhol_time",
  20: "mid_time",
  21: "early_time",
  22: "late_time",
  23: "preover_time",
  24: "lawover_time",
};
const num_type_bonus = {
  0: "manual_num",
  1: "manual_hour",
};

const basepay_minus = {
  0: "basepay_plus_hour",
  1: "basepay_minus_hour",
  2: "basepay_plus_day",
  3: "basepay_minus_day",
};

const mutualaid_type = {
  0: "bar",
  1: "middle_mutualaid",
  2: "company_mutualaid",
  3: "personal_mutualaid",
  4: "disability_mutualaid",
};

const transfer_unit = {
  0: "company_unit",
  1: "branch_unit",
};

const alert_unit = {
  0: "there_isnt",
  5: "5minute",
  10: "10minute",
  15: "15minute",
  20: "20minute",
  25: "25minute",
  30: "30minute",
};

const alert_auth = {
  0: "myself",
  1: "auth_branch",
  2: "auth_company",
};

const admit_auth = {
  1: "auth_company",
  2: "auth_branch_and_company",
};

const send_mail_type = {
  0: "there_isnt",
  1: "auth_branch",
  2: "auth_company",
  3: "auth_branch_and_company",
};

const prev_overdemand = {
  0: "only_prev_demand",
  1: "no_limit",
};

const bank_count = {
  0: "bank_count_1",
  1: "bank_count_2",
};

const employee_info_type = {
  0: "employee_info_date_free",
  1: "employee_info_free_free",
  2: "employee_info_free",
  3: "employee_info_option",
  4: "employee_info_image",
  5: "employee_info_link",
};

const show_edit = {
  1: "only_show",
  2: "editable",
};

const no_show_edit = {
  0: "not_show",
  1: "only_show",
  2: "editable",
};

const is_able = {
  0: "unable",
  1: "able",
};

const no_edit = {
  0: "unable",
  1: "editable",
};

const is_allow = {
  0: "disallow",
  1: "allow",
};

const use_branch_bank = {
  0: "company_bank",
  1: "branch_bank",
};

const is_config = {
  0: "config_isnt",
  1: "config_is",
};

const demand_holiday_kind = {
  0: "demand_holiday_paidhol_full",
  1: "demand_holiday_paidhol_first_half",
  2: "demand_holiday_paidhol_second_half",
  4: "demand_holiday_paidhol_hourly",
  3: "demand_holiday_special_holiday",
};

const demand_holiday_status = {
  0: "demand_holiday_demanding",
  1: "demand_holiday_admit",
  2: "demand_holiday_deny",
};

const demand_holiday_hourly_paidhol = {
  1: "1hour",
  2: "2hour",
  3: "3hour",
  4: "4hour",
  5: "5hour",
  6: "6hour",
  7: "7hour",
  8: "8hour",
};

const work_type = {
  0: "work_type_normal",
  1: "work_type_outhol",
  2: "work_type_lawhol",
  3: "work_type_paidhol_full",
  4: "work_type_paidhol_half",
  5: "work_type_specialhol",
  6: "work_type_transhol",
  7: "work_type_comphol",
  8: "work_type_abs",
  9: "work_type_paidhol_hourly",
};

const over_kind = {
  0: "demand_overtime_kind_over",
  1: "demand_overtime_kind_early",
  2: "demand_overtime_kind_out_shift",
};

const demand_overtime_status = {
  0: "demand_overtime_demanding",
  1: "demand_overtime_admit",
  2: "demand_overtime_deny",
};

const plan = {
  0: "aplan",
  1: "bplan",
  2: "cplan",
};

const use_status = {
  0: "regular_use",
  1: "trial_use",
  2: "expired_use",
  3: "expired_use",
  4: "other_use",
};

const pay_method = {
  0: "pay_method_no_regist",
  1: "pay_method_card",
  2: "pay_method_debit",
  3: "pay_method_bill",
};

export default aggregateOptions({
  hiring_type,
  auth,
  employee_type,
  is_regist,
  bank_type,
  sex,
  tax_type,
  tax_type_employee,
  householder_relationship,
  dependant_relationship,
  disability_type,
  is_applicable,
  single_child,
  widow_type,
  is_housemate,
  week_prescribed_day,
  month_prescribed_day,
  carfare_type,
  is,
  there_is,
  is_either,
  employee_work_type,
  wage_type,
  week_holiday,
  training_hourly_wage_type,
  is_manual,
  is_auto,
  carfare_way,
  over_type,
  over_way,
  is_use,
  is_calc,
  health_ins_type,
  paid_holiday_type,
  paid_holiday_type_manual,
  transfer_type,
  summery_type,
  show_other_shift,
  is_hol,
  is_over,
  week_manual,
  punch_round_unit,
  round_type,
  round_show,
  break_round_unit,
  is_add,
  is_show,
  is_deem_preover,
  pay_day,
  pay_month,
  levy_month,
  is_sign,
  init_type,
  init_type_bonus,
  per,
  num_type,
  num_type_bonus,
  basepay_minus,
  mutualaid_type,
  transfer_unit,
  alert_unit,
  alert_auth,
  admit_auth,
  send_mail_type,
  prev_overdemand,
  bank_count,
  employee_info_type,
  show_edit,
  no_show_edit,
  is_able,
  no_edit,
  is_allow,
  use_branch_bank,
  is_config,
  demand_holiday_kind,
  demand_holiday_status,
  demand_holiday_hourly_paidhol,
  work_type,
  over_kind,
  demand_overtime_status,
  plan,
  use_status,
  pay_method,
  is_attendance_after_join,
});
