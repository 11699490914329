import Vue from "vue";
import * as util from "@/util";
import node from "@/vuex/node";
import router from "@/router";
import dialog from "@/vuex/dialog";
import { Flag, ProcessDescriptor } from "@/typesold/VuexControl";
import validate from "@/validations/validate";
import { each } from "jquery";
import VuexEditManager from "./VuexEditManager";

export default class VuexManagerControl {
  disp_num = Vue.observable<Flag>({});
  add_flag = Vue.observable<Flag>({});
  edit_flag = Vue.observable<Flag>({});
  dialog_flag = Vue.observable<Flag>({});
  tmp_objects = Vue.observable<any>({});
  state = Vue.observable<any>({
    reading_zip: false,
    banks: [],
    branches: [],
    banks1: [],
    branches1: [],
    banks2: [],
    branches2: [],
  });

  edit_manager = Vue.observable<{ [key: string]: VuexEditManager }>({});
  add_manager = Vue.observable<{ [key: string]: VuexEditManager }>({});

  create_descriptor: ProcessDescriptor;
  update_descriptor: ProcessDescriptor;
  delete_descriptor: ProcessDescriptor;
  document_descriptor: ProcessDescriptor;

  public initEditManager({
    name,
    val,
    object,
  }: {
    name: string;
    val?: boolean | number;
    object?: any;
  }) {
    Vue.set(this.edit_manager, name, new VuexEditManager());
    if (val !== undefined && object !== undefined) {
      this.edit_manager[name].edit(object, val);
    }
  }
  public initAddManager({ name, object }: { name: string; object?: any }) {
    Vue.set(this.add_manager, name, new VuexEditManager());
    if (object !== undefined) {
      this.add_manager[name].add(object);
    }
  }

  public initState({ name }) {
    Vue.set(this.state, name, null);
  }
  public setState({ name, val }) {
    Vue.set(this.state, name, val);
  }

  public setDispNum({ name, val }) {
    Vue.set(this.disp_num, name, val);
  }

  public setAddFlag({ name, val }) {
    Vue.set(this.add_flag, name, val);
  }

  public startAdd({ name, object }) {
    this.finishAll({ name });
    if (object) {
      this.setTmpObjects({ name: name, val: util.deep_copy(object) });
    }
    this.setAddFlag({ name, val: true });
  }

  public finishAdd({ name }) {
    this.setAddFlag({ name, val: false });
  }

  public setEditFlag({ name, val }) {
    Vue.set(this.edit_flag, name, val);
  }

  public startEdit({
    name,
    val,
    object,
    hash_key,
  }: {
    name: string;
    val: number | boolean;
    object?: any;
    hash_key?: any;
  }) {
    this.finishAll({ name });
    if (hash_key) {
      this.setTmpObjectsHash({ name: name, key: hash_key, val: util.deep_copy(object) });
    } else if (object) {
      this.setTmpObjects({ name: name, val: util.deep_copy(object) });
    }
    this.setEditFlag({ name, val });
  }

  public finishEdit({ name }) {
    this.setEditFlag({ name, val: null });
  }
  public finishAll({ name }) {
    this.finishAdd({ name });
    this.finishEdit({ name });
  }

  public setDialogFlag({ name, val }) {
    Vue.set(this.dialog_flag, name, val);
  }

  public openDialog({ name, object, objects }) {
    if (objects) {
      Object.keys(objects).forEach((key) => {
        this.setTmpObjects({ name: key, val: util.deep_copy(objects[key]) });
      });
    } else if (object) {
      this.setTmpObjects({ name: name, val: util.deep_copy(object) });
    }
    this.setDialogFlag({ name, val: true });
  }

  public closeDialog({ name }) {
    this.setDialogFlag({ name, val: false });
  }

  public setTmpObjects({ name, val }) {
    Vue.set(this.tmp_objects, name, val);
  }
  public setTmpObjectsHash({ name, key, val }) {
    var hash = {};
    val.forEach((each_val) => {
      hash[each_val[key]] = each_val;
    });
    Vue.set(this.tmp_objects, name, hash);
  }

  public setTmpObject({ name, key, val }) {
    if (this.tmp_objects[name] === undefined) Vue.set(this.tmp_objects, name, {});
    Vue.set(this.tmp_objects[name], key, val);
  }

  public isEdittingNew(): boolean {
    var is_editting = false;
    Object.keys(this.edit_manager).forEach((key) => {
      if (this.edit_manager[key].isInputting()) {
        is_editting = true;
        return;
      }
    });
    return is_editting;
  }
  public isAddingNew() {
    var is_editting = false;
    Object.keys(this.add_manager).forEach((key) => {
      if (this.add_manager[key].isInputting()) {
        is_editting = true;
        return;
      }
    });
    return is_editting;
  }
  public clearEditManager() {
    Object.keys(this.edit_manager).forEach((key) => {
      Vue.set(this.edit_manager, key, new VuexEditManager());
    });
    Object.keys(this.add_manager).forEach((key) => {
      Vue.set(this.add_manager, key, new VuexEditManager());
    });
  }

  public async isEditting() {
    const no_null_flag = Object.values(this.edit_flag).find((flag) => flag !== null);
    return no_null_flag !== undefined;
  }

  public async finishAddAll() {
    Object.keys(this.add_flag).forEach((key) => {
      this.add_flag[key] = false;
    });
    this.clearEditManager();
  }

  public async finishEditAll() {
    Object.keys(this.edit_flag).forEach((key) => {
      this.edit_flag[key] = null;
    });
    this.clearEditManager();
  }

  public cancelAll() {
    this.finishAddAll();
    this.finishEditAll();
    this.clearEditManager();
  }

  public async create({
    name,
    args,
    query,
    self,
  }: {
    name: string;
    args: any;
    query?: any;
    self?: any;
  }) {
    const create_descriptor = this.create_descriptor[name];
    if (create_descriptor.validate !== undefined) {
      if (!validate(await this.execValidation(create_descriptor.validate, self, args)))
        return false;
    }
    if (create_descriptor.dialog) {
      let soft_validate = [];
      if (create_descriptor.soft_validate !== undefined) {
        soft_validate = await this.execValidation(create_descriptor.soft_validate, self, args);
      }
      if (soft_validate.length == 0) {
        dialog.openConfirmDialog({
          msg: create_descriptor.dialog?.msg
            ? util.$t(create_descriptor.dialog?.msg)
            : util.$t("create_confirm_dialog", util.$t(create_descriptor.name)),
          detail: create_descriptor.dialog?.detail ? util.$t(create_descriptor.dialog?.detail) : "",
          cancel_button: create_descriptor.dialog?.cancel_button
            ? util.$t(create_descriptor.dialog?.cancel_button)
            : util.$t("will_not_create"),
          exec_button: create_descriptor.dialog?.exec_button
            ? util.$t(create_descriptor.dialog?.exec_button)
            : util.$t("will_create"),
          func: create_descriptor.force_dialog ? this.createConfirm : this.createProcess,
          is_next_dialog:
            create_descriptor.processing_dialog !== undefined ||
            create_descriptor.force_dialog !== undefined ||
            create_descriptor.finish_dialog !== undefined,
          args: { args, query, create_descriptor, self: this },
        });
      } else {
        dialog.openForceConfirmDialog({
          msg: create_descriptor.dialog?.msg
            ? util.$t(create_descriptor.dialog?.msg)
            : util.$t("create_confirm_dialog", util.$t(create_descriptor.name)),
          detail: soft_validate.join("<br>"),
          cancel_button: create_descriptor.dialog?.cancel_button
            ? util.$t(create_descriptor.dialog?.cancel_button)
            : util.$t("will_not_create"),
          exec_button: create_descriptor.dialog?.exec_button
            ? util.$t(create_descriptor.dialog?.exec_button)
            : util.$t("will_create"),
          func: create_descriptor.force_soft_dialog ? this.createSoftConfirm : this.createProcess,
          is_next_dialog:
            create_descriptor.processing_dialog !== undefined ||
            create_descriptor.force_soft_dialog !== undefined ||
            create_descriptor.finish_dialog !== undefined,
          args: { args, query, create_descriptor, self: this, soft_validate },
        });
      }
    } else {
      await this.createProcess({ args, query, create_descriptor, self: this, soft_validate: [] });
    }
    return true;
  }

  public async createConfirm(args) {
    const create_descriptor = args.create_descriptor;
    dialog.openForceConfirmDialog({
      msg: create_descriptor.dialog?.msg
        ? util.$t(create_descriptor.dialog?.msg)
        : util.$t("create_force_confirm_dialog", util.$t(create_descriptor.name)),
      detail:
        args.soft_validate.length == 0
          ? create_descriptor.force_dialog?.detail ?? ""
          : args.soft_validate.join("<br>"),
      cancel_button: create_descriptor.dialog?.cancel_button
        ? util.$t(create_descriptor.dialog?.cancel_button)
        : util.$t("will_not_create"),
      exec_button: create_descriptor.dialog?.exec_button
        ? util.$t(create_descriptor.dialog?.exec_button)
        : util.$t("will_create"),
      func: args.self.createProcess,
      is_next_dialog: create_descriptor.processing_dialog !== undefined,
      args: args,
    });
  }

  public async createSoftConfirm(args) {
    const create_descriptor = args.create_descriptor;
    dialog.openForceConfirmDialog({
      msg: create_descriptor.force_soft_dialog?.msg
        ? util.$t(create_descriptor.force_soft_dialog?.msg)
        : util.$t("create_force_confirm_dialog", util.$t(create_descriptor.name)),
      detail:
        args.soft_validate.length == 0
          ? create_descriptor.force_soft_dialog?.detail ?? ""
          : args.soft_validate.join("<br>"),
      cancel_button: create_descriptor.force_soft_dialog?.cancel_button
        ? util.$t(create_descriptor.force_soft_dialog?.cancel_button)
        : util.$t("will_not_create"),
      exec_button: create_descriptor.force_soft_dialog?.exec_button
        ? util.$t(create_descriptor.force_soft_dialog?.exec_button)
        : util.$t("will_create"),
      func: args.self.createProcess,
      is_next_dialog: create_descriptor.processing_dialog !== undefined,
      args: args,
    });
  }

  public async createProcess(args) {
    const create_descriptor = args.create_descriptor;
    if (create_descriptor.processing_dialog !== undefined) {
      dialog.openProgressDialog({
        title: create_descriptor.processing_dialog?.title
          ? util.$t(create_descriptor.processing_dialog?.title)
          : util.$t("making"),
        msg: create_descriptor.processing_dialog?.msg
          ? util.$t(create_descriptor.processing_dialog?.msg)
          : util.$t("processing_msg"),
      });
    }
    var res = await util.post(
      create_descriptor.url,
      args.args,
      args.create_descriptor.is_need_session ?? true,
    );
    if (res !== null) {
      if (create_descriptor.continue_edit === undefined || !create_descriptor.continue_edit) {
        args.self.finishEditAll();
      }
      node.disable();
      if (create_descriptor.finish_dialog !== undefined) {
        dialog.openAlertDialog({
          msg: create_descriptor.finish_dialog?.msg
            ? util.$t(create_descriptor.finish_dialog?.msg)
            : util.$t("create_finish"),
        });
      } else {
        dialog.closeDialog();
      }
      if (create_descriptor.routing !== undefined) {
        var query = util.adjustQuery(
          args.query,
          create_descriptor.routing?.plus_args,
          create_descriptor.routing?.minus_args,
        );
        router.push({ name: create_descriptor.routing?.name, query: query });
      }
      if (create_descriptor.plus !== undefined) {
        create_descriptor.plus?.func(create_descriptor.plus?.args, res, args.self);
      }
      if (create_descriptor.close_dialog) {
        create_descriptor.close_dialog.forEach((key) => {
          args.self.closeDialog({ name: key });
        });
      }
    }
  }

  public async update({
    name,
    args,
    query,
    self,
  }: {
    name: string;
    args: any;
    query?: any;
    self?: any;
  }) {
    const update_descriptor = this.update_descriptor[name];
    if (update_descriptor.validate !== undefined) {
      if (!validate(await this.execValidation(update_descriptor.validate, self, args)))
        return false;
    }

    if (update_descriptor.dialog) {
      let soft_validate = [];
      if (update_descriptor.soft_validate !== undefined) {
        soft_validate = await this.execValidation(update_descriptor.soft_validate, self, args);
      }
      if (soft_validate.length == 0) {
        dialog.openConfirmDialog({
          msg: update_descriptor.dialog?.msg
            ? util.$t(update_descriptor.dialog?.msg)
            : update_descriptor.name + "を更新してよろしいですか？",
          detail: update_descriptor.dialog?.detail ? util.$t(update_descriptor.dialog?.detail) : "",
          cancel_button: update_descriptor.dialog?.cancel_button
            ? util.$t(update_descriptor.dialog?.cancel_button)
            : "更新しない",
          exec_button: update_descriptor.dialog?.exec_button
            ? util.$t(update_descriptor.dialog?.exec_button)
            : "更新する",
          func: update_descriptor.force_dialog ? this.updateConfirm : this.updateProcess,
          is_next_dialog:
            update_descriptor.processing_dialog !== undefined ||
            update_descriptor.force_dialog !== undefined ||
            update_descriptor.finish_dialog !== undefined,
          args: { args, query, update_descriptor, self: this },
        });
      } else {
        dialog.openForceConfirmDialog({
          msg: update_descriptor.dialog?.msg
            ? util.$t(update_descriptor.dialog?.msg)
            : update_descriptor.name + "を更新してよろしいですか？",
          detail: soft_validate.join("<br>"),
          cancel_button: update_descriptor.dialog?.cancel_button
            ? util.$t(update_descriptor.dialog?.cancel_button)
            : "更新しない",
          exec_button: update_descriptor.dialog?.exec_button
            ? util.$t(update_descriptor.dialog?.exec_button)
            : "更新する",
          func: update_descriptor.force_soft_dialog ? this.updateSoftConfirm : this.updateProcess,
          is_next_dialog:
            update_descriptor.processing_dialog !== undefined ||
            update_descriptor.force_soft_dialog !== undefined ||
            update_descriptor.finish_dialog !== undefined,
          args: { args, query, update_descriptor, self: this, soft_validate },
        });
      }
    } else {
      await this.updateProcess({ args, query, update_descriptor, self: this, soft_validate: [] });
    }
    return true;
  }

  public async updateConfirm(args) {
    const update_descriptor = args.update_descriptor;
    dialog.openForceConfirmDialog({
      msg: update_descriptor.force_dialog?.msg
        ? util.$t(update_descriptor.force_dialog?.msg)
        : "本当に" + update_descriptor.name + "を更新してよろしいですか？",
      detail:
        args.soft_validate.length == 0
          ? update_descriptor.force_dialog?.detail
            ? util.$t(update_descriptor.force_dialog?.detail)
            : ""
          : args.soft_validate.join("<br>"),
      cancel_button: update_descriptor.force_dialog?.cancel_button
        ? util.$t(update_descriptor.force_dialog?.cancel_button)
        : "更新しない",
      exec_button: update_descriptor.force_dialog?.exec_button
        ? util.$t(update_descriptor.force_dialog?.exec_button)
        : "更新する",
      func: args.self.updateProcess,
      is_next_dialog: update_descriptor.processing_dialog !== undefined,
      args: args,
    });
  }
  public async updateSoftConfirm(args) {
    const update_descriptor = args.update_descriptor;
    dialog.openForceConfirmDialog({
      msg: update_descriptor.force_soft_dialog?.msg
        ? util.$t(update_descriptor.force_soft_dialog?.msg)
        : "本当に" + update_descriptor.name + "を更新してよろしいですか？",
      detail:
        args.soft_validate.length == 0
          ? update_descriptor.force_soft_dialog?.detail
            ? util.$t(update_descriptor.force_soft_dialog?.detail)
            : ""
          : args.soft_validate.join("<br>"),
      cancel_button: update_descriptor.force_soft_dialog?.cancel_button
        ? util.$t(update_descriptor.force_soft_dialog?.cancel_button)
        : "更新しない",
      exec_button: update_descriptor.force_soft_dialog?.exec_button
        ? util.$t(update_descriptor.force_soft_dialog?.exec_button)
        : "更新する",
      func: args.self.updateProcess,
      is_next_dialog: update_descriptor.processing_dialog !== undefined,
      args: args,
    });
  }

  public async updateProcess(args) {
    const update_descriptor = args.update_descriptor;
    if (update_descriptor.processing_dialog !== undefined) {
      dialog.openProgressDialog({
        title: update_descriptor.processing_dialog?.title
          ? util.$t(update_descriptor.processing_dialog?.title)
          : "更新中",
        msg: update_descriptor.processing_dialog?.msg ?? util.$t("processing_msg"),
      });
    }
    var res = await util.post(
      update_descriptor.url,
      args.args,
      args.update_descriptor.is_need_session ?? true,
    );
    if (res !== null) {
      if (update_descriptor.continue_edit === undefined || !update_descriptor.continue_edit) {
        args.self.finishEditAll();
      }
      node.disable();
      if (update_descriptor.finish_dialog !== undefined) {
        dialog.openAlertDialog({
          msg: update_descriptor.finish_dialog?.msg
            ? util.$t(update_descriptor.finish_dialog?.msg)
            : "更新が完了しました",
        });
      } else {
        dialog.closeDialog();
      }
      if (update_descriptor.routing !== undefined) {
        var query = util.adjustQuery(
          args.query,
          update_descriptor.routing?.plus_args,
          update_descriptor.routing?.minus_args,
        );
        router.push({ name: update_descriptor.routing?.name, query: query });
      }
      if (update_descriptor.plus !== undefined) {
        update_descriptor.plus?.func(update_descriptor.plus?.args, res, args.self);
      }
      if (update_descriptor.close_dialog) {
        update_descriptor.close_dialog.forEach((key) => {
          args.self.closeDialog({ name: key });
        });
      }
    }
  }

  public async delete({
    name,
    args,
    query,
    self,
  }: {
    name: string;
    args: any;
    query?: any;
    self?: any;
  }) {
    const delete_descriptor = this.delete_descriptor[name];
    if (delete_descriptor.validate !== undefined) {
      if (!validate(await this.execValidation(delete_descriptor.validate, self, args)))
        return false;
    }

    if (delete_descriptor.dialog) {
      let soft_validate = [];
      if (delete_descriptor.soft_validate !== undefined) {
        soft_validate = await this.execValidation(delete_descriptor.soft_validate, self, args);
      }
      dialog.openForceConfirmDialog({
        msg: delete_descriptor.dialog?.msg ?? delete_descriptor.name + "を削除してよろしいですか？",
        detail:
          soft_validate.length == 0
            ? delete_descriptor.dialog?.detail ?? ""
            : soft_validate.join("<br>"),
        cancel_button: delete_descriptor.dialog?.cancel_button ?? "削除しない",
        exec_button: delete_descriptor.dialog?.exec_button ?? "削除する",
        func: delete_descriptor.force_soft_dialog ? this.deleteSoftConfirm : this.deleteProcess,
        is_next_dialog:
          delete_descriptor.processing_dialog !== undefined ||
          delete_descriptor.force_soft_dialog !== undefined ||
          delete_descriptor.finish_dialog !== undefined,
        args: { args, query, delete_descriptor, self: this, soft_validate },
      });
    } else {
      await this.deleteProcess({ args, query, delete_descriptor, self: this, soft_validate: [] });
    }
    return true;
  }

  public async deleteConfirm(args) {
    const delete_descriptor = args.delete_descriptor;
    dialog.openForceConfirmDialog({
      msg:
        delete_descriptor.force_dialog?.msg ??
        "本当に" + delete_descriptor.name + "を削除してよろしいですか？",
      detail:
        args.soft_validate.length == 0
          ? delete_descriptor.force_dialog?.detail ?? ""
          : args.soft_validate.join("<br>"),
      cancel_button: delete_descriptor.force_dialog?.cancel_button ?? "削除しない",
      exec_button: delete_descriptor.force_dialog?.exec_button ?? "削除する",
      func: args.self.deleteProcess,
      is_next_dialog: delete_descriptor.processing_dialog !== undefined,
      args: args,
    });
  }

  public async deleteSoftConfirm(args) {
    const delete_descriptor = args.delete_descriptor;
    dialog.openForceConfirmDialog({
      msg:
        delete_descriptor.force_soft_dialog?.msg ??
        "本当に" + delete_descriptor.name + "を削除してよろしいですか？",
      detail:
        args.soft_validate.length == 0
          ? delete_descriptor.force_soft_dialog?.detail ?? ""
          : args.soft_validate.join("<br>"),
      cancel_button: delete_descriptor.force_soft_dialog?.cancel_button ?? "削除しない",
      exec_button: delete_descriptor.force_soft_dialog?.exec_button ?? "削除する",
      func: args.self.deleteProcess,
      is_next_dialog: delete_descriptor.processing_dialog !== undefined,
      args: args,
    });
  }

  public async deleteProcess(args) {
    const delete_descriptor = args.delete_descriptor;
    if (delete_descriptor.processing_dialog !== undefined) {
      dialog.openProgressDialog({
        title: delete_descriptor.processing_dialog?.title ?? "削除中",
        msg: delete_descriptor.processing_dialog?.msg ?? util.$t("processing_msg"),
      });
    }
    var res = await util.post(
      delete_descriptor.url,
      args.args,
      args.delete_descriptor.is_need_session ?? true,
    );
    if (res !== null) {
      if (delete_descriptor.continue_edit === undefined || !delete_descriptor.continue_edit) {
        args.self.finishEditAll();
      }
      node.disable();
      if (delete_descriptor.finish_dialog !== undefined) {
        dialog.openAlertDialog({
          msg: delete_descriptor.finish_dialog?.msg ?? "削除が完了しました",
        });
      } else {
        dialog.closeDialog();
      }
      if (delete_descriptor.routing !== undefined) {
        var query = util.adjustQuery(
          args.query,
          delete_descriptor.routing?.plus_args,
          delete_descriptor.routing?.minus_args,
        );
        router.push({ name: delete_descriptor.routing?.name, query: query });
      }
      if (delete_descriptor.plus !== undefined) {
        delete_descriptor.plus?.func(delete_descriptor.plus?.args, res, args.self);
      }
      if (delete_descriptor.close_dialog) {
        delete_descriptor.close_dialog.forEach((key) => {
          this.closeDialog({ name: key });
        });
      }
    }
  }

  public async document({ name, args }) {
    const document_descriptor = this.document_descriptor[name];
    if (document_descriptor.processing_dialog !== undefined) {
      dialog.openProgressDialog({
        title: document_descriptor.processing_dialog?.title ?? "作成中",
        msg: document_descriptor.processing_dialog?.msg ?? "作成に時間がかかることがあります。",
        detail: document_descriptor.processing_dialog?.detail ?? "そのままお待ちください。",
      });
    }
    dialog.openProgressDialog({
      title: "作成中",
      msg: "作成に時間がかかることがあります。",
      detail: "そのままお待ちください。",
    });

    if (document_descriptor.rollover) {
      await util.post_document_split(
        document_descriptor.url,
        args,
        document_descriptor.name,
        document_descriptor.type,
        document_descriptor.rollover.key,
        args[document_descriptor.rollover.key],
        document_descriptor.rollover.count,
      );
    } else {
      await util.post_document(
        document_descriptor.url,
        args,
        document_descriptor.name + "." + document_descriptor.type,
        document_descriptor.type,
      );
    }
  }

  public async execValidation(validation, self, args) {
    var error = [];
    if (Array.isArray(validation)) {
      for (var i = 0; i < validation.length; i++) {
        error = error.concat(await this.execValidationEach(validation[i], self, args));
      }
    } else {
      error = await this.execValidationEach(validation, self, args);
    }
    return error.filter((each_error) => each_error !== true);
  }
  public async execValidationEach(validation, self, args) {
    let res = validation(self, args);
    if (res instanceof Promise) {
      return await res;
    } else {
      return res;
    }
  }
}
