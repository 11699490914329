import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import Vue from "vue";
import global from "@/vuex/nencho/global";

export type EmployeeHash = {
  [id: number]: string;
};
export type LockTypeHash = {
  [id: number]: number;
};
export type NenchoErrorHash = {
  [id: number]: {};
};
export type BranchObject = {
  [id: number]: string;
};
export type Data = {
  employee: any;
  employee_hash: any;
  branch: any;
  branch_hash: any;
  branch_hash_all: any;
  nencho_masters: any;
  is12Locked: boolean;
  is_valid?: boolean;
  is_set: boolean;
  fetching?: Promise<void>;
};
export type BranchlyData = {
  [branch: number]: Data;
};
export type YearlyData = {
  [nencho_id: number]: BranchlyData;
};

@Module({ dynamic: true, store, name: "nencho_yearly_data", namespaced: true })
class NenchoYearly extends VuexModule {
  data: YearlyData = {};

  @Mutation
  public set({ nencho_id, branch_id, data }: { nencho_id: number; branch_id: number; data }) {
    Vue.set(this.data[nencho_id], branch_id, {
      employee_hash: util.createHashObject(data.employees, "id"),
      branch_hash: util.createHashReal(data.branches, "id", "name"),
      branch_hash_all: util.createHash(data.branches, "id", "name"),
      nencho_masters: data.nencho_masters,
      is12Locked: data.is12Locked,
      is_valid: true,
      is_set: true,
      fetching: null,
    });
  }
  @Mutation
  public disable({ nencho_id, branch_id }: { nencho_id: number; branch_id: number }) {
    if (branch_id === undefined || branch_id == 0) {
      if (this.data[nencho_id]) {
        Object.keys(this.data[nencho_id]).forEach((branch_id) => {
          this.data[nencho_id][branch_id].is_valid = false;
        });
      }
    } else {
      if (this.data[nencho_id] && this.data[nencho_id][branch_id]) {
        this.data[nencho_id][branch_id].is_valid = false;
      }
    }
    if (this.data[nencho_id] && this.data[nencho_id][0]) {
      this.data[nencho_id][0].is_valid = false;
    }
  }
  @Mutation
  public disable_all() {
    Object.keys(this.data).forEach((nencho_id) => {
      Object.keys(this.data[nencho_id]).forEach((branch_id) => {
        this.data[nencho_id][branch_id].is_valid = false;
      });
    });
  }
  @Mutation
  public setFetching({
    nencho_id,
    branch_id,
    value,
  }: {
    nencho_id: number;
    branch_id: number;
    value: Promise<void>;
  }) {
    this.data[nencho_id][branch_id].fetching = value;
  }

  // actions
  @Action({ rawError: true })
  public async fetch({ branch_id, nencho_id }: { branch_id: number; nencho_id: number }) {
    //ないなら作る
    if (!this.data[nencho_id]) {
      Vue.set(this.data, nencho_id, {});
    }
    if (!this.data[nencho_id][branch_id]) {
      Vue.set(this.data[nencho_id], branch_id, {
        employee_hash: {},
        branch_hash: {},
        branch_hash_all: {},
        nencho_masters: [],
        is12Locked: false,
        is_valid: false,
        is_set: false,
        fetching: null,
      });
    }

    if (this.data[nencho_id][branch_id].fetching) {
      await this.data[nencho_id][branch_id].fetching;
    } else {
      const promise = util.post("nencho/read_yearly", { branch_id, nencho_id }).then((res) => {
        this.set({ nencho_id, branch_id, data: res.data });
      });
      this.setFetching({ nencho_id, branch_id, value: promise });
      await promise;
    }
  }
}

export default getModule(NenchoYearly);
