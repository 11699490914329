import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import Vue from "vue";
import global from "@/vuex/fixed_tax_reduction/global";

export type EmployeeHash = {
  [id: number]: string;
};
export type LockTypeHash = {
  [id: number]: number;
};
export type NenchoErrorHash = {
  [id: number]: {};
};
export type BranchObject = {
  [id: number]: string;
};
export type Data = {
  employee: any;
  employee_hash: any;
  branch: any;
  branch_hash: any;
  branch_hash_all: any;
  fixed_tax_reduction_masters: any;
  payment_and_bonuses: any;
  fixed_tax_reduction_histories_hash: any;
  editable: boolean;
  is_valid?: boolean;
  is_set: boolean;
  fetching?: Promise<void>;
};
export type BranchlyData = {
  [branch: number]: Data;
};
export type YearlyData = {
  [fixed_tax_reduction_id: number]: BranchlyData;
};

@Module({ dynamic: true, store, name: "fixed_tax_reduction_yearly_data", namespaced: true })
class NenchoYearly extends VuexModule {
  data: YearlyData = {};

  @Mutation
  public set({
    fixed_tax_reduction_id,
    branch_id,
    data,
  }: {
    fixed_tax_reduction_id: number;
    branch_id: number;
    data;
  }) {
    Vue.set(this.data[fixed_tax_reduction_id], branch_id, {
      employee_hash: util.createHashObject(data.employees, "id"),
      branch_hash: util.createHashReal(data.branches, "id", "name"),
      branch_hash_all: util.createHash(data.branches, "id", "name"),
      fixed_tax_reduction_masters: data.fixed_tax_reduction_masters,
      payment_and_bonuses: data.payment_and_bonuses,
      fixed_tax_reduction_histories_hash: data.fixed_tax_reduction_histories_hash,
      editable: data.editable,
      is_valid: true,
      is_set: true,
      fetching: null,
    });
  }
  @Mutation
  public disable({
    fixed_tax_reduction_id,
    branch_id,
  }: {
    fixed_tax_reduction_id: number;
    branch_id: number;
  }) {
    if (branch_id === undefined || branch_id == 0) {
      if (this.data[fixed_tax_reduction_id]) {
        Object.keys(this.data[fixed_tax_reduction_id]).forEach((branch_id) => {
          this.data[fixed_tax_reduction_id][branch_id].is_valid = false;
        });
      }
    } else {
      if (this.data[fixed_tax_reduction_id] && this.data[fixed_tax_reduction_id][branch_id]) {
        this.data[fixed_tax_reduction_id][branch_id].is_valid = false;
      }
    }
    if (this.data[fixed_tax_reduction_id] && this.data[fixed_tax_reduction_id][0]) {
      this.data[fixed_tax_reduction_id][0].is_valid = false;
    }
  }
  @Mutation
  public disable_all() {
    Object.keys(this.data).forEach((fixed_tax_reduction_id) => {
      Object.keys(this.data[fixed_tax_reduction_id]).forEach((branch_id) => {
        this.data[fixed_tax_reduction_id][branch_id].is_valid = false;
      });
    });
  }
  @Mutation
  public setFetching({
    fixed_tax_reduction_id,
    branch_id,
    value,
  }: {
    fixed_tax_reduction_id: number;
    branch_id: number;
    value: Promise<void>;
  }) {
    this.data[fixed_tax_reduction_id][branch_id].fetching = value;
  }

  // actions
  @Action({ rawError: true })
  public async fetch({
    branch_id,
    fixed_tax_reduction_id,
  }: {
    branch_id: number;
    fixed_tax_reduction_id: number;
  }) {
    //ないなら作る
    if (!this.data[fixed_tax_reduction_id]) {
      Vue.set(this.data, fixed_tax_reduction_id, {});
    }
    if (!this.data[fixed_tax_reduction_id][branch_id]) {
      Vue.set(this.data[fixed_tax_reduction_id], branch_id, {
        employee_hash: {},
        branch_hash: {},
        branch_hash_all: {},
        fixed_tax_reduction_masters: [],
        payment_and_bonuses: [],
        fixed_tax_reduction_histories_hash: {},
        editable: false,
        is_valid: false,
        is_set: false,
        fetching: null,
      });
    }

    if (this.data[fixed_tax_reduction_id][branch_id].fetching) {
      await this.data[fixed_tax_reduction_id][branch_id].fetching;
    } else {
      const promise = util
        .post("fixed_tax_reduction/read_yearly", { branch_id, fixed_tax_reduction_id })
        .then((res) => {
          this.set({ fixed_tax_reduction_id, branch_id, data: res.data });
        });
      this.setFetching({ fixed_tax_reduction_id, branch_id, value: promise });
      await promise;
    }
  }
}

export default getModule(NenchoYearly);
