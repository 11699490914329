import { render, staticRenderFns } from "./LogoSpin.vue?vue&type=template&id=ae77dc1e"
import script from "./LogoSpin.vue?vue&type=script&lang=ts&setup=true"
export * from "./LogoSpin.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./LogoSpin.vue?vue&type=style&index=0&id=ae77dc1e&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports