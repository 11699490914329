import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import Vue from "vue";
import Need_pattern from "@/typesold/need_pattern";

export type Data = {
  need_patterns: Need_pattern[];
  need_pattern_option;
  is_valid?: boolean;
  is_set: boolean;
  fetching?: Promise<void>;
};
export type BranchlyData = {
  [branch: number]: Data;
};

@Module({ dynamic: true, store, name: "need_global_data", namespaced: true })
class Need_global extends VuexModule {
  data: BranchlyData = {};

  @Mutation
  public set({ branch_id, data }: { branch_id: number; data: Data }) {
    var need_pattern_option = util.createHash(data.need_patterns, "id", "name");
    need_pattern_option = {
      0: "リセット",
      ...need_pattern_option,
    };
    Vue.set(this.data, branch_id, {
      need_patterns: data.need_patterns,
      need_pattern_option: need_pattern_option,
      is_valid: true,
      is_set: true,
      fetching: null,
    });
  }

  @Mutation
  public createNeedPattern({
    branch_id,
    need_pattern,
  }: {
    branch_id: number;
    need_pattern: Need_pattern;
  }) {
    need_pattern = {
      ...need_pattern,
      need_pattern_time: need_pattern.need_pattern_time.filter(
        (need_pattern_time) => need_pattern_time.started_at || need_pattern_time.ended_at,
      ),
    };

    this.data[branch_id].need_patterns.push(need_pattern);
  }
  @Mutation
  public updateNeedPattern({
    branch_id,
    need_pattern_id,
    need_pattern,
  }: {
    branch_id: number;
    need_pattern_id: number;
    need_pattern: Need_pattern;
  }) {
    need_pattern = {
      ...need_pattern,
      need_pattern_time: need_pattern.need_pattern_time.filter(
        (need_pattern_time) => need_pattern_time.started_at || need_pattern_time.ended_at,
      ),
    };

    this.data[branch_id].need_patterns.forEach((v, i, a) => {
      if (v.id == need_pattern_id) {
        a[i] = need_pattern;
      }
    });
  }
  @Mutation
  public deleteNeedPattern({
    branch_id,
    need_pattern_id,
  }: {
    branch_id: number;
    need_pattern_id: number;
  }) {
    this.data[branch_id].need_patterns = this.data[branch_id].need_patterns.filter(
      (need_pattern) => need_pattern.id != need_pattern_id,
    );
  }
  @Mutation
  public disable({ branch_id }: { branch_id?: number }) {
    if (branch_id === undefined || branch_id == 0) {
      Object.keys(this.data).forEach((branch_id) => {
        this.data[branch_id].is_valid = false;
      });
    } else {
      if (this.data[branch_id]) {
        this.data[branch_id].is_valid = false;
      }
    }
  }
  @Mutation
  public disable_all() {
    Object.keys(this.data).forEach((branch_id) => {
      this.data[branch_id].is_valid = false;
    });
  }

  @Mutation
  public setFetching({ branch_id, value }: { branch_id: number; value: Promise<void> }) {
    this.data[branch_id].fetching = value;
  }

  // actions
  @Action({ rawError: true })
  public async fetch({ branch_id }: { branch_id: number }) {
    if (!this.data[branch_id]) {
      Vue.set(this.data, branch_id, {
        need_patterns: [],
        need_pattern_option: {},
        is_valid: false,
        is_set: false,
        fetching: null,
      });
    }

    if (this.data[branch_id] && this.data[branch_id].fetching) {
      await this.data[branch_id].fetching;
    } else {
      const promise = util.post("shift/read_need_global", { branch_id }).then((res) => {
        this.set({ branch_id, data: res.data });
      });
      this.setFetching({ branch_id, value: promise });
      await promise;
    }
  }
}

export default getModule(Need_global);
