
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import utilMixins from '@/mixins';

@Component({
  components: {
  }
})
export default class Wizard extends Mixins(utilMixins) {

  get step() {
    if (this.isNowPage('wizard_config')) {
      return 1;
    } else if (this.isNowPage('wizard_employee')) {
      return 2;
    } else {
      return 0;
    }
  }
}
