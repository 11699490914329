
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import utilMixins from "@/mixins";

  import { EmployeeHeaderData, BranchHeaderData, CompanyHeaderData } from "@/typesold/header";

  @Component({
    components: {},
  })
  export default class Todo extends Mixins(utilMixins) {
    is_visible_todo = false;

    is_visible_fixed_tax_reduction = true;
    is_visible_renew_resident_tax = true;
    is_visible_renew_labor_ins = true;
    is_visible_renew_std_income = true;
    is_visible_nencho = true;

    created() {
      const year = new Date().getFullYear();
      if (
        util.getLocalStorage("todo_fixed_tax_reduction") &&
        util.getLocalStorage("todo_fixed_tax_reduction") >= year
      ) {
        this.is_visible_fixed_tax_reduction = false;
      }
      if (
        util.getLocalStorage("todo_renew_resident_tax") &&
        util.getLocalStorage("todo_renew_resident_tax") >= year
      ) {
        this.is_visible_renew_resident_tax = false;
      }
      if (
        util.getLocalStorage("todo_renew_labor_ins") &&
        util.getLocalStorage("todo_renew_labor_ins") >= year
      ) {
        this.is_visible_renew_labor_ins = false;
      }
      if (
        util.getLocalStorage("todo_renew_std_income") &&
        util.getLocalStorage("todo_renew_std_income") >= year
      ) {
        this.is_visible_renew_std_income = false;
      }
      if (util.getLocalStorage("todo_nencho") && util.getLocalStorage("todo_nencho") >= year) {
        this.is_visible_nencho = false;
      }
    }

    get todolist() {
      const todolist = [];
      const header = this.header as BranchHeaderData | CompanyHeaderData;
      if (this.s_auth == 2 && this.is_visible_fixed_tax_reduction)
        todolist.push({
          title: "定額減税",
          func: this.click_fixed_tax_reduction,
          hide_func: "fixed_tax_reduction",
        });
      if (header.is_renew_resident_tax && this.is_visible_renew_resident_tax)
        todolist.push({
          title: "住民税改定",
          func: this.click_renew_resident_tax,
          hide_func: "renew_resident_tax",
        });
      if (header.is_renew_labor_ins && this.is_visible_renew_labor_ins)
        todolist.push({
          title: "労働保険改定",
          func: this.click_renew_labor_ins,
          hide_func: "renew_labor_ins",
        });
      if (header.is_renew_std_income && this.is_visible_renew_std_income)
        todolist.push({
          title: "標準報酬月額改定",
          func: this.click_renew_std_income,
          hide_func: "renew_std_income",
        });
      if (header.is_nencho && this.is_visible_nencho)
        todolist.push({ title: "年末調整", func: this.click_nencho, hide_func: "nencho" });
      if (header.is_demand_overtime)
        todolist.push({ title: "残業承認", func: this.click_demand_overtime });
      if (header.is_demand_holiday)
        todolist.push({ title: "休暇承認", func: this.click_demand_holiday });
      if (header.is_demand_punch)
        todolist.push({ title: "打刻修正承認", func: this.click_demand_punch });
      if (header.is_demand_change)
        todolist.push({ title: "変更承認", func: this.click_demand_change });
      if (header.is_join) todolist.push({ title: "入社承認", func: this.click_join });
      return todolist;
    }

    change_visible_todo() {
      this.is_visible_todo = !this.is_visible_todo;
    }

    click_fixed_tax_reduction() {
      this.change_page("fixedtaxreduction");
    }
    click_renew_resident_tax() {
      this.change_page("bundle", { bundle_tab: "resident" });
    }
    click_renew_labor_ins() {
      this.change_page("laborins");
    }
    click_renew_std_income() {
      this.change_page("renew_stdincome");
    }
    click_nencho() {
      this.change_page("nencho");
    }
    click_demand_overtime() {
      this.change_page("employee", { employee_tab: "order" });
    }
    click_demand_holiday() {
      this.change_page("employee", { employee_tab: "order" });
    }
    click_demand_punch() {
      this.change_page("employee", { employee_tab: "order" });
    }
    click_demand_change() {
      this.change_page("employee", { employee_tab: "order" });
    }
    click_join() {
      this.change_page("employee", { employee_tab: "join" });
    }

    hide_func(name) {
      util.setLocalStorage(`todo_${name}`, new Date().getFullYear());
      this[`is_visible_${name}`] = false;
    }
  }
