import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import header from "@/vuex/header";
import node from "@/vuex/node";

function validate(errors: (false | string)[]) {
  const filtered = errors.filter((v) => v !== false);
  if (filtered.length > 0) throw { type: "validation_error", error: filtered };
}

@Module({ dynamic: true, store, name: "login", namespaced: true })
class SessionLogin extends VuexModule {
  // actions

  //使用していない！！！！！！

  @Action({ rawError: true })
  public async login({
    company_code,
    mail,
    pass,
    auth,
  }: {
    company_code: string;
    mail: string;
    pass: string;
    auth: number;
  }) {
    validate([
      mail == "" && "メールアドレスが入力されていません",
      pass == "" && "パスワードが入力されていません",
    ]);
    const res = await util.post("login/login", { company_code, mail, pass, auth }, false);
    if (res.data.code != 0) throw { type: "login_error" };
    else {
      header.disable({});
      await node.disable_all();
    }
  }

  @Action({ rawError: true })
  public async logout() {
    const token = util.getLocalStorage("app_token");
    const res = await util.post("login/logout", { token }, false);
    const redirectUrl = new URL(res.data.redirect_url);
    location.href = `${location.origin}/auth${redirectUrl.pathname}`;
  }

  @Action({ rawError: true })
  public async punch_logout() {
    await util.post("punch/logout_legacy", {}, false);
  }
}

export default getModule(SessionLogin);
