/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */

export default [
  {
    path: "/order/regist",
    name: "OrderRegist",
    view: "Order/Regist",
  },
  {
    path: "/order/change_pay_method",
    name: "OrderChangePayMethod",
    view: "Order/ChangePayMethod",
  },
  {
    path: "/order/update_card",
    name: "OrderUpdateCard",
    view: "Order/UpdateCard",
  },
  {
    path: "/order/change_plan",
    name: "OrderChangePlan",
    view: "Order/ChangePlan",
  },

  {
    path: "/login/:company_code",
    name: "login",
    view: "LoginLegacy",
  },
  {
    path: "/login_legacy/:company_code",
    name: "loginlegacy",
    view: "LoginLegacy",
  },
  {
    path: "/apploginbefore",
    name: "apploginbefore",
    view: "Apploginbefore",
  },
  {
    path: "/applogin",
    name: "applogin",
    view: "Applogin",
  },
  {
    path: "/switchlogin",
    name: "switchlogin",
    view: "SwitchLogin",
  },
  {
    path: "/postlogout",
    name: "postlogout",
    view: "Postlogout",
  },
  {
    path: "/loginexchanged",
    name: "loginexchanged",
    view: "LoginExchanged",
  },
  {
    path: "/illegal",
    name: "illegal",
    view: "Illegal",
  },
  {
    path: "/join_illegal",
    name: "join_illegal",
    view: "Joinillegal",
  },
  {
    path: "/already_join",
    name: "already_join",
    view: "Already_join",
  },

  {
    path: "/company",
    name: "company",
    view: "Company",
  },

  {
    path: "/status",
    name: "Status",
    view: "Status",
  },
  {
    path: "/status/receipt",
    name: "StatusReceipt",
    view: "StatusReceipt",
  },

  {
    path: "/employee",
    name: "employee",
    view: "Employee",
  },

  {
    path: "/employee_each",
    name: "employee_each",
    view: "Employee_each",
  },
  {
    path: "/employee_change/:employee_id/:ch_employee_id",
    name: "employee_change",
    view: "Employee_each",
  },
  {
    path: "/employee_add",
    name: "employee_add",
    view: "Employee_add",
  },
  {
    path: "/employee_demand",
    name: "employee_demand",
    view: "Employee_demand",
  },
  {
    path: "/employee_join_content",
    name: "employee_join_content",
    view: "Employee_join_content",
  },
  {
    path: "/employee_admit",
    name: "employee_admit",
    view: "Employee_admit",
  },
  {
    path: "/spouce",
    name: "spouce",
    view: "Spouce",
  },
  {
    path: "/dependant",
    name: "dependant",
    view: "Dependant",
  },
  {
    path: "/config",
    name: "config",
    view: "Config",
  },
  {
    path: "/demand_holiday",
    name: "demand_holiday",
    view: "Demand_holiday",
  },
  {
    path: "/demand_overtime",
    name: "demand_overtime",
    view: "Demand_overtime",
  },
  {
    path: "/demand_punch",
    name: "demand_punch",
    view: "Attendance_each",
  },
  {
    path: "/punch",
    name: "punch",
    view: "Punch",
  },
  {
    path: "/punch_inside",
    name: "punch_inside",
    view: "Punch",
  },

  //入社用
  {
    path: "/join",
    name: "join",
    view: "Join",
  },
  {
    path: "/demand_change",
    name: "demand_change",
    view: "Demand_change",
  },

  {
    path: "/employee_change_wizard",
    name: "change_employee",
    view: "Join_employee",
  },

  //メインページ
  {
    path: "/shift",
    name: "shift",
    view: "Shift",
  },
  {
    path: "/submit",
    name: "submit",
    view: "Submit",
  },
  {
    path: "/attendance",
    name: "attendance",
    view: "Attendance",
  },
  {
    path: "/attendance_each",
    name: "attendance_each",
    view: "Attendance_each",
  },
  {
    path: "/payment",
    name: "payment",
    view: "Payment",
  },
  {
    path: "/payment_each",
    name: "payment_each",
    view: "Payment_each",
  },

  {
    path: "/bonus",
    name: "bonus",
    view: "Bonus",
  },
  {
    path: "/bonus_each",
    name: "bonus_each",
    view: "Bonus_each",
  },

  {
    path: "/aggregation",
    name: "aggregation",
    view: "Aggregation",
  },
  {
    path: "/notification",
    name: "notification",
    view: "Notification",
  },
  {
    path: "/document",
    name: "document",
    view: "Document",
  },
  {
    path: "/other/:tab",
    name: "other",
    view: "Other",
  },
  {
    path: "/wizard_config",
    name: "wizard_config",
    view: "Wizard_config",
  },
  {
    path: "/wizard_employee",
    name: "wizard_employee",
    view: "Wizard_employee",
  },

  //年末調整
  {
    path: "/nencho",
    name: "nencho",
    view: "Nencho",
  },
  {
    path: "/nencho/master",
    name: "nencho_master",
    view: "Nencho_master",
  },
  {
    path: "/nencho/employee",
    name: "nencho_employee",
    view: "Nencho_employee",
  },
  {
    path: "/nencho/insurance",
    name: "nencho_insurance",
    view: "Nencho_insurance",
  },
  {
    path: "/nencho/loan",
    name: "nencho_loan",
    view: "Nencho_loan",
  },
  {
    path: "/nencho/prev_payment",
    name: "nencho_prev_payment",
    view: "Nencho_prev_payment",
  },

  //定額減税
  {
    path: "/fixedtaxreduction",
    name: "fixedtaxreduction",
    view: "Fixedtaxreduction",
  },
  {
    path: "/fixedtaxreduction/master",
    name: "fixed_tax_reduction_master",
    view: "Fixed_tax_reduction_master",
  },
  {
    path: "/fixedtaxreduction/employee",
    name: "fixed_tax_reduction_employee",
    view: "Fixed_tax_reduction_employee",
  },

  {
    path: "/status",
    name: "status",
    view: "Status",
  },

  {
    path: "/change_plan",
    name: "change_plan",
    view: "Change_plan",
  },

  {
    path: "/test",
    name: "test",
    view: "Test",
  },
  {
    path: "/document_template",
    name: "document_template",
    view: "Document_template",
  },
  {
    path: "/contract_document",
    name: "contract_document",
    view: "Contract_document",
  },
  {
    path: "/document_waiting",
    name: "document_waiting",
    view: "Document_waiting",
  },

  {
    path: "/bundle_change",
    name: "BundleChange",
    view: "BundleChange",
    //労働保険改定
  },

  {
    path: "/bundle",
    name: "bundle",
    view: "Bundle",
    //住民税
  },

  {
    path: "/laborins",
    name: "laborins",
    view: "Laborins",
  },
  {
    path: "/renew_stdincome",
    name: "renew_stdincome",
    view: "Renew_stdincome",
  },
  {
    path: "/update_stdincome",
    name: "update_stdincome",
    view: "Update_stdincome",
  },

  {
    path: "/pdfviewer",
    name: "pdfviewer",
    view: "Pdfviewer",
  },

  {
    path: "/menu",
    name: "menu",
    view: "Menu",
  },
  {
    path: "/inform",
    name: "inform",
    view: "Inform",
  },

  {
    path: "/passreset",
    name: "passreset",
    view: "Passreset",
  },
];
