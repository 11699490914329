function isset(val) {
  return !(val === undefined || val === null || val === "");
}
function is_zip(val) {
  var zip = val.split('-');
  if (isset(zip[0]) && isset(zip[1]) && is_number(zip[0]) && is_number(zip[1]) && zip[0].length == 3 && zip[1].length == 4) {
    return true;
  } else {
    return false;
  }
}
function is_number(val) {
  if (isset(val)) {
    val = ""+val;
    if (val.match(/^-?[0-9]+$/)) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
}



export default {
  isset,
  is_zip,
  is_number,
};