import Able from "@/typesold/able";
import * as util from "@/util";

//validationは、OKならTrue, それ以外なら警告文を配列に入れる。
export default function validationAbles(ables: { [date: string]: Able }) {
  var error = [];
  Object.keys(ables).forEach((date) => {
    const able = ables[date];
    for (var i = 0; i < able.able_time.length; i++) {
      if (!validate_time_start(able.able_time[i]))
        error.push(
          util.date2monthdaystr(date) +
            "の" +
            (i + 1) +
            "行目のシフトの開始時刻が入力されていません",
        );
      if (!validate_time_end(able.able_time[i]))
        error.push(
          util.date2monthdaystr(date) +
            "の" +
            (i + 1) +
            "行目のシフトの終了時刻が入力されていません",
        );
      if (!validate_time_order(able.able_time[i]))
        error.push(
          util.date2monthdaystr(date) +
            "の" +
            (i + 1) +
            "行目のシフトの開始時刻が終了時刻以後となっています",
        );
      error = error.concat(
        validate_time_duplicate(able.able_time, i).map(
          (j) =>
            util.date2monthdaystr(date) +
            "の" +
            (i + 1) +
            "行目のシフトが" +
            (j + 1) +
            "行目のシフトと重複しています",
        ),
      );
    }
  });

  return error;
}

function validate_time_start(time): boolean {
  return time.started_at !== null || time.ended_at === null;
}
function validate_time_end(time): boolean {
  return time.started_at === null || time.ended_at !== null;
}
function validate_time_order(time): boolean {
  return time.started_at === null || time.ended_at === null || time.started_at < time.ended_at;
}
function validate_time_duplicate(times, i): number[] {
  var error_j = [];
  for (var j = i + 1; j < times.length; j++) {
    if (times[i].started_at !== null && times[j].started_at !== null) {
      if (
        times[i].started_at !== null &&
        times[j].started_at !== null &&
        times[j].ended_at !== null &&
        times[j].started_at <= times[i].started_at &&
        times[i].started_at < times[j].ended_at
      )
        error_j.push(j);
      else if (
        times[i].ended_at !== null &&
        times[j].started_at !== null &&
        times[j].ended_at !== null &&
        times[j].started_at < times[i].ended_at &&
        times[i].ended_at <= times[j].ended_at
      )
        error_j.push(j);
      else if (
        times[j].started_at !== null &&
        times[i].started_at !== null &&
        times[i].ended_at !== null &&
        times[i].started_at <= times[j].started_at &&
        times[j].started_at < times[i].ended_at
      )
        error_j.push(j);
      else if (
        times[j].ended_at !== null &&
        times[i].started_at !== null &&
        times[i].ended_at !== null &&
        times[i].started_at < times[j].ended_at &&
        times[j].ended_at <= times[i].ended_at
      )
        error_j.push(j);
      else if (times[i].started_at == times[j].started_at) error_j.push(j);
    }
  }
  return error_j;
}
