import Shift from "@/typesold/shift";
import * as util from "@/util";

//validationは、OKならTrue, それ以外なら警告文を配列に入れる。
export default function validationShiftbundle(
  shifts: { [date: string]: Shift },
  shiftgroup_id: number | null,
) {
  var error = [];

  if (shiftgroup_id === null) error.push("シフトグループが選択されていません");

  Object.keys(shifts).forEach((date) => {
    const shift = shifts[date];
    for (var i = 0; i < shift.shift_shift.length; i++) {
      if (!validate_time_start(shift.shift_shift[i]))
        error.push(
          util.date2monthdaystr(date) + "の" + (i + 1) + "行目の募集の開始時刻が入力されていません",
        );
      if (!validate_time_end(shift.shift_shift[i]))
        error.push(
          util.date2monthdaystr(date) + "の" + (i + 1) + "行目の募集の終了時刻が入力されていません",
        );
      if (!validate_time_order(shift.shift_shift[i]))
        error.push(
          util.date2monthdaystr(date) +
            "の" +
            (i + 1) +
            "行目の募集の開始時刻が終了時刻以後となっています",
        );
      error = error.concat(
        validate_time_duplicate(shift.shift_shift, i).map(
          (j) =>
            util.date2monthdaystr(date) +
            "の" +
            (i + 1) +
            "行目の募集が" +
            (j + 1) +
            "行目の募集と重複しています",
        ),
      );
    }

    for (var i = 0; i < shift.shift_break.length; i++) {
      if (!validate_time_start(shift.shift_break[i]))
        error.push(
          util.date2monthdaystr(date) + "の" + (i + 1) + "行目の休憩の開始時刻が入力されていません",
        );
      if (!validate_time_end(shift.shift_break[i]))
        error.push(
          util.date2monthdaystr(date) + "の" + (i + 1) + "行目の休憩の終了時刻が入力されていません",
        );
      if (!validate_time_order(shift.shift_break[i]))
        error.push(
          util.date2monthdaystr(date) +
            "の" +
            (i + 1) +
            "行目の休憩の開始時刻が終了時刻以後となっています",
        );
      error = error.concat(
        validate_time_duplicate(shift.shift_break, i).map(
          (j) =>
            util.date2monthdaystr(date) +
            "の" +
            (i + 1) +
            "行目の休憩が" +
            (j + 1) +
            "行目の休憩と重複しています",
        ),
      );
    }
  });

  return error;
}

function validate_time_start(time): boolean {
  return time.started_at !== null || time.ended_at === null;
}
function validate_time_end(time): boolean {
  return time.started_at === null || time.ended_at !== null;
}
function validate_time_order(time): boolean {
  return time.started_at === null || time.ended_at === null || time.started_at < time.ended_at;
}
function validate_time_duplicate(times, i): number[] {
  var error_j = [];
  for (var j = i + 1; j < times.length; j++) {
    if (times[i].started_at !== null && times[j].started_at !== null) {
      if (
        times[i].started_at !== null &&
        times[j].started_at !== null &&
        times[j].ended_at !== null &&
        times[j].started_at <= times[i].started_at &&
        times[i].started_at < times[j].ended_at
      )
        error_j.push(j);
      else if (
        times[i].ended_at !== null &&
        times[j].started_at !== null &&
        times[j].ended_at !== null &&
        times[j].started_at < times[i].ended_at &&
        times[i].ended_at <= times[j].ended_at
      )
        error_j.push(j);
      else if (
        times[j].started_at !== null &&
        times[i].started_at !== null &&
        times[i].ended_at !== null &&
        times[i].started_at <= times[j].started_at &&
        times[j].started_at < times[i].ended_at
      )
        error_j.push(j);
      else if (
        times[j].ended_at !== null &&
        times[i].started_at !== null &&
        times[i].ended_at !== null &&
        times[i].started_at < times[j].ended_at &&
        times[j].ended_at <= times[i].ended_at
      )
        error_j.push(j);
      else if (times[i].started_at == times[j].started_at) error_j.push(j);
    }
  }
  return error_j;
}
