import {
  SingleAdminNotificationData,
  SingleAdminNotificationValidateData,
} from "@/typesold/Notification";
import VuexDataControl from "@/vuex/VuexDataControl";

export default class NotificationSingleAdmin extends VuexDataControl {
  api_url = "notification/read_single_admin";
  indexes = ["admin_notification_id"];
}
