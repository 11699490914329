import Bonus_info from "@/typesold/bonus_info";
import * as util from "@/util";

export function validationNenchoMasters(nencho_masters, employee_hash) {
  var error = [];

  Object.keys(nencho_masters).forEach((employee_id) => {
    error = error.concat(
      validationNenchoMaster(nencho_masters[employee_id], employee_hash[employee_id]),
    );
  });

  return error;
}

function validationNenchoMaster(nencho_master, employee) {
  var error = [];

  if (
    nencho_master.is_pay_sum_mode ||
    nencho_master.is_ins_sum_mode ||
    nencho_master.is_tax_sum_mode
  ) {
    if (!nencho_master.is_pay_sum_mode) {
      error.push(employee.name + "さんの給与・賞与の合計額のうち、課税支給額が入力されていません");
    }
    if (!nencho_master.is_ins_sum_mode) {
      error.push(
        employee.name + "さんの給与・賞与の合計額のうち、社会保険料等が入力されていません",
      );
    }
    if (!nencho_master.is_tax_sum_mode) {
      error.push(employee.name + "さんの給与・賞与の合計額のうち、所得税が入力されていません");
    }
  }

  if (
    nencho_master.prev_payment_pay != null ||
    nencho_master.prev_payment_ins != null ||
    nencho_master.prev_payment_tax != null
  ) {
    if (nencho_master.prev_payment_pay == null) {
      error.push(employee.name + "さんの前職分の給与等のうち、課税支給額が入力されていません");
    }
    if (nencho_master.prev_payment_ins == null) {
      error.push(employee.name + "さんの前職分の給与等のうち、社会保険料等が入力されていません");
    }
    if (nencho_master.prev_payment_tax == null) {
      error.push(employee.name + "さんの前職分の給与等のうち、所得税が入力されていません");
    }
  }

  return error;
}
