import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import Vue from "vue";
import Shift_pattern from "@/typesold/shift_pattern";
import Shiftgroup from "@/typesold/Shiftgroup";

export type Data = {
  shift_patterns: Shift_pattern[];
  shift_pattern_option;
  shiftgroups: Shiftgroup[];
  shiftgroup_option;
  is_valid?: boolean;
  is_set: boolean;
  fetching?: Promise<void>;
};
export type BranchlyData = {
  [branch: number]: Data;
};

@Module({ dynamic: true, store, name: "shiftbundle_global_data", namespaced: true })
class Shiftbundle_global extends VuexModule {
  data: BranchlyData = {};

  @Mutation
  public set({ branch_id, data }: { branch_id: number; data: Data }) {
    var shift_pattern_option = util.createHash(data.shift_patterns, "id", "name");
    var shiftgroup_option = util.createHash(data.shiftgroups, "id", "name");
    shift_pattern_option = {
      0: "リセット",
      ...shift_pattern_option,
    };
    shiftgroup_option = {
      0: "全員",
      ...shiftgroup_option,
    };
    Vue.set(this.data, branch_id, {
      shift_patterns: data.shift_patterns,
      shift_pattern_option: shift_pattern_option,
      shiftgroups: data.shiftgroups,
      shiftgroup_option: shiftgroup_option,
      is_valid: true,
      is_set: true,
      fetching: null,
    });
  }

  @Mutation
  public createShiftPattern({
    branch_id,
    shift_pattern,
  }: {
    branch_id: number;
    shift_pattern: Shift_pattern;
  }) {
    shift_pattern = {
      ...shift_pattern,
      shift_pattern_shift: shift_pattern.shift_pattern_shift.filter(
        (shift_pattern_shift) => shift_pattern_shift.started_at || shift_pattern_shift.ended_at,
      ),
      shift_pattern_break: shift_pattern.shift_pattern_break.filter(
        (shift_pattern_break) => shift_pattern_break.started_at || shift_pattern_break.ended_at,
      ),
    };

    this.data[branch_id].shift_patterns.push(shift_pattern);
  }
  @Mutation
  public updateShiftPattern({
    branch_id,
    shift_pattern_id,
    shift_pattern,
  }: {
    branch_id: number;
    shift_pattern_id: number;
    shift_pattern: Shift_pattern;
  }) {
    shift_pattern = {
      ...shift_pattern,
      shift_pattern_shift: shift_pattern.shift_pattern_shift.filter(
        (shift_pattern_shift) => shift_pattern_shift.started_at || shift_pattern_shift.ended_at,
      ),
      shift_pattern_break: shift_pattern.shift_pattern_break.filter(
        (shift_pattern_break) => shift_pattern_break.started_at || shift_pattern_break.ended_at,
      ),
    };

    this.data[branch_id].shift_patterns.forEach((v, i, a) => {
      if (v.id == shift_pattern_id) {
        a[i] = shift_pattern;
      }
    });
  }
  @Mutation
  public deleteShiftPattern({
    branch_id,
    shift_pattern_id,
  }: {
    branch_id: number;
    shift_pattern_id: number;
  }) {
    this.data[branch_id].shift_patterns = this.data[branch_id].shift_patterns.filter(
      (shift_pattern) => shift_pattern.id != shift_pattern_id,
    );
  }
  @Mutation
  public disable({ branch_id }: { branch_id?: number }) {
    if (branch_id === undefined || branch_id == 0) {
      Object.keys(this.data).forEach((branch_id) => {
        if (this.data[branch_id]) {
          this.data[branch_id].is_valid = false;
        }
      });
    } else {
      if (this.data[branch_id]) {
        this.data[branch_id].is_valid = false;
      }
    }
  }
  @Mutation
  public disable_all() {
    Object.keys(this.data).forEach((branch_id) => {
      this.data[branch_id].is_valid = false;
    });
  }

  @Mutation
  public setFetching({ branch_id, value }: { branch_id: number; value: Promise<void> }) {
    this.data[branch_id].fetching = value;
  }

  // actions
  @Action({ rawError: true })
  public async fetch({ branch_id }: { branch_id: number }) {
    if (!this.data[branch_id]) {
      Vue.set(this.data, branch_id, {
        needs: [],
        shift_pattern_option: {},
        shift_patterns: [],
        shiftgroups: [],
        shiftgroup_option: {},
        is_valid: false,
        is_set: false,
        fetching: null,
      });
    }

    if (this.data[branch_id] && this.data[branch_id].fetching) {
      await this.data[branch_id].fetching;
    } else {
      const promise = util.post("shift/read_shiftbundle_global", { branch_id }).then((res) => {
        this.set({ branch_id, data: res.data });
      });
      this.setFetching({ branch_id, value: promise });
      await promise;
    }
  }
}

export default getModule(Shiftbundle_global);
