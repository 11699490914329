import Payment_info from "@/typesold/Payment_info";
import * as util from "@/util";
import * as vali from "@/validations/validationUtil";

export function validationEachPayments(
  each_payments,
  employee_hash,
  saved_payment_allowances,
  saved_payment_deductions,
  saved_payment_other_allowances,
) {
  var error = [];

  Object.keys(each_payments).forEach((employee_id) => {
    error = error.concat(
      validationEachPayment(
        each_payments[employee_id],
        employee_hash[employee_id],
        saved_payment_allowances,
        saved_payment_deductions,
        saved_payment_other_allowances,
        true,
      ),
    );
  });

  return error;
}
export function validationEachPayment(
  each_payment,
  employee,
  saved_payment_allowances,
  saved_payment_deductions,
  saved_payment_other_allowances,
  is_multi,
) {
  var error = [];

  var employee_name = "";
  if (is_multi) {
    employee_name = employee.name + "さんの";
  }

  error.push(
    each_payment.reg_pay === null ||
      vali.is_number(each_payment.reg_pay) ||
      employee_name + "基本給/役員報酬は半角数字で入力してください",
  );
  error.push(
    each_payment.hol_pay === null ||
      vali.is_number(each_payment.hol_pay) ||
      employee_name + "休日手当は半角数字で入力してください",
  );
  error.push(
    each_payment.mid_pay === null ||
      vali.is_number(each_payment.mid_pay) ||
      employee_name + "深夜手当は半角数字で入力してください",
  );
  error.push(
    each_payment.over_pay === null ||
      vali.is_number(each_payment.over_pay) ||
      employee_name + "残業手当は半角数字で入力してください",
  );
  if (employee.over_type == 2) {
    error.push(
      each_payment.deemover_pay === null ||
        vali.is_number(each_payment.deemover_pay) ||
        employee_name + "固定残業手当は半角数字で入力してください",
    );
  }
  error.push(
    each_payment.paid_hol_pay === null ||
      vali.is_number(each_payment.paid_hol_pay) ||
      employee_name + "有給手当は半角数字で入力してください",
  );
  saved_payment_allowances.forEach((payment_allowance) => {
    if (each_payment.each_payment_allowances[payment_allowance.id]) {
      error.push(
        each_payment.each_payment_allowances[payment_allowance.id].money === null ||
          vali.is_number(each_payment.each_payment_allowances[payment_allowance.id].money) ||
          employee_name + payment_allowance.name + "は半角数字で入力してください",
      );
    }
  });
  error.push(
    each_payment.carfare_pay === null ||
      vali.is_number(each_payment.carfare_pay) ||
      employee_name + "通勤手当は半角数字で入力してください",
  );

  error.push(
    each_payment.health_ins === null ||
      vali.is_number(each_payment.health_ins) ||
      employee_name + "健康保険料は半角数字で入力してください",
  );
  error.push(
    each_payment.care_ins === null ||
      vali.is_number(each_payment.care_ins) ||
      employee_name + "介護保険料は半角数字で入力してください",
  );
  error.push(
    each_payment.pension_ins === null ||
      vali.is_number(each_payment.pension_ins) ||
      employee_name + "厚生年金保険料は半角数字で入力してください",
  );
  error.push(
    each_payment.emp_ins === null ||
      vali.is_number(each_payment.emp_ins) ||
      employee_name + "雇用保険料は半角数字で入力してください",
  );
  error.push(
    each_payment.social_ins_adj === null ||
      vali.is_number(each_payment.social_ins_adj) ||
      employee_name + "社会保険料等調整は半角数字で入力してください",
  );
  error.push(
    each_payment.income_tax === null ||
      vali.is_number(each_payment.income_tax) ||
      employee_name + "所得税は半角数字で入力してください",
  );
  error.push(
    each_payment.fixed_tax_reduction === null ||
      vali.is_number(each_payment.fixed_tax_reduction) ||
      employee_name + "定額減税は半角数字で入力してください",
  );
  error.push(
    each_payment.res_tax === null ||
      vali.is_number(each_payment.res_tax) ||
      employee_name + "住民税は半角数字で入力してください",
  );
  saved_payment_deductions.forEach((payment_deduction) => {
    if (each_payment.each_payment_deductions[payment_deduction.id]) {
      error.push(
        each_payment.each_payment_deductions[payment_deduction.id].money === null ||
          vali.is_number(each_payment.each_payment_deductions[payment_deduction.id].money) ||
          employee_name + payment_deduction.name + "は半角数字で入力してください",
      );
    }
  });

  error.push(
    each_payment.yearend_adj === null ||
      vali.is_number(each_payment.yearend_adj) ||
      employee_name + "年末調整は半角数字で入力してください",
  );
  error.push(
    each_payment.settlement === null ||
      vali.is_number(each_payment.settlement) ||
      employee_name + "現物支給清算金は半角数字で入力してください",
  );
  saved_payment_other_allowances.forEach((payment_other_allowance) => {
    if (each_payment.each_payment_other_allowances[payment_other_allowance.id]) {
      error.push(
        each_payment.each_payment_other_allowances[payment_other_allowance.id].money === null ||
          vali.is_number(
            each_payment.each_payment_other_allowances[payment_other_allowance.id].money,
          ) ||
          employee_name + payment_other_allowance.name + "は半角数字で入力してください",
      );
    }
  });

  error.push(
    each_payment.paid_cash === null ||
      vali.is_number(each_payment.paid_cash) ||
      employee_name + "現金支給額は半角数字で入力してください",
  );

  return error;
}
