import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import Vue from "vue";
import Shift from "@/typesold/shift";
import Able from "@/typesold/able";
import Shift_memo from "@/typesold/shift_memo";

export type Employee = {
  id: number;
  name: string;
};
export type EmployeeHash = {
  [id: number]: string;
};
export type Data = {
  shifts: Shift[];
  shift_memo: Shift_memo | null;
  employees: Employee[];
  employee_hash: EmployeeHash;
};
export type BranchlyData = {
  [branch: number]: Data;
  is_valid?: boolean;
  is_set: boolean;
  fetching?: Promise<void>;
};
export type DailyData = {
  [date: string]: BranchlyData;
};

@Module({ dynamic: true, store, name: "daily_employee", namespaced: true })
class DailyEmployee extends VuexModule {
  data: DailyData = {};

  @Mutation
  public set({ date, data }: { date: string; data }) {
    Object.keys(Object(data)).forEach((branch_id) => {
      Vue.set(this.data[date], branch_id, {
        ...data[branch_id],
        employee_hash: util.createHashObject(data[branch_id].employees, "id"),
      });
    });
    this.data[date]["is_valid"] = true;
    this.data[date]["is_set"] = true;
    this.data[date]["fetching"] = null;
  }
  @Mutation
  public createShift({ date, branch_id, shift }: { date: string; branch_id: number; shift }) {
    shift = {
      ...shift,
      shift_shift: shift.shift_shift.filter(
        (shift_shift) => shift_shift.started_at || shift_shift.ended_at,
      ),
      shift_punch: shift.shift_punch.filter(
        (shift_punch) => shift_punch.started_at || shift_punch.ended_at,
      ),
      shift_break: shift.shift_break.filter(
        (shift_break) => shift_break.started_at || shift_break.ended_at,
      ),
    };

    this.data[date][branch_id].shifts.push(shift);
  }
  @Mutation
  public updateShift({
    date,
    branch_id,
    shift_id,
    shift,
  }: {
    date: string;
    branch_id: number;
    shift_id: number;
    shift;
  }) {
    shift = {
      ...shift,
      shift_shift: shift.shift_shift.filter(
        (shift_shift) => shift_shift.started_at || shift_shift.ended_at,
      ),
      shift_punch: shift.shift_punch.filter(
        (shift_punch) => shift_punch.started_at || shift_punch.ended_at,
      ),
      shift_break: shift.shift_break.filter(
        (shift_break) => shift_break.started_at || shift_break.ended_at,
      ),
    };

    this.data[date][branch_id].shifts.forEach((v, i, a) => {
      if (v.id == shift_id) {
        a[i] = shift;
      }
    });
  }
  @Mutation
  public deleteShift({
    date,
    branch_id,
    shift_id,
  }: {
    date: string;
    branch_id: number;
    shift_id: number;
  }) {
    this.data[date][branch_id].shifts = this.data[date][branch_id].shifts.filter(
      (shift) => shift.id != shift_id,
    );
  }
  @Mutation
  public disable({
    date,
    branch_id,
    employee_id,
    yearmonth,
  }: {
    date?: string;
    branch_id?: number;
    employee_id?: number;
    yearmonth?: string;
  }) {
    if (date === undefined) {
      let date_array = util.month_date_array(yearmonth);
      date_array.forEach((date) => {
        if (
          employee_id === undefined ||
          employee_id == Number(util.getLocalStorage("employee_id"))
        ) {
          if (this.data[date]) {
            this.data[date].is_valid = false;
          }
        }
      });
    } else {
      if (employee_id === undefined || employee_id == Number(util.getLocalStorage("employee_id"))) {
        if (this.data[date]) {
          this.data[date].is_valid = false;
        }
      }
    }
  }
  @Mutation
  public disable_all() {
    Object.keys(this.data).forEach((date) => {
      this.data[date].is_valid = false;
    });
  }

  @Mutation
  public setFetching({ date, value }: { date: string; value: Promise<void> }) {
    this.data[date].fetching = value;
  }

  // actions
  @Action({ rawError: true })
  public async fetch({ date, employee_id }: { date: string; employee_id: number }) {
    //ないなら作る
    if (!this.data[date]) {
      Vue.set(this.data, date, {
        is_valid: false,
        is_set: false,
        fetching: null,
      });
    }

    if (this.data[date].fetching) {
      await this.data[date].fetching;
    } else {
      const promise = util.post("shift/read_daily_employee", { date, employee_id }).then((res) => {
        this.set({ date, data: res.data.shifts });
      });
      this.setFetching({ date, value: promise });
      await promise;
    }
  }
}

export default getModule(DailyEmployee);
