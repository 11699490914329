import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import router from "@/router";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import validate from "@/validations/validate";
import validate_api from "@/validations/validate_api";
import { validationDemandHoliday, validationDemandOvertime } from "@/validations/demand";
import dialog from "@/vuex/dialog";
import Vue from "vue";
import order from "./order";
import retire from "./retire";
import now from "./now";
import { Demand_holiday, Demand_overtime } from "@/typesold/demand";
import { validationEmployeeMails } from "@/validations/employee";
import node from "@/vuex/node";

@Module({ dynamic: true, store, name: "employee_manager", namespaced: true })
class Employee_manager extends VuexModule {
  disp_num: number = 10;
  is_valid: boolean = false;

  is_open_demand_holiday_dialog: boolean = false;
  is_open_demand_overtime_dialog: boolean = false;

  is_open_join_dialog: boolean = false;
  is_open_join_mail_dialog: boolean = false;
  is_open_join_manual_dialog: boolean = false;
  is_open_join_excel_dialog: boolean = false;
  is_open_join_mail_nencho_dialog: boolean = false;
  is_open_joinmail_excel_dialog: boolean = false;

  demand_holiday: null | Demand_holiday = null;
  demand_overtime: null | Demand_overtime = null;

  join_mail_employees: any = null;
  join_manual_employee: any = null;

  is_open_resend_mail_dialog: number = 0;
  resend_mail: string | null = null;

  @Mutation
  public setDispNum(val) {
    this.disp_num = val;
  }

  @Mutation
  public setIsOpenDemandHolidayDialog(val: boolean) {
    this.is_open_demand_holiday_dialog = val;
  }
  @Mutation
  public setIsOpenDemandOvertimeDialog(val: boolean) {
    this.is_open_demand_overtime_dialog = val;
  }

  @Mutation
  public setIsOpenJoinDialog(val: boolean) {
    this.is_open_join_dialog = val;
  }
  @Mutation
  public setIsOpenJoinMailDialog(val: boolean) {
    this.is_open_join_mail_dialog = val;
  }
  @Mutation
  public setIsOpenJoinManualDialog(val: boolean) {
    this.is_open_join_manual_dialog = val;
  }
  @Mutation
  public setIsOpenJoinExcelDialog(val: boolean) {
    this.is_open_join_excel_dialog = val;
  }
  @Mutation
  public setIsOpenJoinMailNenchoDialog(val: boolean) {
    this.is_open_join_mail_nencho_dialog = val;
  }

  @Mutation
  public setIsOpenJoinmailExcelDialog(val: boolean) {
    this.is_open_joinmail_excel_dialog = val;
  }

  @Mutation
  public setJoinMailEmployees(val) {
    this.join_mail_employees = val;
  }
  @Mutation
  public setJoinManualEmployee(val) {
    this.join_manual_employee = val;
  }

  @Mutation
  public setIsOpenResendMailDialog(val: number) {
    this.is_open_resend_mail_dialog = val;
    if (val === 0) {
      this.resend_mail = null;
    }
  }
  @Mutation
  public setResendMail(val?: string) {
    this.resend_mail = val;
  }

  @Mutation
  public setDemandHoliday(val: Demand_holiday) {
    this.demand_holiday = val;
  }
  @Mutation
  public setDemandOvertime(val: Demand_overtime) {
    this.demand_overtime = val;
  }

  @Action({ rawError: true })
  public cancelAll() {}

  @Action({ rawError: true })
  public admitDemandHoliday({
    branch_id,
    demand_holiday_id,
    is_changed,
  }: {
    branch_id: number;
    demand_holiday_id: number;
    is_changed: boolean;
  }) {
    if (validate(validationDemandHoliday(this.demand_holiday))) {
      if (is_changed)
        dialog.openConfirmDialog({
          msg: "休暇申請を修正承認してよろしいですか？",
          detail: "申請された情報を変更して承認します。",
          cancel_button: "承認しない",
          exec_button: "承認する",
          func: this.admitDemandHolidayProcess,
          args: { branch_id, demand_holiday_id, demand_holiday: this.demand_holiday, is_changed },
        });
      else
        dialog.openConfirmDialog({
          msg: "休暇申請を承認してよろしいですか？",
          detail: "申請された情報を承認します。",
          cancel_button: "承認しない",
          exec_button: "承認する",
          func: this.admitDemandHolidayProcess,
          args: { branch_id, demand_holiday_id, demand_holiday: this.demand_holiday },
        });
    }
  }
  @Action({ rawError: true })
  public async admitDemandHolidayProcess({
    branch_id,
    demand_holiday_id,
    demand_holiday,
    is_changed,
  }: {
    branch_id: number;
    demand_holiday_id: number;
    demand_holiday: Demand_holiday;
    is_changed: boolean;
  }) {
    const res = await util.post("demand_holiday/admit_demand_holiday", {
      demand_holiday_id,
      demand_holiday,
    });
    if (res !== null) {
      node.disable();
      this.setIsOpenDemandHolidayDialog(false);
      if (is_changed) dialog.openAlertDialog({ msg: "休暇申請を修正承認しました。" });
      else dialog.openAlertDialog({ msg: "休暇申請を承認しました。" });
    }
  }

  @Action({ rawError: true })
  public denyDemandHoliday({
    branch_id,
    demand_holiday_id,
  }: {
    branch_id: number;
    demand_holiday_id: number;
  }) {
    dialog.openConfirmDialog({
      msg: "休暇申請を拒否してよろしいですか？",
      cancel_button: "拒否しない",
      exec_button: "拒否する",
      func: this.denyDemandHolidayProcess,
      args: { branch_id, demand_holiday_id },
    });
  }
  @Action({ rawError: true })
  public async denyDemandHolidayProcess({
    branch_id,
    demand_holiday_id,
  }: {
    branch_id: number;
    demand_holiday_id: number;
  }) {
    await util.post("demand_holiday/deny_demand_holiday", { demand_holiday_id });
    node.disable();
    this.setIsOpenDemandHolidayDialog(false);
    dialog.openAlertDialog({ msg: "休暇申請を拒否しました。" });
  }

  @Action({ rawError: true })
  public admitDemandOvertime({
    branch_id,
    demand_overtime_id,
    is_changed,
  }: {
    branch_id: number;
    demand_overtime_id: number;
    is_changed: boolean;
  }) {
    if (validate(validationDemandOvertime(this.demand_overtime, 1))) {
      if (is_changed)
        dialog.openConfirmDialog({
          msg: "残業申請を修正承認してよろしいですか？",
          detail: "申請された情報を変更して承認します。",
          cancel_button: "承認しない",
          exec_button: "承認する",
          func: this.admitDemandOvertimeProcess,
          args: {
            branch_id,
            demand_overtime_id,
            demand_overtime: this.demand_overtime,
            is_changed,
          },
        });
      else
        dialog.openConfirmDialog({
          msg: "残業申請を承認してよろしいですか？",
          detail: "申請された情報を承認します。",
          cancel_button: "承認しない",
          exec_button: "承認する",
          func: this.admitDemandOvertimeProcess,
          args: { branch_id, demand_overtime_id, demand_overtime: this.demand_overtime },
        });
    }
  }
  @Action({ rawError: true })
  public async admitDemandOvertimeProcess({
    branch_id,
    demand_overtime_id,
    demand_overtime,
    is_changed,
  }: {
    branch_id: number;
    demand_overtime_id: number;
    demand_overtime: Demand_overtime;
    is_changed: boolean;
  }) {
    await util.post("demand_overtime/admit_demand_overtime", {
      demand_overtime_id,
      demand_overtime,
    });
    node.disable();
    this.setIsOpenDemandOvertimeDialog(false);
    if (is_changed) dialog.openAlertDialog({ msg: "残業申請を修正承認しました。" });
    else dialog.openAlertDialog({ msg: "残業申請を承認しました。" });
  }

  @Action({ rawError: true })
  public denyDemandOvertime({
    branch_id,
    demand_overtime_id,
  }: {
    branch_id: number;
    demand_overtime_id: number;
  }) {
    dialog.openConfirmDialog({
      msg: "残業申請を拒否してよろしいですか？",
      cancel_button: "拒否しない",
      exec_button: "拒否する",
      func: this.denyDemandOvertimeProcess,
      args: { branch_id, demand_overtime_id },
    });
  }
  @Action({ rawError: true })
  public async denyDemandOvertimeProcess({
    branch_id,
    demand_overtime_id,
  }: {
    branch_id: number;
    demand_overtime_id: number;
  }) {
    await util.post("demand_overtime/deny_demand_overtime", { demand_overtime_id });
    node.disable();
    this.setIsOpenDemandOvertimeDialog(false);
    dialog.openAlertDialog({ msg: "残業申請を拒否しました。" });
  }

  //delete

  @Action({ rawError: true })
  public async deleteDemandChange({
    ch_employee_id,
    branch_id,
  }: {
    ch_employee_id: number;
    branch_id: number;
  }) {
    dialog.openForceConfirmDialog({
      msg: "社員情報変更申請を削除してよろしいですか？",
      detail: "既に承認・拒否済みのものは変わりません。<br>元に戻すことができません",
      cancel_button: "削除しない",
      exec_button: "削除する",
      func: this.deleteDemandChangeProcess,
      args: { ch_employee_id, branch_id },
    });
  }
  @Action({ rawError: true })
  public async deleteDemandChangeProcess({
    ch_employee_id,
    branch_id,
  }: {
    ch_employee_id: number;
    branch_id: number;
  }) {
    await util.post("demand_change/forcedelete_demand_change", { ch_employee_id });
    node.disable();
  }
  @Action({ rawError: true })
  public async deleteDemandHoliday({
    demand_holiday_id,
    branch_id,
  }: {
    demand_holiday_id: number;
    branch_id: number;
  }) {
    dialog.openForceConfirmDialog({
      msg: "休暇申請を削除してよろしいですか？",
      detail: "既に承認・拒否済みのものは変わりません。<br>元に戻すことができません",
      cancel_button: "削除しない",
      exec_button: "削除する",
      func: this.deleteDemandHolidayProcess,
      args: { demand_holiday_id, branch_id },
    });
  }
  @Action({ rawError: true })
  public async deleteDemandHolidayProcess({
    demand_holiday_id,
    branch_id,
  }: {
    demand_holiday_id: number;
    branch_id: number;
  }) {
    await util.post("demand_holiday/forcedelete_demand_holiday", { demand_holiday_id });
    node.disable();
  }
  @Action({ rawError: true })
  public async deleteDemandOvertime({
    demand_overtime_id,
    branch_id,
  }: {
    demand_overtime_id: number;
    branch_id: number;
  }) {
    dialog.openForceConfirmDialog({
      msg: "残業申請を強制削除してよろしいですか？",
      detail: "元に戻すことができません",
      cancel_button: "削除しない",
      exec_button: "削除する",
      func: this.deleteDemandOvertimeProcess,
      args: { demand_overtime_id, branch_id },
    });
  }
  @Action({ rawError: true })
  public async deleteDemandOvertimeProcess({
    demand_overtime_id,
    branch_id,
  }: {
    demand_overtime_id: number;
    branch_id: number;
  }) {
    await util.post("demand_overtime/forcedelete_demand_overtime", { demand_overtime_id });
    node.disable();
  }
  @Action({ rawError: true })
  public async deleteDemandPunch({
    demand_punch_id,
    branch_id,
  }: {
    demand_punch_id: number;
    branch_id: number;
  }) {
    dialog.openForceConfirmDialog({
      msg: "打刻修正申請を強制削除してよろしいですか？",
      detail: "既に承認・拒否済みのものは変わりません。<br>元に戻すことができません",
      cancel_button: "削除しない",
      exec_button: "削除する",
      func: this.deleteDemandPunchProcess,
      args: { demand_punch_id, branch_id },
    });
  }
  @Action({ rawError: true })
  public async deleteDemandPunchProcess({
    demand_punch_id,
    branch_id,
  }: {
    demand_punch_id: number;
    branch_id: number;
  }) {
    await util.post("demand_punch/forcedelete_demand_punch", { demand_punch_id });
    node.disable();
  }

  @Action({ rawError: true })
  public async cancelRetire({
    employee_id,
    employee_name,
    branch_id,
  }: {
    employee_id: number;
    employee_name: string;
    branch_id: number;
  }) {
    dialog.openForceConfirmDialog({
      msg: employee_name + "さんの退職をキャンセルしてよろしいですか？",
      cancel_button: "退職のまま",
      exec_button: "在職へ戻す",
      func: this.cancelRetireProcess,
      is_next_dialog: true,
      args: { employee_id, employee_name, branch_id },
    });
  }
  @Action({ rawError: true })
  public async cancelRetireProcess({
    employee_id,
    employee_name,
    branch_id,
  }: {
    employee_id: number;
    employee_name: string;
    branch_id: number;
  }) {
    dialog.openProgressDialog({ title: "処理中", msg: "この処理には時間がかかることがあります。" });
    await util.post("employee/cancel_retire", { employee_id });
    node.disable();
    dialog.openAlertDialog({ msg: employee_name + "さんが在職に戻りました。" });
  }

  @Action({ rawError: true })
  public async createEmployeeMail() {
    if (validate(validationEmployeeMails(this.join_mail_employees))) {
      var error = [];
      for (var i = 0; i < this.join_mail_employees.length; i++) {
        var res = await util.post("employee_each/confirm_mail", {
          mail: this.join_mail_employees[i].mail,
          employee_id: 0,
        });
        error.push(res.data.validate || i + 1 + "行目のメールアドレスは使用できません。");
        if (this.join_mail_employees[i].code !== null) {
          var res = await util.post("employee_each/confirm_mail", {
            mail: this.join_mail_employees[i].code,
            employee_id: 0,
          });
          error.push(res.data.validate || i + 1 + "行目の社員コード(ID)は使用できません。");
        }
      }
      if (validate(error)) {
        dialog.openConfirmDialog({
          msg: "入社メールを送信してよろしいですか？",
          cancel_button: "送信しない",
          exec_button: "送信する",
          func: this.createEmployeeMailProcess,
          is_next_dialog: true,
          args: {},
        });
      }
    }
  }
  @Action({ rawError: true })
  public async createEmployeeMailProcess({}) {
    dialog.openProgressDialog({ title: "処理中", msg: "この処理には時間がかかることがあります。" });
    await util.post("employee/create_employee_mail", { employees: this.join_mail_employees });
    this.setIsOpenJoinMailDialog(false);
    this.setIsOpenJoinMailNenchoDialog(false);
    dialog.openAlertDialog({ msg: "入社メールの送信が完了しました。" });
  }

  @Action({ rawError: true })
  public async resendMail() {
    if (
      validate_api(
        await util.post("employee_each/confirm_mail", {
          mail: this.resend_mail,
          employee_id: this.is_open_resend_mail_dialog,
        }),
        ["使用できないメールアドレスが入力されています。"],
      )
    ) {
      dialog.openConfirmDialog({
        msg: "入社メールを再送信してよろしいですか？",
        cancel_button: "送信しない",
        exec_button: "送信する",
        func: this.resendMailProcess,
        is_next_dialog: true,
        args: {},
      });
    }
  }
  @Action({ rawError: true })
  public async resendMailProcess() {
    dialog.openProgressDialog({ title: "処理中", msg: "この処理には時間がかかることがあります。" });
    await util.post("employee/resend_employee_mail", {
      mail: this.resend_mail,
      employee_id: this.is_open_resend_mail_dialog,
    });
    this.setIsOpenResendMailDialog(0);
    dialog.openAlertDialog({ msg: "入社メールの再送信が完了しました。" });
  }

  @Action({ rawError: true })
  public async deleteEmployee({
    employee_id,
    employee_name,
  }: {
    employee_id: number;
    employee_name: string;
  }) {
    dialog.openForceConfirmDialog({
      msg: employee_name + "さんを削除してよろしいですか？",
      detail: "一度削除すると元に戻すことはできません。",
      cancel_button: "削除しない",
      exec_button: "削除する",
      func: this.deleteEmployeeProcess,
      is_next_dialog: true,
      args: { employee_id, employee_name },
    });
  }
  @Action({ rawError: true })
  public async deleteEmployeeProcess({
    employee_id,
    employee_name,
  }: {
    employee_id: number;
    employee_name: string;
  }) {
    dialog.openProgressDialog({ title: "処理中", msg: "この処理には時間がかかることがあります。" });
    await util.post("employee/delete_employee", { employee_id });
    dialog.openAlertDialog({ msg: employee_name + "さんを削除しました。" });
  }

  @Action({ rawError: true })
  public async deleteForceEmployee({
    employee_id,
    employee_name,
  }: {
    employee_id: number;
    employee_name: string;
  }) {
    dialog.openForceConfirmDialog({
      msg: employee_name + "さんの全情報を削除してよろしいですか？",
      detail: "全ての情報が削除されます。<br>一度削除すると二度と元に戻すことはできません。",
      cancel_button: "削除しない",
      exec_button: "削除する",
      func: this.deleteForceEmployeeConfirm,
      is_next_dialog: true,
      args: { employee_id, employee_name },
    });
  }
  @Action({ rawError: true })
  public async deleteForceEmployeeConfirm({
    employee_id,
    employee_name,
  }: {
    employee_id: number;
    employee_name: string;
  }) {
    dialog.openForceConfirmDialog({
      msg: "本当に" + employee_name + "さんの全情報を削除してよろしいですか？",
      detail:
        "最後の確認です。<br>全ての情報が削除されます。<br>一度削除すると二度と元に戻すことはできません。",
      cancel_button: "削除しない",
      exec_button: "削除する",
      func: this.deleteForceEmployeeProcess,
      is_next_dialog: true,
      args: { employee_id, employee_name },
    });
  }
  @Action({ rawError: true })
  public async deleteForceEmployeeProcess({
    employee_id,
    employee_name,
  }: {
    employee_id: number;
    employee_name: string;
  }) {
    dialog.openProgressDialog({ title: "処理中", msg: "この処理には時間がかかることがあります。" });
    await util.post("root/delete_force_employee", { employee_id });
    dialog.openAlertDialog({ msg: employee_name + "さんを削除しました。" });
  }

  @Action({ rawError: true })
  public async change_plan() {
    router.push({ name: "OrderChangePlan" });
  }

  @Action({ rawError: true })
  public async createEmployeeExcel({ excel }) {
    if (!excel) {
      validate(["Excelファイルを選択してください"]);
    } else {
      dialog.openForceConfirmDialog({
        msg: "社員Excel登録を実行してよろしいですか？",
        cancel_button: "登録しない",
        exec_button: "登録する",
        func: this.createEmployeeExcelProcess,
        is_next_dialog: true,
        args: { excel },
      });
    }
  }

  @Action({ rawError: true })
  public async createEmployeeExcelProcess({ excel }) {
    dialog.openProgressDialog({ title: "処理中", msg: "この処理には時間がかかることがあります。" });
    const res = await util.post_file("employee/create_employee_excel", { excel });
    if (res.data.error_message) {
      if (res.data.error_message == "plan_error") {
        dialog.openForceConfirmDialog({
          title: "従業員数が上限に達してしまいます",
          msg: "現在ご利用中のプランAでは11人以上の従業員を登録することはできません。<br>BプランかCプランへ変更のうえ、再度社員登録を行ってください。",
          cancel_button: "キャンセル",
          exec_button: "プラン変更",
          func: this.change_plan,
          args: {},
        });
      } else {
        dialog.openErrorAlertDialog({ msg: res.data.error_message });
      }
    } else {
      this.setIsOpenJoinExcelDialog(false);
      dialog.openAlertDialog({ msg: "社員の登録が完了しました。" });
    }
  }
}

export default getModule(Employee_manager);
