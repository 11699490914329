import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import daily from "@/vuex/shift/daily";
import monthly from "@/vuex/shift/monthly";
import monthly_list from "@/vuex/shift/monthly_list";
import * as util from "@/util";
import validate from "@/validations/validate";
import validationShiftbundle from "@/validations/shiftbundle";
import validationShiftPattern from "@/validations/shift_pattern";
import dialog from "@/vuex/dialog";
import Shift from "@/typesold/shift";
import Shift_pattern from "@/typesold/shift_pattern";
import Vue from "vue";
import realtime from "@/vuex/shift/realtime";
import global from "@/vuex/shift/global";
import manager from "./manager";
import shiftbundle_global from "@/vuex/shift/shiftbundle_global";
import header from "@/vuex/header";

@Module({ dynamic: true, store, name: "shiftbundle_manager", namespaced: true })
class Shiftbundle_manager extends VuexModule {
  shifts: { [date: string]: Shift } = {};
  shiftgroup_id: number | null = null;

  shift_control: Shift = {
    id: null,
    employee_id: null,
    shift_shift: [
      {
        id: null,
        shift_id: null,
        started_at: null,
        ended_at: null,
      },
    ],
    shift_punch: [],
    shift_break: [
      {
        id: null,
        shift_id: null,
        started_at: null,
        ended_at: null,
      },
    ],
  };
  days_control: number[] = [];

  add_shift_pattern_flag: boolean = false;
  edit_shift_pattern_flag: number = 0;
  is_open_shift_pattern_dialog: boolean = false;

  @Mutation
  private setAddShiftPatternFlag(val: boolean) {
    this.add_shift_pattern_flag = val;
  }
  @Mutation
  private setEditShiftPatternFlag(val: number) {
    this.edit_shift_pattern_flag = val;
  }
  @Mutation
  public setIsOpenShiftPatternDialog(val) {
    this.is_open_shift_pattern_dialog = val;
  }

  @Action({ rawError: true })
  public async is_open_input() {
    return this.add_shift_pattern_flag || this.edit_shift_pattern_flag != 0;
  }
  @Action({ rawError: true })
  public async addShiftPattern() {
    if (await this.is_open_input()) return;
    this.setAddShiftPatternFlag(true);
  }
  @Action({ rawError: true })
  public async editShiftPattern(shift_pattern_id: number) {
    if (await this.is_open_input()) return;
    this.setEditShiftPatternFlag(shift_pattern_id);
  }
  @Action({ rawError: true })
  public cancelAll() {
    this.setAddShiftPatternFlag(false);
    this.setEditShiftPatternFlag(0);
  }

  @Action({ rawError: true })
  async getShifts({ yearmonth, branch_id }: { yearmonth: string; branch_id: number }) {
    this.setShifts({ yearmonth, branch_id });
  }

  @Mutation
  setShifts({ yearmonth, branch_id }: { yearmonth: string; branch_id: number }) {
    const date_array = util.month_date_array(yearmonth);
    this.shifts = {};
    date_array.forEach((date) => {
      const shift: Shift = {
        id: null,
        branch_id: branch_id,
        employee_id: null,
        date: date,
        shift_shift: [],
        shift_punch: [],
        shift_break: [],
      };
      Vue.set(this.shifts, date, shift);
    });
  }

  @Mutation
  setShift({ date, shift }: { date: string; shift: Shift }) {
    Vue.set(this.shifts, date, shift);
  }

  @Mutation
  setShiftControl({ shift }: { shift: Shift }) {
    this.shift_control = shift;
  }

  @Mutation
  setShiftgroupId({ shiftgroup_id }: { shiftgroup_id: number | null }) {
    this.shiftgroup_id = shiftgroup_id;
  }

  @Mutation
  setDaysControl({ days }: { days: number[] }) {
    this.days_control = days;
  }

  @Action({ rawError: true })
  public async applyControl({
    yearmonth,
    national_holidays,
  }: {
    yearmonth: string;
    national_holidays: string[];
  }) {
    const date_array = util.month_date_array(yearmonth);
    date_array.forEach(async (date) => {
      if (util.isNationalHoliday(national_holidays, date)) {
        if (this.days_control.indexOf(7) !== -1) {
          Vue.set(this.shifts[date], "shift_shift", util.deep_copy(this.shift_control.shift_shift));
          Vue.set(this.shifts[date], "shift_break", util.deep_copy(this.shift_control.shift_break));
        }
      } else if (this.days_control.indexOf(util.date2day(date)) !== -1) {
        Vue.set(this.shifts[date], "shift_shift", util.deep_copy(this.shift_control.shift_shift));
        Vue.set(this.shifts[date], "shift_break", util.deep_copy(this.shift_control.shift_break));
      }
    });
  }

  @Action({ rawError: true })
  public createShiftbundle({ yearmonth, branch_id }: { yearmonth: string; branch_id: number }) {
    if (validate(validationShiftbundle(this.shifts, this.shiftgroup_id))) {
      dialog.openConfirmDialog({
        msg: "シフト一括登録を実行してよろしいですか？",
        detail: "各社員でシフトが既に入ってる日や勤怠ロックがされている日はスキップされます。",
        cancel_button: "実行しない",
        exec_button: "実行する",
        func: this.createShiftbundleProcess,
        is_next_dialog: true,
        args: { yearmonth, branch_id },
      });
    }
  }
  @Action({ rawError: true })
  public async createShiftbundleProcess({
    yearmonth,
    branch_id,
  }: {
    yearmonth: string;
    branch_id: number;
  }) {
    dialog.openProgressDialog({
      title: "作成中",
      msg: "この処理には時間がかかることがあります。",
      detail: "そのままお待ちください。",
    });
    await util.post_proxy("shift/create_shiftbundle", {
      yearmonth,
      branch_id,
      shiftgroup_id: this.shiftgroup_id,
      shifts: this.shifts,
    });
    const date_array = util.month_date_array(yearmonth);
    date_array.forEach(async (date) => {
      daily.disable({ date, branch_id });
    });
    monthly.disable({ yearmonth, branch_id });
    manager.setIsOpenShiftbundleDialog(false);
    dialog.openAlertDialog({ msg: "シフト一括登録が完了しました。" });
  }

  @Action({ rawError: true })
  public createShiftPattern({
    branch_id,
    shift_pattern,
  }: {
    branch_id: number;
    shift_pattern: Shift_pattern;
  }) {
    if (validate(validationShiftPattern(shift_pattern))) {
      dialog.openConfirmDialog({
        msg: "シフトパターンを作成してよろしいですか？",
        cancel_button: "作成しない",
        exec_button: "作成する",
        func: this.createShiftPatternProcess,
        is_next_dialog: true,
        args: { branch_id, shift_pattern },
      });
    }
  }
  @Action({ rawError: true })
  public async createShiftPatternProcess({
    branch_id,
    date,
    shift_pattern,
  }: {
    branch_id: number;
    date: string;
    shift_pattern;
  }) {
    dialog.openProgressDialog({ title: "実行中", msg: "シフトパターンを作成しています" });
    var res = await util.post("shift/create_shift_pattern", { branch_id, date, shift_pattern });
    if (res !== null) {
      this.cancelAll();
      shiftbundle_global.disable({ branch_id });
      monthly_list.disable({ branch_id });
      dialog.openAlertDialog({ msg: "シフトパターンを作成しました。" });
    }
  }

  @Action({ rawError: true })
  public updateShiftPattern({
    branch_id,
    shift_pattern_id,
    shift_pattern,
  }: {
    branch_id: number;
    shift_pattern_id: number;
    shift_pattern;
  }) {
    if (validate(validationShiftPattern(shift_pattern))) {
      dialog.openConfirmDialog({
        msg: "シフトパターンを更新してよろしいですか？",
        cancel_button: "更新しない",
        exec_button: "更新する",
        func: this.updateShiftPatternProcess,
        is_next_dialog: true,
        args: { branch_id, shift_pattern_id, shift_pattern },
      });
    }
  }
  @Action({ rawError: true })
  public async updateShiftPatternProcess({
    branch_id,
    shift_pattern_id,
    shift_pattern,
  }: {
    branch_id: number;
    shift_pattern_id: number;
    shift_pattern;
  }) {
    dialog.openProgressDialog({ title: "実行中", msg: "シフトパターンを更新しています" });
    var res = await util.post("shift/update_shift_pattern", {
      branch_id,
      shift_pattern_id,
      shift_pattern,
    });
    if (res !== null) {
      this.cancelAll();
      shiftbundle_global.disable({ branch_id });
      monthly_list.disable({ branch_id });
      dialog.openAlertDialog({ msg: "シフトパターンを更新しました。" });
    }
  }

  @Action({ rawError: true })
  public async deleteShiftPattern({
    branch_id,
    shift_pattern_id,
  }: {
    branch_id: number;
    shift_pattern_id: number;
  }) {
    dialog.openForceConfirmDialog({
      msg: "シフトパターンを削除してよろしいですか？",
      detail: "削除したシフトパターンは戻すことができません",
      cancel_button: "削除しない",
      exec_button: "削除する",
      func: this.deleteShiftPatternProcess,
      args: { branch_id, shift_pattern_id },
    });
  }
  @Action({ rawError: true })
  public async deleteShiftPatternProcess({
    branch_id,
    shift_pattern_id,
  }: {
    branch_id: number;
    shift_pattern_id: number;
  }) {
    shiftbundle_global.deleteShiftPattern({ branch_id, shift_pattern_id });
    await util.post("shift/delete_shift_pattern", { branch_id, shift_pattern_id });
    shiftbundle_global.disable({ branch_id });
    monthly_list.disable({ branch_id });
  }
}

export default getModule(Shiftbundle_manager);
