import { Mutation, MutationAction, Action, VuexModule, getModule, Module } from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import Vue from 'vue';
import global from "@/vuex/nencho/global";

export type EmployeeHash = {
  [id: number]: string
}
export type LockTypeHash = {
  [id: number]: number
}
export type NenchoErrorHash = {
  [id: number]: {}
}
export type BranchObject = {
  [id: number]: string,
}
export type Data = {
  life_insurances: any,
  care_insurances: any,
  pension_insurances: any,
  earthquake_insurances: any,
  social_insurances: any,
  mutualaid_insurances: any,
  is_valid?: boolean,
  is_set: boolean,
  fetching?: Promise<void>
}
export type EmployeelyData = {
  [employee_id: number]: Data,
}
export type YearlyData = {
  [nencho_id: number]: EmployeelyData
}

@Module({ dynamic: true, store, name: "nencho_insurance_yearly_data", namespaced: true })
class NenchoYearly extends VuexModule {
  data: YearlyData = {};

  @Mutation
  public set({nencho_id, employee_id, data}: {nencho_id: number, employee_id: number, data}) {
    Vue.set(this.data[nencho_id], employee_id, {
      ...data,
      is_valid: true,
      is_set: true,
      fetching: null,
    });
  }
  @Mutation
  public disable({nencho_id, employee_id}: {nencho_id?: number, employee_id?: number}) {
    if (nencho_id === undefined) {
      if (employee_id === undefined) {
        Object.keys(this.data).forEach(nencho_id => {
          Object.keys(this.data[nencho_id]).forEach(employee_id => {
            this.data[nencho_id][employee_id].is_valid = false;
          });
        });
      } else {
        Object.keys(this.data).forEach(nencho_id => {
          if (this.data[nencho_id][employee_id]) {
            this.data[nencho_id][employee_id].is_valid = false;
          }
        });
      }
    } else {
      if (employee_id === undefined) {
        if (this.data[nencho_id]) {
          Object.keys(this.data[nencho_id]).forEach(employee_id => {
            if (this.data[nencho_id][employee_id]) {
              this.data[nencho_id][employee_id].is_valid = false;
            }
          });
        }
      } else {
        if (this.data[nencho_id] && this.data[nencho_id][employee_id]) {
          this.data[nencho_id][employee_id].is_valid = false;
        }
      }
    }
  }
  @Mutation
  public disable_all() {
    Object.keys(this.data).forEach(nencho_id => {
      Object.keys(this.data[nencho_id]).forEach(employee_id => {
        this.data[nencho_id][employee_id].is_valid = false;
      });
    });
  }
  @Mutation
  public setFetching({nencho_id, employee_id, value}: {nencho_id: number, employee_id: number, value: Promise<void>}) {
    this.data[nencho_id][employee_id].fetching = value;
  }


  // actions
  @Action({ rawError: true })
  public async fetch({nencho_id, employee_id}: {nencho_id: number, employee_id: number}) {
    //ないなら作る
    if (!this.data[nencho_id]) {
      Vue.set(this.data, nencho_id, {});
    }
    if (!this.data[nencho_id][employee_id]) {
      Vue.set(this.data[nencho_id], employee_id, {
        life_insurances: {},
        care_insurances: {},
        pension_insurances: {},
        earthquake_insurances: {},
        social_insurances: {},
        mutualaid_insurances: {},
        is_valid: false,
        is_set: false,
        fetching: null,
      });
    }

    if (this.data[nencho_id][employee_id].fetching) {
      await this.data[nencho_id][employee_id].fetching;
    } else {
      const promise = util.post('nencho/read_insurance_yearly', {nencho_id, employee_id}).then((res) => {
        this.set({nencho_id, employee_id, data: res.data});
      });
      this.setFetching({nencho_id, employee_id, value: promise});
      await promise;
    }
  }
}

export default getModule(NenchoYearly);