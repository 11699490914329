import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import io from "socket.io-client";
import * as env from "@/env.js";

import vuex_data from "@/vuex/vuex_data";
import vuex_manager from "@/vuex/vuex_manager";
import { useQueryClient } from "@tanstack/vue-query";

//VUEX
//shift
import { default as shift_global } from "@/vuex/shift/global";
import { default as shift_daily } from "@/vuex/shift/daily";
import { default as shift_daily_employee } from "@/vuex/shift/daily_employee";
import { default as shift_monthly } from "@/vuex/shift/monthly";
import { default as shift_monthly_employee } from "@/vuex/shift/monthly_employee";
import { default as shift_monthly_summery } from "@/vuex/shift/monthly_summery";
import { default as shift_daily_summery } from "@/vuex/shift/daily_summery";
import { default as shift_need_global } from "@/vuex/shift/need_global";
import { default as shift_shiftbundle_global } from "@/vuex/shift/shiftbundle_global";
import { default as shift_monthly_list } from "@/vuex/shift/monthly_list";

//submit
import { default as submit_global } from "@/vuex/submit/global";
import { default as submit_monthly } from "@/vuex/submit/monthly";

//attendance
import { default as attendance_global } from "@/vuex/attendance/global";
import { default as attendance_monthly } from "@/vuex/attendance/monthly";

//attendance_each
import { default as attendance_each_global } from "@/vuex/attendance_each/global";
import { default as attendance_each_monthly } from "@/vuex/attendance_each/monthly";

//payment
import { default as payment_global } from "@/vuex/payment/global";
import { default as payment_monthly } from "@/vuex/payment/monthly";
import { default as stdincome_monthly } from "@/vuex/payment/monthly_stdincome";

//payment_each
import { default as payment_each_global } from "@/vuex/payment_each/global";
import { default as payment_each_monthly } from "@/vuex/payment_each/monthly";

//bonus
import { default as bonus_global } from "@/vuex/bonus/global";
import { default as bonus_monthly } from "@/vuex/bonus/monthly";

//bonus_each
import { default as bonus_each_global } from "@/vuex/bonus_each/global";
import { default as bonus_each_monthly } from "@/vuex/bonus_each/monthly";

//company
import { default as company_global } from "@/vuex/company/global";
import { default as company_branch } from "@/vuex/company/branch";

//employee
import { default as employee_global } from "@/vuex/employee/global";
import { default as employee_now } from "@/vuex/employee/now";
import { default as employee_order } from "@/vuex/employee/order";
import { default as employee_join } from "@/vuex/employee/join";
import { default as employee_retire } from "@/vuex/employee/retire";

//employee_each
import { default as employee_each_global } from "@/vuex/employee_each/global";
import { default as employee_each_employeely } from "@/vuex/employee_each/employeely";
import { default as employee_each_base } from "@/vuex/employee_each/base";
import { default as employee_each_payment } from "@/vuex/employee_each/payment";
import { default as employee_each_auth } from "@/vuex/employee_each/auth";
import { default as employee_each_family } from "@/vuex/employee_each/family";
import { default as employee_each_holiday } from "@/vuex/employee_each/holiday";
import { default as employee_each_belong } from "@/vuex/employee_each/belong";
import { default as employee_each_info } from "@/vuex/employee_each/info";
import { default as employee_each_config } from "@/vuex/employee_each/config";

//config
import { default as config_base } from "@/vuex/config/base";
import { default as config_allowance } from "@/vuex/config/allowance";
import { default as config_info } from "@/vuex/config/info_old";
import { default as config_template } from "@/vuex/config/template";
import { default as config_template_each } from "@/vuex/config/template_each";
import { default as config_group } from "@/vuex/config/group";
import { default as config_branch_global } from "@/vuex/config/branch_global";
import { default as config_branch } from "@/vuex/config/branch";
import { default as config_paymentgroup_branchly } from "@/vuex/config/paymentgroup_branchly";
import { default as config_shiftgroup_branchly } from "@/vuex/config/shiftgroup_branchly";
import { default as config_branch_bank } from "@/vuex/config/branch_bank";

//demand_holiday
import { default as demand_holiday_global } from "@/vuex/demand_holiday/global";

//demand_overtime
import { default as demand_overtime_global } from "@/vuex/demand_overtime/global";
import { default as demand_overtime_shiftly } from "@/vuex/demand_overtime/shiftly";

//demand_punch
import { default as demand_punch_monthly_demand } from "@/vuex/demand_punch/monthly_demand";

//demand_change
import { default as demand_change_global } from "@/vuex/demand_change/global";
import { default as demand_change_employeely } from "@/vuex/demand_change/employeely";

//punch
import { default as punch_global } from "@/vuex/punch/global";

//header
import { default as header } from "@/vuex/header";

//aggregation
import { default as aggregation_global } from "@/vuex/aggregation/global";

//document
import { default as document_global } from "@/vuex/document/global";
import { default as document_branch_monthly } from "@/vuex/document/branch_monthly";
import { default as document_branch_yearly } from "@/vuex/document/branch_yearly";
import { default as document_employee_monthly } from "@/vuex/document/employee_monthly";
import { default as document_employee_yearly } from "@/vuex/document/employee_yearly";
import { default as document_employee_retired } from "@/vuex/document/employee_retired";
import { default as document_history } from "@/vuex/document/history";
import { default as document_history_employeely } from "@/vuex/document/history_employeely";
import { default as document_contract_document } from "@/vuex/document/contract_document";
import { default as document_waiting } from "@/vuex/document/waiting";

//nencho
import { default as nencho_global } from "@/vuex/nencho/global";
import { default as nencho_employeely } from "@/vuex/nencho/employeely";
import { default as nencho_yearly } from "@/vuex/nencho/yearly";
import { default as nencho_employee_yearly } from "@/vuex/nencho/employee_yearly";
import { default as nencho_insurance_yearly } from "@/vuex/nencho/insurance_yearly";
import { default as nencho_prev_payment_yearly } from "@/vuex/nencho/prev_payment_yearly";
import { default as nencho_loan_yearly } from "@/vuex/nencho/loan_yearly";

//fixed_tax_reduction
import { default as fixed_tax_reduction_global } from "@/vuex/fixed_tax_reduction/global";
import { default as fixed_tax_reduction_employeely } from "@/vuex/fixed_tax_reduction/employeely";
import { default as fixed_tax_reduction_yearly } from "@/vuex/fixed_tax_reduction/yearly";
import { default as fixed_tax_reduction_employee_yearly } from "@/vuex/fixed_tax_reduction/employee_yearly";

//wizard
import { default as wizard_global } from "@/vuex/wizard/global";

//order
import { default as order_global } from "@/vuex/order/global";

//bundle
import { default as bundle_global } from "@/vuex/bundle/global";
import { default as bundle_resident } from "@/vuex/bundle/resident";

//MANAGER
//shift
import { default as shift_manager } from "@/vuex/shift/manager";
import { default as shift_bundle_manager } from "@/vuex/shift/shiftbundle_manager";
import { default as need_manager } from "@/vuex/shift/need_manager";

//submit
import { default as submit_manager } from "@/vuex/submit/manager";

//attendance
import { default as attendance_manager } from "@/vuex/attendance/manager";

//attendance_each
import { default as attendance_each_manager } from "@/vuex/attendance_each/manager";

//payment
import { default as payment_manager } from "@/vuex/payment/manager";

//payment_each
import { default as payment_each_manager } from "@/vuex/payment_each/manager";

//bonus
import { default as bonus_manager } from "@/vuex/bonus/manager";

//bonus_each
import { default as bonus_each_manager } from "@/vuex/bonus_each/manager";

//company
import { default as company_manager } from "@/vuex/company/manager";

//employee
import { default as employee_manager } from "@/vuex/employee/manager";

//config
import { default as config_manager } from "@/vuex/config/manager";

//demand_holiday
import { default as demand_holiday_manager } from "@/vuex/demand_holiday/manager";

//demand_overtime
import { default as demand_overtime_manager } from "@/vuex/demand_overtime/manager";

//demand_punch
import { default as demand_punch_manager } from "@/vuex/demand_punch/manager";

//demand_change

//punch
import { default as punch_manager } from "@/vuex/punch/manager";

//header

//aggregation
import { default as aggregation_manager } from "@/vuex/aggregation/global";

//document
import { default as document_manager } from "@/vuex/document/manager";

//nencho
import { default as nencho_manager } from "@/vuex/nencho/manager";

//fixed_tax_reduction
import { default as fixed_tax_reduction_manager } from "@/vuex/fixed_tax_reduction/manager";

//bundle
import { default as bundle_manager } from "@/vuex/bundle/manager";

const vuex_object = {
  shift_global,
  shift_daily,
  shift_daily_employee,
  shift_monthly,
  shift_monthly_employee,
  shift_monthly_summery,
  shift_daily_summery,
  shift_need_global,
  shift_shiftbundle_global,
  shift_monthly_list,

  //submit
  submit_global,
  submit_monthly,

  //attendance
  attendance_global,
  attendance_monthly,

  //attendance_each
  attendance_each_global,
  attendance_each_monthly,

  //payment
  payment_global,
  payment_monthly,
  stdincome_monthly,

  //payment_each
  payment_each_global,
  payment_each_monthly,

  //bonus
  bonus_global,
  bonus_monthly,

  //bonus_each
  bonus_each_global,
  bonus_each_monthly,

  //employee
  employee_global,
  employee_now,
  employee_order,
  employee_join,
  employee_retire,

  //employee_each
  employee_each_payment,
  employee_each_auth,

  //config
  config_template,
  config_template_each,
  config_branch_global,
  config_paymentgroup_branchly,
  config_branch_bank,

  //demand_holiday
  demand_holiday_global,

  //demand_overtime
  demand_overtime_global,
  demand_overtime_shiftly,

  //demand_punch
  demand_punch_monthly_demand,

  //demand_change
  demand_change_global,
  demand_change_employeely,

  //punch
  punch_global,

  //header
  header,

  //aggregation
  aggregation_global,

  //document
  document_global,
  document_branch_monthly,
  document_branch_yearly,
  document_employee_monthly,
  document_employee_yearly,
  document_employee_retired,
  document_history,
  document_history_employeely,
  document_contract_document,
  document_waiting,

  //nencho
  nencho_global: vuex_data.nencho_global,
  nencho_employeely: vuex_data.nencho_employeely,
  nencho_yearly,
  nencho_employee_yearly,
  nencho_insurance_yearly,
  nencho_prev_payment_yearly,
  nencho_loan_yearly,

  //fixed_tax_reduction
  fixed_tax_reduction_global: vuex_data.fixed_tax_reduction_global,
  fixed_tax_reduction_employeely: vuex_data.fixed_tax_reduction_employeely,
  fixed_tax_reduction_yearly,
  fixed_tax_reduction_employee_yearly,

  //wizard
  wizard_global,

  //order
  order_global,

  //bundle
  bundle_global,
  bundle_resident,

  //laborins
  laborins_global: vuex_data.laborins_global,
  laborins_yearly: vuex_data.laborins_yearly,

  //stdincome
  renew_stdincome_global: vuex_data.renew_stdincome_global,
  renew_stdincome_yearly: vuex_data.renew_stdincome_yearly,
  update_stdincome_global: vuex_data.update_stdincome_global,
  update_stdincome_yearly: vuex_data.update_stdincome_yearly,
  update_stdincome_candidate: vuex_data.update_stdincome_candidate,

  //notification
  notification_global: vuex_data.notification_global,

  //config
  config_base: vuex_data.config_base,
  config_allowance: vuex_data.config_allowance,
  config_group: vuex_data.config_group,
  config_individualunit_global: vuex_data.config_individualunit_global,
  config_info: vuex_data.config_info,

  //employee_each
  employee_each_individualunit_employeely: vuex_data.employee_each_individualunit_employeely,

  //company
  company_global: vuex_data.company_global,
  company_branch: vuex_data.company_branch,

  //employee_each
  employee_each_global: vuex_data.employee_each_global,
  employee_each_employeely: vuex_data.employee_each_employeely,
  employee_each_base: vuex_data.employee_each_base,
  employee_each_holiday: vuex_data.employee_each_holiday,
  employee_each_info: vuex_data.employee_each_info,
  employee_each_belong: vuex_data.employee_each_belong,
  employee_each_family: vuex_data.employee_each_family,
  employee_each_config: vuex_data.employee_each_config,

  //almighty
  almighty_employee_now: vuex_data.almighty_employee_now,
  almighty_employee_sub_now: vuex_data.almighty_employee_sub_now,
  almighty_branch_now: vuex_data.almighty_branch_now,
};

const manager_object = {
  //MANAGER
  //shift
  shift_manager,
  shift_bundle_manager,
  need_manager,

  //submit
  submit_manager,

  //attendance
  attendance_manager,

  //attendance_each
  attendance_each_manager,

  //payment
  payment_manager,

  //payment_each
  payment_each_manager,

  //bonus
  bonus_manager,

  //bonus_each
  bonus_each_manager,

  //employee
  employee_manager,

  //demand_holiday
  demand_holiday_manager,

  //demand_overtime
  demand_overtime_manager,

  //demand_punch
  demand_punch_manager,

  //demand_change

  //punch

  //header

  //aggregation

  //document

  //nencho
  nencho_manager,

  //bundle
  bundle_manager,

  //laborins
  laborins_manager: vuex_manager.laborins_manager,

  //renew_stdincome
  renew_stdincome_manager: vuex_manager.renew_stdincome_manager,

  //update_stdincome
  update_stdincome_manager: vuex_manager.update_stdincome_manager,

  //config
  config_manager: vuex_manager.config_manager,

  //company
  company_namaer: vuex_manager.company_manager,

  //employee_each
  employee_each_manager: vuex_manager.employee_each_manager,
};

@Module({ dynamic: true, store, name: "node", namespaced: true })
class node extends VuexModule {
  socket: any = {};

  @Mutation
  setSocket(val) {
    this.socket = val;
  }

  // actions
  @Action({ rawError: true })
  async connect() {
    //受け取るメソッド達 これ自体は送信メソッド
    /*====================updateSocket.ioのための定数====================*/

    var host = "https://arrow-payment.com"; //サーバー
    var option = {
      "force new connection": true,
      path: new URL(env.api_prefix, host).pathname + "/socket.io",
    };
    /*========================================*/

    var socket = io.connect(host, option); //接続実行
    this.setSocket(socket);
    this.regist(); //まず、つなぐ。company_idとuser_idを送れよ。

    this.socket.on("node", async (packet) => {
      console.log("receive node");
      console.log(packet);
      var data = packet.data;
      console.log(data);
      this.disable(data);
    });

    //接続==========
    this.socket.on("connect", function () {
      if (socket != "") {
        //socket.heartbeatTimeout = 5000;　いらなかった
      }
    });
    //====================

    //切断受信==========
    this.socket.on("disconnect", function (e) {
      if (e == "ping timeout") {
      }
    });
    //====================

    //サーバーが死んでる？受信==========
    this.socket.on("connect_error", function (e) {});
    //====================

    //例外エラー受信==========
    this.socket.on("error", function (reason) {});
    //====================
  }
  /*========================================*/

  @Action({ rawError: true })
  async regist() {
    //コネクション確立依頼
    var employee_id = Number(util.getLocalStorage("employee_id"));
    var company_id = Number(util.getLocalStorage("company_id"));
    this.socket.emit("regist", { user_id: employee_id, company_id: company_id }); //頼む。入れてくれ。
  }

  @Action({ rawError: true })
  async disable_all() {
    // const client = useQueryClient();
    // client.invalidateQueries();
    Object.keys(vuex_object).forEach((name) => {
      vuex_object[name].disable_all();
    });
  }

  @Action({ rawError: true })
  async disable(data = null) {
    if (data === null || data == "all" || data == true) {
      this.disable_all();
    } else {
      data.forEach((object) => {
        vuex_object[object.name].disable(object.args);
      });
    }
  }

  @Action({ rawError: true })
  async cancel_all_vuex() {
    Object.keys(manager_object).forEach((name) => {
      manager_object[name].cancelAll();
    });
  }

  @Action({ rawError: true })
  async is_edit_all_vuex() {
    var result = false;
    Object.keys(manager_object).forEach((name) => {
      if (manager_object[name].isEdittingNew && manager_object[name].isAddingNew) {
        if (manager_object[name].isEdittingNew() || manager_object[name].isAddingNew()) {
          result = true;
        }
      }
    });
    return result;
  }
}

export default getModule(node);
