
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import dialog from "@/vuex/dialog";
  import mixinNotification from "@/mixins/mixinNotification";
  import HeaderWizard from "@/components/header/Wizard.vue";
  import HeaderNormal from "@/components/header/Normal.vue";
  import HeaderLogout from "@/components/header/Logout.vue";
  import InformNotification from "@/components/inform/Notification.vue";
  import InformAdminnotification from "@/components/inform/Adminnotification.vue";
  import InformSystemnotification from "@/components/inform/Systemnotification.vue";

  @Component({
    components: {
      HeaderWizard,
      HeaderNormal,
      HeaderLogout,
      InformNotification,
      InformAdminnotification,
      InformSystemnotification,
    },
  })
  export default class Header extends Mixins(mixinNotification) {
    created() {
      //初めにセットしておかないと変更検知がされない
      this.m.setTmpObjects({ name: "notification_id", val: null });
      this.m.setTmpObjects({ name: "admin_notification_id", val: null });
      this.m.setTmpObjects({ name: "system_notification_id", val: null });
    }

    get is_wizard_page() {
      return this.isNowPage("wizard_config") || this.isNowPage("wizard_employee");
    }
    get line_theme() {
      if (this.isNowPage("shift") || this.isNowPage("submit")) {
        return "shift";
      } else if (
        this.isNowPage("attendance") ||
        this.isNowPage("attendance_each") ||
        this.isNowPage("demand_punch") ||
        this.isNowPage("punch_inside")
      ) {
        return "attendance";
      } else if (
        this.isNowPage("payment") ||
        this.isNowPage("payment_each") ||
        this.isNowPage("bonus") ||
        this.isNowPage("bonus_each") ||
        this.isNowPage("nencho")
      ) {
        return "payment";
      } else if (this.isNowPage("aggregation")) {
        return "aggregation";
      } else if (this.isNowPage("document")) {
        return "document";
      } else {
        return "other";
      }
    }
  }
