
  import { Component, Vue, Emit, Prop, Watch, Mixins } from "vue-property-decorator";
  import { Spin } from "ant-design-vue";

  @Component({
    components: {
      Spin,
    },
  })
  export default class Progress extends Vue {
    @Prop({ default: 15 }) width: number; //px
    @Prop({ default: 70 }) size: number; //px
    @Prop() height: number; //px

    get styles() {
      const height = this.height;
      let paddingtop = 0;
      if (this.height) {
        paddingtop = Math.floor((this.height - this.size) / 2);
      }

      return {
        "--height": `${height}px`,
        "--paddingtop": `${paddingtop}px`,
      };
    }
  }
