import { Spouce, Dependant } from "@/typesold/family";
import * as util from "@/util";
import * as vali from "@/validations/validationUtil";
import global from "@/vuex/employee_each/global";
import Earning from "@/entities/Earning";

//validationは、OKならTrue, それ以外なら警告文を配列に入れる。
export function validationSpouce(self, args) {
  var error = [];
  const index = args.index;

  var prefix = "";
  if (index) {
    prefix = "配偶者の";
  }

  //Base
  error.push(args.spouce.name !== null || util.$t("error.enter_spouce_name"));
  error.push(
    args.spouce.name === null ||
      vali.is_split_equal(args.spouce.name, " ", 2) ||
      util.$t("error.enter_spouce_name"),
  );
  error.push(args.spouce.kana !== null || util.$t("error.enter_spouce_kana"));
  error.push(
    args.spouce.kana === null ||
      vali.is_split_equal(args.spouce.kana, " ", 2) ||
      util.$t("error.enter_spouce_kana"),
  );
  error.push(args.spouce.birth_date !== null || util.$t("error.enter_spouce_birth_date"));
  error.push(
    args.spouce.mynum === null ||
      vali.is_equal(args.spouce.mynum, 12) ||
      util.$t("error.enter_spouce_mynum_in_12char"),
  );
  error.push(args.spouce.is_housemate !== null || util.$t("error.enter_spouce_housemate"));
  if (args.spouce.is_housemate == 0) {
    error.push(args.spouce.address !== null || util.$t("error.enter_spouce_address"));
  }

  //Self
  error.push(args.spouce.income !== null || util.$t("error.enter_spouce_income"));
  error.push(
    args.spouce.income === null ||
      vali.is_number(args.spouce.income) ||
      util.$t("error.enter_spouce_income_halfwidth"),
  );
  error.push(args.spouce.other_income !== null || util.$t("error.enter_spouce_other_income"));
  error.push(
    args.spouce.other_income === null ||
      vali.is_number(args.spouce.other_income) ||
      util.$t("error.enter_spouce_other_income_halfwidth"),
  );
  error.push(args.spouce.retire_income !== null || util.$t("error.enter_spouce_retire_income"));
  error.push(
    args.spouce.retire_income === null ||
      vali.is_number(args.spouce.retire_income) ||
      util.$t("error.enter_spouce_retire_income_halfwidth"),
  );
  error.push(
    args.spouce.retire_income === null ||
      args.spouce.other_income === null ||
      !vali.is_number(args.spouce.retire_income) ||
      !vali.is_number(args.spouce.other_income) ||
      args.spouce.other_income >= 0 ||
      util.$t("error.spouce_retire_income_more_than_other_income"),
  );
  error.push(args.spouce.disability_type !== null || util.$t("error.select_spouce_disability"));

  if (args.spouce.is_housemate == 0) {
    if (args.spouce.disability_type == 2) {
      error.push(
        args.spouce.is_special_disability_housemate !== null ||
          util.$t("error.select_spouce_special_disability_housemate"),
      );
    }
    error.push(args.spouce.is_not_live !== null || util.$t("error.select_spouce_is_not_live"));
    if (args.spouce.is_not_live == 1) {
      error.push(
        args.spouce.not_live_money !== null || util.$t("error.enter_spouce_not_live_money"),
      );
      error.push(
        args.spouce.not_live_money === null ||
          vali.is_number(args.spouce.not_live_money) ||
          util.$t("error.enter_spouce_not_live_money_halfwidth"),
      );
    }
  }
  if (args.spouce.disability_type != 0) {
    error.push(
      args.spouce.disability_reason !== null || util.$t("error.enter_spouce_disability_reason"),
    );
  }

  return error;
}

export function validationSpouceForFixedTaxReduction(self, args) {
  var error = [];
  const index = args.index;

  var prefix = "";
  if (index) {
    prefix = "配偶者の";
  }

  error.push(
    args.spouce.is_resident !== null || util.$t("error.select_is_resident_fixed_tax_reduction"),
  );
  if (args.spouce.is_resident != 1) return error;

  error.push(args.spouce.income !== null || util.$t("error.enter_spouce_income"));
  error.push(
    args.spouce.income === null ||
      vali.is_number(args.spouce.income) ||
      util.$t("error.enter_spouce_income_halfwidth"),
  );
  error.push(args.spouce.other_income !== null || util.$t("error.enter_spouce_other_income"));
  error.push(
    args.spouce.other_income === null ||
      vali.is_number(args.spouce.other_income) ||
      util.$t("error.enter_spouce_other_income_halfwidth"),
  );

  error.push(args.spouce.retire_income !== null || util.$t("error.enter_spouce_retire_income"));
  error.push(
    args.spouce.retire_income === null ||
      vali.is_number(args.spouce.retire_income) ||
      util.$t("error.enter_spouce_retire_income_halfwidth"),
  );
  error.push(
    args.spouce.retire_income === null ||
      args.spouce.other_income === null ||
      !vali.is_number(args.spouce.retire_income) ||
      !vali.is_number(args.spouce.other_income) ||
      args.spouce.other_income >= 0 ||
      util.$t("error.spouce_retire_income_more_than_other_income"),
  );

  if (args.spouce.income === null || args.spouce.other_income === null) return error;

  const earning = new Earning();
  const spouce_earning =
    earning.income2earning(Number(args.spouce.income)) + Number(args.spouce.other_income);
  if (spouce_earning > 480000) return error;

  //Base
  error.push(args.spouce.name !== null || util.$t("error.enter_spouce_name"));
  error.push(
    args.spouce.name === null ||
      vali.is_split_equal(args.spouce.name, " ", 2) ||
      util.$t("error.enter_spouce_name"),
  );
  error.push(args.spouce.kana !== null || util.$t("error.enter_spouce_kana"));
  error.push(
    args.spouce.kana === null ||
      vali.is_split_equal(args.spouce.kana, " ", 2) ||
      util.$t("error.enter_spouce_kana"),
  );
  error.push(args.spouce.birth_date !== null || util.$t("error.enter_spouce_birth_date"));
  error.push(
    args.spouce.mynum === null ||
      vali.is_equal(args.spouce.mynum, 12) ||
      util.$t("error.enter_spouce_mynum_in_12char"),
  );
  error.push(args.spouce.is_housemate !== null || util.$t("error.enter_spouce_housemate"));
  if (args.spouce.is_housemate == 0) {
    error.push(args.spouce.address !== null || util.$t("error.enter_spouce_address"));
  }

  //Self
  error.push(args.spouce.disability_type !== null || util.$t("error.select_spouce_disability"));

  if (args.spouce.is_housemate == 0) {
    if (args.spouce.disability_type == 2) {
      error.push(
        args.spouce.is_special_disability_housemate !== null ||
          util.$t("error.select_spouce_special_disability_housemate"),
      );
    }
    error.push(args.spouce.is_not_live !== null || util.$t("error.select_spouce_is_not_live"));
    if (args.spouce.is_not_live == 1) {
      error.push(
        args.spouce.not_live_money !== null || util.$t("error.enter_spouce_not_live_money"),
      );
      error.push(
        args.spouce.not_live_money === null ||
          vali.is_number(args.spouce.not_live_money) ||
          util.$t("error.enter_spouce_not_live_money_halfwidth"),
      );
    }
  }
  if (args.spouce.disability_type != 0) {
    error.push(
      args.spouce.disability_reason !== null || util.$t("error.enter_spouce_disability_reason"),
    );
  }

  return error;
}

export function validationDependant(self, args) {
  var error = [];
  let index = args.index;

  if (index === 0) {
    index = "";
  }

  //Base
  error.push(
    args.dependant.name !== null || util.$t("error.enter_dependant_name", { index: index }),
  );
  error.push(
    args.dependant.name === null ||
      vali.is_split_equal(args.dependant.name, " ", 2) ||
      util.$t("error.enter_dependant_name", { index: index }),
  );
  error.push(
    args.dependant.kana !== null || util.$t("error.enter_dependant_kana", { index: index }),
  );
  error.push(
    args.dependant.kana === null ||
      vali.is_split_equal(args.dependant.kana, " ", 2) ||
      util.$t("error.enter_dependant_kana", { index: index }),
  );
  error.push(
    args.dependant.birth_date !== null ||
      util.$t("error.enter_dependant_birth_date", { index: index }),
  );
  error.push(
    args.dependant.mynum === null ||
      vali.is_equal(args.dependant.mynum, 12) ||
      util.$t("error.enter_dependant_mynum_in_12char", { index: index }),
  );
  error.push(
    args.dependant.dependant_relationship_id !== null ||
      util.$t("error.enter_dependant_relationship", { index: index }),
  );
  error.push(
    args.dependant.is_housemate !== null ||
      util.$t("error.enter_dependant_housemate", { index: index }),
  );
  if (args.dependant.is_housemate == 0) {
    if (is_disp_old_spouce_housemate(args.is_spouce, args.dependant.birth_date)) {
      error.push(
        args.dependant.is_old_spouce_housemate !== null ||
          util.$t("error.enter_dependant_old_spouce_housemate", { index: index }),
      );
    }
    error.push(
      args.dependant.address !== null || util.$t("error.enter_dependant_address", { index: index }),
    );
  }

  //Self
  error.push(
    args.dependant.income !== null || util.$t("error.enter_dependant_income", { index: index }),
  );
  error.push(
    args.dependant.income === null ||
      vali.is_number(args.dependant.income) ||
      util.$t("error.enter_dependant_income_halfwidth", { index: index }),
  );
  error.push(
    args.dependant.other_income !== null ||
      util.$t("error.enter_dependant_other_income", { index: index }),
  );
  error.push(
    args.dependant.other_income === null ||
      vali.is_number(args.dependant.other_income) ||
      util.$t("error.enter_dependant_other_income_halfwidth", { index: index }),
  );
  error.push(
    args.dependant.retire_income !== null ||
      util.$t("error.enter_dependant_retire_income", { index: index }),
  );
  error.push(
    args.dependant.retire_income === null ||
      vali.is_number(args.dependant.retire_income) ||
      util.$t("error.enter_dependant_retire_income_halfwidth", { index: index }),
  );
  error.push(
    args.dependant.retire_income === null ||
      args.dependant.other_income === null ||
      !vali.is_number(args.dependant.retire_income) ||
      !vali.is_number(args.dependant.other_income) ||
      args.dependant.other_income >= 0 ||
      util.$t("error.error.dependant_retire_income_more_than_other_income"),
  );
  error.push(
    args.dependant.disability_type !== null ||
      util.$t("error.select_dependant_disability", { index: index }),
  );
  error.push(
    args.dependant.is_child_allowance !== null ||
      util.$t("error.select_dependant_child_allowance", { index: index }),
  );

  if (args.dependant.is_housemate == 0) {
    if (args.dependant.disability_type == 2) {
      error.push(
        args.dependant.is_special_disability_housemate !== null ||
          util.$t("error.dependant_housemate_with_livelihood", { index: index }),
      );
    }
    error.push(
      args.dependant.is_not_live !== null ||
        util.$t("error.select_dependant_is_not_live", { index: index }),
    );
    if (args.dependant.is_not_live == 1) {
      error.push(
        args.dependant.not_live_money !== null ||
          util.$t("error.enter_dependant_not_live_money", { index: index }),
      );
      error.push(
        args.dependant.not_live_money === null ||
          vali.is_number(args.dependant.not_live_money) ||
          util.$t("error.enter_dependant_not_live_money_halfwidth", { index: index }),
      );
    }
  }
  if (args.dependant.disability_type != 0) {
    error.push(
      args.dependant.disability_reason !== null ||
        util.$t("error.enter_dependant_disability_reason", { index: index }),
    );
  }

  return error;
}

export function validationDependantForFixedTaxReduction(self, args) {
  var error = [];
  let index = args.index;

  if (index === 0) {
    index = "";
  }

  if (args.dependant.is_resident == 0) return error;
  if (args.dependant.is_housemate == 0 && args.dependant.is_not_live == 1) return error;
  const earning = new Earning();
  const dependant_earning =
    earning.income2earning(Number(args.dependant.income)) + Number(args.dependant.other_income);
  if (dependant_earning > 480000) return error;

  //Base
  error.push(
    args.dependant.name !== null || util.$t("error.enter_dependant_name", { index: index }),
  );
  error.push(
    args.dependant.name === null ||
      vali.is_split_equal(args.dependant.name, " ", 2) ||
      util.$t("error.enter_dependant_name", { index: index }),
  );
  error.push(
    args.dependant.kana !== null || util.$t("error.enter_dependant_kana", { index: index }),
  );
  error.push(
    args.dependant.kana === null ||
      vali.is_split_equal(args.dependant.kana, " ", 2) ||
      util.$t("error.enter_dependant_kana", { index: index }),
  );
  error.push(
    args.dependant.birth_date !== null ||
      util.$t("error.enter_dependant_birth_date", { index: index }),
  );
  error.push(
    args.dependant.mynum === null ||
      vali.is_equal(args.dependant.mynum, 12) ||
      util.$t("error.enter_dependant_mynum_in_12char", { index: index }),
  );
  error.push(
    args.dependant.dependant_relationship_id !== null ||
      util.$t("error.enter_dependant_relationship", { index: index }),
  );
  error.push(
    args.dependant.is_housemate !== null ||
      util.$t("error.enter_dependant_housemate", { index: index }),
  );
  if (args.dependant.is_housemate == 0) {
    if (is_disp_old_spouce_housemate(args.is_spouce, args.dependant.birth_date)) {
      error.push(
        args.dependant.is_old_spouce_housemate !== null ||
          util.$t("error.enter_dependant_old_spouce_housemate", { index: index }),
      );
    }
    error.push(
      args.dependant.address !== null || util.$t("error.enter_dependant_address", { index: index }),
    );
  }

  //Self
  error.push(
    args.dependant.income !== null || util.$t("error.enter_dependant_income", { index: index }),
  );
  error.push(
    args.dependant.income === null ||
      vali.is_number(args.dependant.income) ||
      util.$t("error.enter_dependant_income_halfwidth", { index: index }),
  );
  error.push(
    args.dependant.other_income !== null ||
      util.$t("error.enter_dependant_other_income", { index: index }),
  );
  error.push(
    args.dependant.other_income === null ||
      vali.is_number(args.dependant.other_income) ||
      util.$t("error.enter_dependant_other_income_halfwidth", { index: index }),
  );
  error.push(
    args.dependant.retire_income !== null ||
      util.$t("error.enter_dependant_retire_income", { index: index }),
  );
  error.push(
    args.dependant.retire_income === null ||
      vali.is_number(args.dependant.retire_income) ||
      util.$t("error.enter_dependant_retire_income_halfwidth", { index: index }),
  );
  error.push(
    args.dependant.retire_income === null ||
      args.dependant.other_income === null ||
      !vali.is_number(args.dependant.retire_income) ||
      !vali.is_number(args.dependant.other_income) ||
      args.dependant.other_income >= 0 ||
      util.$t("error.error.dependant_retire_income_more_than_other_income"),
  );
  error.push(
    args.dependant.disability_type !== null ||
      util.$t("error.select_dependant_disability", { index: index }),
  );
  error.push(
    args.dependant.is_child_allowance !== null ||
      util.$t("error.select_dependant_child_allowance", { index: index }),
  );

  if (args.dependant.is_housemate == 0) {
    if (args.dependant.disability_type == 2) {
      error.push(
        args.dependant.is_special_disability_housemate !== null ||
          util.$t("error.dependant_housemate_with_livelihood", { index: index }),
      );
    }
    error.push(
      args.dependant.is_not_live !== null ||
        util.$t("error.select_dependant_is_not_live", { index: index }),
    );
    if (args.dependant.is_not_live == 1) {
      error.push(
        args.dependant.not_live_money !== null ||
          util.$t("error.enter_dependant_not_live_money", { index: index }),
      );
      error.push(
        args.dependant.not_live_money === null ||
          vali.is_number(args.dependant.not_live_money) ||
          util.$t("error.enter_dependant_not_live_money_halfwidth", { index: index }),
      );
    }
  }
  if (args.dependant.disability_type != 0) {
    error.push(
      args.dependant.disability_reason !== null ||
        util.$t("error.enter_dependant_disability_reason", { index: index }),
    );
  }

  return error;
}

function is_disp_old_spouce_housemate(is_spouce, birth_date) {
  return is_spouce && is_over_seventy(birth_date);
}
function is_over_seventy(birth_date) {
  return util.age(birth_date, util.obj2year() + "-01-01") >= 69;
}
