import Bonus_info from "@/typesold/Bonus_info";
import * as util from "@/util";
import * as vali from "@/validations/validationUtil";

export function validationEachBonusPayments(
  each_bonus_payments,
  employee_hash,
  saved_bonus_allowances,
  saved_bonus_deductions,
  saved_bonus_other_allowances,
) {
  var error = [];

  Object.keys(each_bonus_payments).forEach((employee_id) => {
    error = error.concat(
      validationEachBonusPayment(
        each_bonus_payments[employee_id],
        employee_hash[employee_id],
        saved_bonus_allowances,
        saved_bonus_deductions,
        saved_bonus_other_allowances,
        true,
      ),
    );
  });

  return error;
}
export function validationEachBonusPayment(
  each_bonus_payment,
  employee,
  saved_bonus_allowances,
  saved_bonus_deductions,
  saved_bonus_other_allowances,
  is_multi,
) {
  var error = [];

  var employee_name = "";
  if (is_multi) {
    employee_name = employee.name + "さんの";
  }

  error.push(
    each_bonus_payment.money === null ||
      vali.is_number(each_bonus_payment.money) ||
      employee_name + "賞与は半角数字で入力してください",
  );
  saved_bonus_allowances.forEach((bonus_allowance) => {
    if (each_bonus_payment.each_bonus_allowances[bonus_allowance.id]) {
      error.push(
        each_bonus_payment.each_bonus_allowances[bonus_allowance.id].money === null ||
          vali.is_number(each_bonus_payment.each_bonus_allowances[bonus_allowance.id].money) ||
          employee_name + bonus_allowance.name + "は半角数字で入力してください",
      );
    }
  });

  error.push(
    each_bonus_payment.health_ins === null ||
      vali.is_number(each_bonus_payment.health_ins) ||
      employee_name + "健康保険料は半角数字で入力してください",
  );
  error.push(
    each_bonus_payment.care_ins === null ||
      vali.is_number(each_bonus_payment.care_ins) ||
      employee_name + "介護保険料は半角数字で入力してください",
  );
  error.push(
    each_bonus_payment.pension_ins === null ||
      vali.is_number(each_bonus_payment.pension_ins) ||
      employee_name + "厚生年金保険料は半角数字で入力してください",
  );
  error.push(
    each_bonus_payment.emp_ins === null ||
      vali.is_number(each_bonus_payment.emp_ins) ||
      employee_name + "雇用保険料は半角数字で入力してください",
  );
  error.push(
    each_bonus_payment.social_ins_adj === null ||
      vali.is_number(each_bonus_payment.social_ins_adj) ||
      employee_name + "社会保険料等調整は半角数字で入力してください",
  );
  error.push(
    each_bonus_payment.income_tax === null ||
      vali.is_number(each_bonus_payment.income_tax) ||
      employee_name + "所得税は半角数字で入力してください",
  );
  error.push(
    each_bonus_payment.fixed_tax_reduction === null ||
      vali.is_number(each_bonus_payment.fixed_tax_reduction) ||
      employee_name + "定額減税は半角数字で入力してください",
  );
  saved_bonus_deductions.forEach((bonus_deduction) => {
    if (each_bonus_payment.each_bonus_deductions[bonus_deduction.id]) {
      error.push(
        each_bonus_payment.each_bonus_deductions[bonus_deduction.id].money === null ||
          vali.is_number(each_bonus_payment.each_bonus_deductions[bonus_deduction.id].money) ||
          employee_name + bonus_deduction.name + "は半角数字で入力してください",
      );
    }
  });

  error.push(
    each_bonus_payment.yearend_adj === null ||
      vali.is_number(each_bonus_payment.yearend_adj) ||
      employee_name + "年末調整は半角数字で入力してください",
  );
  error.push(
    each_bonus_payment.settlement === null ||
      vali.is_number(each_bonus_payment.settlement) ||
      employee_name + "現物支給清算金は半角数字で入力してください",
  );
  saved_bonus_other_allowances.forEach((bonus_other_allowance) => {
    if (each_bonus_payment.each_bonus_other_allowances[bonus_other_allowance.id]) {
      error.push(
        each_bonus_payment.each_bonus_other_allowances[bonus_other_allowance.id].money === null ||
          vali.is_number(
            each_bonus_payment.each_bonus_other_allowances[bonus_other_allowance.id].money,
          ) ||
          employee_name + bonus_other_allowance.name + "は半角数字で入力してください",
      );
    }
  });

  error.push(
    each_bonus_payment.paid_cash === null ||
      vali.is_number(each_bonus_payment.paid_cash) ||
      employee_name + "現金支給額は半角数字で入力してください",
  );

  return error;
}
