import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import Vue from "vue";
import Employee from "@/typesold/employee";
import Order from "@/typesold/order";
import Branch from "@/typesold/branch";

export type OrderData = {
  employees: Employee[];
  employee_hash: {};
  orders: Order[];
  is_valid?: boolean;
  is_set: boolean;
  fetching?: Promise<void>;
};

export type BranchlyData = {
  [branch_id: number]: OrderData;
};

@Module({ dynamic: true, store, name: "employee_order", namespaced: true })
class Global extends VuexModule {
  data: BranchlyData = {};

  @Mutation
  public disable({ branch_id }: { branch_id: number }) {
    if (branch_id == 0) {
      Object.keys(this.data).forEach((branch_id) => {
        this.data[branch_id].is_valid = false;
      });
    } else {
      if (this.data[branch_id]) {
        this.data[branch_id].is_valid = false;
      }
    }
    if (this.data[0]) {
      this.data[0].is_valid = false;
    }
  }
  @Mutation
  public disable_all() {
    Object.keys(this.data).forEach((branch_id) => {
      this.data[branch_id].is_valid = false;
    });
  }
  @Mutation
  public setFetching({ branch_id, value }: { branch_id: number; value: Promise<void> }) {
    this.data[branch_id].fetching = value;
  }
  @Mutation
  public set({ data, branch_id }: { data: OrderData; branch_id: number }) {
    var employee_hash = util.createHashObject(data.employees, "id");
    Vue.set(this.data, branch_id, {
      ...data,
      employee_hash: employee_hash,
      is_valid: true,
      is_set: true,
      fetching: null,
    });
  }

  // actions
  @Action({ rawError: true })
  public async fetch({ branch_id }: { branch_id: number }) {
    //ないなら作る
    if (!this.data[branch_id]) {
      Vue.set(this.data, branch_id, {
        employees: {},
        employee_hash: {},
        orders: {},
        is_valid: false,
        is_set: false,
        fetching: null,
      });
    }

    if (this.data[branch_id].fetching) {
      await this.data[branch_id].fetching;
    } else {
      const promise = util.post("employee/read_order", { branch_id }).then((res) => {
        this.set({ data: res.data, branch_id });
      });
      this.setFetching({ branch_id, value: promise });
      await promise;
    }
  }
}

export default getModule(Global);
