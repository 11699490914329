import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import daily from "@/vuex/shift/daily";
import monthly from "@/vuex/shift/monthly";
import * as util from "@/util";
import validate from "@/validations/validate";
import { validationShift, validationDeleteShift } from "@/validations/shift";
import dialog from "@/vuex/dialog";
import Shift from "@/typesold/shift";
import Vue from "vue";
import node from "@/vuex/node";

@Module({ dynamic: true, store, name: "manager", namespaced: true })
class Manager extends VuexModule {
  mode: number = 0; //{0: 従来, 1: シフトB}
  color: string = null;
  disp_num: number = 10;
  daily_summery_disp_num: number = 10;
  drag_shift_id: any = null;
  drag_shift_pattern_id: any = null;
  add_shift_flag: boolean = false;
  edit_shift_flag: number = 0;
  able_to_shift_flag: number = 0;
  is_open_shift_dialog: boolean = false;
  is_open_chart_dialog: boolean = false;
  is_open_summery_dialog: boolean = false;
  is_open_daily_summery_dialog: boolean = false;
  is_open_need_dialog: boolean = false;
  is_open_shiftbundle_dialog: boolean = false;
  is_open_shift_table_dialog: boolean = false;
  is_open_shift_excel_dialog: boolean = false;
  is_open_delete_shift_dialog: boolean = false;
  is_open_shift_pattern_colorpicker_dialog: boolean = false;
  is_open_shift_manipulate_dialog: boolean = false;
  disp_need_error: boolean = false;
  shift_manipulate: any = null;
  list_right_click: any = null;
  is_open_list_right_click_dialog = false;

  shift: Shift = {
    // add や edit の際に使用 ver3 の tmp  managerにおく必要がある attendance_eachのrowspan問題
    id: null,
    employee_id: null,
    shift_shift: [],
    shift_punch: [],
    shift_break: [],
  };

  @Mutation
  public setDispNum(val) {
    this.disp_num = val;
  }
  public setDailySummeryDispNum(val) {
    this.disp_num = val;
  }

  @Mutation
  public setDragShiftId(val: any) {
    this.drag_shift_id = val;
  }
  @Mutation
  public resetDragShiftId() {
    this.drag_shift_id = null;
  }
  @Mutation
  public setDragShiftPatternId(val: any) {
    this.drag_shift_pattern_id = val;
  }
  @Mutation
  public resetDragShiftPatternId() {
    this.drag_shift_pattern_id = null;
  }

  @Mutation
  public setListRightClick(val) {
    this.list_right_click = val;
  }
  @Mutation
  public resetListRightClick() {
    this.list_right_click = null;
  }
  @Mutation
  public setIsOpenListRightClickDialog(val) {
    this.is_open_list_right_click_dialog = val;
  }

  @Mutation
  private setAddShiftFlag(val: boolean) {
    this.add_shift_flag = val;
  }
  @Mutation
  private setEditShiftFlag(val: number) {
    this.edit_shift_flag = val;
  }
  @Mutation
  private setAbleToShiftFlag(val: number) {
    this.able_to_shift_flag = val;
  }
  @Mutation
  public setIsOpenShiftDialog(val) {
    this.is_open_shift_dialog = val;
  }
  @Mutation
  public setIsOpenChartDialog(val) {
    this.is_open_chart_dialog = val;
  }
  @Mutation
  public setIsOpenSummeryDialog(val) {
    this.is_open_summery_dialog = val;
  }
  @Mutation
  public setIsOpenDailySummeryDialog(val) {
    this.is_open_daily_summery_dialog = val;
  }
  @Mutation
  public setIsOpenNeedDialog(val) {
    this.is_open_need_dialog = val;
  }
  @Mutation
  public setIsOpenShiftbundleDialog(val) {
    this.is_open_shiftbundle_dialog = val;
  }
  @Mutation
  public setIsOpenShiftTableDialog(val) {
    this.is_open_shift_table_dialog = val;
  }
  @Mutation
  public setIsOpenShiftExcelDialog(val) {
    this.is_open_shift_excel_dialog = val;
  }
  @Mutation
  public setIsOpenDeleteShiftDialog(val) {
    this.is_open_delete_shift_dialog = val;
  }
  @Mutation
  public setIsOpenShiftPatternColorpickerDialog(val) {
    this.is_open_shift_pattern_colorpicker_dialog = val;
  }
  @Mutation
  public setIsOpenShiftManipulateDialog(val) {
    console.log(val);
    this.is_open_shift_manipulate_dialog = val;
  }

  @Mutation
  public setDispNeedError(val) {
    this.disp_need_error = val;
  }

  @Mutation
  public setManipulate(val) {
    this.shift_manipulate = val;
  }
  @Mutation
  public resetManipulate() {
    this.shift_manipulate = null;
  }

  @Action({ rawError: true })
  public cancelAll() {
    this.setAddShiftFlag(false);
    this.setEditShiftFlag(0);
    this.setAbleToShiftFlag(0);
  }

  @Action({ rawError: true })
  public async is_open_input() {
    return this.add_shift_flag || this.edit_shift_flag != 0 || this.able_to_shift_flag != 0;
  }

  @Mutation
  setShift({ shift }: { shift: Shift }) {
    Vue.set(this, "shift", shift);
  }

  @Action({ rawError: true })
  public async addShift() {
    if (await this.is_open_input()) return;
    this.setAddShiftFlag(true);
  }
  @Action({ rawError: true })
  public async editShift(shift_id: number) {
    if (await this.is_open_input()) return;
    this.setEditShiftFlag(shift_id);
  }
  @Action({ rawError: true })
  public async addShiftByAble(able_id: number) {
    if (await this.is_open_input()) return;
    this.setAbleToShiftFlag(able_id);
  }

  @Action({ rawError: true })
  public createShift({ branch_id, date }: { branch_id: number; date: string }) {
    if (validate(validationShift(this.shift))) {
      dialog.openConfirmDialog({
        msg: "シフトを作成してよろしいですか？",
        cancel_button: "作成しない",
        exec_button: "作成する",
        func: this.createShiftProcess,
        args: { branch_id, date },
      });
    }
  }
  @Action({ rawError: true })
  public async createShiftProcess({ branch_id, date }: { branch_id: number; date: string }) {
    const res = await util.post("shift/create_shift", { branch_id, date, shift: this.shift });
    if (res !== null) {
      daily.createShift({ date, branch_id, shift: this.shift });
      this.cancelAll();
      node.disable();
    }
  }

  @Action({ rawError: true })
  public updateShift({
    branch_id,
    date,
    shift_id,
  }: {
    branch_id: number;
    date: string;
    shift_id: number;
  }) {
    if (validate(validationShift(this.shift))) {
      dialog.openConfirmDialog({
        msg: "シフトを更新してよろしいですか？",
        cancel_button: "更新しない",
        exec_button: "更新する",
        func: this.updateShiftProcess,
        args: { branch_id, date, shift_id },
      });
    }
  }
  @Action({ rawError: true })
  public async updateShiftProcess({
    branch_id,
    date,
    shift_id,
  }: {
    branch_id: number;
    date: string;
    shift_id: number;
  }) {
    const res = await util.post("shift/update_shift", {
      branch_id,
      date,
      shift_id,
      shift: this.shift,
    });
    if (res !== null) {
      daily.updateShift({ date, branch_id, shift_id, shift: this.shift });
      this.cancelAll();
      node.disable();
    }
  }

  @Action({ rawError: true })
  public async deleteShift({
    branch_id,
    employee_id,
    date,
    shift_id,
  }: {
    branch_id: number;
    employee_id: number;
    date: string;
    shift_id: number;
  }) {
    dialog.openForceConfirmDialog({
      msg: "シフトを削除してよろしいですか？",
      detail: "勤怠・休憩も削除されます。<br>元に戻すことができません",
      cancel_button: "削除しない",
      exec_button: "削除する",
      func: this.deleteShiftProcess,
      args: { branch_id, employee_id, date, shift_id },
    });
  }
  @Action({ rawError: true })
  public async deleteShiftProcess({
    branch_id,
    employee_id,
    date,
    shift_id,
  }: {
    branch_id: number;
    employee_id: number;
    date: string;
    shift_id: number;
  }) {
    daily.deleteShift({ date, branch_id, shift_id });
    await util.post("shift/delete_shift", { branch_id, employee_id, shift_id, date });
    node.disable();
  }

  @Action({ rawError: true })
  public async updateShiftMemo({
    branch_id,
    date,
    content,
    old_content,
  }: {
    branch_id: number;
    date: string;
    content?: string;
    old_content?: string;
  }) {
    console.log(content, old_content);
    if (old_content == "") {
      old_content = null;
    }
    if (content == old_content) {
      dialog.openErrorDialog({ error: ["内容が変更されていません"] });
    } else {
      await util.post("shift/update_shift_memo", { branch_id, date, content });
      dialog.openAlertDialog({ msg: "伝言メモの更新が完了しました" });
      node.disable();
    }
  }

  //shift_table
  @Action({ rawError: true })
  public async downloadDailyShiftTableCsv({
    branch_id,
    branch_name,
    date,
  }: {
    branch_id: number;
    branch_name: string;
    date: string;
  }) {
    await util.post_document(
      "document/generate_daily_shifttable",
      { branch_id, date },
      util.formatDate(util.date2obj(date), "yyyy年mm月dd日-" + branch_name + "-日勤表.csv"),
    );
  }
  @Action({ rawError: true })
  public async downloadWeeklyShiftTableCsv({
    branch_id,
    branch_name,
    date,
  }: {
    branch_id: number;
    branch_name: string;
    date: string;
  }) {
    await util.post_document(
      "document/generate_weekly_shifttable",
      { branch_id, date },
      util.formatDate(util.date2obj(date), "yyyy年mm月dd日週-" + branch_name + "-週勤表.csv"),
    );
  }
  @Action({ rawError: true })
  public async downloadMonthlyShiftTableCsv({
    branch_id,
    branch_name,
    date,
  }: {
    branch_id: number;
    branch_name: string;
    date: string;
  }) {
    await util.post_document(
      "document/generate_monthly_shifttable",
      { branch_id, date },
      util.formatDate(util.date2obj(date), "yyyy年mm月-" + branch_name + "-月勤表.csv"),
    );
  }

  @Action({ rawError: true })
  public async downloadDailyAllShiftTableCsv({ date }: { date: string }) {
    await util.post_document(
      "document/generate_daily_all_shifttable",
      { branch_id: 0, date },
      util.formatDate(util.date2obj(date), "yyyy年mm月dd日-全店舗-日勤表.csv"),
    );
  }
  @Action({ rawError: true })
  public async downloadWeeklyAllShiftTableCsv({ date }: { date: string }) {
    await util.post_document(
      "document/generate_weekly_all_shifttable",
      { branch_id: 0, date },
      util.formatDate(util.date2obj(date), "yyyy年mm月dd日週-全店舗-週勤表.csv"),
    );
  }
  @Action({ rawError: true })
  public async downloadMonthlyAllShiftTableCsv({ date }: { date: string }) {
    await util.post_document(
      "document/generate_monthly_all_shifttable",
      { branch_id: 0, date },
      util.formatDate(util.date2obj(date), "yyyy年mm月-全店舗-月勤表.csv"),
    );
  }

  //Delete bundle
  @Action({ rawError: true })
  public async createShiftExcel({
    excel,
    branch_id,
    yearmonth,
  }: {
    excel;
    branch_id: number;
    yearmonth: string;
  }) {
    if (!excel) {
      validate(["Excelファイルを選択してください"]);
    } else {
      dialog.openForceConfirmDialog({
        msg: "シフトExcel登録を実行してよろしいですか？",
        cancel_button: "登録しない",
        exec_button: "登録する",
        func: this.createShiftExcelProcess,
        is_next_dialog: true,
        args: { excel, branch_id, yearmonth },
      });
    }
  }
  @Action({ rawError: true })
  public async createShiftExcelProcess({
    excel,
    branch_id,
    yearmonth,
  }: {
    excel;
    branch_id: number;
    yearmonth: string;
  }) {
    dialog.openProgressDialog({
      title: "登録中",
      msg: "この処理には時間がかかることがあります。",
      detail: "そのままお待ちください。",
    });
    const res = await util.post_file_proxy("shift/create_shift_excel", {
      excel,
      branch_id,
      yearmonth,
    });
    if (res.data.error_message) {
      dialog.openErrorAlertDialog({ msg: res.data.error_message });
    } else {
      this.setIsOpenShiftExcelDialog(false);
      dialog.openAlertDialog({ msg: "シフトの登録が完了しました" });
      node.disable();
    }
  }

  //Delete bundle
  @Action({ rawError: true })
  public deleteShiftBundle({
    started_on,
    ended_on,
    branch_id,
    branch_name,
  }: {
    started_on: string;
    ended_on: string;
    branch_id: number;
    branch_name: string;
  }) {
    if (validate(validationDeleteShift(started_on, ended_on))) {
      dialog.openForceConfirmDialog({
        msg:
          branch_name +
          "に存在する" +
          util.formatDate(util.date2obj(started_on), "yyyy年mm月dd日") +
          "から" +
          util.formatDate(util.date2obj(ended_on), "yyyy年mm月dd日") +
          "までの<br>シフト・勤怠・休憩を削除してよろしいですか？",
        detail:
          "一度削除したら元に戻すことはできません。<br>期間をよく確認してください。<br>勤怠ロックがされている期間のものは削除されません",
        cancel_button: "削除しない",
        exec_button: "削除する",
        func: this.deleteShiftBundleConfirm,
        is_next_dialog: true,
        args: { started_on, ended_on, branch_id },
      });
    }
  }
  @Action({ rawError: true })
  public deleteShiftBundleConfirm({
    started_on,
    ended_on,
    branch_id,
  }: {
    started_on: string;
    ended_on: string;
    branch_id: number;
  }) {
    dialog.openForceConfirmDialog({
      msg: "本当によろしいですか？",
      detail: "一度削除したら戻すことができません",
      cancel_button: "削除しない",
      exec_button: "削除する",
      func: this.deleteShiftBundleProcess,
      is_next_dialog: true,
      args: { started_on, ended_on, branch_id },
    });
  }
  @Action({ rawError: true })
  public async deleteShiftBundleProcess({
    started_on,
    ended_on,
    branch_id,
  }: {
    started_on: string;
    ended_on: string;
    branch_id: number;
  }) {
    dialog.openProgressDialog({
      title: "削除中",
      msg: "この処理には時間がかかることがあります。",
      detail: "そのままお待ちください。",
    });
    await util.post_proxy("shift/delete_shift_bundle", { started_on, ended_on, branch_id });
    node.disable();
    this.setIsOpenDeleteShiftDialog(false);
    dialog.openAlertDialog({ msg: "シフト一括削除が完了しました。" });
  }

  @Action({ rawError: true })
  public async dragShift({
    date,
    before_date,
    branch_id,
  }: {
    date: Date;
    before_date: Date;
    branch_id: number;
  }) {
    var drag_shift_id = this.drag_shift_id;
    dialog.openForceConfirmDialog({
      msg:
        util.formatDate(before_date, "mm月dd日") +
        "から" +
        util.formatDate(date, "mm月dd日") +
        "へシフトを移動してよろしいですか？",
      detail: "シフト・勤怠・休憩が移動されます。",
      cancel_button: "移動しない",
      exec_button: "移動する",
      func: this.dragShiftProcess,
      is_next_dialog: true,
      args: { date, branch_id, drag_shift_id },
    });
  }
  @Action({ rawError: true })
  public async dragShiftProcess({
    date,
    branch_id,
    drag_shift_id,
  }: {
    date: Date;
    branch_id: number;
    drag_shift_id: number;
  }) {
    dialog.openProgressDialog({ title: "移動中", msg: "シフトを移動しています。" });
    this.cancelAll();
    var res = await util.post("shift/drag_shift", {
      date: util.formatDate(date, "yyyy-mm-dd"),
      branch_id,
      shift_id: drag_shift_id,
    });
    node.disable();
    if (res !== null) {
      dialog.openAlertDialog({ msg: "シフトを移動しました。" });
    }
  }

  @Action({ rawError: true })
  public async autofillShift({
    origin_row,
    origin_col,
    dest_row,
    dest_col,
    employee_ids,
    yearmonth,
    branch_id,
  }: {
    origin_row: any;
    origin_col: any;
    dest_row: any;
    dest_col: any;
    employee_ids: any;
    yearmonth: any;
    branch_id: number;
  }) {
    await this.autofillShiftProcess({
      origin_row,
      origin_col,
      dest_row,
      dest_col,
      employee_ids,
      yearmonth,
      branch_id,
    });
    // dialog.openForceConfirmDialog({msg: 'シフト・休憩の一括コピーを実行してよろしいですか？', detail: 'コピー先のシフト・休憩は上書きされます。<br>上書きされたものを元に戻すことはできません。', cancel_button: '実行しない', exec_button: '実行する', func: this.autofillShiftProcess, is_next_dialog: true, args: {origin_row, origin_col, dest_row, dest_col, employee_ids, yearmonth, branch_id}});
  }
  @Action({ rawError: true })
  public async autofillShiftProcess({
    origin_row,
    origin_col,
    dest_row,
    dest_col,
    employee_ids,
    yearmonth,
    branch_id,
  }: {
    origin_row: any;
    origin_col: any;
    dest_row: any;
    dest_col: any;
    employee_ids: any;
    yearmonth: any;
    branch_id: number;
  }) {
    dialog.openProgressDialog({ title: "実行中", msg: "シフト・休憩をコピーしています。" });
    this.cancelAll();
    var res = await util.post_proxy("shift/autofill_shift", {
      origin_row,
      origin_col,
      dest_row,
      dest_col,
      employee_ids,
      yearmonth,
      branch_id,
    });
    node.disable();
    if (res !== null) {
      dialog.closeDialog();
      // dialog.openAlertDialog({msg: 'シフト・休憩をコピーしました。'});
    }
  }

  @Action({ rawError: true })
  public async dragListShift({
    origin_row,
    origin_col,
    dest_row,
    dest_col,
    employee_ids,
    yearmonth,
    branch_id,
    is_shift,
  }: {
    origin_row: any;
    origin_col: any;
    dest_row: any;
    dest_col: any;
    employee_ids: any;
    yearmonth: any;
    branch_id: number;
    is_shift: boolean;
  }) {
    if (is_shift) {
      dialog.openForceConfirmDialog({
        msg: "シフト・休憩を移動してよろしいですか？",
        detail:
          "移動先のシフト・休憩は上書きされます。<br>上書きされたものを元に戻すことはできません。",
        cancel_button: "実行しない",
        exec_button: "実行する",
        func: this.dragListShiftProcess,
        is_next_dialog: true,
        args: { origin_row, origin_col, dest_row, dest_col, employee_ids, yearmonth, branch_id },
      });
    } else {
      this.dragListShiftProcess({
        origin_row,
        origin_col,
        dest_row,
        dest_col,
        employee_ids,
        yearmonth,
        branch_id,
      });
    }
  }
  @Action({ rawError: true })
  public async dragListShiftProcess({
    origin_row,
    origin_col,
    dest_row,
    dest_col,
    employee_ids,
    yearmonth,
    branch_id,
  }: {
    origin_row: any;
    origin_col: any;
    dest_row: any;
    dest_col: any;
    employee_ids: any;
    yearmonth: any;
    branch_id: number;
  }) {
    dialog.openProgressDialog({ title: "実行中", msg: "シフト・休憩を移動しています。" });
    this.cancelAll();
    var res = await util.post("shift/drag_list_shift", {
      origin_row,
      origin_col,
      dest_row,
      dest_col,
      employee_ids,
      yearmonth,
      branch_id,
    });
    node.disable();
    if (res !== null) {
      dialog.closeDialog();
      // dialog.openAlertDialog({msg: 'シフト・休憩を移動しました。'});
    }
  }

  @Action({ rawError: true })
  public async dragShiftPatternShift({
    shift_pattern_id,
    date,
    employee_id,
    yearmonth,
    branch_id,
    is_shift,
  }: {
    shift_pattern_id: any;
    date: any;
    employee_id: any;
    yearmonth: any;
    branch_id: number;
    is_shift: boolean;
  }) {
    if (is_shift) {
      dialog.openForceConfirmDialog({
        msg: "パターンからシフト・休憩を作成してよろしいですか？",
        detail:
          "移動先のシフト・休憩は上書きされます。<br>上書きされたものを元に戻すことはできません。",
        cancel_button: "実行しない",
        exec_button: "実行する",
        func: this.dragShiftPatternShiftProcess,
        is_next_dialog: true,
        args: { shift_pattern_id, date, employee_id, yearmonth, branch_id },
      });
    } else {
      this.dragShiftPatternShiftProcess({
        shift_pattern_id,
        date,
        employee_id,
        yearmonth,
        branch_id,
      });
    }
  }
  @Action({ rawError: true })
  public async dragShiftPatternShiftProcess({
    shift_pattern_id,
    date,
    employee_id,
    yearmonth,
    branch_id,
  }: {
    shift_pattern_id: any;
    date: any;
    employee_id: any;
    yearmonth: any;
    branch_id: number;
  }) {
    dialog.openProgressDialog({ title: "実行中", msg: "シフト・休憩を作成しています。" });
    this.cancelAll();
    var res = await util.post("shift/drag_pattern_shift", {
      shift_pattern_id,
      date,
      employee_id,
      yearmonth,
      branch_id,
    });
    node.disable();
    if (res !== null) {
      dialog.closeDialog();
      // dialog.openAlertDialog({msg: 'シフト・休憩を作成しました。'});
    }
  }

  @Action({ rawError: true })
  public async manipulateDeleteShift({
    origin_row,
    origin_col,
    dest_row,
    dest_col,
    employee_ids,
    yearmonth,
    branch_id,
  }: {
    origin_row: any;
    origin_col: any;
    dest_row: any;
    dest_col: any;
    employee_ids: any;
    yearmonth: any;
    branch_id: number;
  }) {
    dialog.openForceConfirmDialog({
      msg: "選択された範囲のシフト・休憩を削除してよろしいですか？",
      detail: "勤怠情報は削除されません。<br>削除されたシフト・休憩を元に戻すことはできません。",
      cancel_button: "削除しない",
      exec_button: "削除する",
      func: this.manipulateDeleteShiftProcess,
      is_next_dialog: true,
      args: { origin_row, origin_col, dest_row, dest_col, employee_ids, yearmonth, branch_id },
    });
  }
  @Action({ rawError: true })
  public async manipulateDeleteShiftProcess({
    origin_row,
    origin_col,
    dest_row,
    dest_col,
    employee_ids,
    yearmonth,
    branch_id,
  }: {
    origin_row: any;
    origin_col: any;
    dest_row: any;
    dest_col: any;
    employee_ids: any;
    yearmonth: any;
    branch_id: number;
  }) {
    dialog.openProgressDialog({ title: "実行中", msg: "シフト・休憩を削除しています" });
    this.cancelAll();
    var res = await util.post_proxy("shift/manipulate_delete_shift", {
      origin_row,
      origin_col,
      dest_row,
      dest_col,
      employee_ids,
      yearmonth,
      branch_id,
    });
    node.disable();
    if (res !== null) {
      dialog.closeDialog();
      // dialog.openAlertDialog({msg: 'シフト・休憩を削除しました。'});
    }
  }

  @Action({ rawError: true })
  public async deleteListShift({
    branch_id,
    employee_id,
    date,
    shift_id,
  }: {
    branch_id: number;
    employee_id: number;
    date: string;
    shift_id: number;
  }) {
    dialog.openForceConfirmDialog({
      msg: "シフト・休憩を削除してよろしいですか？",
      detail: "元に戻すことができません",
      cancel_button: "削除しない",
      exec_button: "削除する",
      func: this.deleteListShiftProcess,
      args: { branch_id, employee_id, date, shift_id },
    });
  }
  @Action({ rawError: true })
  public async deleteListShiftProcess({
    branch_id,
    employee_id,
    date,
    shift_id,
  }: {
    branch_id: number;
    employee_id: number;
    date: string;
    shift_id: number;
  }) {
    await util.post("shift/delete_list_shift", { branch_id, employee_id, shift_id, date });
    node.disable();
  }

  @Mutation
  setMode(mode: number) {
    this.mode = mode;
  }

  @Mutation
  setColor(val: string | null) {
    this.color = val;
  }
}

export default getModule(Manager);
