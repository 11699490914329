import VuexManagerControl from "@/vuex/VuexManagerControl";
import {validationPassreset} from "@/validations/passreset";

export default class Manager extends VuexManagerControl {
  create_descriptor = {
    reset_password: {
      url: 'login/reset_password',
      name: 'パスワード再設定',
      validate: validationPassreset,
      dialog: {msg: 'パスワードを再設定します。<br>よろしいですか？', cancel_button: 'キャンセル', exec_button: '再設定する'},
      processing_dialog: {},
      is_need_session: false,
      plus: {
        func: this.after_reset_password,
        args: {self: this},
      },
    }
  };

  after_reset_password(args, res) {
    if (res.data.code == 0) {
      args.self.setTmpObjects({name: 'step', val: 2});
    }
  }
}