import Need from "@/typesold/need";
import * as util from "@/util";

//validationは、OKならTrue, それ以外なら警告文を配列に入れる。
export default function validationNeeds(needs: { [date: string]: Need }) {
  var error = [];
  Object.keys(needs).forEach((date) => {
    const need = needs[date];
    for (var i = 0; i < need.need_time.length; i++) {
      if (!validate_time_start(need.need_time[i]))
        error.push(
          util.date2monthdaystr(date) + "の" + (i + 1) + "行目の募集の開始時刻が入力されていません",
        );
      if (!validate_time_end(need.need_time[i]))
        error.push(
          util.date2monthdaystr(date) + "の" + (i + 1) + "行目の募集の終了時刻が入力されていません",
        );
      if (!validate_time_order(need.need_time[i]))
        error.push(
          util.date2monthdaystr(date) +
            "の" +
            (i + 1) +
            "行目の募集の開始時刻が終了時刻以後となっています",
        );
      if (!validate_person_num(need.need_time[i]))
        error.push(
          util.date2monthdaystr(date) +
            "の" +
            (i + 1) +
            "行目の募集時間が入力されていないにもかかわらず募集人数が入力されています",
        );
      if (!validate_person_num_minus(need.need_time[i]))
        error.push(
          util.date2monthdaystr(date) +
            "の" +
            (i + 1) +
            "行目の募集時間は1以上の値を入力してください",
        );
      error = error.concat(
        validate_time_duplicate(need.need_time, i).map(
          (j) =>
            util.date2monthdaystr(date) +
            "の" +
            (i + 1) +
            "行目の募集が" +
            (j + 1) +
            "行目の募集と重複しています",
        ),
      );
    }
  });

  return error;
}

function validate_time_start(time): boolean {
  return time.started_at !== null || time.ended_at === null;
}
function validate_time_end(time): boolean {
  return time.started_at === null || time.ended_at !== null;
}
function validate_time_order(time): boolean {
  return time.started_at === null || time.ended_at === null || time.started_at < time.ended_at;
}
function validate_person_num(time): boolean {
  return time.person_num === null || !(time.started_at === null && time.ended_at === null); //開始終了どちらかがあれば先に引っかかる
}
function validate_person_num_minus(time): boolean {
  return time.person_num === null || time.person_num > 0; //開始終了どちらかがあれば先に引っかかる
}
function validate_time_duplicate(times, i): number[] {
  var error_j = [];
  for (var j = i + 1; j < times.length; j++) {
    if (times[i].started_at !== null && times[j].started_at !== null) {
      if (
        times[i].started_at !== null &&
        times[j].started_at !== null &&
        times[j].ended_at !== null &&
        times[j].started_at <= times[i].started_at &&
        times[i].started_at < times[j].ended_at
      )
        error_j.push(j);
      else if (
        times[i].ended_at !== null &&
        times[j].started_at !== null &&
        times[j].ended_at !== null &&
        times[j].started_at < times[i].ended_at &&
        times[i].ended_at <= times[j].ended_at
      )
        error_j.push(j);
      else if (
        times[j].started_at !== null &&
        times[i].started_at !== null &&
        times[i].ended_at !== null &&
        times[i].started_at <= times[j].started_at &&
        times[j].started_at < times[i].ended_at
      )
        error_j.push(j);
      else if (
        times[j].ended_at !== null &&
        times[i].started_at !== null &&
        times[i].ended_at !== null &&
        times[i].started_at < times[j].ended_at &&
        times[j].ended_at <= times[i].ended_at
      )
        error_j.push(j);
      else if (times[i].started_at == times[j].started_at) error_j.push(j);
    }
  }
  return error_j;
}
