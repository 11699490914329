import axios from "axios";
import { message as $message } from "ant-design-vue";
import { AxiosRequestConfig } from "axios";
import io from "socket.io-client";

// @ts-ignore
import router from "@/router";
// @ts-ignore
import * as env from "@/env.js";

export interface RequestOptions {
  //データをアンラップせずに持ってくるならtrueに
  isGetDataDirectly?: boolean;
  //成功時にメッセージを出すならセット
  successMsg?: string;
  //エラー時にメッセージを出すなら、{code: msg}をセット もし該当するcodeがセットされていないのにエラーなら、UNKNOWN_ERRORの内容を表示する
  errorMsg?: { [code: number | string]: string };
  //proxyサーバーを使用する場合はtrueをセット
  useProxy?: boolean;
  isGetRaw?: boolean;
}

const UNKNOWN_ERROR = "不明なエラーです。リロードしてください。";

const baseApiUrl = env.api_prefix + "/";

const service = axios.create({
  baseURL: env.apiBaseUrl,
  timeout: 600000, //10分
});

service.interceptors.response.use(
  (response) => {
    // if the custom code is not 200, it is judged as an error.
    if (response.status !== 200) {
      $message.error(UNKNOWN_ERROR);

      const error = new Error(response.statusText || UNKNOWN_ERROR) as Error & { code: any };
      error.code = response.status;
      return Promise.reject(error);
    } else {
      return response;
    }
  },
  (error) => {
    // 422か500ならここ
    $message.error(UNKNOWN_ERROR);
    error.message = error?.response?.data?.message;
    return Promise.reject(error);
  },
);

export type Response<T = any> = {
  code: number;
  message: string;
  data: T;
};

export type BaseResponse<T = any> = Promise<Response<T>>;

/**
 *
 * @param method - request methods
 * @param url - request url
 * @param data - request data or params
 */
export const request = async <T = any>(
  config: AxiosRequestConfig,
  options: RequestOptions = {},
): Promise<T> => {
  try {
    const { successMsg, errorMsg, isGetDataDirectly = true, isGetRaw = false } = options;
    config.baseURL = config.baseURL ?? baseApiUrl;

    console.log("request", config.url, config.data);
    const res = await post(config);
    console.log("response", res.data);
    if (isGetRaw) return res.data;
    if (res.data.code === 0) {
      successMsg && $message.success(successMsg);
    } else {
      let useErrorMsg = { 100: "セッションが切れています。", 101: "セッションが切れています。" };
      if (errorMsg) useErrorMsg = { ...useErrorMsg, ...errorMsg };
      if (useErrorMsg[res.data.code]) {
        $message.error(useErrorMsg[res.data.code]);
      } else {
        $message.error(UNKNOWN_ERROR);
      }

      if (res.data.code == 101) {
        redirectToJoinIllegal();
      } else if (res.data.code == 100) {
        redirectToIllegal();
      }
    }

    return isGetDataDirectly ? res.data.data : res;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

async function post(config) {
  if (config.useProxy) {
    const requester: (typeof service)["request"] = async (config) => {
      return new Promise(async (resolve, reject) => {
        try {
          // proxyにaxiosでうつ->token受け取り
          const res = await service.request(config);
          const token = res.data.token;
          if (!token) reject("no token");
          // socket.io開始
          var host = location.origin; //サーバー
          var option = {
            "force new connection": true,
            path: new URL(env.api_prefix, host).pathname + "/proxy/socket.io",
          };
          /*========================================*/

          var socket = io.connect(host, option); //接続実行
          socket.on("connect", () => {
            socket.emit("register", { token });
          });
          // endが来たらresponseをresolveする
          socket.on("end", ({ response }) => {
            // socket.ioを片付ける
            socket.close();
            if (response.status >= 400) {
              console.error(response.data);
              reject(`proxy post failed ${response.status}`);
            } else {
              resolve(response);
            }
          });
        } catch (e) {
          reject(e);
        }
      });
    };
    return await requester(config);
  } else {
    return await service.request(config);
  }
}

function redirectToIllegal(val = null) {
  router.push({ name: "illegal" });
}
function redirectToJoinIllegal() {
  router.push({ name: "join_illegal" });
}
