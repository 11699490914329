
  import { Component, Prop, Mixins } from "vue-property-decorator";
  import utilMixins from "@/mixins";
  import header from "@/vuex/header";
  import dialog from "@/vuex/dialog";

  import HeaderNormalMenuButtoncontent from "@/components/header/normal/menu/Buttoncontent.vue";

  @Component({
    components: {
      HeaderNormalMenuButtoncontent,
    },
  })
  export default class Button extends Mixins(utilMixins) {
    @Prop() big?: string;
    @Prop() small?: string;
    @Prop() upper: string;
    @Prop() lower?: string;
    @Prop() img_name: string;
    @Prop() is_active: boolean;
    @Prop() dest_page?: string;
    @Prop() theme: string;
    @Prop() passquery;
    @Prop() forcepassquery;

    get img() {
      if (this.img_name) {
        return `/img/main/${this.img_name}`;
      } else {
        return undefined;
      }
    }

    change_page_button() {
      this.$emit("clicked");
      header.setIsShowMainButton(false);
      header.setIsShowManagementButton(false);
      // this.cancel_all();
    }

    get full_path() {
      let query =
        this.passquery === undefined
          ? { ...this.query }
          : {
              ...this.passquery,
              ...this.query,
            };
      query =
        this.forcepassquery === undefined
          ? query
          : {
              ...query,
              ...this.forcepassquery,
            };
      return { name: this.dest_page, query };
    }

    get button_class() {
      return [
        this.theme,
        this.small !== undefined ? "small" : "big",
        this.is_active ? "active" : "",
      ];
    }
    get img_height() {
      if (this.windowSize.x <= this.SP_WIDTH) {
        return this.small !== undefined ? 22 : 25;
      } else {
        return this.small !== undefined ? 23 : 27;
      }
    }
    get img_width() {
      if (this.windowSize.x <= this.SP_WIDTH) {
        return 30;
      } else {
        return undefined;
      }
    }
  }
