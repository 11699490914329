import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import Vue from "vue";
import { Demand_holiday } from "@/typesold/demand";

export type GlobalData = {
  demand_holidays: Demand_holiday[];
};

@Module({ dynamic: true, store, name: "demand_holiday_base", namespaced: true })
class Global extends VuexModule {
  data: GlobalData | null = null;
  is_valid: boolean = false;
  is_set: boolean = false;
  fetching: Promise<void> = null;

  @Mutation
  public disable({ employee_id }: { employee_id?: number }) {
    if (employee_id === undefined || employee_id == Number(util.getLocalStorage("employee_id"))) {
      this.is_valid = false;
    }
  }
  @Mutation
  public disable_all() {
    this.is_valid = false;
  }
  @Mutation
  public setFetching({ value }: { value: Promise<void> }) {
    this.fetching = value;
  }
  @Mutation
  public set(data: GlobalData) {
    this.data = {
      ...data,
    };
    this.is_valid = true;
    this.is_set = true;
    this.fetching = null;
  }

  // actions
  @Action({ rawError: true })
  public async fetch() {
    if (this.fetching) {
      await this.fetching;
    } else {
      const promise = util.post("demand_holiday/read_global", {}).then((res) => {
        this.set(res.data);
      });
      this.setFetching({ value: promise });
      await promise;
    }
  }

  @Mutation
  public createDemandHoliday({ demand_holiday }: { demand_holiday: Demand_holiday }) {
    this.data.demand_holidays.push(demand_holiday);
  }
  @Mutation
  public deleteDemandHoliday({ demand_holiday_id }: { demand_holiday_id: number }) {
    this.data.demand_holidays = this.data.demand_holidays.filter(
      (demand_holiday) => demand_holiday.id != demand_holiday_id,
    );
  }
}

export default getModule(Global);
