import Shift_pattern from "@/typesold/shift_pattern";
import * as util from "@/util";

//validationは、OKならTrue, それ以外なら警告文を配列に入れる。
export default function validationNeedPattern(shift_pattern: Shift_pattern) {
  var error = [];

  error.push(shift_pattern.name != null || "パターン名を入力してください");
  error.push(shift_pattern.color != null || "パターンの色を選択してください");
  error.push(shift_pattern.shift_pattern_shift[0].started_at != null || "時間が入力されていません");

  for (var i = 0; i < shift_pattern.shift_pattern_shift.length; i++) {
    if (!validate_time_start(shift_pattern.shift_pattern_shift[i]))
      error.push(i + 1 + "行目のシフトの開始時刻が入力されていません");
    if (!validate_time_end(shift_pattern.shift_pattern_shift[i]))
      error.push(i + 1 + "行目のシフトの終了時刻が入力されていません");
    if (!validate_time_order(shift_pattern.shift_pattern_shift[i]))
      error.push(i + 1 + "行目のシフトの開始時刻が終了時刻以後となっています");
    error = error.concat(
      validate_time_duplicate(shift_pattern.shift_pattern_shift, i).map(
        (j) => i + 1 + "行目のシフトが" + (j + 1) + "行目のシフトと重複しています",
      ),
    );
  }

  for (var i = 0; i < shift_pattern.shift_pattern_break.length; i++) {
    if (!validate_time_start(shift_pattern.shift_pattern_break[i]))
      error.push(i + 1 + "行目の休憩の開始時刻が入力されていません");
    if (!validate_time_end(shift_pattern.shift_pattern_break[i]))
      error.push(i + 1 + "行目の休憩の終了時刻が入力されていません");
    if (!validate_time_order(shift_pattern.shift_pattern_break[i]))
      error.push(i + 1 + "行目の休憩の開始時刻が終了時刻以後となっています");
    error = error.concat(
      validate_time_duplicate(shift_pattern.shift_pattern_break, i).map(
        (j) => i + 1 + "行目の休憩が" + (j + 1) + "行目の休憩と重複しています",
      ),
    );
  }

  return error;
}

function validate_time_start(time): boolean {
  return time.started_at !== null || time.ended_at === null;
}
function validate_time_end(time): boolean {
  return time.started_at === null || time.ended_at !== null;
}
function validate_time_order(time): boolean {
  return time.started_at === null || time.ended_at === null || time.started_at < time.ended_at;
}
function validate_time_duplicate(times, i): number[] {
  var error_j = [];
  for (var j = i + 1; j < times.length; j++) {
    if (times[i].started_at !== null && times[j].started_at !== null) {
      if (
        times[i].started_at !== null &&
        times[j].started_at !== null &&
        times[j].ended_at !== null &&
        times[j].started_at <= times[i].started_at &&
        times[i].started_at < times[j].ended_at
      )
        error_j.push(j);
      else if (
        times[i].ended_at !== null &&
        times[j].started_at !== null &&
        times[j].ended_at !== null &&
        times[j].started_at < times[i].ended_at &&
        times[i].ended_at <= times[j].ended_at
      )
        error_j.push(j);
      else if (
        times[j].started_at !== null &&
        times[i].started_at !== null &&
        times[i].ended_at !== null &&
        times[i].started_at <= times[j].started_at &&
        times[j].started_at < times[i].ended_at
      )
        error_j.push(j);
      else if (
        times[j].ended_at !== null &&
        times[i].started_at !== null &&
        times[i].ended_at !== null &&
        times[i].started_at < times[j].ended_at &&
        times[j].ended_at <= times[i].ended_at
      )
        error_j.push(j);
      else if (times[i].started_at == times[j].started_at) error_j.push(j);
    }
  }
  return error_j;
}
