import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import Able_pattern from "@/typesold/able_pattern";

export type BranchData = {
  id: number;
  name: string;
};

export type GlobalData = {
  branches: BranchData[];
  branch_hash: { [id: number]: string };
  national_holidays: string[];
  able_patterns: Able_pattern[];
  able_pattern_option;
  coretime_type: number;
  coretime_started_at?: number;
  coretime_ended_at?: number;
};

@Module({ dynamic: true, store, name: "submit_global", namespaced: true })
class Global extends VuexModule {
  data: GlobalData | null = {
    branches: [],
    branch_hash: {},
    national_holidays: [],
    able_patterns: [],
    able_pattern_option: {},
    coretime_type: 0,
    coretime_started_at: null,
    coretime_ended_at: null,
  };
  is_valid: boolean = false;
  is_set: boolean = false;
  fetching: Promise<void> = null;

  @Mutation
  public disable({ employee_id }: { employee_id?: number }) {
    if (employee_id === undefined || employee_id == Number(util.getLocalStorage("employee_id"))) {
      this.is_valid = false;
    }
  }
  @Mutation
  public disable_all() {
    this.is_valid = false;
  }
  @Mutation
  public setFetching({ value }: { value: Promise<void> }) {
    this.fetching = value;
  }
  @Mutation
  public set(data: GlobalData) {
    var able_pattern_option = util.createHash(data.able_patterns, "id", "name");
    able_pattern_option = {
      0: "リセット",
      ...able_pattern_option,
    };
    this.data = {
      ...data,
      branch_hash: util.createHashReal(data.branches, "id", "name"),
      able_pattern_option: able_pattern_option,
    };
    this.is_valid = true;
    this.is_set = true;
    this.fetching = null;
  }

  // actions
  @Action({ rawError: true })
  public async fetch() {
    if (this.fetching) {
      await this.fetching;
    } else {
      const promise = util.post("submit/read_able_global", {}).then((res) => {
        this.set(res.data);
      });
      this.setFetching({ value: promise });
      await promise;
    }
  }

  @Mutation
  public createAblePattern({ able_pattern }: { able_pattern: Able_pattern }) {
    able_pattern = {
      ...able_pattern,
      able_pattern_time: able_pattern.able_pattern_time.filter(
        (able_pattern_time) => able_pattern_time.started_at || able_pattern_time.ended_at,
      ),
    };

    this.data.able_patterns.push(able_pattern);
  }
  @Mutation
  public updateAblePattern({
    able_pattern_id,
    able_pattern,
  }: {
    able_pattern_id: number;
    able_pattern: Able_pattern;
  }) {
    able_pattern = {
      ...able_pattern,
      able_pattern_time: able_pattern.able_pattern_time.filter(
        (able_pattern_time) => able_pattern_time.started_at || able_pattern_time.ended_at,
      ),
    };

    this.data.able_patterns.forEach((v, i, a) => {
      if (v.id == able_pattern_id) {
        a[i] = able_pattern;
      }
    });
  }
  @Mutation
  public deleteAblePattern({ able_pattern_id }: { able_pattern_id: number }) {
    this.data.able_patterns = this.data.able_patterns.filter(
      (able_pattern) => able_pattern.id != able_pattern_id,
    );
  }
}

export default getModule(Global);
