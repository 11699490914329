import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import validate from "@/validations/validate";
import { validationDemandHoliday } from "@/validations/demand";
import Vue from "vue";
import { Demand_holiday } from "@/typesold/demand";
import dialog from "@/vuex/dialog";
import global from "./global";
import node from "@/vuex/node";

@Module({ dynamic: true, store, name: "demand_holiday_manager", namespaced: true })
class Company_manager extends VuexModule {
  disp_num: number = 10;

  is_open_add_demand_holiday_dialog: boolean = false;
  demand_holiday: null | Demand_holiday = null;

  @Mutation
  public setDispNum(val) {
    this.disp_num = val;
  }

  @Action({ rawError: true })
  public async is_open_input() {
    return false;
  }

  @Action({ rawError: true })
  public cancelAll() {}

  //Base
  @Mutation
  public setIsOpenAddDemandHolidayDialog(val: boolean) {
    this.is_open_add_demand_holiday_dialog = val;
  }
  @Mutation
  setDemandHoliday({ demand_holiday }: { demand_holiday: Demand_holiday }) {
    Vue.set(this, "demand_holiday", demand_holiday);
  }
  @Action({ rawError: true })
  public createDemandHoliday() {
    if (validate(validationDemandHoliday(this.demand_holiday))) {
      dialog.openConfirmDialog({
        msg: util.$t("demand_holiday_create_confirm"),
        cancel_button: util.$t("demand_holiday_send"),
        exec_button: util.$t("demand_holiday_no_send"),
        func: this.createDemandHolidayProcess,
        args: { demand_holiday: this.demand_holiday },
      });
    }
  }
  @Action({ rawError: true })
  public async createDemandHolidayProcess({ demand_holiday }: { demand_holiday: Demand_holiday }) {
    const res = await util.post("demand_holiday/create_demand_holiday", { demand_holiday });
    if (res !== null) {
      this.setIsOpenAddDemandHolidayDialog(false);
      dialog.openAlertDialog({ msg: util.$t("demand_holiday_finish_create") });
      node.disable();
    }
  }

  @Action({ rawError: true })
  public deleteDemandHoliday({ demand_holiday_id }: { demand_holiday_id: number }) {
    dialog.openForceConfirmDialog({
      msg: util.$t("demand_holiday_cancel_confirm"),
      cancel_button: util.$t("demand_holiday_no_cancel"),
      exec_button: util.$t("demand_holiday_cancel"),
      func: this.deleteDemandHolidayProcess,
      args: { demand_holiday_id },
    });
  }
  @Action({ rawError: true })
  public async deleteDemandHolidayProcess({ demand_holiday_id }: { demand_holiday_id: number }) {
    global.deleteDemandHoliday({ demand_holiday_id });
    await util.post("demand_holiday/delete_demand_holiday", { demand_holiday_id });
    node.disable();
  }
}

export default getModule(Company_manager);
