
import { Component, Vue, Emit, Prop, Watch, Mixins } from 'vue-property-decorator';

@Component({
  components: {
  }
})
export default class Text extends Vue {
  @Prop() value: number | string | null;
  @Prop() error_cond: [{func, msg}];
  @Prop() before_label: string;
  @Prop({default: '0px'}) before_label_width: string;
  @Prop() after_label: string;
  @Prop({default: '0px'}) after_label_width: string;
  @Prop({default: 'text'}) type: string;
  @Prop({default: '100%'}) max_width: string;
  @Prop({default: ''}) placeholder: string;
  @Prop({default: false}) is_time: boolean;
  @Prop({default: 'left'}) text_align: string;
  @Prop() num: number;
  @Prop() sum: number;
  @Prop() join: string;
  @Prop() tabindex;
  @Prop({default: true}) is_valid: boolean;

  @Prop() f_row_idx
  @Prop() f_col_idx

  once_input: boolean = false;//lazy

  get disp_value() {
    if (this.num !== undefined) {
      if (this.value !== null) {
        var name = String(this.value).split(this.join);
        if (name[this.num] !== null && name[this.num] !== undefined) {
          return name[this.num];
        } else {
          return '';
        }
      } else {
        return '';
      }
      
    } else {
      return this.value;
    }
  }
  set disp_value(val) {
    if (this.num !== undefined) {
      var name = [];
      if (this.value !== null) {
        name = String(this.value).split(this.join);
      }
      
      if (val !== undefined && val !== null)
        name[this.num] = String(val);
      else
        name[this.num] = '';
      if (name.reduce((carry, val) => {
        return carry + val.length;
      }, 0) == 0) {
        this.input_value(null);
      } else {
        var str = '';
        for (var i = 0; i < this.sum; i++) {
          if (name[i] !== null && name[i] !== undefined && name[i] != '') {
            str += name[i];
          }
          if (i != this.sum - 1)
            str += this.join;
        }
        this.input_value(str);
      }
    } else {
      this.input_value(val);
      this.once_input = true;
    }
  }

  @Emit('input')
  input_value(val) {
    return val == '' ? null : val;
  }

  @Emit()
  inputelement(e) {
    return e;
  }


  @Emit('keydown')
  keydown(e) {
    return e;
  }
  k_left(e) {
    if (e.target.value.length == 0 || (e.target.selectionStart == 0 && e.target.selectionEnd == 0)) {
      e.preventDefault();
      this.prev_focus(e);
    }
  }
  k_right(e) {
    if (e.target.selectionStart == e.target.value.length && e.target.selectionEnd == e.target.value.length) {
      e.preventDefault();
      this.next_focus(e);
    }
  }
  k_delete(e) {
    if (e.target.value.length == 0 || (e.target.selectionStart == 0 && e.target.selectionEnd == 0)) {
      e.preventDefault();
      this.prev_focus(e);
    }
  }
  @Emit('movefocus')
  k_up(e) {
    if(this.f_row_idx !== undefined) {
      e.preventDefault();
      return { row: this.f_row_idx, col: this.f_col_idx, dir: 'up' };
    }
    else this.prev_focus(e);
  }
  @Emit('movefocus')
  k_down(e) {
    if(this.f_row_idx !== undefined) {
      e.preventDefault();
      return { row: this.f_row_idx, col: this.f_col_idx, dir: 'down' };
    }
    else this.next_focus(e);
  }
  @Emit('movefocus')
  k_enter(e) {
    if(this.f_row_idx !== undefined) {
      e.preventDefault();
      return { row: this.f_row_idx, col: this.f_col_idx, dir: 'down' };
    }
    else if (this.type != 'text')
      this.next_focus(e);
  }
  prev_focus(e) {
    const index = $('.textbox_focus').index(e.target);
    var prev_element = <HTMLInputElement>$('.textbox_focus')[index - 1];
    if (prev_element) {
      if (prev_element.type == 'text' || prev_element.type == 'url' || prev_element.type == 'tel') {
        prev_element.selectionStart = 0;
        prev_element.selectionEnd = prev_element.value.length;
        prev_element.focus();
      }
    }
  }
  next_focus(e) {
    const index = $('.textbox_focus').index(e.target);
    var next_element = <HTMLInputElement>$('.textbox_focus')[index + 1];
    if (next_element) {
      if (next_element.type == 'text' || next_element.type == 'url' || next_element.type == 'tel') {
        next_element.selectionStart = 0;
        next_element.selectionEnd = next_element.value.length;
        next_element.focus();
      }
    }
  }

  get styles () {
    return {
      '--beforelabelwidth': this.before_label_width,
      '--afterlabelwidth': this.after_label_width,
      '--textmaxwidth': this.max_width,
      '--textalign': this.text_align,
      '--textindent': this.text_align == 'center' ? '0px' : '5px',
    }
  }
}
