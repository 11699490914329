
import { Component, Prop, Mixins } from 'vue-property-decorator';
import utilMixins from '@/mixins';
import header from "@/vuex/header";
import dialog from "@/vuex/dialog";
import HeaderNormalMenuButtoncontent from "@/components/header/normal/menu/Buttoncontent.vue";

@Component({
  components: {
    HeaderNormalMenuButtoncontent,
  }
})
export default class Button extends Mixins(utilMixins) {
  @Prop() upper: string;
  @Prop() is_active: boolean;
  @Prop() dest_page?: string;
  @Prop() theme: string;
  @Prop() passquery;

  change_page_button() {
    this.$emit('clicked');
    if (!this.dest_page) return;
    header.setIsShowMainButton(false);
    header.setIsShowManagementButton(false);
    this.change_page(this.full_path.name, this.full_path.query);
  }

  get full_path() {
    var query = this.passquery === undefined ? {...this.query} : {
      ...this.passquery,
      ...this.query,
    };
    return { name: this.dest_page, query };
  }

  get button_class() {
    return [
      this.theme,
      this.is_active ? 'active' : '',
    ];
  }
}
