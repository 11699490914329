import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import Vue from "vue";
import Payment_info from "@/typesold/Payment_info";

export type BranchData = {
  id: number;
  name: string;
};

export type BranchObject = {
  [id: number]: string;
};

export type Data = {
  payment_info: Payment_info;
  branches: BranchData[];
  branch_hash: BranchObject;
  branch_hash_all: BranchObject;
  branch_hash_object: any;
  saved_payment_allowances;
  saved_payment_deductions;
  saved_payment_other_allowances;
  is_branch_bank: boolean;
  nencho: any;
  is_each_payment_lock: boolean;
  is_valid?: boolean;
  is_set: boolean;
  fetching?: Promise<void>;
};

export type MonthlyData = {
  [yearmonth: string]: Data;
};

@Module({ dynamic: true, store, name: "payment_global_data", namespaced: true })
class PaymentGlobal extends VuexModule {
  data: MonthlyData = {};

  latest_date: null | string = null;
  latest_fetching: Promise<void> = null;

  @Mutation
  public set({ yearmonth, data, auth }: { yearmonth: string; data: Data; auth: number }) {
    var branch_hash = util.createHashReal(data.branches, "id", "name");
    if (auth == 2 || auth == 1) branch_hash[0] = "全店舗";
    Vue.set(
      this.data,
      util.formatDate(
        util.date2obj(data.payment_info.year + "-" + data.payment_info.month + "-01"),
        "yyyy-mm",
      ),
      {
        ...data,
        branch_hash,
        branch_hash_all: util.createHash(data.branches, "id", "name"),
        branch_hash_object: util.createHashObject(data.branches, "id"),
        is_valid: true,
        is_set: true,
        fetching: null,
      },
    );
    if (yearmonth == null) {
      this.latest_date = util.formatDate(
        util.date2obj(data.payment_info.year + "-" + data.payment_info.month + "-01"),
        "yyyy-mm",
      );
    }
  }
  @Mutation
  public disable({ yearmonth }: { yearmonth?: string }) {
    if (yearmonth === undefined) {
      this.latest_date = null;
      this.latest_fetching = null;
      Object.keys(this.data).forEach((yearmonth) => {
        this.data[yearmonth].is_valid = false;
        if (yearmonth == this.latest_date) {
          this.latest_date = null;
          this.latest_fetching = null;
        }
      });
    } else {
      if (this.data[yearmonth]) {
        this.data[yearmonth].is_valid = false;
      }
      if (yearmonth == this.latest_date) {
        this.latest_date = null;
        this.latest_fetching = null;
      }
    }
  }
  @Mutation
  public disable_all() {
    this.latest_date = null;
    this.latest_fetching = null;
    Object.keys(this.data).forEach((yearmonth) => {
      this.data[yearmonth].is_valid = false;
    });
  }
  @Mutation
  public setFetching({ yearmonth, value }: { yearmonth: string; value: Promise<void> }) {
    this.data[yearmonth].fetching = value;
  }

  @Mutation
  public setLatestPaymentInfoId(value) {
    Vue.set(this, "latest_payment_info_id", value);
  }
  @Mutation
  public setLastFetching({ value }: { value: Promise<void> }) {
    this.latest_fetching = value;
  }

  // actions
  @Action({ rawError: true })
  public async fetch({ yearmonth, auth }: { yearmonth: string; auth: number }) {
    //ないなら作る
    if (!this.data[yearmonth]) {
      Vue.set(this.data, yearmonth, {
        payment_info: {},
        branches: [],
        branch_hash: {},
        saved_payment_allowances: [],
        saved_payment_deductions: [],
        saved_payment_other_allowances: [],
        branch_hash_object: {},
        is_branch_bank: false,
        nencho: null,
        is_each_payment_lock: false,
        is_valid: false,
        is_set: false,
        fetching: null,
      });
    }

    if (yearmonth == null && this.latest_fetching) {
      await this.latest_fetching;
    } else if (yearmonth != null && this.data[yearmonth].fetching) {
      await this.data[yearmonth].fetching;
    } else {
      const promise = util.post("payment/read_global", { yearmonth }).then((res) => {
        this.set({ yearmonth, auth, data: res.data });
      });
      if (yearmonth == null) {
        this.setLastFetching({ value: promise });
      } else {
        this.setFetching({ yearmonth, value: promise });
      }
      await promise;
    }
  }
}

export default getModule(PaymentGlobal);
