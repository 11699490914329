import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";

export type BeforeLoginData = {
  company_id: number;
  company_code: string;
  company_name: string;
  use_status: number;
  wizard: number;
  registered_on: number;
  is_maintenance: number;
  traial_remain_days: number;
  api_version: string;
};

@Module({ dynamic: true, store, name: "before_login", namespaced: true })
class BeforeLogin extends VuexModule {
  // state
  data: BeforeLoginData | null = null;

  @Mutation
  public set(before_login: BeforeLoginData) {
    this.data = before_login;
  }
  @Mutation
  public disable({}) {
    this.data = null;
  }

  // actions
  @Action({ rawError: true })
  public async fetch(company_code) {
    const res = await util.post("login/before_login", { company_code }, false);
    this.set(res.data);
  }
}

export default getModule(BeforeLogin);
