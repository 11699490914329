import { Mutation, MutationAction, Action, VuexModule, getModule, Module } from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import monthly from "@/vuex/payment_each/monthly";
import * as util from "@/util";
import validate from "@/validations/validate";
import {validationEachPayment} from "@/validations/payment";
import dialog from "@/vuex/dialog";
import Vue from 'vue';
import node from "@/vuex/node";


@Module({ dynamic: true, store, name: "payment_each_manager", namespaced: true })
class Manager extends VuexModule {
  edit_each_payment_flag: boolean = false;
  is_open_detail_dialog: boolean = false;

  each_payment: any = {};//each_payment_object
  mode: number = 0;//{0: 従来, 1: カード形式(sp)}

  @Mutation
  private setEditEachPaymentFlag(val: boolean) {
    if (!val) {
      Vue.set(this, 'each_payment', {});
    }
    this.edit_each_payment_flag = val;
  }
  @Mutation
  public setIsOpenDetailDialog(val: boolean) {
    this.is_open_detail_dialog = val;
  }


  @Action({ rawError: true })
  public async editEachPayment() {
    if (await this.is_open_input()) return;
    this.setEditEachPaymentFlag(true);
  }

  @Mutation
  setEachPayment({each_payment_object}: {each_payment_object: any}) {
    Vue.set(this, 'each_payment', each_payment_object);
  }

  @Mutation
  setEachPaymentAllowance({id, val}) {
    Vue.set(this.each_payment.each_payment_allowances, id, val);
  }
  @Mutation
  setEachPaymentDeduction({id, val}) {
    Vue.set(this.each_payment.each_payment_deductions, id, val);
  }
  @Mutation
  setEachPaymentOtherAllowance({id, val}) {
    Vue.set(this.each_payment.each_payment_other_allowances, id, val);
  }

  @Action({ rawError: true })
  public updateEachPayment({yearmonth, employee_id, employee, saved_payment_allowances, saved_payment_deductions, saved_payment_other_allowances}: {yearmonth: string, employee_id: number, employee: any, saved_payment_allowances: any, saved_payment_deductions: any, saved_payment_other_allowances: any}) {
    if (validate(validationEachPayment(this.each_payment, employee, saved_payment_allowances, saved_payment_deductions, saved_payment_other_allowances, false))) {
      dialog.openConfirmDialog({msg: '給与を更新してよろしいですか？', cancel_button: '更新しない', exec_button: '更新する', func: this.updateEachPaymentProcess, args: {yearmonth, employee_id}});
    }
  }
  @Action({ rawError: true })
  public async updateEachPaymentProcess({yearmonth, employee_id}: {yearmonth: string, employee_id: number}) {
    await util.post('payment_each/update_each_payment', {yearmonth, employee_id, object: this.each_payment});
    this.cancelAll();
    node.disable();
  }

  
  @Action({ rawError: true })
  public cancelAll() {
    this.setEditEachPaymentFlag(false);
  }
  
  @Action({ rawError: true })
  public async is_open_input() {
    return this.edit_each_payment_flag;
  }

  @Mutation
  setMode(mode: number) {
    this.mode = mode;
  }
}

export default getModule(Manager);