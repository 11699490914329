
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import utilMixins from "@/mixins";
  import header from "@/vuex/header";

  import { EmployeeHeaderData, BranchHeaderData, CompanyHeaderData } from "@/typesold/header";

  @Component({
    components: {},
  })
  export default class Todo extends Mixins(utilMixins) {
    open_support_dialog() {
      header.setIsOpenSupportDialog(true);
    }
    get is_no_nencho() {
      return !(
        this.isNowPage("nencho_employee") ||
        this.isNowPage("nencho_insurance") ||
        this.isNowPage("nencho_loan") ||
        this.isNowPage("nencho_prev_payment")
      );
    }
  }
