import { Mutation, MutationAction, Action, VuexModule, getModule, Module } from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import monthly from "@/vuex/payment/monthly";
import global from "@/vuex/payment/global";
import validate from "@/validations/validate";
import {validationEachPayments} from "@/validations/payment";
import * as util from "@/util";
import dialog from "@/vuex/dialog";
import Vue from 'vue';
import node from "@/vuex/node";


@Module({ dynamic: true, store, name: "payment_manager", namespaced: true })
class Manager extends VuexModule {
  disp_num: number = 10;
  edit_each_payments_flag: boolean = false;

  is_open_payment_excel_dialog: boolean = false;
  is_open_update_stdincome_dialog: boolean = false;

  each_payment = {};//employee_id => each_payment_object


  @Mutation
  private setEditEachPaymentsFlag(val: boolean) {
    if (!val) {
      Vue.set(this, 'each_payment', {});
    }
    this.edit_each_payments_flag = val;
  }

  @Mutation
  public setIsOpenPaymentExcelDialog(val: boolean) {
    this.is_open_payment_excel_dialog = val;
  }
  @Mutation
  public setIsOpenUpdateStdincomeDialog(val: boolean) {
    this.is_open_update_stdincome_dialog = val;
  }

 
  @Mutation
  public setDispNum(val) {
    this.disp_num = val;
  }
  
  @Action({ rawError: true })
  public cancelAll() {
    this.setEditEachPaymentsFlag(false);
  }
  
  @Action({ rawError: true })
  public async is_open_input() {
    return this.edit_each_payments_flag;
  }

  @Action({ rawError: true })
  public async editEachPayments() {
    if (await this.is_open_input()) return;
    this.setEditEachPaymentsFlag(true);
  }

  @Action({ rawError: true })
  public updateEachPayments({yearmonth, branch_id, employee_hash, saved_payment_allowances, saved_payment_deductions, saved_payment_other_allowances}: {yearmonth: string, branch_id: number, employee_hash: any, saved_payment_allowances: any, saved_payment_deductions: any, saved_payment_other_allowances: any}) {
    if (validate(validationEachPayments(this.each_payment, employee_hash, saved_payment_allowances, saved_payment_deductions, saved_payment_other_allowances))) {
      dialog.openConfirmDialog({msg: '給与を更新してよろしいですか？', cancel_button: '更新しない', exec_button: '更新する', func: this.updateEachPaymentsProcess, is_next_dialog: true, args: {yearmonth, branch_id}});
    }
  }
  @Action({ rawError: true })
  public async updateEachPaymentsProcess({yearmonth, branch_id}: {yearmonth: string, branch_id: number}) {
    dialog.openProgressDialog({title: '更新中', msg: '更新には時間がかかることがあります。'});
    await util.post_proxy('payment/update_each_payments', {yearmonth, branch_id, object: this.each_payment});
    this.cancelAll();
    node.disable();
    dialog.openAlertDialog({msg: '更新が完了しました'});
  }

  @Mutation
  setEachPayment({employee_id, each_payment_object}: {employee_id: number, each_payment_object: any}) {
    Vue.set(this.each_payment, employee_id, each_payment_object);
  }


  @Action({ rawError: true })
  public lock({yearmonth, branch_id, branch_name, is_nencho}: {yearmonth: string, branch_id: number, branch_name: string, is_nencho: boolean}) {
    if (util.yearmonth2month(yearmonth) != 12 || is_nencho) {
      dialog.openConfirmDialog({msg: branch_name+'の給与ロックをしてよろしいですか？', detail: '給与が確定され、以後変更ができなくなります。', cancel_button: 'ロックしない', exec_button: 'ロックする', func: this.lockProcess, args: {yearmonth, branch_id}, is_next_dialog: true});
    } else {
      dialog.openConfirmDialog({msg: branch_name+'の給与ロックをしてよろしいですか？', detail: '給与が確定され、以後変更ができなくなります。<br>年末調整が開始されていません。<br>年末調整を開始せずに12月給与・12月賞与をロックすると、年末調整未済の源泉徴収票が全社員出力可能になります。', cancel_button: 'ロックしない', exec_button: 'ロックする', func: this.lockProcess, args: {yearmonth, branch_id}, is_next_dialog: true});
    }
  }
  @Action({ rawError: true })
  public async lockProcess({yearmonth, branch_id}: {yearmonth: string, branch_id: number}) {
    dialog.openProgressDialog({title: 'ロック中', msg: 'この処理には時間がかかることがあります。'});
    await util.post_proxy('payment/lock', {yearmonth, branch_id});
    node.disable();
    dialog.openAlertDialog({msg: '給与ロックが完了しました'});
  }

  @Action({ rawError: true })
  public recalc({yearmonth, branch_id, branch_name}: {yearmonth: string, branch_id: number, branch_name: string}) {
    dialog.openConfirmDialog({msg: branch_name+'のデータ再計算をしてよろしいですか？', detail: 'ロック後に行った変更は全て破棄されます。<br>また、過去の再計算を行った場合、有給休暇の計算に影響を及ぼす可能性があります。<br>再計算では、ロック以降に変更した会社・店舗・社員情報が反映されます。', cancel_button: '再計算しない', exec_button: '再計算する', func: this.recalcProcess, args: {yearmonth, branch_id}, is_next_dialog: true});   
  }
  @Action({ rawError: true })
  public async recalcProcess({yearmonth, branch_id}: {yearmonth: string, branch_id: number}) {
    dialog.openProgressDialog({title: '再計算中', msg: 'この処理には時間がかかることがあります。'});
    await util.post_proxy('attendance/recalc', {yearmonth, branch_id});
    node.disable();
    dialog.openAlertDialog({msg: '再計算が完了しました'});
  }
  
  @Action({ rawError: true })
  public unlock({yearmonth, branch_id, branch_name}: {yearmonth: string, branch_id: number, branch_name: string}) {
    dialog.openConfirmDialog({msg: branch_name+'の給与ロックを解除してよろしいですか？', detail: '支払済みの場合は絶対にロックを解除しないでください。<br>ロックを解除すると、正しく計算されない可能性があります。', cancel_button: 'ロック解除しない', exec_button: 'ロック解除する', func: this.unlockConfirm, args: {yearmonth, branch_id}, is_next_dialog: true});
  }
  @Action({ rawError: true })
  public unlockConfirm({yearmonth, branch_id}: {yearmonth: string, branch_id: number}) {
    dialog.openForceConfirmDialog({msg: '本当によろしいですか？', detail: '一度解除すると元の値に戻すことはできません。<br>支払済みの場合は絶対にロックを解除しないでください。', cancel_button: 'ロック解除しない', exec_button: 'ロック解除する', func: this.unlockProcess, is_next_dialog: true, args: {yearmonth, branch_id}});
  }
  @Action({ rawError: true })
  public async unlockProcess({yearmonth, branch_id}: {yearmonth: string, branch_id: number}) {
    dialog.openProgressDialog({title: 'ロック解除中', msg: 'この処理には時間がかかることがあります。'});
    await util.post_proxy('payment/unlock', {yearmonth, branch_id});
    node.disable();
    dialog.openAlertDialog({msg: '給与ロック解除が完了しました'});
  }

  @Action({ rawError: true })
  public async zengin({branch_id, yearmonth}: {branch_id: number, yearmonth: string}) {
    dialog.openProgressDialog({title: '作成中', msg: '作成には時間がかかることがあります。'});
    await util.post_document('document/generate_payment_zengin', {yearmonth, branch_id}, util.formatDate(util.date2obj(yearmonth+'-01'), 'yyyy年mm月')+'支給分給与全銀テキスト.txt', '振込元口座が登録されていない可能性があります');
    await util.post_document('document/generate_payment_cash', {yearmonth, branch_id}, util.formatDate(util.date2obj(yearmonth+'-01'), 'yyyy年mm月')+'支給分給与現金支給分.csv', '', false, false);
  }
  @Action({ rawError: true })
  public async zengin_zip({branch_id, yearmonth}: {branch_id: number, yearmonth: string}) {
    dialog.openProgressDialog({title: '作成中', msg: '作成には時間がかかることがあります。'});
    await util.post_document('document/generate_payment_zengin', {yearmonth, branch_id}, util.formatDate(util.date2obj(yearmonth+'-01'), 'yyyy年mm月')+'支給分給与全銀テキスト.zip', '振込元口座が登録されていない可能性があります');
    await util.post_document('document/generate_payment_cash', {yearmonth, branch_id}, util.formatDate(util.date2obj(yearmonth+'-01'), 'yyyy年mm月')+'支給分給与現金支給分.csv', '', false, false);
  }

  @Action({ rawError: true })
  public async payment_csv({branch_id, yearmonth}: {branch_id: number, yearmonth: string}) {
    dialog.openProgressDialog({title: '作成中', msg: '作成には時間がかかることがあります。'});
    await util.post_document('document/generate_payment_csv', {yearmonth, branch_id}, util.formatDate(util.date2obj(yearmonth+'-01'), 'yyyy年mm月')+'支給分給与データ.csv');
  }

  @Action({ rawError: true })
  public async gensen_pay({yearmonth}) {
    dialog.openProgressDialog({title: '作成中', msg: '作成に時間がかかることがあります。', detail: 'そのままお待ちください。'});
    await util.post_document('document/generate_gensenpay', {yearmonth}, util.yearmonth2str(yearmonth)+'分源泉税徴収高計算書.pdf');
  }

  @Action({ rawError: true })
  public async payment_excel({yearmonth}: {yearmonth: string}) {
    dialog.openProgressDialog({title: '作成中', msg: '作成には時間がかかることがあります。'});
    await util.post_document('document/generate_payment_excel', {yearmonth}, util.formatDate(util.date2obj(yearmonth+'-01'), 'yyyy年mm月')+'支給分給与インポート用.xlsx');
  }


  //Excel
  @Action({ rawError: true })
  public async updateEachPaymentsExcel({excel, branch_id, yearmonth}: {excel, branch_id: number, yearmonth: string}) {
    if (!excel) {
      validate(['Excelファイルを選択してください']);
    } else {
      dialog.openForceConfirmDialog({msg: '給与Excel更新を実行してよろしいですか？', cancel_button: '登録しない', exec_button: '登録する', func: this.updateEachPaymentsExcelProcess, is_next_dialog: true, args: {excel, branch_id, yearmonth}});
    }
  }
  @Action({ rawError: true })
  public async updateEachPaymentsExcelProcess({excel, branch_id, yearmonth}: {excel, branch_id: number, yearmonth: string}) {
    dialog.openProgressDialog({title: '登録中', msg: 'この処理には時間がかかることがあります。', detail: 'そのままお待ちください。'});
    const res = await util.post_file_proxy('payment/update_each_payments_excel', {excel, branch_id, yearmonth});
    if (res.data.error_message) {
      dialog.openErrorAlertDialog({msg: res.data.error_message});
    } else {
      this.setIsOpenPaymentExcelDialog(false);
      node.disable();
      dialog.openAlertDialog({msg: '更新が完了しました'});
    }
  }
}

export default getModule(Manager);