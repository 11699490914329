import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import Vue from "vue";
import Shift from "@/typesold/shift";
import Able from "@/typesold/able";
import Shift_memo from "@/typesold/shift_memo";

export type Employee = {
  id: number;
  name: string;
};
export type EmployeeHash = {
  [id: number]: string;
};
export type Data = {
  employees: any;
  is_valid?: boolean;
  is_set: boolean;
  fetching?: Promise<void>;
};
export type TypelyData = {
  [type: number]: Data;
};
export type BranchlyData = {
  [branch_id: number]: TypelyData;
};
export type MonthlyData = {
  [yearmonth: string]: BranchlyData;
};

@Module({ dynamic: true, store, name: "document_employee_monthly", namespaced: true })
class Monthly extends VuexModule {
  data: MonthlyData = {};

  @Mutation
  public set({
    yearmonth,
    branch_id,
    type,
    data,
  }: {
    yearmonth: string;
    branch_id: number;
    type: number;
    data: Data;
  }) {
    Vue.set(this.data[yearmonth][branch_id], type, {
      ...data,
      is_valid: true,
      is_set: true,
      fetching: null,
    });
  }

  @Mutation
  public disable({
    yearmonth,
    branch_id,
    type,
  }: {
    yearmonth: string;
    branch_id: number;
    type: number;
  }) {
    if (
      this.data[yearmonth] &&
      this.data[yearmonth][branch_id] &&
      this.data[yearmonth][branch_id][type]
    ) {
      this.data[yearmonth][branch_id][type].is_valid = false;
    }
  }
  @Mutation
  public disable_all() {
    Object.keys(this.data).forEach((yearmonth) => {
      Object.keys(this.data[yearmonth]).forEach((branch_id) => {
        Object.keys(this.data[yearmonth][branch_id]).forEach((type) => {
          this.data[yearmonth][branch_id][type].is_valid = false;
        });
      });
    });
  }
  @Mutation
  public setFetching({
    yearmonth,
    branch_id,
    type,
    value,
  }: {
    yearmonth: string;
    branch_id: number;
    type: number;
    value: Promise<void>;
  }) {
    this.data[yearmonth][branch_id][type].fetching = value;
  }

  // actions
  @Action({ rawError: true })
  public async fetch({
    yearmonth,
    branch_id,
    type,
  }: {
    yearmonth: string;
    branch_id: number;
    type: number;
  }) {
    //ないなら作る
    if (!this.data[yearmonth]) {
      Vue.set(this.data, yearmonth, {});
    }
    if (!this.data[yearmonth][branch_id]) {
      Vue.set(this.data[yearmonth], branch_id, {});
    }
    if (!this.data[yearmonth][branch_id][type]) {
      Vue.set(this.data[yearmonth][branch_id], type, {
        employees: [],
        is_valid: false,
        is_set: false,
        fetching: null,
      });
    }

    if (this.data[yearmonth][branch_id][type].fetching) {
      await this.data[yearmonth][branch_id][type].fetching;
    } else {
      const promise = util
        .post("document/read_employee_monthly", { yearmonth, branch_id, type })
        .then((res) => {
          this.set({ yearmonth, branch_id, type, data: res.data });
        });
      this.setFetching({ yearmonth, branch_id, type, value: promise });
      await promise;
    }
  }
}

export default getModule(Monthly);
