import VuexManagerControl from "@/vuex/VuexManagerControl";
import validationPaidHoliday from "@/validations/paid_holiday";
import { validationEmployeeMail, validationEmployeeBase, validationEmployeePayment, validationEmployeeCode, validationMail, validationPassword, validationCurrentPassword, validationAdministrator } from "@/validations/employee";
import { validationEachEmployeeInfos } from "@/validations/employee_info";
import { validationDependant, validationSpouce } from "@/validations/family";

export default class Manager extends VuexManagerControl {
  create_descriptor = {
   
  };

  update_descriptor = {
    admit: {
      url: 'employee/admit_employee',
      validate: [validationEmployeeCode, validationEmployeePayment],
      dialog: {msg: '入社を承認してよろしいですか？', detail: '承認後に削除することはできません。', cancel_button: '承認しない', exec_button: '承認する'},
      processing_dialog: {},
      finish_dialog: {msg: '社員の入社承認が完了しました。'},
      routing: {
        name: 'employee',
        plus_args: {employee_tab: 'join'},
      },
    },
    deny: {
      url: 'employee/deny_employee',
      dialog: {msg: '入社申請を差し戻してよろしいですか？', detail: '再度従業員に入社メールが送信されます。', cancel_button: '差し戻さない', exec_button: '差し戻す'},
      processing_dialog: {},
      finish_dialog: {msg: '入社申請の差し戻しが完了しました。'},
      routing: {
        name: 'employee',
        plus_args: {employee_tab: 'join'},
      },
    },
   
  }
  delete_descriptor = {

  }
}