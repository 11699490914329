import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import Vue from "vue";
import Shift from "@/typesold/shift";
import Able from "@/typesold/able";
import Shift_memo from "@/typesold/shift_memo";

export type EmployeeHash = {
  [id: number]: string;
};
export type Data = {
  employees: any;
  employees_all: any;
  employee_hash: any;
  employee_hash_all: any;
  is_valid?: boolean;
  is_set: boolean;
  fetching?: Promise<void>;
};
export type TypelyData = {
  [type: number]: Data;
};
export type BranchlyData = {
  [branch_id: number]: TypelyData;
};
export type MonthlyData = {
  [year: number]: BranchlyData;
};

@Module({ dynamic: true, store, name: "document_employee_yearly", namespaced: true })
class Monthly extends VuexModule {
  data: MonthlyData = {};

  @Mutation
  public set({
    year,
    branch_id,
    type,
    data,
  }: {
    year: number;
    branch_id: number;
    type: number;
    data: Data;
  }) {
    var employee_hash = util.createHashObject(data.employees, "id");
    var employee_hash_all = util.createHashObject(data.employees_all, "id");
    Vue.set(this.data[year][branch_id], type, {
      ...data,
      employee_hash_all,
      employee_hash,
      is_valid: true,
      is_set: true,
      fetching: null,
    });
  }

  @Mutation
  public disable({ year, branch_id, type }: { year: number; branch_id: number; type: number }) {
    if (this.data[year] && this.data[year][branch_id] && this.data[year][branch_id][type]) {
      this.data[year][branch_id][type].is_valid = false;
    }
  }
  @Mutation
  public disable_all() {
    Object.keys(this.data).forEach((year) => {
      Object.keys(this.data[year]).forEach((branch_id) => {
        Object.keys(this.data[year][branch_id]).forEach((type) => {
          this.data[year][branch_id][type].is_valid = false;
        });
      });
    });
  }
  @Mutation
  public setFetching({
    year,
    branch_id,
    type,
    value,
  }: {
    year: number;
    branch_id: number;
    type: number;
    value: Promise<void>;
  }) {
    this.data[year][branch_id][type].fetching = value;
  }

  // actions
  @Action({ rawError: true })
  public async fetch({ year, branch_id, type }: { year: number; branch_id: number; type: number }) {
    //ないなら作る
    if (!this.data[year]) {
      Vue.set(this.data, year, {});
    }
    if (!this.data[year][branch_id]) {
      Vue.set(this.data[year], branch_id, {});
    }
    if (!this.data[year][branch_id][type]) {
      Vue.set(this.data[year][branch_id], type, {
        employees: [],
        employees_all: [],
        employee_hash: {},
        employee_hash_all: {},
        is_valid: false,
        is_set: false,
        fetching: null,
      });
    }

    if (this.data[year][branch_id][type].fetching) {
      await this.data[year][branch_id][type].fetching;
    } else {
      const promise = util
        .post("document/read_employee_yearly", { year, branch_id, type })
        .then((res) => {
          this.set({ year, branch_id, type, data: res.data });
        });
      this.setFetching({ year, branch_id, type, value: promise });
      await promise;
    }
  }
}

export default getModule(Monthly);
