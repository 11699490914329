import VuexManagerControl from "@/vuex/VuexManagerControl";
import validationCompany from "@/validations/company";
import validationBranch, {
  validationBranchBank,
  validationBranchBelongEmployee,
  validationBranchBelongGroup,
} from "@/validations/branch";
import { ProcessDescriptor } from "@/typesold/VuexControl";

export default class Manager extends VuexManagerControl {
  create_descriptor = {
    branch: {
      url: "config/create_branch",
      name: "店舗",
      validate: validationBranch,
      dialog: { detail: "月額料金が変更されます。" },
      processing_dialog: {},
      finish_dialog: {},
    },
  };
  update_descriptor = {
    base: {
      url: "company/update_company",
      name: "会社情報",
      validate: validationCompany,
      dialog: {},
      processing_dialog: {},
      finish_dialog: {},
    },
    branch: {
      url: "config/update_branch",
      name: "店舗",
      validate: validationBranch,
      dialog: {},
      processing_dialog: {},
      finish_dialog: {},
    },
    branch_bank: {
      url: "config/update_branch_bank",
      name: "店舗口座情報",
      dialog: {},
      validate: validationBranchBank,
      processing_dialog: {},
      finish_dialog: {},
    },
  };
  delete_descriptor = {
    branch: {
      url: "config/delete_branch",
      name: "店舗",
      validate: [validationBranchBelongEmployee, validationBranchBelongGroup],
      dialog: {},
      force_dialog: {},
      processing_dialog: {},
      finish_dialog: {},
    },
  };
}
