
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import dialog from "@/vuex/dialog";
  import utilMixins from "@/mixins";
  import * as util from "@/util";
  import header from "@/vuex/header";
  import UtilPopup from "@/components/util/Popup.vue";
  import CommonBigbutton from "@/components/common/Bigbutton.vue";

  @Component({
    components: {
      UtilPopup,
      CommonBigbutton,
    },
  })
  export default class Main extends Mixins(utilMixins) {
    get is_display() {
      return header.is_open_movie_dialog;
    }
    set is_display(val: boolean) {
      header.setIsOpenMovieDialog(val);
    }

    open_shift_movie() {
      window.open("https://youtu.be/UiI6X9RWymw", "_blank");
    }
    open_attendance_movie() {
      window.open("https://youtu.be/ClPMzWnYJcc", "_blank");
    }
    open_punch_movie() {
      window.open("https://youtu.be/Mtc-FrY2_9c", "_blank");
    }
    open_nencho_movie() {
      window.open("https://youtu.be/yfPQfbF1S8E", "_blank");
    }
  }
