import { Mutation, MutationAction, Action, VuexModule, getModule, Module } from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import Vue from 'vue';

export type EmployeeData = {
  id: number,
  name: string,//nicknameの可能性あり
}
export type Data = {
  employees, 
  branches, 
  employee_hash, 
  branch_hash, 
  branch_hash_all: any,
  stdincomes,
  max_health_grade: number,
  max_pension_grade: number,
  min_health_grade: number,
  min_pension_grade: number,
  started_on: string, 
  ended_on: string, 
  is_valid?: boolean, 
  is_set: boolean,
  fetching?: Promise<void>
}
export type BranchlyData = {
  [branch: number]: Data
}
export type MonthlyData = {
  [yearmonth: string]: BranchlyData
}

@Module({ dynamic: true, store, name: "monthly_stdincome_data", namespaced: true })
class Monthly extends VuexModule {
  data: MonthlyData = {};

  @Mutation
  public set({yearmonth, branch_id, data}: {yearmonth: string, branch_id: number, data: Data}) {
    Vue.set(this.data[yearmonth], branch_id, {
      ...data,
      employees: data.employees,
      employee_hash: util.createHashObject(data.employees, 'id'),
      branches: data.branches,
      branch_hash: util.createHashReal(data.branches, 'id', 'name'),
      branch_hash_all: util.createHash(data.branches, 'id', 'name'),
      is_valid: true,
      is_set: true,
      fetching: null,
    });
  }
  @Mutation
  public disable({yearmonth, branch_id}: {yearmonth: string, branch_id?: number}) {
    if (branch_id === undefined || branch_id == 0) {
      if (this.data[yearmonth]) {
        Object.keys(this.data[yearmonth]).forEach(branch_id => {
          this.data[yearmonth][branch_id].is_valid = false;
        });
      }
    } else {
      if (this.data[yearmonth] && this.data[yearmonth][branch_id]) {
        this.data[yearmonth][branch_id].is_valid = false;
      }
    }
  }
  @Mutation
  public disable_all() {
    Object.keys(this.data).forEach(yearmonth => {
      Object.keys(this.data[yearmonth]).forEach(branch_id => {
        this.data[yearmonth][branch_id].is_valid = false;
      });
    });
  }

  @Mutation
  public setFetching({yearmonth, branch_id, value}: {yearmonth: string, branch_id: number, value: Promise<void>}) {
    this.data[yearmonth][branch_id].fetching = value;
  }


  // actions
  @Action({ rawError: true })
  public async fetch({yearmonth, branch_id, payment_info_id}: {yearmonth: string, branch_id: number, payment_info_id: number}) {
    //ないなら作る
    if (!this.data[yearmonth]) {
      Vue.set(this.data, yearmonth, {});
    }
    if (!this.data[yearmonth][branch_id]) {
      Vue.set(this.data[yearmonth], branch_id, {
        employee_hash: {},
        branch_hash: {},
        branch_hash_all: {},
        stdincomes: [],
        is_valid: false,
        is_set: false,
        fetching: null,
      });
    }

    if (this.data[yearmonth][branch_id].fetching) {
      await this.data[yearmonth][branch_id].fetching;
    } else {
      const promise = util.post('payment/read_monthly_stdincome', {branch_id, payment_info_id}).then((res) => {
        this.set({yearmonth, branch_id, data: res.data});
      });
      this.setFetching({yearmonth, branch_id, value: promise});
      await promise;
    }
  }
}

export default getModule(Monthly);

