import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import yearly from "@/vuex/nencho/yearly";
import employee_yearly from "@/vuex/nencho/employee_yearly";
import insurance_yearly from "@/vuex/nencho/insurance_yearly";
import prev_payment_yearly from "@/vuex/nencho/prev_payment_yearly";
import loan_yearly from "@/vuex/nencho/loan_yearly";
import global from "@/vuex/nencho/global";
import * as util from "@/util";
import validate from "@/validations/validate";
import { validationResidentTaxes } from "@/validations/bundle";
import dialog from "@/vuex/dialog";
import Vue from "vue";
import router from "@/router";
import node from "@/vuex/node";

@Module({ dynamic: true, store, name: "bundle_manager", namespaced: true })
class Manager extends VuexModule {
  disp_num: number = 10;
  edit_resident_flag: boolean = false;
  is_open_csv_dialog: boolean = false;
  resident_taxes: any = {};
  yearend_zips: any = {};

  @Mutation
  public setDispNum(val) {
    this.disp_num = val;
  }

  @Action({ rawError: true })
  public cancelAll() {
    this.setEditResidentFlag(false);
  }

  @Action({ rawError: true })
  public async is_open_input() {
    return this.edit_resident_flag;
  }

  @Mutation
  public setIsOpenCsvDialog(val: boolean) {
    this.is_open_csv_dialog = val;
  }

  @Mutation
  private setEditResidentFlag(val: boolean) {
    if (!val) {
      Vue.set(this, "resident_taxes", {});
      Vue.set(this, "yearend_zips", {});
    }
    this.edit_resident_flag = val;
  }

  @Action({ rawError: true })
  public async editResident() {
    if (await this.is_open_input()) return;
    this.setEditResidentFlag(true);
  }

  @Action({ rawError: true })
  public updateResident({
    branch_id,
    year,
    employee_hash,
  }: {
    branch_id: number;
    year: number;
    employee_hash: any;
  }) {
    if (validate(validationResidentTaxes(this.resident_taxes, employee_hash))) {
      dialog.openConfirmDialog({
        msg: "住民税を一括変更してよろしいですか？",
        cancel_button: "更新しない",
        exec_button: "更新する",
        func: this.updateResidentProcess,
        is_next_dialog: true,
        args: { branch_id, year },
      });
    }
  }
  @Action({ rawError: true })
  public async updateResidentProcess({ branch_id, year }: { branch_id: number; year: number }) {
    dialog.openProgressDialog({ title: "更新中", msg: "この処理には時間がかかることがあります。" });
    await util.post("bundle/update_resident_taxes", {
      branch_id,
      year,
      resident_taxes: this.resident_taxes,
      yearend_zips: this.yearend_zips,
    });
    this.cancelAll();
    node.disable();
    dialog.openAlertDialog({ msg: "更新が完了しました" });
  }

  @Action({ rawError: true })
  public updateYearendZip({
    branch_id,
    year,
    branch_name,
  }: {
    branch_id: number;
    year: number;
    branch_name: string;
  }) {
    if (branch_name == "全店舗") {
      dialog.openForceConfirmDialog({
        msg: "全従業員の住民税納付先を更新してよろしいですか？",
        detail: "今年入社した従業員分の納付先が更新されます。",
        cancel_button: "更新しない",
        exec_button: "更新する",
        func: this.updateYearendZipProcess,
        is_next_dialog: true,
        args: { branch_id, year, branch_name },
      });
    } else {
      dialog.openForceConfirmDialog({
        msg: branch_name + "に主所属の従業員の住民税納付先を更新してよろしいですか？",
        detail: "今年入社した従業員分の納付先が更新されます。",
        cancel_button: "更新しない",
        exec_button: "更新する",
        func: this.updateYearendZipProcess,
        is_next_dialog: true,
        args: { branch_id, year, branch_name },
      });
    }
  }
  @Action({ rawError: true })
  public async updateYearendZipProcess({ branch_id, year }: { branch_id: number; year: number }) {
    dialog.openProgressDialog({ title: "更新中", msg: "この処理には時間がかかることがあります。" });
    await util.post("bundle/update_yearend_zip", { branch_id, year });
    this.cancelAll();
    node.disable();
    dialog.openAlertDialog({ msg: "更新が完了しました" });
  }

  @Mutation
  setResidentTax({ employee_id, resident_tax }: { employee_id: number; resident_tax: any }) {
    Vue.set(this.resident_taxes, employee_id, resident_tax);
  }
  @Mutation
  setResidentTaxes({ resident_taxes }: { resident_taxes: any }) {
    this.resident_taxes = resident_taxes;
  }

  @Mutation
  setYearendZip({ employee_id, yearend_zip }: { employee_id: number; yearend_zip?: string }) {
    Vue.set(this.yearend_zips, employee_id, yearend_zip);
  }

  @Mutation
  setYearendZips({ yearend_zips }: { yearend_zips: any }) {
    this.yearend_zips = yearend_zips;
  }

  @Action({ rawError: true })
  public async updateResidentTaxesCsv({ excel, year }) {
    if (!excel) {
      validate(["CSVファイルを選択してください"]);
    } else {
      dialog.openForceConfirmDialog({
        msg: "住民税CSV更新を実行してよろしいですか？",
        cancel_button: "実行しない",
        exec_button: "実行する",
        func: this.updateResidentTaxesCsvProcess,
        is_next_dialog: true,
        args: { excel, year },
      });
    }
  }

  @Action({ rawError: true })
  public async updateResidentTaxesCsvProcess({ excel, year }) {
    dialog.openProgressDialog({ title: "処理中", msg: "この処理には時間がかかることがあります。" });
    await util.post_file("bundle/update_resident_taxes_csv", { csv: excel, year });
    this.setIsOpenCsvDialog(false);
    dialog.openAlertDialog({ msg: "登録が完了しました。" });
  }
}

export default getModule(Manager);
