import Vue from 'vue';
import * as util from '@/util';

export default class VuexEditManager {
  flag = Vue.observable<number | boolean>(false);
  change_flag = Vue.observable<null | boolean>(null);//アクティブエディットの場合にのみboolean
  object = Vue.observable<any>({});
 
  add(object: any) {
    Vue.set(this, 'object', util.deep_copy(object));
    this.flag = true;
  }
  edit(object: any, id: number | boolean = true) {
    Vue.set(this, 'object', util.deep_copy(object));
    this.flag = id;
  }
  cancel() {
    this.flag = false;
    Vue.set(this, 'object', {});
  }

  setChangeFlag(change_flag: boolean) {
    this.change_flag = change_flag;
  }

  isInputting(): boolean {
    return this.flag !== false && (this.change_flag === null || this.change_flag !== false);
  }
}