
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {
  }
})
export default class Textarea extends Vue {
  @Prop() value: number | string;
  @Prop() error_cond: [{func, msg}];
  @Prop({default: 5}) rows: number;
  @Prop({default: ''}) placeholder: string;

  once_input: boolean = false;//lazy

  get disp_value() {
    return this.value;
  }
  set disp_value(val) {
    this.input_value(val);
    this.once_input = true;
  }

  input_value(val) {
    this.$emit('input', val == '' ? null : val);
  }
}
