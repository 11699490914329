import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import Vue from "vue";
import Shift from "@/typesold/shift";
import Able from "@/typesold/able";
import Need from "@/typesold/need";

export type Data = {
  shifts: { [date: string]: Shift };
  ables: { [date: string]: Able };
  needs: { [date: string]: Need };
  is_valid?: boolean;
  is_set: boolean;
  fetching?: Promise<void>;
};
export type BranchlyData = {
  [branch: number]: Data;
};
export type MonthlyData = {
  [yearmonth: string]: BranchlyData;
};

@Module({ dynamic: true, store, name: "submit_monthly_data", namespaced: true })
class Monthly extends VuexModule {
  data: MonthlyData = {};

  @Mutation
  public set({ yearmonth, branch_id, data }: { yearmonth: string; branch_id: number; data: Data }) {
    Vue.set(this.data[yearmonth], branch_id, {
      shifts: data.shifts,
      ables: data.ables,
      needs: data.needs,
      is_valid: true,
      is_set: true,
      fetching: null,
    });
  }
  @Mutation
  public disable({ yearmonth, branch_id }: { yearmonth: string; branch_id: number }) {
    if (this.data[yearmonth] && this.data[yearmonth][branch_id]) {
      this.data[yearmonth][branch_id].is_valid = false;
    }
  }
  @Mutation
  public disable_all() {
    Object.keys(this.data).forEach((yearmonth) => {
      Object.keys(this.data[yearmonth]).forEach((branch_id) => {
        this.data[yearmonth][branch_id].is_valid = false;
      });
    });
  }

  @Mutation
  public setFetching({
    yearmonth,
    branch_id,
    value,
  }: {
    yearmonth: string;
    branch_id: number;
    value: Promise<void>;
  }) {
    this.data[yearmonth][branch_id].fetching = value;
  }

  // actions
  @Action({ rawError: true })
  public async fetch({ yearmonth, branch_id }: { yearmonth: string; branch_id: number }) {
    //ないなら作る
    if (!this.data[yearmonth]) {
      Vue.set(this.data, yearmonth, {});
    }
    if (!this.data[yearmonth][branch_id]) {
      Vue.set(this.data[yearmonth], branch_id, {
        shifts: {},
        ables: {},
        needs: [],
        is_valid: false,
        is_set: false,
        fetching: null,
      });
    }

    if (this.data[yearmonth][branch_id].fetching) {
      await this.data[yearmonth][branch_id].fetching;
    } else {
      const promise = util
        .post("submit/read_able_monthly", { yearmonth, branch_id })
        .then((res) => {
          this.set({ yearmonth, branch_id, data: res.data });
        });
      this.setFetching({ yearmonth, branch_id, value: promise });
      await promise;
    }
  }
}

export default getModule(Monthly);
