import { render, staticRenderFns } from "./Systemnotification.vue?vue&type=template&id=18c7eae2&scoped=true&lang=pug"
import script from "./Systemnotification.vue?vue&type=script&lang=ts"
export * from "./Systemnotification.vue?vue&type=script&lang=ts"
import style0 from "./Systemnotification.vue?vue&type=style&index=0&id=18c7eae2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18c7eae2",
  null
  
)

export default component.exports