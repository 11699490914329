import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import Vue from "vue";
import global from "@/vuex/attendance_each/global";

export type EmployeeHash = {
  [id: number]: string;
};
export type LockTypeHash = {
  [id: number]: number;
};
export type BranchObject = {
  [id: number]: string;
};
export type HolidayHash = {
  [date: string]: number;
};
export type HourlyPaidholHash = {
  [date: string]: number;
};
export type CarfareHash = {
  [date: string]: number;
};
export type AttendanceErrorHash = {
  [date: string]: number | null;
};
export type ShiftHash = {
  [date: string]: any;
};
export type Data = {
  employee: any;
  branch_hash: BranchObject;
  branch_hash_all: BranchObject;
  each_attendance: any;
  lock_types_hash: LockTypeHash;
  attendance_error_hash: AttendanceErrorHash;
  shifts: ShiftHash;
  holiday_hash: HolidayHash;
  hourly_paidhol_hash: HourlyPaidholHash;
  carfare_hash: CarfareHash;
  prev_employee_id: number | null;
  next_employee_id: number | null;
  show_config: any;
  is_over_fivehundred: boolean;
  is_shift_punch_sync: boolean;
  is_valid?: boolean;
  is_set: boolean;
  fetching?: Promise<void>;
};
export type EmployeelyData = {
  [employee: number]: Data;
};
export type MonthlyData = {
  [yearmonth: string]: EmployeelyData;
};

@Module({ dynamic: true, store, name: "attendance_each_monthly_data", namespaced: true })
class AttendanceEachMonthly extends VuexModule {
  data: MonthlyData = {};

  @Mutation
  public set({ yearmonth, employee_id, data }: { yearmonth: string; employee_id: number; data }) {
    Vue.set(this.data[yearmonth], employee_id, {
      ...data,
      branch_hash: util.createHashReal(data.branches, "id", "name"),
      branch_hash_all: util.createHash(data.all_branches, "id", "name"),
      lock_types_hash: util.createHash(data.lock_types, "branch_id", "lock_type"),
      attendance_error_hash: data.attendance_error, //元々hash
      holiday_hash: data.holidays, //元々hash
      hourly_paidhol_hash: data.hourly_paidhols, //元々hash
      carfare_hash: data.carfares, //元々hash
      is_valid: true,
      is_set: true,
      fetching: null,
    });
  }

  @Mutation
  public createShift({
    yearmonth,
    date,
    employee_id,
    shift,
  }: {
    yearmonth: string;
    date: string;
    employee_id: number;
    shift;
  }) {
    shift = {
      ...shift,
      shift_shift: shift.shift_shift.filter(
        (shift_shift) => shift_shift.started_at || shift_shift.ended_at,
      ),
      shift_punch: shift.shift_punch.filter(
        (shift_punch) => shift_punch.started_at || shift_punch.ended_at,
      ),
      shift_break: shift.shift_break.filter(
        (shift_break) => shift_break.started_at || shift_break.ended_at,
      ),
    };

    if (!this.data[yearmonth][employee_id].shifts[date]) {
      Vue.set(this.data[yearmonth][employee_id].shifts, date, []);
    }

    this.data[yearmonth][employee_id].shifts[date].push(shift);
  }
  @Mutation
  public updateShift({
    yearmonth,
    date,
    employee_id,
    shift_id,
    shift,
  }: {
    yearmonth: string;
    date: string;
    employee_id: number;
    shift_id: number;
    shift;
  }) {
    shift = {
      ...shift,
      shift_shift: shift.shift_shift.filter(
        (shift_shift) => shift_shift.started_at || shift_shift.ended_at,
      ),
      shift_punch: shift.shift_punch.filter(
        (shift_punch) => shift_punch.started_at || shift_punch.ended_at,
      ),
      shift_break: shift.shift_break.filter(
        (shift_break) => shift_break.started_at || shift_break.ended_at,
      ),
    };

    this.data[yearmonth][employee_id].shifts[date].forEach((v, i, a) => {
      if (v.id == shift_id) {
        a[i] = shift;
      }
    });
  }
  @Mutation
  public deleteShift({
    yearmonth,
    date,
    employee_id,
    shift_id,
  }: {
    yearmonth: string;
    date: string;
    employee_id: number;
    shift_id: number;
  }) {
    this.data[yearmonth][employee_id].shifts[date] = this.data[yearmonth][employee_id].shifts[
      date
    ].filter((shift) => shift.id != shift_id);
  }

  @Mutation
  public disable({ yearmonth, employee_id }: { yearmonth?: string; employee_id?: number }) {
    if (employee_id === undefined) {
      if (yearmonth === undefined) {
        Object.keys(this.data).forEach((yearmonth) => {
          if (this.data[yearmonth]) {
            Object.keys(this.data[yearmonth]).forEach((employee_id) => {
              this.data[yearmonth][employee_id].is_valid = false;
            });
          }
        });
      } else {
        if (this.data[yearmonth]) {
          Object.keys(this.data[yearmonth]).forEach((employee_id) => {
            this.data[yearmonth][employee_id].is_valid = false;
          });
        }
      }
    } else {
      if (yearmonth === undefined) {
        Object.keys(this.data).forEach((yearmonth) => {
          if (this.data[yearmonth] && this.data[yearmonth][employee_id]) {
            this.data[yearmonth][employee_id].is_valid = false;
          }
        });
      } else {
        if (this.data[yearmonth] && this.data[yearmonth][employee_id]) {
          this.data[yearmonth][employee_id].is_valid = false;
        }
      }
    }
  }
  @Mutation
  public disable_all() {
    Object.keys(this.data).forEach((yearmonth) => {
      Object.keys(this.data[yearmonth]).forEach((employee_id) => {
        this.data[yearmonth][employee_id].is_valid = false;
      });
    });
  }
  @Mutation
  public setFetching({
    yearmonth,
    employee_id,
    value,
  }: {
    yearmonth: string;
    employee_id: number;
    value: Promise<void>;
  }) {
    this.data[yearmonth][employee_id].fetching = value;
  }

  // actions
  @Action({ rawError: true })
  public async fetch({
    yearmonth,
    branch_id,
    employee_id,
    payment_info_id,
  }: {
    yearmonth: string;
    branch_id: number;
    employee_id: number;
    payment_info_id: number;
  }) {
    //ないなら作る
    if (!this.data[yearmonth]) {
      Vue.set(this.data, yearmonth, {});
    }
    if (!this.data[yearmonth][employee_id]) {
      Vue.set(this.data[yearmonth], employee_id, {
        employee: {},
        branch_hash: {},
        each_attendance: {},
        lock_types_hash: {},
        attendance_error_hash: {},
        shifts: {},
        holiday_hash: {},
        carfare_hash: {},
        prev_employee_id: null,
        next_employee_id: null,
        show_config: {},
        is_over_fivehundred: false,
        is_valid: false,
        is_set: false,
        fetching: null,
        continuous_error_time: 0,
        continuous_error_day: 0,
      });
    }

    if (this.data[yearmonth][employee_id].fetching) {
      await this.data[yearmonth][employee_id].fetching;
    } else {
      const promise = util
        .post("attendance_each/read_monthly", {
          yearmonth,
          employee_id,
          branch_id,
          payment_info_id,
        })
        .then((res) => {
          this.set({ yearmonth, employee_id, data: res.data });
        });
      this.setFetching({ yearmonth, employee_id, value: promise });
      await promise;
    }
  }
}

export default getModule(AttendanceEachMonthly);
