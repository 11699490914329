import { Component, Vue, Mixins } from "vue-property-decorator";
import yearly from "@/vuex/nencho/yearly";
import employee_yearly from "@/vuex/nencho/employee_yearly";
import insurance_yearly from "@/vuex/nencho/insurance_yearly";
import prev_payment_yearly from "@/vuex/nencho/prev_payment_yearly";
import loan_yearly from "@/vuex/nencho/loan_yearly";
import global from "@/vuex/nencho/global";
import auth from "@/vuex/employee_each/auth";
import * as util from "@/util";
import utilMixins from "@/mixins";
import manager from "@/vuex/nencho/manager";
import employee_manager from "@/vuex/employee_each/manager";
import { BranchHeaderData } from "@/typesold/header";
import vuex_data from "@/vuex/vuex_data";
import vuex_manager from "@/vuex/vuex_manager";

@Component
export default class mixinNencho extends Mixins(utilMixins) {
  changed_disp_num: boolean = false;

  get newm() {
    return vuex_manager.nencho_new_manager;
  }

  get global() {
    return vuex_data.nencho_global.get(this);
  }
  get global_latest() {
    return vuex_data.nencho_global.getLatest();
  }

  get is_set_global(): boolean {
    return !!this.global;
  }
  get is_set_yearly(): boolean {
    return (
      yearly.data[this.nencho_id] &&
      yearly.data[this.nencho_id][this.viewing_branch] &&
      yearly.data[this.nencho_id][this.viewing_branch].is_set
    );
  }
  get is_set_employee_yearly(): boolean {
    return (
      employee_yearly.data[this.nencho_id] &&
      employee_yearly.data[this.nencho_id][this.employee_id] &&
      employee_yearly.data[this.nencho_id][this.employee_id] &&
      employee_yearly.data[this.nencho_id][this.employee_id].is_set
    );
  }
  get is_set_insurance_yearly(): boolean {
    return (
      insurance_yearly.data[this.nencho_id] &&
      insurance_yearly.data[this.nencho_id][this.employee_id] &&
      insurance_yearly.data[this.nencho_id][this.employee_id] &&
      insurance_yearly.data[this.nencho_id][this.employee_id].is_set
    );
  }
  get is_set_prev_payment_yearly(): boolean {
    return (
      prev_payment_yearly.data[this.nencho_id] &&
      prev_payment_yearly.data[this.nencho_id][this.employee_id] &&
      prev_payment_yearly.data[this.nencho_id][this.employee_id].is_set
    );
  }
  get is_set_loan_yearly(): boolean {
    return (
      loan_yearly.data[this.nencho_id] &&
      loan_yearly.data[this.nencho_id][this.employee_id] &&
      loan_yearly.data[this.nencho_id][this.employee_id].is_set
    );
  }
  get is_set_auth(): boolean {
    return auth.data[this.employee_id] && auth.data[this.employee_id].is_set;
  }

  get is_valid_global(): boolean {
    return !!this.global;
  }
  get is_valid_yearly(): boolean {
    return (
      yearly.data[this.nencho_id] &&
      yearly.data[this.nencho_id][this.viewing_branch] &&
      yearly.data[this.nencho_id][this.viewing_branch].is_valid
    );
  }
  get is_valid_employee_yearly(): boolean {
    return (
      employee_yearly.data[this.nencho_id] &&
      employee_yearly.data[this.nencho_id][this.employee_id] &&
      employee_yearly.data[this.nencho_id][this.employee_id].is_valid
    );
  }
  get is_valid_insurance_yearly(): boolean {
    return (
      insurance_yearly.data[this.nencho_id] &&
      insurance_yearly.data[this.nencho_id][this.employee_id] &&
      insurance_yearly.data[this.nencho_id][this.employee_id].is_valid
    );
  }
  get is_valid_prev_payment_yearly(): boolean {
    return (
      prev_payment_yearly.data[this.nencho_id] &&
      prev_payment_yearly.data[this.nencho_id][this.employee_id] &&
      prev_payment_yearly.data[this.nencho_id][this.employee_id].is_valid
    );
  }
  get is_valid_loan_yearly(): boolean {
    return (
      loan_yearly.data[this.nencho_id] &&
      loan_yearly.data[this.nencho_id][this.employee_id] &&
      loan_yearly.data[this.nencho_id][this.employee_id].is_valid
    );
  }
  get is_valid_auth(): boolean {
    return auth.data[this.employee_id] && auth.data[this.employee_id].is_valid;
  }

  async get_yearly() {
    await yearly.fetch({ branch_id: this.viewing_branch, nencho_id: this.nencho_id });
  }
  async get_employee_yearly() {
    await employee_yearly.fetch({ nencho_id: this.nencho_id, employee_id: this.employee_id });
  }
  async get_insurance_yearly() {
    await insurance_yearly.fetch({ nencho_id: this.nencho_id, employee_id: this.employee_id });
  }
  async get_prev_payment_yearly() {
    await prev_payment_yearly.fetch({ nencho_id: this.nencho_id, employee_id: this.employee_id });
  }
  async get_loan_yearly() {
    await loan_yearly.fetch({ nencho_id: this.nencho_id, employee_id: this.employee_id });
  }
  async get_auth() {
    await auth.fetch({ employee_id: this.employee_id });
  }

  get yearly() {
    if ((!this.is_set_yearly || !this.is_valid_yearly) && this.nencho_id !== 0) this.get_yearly();
    if (this.is_set_yearly) return yearly.data[this.nencho_id][this.viewing_branch];
  }
  get employee_yearly() {
    if ((!this.is_set_employee_yearly || !this.is_valid_employee_yearly) && this.nencho_id !== 0)
      this.get_employee_yearly();
    if (this.is_set_employee_yearly) return employee_yearly.data[this.nencho_id][this.employee_id];
  }
  get insurance_yearly() {
    if ((!this.is_set_insurance_yearly || !this.is_valid_insurance_yearly) && this.nencho_id !== 0)
      this.get_insurance_yearly();
    if (this.is_set_insurance_yearly)
      return insurance_yearly.data[this.nencho_id][this.employee_id];
  }
  get prev_payment_yearly() {
    if (
      (!this.is_set_prev_payment_yearly || !this.is_valid_prev_payment_yearly) &&
      this.nencho_id !== 0
    )
      this.get_prev_payment_yearly();
    if (this.is_set_prev_payment_yearly)
      return prev_payment_yearly.data[this.nencho_id][this.employee_id];
  }
  get loan_yearly() {
    if ((!this.is_set_loan_yearly || !this.is_valid_loan_yearly) && this.nencho_id !== 0)
      this.get_loan_yearly();
    if (this.is_set_loan_yearly) return loan_yearly.data[this.nencho_id][this.employee_id];
  }
  get auth() {
    if (!this.is_set_auth || !this.is_valid_auth) this.get_auth();
    if (this.is_set_auth) return auth.data[this.employee_id];
  }

  get employee_auth() {
    return this.auth.employee;
  }

  get nencho_id() {
    if (this.param_nencho_id == 0) {
      return this.global_latest.latest_id;
    } else {
      return this.param_nencho_id;
    }
  }
  set nencho_id(val) {
    this.param_nencho_id = val;
  }

  get employee_id() {
    if (this.is_manager) {
      return this.query.employee_id;
    } else {
      return this.s_employee_id;
    }
  }

  get nencho() {
    return this.global.nencho;
  }
  get payment_info() {
    return this.global.payment_info;
  }
  get bonus_info() {
    return this.global.bonus_info;
  }

  get viewing_branch() {
    var branch_id = this.query.viewing_branch;
    if (branch_id === undefined) {
      branch_id = util.getLocalStorage("branch_id");
      this.query = { viewing_branch: branch_id };
    }
    return branch_id;
  }
  set viewing_branch(val) {
    this.page = 1;
    this.query = { viewing_branch: val };
  }

  get m() {
    return manager;
  }
  get em() {
    return employee_manager;
  }

  get branch_hash_object() {
    return this.global.branch_hash_object;
  }

  get param_nencho_id() {
    var nencho_id = this.query.nencho_id;
    if (nencho_id === undefined) {
      nencho_id = 0;
      this.query = { nencho_id };
    }
    return nencho_id;
  }
  set param_nencho_id(val) {
    if (val != this.param_nencho_id) {
      this.query_push = { nencho_id: val };
    }
  }

  get search_key() {
    var search_key = this.query.nencho_search_key;
    if (search_key === undefined || search_key == "") {
      this.query = { nencho_search_key: undefined };
      return null;
    } else {
      return search_key;
    }
  }
  set search_key(val) {
    this.page = 1;
    if (val == "" || val == null) {
      this.query = { nencho_search_key: undefined };
    } else {
      this.query = { nencho_search_key: val };
    }
  }

  get is_open() {
    return this.query.is_open_nencho;
  }
  set is_open(val) {
    if (val == "" || val == null) {
      this.query = { is_open_nencho: undefined };
    } else {
      this.query = { is_open_nencho: val };
    }
  }

  get sort_code() {
    return this.query.nencho_sort_code;
  }
  set sort_code(val) {
    if (val == "" || val == null) {
      this.query = { nencho_sort_code: undefined };
    } else {
      this.query = { nencho_sort_code: val };
    }
  }
  get sort_name() {
    return this.query.nencho_sort_name;
  }
  set sort_name(val) {
    if (val == "" || val == null) {
      this.query = { nencho_sort_name: undefined };
    } else {
      this.query = { nencho_sort_name: val };
    }
  }
  get sort_hiring_type() {
    return this.query.nencho_sort_hiring_type;
  }
  set sort_hiring_type(val) {
    if (val == "" || val == null) {
      this.query = { nencho_sort_hiring_type: undefined };
    } else {
      this.query = { nencho_sort_hiring_type: val };
    }
  }
  get sort_branch_id() {
    return this.query.nencho_sort_branch_id;
  }
  set sort_branch_id(val) {
    if (val == "" || val == null) {
      this.query = { nencho_sort_branch_id: undefined };
    } else {
      this.query = { nencho_sort_branch_id: val };
    }
  }
  get sort_prev_payment() {
    return this.query.nencho_sort_prev_payment;
  }
  set sort_prev_payment(val) {
    if (val == "" || val == null) {
      this.query = { nencho_sort_prev_payment: undefined };
    } else {
      this.query = { nencho_sort_prev_payment: val };
    }
  }
  get sort_self_info() {
    return this.query.nencho_sort_self_info;
  }
  set sort_self_info(val) {
    if (val == "" || val == null) {
      this.query = { nencho_sort_self_info: undefined };
    } else {
      this.query = { nencho_sort_self_info: val };
    }
  }
  get sort_insurance() {
    return this.query.nencho_sort_insurance;
  }
  set sort_insurance(val) {
    if (val == "" || val == null) {
      this.query = { nencho_sort_insurance: undefined };
    } else {
      this.query = { nencho_sort_insurance: val };
    }
  }
  get sort_loan() {
    return this.query.nencho_sort_loan;
  }
  set sort_loan(val) {
    if (val == "" || val == null) {
      this.query = { nencho_sort_loan: undefined };
    } else {
      this.query = { nencho_sort_loan: val };
    }
  }
  get sort_is_special_levy() {
    return this.query.nencho_sort_is_special_levy;
  }
  set sort_is_special_levy(val) {
    if (val == "" || val == null) {
      this.query = { nencho_sort_is_special_levy: undefined };
    } else {
      this.query = { nencho_sort_is_special_levy: val };
    }
  }
  get sort_reflect() {
    return this.query.nencho_sort_reflect;
  }
  set sort_reflect(val) {
    if (val == "" || val == null) {
      this.query = { nencho_sort_reflect: undefined };
    } else {
      this.query = { nencho_sort_reflect: val };
    }
  }

  get page() {
    var page = this.query.nencho_page;
    if (page === undefined || page == "") {
      this.query = { nencho_page: 1 };
      page = 1;
    }
    return page;
  }
  set page(val) {
    if (val == "" || val == null) {
      this.query = { nencho_page: undefined };
    } else {
      this.query = { nencho_page: val };
    }
  }

  get searched_sorted_nencho_masters() {
    var nencho_masters = util.deep_copy(this.nencho_masters);
    nencho_masters = this.search_nencho_masters(nencho_masters);
    nencho_masters = this.sort_nencho_masters(nencho_masters);
    return nencho_masters;
  }
  get managed_nencho_masters() {
    var nencho_masters = util.deep_copy(this.searched_sorted_nencho_masters);
    nencho_masters = this.page_nencho_masters(nencho_masters);
    return nencho_masters;
  }
  search_nencho_masters(nencho_masters) {
    if (this.search_key === null) {
      return nencho_masters;
    } else {
      const search_keys = this.search_key.split(/\s+/).filter((str) => str != "");
      var _this = this;
      return nencho_masters.filter((nencho_master) => {
        var flag = 0;
        search_keys.forEach((key) => {
          if (
            this.employee_hash[nencho_master.employee_id].name
              .toLowerCase()
              .indexOf(key.toLowerCase()) != -1 ||
            util.search_kana(this.employee_hash[nencho_master.employee_id].kana, key) ||
            (this.employee_hash[nencho_master.employee_id].code &&
              this.employee_hash[nencho_master.employee_id].code
                .toLowerCase()
                .indexOf(key.toLowerCase()) != -1)
          ) {
            flag = 1;
            return;
          }
        });
        return flag;
      });
    }
  }
  sort_nencho_masters(nencho_masters) {
    if (this.sort_code)
      nencho_masters.sort((x, y) =>
        util.sort_idx(
          this.employee_hash[x.employee_id].code,
          this.employee_hash[y.employee_id].code,
          this.sort_code,
        ),
      );
    else if (this.sort_name)
      nencho_masters.sort((x, y) =>
        util.sort_idx(
          this.employee_hash[x.employee_id].kana,
          this.employee_hash[y.employee_id].kana,
          this.sort_name,
        ),
      );
    else if (this.sort_hiring_type)
      nencho_masters.sort((x, y) =>
        util.sort_idx(
          this.employee_hash[x.employee_id].hiring_type,
          this.employee_hash[y.employee_id].hiring_type,
          this.sort_hiring_type,
        ),
      );
    else if (this.sort_branch_id)
      nencho_masters.sort((x, y) =>
        util.sort_idx(
          this.employee_hash[x.employee_id].branch_id,
          this.employee_hash[y.employee_id].branch_id,
          this.sort_branch_id,
        ),
      );
    else if (this.sort_prev_payment)
      nencho_masters.sort((x, y) =>
        util.sort_idx(
          x.option_selected ? (x.is_prev_payment ? (x.is_finish_prev_payment ? 3 : 2) : 1) : 0,
          y.option_selected ? (y.is_prev_payment ? (y.is_finish_prev_payment ? 3 : 2) : 1) : 0,
          this.sort_prev_payment,
        ),
      );
    else if (this.sort_self_info)
      nencho_masters.sort((x, y) =>
        util.sort_idx(
          x.option_selected ? (x.is_finish_employee ? 2 : 1) : 0,
          y.option_selected ? (y.is_finish_employee ? 2 : 1) : 0,
          this.sort_self_info,
        ),
      );
    else if (this.sort_insurance)
      nencho_masters.sort((x, y) =>
        util.sort_idx(
          x.option_selected ? (x.is_insurance ? (x.is_finish_insurance ? 3 : 2) : 1) : 0,
          y.option_selected ? (y.is_insurance ? (y.is_finish_insurance ? 3 : 2) : 1) : 0,
          this.sort_insurance,
        ),
      );
    else if (this.sort_loan)
      nencho_masters.sort((x, y) =>
        util.sort_idx(
          x.option_selected ? (x.is_loan ? (x.is_finish_loan ? 3 : 2) : 1) : 0,
          y.option_selected ? (y.is_loan ? (y.is_finish_loan ? 3 : 2) : 1) : 0,
          this.sort_loan,
        ),
      );
    else if (this.sort_is_special_levy)
      nencho_masters.sort((x, y) =>
        util.sort_idx(
          x.option_selected ? (x.is_special_levy ? 2 : 1) : 0,
          y.option_selected ? (y.is_special_levy ? 2 : 1) : 0,
          this.sort_is_special_levy,
        ),
      );
    else if (this.sort_reflect)
      nencho_masters.sort((x, y) =>
        util.sort_idx(
          x.option_selected ? x.reflected_to : -1,
          y.option_selected ? y.reflected_to : -1,
          this.sort_reflect,
        ),
      );

    return nencho_masters;
  }
  page_nencho_masters(nencho_masters) {
    return nencho_masters.slice(this.disp_num * (this.page - 1), this.disp_num * this.page);
  }
  sort_click(sort_key, id) {
    this.page = 1;
    sort_key = "sort_" + sort_key;
    var sort_mode = undefined;
    if (this[sort_key] === undefined) {
      sort_mode = "asc";
    } else if (this[sort_key] == "asc") {
      sort_mode = "desc";
    } else if (this[sort_key] == "desc") {
      sort_mode = "asc";
    }
    this.set_sort(sort_key, sort_mode);
  }
  set_sort(sort_key, sort_mode) {
    this.query = {
      nencho_sort_code: sort_key == "sort_code" ? sort_mode : undefined,
      nencho_sort_name: sort_key == "sort_name" ? sort_mode : undefined,
      nencho_sort_hiring_type: sort_key == "sort_hiring_type" ? sort_mode : undefined,
      nencho_sort_branch_id: sort_key == "sort_branch_id" ? sort_mode : undefined,
      nencho_sort_prev_payment: sort_key == "sort_prev_payment" ? sort_mode : undefined,
      nencho_sort_self_info: sort_key == "sort_self_info" ? sort_mode : undefined,
      nencho_sort_insurance: sort_key == "sort_insurance" ? sort_mode : undefined,
      nencho_sort_loan: sort_key == "sort_loan" ? sort_mode : undefined,
      nencho_sort_is_special_levy: sort_key == "sort_is_special_levy" ? sort_mode : undefined,
      nencho_sort_reflect: sort_key == "sort_reflect" ? sort_mode : undefined,
    };
  }

  get nencho_masters() {
    return this.yearly.nencho_masters;
  }
  get nencho_master_employee() {
    return this.employee_yearly.nencho_master;
  }
  get is12Locked() {
    return this.yearly.is12Locked;
  }

  get employee_hash() {
    return this.yearly.employee_hash;
  }
  get branch_hash() {
    return this.yearly.branch_hash;
  }
  get branch_hash_all() {
    return this.yearly.branch_hash_all;
  }

  get disp_num() {
    return manager.disp_num;
  }
  set disp_num(val) {
    this.page = 1;
    util.setLocalStorage("nencho_disp_num", val);
    manager.setDispNum(val);
  }

  get reflectable_nencho() {
    let header = this.header as BranchHeaderData;
    return this.s_auth == 2 || (this.s_auth == 1 && header.auth_nencho_reflect == 1);
  }
  get editable_nencho() {
    let header = this.header as BranchHeaderData;
    return this.s_auth == 2 || (this.s_auth == 1 && header.auth_nencho == 2);
  }

  get insurance_step_number() {
    return {
      initial: {
        step: 0.1,
        next: 1,
      },
      life: {
        prev: 0.1,
        step: 1,
        next: 2,
      },
      care: {
        prev: 1,
        step: 2,
        next: 3,
      },
      pension: {
        prev: 2,
        step: 3,
        next: 4,
      },
      earthquake: {
        prev: 3,
        step: 4,
        next: 5,
      },
      social: {
        prev: 4,
        step: 5,
        next: 6,
      },
      mutualaid: {
        prev: 5,
        step: 6,
        next: 7,
      },
      confirm: {
        prev: 6,
        step: 7,
        next: 10,
      },
    };
  }

  get prev_payment_step_number() {
    return {
      initial: {
        step: 0.1,
        next: 1,
      },
      payment: {
        prev: 0.1,
        step: 1,
        next: 2,
      },
      confirm: {
        prev: 1,
        step: 2,
        next: 10,
      },
    };
  }

  get loan_step_number() {
    return {
      initial: {
        step: 0.1,
        next: 1,
      },
      new_loan_certificate: {
        prev: 0.1,
        step: 1,
        yes_new_loan: 2,
        no_new_loan: 3,
      },
      new_loan_money: {
        prev: 1,
        step: 2,
        next: 3,
      },
      reform_loan_certificate: {
        yes_new_loan: 2,
        no_new_loan: 1,
        step: 3,
        yes_reform_loan: 4,
        no_reform_loan: 5,
      },
      reform_loan_money: {
        prev: 3,
        step: 4,
        next: 5,
      },
      confirm: {
        step: 5,
        next: 10,
      },
    };
  }
}
