import * as vali from '@/validations/validationUtil';

//validationは、OKならTrue, それ以外なら警告文を配列に入れる。
export function validationBase(self, args) {
  var error = [];
  return error;
}
export function validationPaymentAllowance(self, args) {
  return validationPaymentAllowanceCommon(self, args.payment_allowance);
}
export function validationPaymentDeduction(self, args) {
  console.log(args);
  return validationPaymentAllowanceCommon(self, args.payment_deduction);
}
export function validationPaymentOtherAllowance(self, args) {
  return validationPaymentAllowanceCommon(self, args.payment_other_allowance);
}
export function validationBonusAllowance(self, args) {
  return validationBonusAllowanceCommon(self, args.bonus_allowance);
}
export function validationBonusDeduction(self, args) {
  return validationBonusAllowanceCommon(self, args.bonus_deduction);
}
export function validationBonusOtherAllowance(self, args) {
  return validationBonusAllowanceCommon(self, args.bonus_other_allowance);
}

function validationPaymentAllowanceCommon(self, payment_allowance) {
  var error = [];
  error.push(payment_allowance.name !== null || '項目名を入力してください');
  error.push(payment_allowance.init_type !== null || '初期値を選択してください');
  if (payment_allowance.init_type == 2 || payment_allowance.init_type == 3) {
    error.push(payment_allowance.unit !== null || '単価を入力してください');
    error.push(payment_allowance.unit === null || vali.is_number(payment_allowance.unit) || '単価は半角数字で入力してください');
  }
  if (payment_allowance.paymentgroup_id == -1) {
    error.push(payment_allowance.hiring_type !== null || '区分を選択してください'); 
  }
  if (payment_allowance.init_type == 2 || payment_allowance.init_type == 4 || payment_allowance.init_type == 5) {
    error.push(payment_allowance.num_type !== null || '回数タイプを選択してください');
    if (payment_allowance.init_type == 2 || payment_allowance.init_type == 5) {
      if (payment_allowance.num_type == 0) {
        error.push(payment_allowance.unitstr !== null || '単位名を入力してください');
      } else if (payment_allowance.num_type == 1 || payment_allowance.num_type >= 15) {
        error.push(payment_allowance.per !== null || '時間単位を入力してください');
      }
    }
  }
  if (payment_allowance.init_type == 5) {
    error.push(payment_allowance.individual_unit_id !== null || '個人別単価を選択してください');
  }
  return error;
}
function validationBonusAllowanceCommon(self, bonus_allowance) {
  var error = [];
  error.push(bonus_allowance.name !== null || '項目名を入力してください');
  error.push(bonus_allowance.init_type !== null || '初期値を選択してください');
  if (bonus_allowance.init_type == 2 || bonus_allowance.init_type == 3) {
    error.push(bonus_allowance.unit !== null || '単価を入力してください');
    error.push(bonus_allowance.unit === null || vali.is_number(bonus_allowance.unit) || '単価は半角数字で入力してください');
  }
  if (bonus_allowance.paymentgroup_id == -1) {
    error.push(bonus_allowance.hiring_type !== null || '区分を選択してください'); 
  }
  if (bonus_allowance.init_type == 2 || bonus_allowance.init_type == 5) {
    error.push(bonus_allowance.num_type !== null || '回数タイプを選択してください');
    if (bonus_allowance.init_type == 2 || bonus_allowance.init_type == 5) {
      if (bonus_allowance.num_type == 0) {
        error.push(bonus_allowance.unitstr !== null || '単位名を入力してください');
      } else if (bonus_allowance.num_type == 1) {
        error.push(bonus_allowance.per !== null || '時間単位を入力してください');
      }
    }
  }
  if (bonus_allowance.init_type == 5) {
    error.push(bonus_allowance.individual_unit_id !== null || '個人別単価を選択してください');
  }
  return error;
}

export function validationGroup(self, args) {
  var error = [];
  error.push(args.name !== null || 'グループ名を入力してください');
  error.push(args.branch_id !== null || '部署/店舗を選択してください');
  return error;
}