import * as vali from "@/validations/validationUtil";

export default function validationPaidHoliday(self, args) {
  var error = [];

  error.push(args.paid_holiday.date !== null || '日付を正しく入力してください');
  error.push(args.paid_holiday.value !== null || '日数を入力してください');
  error.push(args.paid_holiday.value === null || vali.is_float(args.paid_holiday.value) || '日数は半角数字(小数点を含む)で入力してください');
  error.push(args.paid_holiday.type !== null || '形態を入力してください');

  return error;
}
