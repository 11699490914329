import { Demand_holiday, Demand_overtime } from "@/typesold/demand";
import * as util from "@/util";
import * as vali from "@/validations/validationUtil";

//validationは、OKならTrue, それ以外なら警告文を配列に入れる。
export function validationDemandHoliday(demand_holiday: Demand_holiday) {
  var error = [];

  error.push(demand_holiday.date !== null || util.$t("error.demand_holiday_enter_date"));
  error.push(demand_holiday.kind !== null || util.$t("error.demand_holiday_kind"));

  return error;
}

export function validationDemandOvertime(
  demand_overtime: Demand_overtime,
  is_prev_overdemand: number,
) {
  var error = [];

  error.push(demand_overtime.date !== null || util.$t("error.demand_overtime_enter_date"));
  if (demand_overtime.date !== null && is_prev_overdemand == 0) {
    error.push(
      util.date2obj(demand_overtime.date).getTime() >= util.date2obj(util.today()).getTime() ||
        util.$t("error.demand_overtime_after_today"),
    );
  }
  error.push(demand_overtime.branch_id !== null || util.$t("error.demand_overtime_select_branch"));
  error.push(demand_overtime.kind !== null || util.$t("error.demand_overtime_kind"));
  if (demand_overtime.kind !== null) {
    if (demand_overtime.kind == 0) {
      error.push(
        demand_overtime.shift_shift_id !== null || util.$t("error.demand_overtime_select_shift"),
      );
      error.push(
        demand_overtime.started_at !== null || util.$t("error.demand_overtime_invalid_started_at"),
      );
      error.push(
        demand_overtime.ended_at !== null || util.$t("error.demand_overtime_enter_ended_at"),
      );
      error.push(
        demand_overtime.started_at < demand_overtime.ended_at ||
          util.$t("error.demand_overtime_after_shift_end"),
      );
    } else if (demand_overtime.kind == 1) {
      error.push(
        demand_overtime.shift_shift_id !== null || util.$t("error.demand_overtime_select_shift"),
      );
      error.push(
        demand_overtime.started_at !== null || util.$t("error.demand_overtime_enter_start"),
      );
      error.push(
        demand_overtime.ended_at !== null || util.$t("error.demand_overtime_invalid_started_at"),
      );
      error.push(
        demand_overtime.started_at < demand_overtime.ended_at ||
          util.$t("error.demand_overtime_before_shift_start"),
      );
    } else if (demand_overtime.kind == 2) {
      error.push(
        demand_overtime.started_at !== null || util.$t("error.demand_overtime_enter_start"),
      );
      error.push(
        demand_overtime.ended_at !== null || util.$t("error.demand_overtime_enter_ended_at"),
      );
      error.push(
        demand_overtime.started_at < demand_overtime.ended_at ||
          util.$t("error.demand_overtime_time_contradiction"),
      );
    }
  }

  return error;
}
