import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import validate from "@/validations/validate";
import { soft_validate } from "@/validations/validate";
import { validationShifts } from "@/validations/shift";
import validationAbles from "@/validations/ables";
import validationAblePattern from "@/validations/able_pattern";
import dialog from "@/vuex/dialog";
import Able from "@/typesold/able";
import Shift from "@/typesold/shift";
import Able_pattern from "@/typesold/able_pattern";
import Vue from "vue";
import global from "@/vuex/submit/global";
import manager from "./manager";
import header from "@/vuex/header";
import router from "@/router";
import node from "@/vuex/node";
import realtime from "@/vuex/shift/realtime";

@Module({ dynamic: true, store, name: "submit_manager", namespaced: true })
class Able_manager extends VuexModule {
  is_valid: boolean = false;
  ables: { [date: string]: Able } = {};
  shifts: { [date: string]: Shift } = {};
  tmp_shift: Shift = null;
  tmp_able: Able = null;

  mode: number = 1; //{0: 従来, 1: カレンダー}

  able_control: Able = {
    id: null,
    employee_id: null,
    date: null,
    content: null,
    able_time: [
      {
        id: null,
        able_id: null,
        started_at: null,
        ended_at: null,
      },
    ],
    able_break_time: [
      {
        id: null,
        able_id: null,
        started_at: null,
        ended_at: null,
      },
    ],
  };
  days_control: number[] = [];

  add_able_pattern_flag: boolean = false;
  edit_able_pattern_flag: number = 0;
  is_open_able_pattern_dialog: boolean = false;
  is_open_submit_dialog: boolean = false;

  @Mutation
  setMode(mode: number) {
    this.mode = mode;
  }

  @Mutation
  private setAddAblePatternFlag(val: boolean) {
    this.add_able_pattern_flag = val;
  }
  @Mutation
  private setEditAblePatternFlag(val: number) {
    this.edit_able_pattern_flag = val;
  }
  @Mutation
  public setIsOpenAblePatternDialog(val) {
    this.is_open_able_pattern_dialog = val;
  }
  @Mutation
  public setIsOpenSubmitDialog(val) {
    this.is_open_submit_dialog = val;
  }
  @Mutation
  public setIsValid(val: boolean) {
    this.is_valid = val;
  }

  @Action({ rawError: true })
  public async is_open_input() {
    return this.add_able_pattern_flag || this.edit_able_pattern_flag != 0;
  }
  @Action({ rawError: true })
  public async addAblePattern() {
    if (await this.is_open_input()) return;
    this.setAddAblePatternFlag(true);
  }
  @Action({ rawError: true })
  public async editAblePattern(able_pattern_id: number) {
    if (await this.is_open_input()) return;
    this.setEditAblePatternFlag(able_pattern_id);
  }
  @Action({ rawError: true })
  public cancelAll() {
    this.setAddAblePatternFlag(false);
    this.setEditAblePatternFlag(0);
  }

  @Mutation
  resetAbles() {
    this.ables = null;
  }

  @Mutation
  setAbles({ yearmonth, ables }: { yearmonth: string; ables: { [date: string]: Able } }) {
    const date_array = util.month_date_array(yearmonth);
    this.ables = {};
    date_array.forEach((date) => {
      if (ables[date]) {
        Vue.set(this.ables, date, ables[date]);
      } else {
        const able: Able = {
          id: null,
          employee_id: null,
          date: date,
          content: null,
          able_time: [],
          able_break_time: [],
        };
        Vue.set(this.ables, date, able);
      }
    });
  }

  @Mutation
  setAble({ date, able }: { date: string; able: Able }) {
    Vue.set(this.ables, date, able);
  }

  @Mutation
  resetShifts() {
    this.shifts = null;
  }
  @Mutation
  setShifts({ yearmonth, shifts }: { yearmonth: string; shifts: { [date: string]: Shift } }) {
    const date_array = util.month_date_array(yearmonth);
    this.shifts = {};
    date_array.forEach((date) => {
      if (shifts[date]) {
        Vue.set(this.shifts, date, {
          id: null,
          employee_id: null,
          date: date,
          shift_shift: shifts[date].shift_shift,
          shift_break: shifts[date].shift_break,
          shift_punch: shifts[date].shift_punch,
        });
      } else {
        const shift: Shift = {
          id: null,
          employee_id: null,
          date: date,
          shift_shift: [],
          shift_break: [],
          shift_punch: [],
        };
        Vue.set(this.shifts, date, shift);
      }
    });
  }

  @Action({ rawError: true })
  async getAbleShift({ yearmonth, branch_id }: { yearmonth: string; branch_id: number }) {
    this.setIsValid(false);
    const res = await realtime.getAble({ yearmonth, branch_id });
    this.setShifts({ yearmonth: yearmonth, shifts: res.shifts });
    this.setAbles({ yearmonth: yearmonth, ables: res.ables });
    this.setIsValid(true);
  }

  @Mutation
  setShift({ date, shift }: { date: string; shift: Shift }) {
    Vue.set(this.shifts, date, shift);
  }

  @Mutation
  setTmpShift({ shift }: { shift: Shift }) {
    Vue.set(this, "tmp_shift", shift);
  }
  @Mutation
  setTmpAble({ able }: { able: Able }) {
    Vue.set(this, "tmp_able", able);
  }

  @Mutation
  setAbleControl({ able }: { able: Able }) {
    this.able_control = able;
  }

  @Mutation
  setDaysControl({ days }: { days: number[] }) {
    this.days_control = days;
  }

  @Action({ rawError: true })
  public async applyAbleControl({
    yearmonth,
    national_holidays,
  }: {
    yearmonth: string;
    national_holidays: string[];
  }) {
    const date_array = util.month_date_array(yearmonth);
    date_array.forEach(async (date) => {
      if (util.isNationalHoliday(national_holidays, date)) {
        if (this.days_control.indexOf(7) !== -1) {
          Vue.set(this.ables[date], "able_time", util.deep_copy(this.able_control.able_time));
        }
      } else if (this.days_control.indexOf(util.date2day(date)) !== -1) {
        Vue.set(this.ables[date], "able_time", util.deep_copy(this.able_control.able_time));
      }
    });
  }

  @Action({ rawError: true })
  public async applyShiftControl({
    yearmonth,
    national_holidays,
  }: {
    yearmonth: string;
    national_holidays: string[];
  }) {
    const date_array = util.month_date_array(yearmonth);
    date_array.forEach(async (date) => {
      if (util.isNationalHoliday(national_holidays, date)) {
        if (this.days_control.indexOf(7) !== -1) {
          Vue.set(this.shifts[date], "shift_shift", util.deep_copy(this.able_control.able_time));
          Vue.set(
            this.shifts[date],
            "shift_break",
            util.deep_copy(this.able_control.able_break_time),
          );
        }
      } else if (this.days_control.indexOf(util.date2day(date)) !== -1) {
        Vue.set(this.shifts[date], "shift_shift", util.deep_copy(this.able_control.able_time));
        Vue.set(
          this.shifts[date],
          "shift_break",
          util.deep_copy(this.able_control.able_break_time),
        );
      }
    });
  }

  @Action({ rawError: true })
  public updateAbles({
    yearmonth,
    branch_id,
    employee_id,
  }: {
    yearmonth: string;
    branch_id: number;
    employee_id: number;
  }) {
    if (validate(validationAbles(this.ables))) {
      dialog.openConfirmDialog({
        msg: util.$t("able_create_confirm_dialog"),
        cancel_button: util.$t("will_not_submit"),
        exec_button: util.$t("will_submit"),
        func: this.updateAblesProcess,
        is_next_dialog: true,
        args: { yearmonth, branch_id, employee_id },
      });
    }
  }
  @Action({ rawError: true })
  public async updateAblesProcess({
    yearmonth,
    branch_id,
    employee_id,
  }: {
    yearmonth: string;
    branch_id: number;
    employee_id: number;
  }) {
    dialog.openProgressDialog({
      title: util.$t("processing"),
      msg: util.$t("processing_msg"),
      detail: util.$t("waiting_please"),
    });
    await util.post("submit/update_ables", {
      yearmonth,
      branch_id,
      employee_id,
      ables: this.ables,
    });
    router.push({ name: "shift", query: util.passquery["shift"] });
    dialog.openAlertDialog({ msg: util.$t("able_create_done_msg") });
  }

  @Action({ rawError: true })
  public updateShifts({
    yearmonth,
    branch_id,
    employee_id,
    coretime_type,
    coretime_started_at,
    coretime_ended_at,
  }: {
    yearmonth: string;
    branch_id: number;
    employee_id: number;
    coretime_type: number;
    coretime_started_at?: number;
    coretime_ended_at?: number;
  }) {
    var error = validationShifts(
      this.shifts,
      coretime_type,
      coretime_started_at,
      coretime_ended_at,
    );
    if (validate(error.error)) {
      dialog.openConfirmDialog({
        msg: util.$t("submit_regist_shift_confirm"),
        detail: util.$t("submit_regist_shift_confirm_detail"),
        cancel_button: util.$t("will_not_submit"),
        exec_button: util.$t("will_submit"),
        func: this.updateShiftsSoftProcess,
        is_next_dialog: true,
        args: { soft_error: error.soft_error, yearmonth, branch_id, employee_id },
      });
    }
  }
  @Action({ rawError: true })
  public async updateShiftsSoftProcess({
    soft_error,
    yearmonth,
    branch_id,
    employee_id,
  }: {
    soft_error: any;
    yearmonth: string;
    branch_id: number;
    employee_id: number;
  }) {
    if (
      soft_validate({
        error: soft_error,
        func: this.updateShiftsProcess,
        args: { yearmonth, branch_id, employee_id },
        is_next_dialog: true,
      })
    ) {
      this.updateShiftsProcess({ yearmonth, branch_id, employee_id });
    }
  }
  @Action({ rawError: true })
  public async updateShiftsProcess({
    yearmonth,
    branch_id,
    employee_id,
  }: {
    yearmonth: string;
    branch_id: number;
    employee_id: number;
  }) {
    dialog.openProgressDialog({
      title: util.$t("processing"),
      msg: util.$t("processing_msg"),
      detail: util.$t("waiting_please"),
    });
    const res = await util.post("submit/update_shifts", {
      yearmonth,
      branch_id,
      employee_id,
      shifts: this.shifts,
    });
    if (res !== null) {
      router.push({ name: "shift", query: util.passquery["shift"] });
      dialog.openAlertDialog({ msg: util.$t("create_submit_finish") });
    }
  }

  @Action({ rawError: true })
  public createAblePattern({ able_pattern }: { able_pattern: Able_pattern }) {
    if (validate(validationAblePattern(able_pattern))) {
      dialog.openConfirmDialog({
        msg: util.$t("create_submit_pattern_confirm"),
        cancel_button: util.$t("will_not_create"),
        exec_button: util.$t("will_create"),
        func: this.createAblePatternProcess,
        args: { able_pattern },
      });
    }
  }
  @Action({ rawError: true })
  public async createAblePatternProcess({ able_pattern }: { able_pattern }) {
    global.createAblePattern({ able_pattern });
    this.cancelAll();
    await util.post("submit/create_able_pattern", { able_pattern });
    node.disable();
  }

  @Action({ rawError: true })
  public updateAblePattern({
    able_pattern_id,
    able_pattern,
  }: {
    able_pattern_id: number;
    able_pattern;
  }) {
    if (validate(validationAblePattern(able_pattern))) {
      dialog.openConfirmDialog({
        msg: util.$t("update_submit_pattern_confirm"),
        cancel_button: util.$t("will_not_update"),
        exec_button: util.$t("will_update"),
        func: this.updateAblePatternProcess,
        args: { able_pattern_id, able_pattern },
      });
    }
  }
  @Action({ rawError: true })
  public async updateAblePatternProcess({
    able_pattern_id,
    able_pattern,
  }: {
    able_pattern_id: number;
    able_pattern;
  }) {
    global.updateAblePattern({ able_pattern_id, able_pattern });
    this.cancelAll();
    await util.post("submit/update_able_pattern", { able_pattern_id, able_pattern });
    node.disable();
  }

  @Action({ rawError: true })
  public async deleteAblePattern({ able_pattern_id }: { able_pattern_id: number }) {
    dialog.openForceConfirmDialog({
      msg: util.$t("delete_submit_pattern_confirm"),
      detail: util.$t("delete_submit_pattern_confirm_detail"),
      cancel_button: util.$t("will_not_delete"),
      exec_button: util.$t("will_delete"),
      func: this.deleteAblePatternProcess,
      args: { able_pattern_id },
    });
  }
  @Action({ rawError: true })
  public async deleteAblePatternProcess({ able_pattern_id }: { able_pattern_id: number }) {
    global.deleteAblePattern({ able_pattern_id });
    await util.post("submit/delete_able_pattern", { able_pattern_id });
    node.disable();
  }
}

export default getModule(Able_manager);
