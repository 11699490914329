import { defineAsyncComponent } from "vue";
import { readTag } from "@/query/apis/Session";
import { ReadTagResult } from "@/types/Session";
import * as util from "@/util";

let tagData: ReadTagResult | null = null;
export const getTagData = (() => {
  let fetching: Promise<void> | null = null;
  return async (company_id?: number) => {
    if (!tagData) {
      if (!fetching) {
        if (company_id === undefined) {
          company_id = Number(util.getLocalStorage("company_id"));
        }
        fetching = readTag(company_id).then((result) => {
          console.log("readTag() resolved: ", result);
          tagData = result;
        });
      }
      await fetching;
    }
    console.log("return from getTagData", tagData);
    return tagData;
  };
})();

export const hasCustomizeTag = (key: string) => {
  if (tagData) {
    return tagData.tag.includes(key);
  } else {
    return false;
  }
};

export function createCustomized3<T>(
  baseloader: () => T,
  customizeMap: { [tag: string]: () => any },
): Awaited<T> {
  return defineAsyncComponent(createCustomized<T>(baseloader, customizeMap)() as any) as Awaited<T>;
}

export function createCustomized<T>(
  baseloader: () => T,
  customizeMap: { [tag: string]: () => any },
  company_id?: number,
): () => T {
  return (async () => {
    let customized: any = undefined;
    const tags = (await getTagData(company_id)).tag;
    for (const tag of tags) {
      if ((customized = customizeMap[tag])) break;
    }
    return (customized ?? baseloader)() as Awaited<T>;
  }) as () => T;
}
