import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import Vue from "vue";
import Able from "@/typesold/able";
import Shift from "@/typesold/Shift";

export type List = {
  shift: any;
  shift_pattern_id: number;
  able: any;
};
export type Data = {
  employees: { [id: number]: any };
  branches: { [id: number]: any };
  lists: { [employee_id: number]: { [date: string]: List } };
  shift_patterns: { [id: number]: any };
  is_valid?: boolean;
  is_set: boolean;
  fetching?: Promise<void>;
};
export type BranchlyData = {
  [branch: number]: Data;
};
export type MonthlyData = {
  [yearmonth: string]: BranchlyData;
};

@Module({ dynamic: true, store, name: "monthly_list_data", namespaced: true })
class Monthly extends VuexModule {
  data: MonthlyData = {};

  @Mutation
  public set({ yearmonth, branch_id, data }: { yearmonth: string; branch_id: number; data: Data }) {
    Vue.set(this.data[yearmonth], branch_id, {
      ...data,
      is_valid: true,
      is_set: true,
      fetching: null,
    });
  }
  @Mutation
  public disable({ yearmonth, branch_id }: { yearmonth?: string; branch_id: number }) {
    if (yearmonth === undefined) {
      if (branch_id === undefined) {
        if (this.data) {
          Object.keys(this.data).forEach((yearmonth) => {
            Object.keys(this.data[yearmonth]).forEach((branch_id) => {
              this.data[yearmonth][branch_id].is_valid = false;
            });
          });
        }
      } else {
        Object.keys(this.data).forEach((yearmonth) => {
          this.data[yearmonth][branch_id].is_valid = false;
        });
      }
    } else {
      if (branch_id === undefined) {
        if (this.data) {
          Object.keys(this.data).forEach((yearmonth) => {
            if (this.data[yearmonth]) {
              this.data[yearmonth][branch_id].is_valid = false;
            }
          });
        }
      } else {
        this.data[yearmonth][branch_id].is_valid = false;
      }
    }
  }
  @Mutation
  public disable_all() {
    Object.keys(this.data).forEach((yearmonth) => {
      Object.keys(this.data[yearmonth]).forEach((branch_id) => {
        this.data[yearmonth][branch_id].is_valid = false;
      });
    });
  }

  @Mutation
  public setFetching({
    yearmonth,
    branch_id,
    value,
  }: {
    yearmonth: string;
    branch_id: number;
    value: Promise<void>;
  }) {
    this.data[yearmonth][branch_id].fetching = value;
  }

  // actions
  @Action({ rawError: true })
  public async fetch({ yearmonth, branch_id }: { yearmonth: string; branch_id: number }) {
    //ないなら作る
    if (!this.data[yearmonth]) {
      Vue.set(this.data, yearmonth, {});
    }
    if (!this.data[yearmonth][branch_id]) {
      Vue.set(this.data[yearmonth], branch_id, {
        employees: {},
        branches: {},
        lists: {},
        shift_patterns: {},
        is_valid: false,
        is_set: false,
        fetching: null,
      });
    }

    if (this.data[yearmonth][branch_id].fetching) {
      await this.data[yearmonth][branch_id].fetching;
    } else {
      const promise = util.post("shift/read_monthly_list", { yearmonth, branch_id }).then((res) => {
        this.set({ yearmonth, branch_id, data: res.data });
      });
      this.setFetching({ yearmonth, branch_id, value: promise });
      await promise;
    }
  }
}

export default getModule(Monthly);
