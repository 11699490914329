import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import Vue from "vue";
import Shift from "@/typesold/shift";
import Able from "@/typesold/able";
import Shift_memo from "@/typesold/shift_memo";

export type EmployeeHash = {
  [id: number]: string;
};
export type Data = {
  contract_documents: any;
  is_valid?: boolean;
  is_set: boolean;
  fetching?: Promise<void>;
};
export type BranchlyData = {
  [branch_id: number]: Data;
};
export type DocumentTemplatelyData = {
  [document_template_id: number]: BranchlyData;
};

@Module({ dynamic: true, store, name: "document_history", namespaced: true })
class Monthly extends VuexModule {
  data: DocumentTemplatelyData = {};

  @Mutation
  public set({
    document_template_id,
    branch_id,
    data,
  }: {
    document_template_id: number;
    branch_id: number;
    data: Data;
  }) {
    Vue.set(this.data[document_template_id], branch_id, {
      ...data,
      is_valid: true,
      is_set: true,
      fetching: null,
    });
  }

  @Mutation
  public disable({
    document_template_id,
    branch_id,
  }: {
    document_template_id?: number;
    branch_id?: number;
  }) {
    if (branch_id === undefined) {
      if (document_template_id === undefined) {
        Object.keys(this.data).forEach((document_template_id) => {
          if (this.data[document_template_id]) {
            Object.keys(this.data[document_template_id]).forEach((branch_id) => {
              if (this.data[document_template_id] && this.data[document_template_id][branch_id]) {
                this.data[document_template_id][branch_id].is_valid = false;
              }
            });
          }
        });
      } else {
        if (this.data[document_template_id]) {
          Object.keys(this.data[document_template_id]).forEach((branch_id) => {
            if (this.data[document_template_id] && this.data[document_template_id][branch_id]) {
              this.data[document_template_id][branch_id].is_valid = false;
            }
          });
        }
      }
    } else {
      if (document_template_id === undefined) {
        Object.keys(this.data).forEach((document_template_id) => {
          if (this.data[document_template_id] && this.data[document_template_id][branch_id]) {
            this.data[document_template_id][branch_id].is_valid = false;
          }
        });
      } else {
        if (this.data[document_template_id] && this.data[document_template_id][branch_id]) {
          this.data[document_template_id][branch_id].is_valid = false;
        }
      }
    }
  }
  @Mutation
  public disable_all() {
    Object.keys(this.data).forEach((document_template_id) => {
      Object.keys(this.data[document_template_id]).forEach((branch_id) => {
        this.data[document_template_id][branch_id].is_valid = false;
      });
    });
  }
  @Mutation
  public setFetching({
    document_template_id,
    branch_id,
    value,
  }: {
    document_template_id: number;
    branch_id: number;
    value: Promise<void>;
  }) {
    this.data[document_template_id][branch_id].fetching = value;
  }

  // actions
  @Action({ rawError: true })
  public async fetch({
    document_template_id,
    branch_id,
  }: {
    document_template_id: number;
    branch_id: number;
  }) {
    //ないなら作る
    if (!this.data[document_template_id]) {
      Vue.set(this.data, document_template_id, {});
    }
    if (!this.data[document_template_id][branch_id]) {
      Vue.set(this.data[document_template_id], branch_id, {
        contract_documents: [],
        is_valid: false,
        is_set: false,
        fetching: null,
      });
    }

    if (this.data[document_template_id][branch_id].fetching) {
      await this.data[document_template_id][branch_id].fetching;
    } else {
      const promise = util
        .post("document/read_history", { document_template_id, branch_id })
        .then((res) => {
          this.set({ document_template_id, branch_id, data: res.data });
        });
      this.setFetching({ document_template_id, branch_id, value: promise });
      await promise;
    }
  }
}

export default getModule(Monthly);
