const ja = {
  enter_branch: "部署/店舗IDを入力してください",
  enter_name: "名前を入力してください",
  enter_kana: "名前(カナ)を入力してください",
  select_sex: "性別を選択してください",
  enter_birth_date: "生年月日を正しく入力してください",
  enter_mynum_in_12char: "マイナンバーは12桁で入力してください",
  enter_mail: "メールアドレス(ID)を入力してください",
  contain_space_in_mail: "メールアドレス(ID)に空白が含まれています",
  invalid_mail: "使用できないメールアドレス(ID)が入力されています",
  enter_pass: "パスワードを入力してください",
  enter_pass_confirm: "パスワード(確認)を入力してください",
  not_match_pass_confirm: "パスワードが確認と一致しません",

  enter_zip: "郵便番号を入力してください",
  enter_address: "住所を入力してください",
  incorrect_zip: "郵便番号が正しくありません",
  tel_halfwidth_number: "電話番号は半角数字で入力してください",
  mobile_halfwidth_number: "携帯電話番号は半角数字で入力してください",
  select_carfare_type_detail: "通勤手当を使用しているかどうかを選択してください",
  select_carfare_way: "通勤方法を選択してください",
  enter_km: "マイカー使用部分の片道距離を入力してください",
  km_halfwidth_number: "マイカー使用部分の片道距離は半角数字で入力してください",

  enter_employee_bank_name: "金融機関名を入力してください",
  enter_employee_branch_name: "支店名を入力してください",
  enter_employee_bank_type: "口座の種類を選択してください",
  enter_employee_bank_holder: "口座名義を入力してください",
  enter_employee_bank_num_in_7char: "口座番号は7桁で入力してください",
  enter_employee_bank_num: "口座番号を入力してください",
  enter_employee_bank_halfwidth_number: "口座番号は半角数字で入力してください",
  enter_employee_holder_without_kanji: "口座名義は漢字以外で入力してください",
  enter_multi_employee_bank_name: "%{employee_bank}の金融機関名を入力してください",
  enter_multi_employee_branch_name: "%{employee_bank}の支店名を入力してください",
  enter_multi_employee_bank_type: "%{employee_bank}の口座の種類を選択してください",
  enter_multi_employee_bank_holder: "%{employee_bank}の口座名義を入力してください",
  enter_multi_employee_bank_num_in_7char: "%{employee_bank}の口座番号は7桁で入力してください",
  enter_multi_employee_bank_num: "%{employee_bank}の口座番号を入力してください",
  enter_multi_employee_bank_halfwidth_number:
    "%{employee_bank}の口座番号は半角数字で入力してください",
  enter_multi_employee_bank_holder_without_kanji:
    "%{employee_bank}の口座名義は漢字以外で入力してください",
  enter_employee_bank1_limit: "振込口座1の上限金額を入力してください",
  enter_employee_bank1_limit_halfwidth_number: "振込口座1の上限金額は半角数字で入力してください",

  select_tax_type: "税額表区分を選択してください",
  select_is_resident_fixed_tax_reduction: "居住者かどうかを選択してください",
  select_householder_relationship: "世帯主との続柄を選択してください",
  enter_householder_name: "世帯主名を入力してください",
  enter_householder_relationship: "世帯主との続柄を入力してください",
  enter_income: "給与収入を入力してください",
  enter_income_halfwidth: "給与収入は半角数字で入力してください",
  enter_other_income: "給与収入以外の所得金額を入力してください",
  enter_other_income_halfwidth: "給与収入以外の所得金額は半角数字で入力してください",
  select_disability_type: "障害を選択してください",
  select_work_student: "勤労学生を選択してください",
  enter_sdisability_reason: "障害者に該当する事実を入力してください",
  select_single_child: "ひとり親を選択してください",
  select_single_child_widow: "ひとり親・寡婦を選択してください",
  enter_work_student_reason: "学校名と入学年を入力してください",

  enter_spouce_name: "配偶者の名前を入力してください",
  enter_spouce_kana: "配偶者の名前(カナ)を入力してください",
  enter_spouce_birth_date: "配偶者の生年月日を正しく入力してください",
  enter_spouce_mynum_in_12char: "配偶者のマイナンバーは12桁で入力してください",
  enter_spouce_housemate: "配偶者の同居を選択してください",
  enter_spouce_address: "配偶者の住所を入力してください",
  enter_spouce_income: "配偶者の給与収入を入力してください",
  enter_spouce_income_halfwidth: "配偶者の給与収入は半角数字で入力してください",
  enter_spouce_retire_income: "配偶者の退職所得金額を入力してください",
  enter_spouce_retire_income_halfwidth: "配偶者の退職所得金額は半角数字で入力してください",
  enter_spouce_other_income: "配偶者の給与収入以外の所得金額を入力してください",
  enter_spouce_other_income_halfwidth: "配偶者の給与収入以外の所得金額は半角数字で入力してください",
  select_spouce_disability: "配偶者の障害を選択してください",
  select_spouce_special_disability_housemate:
    "配偶者の生計を一にする親族との同居を選択してください",
  select_spouce_is_not_live: "配偶者の非居住者の該当の有無を選択してください",
  enter_spouce_not_live_money: "配偶者の送金額を入力してください",
  enter_spouce_not_live_money_halfwidth: "配偶者の送金額は半角数字で入力してください",
  enter_spouce_disability_reason: "配偶者の障害者に該当する事実を入力してください",

  enter_dependant_name: "扶養親族%{index}の名前を入力してください",
  enter_dependant_kana: "扶養親族%{index}の名前(カナ)を入力してください",
  enter_dependant_birth_date: "扶養親族%{index}の生年月日を正しく入力してください",
  enter_dependant_mynum_in_12char: "扶養親族%{index}のマイナンバーは12桁で入力してください",
  enter_dependant_relationship: "扶養親族%{index}の続柄を選択してください",
  enter_dependant_housemate: "扶養親族%{index}の同居を選択してください",
  enter_dependant_old_spouce_housemate: "扶養親族%{index}の配偶者との同居を選択してください",
  enter_dependant_address: "扶養親族%{index}の住所を入力してください",
  enter_dependant_income: "扶養親族%{index}の給与収入を入力してください",
  enter_dependant_income_halfwidth: "扶養親族%{index}の給与収入は半角数字で入力してください",
  enter_dependant_retire_income: "扶養親族%{index}の退職所得金額を入力してください",
  enter_dependant_retire_income_halfwidth:
    "扶養親族%{index}の退職所得金額は半角数字で入力してください",
  enter_dependant_other_income: "扶養親族%{index}の給与収入以外の所得金額を入力してください",
  enter_dependant_other_income_halfwidth:
    "扶養親族%{index}の給与収入以外の所得金額は半角数字で入力してください",
  select_dependant_disability: "扶養親族%{index}の障害を選択してください",
  select_dependant_child_allowance: "扶養親族%{index}の児童扶養手当の受給を選択してください",
  select_dependant_is_not_live: "扶養親族%{index}の非居住者の該当の有無を選択してください",
  enter_dependant_not_live_money: "扶養親族%{index}の送金額を入力してください",
  enter_dependant_not_live_money_halfwidth: "扶養親族%{index}の送金額は半角数字で入力してください",
  enter_dependant_disability_reason: "扶養親族%{index}の障害者に該当する事実を入力してください",

  contact_enter_content: "内容が入力されていません。",

  demand_holiday_enter_date: "取得希望日を正しく入力してください",
  demand_holiday_kind: "種類を選択してください",
  demand_holiday_create_confirm: "休暇申請を送信してよろしいですか？",
  demand_holiday_send: "申請しない",
  demand_holiday_no_send: "申請する",
  demand_holiday_finish_create: "休暇申請を送信しました。",

  demand_punch_page: "打刻修正申請",
  demand_punch_order_delete: "(削除申請中)",
  demand_punch_shift_punch: "シフト・勤怠",
  demand_punch_ordering: "(申請中)",
  demand_punch_select_branch: "店舗を選択してください",
  demand_punch_enter_atleast_one_kind: "少なくとも一種類を入力してください。",
  demand_punch_only_punch_out: "%{row}行目の勤怠が退勤時刻のみ入力されています",
  demand_punch_only_punch_in: "%{row}行目の勤怠が出勤時刻のみ入力されています",
  demand_punch_punch_time_contradiction: "%{row}行目の勤怠の出勤時刻が退勤時刻以後となっています",
  demand_punch_punch_time_duplication: "%{row1}行目の勤怠が%{row2}行目の勤怠と重複しています。",
  demand_punch_punch_before_24: "%{row}行目の出勤時刻は24時より前である必要があります",
  demand_punch_punch_after_0: "%{row}行目の退勤時刻は0時より後である必要があります",
  demand_punch_punch_over_24: "%{row}行目の勤怠時間が24時間を超えています",
  demand_punch_only_break_start: "%{row}行目の休憩が終了時刻のみ入力されています",
  demand_punch_only_break_end: "%{row}行目の休憩が開始時刻のみ入力されています",
  demand_punch_break_time_contradiction: "%{row}行目の休憩の開始時刻が終了時刻以後となっています。",
  demand_punch_break_time_duplication: "%{row1}行目の休憩が%{row2}行目の休憩と重複しています。",

  demand_overtime_enter_date: "取得希望日を正しく入力してください",
  demand_overtime_after_today: "本日以降の申請のみ認められています。",
  demand_overtime_select_branch: "申請先店舗を選択してください",
  demand_overtime_kind: "種類を選択してください",
  demand_overtime_select_shift: "対象シフト時間を選択してください",
  demand_overtime_invalid_started_at: "シフトが正しく選択されていません",
  demand_overtime_enter_ended_at: "終了時間を入力してください",
  demand_overtime_after_shift_end: "終了時間はシフトの終了時間より後の時間を入力してください",
  demand_overtime_before_shift_start: "開始時間はシフトの開始時間より前の時間を入力してください",
  demand_overtime_time_contradiction: "開始時間は終了時間より前の時間を入力してください",
  demand_overtime_enter_start: "開始時間を入力してください",

  contract_document_enter_auth: "署名を入力してください。",
  punch_browser_not_supported_gps: "ブラウザが位置情報取得に対応していません",
  punch_not_admitted_gps: "位置情報の利用が許可されていません",
  punch_cannot_get_location: "デバイスの位置が判定できません",
  punch_timeout: "タイムアウトしました",
  punch_unknown_error: "予期せぬエラーが発生しました。",
  punch_already_break_start: "%{name}さんは既に休憩中です。",
  punch_no_break_start: "%{name}さんは休憩中ではありません。",
  punch_no_punch_in: "%{name}さんの出勤記録がありません",
  cannot_punch_out_during_break: "%{name}さんは休憩中のため退勤できません。",
  punch_enter_mail_pass: "「メールアドレス/社員コード」と「パスワード」を入力してください。",
  punch_wrong_mail_code: "メールアドレス/社員IDかパスワードが違います。",
  punch_noregist_mypc: "MyPCの登録がされていないか、期限が切れています。",
  punch_already_punch_in: "%{name}さんはすでに出勤登録されています",

  reset_password_enter_mail: "メールアドレスを入力してください",

  spouce_retire_income_more_than_other_income:
    "退職所得の金額は給与収入以外の所得金額以下の金額を入力してください",
  dependant_retire_income_more_than_other_income:
    "退職所得の金額は給与収入以外の所得金額以下の金額を入力してください",
};

const en = {
  enter_branch: "Please enter section code.",
  enter_name: "Please enter your name.",
  enter_kana: "Please enter your name pronounciation(furigana).",
  select_sex: "Please select your sex.",
  enter_birth_date: "Please enter your date of birth correctly.",
  enter_mail: "Please enter your email address.",
  contain_space_in_mail: "Your email address contains spaces.",
  invalid_mail: "This email address is already in use.",
  enter_mynum_in_12char: "Individual number must be 12 digits.",
  enter_pass: "Please enter your password.",
  enter_pass_confirm: "Please enter your password (Confirmation).",
  not_match_pass_confirm: "Password does not match the confirmation.",
  enter_zip: "Please enter the ZIP code.",
  enter_address: "Please enter your address.",
  incorrect_zip: "ZIP code is incorrect.",
  tel_halfwidth_number: "Please enter your home phone number in half-width numbers.",
  mobile_halfwidth_number: "Please enter your mobile phone number in half-width numbers.",
  select_carfare_type_detail:
    "Please select whether you use public transportation or your private car for commuting.",
  select_carfare_way: "Please select what you are using for commuting.",
  enter_km: "Please enter the one-way distance of the part of using private car.",
  km_halfwidth_number:
    "Please use half-width numbers for the one-way distance of the part of using private car.",
  enter_employee_bank_name: "Please enter the financial institution name.",
  enter_employee_branch_name: "Please enter the branch name.",
  enter_employee_bank_type: "Please enter the bank account type.",
  enter_employee_bank_holder: "Please enter the account name.",
  enter_employee_bank_num_in_7char: "Account number must be 7 digits.",
  enter_employee_bank_num: "Please enter your account number.",
  enter_employee_bank_halfwidth_number: "Please enter the account number in half-width numbers.",
  enter_employee_holder_without_kanji: "Please enter the account name in non-Kanji characters.",
  enter_multi_employee_bank_name:
    "Please enter the financial institution name of %{employee_bank}.",
  enter_multi_employee_branch_name: "Please enter the branch name of %{employee_bank}.",
  enter_multi_employee_bank_type: "Please enter the bank account type of %{employee_bank}.",
  enter_multi_employee_bank_holder: "Please enter the account name of %{employee_bank}.",
  enter_multi_employee_bank_num_in_7char: "Account number of %{employee_bank} should be 7 digits.",
  enter_multi_employee_bank_num: "Please enter the account number of %{employee_bank}.",
  enter_multi_employee_bank_halfwidth_number:
    "Please enter the account number of %{employee_bank} in half-width numbers.",
  enter_multi_employee_bank_holder_without_kanji:
    "Please enter the account name of %{employee_bank} in non-Kanji characters.",
  enter_employee_bank1_limit: "Please enter the maximum amount of transfer account 1.",
  enter_employee_bank1_limit_halfwidth_number:
    "Please use half-width numbers for the maximum amount of transfer account 1.",

  select_tax_type: "Please select whether you are mainly working elsewhere or not.",
  select_householder_relationship: "Please select your relationship with the head of household.",
  enter_householder_name: "Please enter the name of the head of household.",
  enter_householder_relationship: "Please enter the name of relationship to the head of household.",
  enter_income: "Please enter your salary income.",
  enter_income_halfwidth: "Please enter the salary income in half-width numbers.",
  enter_other_income: "Please enter your income amount other than salary income.",
  enter_other_income_halfwidth:
    "Please use half-width numbers for your income amount other than salary income.",
  select_disability_type: "Please select your disability.",
  select_work_student: "Please select whether you are a working student.",
  enter_sdisability_reason: "Please enter the details of your disability.",
  select_single_child: "Please select whether you are a single parent.",
  select_single_child_widow: "Please select whether you are a single parent or a widow.",
  enter_work_student_reason: "Please ",
  enter_spouce_name: "Please enter the name of your spouse.",
  enter_spouce_kana: "Please enter the name pronounciation of your spouse.",
  enter_spouce_birth_date: "Please enter the birth of date of your spouse.",
  enter_spouce_mynum_in_12char: "Individual number must be 12 digits.",
  enter_spouce_housemate: "Please select whether you are living with your spouse.",
  enter_spouce_address: "Please enter the spouse address.",
  enter_spouce_income: "Please enter the salary income of your spouse.",
  enter_spouce_income_halfwidth:
    "Please use half-width numbers for the salary income of your spouse.",
  enter_spouce_other_income:
    "Please enter the income amount other than salary income of your spouse.",
  enter_spouce_other_income_halfwidth:
    "Please use half-width numbers for the income amount other than salary income of your spouse.",
  select_spouce_disability: "Please select disability of your spouse.",
  select_spouce_special_disability_housemate:
    "Please select whether your spouse lives with your relatives who live in the same household as yours. ",
  select_spouce_is_not_live: "Please select whether your spouse is non-resident.",
  enter_spouce_not_live_money: "Please enter the amount of remittance to spouse.",
  enter_spouce_not_live_money_halfwidth:
    "Please enter the amount of remittance to spouse in half-width numbers.",
  enter_spouce_disability_reason: "Please enter the details your spouse's disability.",
  enter_dependant_name: "Please enter the name of dependent relative %{index}.",
  enter_dependant_kana: "Please enter the name pronounciation of dependent relatieve %{index}.",
  enter_dependant_birth_date: "Please enter the birth of date of dependent relative %{index}.",
  enter_dependant_mynum_in_12char:
    "Individual number of dependent relative %{index} must be 12 digits.",
  enter_dependant_relationship: "Please select the relationship to dependent relative %{index}.",
  enter_dependant_housemate: "Please select whether dependent relative %{index} lives with you.",
  enter_dependant_old_spouce_housemate:
    "Please select whether dependent relative %{index} lives with your spouse.",
  enter_dependant_address: "Please enter the address of dependent relative %{index}.",
  enter_dependant_income: "Please enter the salary income of dependent relative %{index}.",
  enter_dependant_income_halfwidth:
    "Please use half-width numbers for the salary income of dependent relative %{index}.",
  enter_dependant_other_income:
    "Please enter the income amount other than salary income of dependent relative %{index}.",
  enter_dependant_other_income_halfwidth:
    "Please use half-width number for the income amount other than salary income of dependent relative %{index}.",
  select_dependant_disability: "Please select disability of dependent relative %{index}.",
  select_dependant_child_allowance:
    "Please select whether you receive childrearing allowance for dependent relative %{index}.",
  select_dependant_is_not_live:
    "Please select whether dependent relative %{index} is non-resident.",
  enter_dependant_not_live_money:
    "Please enter the amount of remittance to dependent relative %{index}.",
  enter_dependant_not_live_money_halfwidth:
    "Please use half-width numbers for the amount of remittance to dependent relative %{index}.",
  enter_dependant_disability_reason:
    "Please enter the details of disability of dependent relative %{index}.",
  reset_password_enter_mail: "Please enter the email address.",
  wrong_company_code: "The company ID is incorrect.",
  app_notification: "Please allow notification.",
  submit_enter_pattern_name: "Please fill in the name of pattern.",
  submit_enter_pattern_shift_time: "Please write the available time.",
  submit_pattern_no_shift_start: "The start time of the shift in line %{row} is missing.",
  submit_pattern_no_shift_end: "The end time of the shift in line %{row} is missing.",
  submit_pattern_duplicate_shift: "Shift on line %{row1} overlaps shift on line %{row2}.",
  submit_pattern_shift_time_contradiction:
    "The start time in line %{row} of available time is after the end time ",
  submit_pattern_no_break_start: "Please enter the start time of break time in line %{row}.",
  submit_pattern_no_break_end: "Please enter the end time of break time in line %{row}.",
  submit_pattern_break_time_contradiction:
    "The start time in line %{row} of break time is after the end time.",
  submit_pattern_duplicate_break: "Break time in line %{row1} overlaps break time in line %{row2}.",
  punch_enter_mail_pass: "Please enter the email address/employee code and password.",
  punch_wrong_mail_code: "Email address/employee ID or password is wrong.",
  punch_noregist_mypc: "My PC is not registered or expired.",
  punch_already_punch_in: '"Clock-in" of %{name} has already done before.',
  punch_no_punch_in: 'There is no "Clock-in" record of %{name}.',
  cannot_punch_out_during_break: '%{name} cannot "Clock-out" during the break time.',
  punch_already_break_start: '"Break-in" of %{name} has already done before.',
  punch_no_break_start: "%{name} is not taking the break now.",
  punch_unknown_error: "An unexpected error has occurred.",
  punch_browser_not_supported_gps: "This browser is not supported to use the GPS.",
  punch_not_admitted_gps: "GPS is not allowed.",
  punch_cannot_get_location: "Cannot find the location.",
  punch_timeout: "Connection timed out",
  contact_enter_content: "Please input your contact details.",
  demand_holiday_enter_date: "Please enter the request date correctly.",
  demand_holiday_kind: "Please select the type.",
  demand_punch_select_branch: "Please select the section / branch.",
  demand_punch_enter_atleast_one_kind: "Please enter at least one column pair.",
  demand_punch_only_punch_out: "Attendance on line %{row} is entered only for leaving time.",
  demand_punch_only_punch_in:
    "Attendance on line %{row} is entered only for the time of attendance.",
  demand_punch_punch_time_contradiction: "Attendance time in line %{row} is after clock-out time.",
  demand_punch_punch_time_duplication:
    "Attendance on line %{row1} overlaps attendance on line %{row2}.",
  demand_punch_punch_before_24: "The time of attendance in line %{row} must be before 24:00.",
  demand_punch_punch_after_0: "The leaving time in line %{row} must be after 0:00.",
  demand_punch_punch_over_24: "Attendance time on line %{row} exceeds 24 hours.",
  demand_punch_only_break_start: "The break in line %{row} has only the start time entered.",
  demand_punch_only_break_end: "Only the end time has been entered for the break on line %{row}.",
  demand_punch_break_time_contradiction:
    "The start time of the break in line %{row} is after the end time.",
  demand_punch_break_time_duplication:
    "The break in line %{row1} overlaps the break in line %{row2}.",

  demand_overtime_enter_date: "Please enter your desired date correctly.",
  demand_overtime_after_today: "You can apply only for today or for a future date.",
  demand_overtime_select_branch: "Please select the section / branch you apply for.",
  demand_overtime_kind: "Please select the type.",
  demand_overtime_select_shift: "Please select the applicable shift hours.",
  demand_overtime_invalid_started_at: "Shift is not selected correctly.",
  demand_overtime_enter_ended_at: "Please enter the end time.",
  demand_overtime_after_shift_end: "End time must be after the end time of the shift.",
  demand_overtime_before_shift_start: "Start time must be before the start of the shift.",
  demand_overtime_time_contradiction: "Start time must be before the end time.",
  demand_overtime_enter_start: "Please enter the start time.",
  contract_document_enter_auth: "Please enter your name.",
};

export default {
  ja: ja,
  en: {
    ...ja,
    ...en,
  },
};
