import i18n from "@/plugins/i18n";
import validation from "@/library/validation";
import * as util from "@/util";

const isset = (val: string) => {
  if (validation.isset(val)) {
    return val;
  } else {
    return i18n.t("no_regist");
  }
};
const isinput = (val: string) => {
  if (validation.isset(val)) {
    return val;
  } else {
    return i18n.t("no_input");
  }
};
const isno = (val: string) => {
  if (validation.isset(val)) {
    return val;
  } else {
    return i18n.t("there_isnt");
  }
};
const ifset = (val: string) => {
  if (validation.isset(val)) {
    return i18n.t("already_regist");
  } else {
    return i18n.t("no_regist");
  }
};
const time = (val: number) => {
  if (validation.isset(val)) {
    if (val >= 0) {
      return ("0" + Math.floor(val / 60)).slice(-2) + ":" + ("0" + (val % 60)).slice(-2);
    } else {
      val += 1440;
      return "前" + ("0" + Math.floor(val / 60)).slice(-2) + ":" + ("0" + (val % 60)).slice(-2);
    }
  } else {
    return "";
  }
};
const day = (val: number) => {
  if (validation.isset(val)) {
    return util.$t("day_format", { day: String(val) });
  } else {
    return "";
  }
};
const time_hour_minute = (val: number) => {
  if (validation.isset(val)) {
    if (val >= 60) {
      return util.$t("timespan", {
        hour: String(("0" + Math.floor(val / 60)).slice(-2)),
        min: String(("0" + (val % 60)).slice(-2)),
      });
    } else {
      return util.$t("timespanmin", { min: String(val % 60) });
    }
  } else {
    return "";
  }
};
const time_span = (val: number, is_just_minute = true) => {
  if (validation.isset(val)) {
    if (val >= 60) {
      if (!is_just_minute && val % 60 == 0) {
        return util.$t("timespanhour", {
          hour: String(Math.floor(val / 60)),
        });
      } else {
        return util.$t("timespan", {
          hour: String(Math.floor(val / 60)),
          min: String(("0" + (val % 60)).slice(-2)),
        });
      }
    } else {
      return util.$t("timespanmin", { min: String(val % 60) });
    }
  } else {
    return "";
  }
};
const date = (val: string) => {
  if (validation.isset(val)) {
    if (val == "0000-00-00") {
      return "無効な年月日";
    } else {
      var tmp = val.split("-");
      if (tmp.length == 3) {
        return util.$t("date_format", { year: tmp[0], month: tmp[1], day: tmp[2] });
      } else {
        return "無効な年月日";
      }
    }
  } else {
    return "";
  }
};

const datetime_date = (val: string) => {
  var tmp = val.split(" ");
  tmp = tmp[0].split("-");
  if (tmp.length == 3) {
    return util.$t("date_format", { year: tmp[0], month: tmp[1], day: tmp[2] });
  } else {
    return "無効な年月日";
  }
};

const yearmonth = (val: string) => {
  if (validation.isset(val)) {
    if (val == "0000-00-00") {
      return "無効な年月日";
    } else {
      var tmp = val.split("-");
      if (tmp.length >= 2) {
        return util.$t("yearmonth", { year: tmp[0], month: tmp[1] });
      } else {
        return "無効な年月日";
      }
    }
  } else {
    return "";
  }
};

const monthday = (val: string) => {
  if (validation.isset(val)) {
    if (val == "0000-00-00") {
      return "無効な年月日";
    } else {
      var tmp = val.split("-");
      if (tmp.length == 3) {
        return util.$t("month_day", { month: tmp[1], day: tmp[2] });
      } else {
        return "無効な年月日";
      }
    }
  } else {
    return "";
  }
};
const week = (val: string) => {
  if (validation.isset(val)) {
    const dateobj = new Date(val);
    const week = dateobj.getDay();
    switch (week) {
      case 0:
        return util.$t("sunday");
      case 1:
        return util.$t("monday");
      case 2:
        return util.$t("tuesday");
      case 3:
        return util.$t("wednesday");
      case 4:
        return util.$t("thursday");
      case 5:
        return util.$t("friday");
      case 6:
        return util.$t("saturday");
    }
  } else {
    return "";
  }
};
const nullauto = (val: any) => {
  if (validation.isset(val)) {
    return val;
  } else {
    return "自動計算";
  }
};

const number_format = (val: any) => {
  if (val == "" || val == undefined || Number.isNaN(val)) {
    return 0;
  } else {
    return val.toString().replace(/([0-9]+?)(?=(?:[0-9]{3})+$)/g, "$1,");
  }
};

const isnt_set_manual = (val: string) => {
  if (validation.isset(val)) {
    return val;
  } else {
    return i18n.t("manual");
  }
};
const zero = (val: number) => {
  if (validation.isset(val)) {
    return val;
  } else {
    return "0";
  }
};

const unix2dateobj = (val: number) => {
  return new Date(val * 1000);
};

const formatDatetime = (date, format: string) => {
  format = format.replace(/yyyy/g, date.getFullYear());
  format = format.replace(/mm/g, `0${date.getMonth() + 1}`.slice(-2));
  format = format.replace(/dd/g, `0${date.getDate()}`.slice(-2));
  format = format.replace(/hh/g, `0${date.getHours()}`.slice(-2));
  format = format.replace(/ii/g, `0${date.getMinutes()}`.slice(-2));
  format = format.replace(/ss/g, `0${date.getSeconds()}`.slice(-2));
  return format;
};

export default {
  date,
  datetime_date,
  isset,
  isno,
  ifset,
  day,
  week,
  time,
  time_hour_minute,
  time_span,
  nullauto,
  yearmonth,
  monthday,
  number_format,
  isnt_set_manual,
  isinput,
  zero,
  unix2dateobj,
  formatDatetime,
};
