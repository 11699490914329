import * as vali from "@/validations/validationUtil";

//validationは、OKならTrue, それ以外なら警告文を配列に入れる。
export function validationWizardCompany(company: any) {
  var error = [];

  //必須項目
  error.push(company.close_day !== null || '締め日を選択してください');
  error.push(company.pay_month !== null || '支払月を選択してください');
  error.push(company.pay_day !== null || '支払日を選択してください');
  error.push(company.levy_month !== null || '社会保険料徴収時期を選択してください');

  return error;
}
export default function validationCompany(self, args) {
  var error = [];

  //必須項目
  error.push(args.company.name !== null || '会社名(支払者名)を入力してください');
  error.push(args.company.kana !== null || '会社名カナ(支払者名カナ)を入力してください');
  error.push(args.company.staff_id !== null || '担当者を選択してください');
  error.push(args.company.zip !== null || '郵便番号を入力してください');
  error.push(args.company.zip === null || vali.is_zip(args.company.zip) || '郵便番号が正しくありません');
  error.push(args.company.address !== null || '住所を入力してください');
  error.push(args.company.tel !== null || '電話番号を入力してください');
  error.push(args.company.tel === null || vali.is_plus_number(args.company.tel) || '電話番号は半角数字で入力してください');
  error.push(args.company.fax === null || vali.is_plus_number(args.company.fax) || 'FAX番号は半角数字で入力してください');

  error.push(args.company.mynum === null || args.company.mynum.length >= 12 || 'マイナンバーは12桁以上で入力してください');
  error.push(args.company.mynum === null || vali.is_number_hyphen(args.company.mynum) || 'マイナンバーは半角数字で入力してください');
  error.push(args.company.seiri_num === null || vali.is_equal(args.company.seiri_num, 8) || '整理番号は8桁で入力してください');
  error.push(args.company.seiri_num === null || vali.is_number_hyphen(args.company.seiri_num) || '整理番号は半角数字で入力してください');

  error.push(args.company.office_num === null || vali.is_number_hyphen(args.company.office_num) || '事業所番号は半角数字で入力してください');
  

  if (args.company.is_bank == 1) {
    error.push(args.company.bank_id !== null || '金融機関名を入力してください');
    error.push(args.company.branch_id !== null || '支店名を入力してください');
    error.push(args.company.bank_type !== null || '口座の種類を選択してください');
    error.push(args.company.bank_num !== null || '口座番号を入力してください');
    error.push(args.company.bank_holder !== null || '口座名義を入力してください');

    error.push(args.company.bank_num === null || vali.is_equal(args.company.bank_num, 7) || '口座番号は7桁で入力してください');
    error.push(args.company.bank_num === null || vali.is_number(args.company.bank_num) || '口座番号は半角数字で入力してください');
    error.push(args.company.bank_holder === null || !vali.is_kanji(args.company.bank_holder) || '口座名義は漢字以外で入力してください');
    error.push(args.company.biz_code === null || vali.is_number(args.company.biz_code) || '振込依頼人コードは半角数字で入力してください');

    error.push(args.company.transfer_type !== null || '業務種別を選択してください');
  }
  
  return error;
}

export function validationCompanyConfig(company) {
  var error = [];

  error.push(company.over_rate !== null || '法定外残業の割増賃金割合を入力してください');
  error.push(company.over_rate === null || vali.is_number(company.over_rate) || '法定外残業の割増賃金割合は半角数字で入力してください');
  error.push(company.pre_over_rate !== null || '法定内残業の割増賃金割合を入力してください');
  error.push(company.pre_over_rate === null || vali.is_number(company.pre_over_rate) || '法定内残業の割増賃金割合は半角数字で入力してください');
  error.push(company.sixty_over_rate !== null || '60時間超法定外残業の割増賃金割合を入力してください');
  error.push(company.sixty_over_rate === null || vali.is_number(company.sixty_over_rate) || '60時間超法定外残業の割増賃金割合は半角数字で入力してください');
  error.push(company.out_hol_rate !== null || '法定外休日の割増賃金割合を入力してください');
  error.push(company.out_hol_rate === null || vali.is_number(company.out_hol_rate) || '法定外休日の割増賃金割合は半角数字で入力してください');
  error.push(company.law_hol_rate !== null || '法定休日の割増賃金割合を入力してください');
  error.push(company.law_hol_rate === null || vali.is_number(company.law_hol_rate) || '法定休日の割増賃金割合は半角数字で入力してください');
  error.push(company.mid_rate !== null || '深夜の割増賃金割合を入力してください');
  error.push(company.mid_rate === null || vali.is_number(company.mid_rate) || '深夜の割増賃金割合は半角数字で入力してください');

  return error;
}