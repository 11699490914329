import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import Vue from "vue";
import Bonus_info from "@/typesold/Bonus_info";

export type BranchData = {
  id: number;
  name: string;
};

export type BranchObject = {
  [id: number]: string;
};

export type Data = {
  company: any;
  bonus_info: Bonus_info;
  prev_bonus_info_id: number | null;
  next_bonus_info_id: number | null;
  saved_bonus_allowances;
  saved_bonus_deductions;
  saved_bonus_other_allowances;
  is_valid?: boolean;
  is_set: boolean;
  fetching?: Promise<void>;
};

export type MonthlyData = {
  [bonus_info_id: number]: Data;
};

@Module({ dynamic: true, store, name: "bonus_each_global_data", namespaced: true })
class BonusEachGlobal extends VuexModule {
  data: MonthlyData = {};

  latest_bonus_info_id: null | number = null;
  latest_fetching: Promise<void> = null;

  @Mutation
  public set({ bonus_info_id, data, auth }: { bonus_info_id: number; data: Data; auth: number }) {
    Vue.set(this.data, data.bonus_info.id, {
      ...data,
      is_valid: true,
      is_set: true,
      fetching: null,
    });
    this.latest_fetching = null;
    if (bonus_info_id == 0) this.latest_bonus_info_id = data.bonus_info.id;
  }
  @Mutation
  public disable({ bonus_info_id }: { bonus_info_id?: number }) {
    if (bonus_info_id === undefined) {
      this.latest_bonus_info_id = null;
      this.latest_fetching = null;
      Object.keys(this.data).forEach((bonus_info_id) => {
        this.data[bonus_info_id].is_valid = false;
        if (Number(bonus_info_id) == this.latest_bonus_info_id) {
          this.latest_bonus_info_id = null;
          this.latest_fetching = null;
        }
      });
    } else {
      if (this.data[bonus_info_id]) {
        this.data[bonus_info_id].is_valid = false;
      }
      if (bonus_info_id == this.latest_bonus_info_id) {
        this.latest_bonus_info_id = null;
        this.latest_fetching = null;
      }
    }
  }
  @Mutation
  public disable_all() {
    this.latest_bonus_info_id = null;
    this.latest_fetching = null;
    Object.keys(this.data).forEach((bonus_info_id) => {
      this.data[bonus_info_id].is_valid = false;
    });
  }

  @Mutation
  public setLatestBonusInfoId(value) {
    Vue.set(this, "latest_bonus_info_id", value);
  }
  @Mutation
  public setFetching({ bonus_info_id, value }: { bonus_info_id: number; value: Promise<void> }) {
    this.data[bonus_info_id].fetching = value;
  }
  @Mutation
  public setLastFetching({ value }: { value: Promise<void> }) {
    this.latest_fetching = value;
  }

  // actions
  @Action({ rawError: true })
  public async fetch({ bonus_info_id, auth }: { bonus_info_id: number; auth: number }) {
    //ないなら作る
    if (!this.data[bonus_info_id] && bonus_info_id != 0) {
      Vue.set(this.data, bonus_info_id, {
        company: {},
        bonus_info: {},
        prev_bonus_info_id: null,
        next_bonus_info_id: null,
        saved_bonus_allowances: [],
        saved_bonus_deductions: [],
        saved_bonus_other_allowances: [],
        is_valid: false,
        is_set: false,
        fetching: null,
      });
    }

    if (bonus_info_id == 0 && this.latest_fetching) {
      await this.latest_fetching;
    } else if (bonus_info_id != 0 && this.data[bonus_info_id].fetching) {
      await this.data[bonus_info_id].fetching;
    } else {
      const promise = util.post("bonus_each/read_global", { bonus_info_id }).then((res) => {
        if (res.data.bonus_info !== null) this.set({ bonus_info_id, auth, data: res.data });
        else this.setLatestBonusInfoId(0);
        this.setLastFetching({ value: null });
      });
      if (bonus_info_id == 0) {
        this.setLastFetching({ value: promise });
      } else {
        this.setFetching({ bonus_info_id, value: promise });
      }
      await promise;
    }
  }
}

export default getModule(BonusEachGlobal);
