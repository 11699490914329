
import { Component, Mixins } from 'vue-property-decorator';
import mixinPayment from '@/mixins/mixinPayment';
import header from '@/vuex/header';
import UtilPopup from "@/components/util/Popup.vue";
import CommonTextarea from "@/components/common/Textarea.vue";

@Component({
  components: {
    UtilPopup,
    CommonTextarea,
  }
})
export default class Main extends Mixins(mixinPayment) {
  content = null;

  get is_display() {
    return header.is_open_support_dialog;
  }
  set is_display(val: boolean) {
    header.setIsOpenSupportDialog(val);
  }

  get destination() {
    if (this.s_auth == 2) {
      return this.$t('contact_arrow_support');
    } else if (this.s_auth == 1) {
      return this.$t('contact_company_manager');
    } else if (this.s_auth == 0) {
      return this.$t('contact_admin');
    }
  }

  async send_support_mail() {
    await header.sendSupportMail({content: this.content, auth: this.s_auth});
    this.content = null;
  }
}
