import VuexManagerControl from "@/vuex/VuexManagerControl";
import {validationNotification} from "@/validations/notification";

export default class Manager extends VuexManagerControl {
  create_descriptor = {
    create_notification: {
      url: 'notification/create_notification',
      name: '通知',
      validate: validationNotification,
      dialog: {},
      processing_dialog: {},
      finish_dialog: {},
      close_dialog: ['notification'],
    },
    exec_notification: {
      url: 'routine/test_notification',
      name: '通知発行',
      dialog: {title: '通知を発行します。', msg: '5分単位に切り捨てられて通知が発行します。<br>ex) 現在が15:32なら、15:30の通知が発行されます。'},
      processing_dialog: {},
      finish_dialog: {title: '発行しました', msg: '通知を発行しました。'},
    }
  };

  delete_descriptor = {
    delete_notification: {
      url: 'notification/delete_notification',
      name: '通知',
      dialog: {msg: '通知を削除してよろしいですか？', detail: 'すでに送信済みのメール・通知はキャンセルされません。'},
      processing_dialog: {},
      finish_dialog: {},
    }
  }

}