
import { Component, Vue, Emit, Prop, Watch, Mixins } from 'vue-property-decorator';
import dialog from "@/vuex/dialog";

@Component({
  components: {
  }
})
export default class Check extends Vue {
  @Prop() value: any;
  @Prop({default: false}) multi: boolean;
  @Prop({default: 'null'}) this_value: any;
  @Prop({default: ''}) label: string;
  @Prop({default: ''}) after_label: string;
  @Prop({default: false}) disabled: boolean;
  @Prop({default: 'normal'}) background_color: string;
  @Prop({default: 'normal'}) border_color: string;
  @Prop({default: true}) isvalid: boolean | boolean[];
  @Prop() disable_reason: any;
  

  once_input: boolean = false;//lazy
  

  get checked_value() {
    if (this.multi && typeof this.value !== 'boolean') {
      return this.value.indexOf(this.this_value) !== -1;
    } else {
      return this.value;
    }
  }
  set checked_value(val) {
    this.change_checked_value(val);
    this.once_input = true;
  }

  get use_isvalid() {
    if (Array.isArray(this.isvalid)) {
      var isvalid = true;
      this.isvalid.forEach(now => {
        if (!now) {
          isvalid = false;
        }
      });
      return isvalid;
    } else {
      return this.isvalid;
    }
  }

  disable_error() {
    var str = [];

    if (Array.isArray(this.isvalid)) {
      this.isvalid.forEach((now, i) => {
        if (!now) {
          str = str.concat(this.disable_reason[i]);
        }
      });
    } else {
      str = this.disable_reason;
    }
    dialog.openErrorDialog({error: str});
  }

  @Emit('input')
  change_checked_value(val) {
    if (this.multi && Array.isArray(this.value)) {
      if (val) {
        var tmp = this.value;
        tmp.push(this.this_value);
        return tmp;
      } else {
        return this.value.filter(value => value != this.this_value);
      }
    } else {
      return val;
    }
  }
}
