
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import utilMixins from '@/mixins';
import header from "@/vuex/header";
import dialog from "@/vuex/dialog";
import HeaderNormalLogo from "@/components/header/normal/Logo.vue";
import HeaderNormalNotification from "@/components/header/normal/Notification.vue";
import HeaderNormalNews from "@/components/header/normal/News.vue";
import HeaderNormalTodo from "@/components/header/normal/Todo.vue";
import HeaderNormalMenu from "@/components/header/normal/Menu.vue";
import HeaderNormalPopupnews from "@/components/header/normal/Popupnews.vue";
import HeaderNormalStart from "@/components/header/normal/Start.vue";
import HeaderNormalEnd from "@/components/header/normal/End.vue";
import HeaderNormalCredit from "@/components/header/normal/Credit.vue";

@Component({
  components: {
    HeaderNormalLogo,
    HeaderNormalNotification,
    HeaderNormalNews,
    HeaderNormalTodo,
    HeaderNormalMenu,
    HeaderNormalPopupnews,
    HeaderNormalStart,
    HeaderNormalEnd,
    HeaderNormalCredit,
  }
})
export default class Normal extends Mixins(utilMixins) {
}
