
import { Component, Vue, Emit, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';

@Component({
  components: {
  }
})
export default class Pager extends Vue {
  @Prop() value: number | string;
  @Prop() options;//hash
  @Prop() name;//optionsのvalueがオブジェクトの場合
  @Prop() error_cond: [{func, msg}];
  @Prop({default: false}) no_null: boolean;
  @Prop({default: '未選択'}) no_null_str: string;
  @Prop() before_label: string;
  @Prop({default: '0px'}) before_label_width: string;
  @Prop() after_label: string;
  @Prop({default: '0px'}) after_label_width: string;
  @Prop({default: '100%'}) max_width: string;
  @Prop({default: false}) reset: boolean;
  @Prop() order: string;
  @Prop() order_array;
  @Prop() filter;
  @Prop() remove_keys;

  once_input: boolean = false;//lazy

  get use_filter() {
    if (this.filter === undefined) {
      return this.default_filter;
    } else if (this.filter == 'key_value') {
      return this.key_value_filter;
    } else {
      return this.filter;
    }
  }
  default_filter(val) {
    return val;
  }
  key_value_filter(val) {
    return val[this.name];
  }

  get selected_value() {
    return this.value == null ? 'null' : this.value;
  }
  set selected_value(val) {
    this.change_selected_value(val);
    this.once_input = true;
  }

  get use_options() {
    var options = this.options;
    if (this.order === undefined) {
      options = util.deep_copy(this.options);

      if (this.remove_keys) {
        this.remove_keys.forEach(key => {
          delete options[key];
        });
      }
    } else {
      options = [];
      var keys = Object.keys(this.options);
      if (this.order == 'desc') {
        keys.sort((a,b) => {
          return (a < b ? 1 : -1);
        });
        keys.forEach(key => {
          options.push({id: key, name: this.options[key]});
        });
      } else if (this.order == 'asc') {
        keys.sort();
        keys.forEach(key => {
          options.push({id: key, name: this.options[key]});
        });
      } else if (this.order == 'maintain') {
        this.order_array.forEach(key => {
          options.push({id: key, name: this.options[key]});
        })
      }

      if (this.remove_keys) {
        var new_options = [];
        options.forEach(option => {
          if (this.remove_keys.indexOf(option.id) === -1) {
            new_options.push(option);
          }
        });
      }
    }

    return options;
  }

  @Emit('input')
  change_selected_value(val) {
    return val == 'null' ? null : val;
  }

  @Emit()
  inputelement(e) {
    this.change_select(e);
    return e;
  }

  change_select(e) {
    if (this.reset) {
      e.target.value = 'null';
    }
  }

  get styles () {
    return {
      '--beforelabelwidth': this.before_label_width,
      '--afterlabelwidth': this.after_label_width,
      '--textmaxwidth': this.max_width,
    }
  }
}
