import * as util from '@/util';
import { is_number } from '@/validations/validationUtil';
export default class Earning {
  year: number;
  constructor(year = null) {
    if (year == null) {
      var date = new Date();
      this.year = util.obj2year(date);
    }
  }

  income2earning(money) {
    if (isFinite(money)) {
      if (this.year == 2019) {
        if (money < 651000) {
          var result = 0;
        } else if (money < 1619000) {
          var result = money - 650000;
        } else if (money < 1620000) {
          var result = 969000;
        } else if (money < 1622000) {
          var result = 970000;
        } else if (money < 1624000) {
          var result = 972000;
        } else if (money < 1628000) {
          var result = 974000;
        } else if (money < 1800000) {
          var b = this.calcb(money);
          var result = b * 2.4;
        } else if (money < 3600000) {
          var b = this.calcb(money);
          var result = b * 2.8 - 180000;
        } else if (money < 6600000) {
          var b = this.calcb(money);
          var result = b * 3.2 - 540000;
        } else if (money < 10000000) {
          var result = money * 0.9 - 1200000;
        } else {
          var result = money - 2200000;
        }
      } else if (this.year >= 2020) {
        if (money < 551000) {
          var result = 0;
        } else if (money < 1619000) {
          var result = money - 550000;
        } else if (money < 1620000) {
          var result = 1069000;
        } else if (money < 1622000) {
          var result = 1070000;
        } else if (money < 1624000) {
          var result = 1072000;
        } else if (money < 1628000) {
          var result = 1074000;
        } else if (money < 1800000) {
          var b = this.calcb(money);
          var result = b * 2.4 + 100000;
        } else if (money < 3600000) {
          var b = this.calcb(money);
          var result = b * 2.8 - 80000;
        } else if (money < 6600000) {
          var b = this.calcb(money);
          var result = b * 3.2 - 440000;
        } else if (money < 8500000) {
          var result = money * 0.9 - 1100000;
        } else {
          var result = money - 1950000;
        }
      }
    } else {
      var result = 0;
    }

    result = Math.floor(result + 0.01);
  
    return result;
  }


  calcb(value) {
    value /= 4;
    value = Math.floor(value / 1000);
    value *= 1000;
    return value;
  }
}