import Vue from "vue";
import * as util from "@/util";
import VueFragment from "@grainrigi/vue-fragment";
import { sync } from "vuex-router-sync";
import "./plugins";
import App from "./App.vue";
import router from "@/router";
import store from "@/store";
import VueFroala from "vue-froala-wysiwyg";
import Croppa from "vue-croppa";
import "vue-croppa/dist/vue-croppa.css";
import i18n from "@/plugins/i18n";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";

require("froala-editor/js/froala_editor.pkgd.min.js");
require("froala-editor/js/languages/ja.js");
require("froala-editor/css/froala_editor.pkgd.min.css");
require("froala-editor/css/froala_style.min.css");
require("froala-editor/js/plugins/align.min.js");
require("froala-editor/js/plugins/char_counter.min.js");
require("froala-editor/css/plugins/char_counter.min.css");
require("froala-editor/js/plugins/code_beautifier.min.js");
require("froala-editor/js/plugins/code_view.min.js");
require("froala-editor/css/plugins/code_view.min.css");
require("froala-editor/js/plugins/colors.min.js");
require("froala-editor/css/plugins/colors.min.css");
require("froala-editor/js/plugins/draggable.min.js");
require("froala-editor/css/plugins/draggable.min.css");
require("froala-editor/js/third_party/embedly.min.js");
require("froala-editor/css/third_party/embedly.min.css");
require("froala-editor/js/plugins/emoticons.min.js");
require("froala-editor/css/plugins/emoticons.min.css");
require("froala-editor/js/plugins/entities.min.js");
require("froala-editor/js/plugins/file.min.js");
require("froala-editor/css/plugins/file.min.css");
require("froala-editor/js/plugins/font_family.min.js");
require("froala-editor/js/plugins/font_size.min.js");
require("froala-editor/js/plugins/fullscreen.min.js");
require("froala-editor/css/plugins/fullscreen.min.css");
require("froala-editor/js/plugins/help.min.js");
require("froala-editor/css/plugins/help.min.css");
require("froala-editor/js/plugins/image.min.js");
require("froala-editor/css/plugins/image.min.css");
require("froala-editor/js/plugins/image_manager.min.js");
require("froala-editor/css/plugins/image_manager.min.css");
require("froala-editor/js/third_party/image_tui.min.js");
require("froala-editor/css/third_party/image_tui.min.css");
require("froala-editor/js/plugins/inline_class.min.js");
require("froala-editor/js/plugins/inline_style.min.js");
require("froala-editor/js/plugins/line_breaker.min.js");
require("froala-editor/css/plugins/line_breaker.min.css");
require("froala-editor/js/plugins/line_height.min.js");
require("froala-editor/js/plugins/link.min.js");
require("froala-editor/js/plugins/lists.min.js");
require("froala-editor/js/plugins/paragraph_format.min.js");
require("froala-editor/js/plugins/paragraph_style.min.js");
require("froala-editor/js/plugins/print.min.js");
require("froala-editor/js/plugins/quick_insert.min.js");
require("froala-editor/css/plugins/quick_insert.min.css");
require("froala-editor/js/plugins/quote.min.js");
require("froala-editor/js/plugins/save.min.js");
require("froala-editor/js/plugins/special_characters.min.js");
require("froala-editor/css/plugins/special_characters.min.css");
require("froala-editor/js/plugins/table.min.js");
require("froala-editor/css/plugins/table.min.css");
require("froala-editor/js/plugins/url.min.js");
require("froala-editor/js/plugins/video.min.js");
require("froala-editor/css/plugins/video.min.css");
require("froala-editor/js/plugins/word_paste.min.js");

sync(store, router);
Vue.config.productionTip = false;

/* eslint-disable no-new */
new Vue({
  // @ts-ignore
  i18n,
  router,
  store,
  sockets: {
    connect: function () {
      console.log("socket connected");
    },
    customEmit: function (data) {
      console.log('this method was fired by the socket server. eg: io.emit("customEmit", data)');
    },
  },
  render: (h) => h(App),
}).$mount("#app");

Vue.use(VueFragment.Plugin);
Vue.use(VueFroala);
Vue.use(Croppa);
Vue.use(Antd);

import { VueQueryPlugin } from "@tanstack/vue-query";
Vue.use(VueQueryPlugin);

//新FILTER定義
Vue.filter("int2timespan", function (value) {
  if (value === null || value === undefined) {
    return util.$t("timespanmin", { min: "0" });
  } else {
    return util.formatTimespanint(value);
  }
});

//FILTER定義

Vue.filter("number_format", function (value) {
  if (value == "" || value == undefined || Number.isNaN(value)) {
    return 0;
  } else {
    return value.toString().replace(/([0-9]+?)(?=(?:[0-9]{3})+$)/g, "$1,");
  }
});
Vue.filter("formal_number_format", function (value) {
  if (value == "" || value == undefined || Number.isNaN(value)) {
    return 0;
  } else {
    value = value.toString().replace("-", "▲");
    return value.replace(/([0-9]+?)(?=(?:[0-9]{3})+$)/g, "$1,");
  }
});

Vue.filter("unix2date", function (value) {
  if (value === "" || value === undefined || value === null) {
    return "登録なし";
  } else {
    var d = new Date(value * 1000);
    var year = d.getFullYear();
    var month = d.getMonth() + 1;
    var day = d.getDate();
    return year + "-" + month + "-" + day;
  }
});
Vue.filter("unix2datetime", function (value) {
  if (value === "" || value === undefined || value === null) {
    return "登録なし";
  } else {
    var d = new Date(value * 1000);
    var year = d.getFullYear();
    var month = d.getMonth() + 1;
    var day = d.getDate();
    var hour = d.getHours();
    var minute = d.getMinutes();
    var second = d.getSeconds();
    return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
  }
});

Vue.filter("ceil", function (value) {
  if (value == "" || value == undefined) {
    return 0;
  } else {
    return Math.ceil(value);
  }
});

Vue.filter("pay_status", function (value) {
  if (value == 0) {
    return "正常";
  } else if (value == 1) {
    return "滞納";
  } else if (value == 2) {
    return "過払";
  } else {
    return "なし";
  }
});

Vue.filter("work_type", function (value) {
  if (value == 0) {
    return "通常";
  } else if (value == 1) {
    return "週単位変形労働制";
  } else if (value == 2) {
    return "月単位変形労働制";
  } else if (value == 3) {
    return "年単位変形労働制";
  } else if (value == 4) {
    return "月単位フレックスタイム制";
  } else {
    return "エラー";
  }
});

Vue.filter("wage_type", function (value) {
  if (value == 0) {
    return "時給";
  } else if (value == 1) {
    return "月給";
  } else if (value == 2) {
    return "手動";
  } else {
    return "エラー";
  }
});

Vue.filter("use_status", function (value) {
  if (value == 0) {
    return "正規使用";
  } else if (value == 1) {
    return "お試し使用";
  } else if (value == 2) {
    return "期限切れ";
  } else if (value == 3) {
    return "期限切れ";
  } else if (value == 4) {
    return "その他";
  } else {
    return "なし";
  }
});

Vue.filter("rental_status", function (value) {
  if (value == 0) {
    return "なし";
  } else if (value == 1) {
    return "あり";
  } else {
    return "なし";
  }
});

Vue.filter("plan", function (value) {
  if (value == 0) {
    return "Aプラン";
  } else if (value == 1) {
    return "Bプラン";
  } else if (value == 2) {
    return "Cプラン";
  } else {
    return "登録なし";
  }
});

Vue.filter("pay_method", function (value) {
  if (value == 0) {
    return "登録なし";
  } else if (value == 1) {
    return "クレジットカード";
  } else if (value == 2) {
    return "口座振替";
  } else if (value == 3) {
    return "請求書";
  }
});

Vue.filter("agency_status", function (value) {
  if (value == 0) {
    return "契約なし";
  } else if (value == 1) {
    return "契約中";
  } else {
    return "なし";
  }
});

Vue.filter("equipment", function (value) {
  if (value == 1) {
    return "タブレット";
  } else if (value == 2) {
    return "Felicaリーダー";
  } else {
    return "なし";
  }
});

Vue.filter("isset", function (value) {
  if (isset(value)) {
    return value;
  } else {
    return "未登録";
  }
});
Vue.filter("isno", function (value) {
  if (isset(value)) {
    return value;
  } else {
    return "なし";
  }
});
Vue.filter("ifset", function (value) {
  if (value) {
    return "登録済";
  } else {
    return "未登録";
  }
});

Vue.filter("auto", function (value) {
  if (isset(value)) {
    return value;
  } else {
    return "自動計算";
  }
});

Vue.filter("isset_hide", function (value) {
  if (isset(value)) {
    return "登録済";
  } else {
    return "未登録";
  }
});

Vue.filter("nengo", function (value) {
  if (isset(value)) {
    if (value < 1926) {
      return value + "(大正" + (value - 1910) + ")";
    } else if (value == 1926) {
      return value + "(昭和元/大正15)";
    } else if (value < 1989) {
      return value + "(昭和" + (value - 1925) + ")";
    } else if (value == 1989) {
      return value + "(平成元/昭和64)";
    } else if (value < 2019) {
      return value + "(平成" + (value - 1988) + ")";
    } else if (value == 2019) {
      return value + "(令和元/平成31)";
    } else {
      return value + "(令和" + (value - 2018) + ")";
    }
  } else {
    return "";
  }
});

Vue.filter("nendo", function (value) {
  if (isset(value)) {
    var obj = util.date2obj(value);
    if (obj.year < 2020) {
      return obj.year - 1988;
    } else {
      return util.pad_num(obj.year - 2018);
    }
  } else {
    return "";
  }
});

Vue.filter("two_two", function (value) {
  if (isset(value)) {
    var num = Math.floor(value / 1000);
    if (num == 0) {
      return "";
    } else {
      return num;
    }
  } else {
    return "";
  }
});

Vue.filter("two_one", function (value) {
  if (isset(value)) {
    var num = value % 1000;
    if (value < 1000) {
      return num;
    } else {
      return pad(num, 3, "0");
    }
  } else {
    return "";
  }
});

Vue.filter("four_four", function (value) {
  if (isset(value)) {
    var num = Math.floor(value / 1000000000);
    if (num == 0) {
      return "";
    } else {
      return num;
    }
  } else {
    return "";
  }
});

Vue.filter("four_three", function (value) {
  if (isset(value)) {
    var num = value % 1000000000;
    if (num < 1000000) {
      if (value < 1000000000) {
        return "";
      } else {
        return "000";
      }
    } else {
      num = Math.floor(num / 1000000);
      if (value < 1000000000) {
        return num;
      } else {
        return pad(num, 3, "0");
      }
    }
  } else {
    return "";
  }
});

Vue.filter("four_two", function (value) {
  if (isset(value)) {
    var num = value % 1000000;
    if (num < 1000) {
      if (value < 1000000) {
        return "";
      } else {
        return "000";
      }
    } else {
      num = Math.floor(num / 1000);
      if (value < 1000000) {
        return num;
      } else {
        return pad(num, 3, "0");
      }
    }
  } else {
    return "";
  }
});

Vue.filter("four_one", function (value) {
  if (isset(value)) {
    var num = value % 1000;
    if (value < 1000) {
      return num;
    } else {
      return pad(num, 3, "0");
    }
  } else {
    return "";
  }
});

Vue.filter("three_three", function (value) {
  if (isset(value)) {
    var num = Math.floor(value / 1000000);
    if (num == 0) {
      return "";
    } else {
      return num;
    }
  } else {
    return "";
  }
});

Vue.filter("three_two", function (value) {
  if (isset(value)) {
    var num = value % 1000000;
    if (num < 1000) {
      if (value < 1000000) {
        return "";
      } else {
        return "000";
      }
    } else {
      num = Math.floor(num / 1000);
      if (value < 1000000) {
        return num;
      } else {
        return pad(num, 3, "0");
      }
    }
  } else {
    return "";
  }
});

Vue.filter("three_one", function (value) {
  if (isset(value)) {
    var num = value % 1000;
    if (value < 1000) {
      return num;
    } else {
      return pad(num, 3, "0");
    }
  } else {
    return "";
  }
});

Vue.filter("date", function (value) {
  if (isset(value)) {
    if (value == "0000-00-00") {
      return util.$t("invalid_date");
    } else {
      var tmp = value.split("-");
      return util.$t("date_format", { year: tmp[0], month: tmp[1], day: tmp[2] });
    }
  } else {
    return "";
  }
});
Vue.filter("yearmonth", function (value) {
  if (isset(value)) {
    if (value == "0000-00-00") {
      return util.$t("invalid_date");
    } else {
      var tmp = value.split("-");
      return util.$t("yearmonth", { year: tmp[0], month: tmp[1] });
    }
  } else {
    return "";
  }
});

Vue.filter("year", function (value) {
  if (isset(value)) {
    var tmp = value.toString().split("-");
    return tmp[0];
  } else {
    return "";
  }
});
Vue.filter("month", function (value) {
  if (isset(value)) {
    var tmp = value.split("-");
    return tmp[1];
  } else {
    return "";
  }
});
Vue.filter("day", function (value) {
  if (isset(value)) {
    var tmp = value.split("-");
    return tmp[2];
  } else {
    return "";
  }
});
Vue.filter("daystr", function (value) {
  if (isset(value)) {
    return value + "日";
  } else {
    return "";
  }
});

Vue.filter("ryaku_date", function (value) {
  if (isset(value)) {
    if (value == "0000-00-00") {
      return "";
    } else {
      var tmp = value.split("-");
      return "'" + (Number(tmp[0]) - 2000) + "/" + tmp[1];
    }
  } else {
    return "";
  }
});

Vue.filter("datetime", function (value) {
  if (isset(value)) {
    var tmp = value.split(" ");
    tmp = tmp[0].split("-");
    return tmp[0] + "年" + tmp[1] + "月" + tmp[2] + "日";
  } else {
    return "";
  }
});
Vue.filter("datetime_ago", function (value) {
  if (isset(value)) {
    var datetime_obj = util.datetime2obj(value);
    var now_obj = new Date();

    var datetime_unix = datetime_obj.getTime() / 1000;
    var now_unix = now_obj.getTime() / 1000;

    if (now_unix - datetime_unix < 86400) {
      return util.formatDatetime(datetime_obj, "hh:ii");
    } else if (
      now_unix - datetime_unix < 172800 &&
      datetime_obj.getDate() == now_obj.getDate() - 1
    ) {
      return util.$t(util.day2weekstrengfull(datetime_obj.getDay()));
    } else if (now_unix - datetime_unix < 604800) {
      return util.$t(util.day2weekstrengfull(datetime_obj.getDay()));
    } else if (datetime_obj.getFullYear() == now_obj.getFullYear()) {
      return util.formatDate(datetime_obj, "mm/dd");
    } else {
      return util.formatDate(datetime_obj, "yyyy/mm/dd");
    }
  } else {
    return "";
  }
});
Vue.filter("datetime2str", function (value) {
  if (isset(value)) {
    var tmp = value.split(" ");
    var date = tmp[0].split("-");
    var time = tmp[1].split(":");
    return util.$t("datetime", {
      year: date[0],
      month: date[1],
      day: date[2],
      hour: time[0],
      minute: time[1],
    });
  } else {
    return "";
  }
});
Vue.filter("sqldatetime2str", function (value) {
  var datetimeobj = new Date(value);
  value = util.obj2datetimeNosecond(datetimeobj, 5);
  if (isset(value)) {
    var tmp = value.split(" ");
    var date = tmp[0].split("-");
    var time = tmp[1].split(":");
    return date[0] + "年" + date[1] + "月" + date[2] + "日" + time[0] + "時" + time[1] + "分";
  } else {
    return "";
  }
});
Vue.filter("sqldatetime2strbr", function (value) {
  var datetimeobj = new Date(value);
  value = util.obj2datetimeNosecond(datetimeobj, 5);
  if (isset(value)) {
    var tmp = value.split(" ");
    var date = tmp[0].split("-");
    var time = tmp[1].split(":");
    return util.$t("datetime", {
      year: date[0],
      month: date[1],
      day: date[2],
      hour: time[0],
      minute: time[1],
    });
  } else {
    return "";
  }
});

Vue.filter("datetimedate", function (value) {
  if (isset(value)) {
    var tmp = value.split(" ");
    tmp = tmp[0].split("-");
    return tmp[0] + "年" + tmp[1] + "月" + tmp[2] + "日";
  } else {
    return "";
  }
});
Vue.filter("datetimetime", function (value) {
  if (isset(value)) {
    var tmp = value.split(" ");
    var time = tmp[1].split(":");
    return time[0] + "時" + time[1] + "分" + time[2] + "秒";
  } else {
    return "";
  }
});

Vue.filter("month_day", function (value) {
  if (isset(value)) {
    if (value == "0000-00-00") {
      return "";
    } else {
      var tmp = value.split("-");
      return tmp[1] + "月" + tmp[2] + "日";
    }
  } else {
    return "";
  }
});

Vue.filter("age", function (value) {
  return util.age(value);
});

Vue.filter("zip", function (value) {
  if (isset(value)) {
    var tmp = value.split("-");
    if (isset(tmp[0]) && isset(tmp[1])) {
      return tmp[0] + "-" + tmp[1];
    } else {
      return "";
    }
  } else {
    return "";
  }
});

Vue.filter("auto_no", function (value) {
  if (isset(value)) {
    if (value == 0) {
      return "なし";
    } else if (value == 1) {
      return "自動";
    } else {
      return "エラー";
    }
  } else {
    return "";
  }
});

Vue.filter("auto_manual", function (value) {
  if (isset(value)) {
    if (value == 0) {
      return "自動";
    } else if (value == 1) {
      return "手動";
    } else {
      return "エラー";
    }
  } else {
    return "";
  }
});

Vue.filter("auto_manual_no", function (value) {
  if (isset(value)) {
    if (value == 0) {
      return "なし";
    } else if (value == 1) {
      return "自動";
    } else if (value == 2) {
      return "手動";
    } else {
      return "エラー";
    }
  } else {
    return "";
  }
});

Vue.filter("ratio", function (value) {
  if (isset(value)) {
    var pow = Math.pow(10, 1);
    return Math.round(value * pow) / pow;
  } else {
    return "";
  }
});

Vue.filter("health_ins_type", function (value) {
  if (isset(value)) {
    if (value == 0) {
      return "なし";
    } else if (value == 1) {
      return "自動";
    } else if (value == 2) {
      return "組合国保";
    } else {
      return "エラー";
    }
  } else {
    return "";
  }
});

Vue.filter("yes_no_4", function (value) {
  if (isset(value)) {
    if (value == 0) {
      return "いいえ";
    } else {
      return "はい";
    }
  } else {
    return "";
  }
});

Vue.filter("yes_no", function (value) {
  if (isset(value)) {
    if (value == 0) {
      return "×";
    } else {
      return "◯";
    }
  } else {
    return "";
  }
});

Vue.filter("yes_no_2", function (value) {
  if (isset(value)) {
    if (value == 0) {
      return "該当しない";
    } else {
      return "該当する";
    }
  } else {
    return "";
  }
});

Vue.filter("yes_no_3", function (value) {
  if (isset(value)) {
    if (value == 0) {
      return "なし";
    } else {
      return "あり";
    }
  } else {
    return "";
  }
});

Vue.filter("over_type", function (value) {
  if (isset(value)) {
    if (value == 0) {
      return "なし";
    } else if (value == 1) {
      return "通常";
    } else if (value == 2) {
      return "固定残業制";
    } else {
      return "エラー";
    }
  } else {
    return "";
  }
});

Vue.filter("carfare_type", function (value) {
  if (isset(value)) {
    if (value == 0) {
      return "なし";
    } else if (value == 1) {
      return "日額";
    } else if (value == 2) {
      return "月額";
    } else {
      return "エラー";
    }
  } else {
    return "";
  }
});

Vue.filter("bank_type", function (value) {
  if (isset(value)) {
    if (value == 0) {
      return "普通";
    } else if (value == 1) {
      return "当座";
    } else {
      return "エラー";
    }
  } else {
    return "";
  }
});

Vue.filter("employee_status", function (value) {
  if (isset(value)) {
    if (value == 0) {
      return "入力待ち";
    } else if (value == 1) {
      return "在職";
    } else if (value == 2) {
      return "退職";
    } else if (value == 3) {
      return "承認待ち";
    }
  } else {
    return "";
  }
});

Vue.filter("sex", function (value) {
  if (isset(value)) {
    if (value == 0) {
      return "男性";
    } else if (value == 1) {
      return "女性";
    } else {
      return "エラー";
    }
  } else {
    return "";
  }
});

Vue.filter("tax_type", function (value) {
  if (isset(value)) {
    if (value == 0) {
      return "甲";
    } else if (value == 1) {
      return "乙";
    } else {
      return "エラー";
    }
  } else {
    return "";
  }
});

Vue.filter("householder_relationship", function (value) {
  if (isset(value)) {
    if (value == 1) {
      return "本人";
    } else if (value == 2) {
      return "夫";
    } else if (value == 3) {
      return "妻";
    } else if (value == 4) {
      return "父";
    } else if (value == 5) {
      return "母";
    } else if (value == 6) {
      return "その他";
    } else {
      return "エラー";
    }
  } else {
    return "";
  }
});

Vue.filter("disability_type", function (value) {
  if (isset(value)) {
    if (value == 0) {
      return "なし";
    } else if (value == 1) {
      return "一般障害者";
    } else if (value == 2) {
      return "特別障害者";
    } else {
      return "エラー";
    }
  } else {
    return "";
  }
});

Vue.filter("lock_type", function (value) {
  if (isset(value)) {
    if (value.lock_type == 0) {
      return "未ロック";
    } else if (value.lock_type == 1) {
      return "勤怠ロック";
    } else if (value.lock_type == 2) {
      return "給与ロック";
    } else {
      return "エラー";
    }
  } else {
    return "未ロック";
  }
});

Vue.filter("widow_type_man", function (value) {
  if (isset(value)) {
    if (value == 0) {
      return "該当しない";
    } else if (value == 3) {
      return "該当する";
    } else {
      return "エラー";
    }
  } else {
    return "";
  }
});

Vue.filter("widow_type_woman", function (value) {
  if (isset(value)) {
    if (value == 0) {
      return "該当しない";
    } else if (value == 1) {
      return "寡婦";
    } else if (value == 2) {
      return "特別寡婦";
    } else {
      return "エラー";
    }
  } else {
    return "";
  }
});

Vue.filter("time", function (value) {
  if (isset(value)) {
    var is_minus = 0;
    if (value.toString().indexOf("-") != -1) {
      is_minus = 1;
    }
    value = value.toString().replace("-", "").split(":");
    if (value.length != 2) {
      return "";
    } else {
      if (is_minus == 1) {
        return "前" + value[0] + ":" + value[1];
      } else {
        return value[0] + ":" + value[1];
      }
    }
  } else {
    return "";
  }
});

Vue.filter("timespan", function (value) {
  if (isset(value)) {
    value = value.toString().split(":");
    if (value.length != 2) {
      return "";
    } else {
      return util.$t("timespan", { hour: value[0], min: value[1] });
    }
  } else {
    return "";
  }
});

Vue.filter("zero", function (value) {
  if (isset(value)) {
    return value;
  } else {
    return "0";
  }
});

Vue.filter("zero_blank", function (value) {
  if (isset(value)) {
    if (value == 0) {
      return "";
    } else {
      return value;
    }
  } else {
    return "";
  }
});

Vue.filter("zerotimespan", function (value) {
  if (isset(value)) {
    return value;
  } else {
    return "00時間00分";
  }
});

//定義しただけ
Vue.filter("loan_type", function (value) {
  if (isset(value)) {
    if (value == 0) {
      return "";
    } else {
      return "";
    }
  } else {
    return "";
  }
});

//汎用関数
function isset(val) {
  if (val === undefined || val === null || val === "") {
    return 0;
  } else {
    return 1;
  }
}

function pad(num, length = 2, str = "0") {
  if (!isset(num)) {
    return "";
  }
  var ret = "";
  for (var i = String(num).length; i < length; i++) {
    ret = ret + str;
  }
  ret = ret + num;
  return ret;
}
