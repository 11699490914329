import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import Vue from "vue";
import { Demand_overtime } from "@/typesold/demand";
import Branch from "@/typesold/Branch";

export type GlobalData = {
  is_prev_overdemand: number;
  demand_overtimes: Demand_overtime[];
  branches: Branch[];
  branch_hash: { [id: number]: string };
  branch_hash_all: { [id: number]: string };
};

@Module({ dynamic: true, store, name: "demand_overtime_base", namespaced: true })
class Global extends VuexModule {
  data: GlobalData | null = null;
  is_valid: boolean = false;
  is_set: boolean = false;
  fetching: Promise<void> = null;

  @Mutation
  public disable({ employee_id }: { employee_id?: number }) {
    if (employee_id === undefined || employee_id == Number(util.getLocalStorage("employee_id"))) {
      this.is_valid = false;
    }
  }
  @Mutation
  public disable_all() {
    this.is_valid = false;
  }
  @Mutation
  public setFetching({ value }: { value: Promise<void> }) {
    this.fetching = value;
  }
  @Mutation
  public set(data: GlobalData) {
    this.data = {
      ...data,
      branch_hash: util.createHashReal(data.branches, "id", "name"),
      branch_hash_all: util.createHash(data.branches, "id", "name"),
    };
    this.is_valid = true;
    this.is_set = true;
    this.fetching = null;
  }

  // actions
  @Action({ rawError: true })
  public async fetch() {
    if (this.fetching) {
      await this.fetching;
    } else {
      const promise = util.post("demand_overtime/read_global", {}).then((res) => {
        this.set(res.data);
      });
      this.setFetching({ value: promise });
      await promise;
    }
  }

  @Mutation
  public createDemandOvertime({ demand_overtime }: { demand_overtime: Demand_overtime }) {
    this.data.demand_overtimes.push(demand_overtime);
  }
  @Mutation
  public deleteDemandOvertime({ demand_overtime_id }: { demand_overtime_id: number }) {
    this.data.demand_overtimes = this.data.demand_overtimes.filter(
      (demand_overtime) => demand_overtime.id != demand_overtime_id,
    );
  }
}

export default getModule(Global);
