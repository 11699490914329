import VuexManagerControl from "@/vuex/VuexManagerControl";
import {validationLaborins, validationLaborinsMasters} from "@/validations/laborins";
import dialog from "@/vuex/dialog";
import * as util from "@/util";;

export default class Manager extends VuexManagerControl {
  create_descriptor = {
    laborins: {
      url: 'laborins/create_laborins',
      name: '労働保険改定情報',
      validate: validationLaborins,
      dialog: {},
      processing_dialog: {},
      finish_dialog: {},
      close_dialog: ['create_laborins'],
    }
  };
  update_descriptor = {
    laborins_masters: {
      url: 'laborins/update_laborins_masters',
      name: '労働保険改定情報',
      validate: validationLaborinsMasters,
      dialog: {},
      processing_dialog: {},
      finish_dialog: {},
    }
  };
  delete_descriptor = {
    laborins: {
      url: 'laborins/delete_laborins',
      name: '労働保険改定情報',
      dialog: {msg: "労働保険改定のやり直しを行ってよろしいですか？", detail: '現在までの変更内容は全て削除されます。'},
      processing_dialog: {},
      finish_dialog: {},
      routing: {name: 'laborins', args_plus: {laborins_id: undefined}},
    }
  };

  document_descriptor = {
    renew_laborins_pdf: {
      url: 'document/generate_renew_laborins',
      name: '労働保険改定',
      type: 'pdf',
      processing_dialog: {},
    },
  };

  reflect_type: number = 0;
  step: number = 0.1;
}