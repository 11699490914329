import Vue from "vue";
import VueI18n from "vue-i18n";
import language from "@/library/language";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "ja",
  messages: language,
});

let value = localStorage.getItem("locale");
if (value != "ja" && value != "en") {
  value = "ja";
}
i18n.locale = value;

export default i18n;
