import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import Vue from "vue";
import { Document_template } from "@/typesold/Document_template";

export type GlobalData = {
  contract_documents: any;
};

@Module({ dynamic: true, store, name: "document_waiting", namespaced: true })
class Global extends VuexModule {
  data: GlobalData | null = null;
  is_valid: boolean = false;
  is_set: boolean = false;
  fetching: Promise<void> = null;

  @Mutation
  public disable({}) {
    this.is_valid = false;
  }
  @Mutation
  public disable_all() {
    this.is_valid = false;
  }
  @Mutation
  public setFetching({ value }: { value: Promise<void> }) {
    this.fetching = value;
  }
  @Mutation
  public set(data: GlobalData) {
    this.data = {
      ...data,
    };
    this.is_valid = true;
    this.is_set = true;
    this.fetching = null;
  }

  @Action({ rawError: true })
  public async fetch() {
    if (this.fetching) {
      await this.fetching;
    } else {
      const promise = util.post("document/read_waiting", {}).then((res) => {
        this.set(res.data);
      });
      this.setFetching({ value: promise });
      await promise;
    }
  }
}

export default getModule(Global);
