import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import monthly from "@/vuex/attendance/monthly";
import global from "@/vuex/attendance/global";
import * as util from "@/util";
import validate from "@/validations/validate";
import { validationShift } from "@/validations/shift";
import dialog from "@/vuex/dialog";
import Vue from "vue";
import node from "@/vuex/node";

@Module({ dynamic: true, store, name: "attendance_manager", namespaced: true })
class Manager extends VuexModule {
  disp_num: number = 10;
  edit_prescribed_flag: boolean = false;

  prescribed = {}; //employee_id => {prescribed_day, prescribed_time}

  @Mutation
  private setEditPrescribedFlag(val: boolean) {
    if (!val) {
      Vue.set(this, "prescribed", {});
    }
    this.edit_prescribed_flag = val;
  }

  @Mutation
  public setDispNum(val) {
    this.disp_num = val;
  }

  @Action({ rawError: true })
  public cancelAll() {
    this.setEditPrescribedFlag(false);
  }

  @Action({ rawError: true })
  public async is_open_input() {
    return this.edit_prescribed_flag;
  }

  @Action({ rawError: true })
  public async editPrescribed() {
    if (await this.is_open_input()) return;
    this.setEditPrescribedFlag(true);
  }

  @Action({ rawError: true })
  public updatePrescribed({ yearmonth, branch_id }: { yearmonth: string; branch_id: number }) {
    dialog.openConfirmDialog({
      msg: "所定労働を更新してよろしいですか？",
      cancel_button: "更新しない",
      exec_button: "更新する",
      func: this.updatePrescribedProcess,
      is_next_dialog: true,
      args: { yearmonth, branch_id },
    });
  }
  @Action({ rawError: true })
  public async updatePrescribedProcess({
    yearmonth,
    branch_id,
  }: {
    yearmonth: string;
    branch_id: number;
  }) {
    dialog.openProgressDialog({ title: "更新中", msg: "更新には時間がかかることがあります。" });
    await util.post_proxy("attendance/update_prescribed", {
      yearmonth,
      branch_id,
      object: this.prescribed,
    });
    this.cancelAll();
    node.disable();
    dialog.openAlertDialog({ msg: "更新が完了しました" });
  }

  @Mutation
  setPrescribed({
    employee_id,
    prescribed_object,
  }: {
    employee_id: number;
    prescribed_object: any;
  }) {
    Vue.set(this.prescribed, employee_id, prescribed_object);
  }

  @Action({ rawError: true })
  public lock({
    yearmonth,
    branch_id,
    branch_name,
    close_ended_on,
  }: {
    yearmonth: string;
    branch_id: number;
    branch_name: string;
    close_ended_on: string;
  }) {
    if (util.date2unix(close_ended_on) > util.date2unix(util.today())) {
      //まだ計算期間の終了日より前なのにロックしようとしている
      dialog.openForceConfirmDialog({
        msg: branch_name + "の勤怠ロックをしてよろしいですか？",
        detail:
          "計算期間の終了日が本日より後の勤怠をロックしようとしています。<br>ロック後は打刻をしても勤怠計算がされません。<br>本当にロックしてよろしいですか？<br>ロック以降の会社・店舗・社員情報や、手当などの変更は反映されません。",
        cancel_button: "ロックしない",
        exec_button: "ロックする",
        func: this.lockProcess,
        args: { yearmonth, branch_id },
        is_next_dialog: true,
      });
    } else {
      dialog.openConfirmDialog({
        msg: branch_name + "の勤怠ロックをしてよろしいですか？",
        detail: "ロック以降の会社・店舗・社員情報や、手当などの変更は反映されません。",
        cancel_button: "ロックしない",
        exec_button: "ロックする",
        func: this.lockProcess,
        args: { yearmonth, branch_id },
        is_next_dialog: true,
      });
    }
  }
  @Action({ rawError: true })
  public async lockProcess({ yearmonth, branch_id }: { yearmonth: string; branch_id: number }) {
    dialog.openProgressDialog({
      title: "ロック中",
      msg: "この処理には時間がかかることがあります。",
    });
    await util.post_proxy("attendance/lock", { yearmonth, branch_id });
    node.disable();
    dialog.openAlertDialog({ msg: "勤怠ロックが完了しました" });
  }

  @Action({ rawError: true })
  public recalc({
    yearmonth,
    branch_id,
    branch_name,
  }: {
    yearmonth: string;
    branch_id: number;
    branch_name: string;
  }) {
    dialog.openConfirmDialog({
      msg: branch_name + "のデータ再計算をしてよろしいですか？",
      detail:
        "ロック後に行った変更は全て破棄されます。<br>また、過去の再計算を行った場合、有給休暇の計算に影響を及ぼす可能性があります。<br>再計算では、ロック以降に変更した会社・店舗・社員情報が反映されます。",
      cancel_button: "再計算しない",
      exec_button: "再計算する",
      func: this.recalcProcess,
      args: { yearmonth, branch_id },
      is_next_dialog: true,
    });
  }
  @Action({ rawError: true })
  public async recalcProcess({ yearmonth, branch_id }: { yearmonth: string; branch_id: number }) {
    dialog.openProgressDialog({
      title: "再計算中",
      msg: "この処理には時間がかかることがあります。",
    });
    await util.post_proxy("attendance/recalc", { yearmonth, branch_id });
    node.disable();
    dialog.openAlertDialog({ msg: "再計算が完了しました" });
  }

  @Action({ rawError: true })
  public unlock({
    yearmonth,
    branch_id,
    branch_name,
  }: {
    yearmonth: string;
    branch_id: number;
    branch_name: string;
  }) {
    dialog.openConfirmDialog({
      msg: branch_name + "の勤怠ロックを解除してよろしいですか？",
      detail:
        "ロック後に行った変更は全て破棄されます。<br>また、過去のロック解除を行った場合、有給休暇の計算に影響を及ぼす可能性があります。",
      cancel_button: "ロック解除しない",
      exec_button: "ロック解除する",
      func: this.unlockConfirm,
      args: { yearmonth, branch_id },
      is_next_dialog: true,
    });
  }
  @Action({ rawError: true })
  public unlockConfirm({ yearmonth, branch_id }: { yearmonth: string; branch_id: number }) {
    dialog.openForceConfirmDialog({
      msg: "本当によろしいですか？",
      detail: "有給休暇やその他の勤怠計算にズレが生じる場合があります。<br>本当によろしいですか？",
      cancel_button: "ロック解除しない",
      exec_button: "ロック解除する",
      func: this.unlockProcess,
      is_next_dialog: true,
      args: { yearmonth, branch_id },
    });
  }
  @Action({ rawError: true })
  public async unlockProcess({ yearmonth, branch_id }: { yearmonth: string; branch_id: number }) {
    dialog.openProgressDialog({
      title: "ロック解除中",
      msg: "この処理には時間がかかることがあります。",
    });
    await util.post_proxy("attendance/unlock", { yearmonth, branch_id });
    node.disable();
    dialog.openAlertDialog({ msg: "勤怠ロック解除が完了しました" });
  }

  @Action({ rawError: true })
  public async attendance_csv({ branch_id, yearmonth }: { branch_id: number; yearmonth: string }) {
    dialog.openProgressDialog({ title: "作成中", msg: "作成には時間がかかることがあります。" });
    await util.post_document_proxy(
      "document/generate_attendance_csv",
      { yearmonth, branch_id },
      util.formatDate(util.date2obj(yearmonth + "-01"), "yyyy年mm月") + "支給分勤怠詳細データ.csv",
    );
    dialog.closeDialog();
  }

  @Action({ rawError: true })
  public async attendance_pdf({ branch_id, yearmonth }: { branch_id: number; yearmonth: string }) {
    dialog.openProgressDialog({ title: "作成中", msg: "作成には時間がかかることがあります。" });
    await util.post_document(
      "document/generate_attendance_pdf",
      { yearmonth, branch_id },
      util.formatDate(util.date2obj(yearmonth + "-01"), "yyyy年mm月") + "支給分勤怠詳細データ.pdf",
    );
    dialog.closeDialog();
  }

  @Action({ rawError: true })
  public async updateFromAsk({ yearmonth }: { yearmonth: string }) {
    dialog.openProgressDialog({ title: "取込中", msg: "取り込みには時間がかかることがあります。" });
    const res = await util.post("attendance/update_from_ask", { yearmonth });
    if (!res.data.error_header) {
      dialog.closeDialog();
    }
  }

  @Action({ rawError: true })
  public async attendance_detail_csv({
    branch_id,
    yearmonth,
  }: {
    branch_id: number;
    yearmonth: string;
  }) {
    dialog.openProgressDialog({ title: "作成中", msg: "作成には時間がかかることがあります。" });
    await util.post_document(
      "document/generate_attendance_detail_csv",
      { yearmonth, branch_id },
      util.formatDate(util.date2obj(yearmonth + "-01"), "yyyy年mm月") + "支給分勤怠概要データ.csv",
    );
    dialog.closeDialog();
  }
}

export default getModule(Manager);
