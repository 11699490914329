
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import utilMixins from "@/mixins";
  @Component({
    components: {},
  })
  export default class Menu extends Mixins(utilMixins) {
    go_to_home() {
      this.change_page("shift", util.passquery["shift"]);
    }
  }
