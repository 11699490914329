import { request } from "@/query/request";
import { LogoutProp } from "@/types/Session";

export const readCompanySession = async () =>
  await request({
    url: `session/session`,
    method: "get",
  });

export const logout = async (data: LogoutProp) =>
  await request({
    url: `session/logout`,
    method: "post",
    data,
  });

export const readTag = async (company_id: number) =>
  await request({
    url: `session/tag?company_id=${company_id}`,
    method: "get",
  });
