import { Mutation, MutationAction, Action, VuexModule, getModule, Module } from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import Vue from 'vue';

@Module({ dynamic: true, store, name: "realtime", namespaced: true })
class Realtime extends VuexModule {
  @Action({ rawError: true })
  public async getChart({date, branch_id}: {date: string, branch_id: number}) {
    const res = await util.post('shift/read_chart', {date, branch_id});
    return res.data;
  }

  @Action({ rawError: true })
  public async getNeed({yearmonth, branch_id}: {yearmonth: string, branch_id: number}) {
    const res = await util.post('shift/read_need_monthly', {yearmonth, branch_id});
    return res.data;
  }

  @Action({ rawError: true })
  public async getSummery({date, branch_id}: {date: string, branch_id: number}) {
    const res = await util.post('shift/read_summery', {date, branch_id});
    return res.data;
  }

  @Action({ rawError: true })
  public async getAble({yearmonth, branch_id}: {yearmonth: string, branch_id: number}) {
    const res = await util.post('submit/read_able_monthly', {yearmonth, branch_id});
    return res.data;
  }
}

export default getModule(Realtime);