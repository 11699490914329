
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import header from "@/vuex/header";
import mixinNotification from '@/mixins/mixinNotification';

@Component({
  components: {
  }
})
export default class News extends Mixins(mixinNotification) {
  is_visible_news: boolean = false;

  get inform_num() {
    return Object.keys(this.header.system_informs).filter(key => this.header.system_informs[key].is_new).length;
  }

  open_admin_notification_dialog(notification_id) {
    this.m.setTmpObjects({name: 'admin_notification_id', val: notification_id});
    this.m.openDialog({name: 'inform_admin_detail'});
  }

  change_visible_news() {
    this.is_visible_news = !this.is_visible_news;
  }

  click_demand_overtime() {

  }
  click_demand_holiday() {

  }
  click_demand_punch() {

  }
  click_demand_change() {

  }
}
