import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import Vue from "vue";
import { Spouce, Dependant } from "@/typesold/family";
import { Each_employee_info } from "@/typesold/employee_info";

export type GlobalData = {
  employee: any;
  spouce?: Spouce;
  dependants: any;
  each_employee_infos: { [employee_info_id: number]: Each_employee_info[] };
  is_valid?: boolean;
  is_set: boolean;
  fetching?: Promise<void>;
};

export type EmployeelyData = {
  [employee_id: number]: GlobalData;
};

@Module({ dynamic: true, store, name: "change_employeely", namespaced: true })
class Global extends VuexModule {
  data: EmployeelyData = {};

  @Mutation
  public disable({ employee_id }: { employee_id: number }) {
    if (this.data[employee_id]) {
      this.data[employee_id].is_valid = false;
    }
  }
  @Mutation
  public disable_all() {
    Object.keys(this.data).forEach((employee_id) => {
      this.data[employee_id].is_valid = false;
    });
  }
  @Mutation
  public setFetching({ employee_id, value }: { employee_id: number; value: Promise<void> }) {
    this.data[employee_id].fetching = value;
  }
  @Mutation
  public set({ employee_id, data }: { employee_id: number; data }) {
    Vue.set(this.data, employee_id, {
      ...data,
      branch_hash: util.createHash(data.branches, "id", "name"),
      is_valid: true,
      is_set: true,
      fetching: null,
    });
  }
  // actions
  @Action({ rawError: true })
  public async fetch({ employee_id }: { employee_id: number }) {
    //ないなら作る
    if (!this.data[employee_id]) {
      Vue.set(this.data, employee_id, {
        employee: {},
        is_valid: false,
        is_set: false,
        fetching: null,
      });
    }

    if (this.data[employee_id].fetching) {
      await this.data[employee_id].fetching;
    } else {
      const promise = util.post("demand_change/read_employeely", { employee_id }).then((res) => {
        this.set({ employee_id, data: res.data });
      });
      this.setFetching({ employee_id, value: promise });
      await promise;
    }
  }
}

export default getModule(Global);
