import * as util from '@/util';
import * as vali from '@/validations/validationUtil';

//validationは、OKならTrue, それ以外なら警告文を配列に入れる。
export function validationPassreset(self, args) {
  var error = [];

  error.push(args.pass.pass !== null || 'パスワードが入力されていません');
  error.push(args.pass.pass_confirm !== null || 'パスワード(確認)が入力されていません');
  error.push(args.pass.pass === null || args.pass.pass_confirm === null || args.pass.pass == args.pass.pass_confirm || 'パスワードが確認と一致しません');
  
  return error;
}