import { Mutation, MutationAction, Action, VuexModule, getModule, Module } from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import monthly from "@/vuex/bonus_each/monthly";
import * as util from "@/util";
import validate from "@/validations/validate";
import {validationEachBonusPayment} from "@/validations/bonus";
import dialog from "@/vuex/dialog";
import Vue from 'vue';
import node from '@/vuex/node';


@Module({ dynamic: true, store, name: "bonus_each_manager", namespaced: true })
class Manager extends VuexModule {
  edit_each_bonus_payment_flag: boolean = false;

  each_bonus_payment: any = {};//each_bonus_object
  mode: number = 0;//{0: 従来, 1: カード形式(sp)}

  @Mutation
  private setEditEachBonusPaymentFlag(val: boolean) {
    if (!val) {
      Vue.set(this, 'each_bonus_payment', {});
    }
    this.edit_each_bonus_payment_flag = val;
  }

  @Action({ rawError: true })
  public async editEachBonusPayment() {
    if (await this.is_open_input()) return;
    this.setEditEachBonusPaymentFlag(true);
  }

  @Mutation
  setEachBonusPayment({each_bonus_payment_object}: {each_bonus_payment_object: any}) {
    Vue.set(this, 'each_bonus_payment', each_bonus_payment_object);
  }

  @Mutation
  setEachBonusAllowance({id, val}) {
    Vue.set(this.each_bonus_payment.each_bonus_allowances, id, val);
  }
  @Mutation
  setEachBonusDeduction({id, val}) {
    Vue.set(this.each_bonus_payment.each_bonus_deductions, id, val);
  }
  @Mutation
  setEachBonusOtherAllowance({id, val}) {
    Vue.set(this.each_bonus_payment.each_bonus_other_allowances, id, val);
  }

  @Action({ rawError: true })
  public updateEachBonusPayment({bonus_info_id, employee_id, employee, saved_bonus_allowances, saved_bonus_deductions, saved_bonus_other_allowances}: {bonus_info_id: number, employee_id: number, employee: any, saved_bonus_allowances: any, saved_bonus_deductions: any, saved_bonus_other_allowances: any}) {
    if (validate(validationEachBonusPayment(this.each_bonus_payment, employee, saved_bonus_allowances, saved_bonus_deductions, saved_bonus_other_allowances, false))) {
      dialog.openConfirmDialog({msg: '給与を更新してよろしいですか？', cancel_button: '更新しない', exec_button: '更新する', func: this.updateEachBonusPaymentProcess, args: {bonus_info_id, employee_id}});
    }
  }
  @Action({ rawError: true })
  public async updateEachBonusPaymentProcess({bonus_info_id, employee_id}: {bonus_info_id: number, employee_id: number}) {
    await util.post('bonus_each/update_each_bonus_payment', {bonus_info_id, employee_id, object: this.each_bonus_payment});
    this.cancelAll();
    node.disable();
  }

  
  @Action({ rawError: true })
  public cancelAll() {
    this.setEditEachBonusPaymentFlag(false);
  }
  
  @Action({ rawError: true })
  public async is_open_input() {
    return this.edit_each_bonus_payment_flag;
  }

  @Mutation
  setMode(mode: number) {
    this.mode = mode;
  }
}

export default getModule(Manager);