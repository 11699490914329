
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import dialog from "@/vuex/dialog";
import utilMixins from '@/mixins';
import * as util from '@/util';
import header from '@/vuex/header';
import session from '@/vuex/session';
import UtilPopup from "@/components/util/Popup.vue";

@Component({
  components: {
    UtilPopup,
  }
})
export default class Main extends Mixins(utilMixins) {
  get popup_news() {
    return session.popup_news;
  }
  get is_display() {
    return header.is_open_popupnews_dialog;
  }
  set is_display(val: boolean) {
    header.setIsOpenPopupnewsDialog(val);
  }
}
