import * as util from '@/util';
import * as vali from '@/validations/validationUtil';

//validationは、OKならTrue, それ以外なら警告文を配列に入れる。
export function validationIndividualunit(self, args) {
  var error = [];

  error.push(self.tmp_objects.individual_unit.name !== null || '名前が入力されていません');
  
  return error;
}