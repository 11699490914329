import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import Vue from "vue";
import global from "@/vuex/attendance/global";

export type EmployeeHash = {
  [id: number]: any;
};
export type LockTypeHash = {
  [id: number]: number;
};
export type AttendanceErrorHash = {
  [id: number]: any;
};
export type BranchObject = {
  [id: number]: string;
};
export type Data = {
  employee_hash: EmployeeHash;
  branch_hash: BranchObject;
  branch_hash_all: BranchObject;
  each_attendances: any[];
  lock_types_hash: LockTypeHash;
  attendance_error: any[];
  is_valid?: boolean;
  is_set: boolean;
  fetching?: Promise<void>;
};
export type BranchlyData = {
  [branch: number]: Data;
};
export type MonthlyData = {
  [yearmonth: string]: BranchlyData;
};

@Module({ dynamic: true, store, name: "attendance_monthly_data", namespaced: true })
class AttendanceMonthly extends VuexModule {
  data: MonthlyData = {};

  @Mutation
  public set({ yearmonth, branch_id, data }: { yearmonth: string; branch_id: number; data }) {
    Vue.set(this.data[yearmonth], branch_id, {
      employee_hash: util.createHashObject(data.employees, "id"),
      branch_hash: util.createHashReal(data.branches, "id", "name"),
      branch_hash_all: util.createHash(data.branches, "id", "name"),
      each_attendances: data.each_attendances,
      attendance_error: data.attendance_error,
      lock_types_hash: util.createHash(data.lock_types, "branch_id", "lock_type"),
      is_valid: true,
      is_set: true,
      fetching: null,
    });
  }
  @Mutation
  public disable({ yearmonth, branch_id }: { yearmonth: string; branch_id: number }) {
    if (branch_id == 0) {
      if (this.data[yearmonth]) {
        Object.keys(this.data[yearmonth]).forEach((branch_id) => {
          this.data[yearmonth][branch_id].is_valid = false;
        });
      }
    } else {
      if (this.data[yearmonth] && this.data[yearmonth][branch_id]) {
        this.data[yearmonth][branch_id].is_valid = false;
      }
    }
    if (this.data[yearmonth] && this.data[yearmonth][0]) {
      this.data[yearmonth][0].is_valid = false;
    }
  }
  @Mutation
  public disable_all() {
    Object.keys(this.data).forEach((yearmonth) => {
      Object.keys(this.data[yearmonth]).forEach((branch_id) => {
        this.data[yearmonth][branch_id].is_valid = false;
      });
    });
  }
  @Mutation
  public setFetching({
    yearmonth,
    branch_id,
    value,
  }: {
    yearmonth: string;
    branch_id: number;
    value: Promise<void>;
  }) {
    this.data[yearmonth][branch_id].fetching = value;
  }

  // actions
  @Action({ rawError: true })
  public async fetch({
    yearmonth,
    branch_id,
    payment_info_id,
  }: {
    yearmonth: string;
    branch_id: number;
    payment_info_id: number;
  }) {
    //ないなら作る
    if (!this.data[yearmonth]) {
      Vue.set(this.data, yearmonth, {});
    }
    if (!this.data[yearmonth][branch_id]) {
      Vue.set(this.data[yearmonth], branch_id, {
        employee_hash: {},
        branch_hash: {},
        branch_hash_all: {},
        each_attendances: [],
        lock_types_hash: {},
        attendance_error: [],
        is_valid: false,
        is_set: false,
        fetching: null,
      });
    }

    if (this.data[yearmonth][branch_id].fetching) {
      await this.data[yearmonth][branch_id].fetching;
    } else {
      const promise = util
        .post_proxy("attendance/read_monthly", { branch_id, payment_info_id })
        .then((res) => {
          this.set({ yearmonth, branch_id, data: res.data });
        });
      this.setFetching({ yearmonth, branch_id, value: promise });
      await promise;
    }
  }
}

export default getModule(AttendanceMonthly);
