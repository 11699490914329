import * as util from "@/util";

export function validationResidentTaxes(resident_taxes, employee_hash) {
  var error = [];

  Object.keys(resident_taxes).forEach(employee_id => {
    error = error.concat(validationResidentTax(resident_taxes[employee_id], employee_hash[employee_id]));
  });

  return error;
}

function validationResidentTax(resident_tax, employee) {
  var error = [];
  
  return error;
}
