
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import utilMixins from '@/mixins';
import dialog from "@/vuex/dialog";
import header from "@/vuex/header";

@Component({
  components: {
  }
})
export default class Header extends Mixins(utilMixins) {
  disp_shift_menu = false;
  disp_payment_menu = false;
  open_movie_dialog() {
    header.setIsOpenMovieDialog(true)
  }

  move_page_local(page, forcepassquery) {
    this.disp_shift_menu = false;
    this.disp_payment_menu = false;
    this.move_page(page, forcepassquery)
  }

  disp_shift() {
    this.disp_shift_menu = !this.disp_shift_menu;
    this.disp_payment_menu = false;
  }
  disp_payment() {
    this.disp_shift_menu = false;
    this.disp_payment_menu = !this.disp_payment_menu;
  }
}

