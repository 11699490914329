import { Mutation, MutationAction, Action, VuexModule, getModule, Module } from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import Vue from 'vue';

@Module({ dynamic: true, store, name: "master_realtime", namespaced: true })
class Realtime extends VuexModule {
  @Action({ rawError: true })
  public async readBank({bank_name}: {bank_name: string}) {
    const res = await util.post('admin/read_bank', {bank_name}, false);
    return res.data;
  }

  @Action({ rawError: true })
  public async readBranch({bank_code, branch_name}: {bank_code: string, branch_name: string}) {
    const res = await util.post('admin/read_branch', {bank_code, branch_name}, false);
    return res.data;
  }

  @Action({ rawError: true })
  public async readBranchYucho({bank_code, branch_code}: {bank_code: string, branch_code: string}) {
    const res = await util.post('admin/read_yucho', {bank_code, branch_code}, false);
    return res.data;
  }

  @Action({ rawError: true })
  public async readTaxoffice({taxoffice_name}: {taxoffice_name: string}) {
    const res = await util.post('admin/read_taxoffice', {taxoffice_name}, false);
    return res.data;
  }
  @Action({ rawError: true })
  public async readZip({zip}: {zip: string}) {
    const res = await util.post('admin/read_zip', {zip}, false);
    return res.data;
  }
}

export default getModule(Realtime);