import * as util from "@/util";

//validationは、OKならTrue, それ以外なら警告文を配列に入れる。
export default function validationDemandPunchShift(shift) {
  var error = [];
  error.push(shift.branch_id != null || util.$t("error.demand_punch_select_branch"));
  error.push(
    shift.shift_punch[0].started_at != null ||
      shift.shift_break[0].started_at != null ||
      util.$t("demand_punch_enter_atleast_one_kind"),
  );

  for (var i = 0; i < shift.shift_punch.length; i++) {
    if (!validate_time_start(shift.shift_punch[i]))
      error.push(util.$t("error.demand_punch_only_punch_out", { row: i + 1 }));
    if (!validate_time_end(shift.shift_punch[i]))
      error.push(util.$t("error.demand_punch_only_punch_in", { row: i + 1 }));
    if (!validate_time_order(shift.shift_punch[i]))
      error.push(util.$t("error.demand_punch_punch_time_contradiction", { row: i + 1 }));
    error = error.concat(
      validate_time_duplicate(shift.shift_punch, i).map((j) =>
        util.$t("error.demand_punch_punch_time_duplication", { row1: i + 1, row2: j + 1 }),
      ),
    );
    if (!validate_time_start_before_24(shift.shift_punch[i]))
      error.push(util.$t("error.demand_punch_punch_before_24", { row: i + 1 }));
    if (!validate_time_end_after_0(shift.shift_punch[i]))
      error.push(util.$t("error.demand_punch_punch_after_0", { row: i + 1 }));
    if (!validate_time_within_24(shift.shift_punch[i]))
      error.push(util.$t("error.demand_punch_punch_over_24", { row: i + 1 }));
  }

  for (var i = 0; i < shift.shift_break.length; i++) {
    if (!validate_time_start(shift.shift_break[i]))
      error.push(util.$t("error.demand_punch_only_break_end", { row: i + 1 }));
    if (!validate_time_end(shift.shift_break[i]))
      error.push(util.$t("error.demand_punch_only_break_start", { row: i + 1 }));
    if (!validate_time_order(shift.shift_break[i]))
      error.push(util.$t("error.demand_punch_break_time_contradiction", { row: i + 1 }));
    error = error.concat(
      validate_time_duplicate(shift.shift_break, i).map((j) =>
        util.$t("error.demand_punch_break_time_duplication", { row1: i + 1, row2: j + 1 }),
      ),
    );
  }

  return error;
}

function validate_time_start(time): boolean {
  return time.started_at !== null || time.ended_at === null;
}
function validate_time_end(time): boolean {
  return time.started_at === null || time.ended_at !== null;
}
function validate_time_order(time): boolean {
  return time.started_at === null || time.ended_at === null || time.started_at < time.ended_at;
}
function validate_time_duplicate(times, i): number[] {
  var error_j = [];
  for (var j = i + 1; j < times.length; j++) {
    if (times[i].started_at !== null && times[j].started_at !== null) {
      if (
        times[i].started_at !== null &&
        times[j].started_at !== null &&
        times[j].ended_at !== null &&
        times[j].started_at <= times[i].started_at &&
        times[i].started_at < times[j].ended_at
      )
        error_j.push(j);
      else if (
        times[i].ended_at !== null &&
        times[j].started_at !== null &&
        times[j].ended_at !== null &&
        times[j].started_at < times[i].ended_at &&
        times[i].ended_at <= times[j].ended_at
      )
        error_j.push(j);
      else if (
        times[j].started_at !== null &&
        times[i].started_at !== null &&
        times[i].ended_at !== null &&
        times[i].started_at <= times[j].started_at &&
        times[j].started_at < times[i].ended_at
      )
        error_j.push(j);
      else if (
        times[j].ended_at !== null &&
        times[i].started_at !== null &&
        times[i].ended_at !== null &&
        times[i].started_at < times[j].ended_at &&
        times[j].ended_at <= times[i].ended_at
      )
        error_j.push(j);
      else if (times[i].started_at == times[j].started_at) error_j.push(j);
    }
  }
  return error_j;
}
function validate_time_start_within_today(time): boolean {
  return (
    time.started_at === null ||
    time.ended_at === null ||
    (time.started_at >= 0 && time.started_at <= 1440)
  );
}
function validate_time_within_24(time): boolean {
  return (
    time.started_at === null || time.ended_at === null || time.ended_at - time.started_at <= 1440
  );
}
function validate_time_end_after_0(time): boolean {
  return time.ended_at === null || time.ended_at > 0;
}
function validate_time_start_before_24(time): boolean {
  return time.started_at === null || time.started_at < 1440;
}
