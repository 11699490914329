import VuexManagerControl from "@/vuex/VuexManagerControl";
import {validationRenewStdincome, validationRenewStdincomeMasters} from "@/validations/renew_stdincome";
import dialog from "@/vuex/dialog";
import * as util from "@/util";

export default class Manager extends VuexManagerControl {
  create_descriptor = {
    renew_stdincome: {
      url: 'renew_stdincome/create_renew_stdincome',
      name: '算定基礎届情報',
      dialog: {msg: '給与ロックがされていない月の分は計算されません。', detail: '必ず4〜6月の給与ロック後に開始してください。'},
      processing_dialog: {},
      finish_dialog: {},
    }
  };
  update_descriptor = {
    renew_stdincome_masters: {
      url: 'renew_stdincome/update_renew_stdincome_masters',
      name: '算定基礎届情報',
      validate: validationRenewStdincomeMasters,
      dialog: {},
      processing_dialog: {},
      finish_dialog: {},
    },
    renew_stdincome_master_valid: {
      url: 'renew_stdincome/update_renew_stdincome_master_valid',
      name: '算定基礎届有効無効',
    },
    renew_stdincome_reflect: {
      url: 'renew_stdincome/reflect_renew_stdincome',
      name: '標準報酬月額反映',
      dialog: {msg: '計算された標準報酬月額を社員情報へ反映してよろしいですか？', detail: '反映前に勤怠ロックされた分の計算は変更されません。'},
      processing_dialog: {},
      finish_dialog: {},
    },
    renew_stdincome_unreflect: {
      url: 'renew_stdincome/unreflect_renew_stdincome',
      name: '標準報酬月額反映取消',
      dialog: {msg: '社員情報の標準報酬月額を反映前の値に戻してよろしいですか？', detail: '反映取消前に勤怠ロックされた分の計算は変更されません。'},
      processing_dialog: {},
      finish_dialog: {},
    },
    renew_stdincomes_reflect: {
      url: 'renew_stdincome/reflect_renew_stdincomes',
      name: '標準報酬月額反映',
      dialog: {msg: '表示中の店舗に所属している社員の計算された標準報酬月額を一括反映してよろしいですか？', detail: '「有効」かつ「未反映」の社員のみが対象です。<br>反映前に勤怠ロックされた分の計算は変更されません。'},
      force_dialog: {msg: '表示中の店舗に所属している社員の計算された標準報酬月額を一括反映してよろしいですか？', detail: '「有効」かつ「未反映」の社員のみが対象です。<br>本当によろしいですか？'},
      processing_dialog: {},
      finish_dialog: {},
    },
    renew_stdincomes_unreflect: {
      url: 'renew_stdincome/unreflect_renew_stdincomes',
      name: '標準報酬月額反映取消',
      dialog: {msg: '表示中の店舗に所属している社員の社員情報の標準報酬月額を反映前の値に戻してよろしいですか？', detail: '「有効」かつ「反映済」の社員のみが対象です。<br>反映取消前に勤怠ロックされた分の計算は変更されません。'},
      force_dialog: {msg: '表示中の店舗に所属している社員の社員情報の標準報酬月額を反映前の値に戻してよろしいですか？', detail: '「有効」かつ「反映済」の社員のみが対象です。<br>本当によろしいですか？'},
      processing_dialog: {},
      finish_dialog: {},
    },
  };
  delete_descriptor = {
    renew_stdincome: {
      url: 'renew_stdincome/delete_renew_stdincome',
      name: '算定基礎届情報',
      dialog: {msg: "算定基礎届のやり直しを行ってよろしいですか？", detail: '現在までの変更内容は全て削除されます。'},
      processing_dialog: {},
      finish_dialog: {},
      routing: {name: 'renew_stdincome', args_plus: {renew_stdincome_id: undefined}},
    }
  };

  document_descriptor = {
    renew_stdincome_pdf: {
      url: 'document/generate_renew_stdincome_pdf',
      name: '算定基礎届',
      type: 'pdf',
      rollover: {
        key: 'employee_ids',
        count: 100,
      },
      processing_dialog: {},
    },
    renew_stdincome_csv: {
      url: 'document/generate_renew_stdincome_csv',
      name: 'SHFD0006',
      type: 'csv',
      processing_dialog: {},
    },
  };

  reflect_type: number = 0;
  step: number = 0.1;
}