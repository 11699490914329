
  import { Component, Mixins } from "vue-property-decorator";
  import mixinNotification from "@/mixins/mixinNotification";
  import UtilPopup from "@/components/util/Popup.vue";
  import CommonProgress from "@/components/common/Progress.vue";

  @Component({
    components: {
      UtilPopup,
      CommonProgress,
    },
  })
  export default class Main extends Mixins(mixinNotification) {
    get backgroundcolor() {
      if (this.m.tmp_objects["notification_id"] && this.single) {
        if (this.single.notification.importance == 0) {
          return "white";
        } else if (this.single.notification.importance == 1) {
          return "rgb(255, 255, 195)";
        } else if (this.single.notification.importance == 2) {
          return "rgb(255, 195, 195)";
        }
      }
      return "white";
    }
  }
