import VuexManagerControl from "@/vuex/VuexManagerControl";
import {validationIndividualunit} from "@/validations/employee_each/individualunit";

export default class Manager extends VuexManagerControl {

  update_descriptor = {
    each_individual_unit: {
      url: 'employee_each/update_each_individual_unit',
      name: '個人別単価',
      validate: validationIndividualunit,
      dialog: {},
      processing_dialog: {},
      finish_dialog: {},
    },
  };
}