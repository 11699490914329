import VuexManagerControl from "@/vuex/VuexManagerControl";
import {validationIndividualunit} from "@/validations/config/individualunit";

export default class Manager extends VuexManagerControl {
  create_descriptor = {
    individual_unit: {
      url: 'config/create_individual_unit',
      name: '個人別単価',
      validate: validationIndividualunit,
      dialog: {},
      processing_dialog: {},
      finish_dialog: {},
    },
  };

  update_descriptor = {
    individual_unit: {
      url: 'config/update_individual_unit',
      name: '個人別単価',
      validate: validationIndividualunit,
      dialog: {},
      processing_dialog: {},
      finish_dialog: {},
    },
  };

  delete_descriptor = {
    individual_unit: {
      url: 'config/delete_individual_unit',
      name: '個人別単価',
      dialog: {},
      processing_dialog: {},
      finish_dialog: {},
    }
  }
}