import { Mutation, MutationAction, Action, VuexModule, getModule, Module } from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import yearly from "@/vuex/nencho/yearly";
import employee_yearly from "@/vuex/nencho/employee_yearly";
import insurance_yearly from "@/vuex/nencho/insurance_yearly";
import prev_payment_yearly from "@/vuex/nencho/prev_payment_yearly";
import loan_yearly from "@/vuex/nencho/loan_yearly";
import global from "@/vuex/nencho/global";
import * as util from "@/util";
import validate from "@/validations/validate";
import {validationNenchoMasters} from "@/validations/nencho";
import dialog from "@/vuex/dialog";
import Vue from 'vue';
import router from "@/router";
import node from "@/vuex/node";
import vuex_manager from '@/vuex/vuex_manager';


@Module({ dynamic: true, store, name: "nencho_manager", namespaced: true })
class Manager extends VuexModule {
  disp_num: number = 10;
  edit_nencho_masters_flag: boolean = false;

  is_open_nencho_master_dialog: boolean = false;
  is_open_reflect_dialog: number = 0;
  is_open_reflects_dialog: number = 0;
  is_open_nencho_excel_dialog: boolean = false;

  reflect_type: number = 0;

  nencho_masters: any = {};
  nencho_master: any = {};

  step: number = 0.1;


  //Insurance
  life_insurance_num: number = 0;
  care_insurance_num: number = 0;
  pension_insurance_num: number = 0;
  earthquake_insurance_num: number = 0;
  social_insurance_num: number = 0;
  mutualaid_insurance_num: number = 0;

  life_insurances: any = [];
  care_insurances: any = [];
  pension_insurances: any = [];
  earthquake_insurances: any = [];
  social_insurances: any = [];
  mutualaid_insurances: any = [];

  //PrevPayment
  prev_payment_num: number = 0;

  prev_payments: any = [];

  //Loan
  new_loan_num: number = 0;
  reform_loan_num: number = 0;

  new_loans: any = [];
  reform_loans: any = [];

  @Mutation
  public setIsOpenNenchoExcelDialog(val) {
    this.is_open_nencho_excel_dialog = val;
  }

  @Mutation
  public setStep(val) {
    this.step = val;
  }

  @Mutation
  public setDispNum(val) {
    this.disp_num = val;
  }

  @Action({ rawError: true })
  public cancelAll() {
    this.setEditNenchoMastersFlag(false);
  }

  @Action({ rawError: true })
  public async is_open_input() {
    return this.edit_nencho_masters_flag;
  }

  
  @Mutation
  public setReflectType(val: number) {
    this.reflect_type = val;
  }

  @Mutation
  private setEditNenchoMastersFlag(val: boolean) {
    if (!val) {
      Vue.set(this, 'nencho_masters', {});
    }
    this.edit_nencho_masters_flag = val;
  }
  @Mutation
  public setIsOpenNenchoMasterDialog(val: boolean) {
    this.is_open_nencho_master_dialog = val;
  }
  @Mutation
  public setIsOpenReflectDialog(val: number) {
    this.is_open_reflect_dialog = val;
  }
  @Mutation
  public setIsOpenReflectsDialog(val: number) {
    this.is_open_reflects_dialog = val;
  }

  
  



  @Action({ rawError: true })
  public createNencho({payment_info, bonus_info}: {payment_info: any, bonus_info: any}) {
    if (payment_info === null) {
      //給与がそもそもない(まだ勤怠も何も開始していない)つまり未ロック
      dialog.openConfirmDialog({msg: '年末調整を開始してよろしいですか？', detail: '全従業員にメールが送信されます', cancel_button: '開始しない', exec_button: '開始する', func: this.createNenchoProcess, args: {}, is_next_dialog: true});
    } else if (payment_info.lock_type == 2) {
      //給与はロック済み
      if (bonus_info === null) {
        //しかも賞与はない
        //つまり源泉徴収票が既に出せる
        dialog.openConfirmDialog({msg: '既に12月給与がロックされています。', detail: '既に源泉徴収票を各社員が出力可能な状態です。<br>この状態で年末調整を開始すると、既に出力済みの源泉徴収票は正しくないものとなります。<br>それでも、年末調整を開始しますか？', cancel_button: '開始しない', exec_button: '開始する', func: this.createNenchoProcess, args: {}, is_next_dialog: true});
      } else if (bonus_info.lock_type == 2) {
        //賞与があり、ロック済み
        //つまり源泉徴収票が既に出せる
        dialog.openConfirmDialog({msg: '既に12月給与・賞与がロックされています。', detail: '既に源泉徴収票を各社員が出力可能な状態です。<br>この状態で年末調整を開始すると、既に出力済みの源泉徴収票は正しくないものとなります。<br>それでも、年末調整を開始しますか？', cancel_button: '開始しない', exec_button: '開始する', func: this.createNenchoProcess, args: {}, is_next_dialog: true});
      } else {
        //賞与はあるが、ロックされていない
        //つまり、源泉徴収票は出せていない
        dialog.openConfirmDialog({msg: '年末調整を開始してよろしいですか？', detail: '全従業員にメールが送信されます', cancel_button: '開始しない', exec_button: '開始する', func: this.createNenchoProcess, args: {}, is_next_dialog: true});
      }
    } else {
      //給与が未ロック
      dialog.openConfirmDialog({msg: '年末調整を開始してよろしいですか？', detail: '全従業員にメールが送信されます', cancel_button: '開始しない', exec_button: '開始する', func: this.createNenchoProcess, args: {}, is_next_dialog: true});
    }
  }
  @Action({ rawError: true })
  public async createNenchoProcess({}) {
    dialog.openProgressDialog({title: '開始中', msg: 'この処理には時間がかかることがあります。'});
    await util.post('nencho/create_nencho', {});
    node.disable();
    dialog.openAlertDialog({msg: '年末調整を開始しました'});
  }



  @Action({ rawError: true })
  public async editNenchoMasters() {
    if (await this.is_open_input()) return;
    this.setEditNenchoMastersFlag(true);
  }

  @Action({ rawError: true })
  public updateNenchoMasters({nencho_id, branch_id, employee_hash}: {nencho_id: number, branch_id: number, employee_hash: any}) {
    if (validate(validationNenchoMasters(this.nencho_masters, employee_hash))) {
      dialog.openConfirmDialog({msg: '年末調整に使用する給与・賞与情報等を更新してよろしいですか？', cancel_button: '更新しない', exec_button: '更新する', func: this.updateNenchoMastersProcess, is_next_dialog: true, args: {nencho_id, branch_id}});
    }
  }
  @Action({ rawError: true })
  public async updateNenchoMastersProcess({nencho_id, branch_id}: {nencho_id: number, branch_id: number}) {
    dialog.openProgressDialog({title: '更新中', msg: 'この処理には時間がかかることがあります。'});
    await util.post('nencho/update_nencho_masters', {nencho_id, branch_id, nencho_masters: this.nencho_masters});
    this.cancelAll();
    node.disable();
    dialog.openAlertDialog({msg: '更新が完了しました'});
  }


  @Action({ rawError: true })
  public updateNenchoMasterOption({nencho_id, employee_id}: {nencho_id: number, employee_id: number}) {
    dialog.openConfirmDialog({msg: '年末調整項目選択を確定してよろしいですか？', cancel_button: '確定しない', exec_button: '確定する', func: this.updateNenchoMasterOptionProcess, is_next_dialog: true, args: {nencho_id, employee_id}});
  }
  @Action({ rawError: true })
  public async updateNenchoMasterOptionProcess({nencho_id, employee_id}: {nencho_id: number, employee_id: number}) {
    dialog.openProgressDialog({title: '年末調整開始中', msg: '年末調整の準備をしております'});
    await util.post('nencho/update_nencho_master_option', {nencho_id, employee_id, nencho_master: this.nencho_master});
    this.cancelAll();
    node.disable();
    this.setIsOpenNenchoMasterDialog(false);
    dialog.openAlertDialog({msg: '年末調整を行う準備が完了しました。', detail: '本人情報から回答を開始してください'});
  }


  @Action({ rawError: true })
  public updateNenchoMastersEmployee({nencho_id, branch_id, branch_name}: {nencho_id: number, branch_id: number, branch_name: string}) {
    var branch_name_str = branch_name+'に主所属として';
    if (branch_id == 0) {
      branch_name_str = '';
    }
    dialog.openConfirmDialog({msg: '年末調整開始後に'+branch_name_str+'入社した社員を追加してよろしいですか？', cancel_button: '追加しない', exec_button: '追加する', func: this.updateNenchoMastersEmployeeProcess, is_next_dialog: true, args: {nencho_id, branch_id}});
  }
  @Action({ rawError: true })
  public async updateNenchoMastersEmployeeProcess({nencho_id, branch_id}: {nencho_id: number, branch_id: number}) {
    dialog.openProgressDialog({title: '処理中', msg: 'しばらくお待ちください'});
    await util.post('nencho/update_nencho_master_employee', {nencho_id, branch_id});
    this.cancelAll();
    node.disable();
    this.setIsOpenNenchoMasterDialog(false);
    dialog.openAlertDialog({msg: '完了しました。'});
  }


  @Action({ rawError: true })
  public async nencho_csv({branch_id, nencho_id, year}: {branch_id: number, nencho_id: number, year: number}) {
    dialog.openProgressDialog({title: '作成中', msg: '作成には時間がかかることがあります。'});
    await util.post_document('document/generate_nencho_csv', {nencho_id, branch_id}, year+'年末調整データ.csv');
  }
  

  


  @Mutation
  setNenchoMaster({employee_id, nencho_master_object}: {employee_id: number, nencho_master_object: any}) {
    Vue.set(this.nencho_masters, employee_id, nencho_master_object);
  }
  @Mutation
  setNenchoMasterOption({nencho_master}: {nencho_master: any}) {
    Vue.set(this, 'nencho_master', nencho_master);
  }



  //Insurance
  @Mutation
  setLifeInsuranceNum({num}: {num: number}) {
    this.life_insurance_num = num;
  }
  @Mutation
  setCareInsuranceNum({num}: {num: number}) {
    this.care_insurance_num = num;
  }
  @Mutation
  setPensionInsuranceNum({num}: {num: number}) {
    this.pension_insurance_num = num;
  }
  @Mutation
  setEarthquakeInsuranceNum({num}: {num: number}) {
    this.earthquake_insurance_num = num;
  }
  @Mutation
  setSocialInsuranceNum({num}: {num: number}) {
    this.social_insurance_num = num;
  }
  @Mutation
  setMutualaidInsuranceNum({num}: {num: number}) {
    this.mutualaid_insurance_num = num;
  }


  @Mutation
  setLifeInsurance({index, life_insurance}) {
    Vue.set(this.life_insurances, index, life_insurance);
  }
  @Mutation
  setCareInsurance({index, care_insurance}) {
    Vue.set(this.care_insurances, index, care_insurance);
  }
  @Mutation
  setPensionInsurance({index, pension_insurance}) {
    Vue.set(this.pension_insurances, index, pension_insurance);
  }
  @Mutation
  setEarthquakeInsurance({index, earthquake_insurance}) {
    Vue.set(this.earthquake_insurances, index, earthquake_insurance);
  }
  @Mutation
  setSocialInsurance({index, social_insurance}) {
    Vue.set(this.social_insurances, index, social_insurance);
  }
  @Mutation
  setMutualaidInsurance({index, mutualaid_insurance}) {
    Vue.set(this.mutualaid_insurances, index, mutualaid_insurance);
  }

  @Mutation
  setLifeInsurances({life_insurances}) {
    Vue.set(this, 'life_insurances', life_insurances);
  }
  @Mutation
  setCareInsurances({care_insurances}) {
    Vue.set(this, 'care_insurances', care_insurances);
  }
  @Mutation
  setPensionInsurances({pension_insurances}) {
    Vue.set(this, 'pension_insurances', pension_insurances);
  }
  @Mutation
  setEarthquakeInsurances({earthquake_insurances}) {
    Vue.set(this, 'earthquake_insurances', earthquake_insurances);
  }
  @Mutation
  setSocialInsurances({social_insurances}) {
    Vue.set(this, 'social_insurances', social_insurances);
  }
  @Mutation
  setMutualaidInsurances({mutualaid_insurances}) {
    Vue.set(this, 'mutualaid_insurances', mutualaid_insurances);
  }

  @Mutation
  deleteLifeInsurance({index}) {
    this.life_insurances.splice(index, 1);
  }
  @Mutation
  deleteCareInsurance({index}) {
    this.care_insurances.splice(index, 1);
  }
  @Mutation
  deletePensionInsurance({index}) {
    this.pension_insurances.splice(index, 1);
  }
  @Mutation
  deleteEarthquakeInsurance({index}) {
    this.earthquake_insurances.splice(index, 1);
  }
  @Mutation
  deleteSocialInsurance({index}) {
    this.social_insurances.splice(index, 1);
  }
  @Mutation
  deleteMutualaidInsurance({index}) {
    this.mutualaid_insurances.splice(index, 1);
  }



  @Action({ rawError: true })
  public async updateNenchoInsurance({nencho_id, employee_id, query}: {nencho_id: number, employee_id: number, query: any}) {
    dialog.openForceConfirmDialog({msg: '確定よろしいですか？', cancel_button: '確定しない', exec_button: '確定する', func: this.updateNenchoInsuranceProcess, is_next_dialog: true, args: {nencho_id, employee_id, query}});
  }
  @Action({ rawError: true })
  public async updateNenchoInsuranceProcess({nencho_id, employee_id, query}: {nencho_id: number, employee_id: number, query: any}) {
    router.push({name: 'nencho_insurance', query: {...query, is_finish_insurance: 'true'}})
    dialog.openProgressDialog({title: '送信中', msg: 'この処理には時間がかかることがあります。'});
    await util.post('nencho/update_nencho_insurance', {nencho_id, employee_id, 
      life_insurances: this.life_insurances,
      care_insurances: this.care_insurances,
      pension_insurances: this.pension_insurances,
      earthquake_insurances: this.earthquake_insurances,
      social_insurances: this.social_insurances,
      mutualaid_insurances: this.mutualaid_insurances,
    });
    node.disable();
    this.setStep(10);
    dialog.closeDialog();
  }






  //prev_payment
  @Mutation
  setPrevPaymentNum({num}: {num: number}) {
    this.prev_payment_num = num;
  }

  @Mutation
  setPrevPayment({index, prev_payment}) {
    Vue.set(this.prev_payments, index, prev_payment);
  }
  @Mutation
  setPrevPayments({prev_payments}) {
    Vue.set(this, 'prev_payments', prev_payments);
  }


  @Mutation
  deletePrevPayment({index}) {
    this.prev_payments.splice(index, 1);
  }



  @Action({ rawError: true })
  public async updateNenchoPrevPayment({nencho_id, employee_id, query}: {nencho_id: number, employee_id: number, query: any}) {
    dialog.openForceConfirmDialog({msg: '確定よろしいですか？', cancel_button: '確定しない', exec_button: '確定する', func: this.updateNenchoPrevPaymentProcess, is_next_dialog: true, args: {nencho_id, employee_id, query}});
  }
  @Action({ rawError: true })
  public async updateNenchoPrevPaymentProcess({nencho_id, employee_id, query}: {nencho_id: number, employee_id: number, query: any}) {
    router.push({name: 'nencho_prev_payment', query: {...query, is_finish_prev_payment: 'true'}})
    dialog.openProgressDialog({title: '送信中', msg: 'この処理には時間がかかることがあります。'});
    await util.post('nencho/update_nencho_prev_payment', {nencho_id, employee_id, 
      prev_payments: this.prev_payments,
    });
    node.disable();
    this.setStep(10);
    dialog.closeDialog();
  }




  //loan
  @Mutation
  setNewLoanNum({num}: {num: number}) {
    this.new_loan_num = num;
  }
  @Mutation
  setNewLoan({index, new_loan}) {
    Vue.set(this.new_loans, index, new_loan);
  }
  @Mutation
  setNewLoans({new_loans}) {
    Vue.set(this, 'new_loans', new_loans);
  }
  @Mutation
  deleteNewLoan({index}) {
    this.new_loans.splice(index, 1);
  }

  @Mutation
  setReformLoanNum({num}: {num: number}) {
    this.reform_loan_num = num;
  }
  @Mutation
  setReformLoan({index, reform_loan}) {
    Vue.set(this.reform_loans, index, reform_loan);
  }
  @Mutation
  setReformLoans({reform_loans}) {
    Vue.set(this, 'reform_loans', reform_loans);
  }
  @Mutation
  deleteReformLoan({index}) {
    this.reform_loans.splice(index, 1);
  }



  @Action({ rawError: true })
  public async updateNenchoLoan({nencho_id, employee_id, query}: {nencho_id: number, employee_id: number, query: any}) {
    dialog.openForceConfirmDialog({msg: '確定してよろしいですか？', cancel_button: '確定しない', exec_button: '確定する', func: this.updateNenchoLoanProcess, is_next_dialog: true, args: {nencho_id, employee_id, query}});
  }
  @Action({ rawError: true })
  public async updateNenchoLoanProcess({nencho_id, employee_id, query}: {nencho_id: number, employee_id: number, query: any}) {
    router.push({name: 'nencho_loan', query: {...query, is_finish_loan: 'true'}})
    dialog.openProgressDialog({title: '送信中', msg: 'この処理には時間がかかることがあります。'});
    await util.post('nencho/update_nencho_loan', {nencho_id, employee_id, 
      new_loans: this.new_loans,
      reform_loans: this.reform_loans,
    });
    node.disable();
    this.setStep(10);
    dialog.closeDialog();
  }


  @Action({ rawError: true })
  public async updateNenchoMasterValid({nencho_id, branch_id, employee_id, is_valid}: {nencho_id: number, branch_id: number, employee_id: number, is_valid: boolean}) {
    await util.post('nencho/update_nencho_master_valid', {nencho_id, branch_id, employee_id, is_valid});
  }

  @Action({ rawError: true })
  public async reflect({nencho_id, employee_id}: {nencho_id: number, employee_id: number}) {
    dialog.openForceConfirmDialog({msg: '反映してよろしいですか？', detail: 'ロック済みの給与にのみ反映可能です。<br>源泉徴収票が出力可能になります。', cancel_button: '反映しない', exec_button: '反映する', func: this.reflectProcess, is_next_dialog: true, args: {nencho_id, employee_id}});
  }
  @Action({ rawError: true })
  public async reflectProcess({nencho_id, employee_id}: {nencho_id: number, employee_id: number}) {
    dialog.openProgressDialog({title: '反映中', msg: 'この処理には時間がかかることがあります。'});
    var res = await util.post('nencho/reflect_nencho', {nencho_id, employee_id, reflect_type: this.reflect_type});
    node.disable();
    dialog.closeDialog();
    this.setIsOpenReflectDialog(0);
    if (res.data.code == 0) {
      dialog.openAlertDialog({msg: '反映が完了しました'});
    } else {
      dialog.openErrorDialog({error: ['給与が存在しないか、ロックされていません。'], text_align: 'center'});
    }
  }

  @Action({ rawError: true })
  public async reflects({nencho_id, branch_id}: {nencho_id: number, branch_id: number}) {
    dialog.openForceConfirmDialog({msg: '反映してよろしいですか？', detail: '反映が可能な社員の反映処理が行われます。<br>確定が可能な社員(乙の場合など)も確定処理が行われます。<br>ロック済みの給与にのみ反映可能です。<br>源泉徴収票が出力可能になります。', cancel_button: '反映しない', exec_button: '反映する', func: this.reflectsProcess, is_next_dialog: true, args: {nencho_id, branch_id}});
  }
  @Action({ rawError: true })
  public async reflectsProcess({nencho_id, branch_id}: {nencho_id: number, branch_id: number}) {
    dialog.openProgressDialog({title: '反映中', msg: 'この処理には時間がかかることがあります。'});
    var res = await util.post('nencho/reflect_nenchos', {nencho_id, branch_id, reflect_type: this.reflect_type});
    node.disable();
    dialog.closeDialog();
    this.setIsOpenReflectsDialog(0);
    if (res.data.code == 0) {
      dialog.openAlertDialog({msg: '反映が完了しました'});
    } else {
      dialog.openErrorDialog({error: ['給与が存在しないか、ロックされていません。'], text_align: 'center'});
    }
  }

  @Action({ rawError: true })
  public async unreflect({nencho_id, employee_id}: {nencho_id: number, employee_id: number}) {
    dialog.openForceConfirmDialog({msg: '反映を取り消してよろしいですか？', detail: '支払い済みの給与に反映されたものは絶対に取り消さないでください。', cancel_button: '取り消さない', exec_button: '取り消す', func: this.unreflectConfirm, is_next_dialog: true, args: {nencho_id, employee_id}});
  }
  @Action({ rawError: true })
  public unreflectConfirm({nencho_id, employee_id}: {nencho_id: number, employee_id: number}) {
    dialog.openForceConfirmDialog({msg: '本当によろしいですか？', detail: '支払い済みの給与に反映されたものは絶対に取り消さないでください。<br>本当によろしいですか？', cancel_button: '取り消さない', exec_button: '取り消す', func: this.unreflectProcess, is_next_dialog: true, args: {nencho_id, employee_id}});
  }
  @Action({ rawError: true })
  public async unreflectProcess({nencho_id, employee_id}: {nencho_id: number, employee_id: number}) {
    dialog.openProgressDialog({title: '反映取消中', msg: 'この処理には時間がかかることがあります。'});
    var res = await util.post('nencho/unreflect_nencho', {nencho_id, employee_id});
    node.disable();
    dialog.closeDialog();
    if (res.data.code == 0) {
      dialog.openAlertDialog({msg: '反映を取り消しました'});
    } else {
      dialog.openErrorDialog({error: ['給与が存在しません。'], text_align: 'center'});
    }
  }

  @Action({ rawError: true })
  public async updateNenchoExcel({excel, branch_id, nencho_id}: {excel, branch_id: number, nencho_id: number}) {
    if (!excel) {
      validate(['Excelファイルを選択してください']);
    } else {
      dialog.openForceConfirmDialog({msg: '更新を実行してよろしいですか？', cancel_button: '更新しない', exec_button: '更新する', func: this.updateNenchoExcelProcess, is_next_dialog: true, args: {excel, branch_id, nencho_id}});
    }
  }
  @Action({ rawError: true })
  public async updateNenchoExcelProcess({excel, branch_id, nencho_id}: {excel, branch_id: number, nencho_id: number}) {
    dialog.openProgressDialog({title: '更新中', msg: 'この処理には時間がかかることがあります。', detail: 'そのままお待ちください。'});
    const res = await util.post_file('nencho/update_nencho_masters_excel', {excel, branch_id, nencho_id});
    if (res.data.error_message) {
      dialog.openErrorAlertDialog({msg: res.data.error_message});
    } else if (res.data.soft_error_message) {
      dialog.openErrorForceConfirmDialog({msg: '以下のエラー行をスキップして実行しますか？', error: res.data.soft_error_message, cancel_button: '実行しない', exec_button: '強制実行する', func: this.updateNenchoExcelForceProcess, is_next_dialog: true, args: {excel, branch_id, nencho_id}});
    } else {
      this.setIsOpenNenchoExcelDialog(false);
      dialog.openAlertDialog({msg: '年末調整情報の更新が完了しました'});
      node.disable();
    }
  }
  @Action({ rawError: true })
  public async updateNenchoExcelForceProcess({excel, branch_id, nencho_id}: {excel, branch_id: number, nencho_id: number}) {
    dialog.openProgressDialog({title: '更新中', msg: 'この処理には時間がかかることがあります。', detail: 'そのままお待ちください。'});
    const res = await util.post_file('nencho/update_nencho_masters_excel', {excel, branch_id, nencho_id, is_force: true});
    if (res.data.error_message) {
      dialog.openErrorAlertDialog({msg: res.data.error_message});
    } else {
      this.setIsOpenNenchoExcelDialog(false);
      dialog.openAlertDialog({msg: '年末調整情報の更新が完了しました'});
      node.disable();
    }
  }

  @Action({ rawError: true })
  public async updateNenchoYayoi({csv1, csv2, csv3, csv4, csv5, csv6, csv7, csv8, csv9, csv10, csv11, csv12, csv13, csv14, csv15, csv16, branch_id, nencho_id}: {csv1, csv2, csv3, csv4, csv5, csv6, csv7, csv8, csv9, csv10, csv11, csv12, csv13, csv14, csv15, csv16, branch_id: number, nencho_id: number}) {
    dialog.openForceConfirmDialog({msg: '更新を実行してよろしいですか？', detail: 'csvファイルが選択された月のみ更新されます。', cancel_button: '更新しない', exec_button: '更新する', func: this.updateNenchoYayoiProcess, is_next_dialog: true, args: {csv1, csv2, csv3, csv4, csv5, csv6, csv7, csv8, csv9, csv10, csv11, csv12, csv13, csv14, csv15, csv16, branch_id, nencho_id}});
  }
  @Action({ rawError: true })
  public async updateNenchoYayoiProcess({csv1, csv2, csv3, csv4, csv5, csv6, csv7, csv8, csv9, csv10, csv11, csv12, csv13, csv14, csv15, csv16, branch_id, nencho_id}: {csv1, csv2, csv3, csv4, csv5, csv6, csv7, csv8, csv9, csv10, csv11, csv12, csv13, csv14, csv15, csv16, branch_id: number, nencho_id: number}) {
    dialog.openProgressDialog({title: '更新中', msg: 'この処理には時間がかかることがあります。', detail: 'そのままお待ちください。'});
    const res = await util.post_file('nencho/update_nencho_masters_yayoi', {csv1, csv2, csv3, csv4, csv5, csv6, csv7, csv8, csv9, csv10, csv11, csv12, csv13, csv14, csv15, csv16, branch_id, nencho_id});
    if (res.data.error_message) {
      dialog.openErrorAlertDialog({msg: res.data.error_message});
    } else if (res.data.soft_error_message) {
      dialog.openErrorForceConfirmDialog({msg: '以下のエラー列をスキップして実行しますか？', error: res.data.soft_error_message, cancel_button: '実行しない', exec_button: '強制実行する', func: this.updateNenchoYayoiForceProcess, is_next_dialog: true, args: {csv1, csv2, csv3, csv4, csv5, csv6, csv7, csv8, csv9, csv10, csv11, csv12, csv13, csv14, csv15, csv16, branch_id, nencho_id}});
    } else {
      this.setIsOpenNenchoExcelDialog(false);
      dialog.openAlertDialog({msg: '年末調整情報の更新が完了しました'});
      node.disable();
    }
  }
  @Action({ rawError: true })
  public async updateNenchoYayoiForceProcess({csv1, csv2, csv3, csv4, csv5, csv6, csv7, csv8, csv9, csv10, csv11, csv12, csv13, csv14, csv15, csv16, branch_id, nencho_id}: {csv1, csv2, csv3, csv4, csv5, csv6, csv7, csv8, csv9, csv10, csv11, csv12, csv13, csv14, csv15, csv16, branch_id: number, nencho_id: number}) {
    dialog.openProgressDialog({title: '更新中', msg: 'この処理には時間がかかることがあります。', detail: 'そのままお待ちください。'});
    const res = await util.post_file('nencho/update_nencho_masters_yayoi', {csv1, csv2, csv3, csv4, csv5, csv6, csv7, csv8, csv9, csv10, csv11, csv12, csv13, csv14, csv15, csv16, branch_id, nencho_id, is_force: true});
    if (res.data.error_message) {
      dialog.openErrorAlertDialog({msg: res.data.error_message});
    } else {
      this.setIsOpenNenchoExcelDialog(false);
      dialog.openAlertDialog({msg: '年末調整情報の更新が完了しました'});
      node.disable();
    }
  }

  @Action({ rawError: true })
  public async decide({nencho_id, employee_id}: {nencho_id: number, employee_id: number}) {
    dialog.openForceConfirmDialog({msg: '確定してよろしいですか？', detail: '源泉徴収票が出力可能になります。', cancel_button: '確定しない', exec_button: '確定する', func: this.decideProcess, is_next_dialog: true, args: {nencho_id, employee_id}});
  }
  @Action({ rawError: true })
  public async decideProcess({nencho_id, employee_id}: {nencho_id: number, employee_id: number}) {
    dialog.openProgressDialog({title: '確定中', msg: 'この処理には時間がかかることがあります。'});
    await util.post('nencho/reflect_nencho', {nencho_id, employee_id, reflect_type: 10});
    node.disable();
    dialog.closeDialog();
    this.setIsOpenReflectDialog(0);
    dialog.openAlertDialog({msg: '確定が完了しました'});
  }

  @Action({ rawError: true })
  public async decide_force({nencho_id, employee_id}: {nencho_id: number, employee_id: number}) {
    dialog.openForceConfirmDialog({msg: '強制的に確定してよろしいですか？', detail: 'まだ年末調整の入力が完了していませんが、乙欄のため強制的に確定することができます。<br>確定後は源泉徴収票が出力可能になります。<br>本人情報等を現在の情報で確定してよろしいですか？', cancel_button: '確定しない', exec_button: '確定する', func: this.decideForceProcess, is_next_dialog: true, args: {nencho_id, employee_id}});
  }
  @Action({ rawError: true })
  public async decideForceProcess({nencho_id, employee_id}: {nencho_id: number, employee_id: number}) {
    dialog.openProgressDialog({title: '確定中', msg: 'この処理には時間がかかることがあります。'});
    await util.post('nencho/reflect_nencho', {nencho_id, employee_id, reflect_type: 11});
    node.disable();
    dialog.closeDialog();
    this.setIsOpenReflectDialog(0);
    dialog.openAlertDialog({msg: '確定が完了しました'});
  }
  
  @Action({ rawError: true })
  public async decide_force_retire({nencho_id, employee_id}: {nencho_id: number, employee_id: number}) {
    dialog.openForceConfirmDialog({msg: '確定してよろしいですか？', detail: '確定すると、このページで入力した金額が源泉徴収票に反映され、<br>過去に出力されたものと金額にズレが生じる可能性がありますがよろしいですか？', cancel_button: '確定しない', exec_button: '確定する', func: this.decideForceRetireProcess, is_next_dialog: true, args: {nencho_id, employee_id}});
  }
  @Action({ rawError: true })
  public async decideForceRetireProcess({nencho_id, employee_id}: {nencho_id: number, employee_id: number}) {
    dialog.openProgressDialog({title: '確定中', msg: 'この処理には時間がかかることがあります。'});
    await util.post('nencho/reflect_nencho', {nencho_id, employee_id, reflect_type: 11});
    node.disable();
    dialog.closeDialog();
    this.setIsOpenReflectDialog(0);
    dialog.openAlertDialog({msg: '確定が完了しました'});
  }

  @Action({ rawError: true })
  public async undecide({nencho_id, employee_id}: {nencho_id: number, employee_id: number}) {
    dialog.openForceConfirmDialog({msg: '確定を取り消してよろしいですか？', cancel_button: '取り消さない', exec_button: '取り消す', func: this.undecideProcess, is_next_dialog: true, args: {nencho_id, employee_id}});
  }
  @Action({ rawError: true })
  public async undecideProcess({nencho_id, employee_id}: {nencho_id: number, employee_id: number}) {
    dialog.openProgressDialog({title: '確定取消中', msg: 'この処理には時間がかかることがあります。'});
    await util.post('nencho/unreflect_nencho', {nencho_id, employee_id});
    node.disable();
    dialog.closeDialog();
    dialog.openAlertDialog({msg: '反映を取り消しました'});
  }

  @Action({ rawError: true })
  public async updateNenchoEmployee({nencho_id, employee_id, employee, spouce, dependants, query}: {nencho_id: number, employee_id: number, employee: any, spouce: any, dependants: any, query: any}) {
    dialog.openForceConfirmDialog({msg: '確定よろしいですか？', cancel_button: '確定しない', exec_button: '確定する', func: this.updateNenchoEmployeeProcess, is_next_dialog: true, args: {nencho_id, employee_id, employee, spouce, dependants, query}});
  }
  @Action({ rawError: true })
  public async updateNenchoEmployeeProcess({nencho_id, employee_id, employee, spouce, dependants, query}: {nencho_id: number, employee_id: number, employee: any, spouce: any, dependants: any, query: any}) {
    router.push({name: 'nencho_employee', query: {...query, is_finish_employee: 'true'}})
    dialog.openProgressDialog({title: '送信中', msg: 'この処理には時間がかかることがあります。'});
    await util.post('nencho/update_nencho_employee', {nencho_id, employee_id, employee, spouce, dependants});
    vuex_manager.nencho_new_manager.setState({name: 'step', val: 10});
    dialog.closeDialog();
  }



  @Action({ rawError: true })
  public async deleteNencho({nencho_id}: {nencho_id: number}) {
    dialog.openForceConfirmDialog({msg: '年末調整を削除してよろしいですか？', detail: '年末調整を削除すると、各社員が入力した保険料、前職分の給与、住宅ローン控除などの情報が全て削除されます。<br>本人情報確認で変更された社員情報はそのままです。給与明細へ反映済みの年末調整額は残りますが、年末調整ページで入力した給与や賞与の金額は全て削除されます。<br>一度削除すると、これらを元に戻すことはできません。<br>本当によろしいですか？', cancel_button: '削除しない', exec_button: '全て削除する', func: this.deleteNenchoConfirm, is_next_dialog: true, args: {nencho_id}});
  }
  @Action({ rawError: true })
  public deleteNenchoConfirm({nencho_id}: {nencho_id: number}) {
    dialog.openForceConfirmDialog({msg: '本当に全て削除してよろしいですか？', detail: '各社員が入力した保険料、前職分の給与、住宅ローン控除などの情報が全て削除されます。<br>本人情報確認で変更された社員情報はそのままです。給与明細へ反映済みの年末調整額は残りますが、年末調整ページで入力した給与や賞与の金額は全て削除されます。<br>一度削除すると、これらを元に戻すことはできません。<br>本当によろしいですか？', cancel_button: '削除しない', exec_button: '全て削除する', func: this.deleteNenchoConfirmConfirm, is_next_dialog: true, args: {nencho_id}});
  }
  @Action({ rawError: true })
  public deleteNenchoConfirmConfirm({nencho_id}: {nencho_id: number}) {
    dialog.openForceConfirmDialog({msg: '本当によろしいですか？', detail: '一度削除すると、元に戻すことはできません。<br>本当によろしいですか？', cancel_button: '削除しない', exec_button: '全て削除する', func: this.deleteNenchoProcess, is_next_dialog: true, args: {nencho_id}});
  }
  @Action({ rawError: true })
  public async deleteNenchoProcess({nencho_id}: {nencho_id: number, employee_id: number}) {
    dialog.openProgressDialog({title: '反映取消中', msg: 'この処理には時間がかかることがあります。'});
    await util.post('nencho/delete_nencho', {nencho_id});
    node.disable();
    router.push({name: 'nencho'});
    dialog.closeDialog();
    dialog.openAlertDialog({msg: '年末調整を削除しました。'});
  }



  @Action({ rawError: true })
  public async getSave({nencho_id, employee_id, kind}: {nencho_id: number, employee_id: number, kind: string}) {
    var res = await util.post('nencho/read_save', {nencho_id, employee_id, kind});
    return res.data.save;
  }

  @Action({ rawError: true })
  public async getSaveMaster({nencho_id, employee_id}: {nencho_id: number, employee_id: number}) {
    var res = await util.post('nencho/read_save_master', {nencho_id, employee_id});
    return res.data.save;
  }

  @Action({ rawError: true })
  public async setSave({nencho_id, employee_id, kind, content, is_manager}: {nencho_id: number, employee_id: number, kind: string, content: any, is_manager: boolean}) {
    dialog.openProgressDialog({title: '保存中', msg: 'この処理には時間がかかることがあります。'});
    await util.post('nencho/save_nencho', {nencho_id, employee_id, kind, content});
    dialog.openOptionDialog({title: '確認', msg: '保存しました。', detail: '回答を続けますか？', cancel_button: '中断する', exec_button: '回答を続ける', func: this.continueAnswer, cancel_func: this.cancelAnswer, args: {}, cancel_args: {is_manager}})
  }

  @Action({ rawError: true })
  public async cancelAnswer({is_manager}: {is_manager: boolean}) {
    if (is_manager) {
      router.push({name: 'nencho'});
    } else {
      router.push({name: 'shift'});
    }
  }
  @Action({ rawError: true })
  public async continueAnswer({}) {
    dialog.closeDialog();
  }

  @Action({ rawError: true })
  public async deleteSave({nencho_id, employee_id, kind}: {nencho_id: number, employee_id: number, kind: string}) {
    await util.post('nencho/delete_save_nencho', {nencho_id, employee_id, kind});
  }


  @Action({ rawError: true })
  public sendNenchoReminder({employee_id, nencho_id, employee_name}: {employee_id: number, nencho_id: number, employee_name: string}) {
    dialog.openConfirmDialog({msg: employee_name+'さんへ年末調整催促通知を送信してよろしいですか？', detail: '年末調整開始時と同じメール・アプリ通知が送信されます。', cancel_button: '送信しない', exec_button: '送信する', func: this.sendNenchoReminderProcess, is_next_dialog: true, args: {employee_id, nencho_id}});
  }
  @Action({ rawError: true })
  public async sendNenchoReminderProcess({employee_id, nencho_id}: {employee_id: number, nencho_id: number}) {
    dialog.openProgressDialog({title: '処理中', msg: 'この処理には時間がかかることがあります。'});
    await util.post('nencho/send_nencho_reminder', {employee_id, nencho_id});
    dialog.openAlertDialog({msg: '送信しました。'});
  }
  
  @Action({ rawError: true })
  public async updateIsSpecialLevy({nencho_id, employee_id, is_special_levy}: {nencho_id: number, employee_id: number, is_special_levy: number}) {
    await util.post('nencho/update_nencho_levy', {nencho_id, employee_id, is_special_levy});
    node.disable();
  }
}

export default getModule(Manager);