
  import { Component, Prop, Mixins } from "vue-property-decorator";
  import utilMixins from "@/mixins";

  @Component({
    components: {},
  })
  export default class Header extends Mixins(utilMixins) {
    @Prop() theme;

    get metaInfo() {
      return {
        title: "ARROW",
      };
    }
    logout(event) {
      this.$emit("logout");
    }
    open_info(event) {
      this.$emit("open_info", { title: event.title, msg: event.msg });
    }
    open_alert(event) {
      this.$emit("open_alert", {
        title: event.title,
        msg: event.msg,
        func: event.func,
        error: event.error,
      });
    }
    open_confirm(event) {
      this.$emit("open_confirm", {
        title: event.title,
        msg: event.msg,
        detail: event.detail,
        func: event.func,
        after_msg: event.after_msg,
        after_detail: event.after_detail,
      });
    }
    open_error(event) {
      this.$emit("open_error", { error_msg: event.error_msg });
    }

    get is_login_page() {
      return this.$route.name == "login";
    }
  }
