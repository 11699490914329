import { Mutation, MutationAction, Action, VuexModule, getModule, Module } from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import monthly_demand from "@/vuex/demand_punch/monthly_demand";
import monthly from "@/vuex/attendance_each/monthly";
import * as util from "@/util";
import validate from "@/validations/validate";
import validationDemandPunchShift from "@/validations/demand_punch_shift";
import dialog from "@/vuex/dialog";
import Vue from 'vue';
import node from "@/vuex/node";


@Module({ dynamic: true, store, name: "demand_punch_manager", namespaced: true })
class Manager extends VuexModule {
  add_shift_flag: string | boolean = false;//追加するdateを格納 falseなら編集なし
  edit_shift_flag: number = 0;//shift_idを格納 0なら編集なし

  shift = {// add や edit の際に使用 ver3 の tmp  managerにおく必要がある attendance_eachのrowspan問題
    id: null,
    date: null,
    branch_id: null,
    employee_id: null,
    shift_shift: [],
    shift_punch: [],
    shift_break: [],
  };

  @Mutation
  setShift({shift}) {
    Vue.set(this, 'shift', shift);
  }

  @Mutation
  setCarfare({carfare}) {
    Vue.set(this, 'carfare', carfare);
  }

  @Mutation
  private setAddShiftFlag(date: string | boolean) {
    this.add_shift_flag = date;
  }
  @Mutation
  private setEditShiftFlag(shift_id: number) {
    this.edit_shift_flag = shift_id;
  }
  
  @Action({ rawError: true })
  public cancelAll() {
    this.setAddShiftFlag(false);
    this.setEditShiftFlag(0);
  }
  
  @Action({ rawError: true })
  public async is_open_input() {
    return this.add_shift_flag || this.edit_shift_flag != 0;
  }

  @Action({ rawError: true })
  public async addShift(date: string) {//追加するdateを渡す
    console.log('hello');
    if (await this.is_open_input()) return;
    this.setAddShiftFlag(date);
  }
  @Action({ rawError: true })
  public async editShift(shift_id: number) {
    if (await this.is_open_input()) return;
    this.setEditShiftFlag(shift_id);
  }



  @Action({ rawError: true })
  public createShift({employee_id, date, yearmonth}: {employee_id: number, date: string, yearmonth: string}) {
    if (validate(validationDemandPunchShift(this.shift))) {
      dialog.openConfirmDialog({msg: util.$t('demand_punch_create_confirm'), cancel_button: util.$t('demand_punch_no_send'), exec_button: util.$t('demand_punch_send'), func: this.createShiftProcess, args: {employee_id, date, yearmonth}});
    }
  }
  @Action({ rawError: true })
  public async createShiftProcess({employee_id, date, yearmonth}: {employee_id: number, date: string, yearmonth: string}) {
    monthly_demand.createShift({yearmonth, date, employee_id, shift: this.shift});
    this.cancelAll();
    await util.post('demand_punch/create_demand_punch', {employee_id, date, shift: this.shift});
    node.disable();
  }

  @Action({ rawError: true })
  public updateShift({employee_id, date, yearmonth}: {employee_id: number, date: string, yearmonth: string}) {
    if (validate(validationDemandPunchShift(this.shift))) {
      dialog.openConfirmDialog({msg: util.$t('demand_punch_create_confirm'), cancel_button: util.$t('demand_punch_no_send'), exec_button: util.$t('demand_punch_send'), func: this.updateShiftProcess, args: {employee_id, date, yearmonth}});
    }
  }
  @Action({ rawError: true })
  public async updateShiftProcess({employee_id, date, yearmonth}: {employee_id: number, date: string, yearmonth: string}) {
    monthly_demand.updateShift({yearmonth, date, employee_id, shift: this.shift});
    this.cancelAll();
    await util.post('demand_punch/update_demand_punch', {employee_id, date, shift: this.shift});
    node.disable();
  }

  @Action({ rawError: true })
  public deleteShift({employee_id, date, yearmonth, branch_id}: {employee_id: number, date: string, yearmonth: string, branch_id: number}) {
    dialog.openConfirmDialog({msg: util.$t('demand_punch_create_confirm'), cancel_button: util.$t('demand_punch_no_send'), exec_button: util.$t('demand_punch_send'), func: this.deleteShiftProcess, args: {employee_id, date, yearmonth, branch_id}});
  }
  @Action({ rawError: true })
  public async deleteShiftProcess({employee_id, date, yearmonth, branch_id}: {employee_id: number, date: string, yearmonth: string, branch_id: number}) {
    monthly_demand.deleteShift({yearmonth, date, employee_id, branch_id});
    this.cancelAll();
    await util.post('demand_punch/delete_demand_punch', {employee_id, date, branch_id});
    node.disable();
  }

  @Action({ rawError: true })
  public async cancelShift({branch_id, employee_id, date, yearmonth, demand_punch_id}: {branch_id: number, employee_id: number, date: string, yearmonth: string, demand_punch_id: number}) {
    dialog.openForceConfirmDialog({msg: util.$t('demand_punch_cancel_confirm'), detail: util.$t('demand_punch_cancel_detail'), cancel_button: util.$t('demand_punch_no_cancel'), exec_button: util.$t('demand_punch_yes_cancel'), func: this.cancelShiftProcess, args: {branch_id, employee_id, date, yearmonth, demand_punch_id}});
  }
  @Action({ rawError: true })
  public async cancelShiftProcess({branch_id, employee_id, date, yearmonth, demand_punch_id}: {branch_id: number, employee_id: number, date: string, yearmonth: string, demand_punch_id: number}) {
    monthly_demand.cancelShift({yearmonth, date, employee_id, branch_id, demand_punch_id});
    await util.post('demand_punch/cancel_demand_punch', {branch_id, employee_id, demand_punch_id, date});
    node.disable();
  }

  @Action({ rawError: true })
  public admitDemandPunch({employee_id, yearmonth, demand_punch_id}: {employee_id: number, yearmonth: string, demand_punch_id: number}) {
    dialog.openConfirmDialog({msg: 'この変更を承認してよろしいですか？', detail: '元に戻すことはできません。', cancel_button: '承認しない', exec_button: '承認する', func: this.admitDemandPunchProcess, args: {employee_id, yearmonth, demand_punch_id}});
  }
  @Action({ rawError: true })
  public async admitDemandPunchProcess({employee_id, yearmonth, demand_punch_id}: {employee_id: number, date: string, yearmonth: string, demand_punch_id: number}) {
    await util.post('demand_punch/admit_demand_punch', {employee_id, demand_punch_id});
    node.disable();
  }
  @Action({ rawError: true })
  public denyDemandPunch({employee_id, yearmonth, demand_punch_id}: {employee_id: number, yearmonth: string, demand_punch_id: number}) {
    dialog.openConfirmDialog({msg: 'この変更を拒否してよろしいですか？', detail: '元に戻すことはできません。', cancel_button: '拒否しない', exec_button: '拒否する', func: this.denyDemandPunchProcess, args: {employee_id, yearmonth, demand_punch_id}});
  }
  @Action({ rawError: true })
  public async denyDemandPunchProcess({employee_id, yearmonth, demand_punch_id}: {employee_id: number, date: string, yearmonth: string, demand_punch_id: number}) {
    await util.post('demand_punch/deny_demand_punch', {employee_id, demand_punch_id});
    node.disable();
  }
}

export default getModule(Manager);