
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import utilMixins from '@/mixins';
import dialog from "@/vuex/dialog";
import HeaderJoin from "@/components/header/Join.vue";

@Component({
  components: {
    HeaderJoin,
  }
})
export default class Header extends Mixins(utilMixins) {
 
}

