import * as util from '@/util';

//汎用関数
export function isset(val) {
  if (val === undefined || val === null || val === "") {
    return false;
  } else {
    return true;
  }
}
export function is_number_hyphen(val) {
  if (isset(val)) {
    val = ""+val;
    if (val.match(/^[\-0-9]+$/)) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
}
export function is_number(val) {
  if (isset(val)) {
    val = ""+val;
    if (val.match(/^-?[0-9]+$/)) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
}

export function is_plus_number(val) {
  if (isset(val)) {
    val = ""+val;
    if (val.match(/^[0-9]+$/)) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
}
export function is_no_blank(val) {
  if (isset(val)) {
    val = ""+val;
    if (val.match(/[\s　]/)) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
}
export function is_plus(val) {
  if (is_number(val) || is_float(val)) {
    if (Number(val) > 0) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}
export function is_zeroplus(val) {
  if (is_number(val) || is_float(val)) {
    if (Number(val) >= 0) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  } 
}

export function is_float(val) {
  if (isset(val)) {
    val = ""+val;
    if (val.match(/[^0-9.]+/)) {
      return false;
    } else {
      var spl = val.split('.');
      if (spl.length > 2) {
        return false;
      } else {
        return true;
      }
    }
  } else {
    return true;
  }
}

//validation関数
export function is_zip(val) {
  var zip = val.split('-');
  if (isset(zip[0]) && isset(zip[1]) && is_number(zip[0]) && is_number(zip[1]) && zip[0].length == 3 && zip[1].length == 4) {
    return true;
  } else {
    return false;
  }
}
export function is_equal(val, num) {
  return val.length == num;
}
export function is_split_equal(val, join, num) {
  var name = val.split(join);
  return name.reduce((carry, val) => {
    if (val.length != 0)
      return carry + 1;
  }, 0) == num;
}
export function is_kanji(val) {
  var regexp = /([\u{3005}\u{3007}\u{303b}\u{3400}-\u{9FFF}\u{F900}-\u{FAFF}\u{20000}-\u{2FFFF}][\u{E0100}-\u{E01EF}\u{FE00}-\u{FE02}]?)/mu;
  return regexp.test(val);
}