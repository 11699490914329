
  import { Component, Mixins } from "vue-property-decorator";
  import jQuery from "jquery";
  import * as util from "@/util";
  import utilMixins from "@/mixins";
  import header from "@/vuex/header";
  import dialog from "@/vuex/dialog";
  import { EmployeeHeaderData, BranchHeaderData, CompanyHeaderData } from "@/typesold/header";
  import HeaderNormalMenuButton from "@/components/header/normal/menu/Button.vue";
  import HeaderNormalMenuButtoninner from "@/components/header/normal/menu/Buttoninner.vue";
  const $ = jQuery;

  @Component({
    components: {
      HeaderNormalMenuButton,
      HeaderNormalMenuButtoninner,
    },
  })
  export default class Menu extends Mixins(utilMixins) {
    async download_payment_pdf() {
      dialog.openProgressDialog({
        title: "作成中",
        msg: "作成に時間がかかることがあります。",
        detail: "そのままお待ちください。",
      });
      await util.post_document("document/generate_paymentslip", {}, "給与明細書.pdf");
    }
    async download_bonus_pdf() {
      dialog.openProgressDialog({
        title: "作成中",
        msg: "作成に時間がかかることがあります。",
        detail: "そのままお待ちください。",
      });
      await util.post_document("document/generate_bonusslip", {}, "賞与明細書.pdf");
    }

    get is_plan_a() {
      return this.header.plan == 0 && !this.header.is_full_function;
    }

    unableUseBonus() {
      dialog.openForceConfirmDialog({
        title: "賞与は使用できません。",
        msg: "現在ご利用中のプランAでは賞与機能を使用することはできません。<br>BプランかCプランへ変更のうえご利用ください。",
        cancel_button: "キャンセル",
        exec_button: "プラン変更",
        func: this.changePlan,
        args: {},
      });
    }
    unableUseNencho() {
      dialog.openForceConfirmDialog({
        title: "年末調整は使用できません。",
        msg: "現在ご利用中のプランAでは年末調整機能を使用することはできません。<br>BプランかCプランへ変更のうえご利用ください。",
        cancel_button: "キャンセル",
        exec_button: "プラン変更",
        func: this.changePlan,
        args: {},
      });
    }
    changePlan() {
      this.change_page("OrderChangePlan");
    }

    get is_show_main_button() {
      return header.is_show_main_button;
    }
    get is_show_management_button() {
      return header.is_show_management_button;
    }

    change_show_main_button() {
      header.setIsShowMainButton(!header.is_show_main_button);
      if (header.is_show_main_button) {
        header.setIsShowManagementButton(false);
      }
    }
    change_show_management_button() {
      header.setIsShowManagementButton(!header.is_show_management_button);
      if (header.is_show_management_button) {
        header.setIsShowMainButton(false);
      }
    }

    get name() {
      const employee_name = this.header.employee_name.split(" ");
      return employee_name[0] ? `${employee_name[0]}さん` : "";
    }

    get inform_num() {
      return this.header.informs
        ? Object.keys(this.header.informs).filter((key) => this.header.informs[key].is_new).length
        : 0;
    }
    get system_inform_num() {
      return (
        Object.keys(this.header.system_informs).filter(
          (key) => this.header.system_informs[key].is_new,
        ).length +
        Object.keys(this.header.system_notifications).filter(
          (key) => this.header.system_notifications[key].is_new,
        ).length
      );
    }
    get all_inform_num() {
      let all_inform_num = this.inform_num + this.system_inform_num;
      if (this.header.is_contract_document) {
        all_inform_num++;
      }
      if (this.header.is_nencho_start) {
        all_inform_num++;
      }
      return all_inform_num;
    }

    get login_type() {
      if (this.s_auth == 0) {
        return "マイページ";
      } else if (this.s_auth == 1) {
        return "管理ページ";
      } else if (this.s_auth == 2) {
        return "管理ページ";
      }
    }

    get passquery() {
      return util.passquery;
    }

    logout() {
      header.setIsOpenLogoutDialog(true);
    }
    get is_flex() {
      if (this.s_auth == 0) {
        const header = this.header as EmployeeHeaderData;
        return header.is_flex;
      } else {
        return false;
      }
    }

    open_support_dialog() {
      header.setIsOpenSupportDialog(true);
    }

    get is_show() {
      let is_show = {};
      if (this.s_auth == 2) {
        const header = this.header as CompanyHeaderData;
        is_show = {
          shift: true,
          submit: false,
          attendance: true,
          attendance_each: false,
          gps: false,
          mypc: false,
          payment: true,
          payment_each: false,
          bonus: true,
          bonus_each: false,
          nencho: false,
          // nencho: true,
          nencho_master: false,
          aggregation: false,
          notification: true,
          document: true,
          document_employee: false,
          company: true,
          employee: true,
          employee_each: false,
          config: true,
          demand_overtime: false,
          demand_holiday: false,
          demand_punch: false,
          demand_change: false,
          regist: header.is_not_ordered,
          status: true,
          renew_resident_tax: header.is_renew_resident_tax,
          fixedtaxreduction: true,
          fixed_tax_reduction_master: false,
        };
      } else if (this.s_auth == 1) {
        const header = this.header as BranchHeaderData;
        is_show = {
          shift: true,
          submit: false,
          attendance: true,
          attendance_each: false,
          gps: false,
          mypc: false,
          payment: header.auth_payment != 0,
          payment_each: false,
          bonus: header.auth_bonus != 0,
          bonus_each: false,
          nencho: false && header.auth_nencho != 0,
          nencho_master: false,
          aggregation: false,
          notification: header.auth_notification != 0,
          document: header.auth_document != 0,
          document_employee: false,
          company: header.auth_config_company != 0,
          employee: true,
          employee_each: false,
          config: header.auth_config_company != 0 || header.auth_config_group != 0,
          demand_overtime: false,
          demand_holiday: false,
          demand_punch: false,
          demand_change: false,
          regist: false,
          status: false,
          renew_resident_tax: false,
          fixedtaxreduction: false,
          fixed_tax_reduction_master: false,
        };
      } else if (this.s_auth == 0) {
        const header = this.header as EmployeeHeaderData;
        is_show = {
          shift: true,
          submit: true,
          attendance: false,
          attendance_each: true,
          gps: header.gps_punch,
          mypc: header.mypc_punch,
          payment: false,
          payment_each: this.is_disp_personal_payment && true,
          bonus: false,
          bonus_each: this.is_disp_personal_payment && true,
          nencho: false,
          nencho_master: false,
          // nencho_master: true,
          aggregation: false,
          notification: false,
          document: false,
          document_employee: this.is_disp_personal_payment && true,
          company: false,
          employee: false,
          employee_each: true,
          config: false,
          order_overtime: header.order_overtime,
          order_holiday: header.order_holiday,
          order_punch: header.order_punch,
          order_change: header.order_change,
          regist: false,
          status: false,
          is_renew_resident_tax: false,
          fixedtaxreduction: false,
          fixed_tax_reduction_master: true,
        };
      }
      return is_show;
    }

    async switch_personal_login() {
      window.location.href = "/switchlogin?auth=0";
    }
    async switch_branch_login() {
      window.location.href = "/switchlogin?auth=1";
    }
    async switch_company_login() {
      window.location.href = "/switchlogin?auth=2";
    }
  }

  //ヘッダー固定
  $(function () {
    // メニューのtop座標を取得する
    const offsetTop = 53;

    const floatMenu = function () {
      const nav = $(".main_page_button_wrap");
      // スクロール位置がメニューのtop座標を超えたら固定にする
      if ($(window).scrollTop() > offsetTop) {
        nav.addClass("fixed");
      } else {
        nav.removeClass("fixed");
      }
    };
    $(window).scroll(floatMenu);
    $("body").bind("touchmove", floatMenu);
  });
