import content from "./popup_content";

const ja = {
  week_prescribed_day: '有給休暇の自動計算に<span class="font-red">必須</span>です。',
  week_prescribed_time: '有給休暇の自動計算に<span class="font-red">必須</span>です。',
  month_prescribed_day:
    "ARROWではシフトから自動計算されますが、この項目を入力すると入力された値が常に使用されます。",
  month_prescribed_time:
    "ARROWではシフトから自動計算されますが、この項目を入力すると入力された値が常に使用されます。",
  joined_on: '有給休暇の自動計算に<span class="font-red">必須</span>です。',
  retired_on: "入力された日付から退職者として扱われます。",
  child_care: "入力期間中は、健康・介護・厚生年金保険料が自動的に0円になります。",
  social_ins_id:
    "社会保険の被保険者に割り振られている番号です。<br>健康保険証の「番号」の箇所に書かれている数字が被保険者番号です。",
  short_of_attendance:
    "有給休暇の計算期間における出勤率が80%未満のため、有給休暇が付与されません。<br>出勤率は週所定労働日数に基づいて、以下の式で計算されます。<br><br>(出勤率) = (勤務・有給休暇使用などの日数) / (計算期間の所定労働日数)<br><br>(計算期間の所定労働日数) = ((週所定労働日数) / 7(1週間の日数)) × (計算期間の日数)<br><br>※「計算期間」について、入社後初めて与えられる有給休暇の場合は入社してから半年間、それ以降の有給休暇の場合は前回の付与から1年間が計算期間となります。",
  dependant_num:
    '税制上の扶養人数と実際の扶養人数は異なることがあります。<br>詳しくは<a target="__blank" href="https://help.arrow-payment.com/law/dependantnum/">こちら</a>をご覧ください。',
  employee_type:
    'ARROWでの従業員の用途です。<br>用途によって、社員情報の必須項目が変化します。<br>※あくまで社員情報の入力項目を増減させるための機能であり、用途以外のページにも表示されます。<br>詳しくは<a target="__blank" href="https://help.arrow-payment.com/employee/employee_each/type/">こちら</a>をご覧ください。',
  socialins_seiri_code:
    "保険料納入告知額・領収済額通知書に記載されている、(2桁の数字)-(4文字以内の英数字カタカナひらがな)の記号です。<br>ひらがなの場合はカタカナに変換して入力してください。",
  socialins_office_num: "保険料納入告知額・領収済額通知書に記載されている5桁の番号です。",
  empins_office_num:
    "雇用保険適用事業所設置届事業主控などに記載されている、(4桁)-(6桁)-(1桁)の番号です。",

  work_ins_kind: "労災保険用の事業の種類の分類です。",
  work_ins_type: "労災保険用の事業の種類です。",
  special_biz:
    "次の条件に該当する場合には、週の法定労働時間を44時間にすることができます。<br>①常時使用する労働者が10人未満の事業場<br>②適用業種<br>(ⅰ)商業（卸売業、小売業、理美容業、倉庫業、駐車場業、不動産管理業、出版業（印刷部門を除く）、その他の商業）。<br>(ⅱ)映画・演劇業（映画の映写（映画の製作の事業を除く）、演劇、その他興業の事業）。<br>(ⅲ)保健衛生業（病院、診療所、保育園、老人ホームなどの社会福祉施設、浴場業（個室付き浴場業を除く）、その他の保健衛生業）。<br>(ⅳ)接客娯楽業（旅館業、飲食店、ゴルフ場、娯楽場、公園・遊園地、その他の接客娯楽業）。",
  summery_type:
    "シフトサマリーの計算期間を選択できます。<br>「月ごと」を選択した場合は1日から月末までが計算期間とし、「給与計算期間」を選択した場合は締め日の翌日から翌月の締め日までが計算期間となります。",
  show_other_shift:
    "マイページでシフトページを表示した際、他の人のシフトを表示するかを設定することができます。<br>「全員」を選択すると、自分が所属している店舗のシフトは全て閲覧できます。<br>「自分のシフトが存在する日の全員」を選択すると、自分がシフトに入っている日のみ全員のシフトが閲覧できます。<br>「自分のみ」を選択すると、自分のシフトのみが閲覧できます。",
  shift_punch_sync:
    "シフトを作れない理由がある場合、打刻からシフトを自動作成する機能です。<br>打刻からシフト作成を「使用する」に設定すると、以下ができなくなります。<br><br>・シフトの編集 : 打刻から強制的にシフトが上書きされるため、シフトの手動編集が一切できなくなります。<br>・丸め機能 : 丸めはシフトの外側でのみ機能するため、丸めが効かなくなります。<br>・遅刻や早退の計算 : 遅刻や早退は一切計算できなくなります。<br>・残業申請 : 残業申請はシフトを超えている部分に適用されるため、残業申請は使用できません(シフト外出勤申請は可能です)。<br><br>以上ができなくなると問題がある場合、この機能を使用せずにシフトを作成してください。<br>シフトテーブルページやシフト一括登録など、シフトを登録する機能を使用することをお勧めします。",

  lawhol:
    "法律で週1日以上与えることが義務づけられている休日であり、法定休日での出勤には35%以上の割増賃金の支給が義務付けられています。",
  punch_round:
    "勤務時間：8:57〜17:08　シフト時間：09:00〜17:00<br><br>①：10分単位で切り上げの場合、勤務時間は08:50〜17:10になります。<br>②：10分単位で切り捨ての場合、勤務時間は09:00〜17:00になります。<br>③：10分単位で四捨五入の場合、勤務時間は09:00〜17:10になります。",
  break_round:
    "休憩時間打刻：11:57〜13:08<br><br>①：10分単位で切り上げの場合、休憩時間は11:50〜13:10になります。<br>②：10分単位で切り捨ての場合、休憩時間は12:00〜13:00になります。<br>③：10分単位で四捨五入の場合、休憩時間は12:00〜13:10になります。",
  config_punch_gps:
    "GPS打刻を有効にすると、スマートフォンやタブレットでマイページにログインした際にGPS打刻のボタンが表示されます。",
  config_punch_mypc:
    "MyPC打刻を有効にすると、PCでマイページにログインした際にMyPC打刻のボタンが表示されます。<br>また、マイページの社員詳細ページにMyPC打刻のPC登録ボタンが表示されます。<br>PC登録には、管理者権限を持つアカウントでの認証が必要です。<br>また、キャッシュなどを削除するとPC登録が解除される可能性があります。",
  config_punch_alert:
    "「あり」を選択すると、シフトがないのに打刻をした際に「シフト外出勤をしてよろしいですか？」という確認が表示されます。",
  add_paidhol_time:
    "「加算する」を選択すると、勤怠ページやCSVに表示される合計勤務時間に有給休暇・特別休暇時間が加算されます。<br>表示上のものであり、勤怠計算に影響があるものではありません。",
  show_attendance_chart:
    "マイページで勤怠詳細ページを表示した際に、残業などの細かいチャートを表示するボタンを使用できるかどうかを選択できます。",
  no_shift_error:
    "打刻があるのにシフトがない場合に勤怠ページに表示されるエラーを表示するかを選択できます。",
  close_day: "勤怠を締める日です。<br>その日までが給与の計算期間となります。",
  pay_month:
    "給与の振り込み月です。<br>当月の場合は勤怠の締め日と同月に振り込まれます。<br>翌月の場合は勤怠の締め日の翌月に振り込まれます。",
  pay_day: "給与の振り込み日です。<br>金融機関が休みの場合は、直前の営業日が振り込み日となります。",
  levy_month:
    "翌月徴収：当月分の社会保険料を翌月支給の給与から控除します。<br>当月徴収：当月分の社会保険料を当月支給の給与から控除します。",
  lawover_rate:
    "所定労働時間が７時間、勤務時間が９時間の場合、８時間を超えた１時間分が法定外残業時間となります。法定外残業には25%以上の割増賃金の支給が義務付けられています。",
  preover_rate:
    "所定労働時間が７時間、勤務時間が９時間の場合、７時間を超えた１時間分が法定内残業時間となります。",
  outhol_rate:
    "法律で定められていない休日です。<br>労働基準法では週に1日以上または4週に4日以上の休日を与えなければいけないと定められています。",
  lawhol_rate:
    "法律で週1日以上与えることが義務づけられている休日であり、法定休日での出勤には35%以上の割増賃金の支給が義務付けられています。",
  mid_rate: "22:00~翌5:00までの深夜勤務には25%以上の割増賃金の支給が義務付けられています。",
  allowance_mutualaid:
    "以下のいずれかに当てはまる場合に選択してください。<br>また、課税上の控除対象にもチェックをつけてください。<br><br>・独立行政法人中小企業基盤整備機構の共済契約の掛金<br>・確定拠出年金法に規定する企業型年金加入者掛金<br>・確定拠出年金法に規定する個人型年金加入者掛金<br>・心身障害者扶養共済制度に関する契約の掛金",
  allowance_base_pay:
    "割増賃金（残業・深夜・休日手当）を自動に設定していて、基礎時給の計算に含める項目の場合には、チェックしてください。労働と直接関係が薄く、個人的事情に基づいて支給される以下のようなものは除外します。<br>①家族手当　②通勤手当　③別居手当　④子女教育手当　⑤住宅手当　⑥臨時に支払われた賃金　⑦1カ月を超える期間ごとに支払われる賃金",
  allowance_cash_pay:
    "賄いなどの現物支給の項目の場合には、チェックしてください。自動的に精算金のマイナスと両建てになります。",
  initial_value:
    "手動：元々の値は0となります。<br>前月と同じ：前月の給与がロックされている場合は前月が使用されます。<br>単価×回数：入力された金額と選択された値を掛け合わせた値が使用されます。<br>固定値：常に入力された値が使用されます。<br>基礎単価×回数：基礎単価と選択された値を掛け合わせた値が使用されます。<br><br>どれを選択されても、給与ページで後から手動変更することが可能です。",
  transfer_unit:
    "従業員への振り込み用の銀行口座が店舗ごとに存在する場合は「店舗単位」を選択してください。<br>設定ページの「部署/店舗」タブにて、店舗ごとの口座設定が可能になります。",
  delay_alert:
    "遅刻アラートを有効にすると、指定した遅刻時間を過ぎた時にメールやアプリ通知が送信されます。<br>通知の送信は5分間隔のため、指定した遅刻時間丁度に送信されるわけではありません。",
  need_alert:
    "シフト募集を行った際にシフト提出を促すメールやアプリ通知を送信する通知先を選択できます。",
  order_overtime:
    "残業申請を有効にすると、マイページで残業申請を行うことができるようになります。<br>また、シフトの時間に含まれない勤怠が全て無効になります。<br>マイページから残業を申請し、管理者が承認することで、その分の勤怠が有効となります。<br>勤怠丸めの設定をしている場合でも、無効となった勤務には勤怠丸めは行われず、シフトに合わせて切り捨てられます。",
  order_holiday:
    "休暇申請を有効にすると、マイページで休暇申請を行うことができるようになります。<br>マイページから休暇(有給・特別休暇など)を申請し、管理者が承認することで、その日の勤務形態が変更されます。",
  order_change:
    "変更申請を有効にすると、マイページで社員情報の変更申請を行うことができるようになります。<br>マイページから社員情報変更を申請し、管理者が承認することで、社員情報を変更することができます。<br>ただし、会社設定項目(給与・税・社会保険など)に関しては申請できません。",
  order_punch:
    "打刻修正申請を有効にすると、マイページで打刻修正申請を行うことができるようになります。<br>打刻を忘れた場合や、誤った打刻をしてしまった場合に、マイページから打刻修正を申請し、管理者が承認することで、打刻の追加・変更・削除ができます。",
  order_join:
    "入社申請の承認権限を、会社管理者のみに与えるか、店舗管理者にも与えるかを選択できます。",
  use_nickname:
    "「使用する」を選択すると、従業員の詳細設定に「シフトページの表示名」という入力欄が追加されます。<br>この入力欄が空欄であれば本名が表示され、入力されている場合はそのニックネームが表示されます。<br>ニックネームの表示はマイページのみで適用され、管理者側では本名が表示されます。",
  branch_hourly_wage:
    "複数の店舗に勤務していて、店舗ごとに時給が異なる社員がいる場合に、「あり」を選択してください。<br>社員詳細ページ->労務情報->給与設定の基本給で時給を選択した際に、例外時給を追加できるようになります。",
  branch_carfare:
    "複数の店舗に勤務していて、店舗ごとに通勤手当の金額が異なる社員がいる場合に、「あり」を選択してください。<br>社員詳細ページ->労務情報->給与設定の通勤手当で日額を選択した際に、例外通勤手当を追加できるようになります。",
  bank_count:
    "従業員が登録できる口座数を選択できます。<br>2口座の場合、1つ目の口座に振り込む上限額を設定できるようになり、その上限額を超えた分が2つ目の口座へ振り込まれるようになります。",
  is_income_tax_opt:
    "非居住者などの場合で租税条約により免税となる従業員がいる場合に「使用する」を選択してください。<br>社員詳細ページ->労務情報->税・社会保険設定に源泉所得税の計算の有無を選択する項目が表示されます。",
  disability: content.ja.disability_content,
  widow: content.ja.widow_content,
  work_student: content.ja.work_student_content,
  single_child: content.ja.single_child_content,
  branch: content.ja.branch_content,
  earning:
    '収入 : 社員やパートアルバイトに支給される給与など<br>所得 : 収入から「会社員の必要経費とみなされるもの」を引いたもの<br><br>所得は決められた式によって計算されます。<br>詳しくは<a target="__blank" href="https://help.arrow-payment.com/law/earning/">こちら</a>をご覧ください。',
  use_double_biz:
    "二以上事業所勤務者がいる場合に「使用する」を選択してください。<br>社員詳細ページ->会社設定項目タブの標準報酬月額の選択欄右に「二以上事業所勤務者」という項目が表示されます。",
  is_day_over:
    "日の法定外残業(基本的には1日8時間超えの残業)を計算するかを選択してください。基本的には計算が必須です。<br>手動で残業代を計算して支給する場合など、自動で法定外残業を計算する必要がない場合に「計算しない」を選択します。<br>「計算しない」に設定された場合、日の法定外残業に相当する部分は通常勤務や法定内残業等として計算されます。<br>社員設定で残業を「なし」に設定した場合は残業勤務が切り取られる(勤務しなかったことになる)ため、計算方法が異なります。<br>残業代を支給しない場合は法令違反となりますので、ご注意ください。",
  is_week_over:
    "週の法定外残業(基本的には週40時間超えの残業)を計算するかを選択してください。基本的には計算が必須です。<br>手動で残業代を計算して支給する場合など、自動で法定外残業を計算する必要がない場合に「計算しない」を選択します。<br>「計算しない」に設定された場合、週の法定外残業に相当する部分は通常勤務や法定内残業等として計算されます。<br>社員設定で残業を「なし」に設定した場合は残業勤務が切り取られる(勤務しなかったことになる)ため、計算方法が異なります。<br>残業代を支給しない場合は法令違反となりますので、ご注意ください。",
  is_month_over:
    "月の法定外残業(月単位変形労働制やフレックスタイム制の場合に月の上限によって発生する残業)を計算するかを選択してください。基本的には計算が必須です。<br>手動で残業代を計算して支給する場合など、自動で法定外残業を計算する必要がない場合に「計算しない」を選択します。<br>「計算しない」に設定された場合、月の法定外残業に相当する部分は通常勤務や法定内残業等として計算されます。<br>社員設定で残業を「なし」に設定した場合は残業勤務が切り取られる(勤務しなかったことになる)ため、計算方法が異なります。<br>残業代を支給しない場合は法令違反となりますので、ご注意ください。",
  individual_unit:
    "社員ごとに異なる単価を設定できる用になります。<br>手当控除項目で単価を使用する際に、個人別単価を使用することで日給などの設定が可能になります。",
};

const en = {
  earning:
    'Revenue : Salaries paid to employees and part-time workers.<br>Income : The amount that the necessary expenses for employee is deducted from the revenue.<br><br>Income is calculated according to a defined formula.<br>Click <a target="__blank" href="https://help.arrow-payment.com/law/earning/">here</a> for more details.',
};

export default {
  ja: ja,
  en: {
    ...ja,
    ...en,
  },
};
