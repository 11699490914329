import { Component, Vue, Mixins } from "vue-property-decorator";
import monthly from "@/vuex/shift/monthly";
import monthly_list from "@/vuex/shift/monthly_list";
import monthly_employee from "@/vuex/shift/monthly_employee";
import monthly_summery from "@/vuex/shift/monthly_summery";
import daily_summery from "@/vuex/shift/daily_summery";
import global from "@/vuex/shift/global";
import need_global from "@/vuex/shift/need_global";
import shiftbundle_global from "@/vuex/shift/shiftbundle_global";
import daily from "@/vuex/shift/daily";
import daily_employee from "@/vuex/shift/daily_employee";
import * as util from "@/util";
import utilMixins from "@/mixins";
import manager from "@/vuex/shift/manager";
import need_manager from "@/vuex/shift/need_manager";
import shiftbundle_manager from "@/vuex/shift/shiftbundle_manager";
import { BranchHeaderData } from "@/typesold/header";

@Component
export default class mixinShift extends Mixins(utilMixins) {
  get is_set_global(): boolean {
    return global.is_set;
  }
  get is_set_monthly(): boolean {
    return (
      monthly.data[this.yearmonth] &&
      monthly.data[this.yearmonth][this.viewing_branch] &&
      monthly.data[this.yearmonth][this.viewing_branch].is_set
    );
  }
  get is_set_daily(): boolean {
    return (
      daily.data[this.date] &&
      daily.data[this.date][this.viewing_branch] &&
      daily.data[this.date][this.viewing_branch].is_set
    );
  }
  get is_set_monthly_employee(): boolean {
    return monthly_employee.data[this.yearmonth] && monthly_employee.data[this.yearmonth].is_set;
  }
  get is_set_daily_employee(): boolean {
    return daily_employee.data[this.date] && daily_employee.data[this.date].is_set;
  }
  get is_set_monthly_summery(): boolean {
    return (
      monthly_summery.data[this.summery_date] &&
      monthly_summery.data[this.summery_date][this.viewing_branch] &&
      monthly_summery.data[this.summery_date][this.viewing_branch].is_set
    );
  }
  get is_set_daily_summery(): boolean {
    return (
      daily_summery.data[this.date] &&
      daily_summery.data[this.date][this.viewing_branch] &&
      daily_summery.data[this.date][this.viewing_branch].is_set
    );
  }
  get is_set_need_global(): boolean {
    return need_global.data[this.viewing_branch] && need_global.data[this.viewing_branch].is_set;
  }
  get is_set_shiftbundle_global(): boolean {
    return (
      shiftbundle_global.data[this.viewing_branch] &&
      shiftbundle_global.data[this.viewing_branch].is_set
    );
  }
  get is_set_monthly_list(): boolean {
    return (
      monthly_list.data[this.yearmonth] &&
      monthly_list.data[this.yearmonth][this.viewing_branch] &&
      monthly_list.data[this.yearmonth][this.viewing_branch].is_set
    );
  }

  get is_valid_global(): boolean {
    return global.is_valid;
  }
  get is_valid_monthly(): boolean {
    return (
      monthly.data[this.yearmonth] &&
      monthly.data[this.yearmonth][this.viewing_branch] &&
      monthly.data[this.yearmonth][this.viewing_branch].is_valid
    );
  }
  get is_valid_daily(): boolean {
    return (
      daily.data[this.date] &&
      daily.data[this.date][this.viewing_branch] &&
      daily.data[this.date][this.viewing_branch].is_valid
    );
  }
  get is_valid_monthly_employee(): boolean {
    return monthly_employee.data[this.yearmonth] && monthly_employee.data[this.yearmonth].is_valid;
  }
  get is_valid_daily_employee(): boolean {
    return daily_employee.data[this.date] && daily_employee.data[this.date].is_valid;
  }
  get is_valid_monthly_summery(): boolean {
    return (
      monthly_summery.data[this.summery_date] &&
      monthly_summery.data[this.summery_date][this.viewing_branch] &&
      monthly_summery.data[this.summery_date][this.viewing_branch].is_valid
    );
  }
  get is_valid_daily_summery(): boolean {
    return (
      daily_summery.data[this.date] &&
      daily_summery.data[this.date][this.viewing_branch] &&
      daily_summery.data[this.date][this.viewing_branch].is_valid
    );
  }
  get is_valid_need_global(): boolean {
    return need_global.data[this.viewing_branch] && need_global.data[this.viewing_branch].is_valid;
  }
  get is_valid_shiftbundle_global(): boolean {
    return (
      shiftbundle_global.data[this.viewing_branch] &&
      shiftbundle_global.data[this.viewing_branch].is_valid
    );
  }
  get is_valid_monthly_list(): boolean {
    return (
      monthly_list.data[this.yearmonth] &&
      monthly_list.data[this.yearmonth][this.viewing_branch] &&
      monthly_list.data[this.yearmonth][this.viewing_branch].is_valid
    );
  }

  async get_global() {
    await global.fetch();
  }
  async get_monthly() {
    await monthly.fetch({ yearmonth: this.yearmonth, branch_id: this.viewing_branch });
  }
  async get_daily() {
    await daily.fetch({ date: this.date, branch_id: this.viewing_branch });
  }
  async get_monthly_employee() {
    await monthly_employee.fetch({ yearmonth: this.yearmonth, employee_id: this.s_employee_id });
  }
  async get_daily_employee() {
    await daily_employee.fetch({ date: this.date, employee_id: this.s_employee_id });
  }
  async get_monthly_summery() {
    await monthly_summery.fetch({ yearmonth: this.summery_date, branch_id: this.viewing_branch });
  }
  async get_daily_summery() {
    await daily_summery.fetch({ date: this.date, branch_id: this.viewing_branch });
  }
  async get_need_global() {
    await need_global.fetch({ branch_id: this.viewing_branch });
  }
  async get_shiftbundle_global() {
    await shiftbundle_global.fetch({ branch_id: this.viewing_branch });
  }
  async get_monthly_list() {
    await monthly_list.fetch({ yearmonth: this.yearmonth, branch_id: this.viewing_branch });
  }

  get global() {
    if (!this.is_set_global || !this.is_valid_global) this.get_global();
    if (this.is_set_global) return global.data;
  }
  get monthly() {
    if (!this.is_set_monthly || !this.is_valid_monthly) this.get_monthly();
    if (this.is_set_monthly) return monthly.data[this.yearmonth][this.viewing_branch];
  }
  get daily() {
    if (!this.is_set_daily || !this.is_valid_daily) this.get_daily();
    if (this.is_set_daily) return daily.data[this.date][this.viewing_branch];
  }
  get monthly_employee() {
    if (!this.is_set_monthly_employee || !this.is_valid_monthly_employee)
      this.get_monthly_employee();
    if (this.is_set_monthly_employee) return monthly_employee.data[this.yearmonth];
  }
  get daily_employee() {
    if (!this.is_set_daily_employee || !this.is_valid_daily_employee) this.get_daily_employee();
    if (this.is_set_daily_employee) return daily_employee.data[this.date];
  }
  get monthly_summery() {
    if (!this.is_set_monthly_summery || !this.is_valid_monthly_summery) this.get_monthly_summery();
    if (this.is_set_monthly_summery)
      return monthly_summery.data[this.summery_date][this.viewing_branch];
  }
  get daily_summery() {
    if (!this.is_set_daily_summery || !this.is_valid_daily_summery) this.get_daily_summery();
    if (this.is_set_daily_summery) return daily_summery.data[this.date][this.viewing_branch];
  }
  get need_global() {
    if (!this.is_set_need_global || !this.is_valid_need_global) this.get_need_global();
    if (this.is_set_need_global) return need_global.data[this.viewing_branch];
  }
  get shiftbundle_global() {
    if (!this.is_set_shiftbundle_global || !this.is_valid_shiftbundle_global)
      this.get_shiftbundle_global();
    if (this.is_set_shiftbundle_global) return shiftbundle_global.data[this.viewing_branch];
  }
  get monthly_list() {
    if (!this.is_set_monthly_list || !this.is_valid_monthly_list) this.get_monthly_list();
    if (this.is_set_monthly_list) return monthly_list.data[this.yearmonth][this.viewing_branch];
  }

  get yearmonth() {
    return util.formatDate(this.dateobj, "yyyy-mm");
  }

  get viewing_branch() {
    var branch_id = this.query.viewing_branch;
    if (branch_id === undefined || branch_id == 0) {
      branch_id = Number(util.getLocalStorage("branch_id"));
      this.query = { viewing_branch: branch_id };
    }
    return branch_id;
  }
  set viewing_branch(val) {
    this.query = { viewing_branch: val };
  }

  get m() {
    return manager;
  }
  get nm() {
    return need_manager;
  }
  get sm() {
    return shiftbundle_manager;
  }

  get todayobj() {
    return new Date();
  }

  get dateobj() {
    return util.date2obj(this.date);
  }
  get date() {
    var date = this.query.shift_date;
    if (date === undefined) {
      date = util.formatDate(new Date(), "yyyy-mm-dd");
      this.query = { shift_date: date };
    }
    return date;
  }
  set date(val) {
    if (val != this.date) {
      this.query = { shift_date: val };
    }
  }

  get needdateobj() {
    return util.date2obj(this.need_date + "-01");
  }
  get need_date() {
    var need_date = this.query.need_date;
    if (need_date === undefined) {
      const need_date_obj = new Date();
      need_date_obj.setMonth(need_date_obj.getMonth() + 1);
      need_date = util.formatDate(need_date_obj, "yyyy-mm");
      this.query = { need_date: need_date };
    }
    return need_date;
  }
  set need_date(val) {
    need_manager.getNeeds({ yearmonth: val, branch_id: this.viewing_branch });
    if (val != this.need_date) {
      this.query = { need_date: val };
    }
  }
  get summery_date() {
    var summery_date = this.query.summery_date;
    if (summery_date === undefined) {
      const summery_date_obj = new Date();
      summery_date_obj.setMonth(summery_date_obj.getMonth() + 1);
      summery_date = util.formatDate(summery_date_obj, "yyyy-mm");
      this.query = { summery_date: summery_date };
    }
    return summery_date;
  }
  set summery_date(val) {
    if (val != this.summery_date) {
      this.query = { summery_date: val };
    }
  }

  get shiftbundledateobj() {
    return util.date2obj(this.shiftbundle_date + "-01");
  }
  get shiftbundle_date() {
    var shiftbundle_date = this.query.shiftbundle_date;
    if (shiftbundle_date === undefined) {
      shiftbundle_date = util.formatDate(new Date(), "yyyy-mm");
      this.query = { shiftbundle_date: shiftbundle_date };
    }
    return shiftbundle_date;
  }
  set shiftbundle_date(val) {
    if (val != this.shiftbundle_date) {
      this.query = { shiftbundle_date: val };
    }
  }

  get search_key() {
    var search_key = this.query.summery_search_key;
    if (search_key === undefined || search_key == "") {
      this.query = { summery_search_key: undefined };
      return null;
    } else {
      return search_key;
    }
  }
  set search_key(val) {
    this.page = 1;
    if (val == "" || val == null) {
      this.query = { summery_search_key: undefined };
    } else {
      this.query = { summery_search_key: val };
    }
  }

  get sort_code() {
    return this.query.shift_sort_code;
  }
  set sort_code(val) {
    if (val == "" || val == null) {
      this.query = { shift_sort_code: undefined };
    } else {
      this.query = { shift_sort_code: val };
    }
  }
  get sort_name() {
    return this.query.shift_sort_name;
  }
  set sort_name(val) {
    if (val == "" || val == null) {
      this.query = { shift_sort_name: undefined };
    } else {
      this.query = { shift_sort_name: val };
    }
  }
  get sort_branch() {
    return this.query.shift_sort_branch;
  }
  set sort_branch(val) {
    if (val == "" || val == null) {
      this.query = { shift_sort_branch: undefined };
    } else {
      this.query = { shift_sort_branch: val };
    }
  }
  get sort_shift_started_at() {
    return this.query.shift_sort_shift_started_at;
  }
  set sort_shift_started_at(val) {
    if (val == "" || val == null) {
      this.query = { shift_sort_shift_started_at: undefined };
    } else {
      this.query = { shift_sort_shift_started_at: val };
    }
  }
  get sort_shift_ended_at() {
    return this.query.shift_sort_shift_ended_at;
  }
  set sort_shift_ended_at(val) {
    if (val == "" || val == null) {
      this.query = { shift_sort_shift_ended_at: undefined };
    } else {
      this.query = { shift_sort_shift_ended_at: val };
    }
  }
  get sort_punch_started_at() {
    return this.query.shift_sort_punch_started_at;
  }
  set sort_punch_started_at(val) {
    if (val == "" || val == null) {
      this.query = { shift_sort_punch_started_at: undefined };
    } else {
      this.query = { shift_sort_punch_started_at: val };
    }
  }
  get sort_punch_ended_at() {
    return this.query.shift_sort_punch_ended_at;
  }
  set sort_punch_ended_at(val) {
    if (val == "" || val == null) {
      this.query = { shift_sort_punch_ended_at: undefined };
    } else {
      this.query = { shift_sort_punch_ended_at: val };
    }
  }
  get sort_break_started_at() {
    return this.query.shift_sort_break_started_at;
  }
  set sort_break_started_at(val) {
    if (val == "" || val == null) {
      this.query = { shift_sort_break_started_at: undefined };
    } else {
      this.query = { shift_sort_break_started_at: val };
    }
  }
  get sort_break_ended_at() {
    return this.query.shift_sort_break_ended_at;
  }
  set sort_break_ended_at(val) {
    if (val == "" || val == null) {
      this.query = { shift_sort_break_ended_at: undefined };
    } else {
      this.query = { shift_sort_break_ended_at: val };
    }
  }

  get sort_summery_code() {
    return this.query.summery_sort_code;
  }
  set sort_summery_code(val) {
    if (val == "" || val == null) {
      this.query = { summery_sort_code: undefined };
    } else {
      this.query = { summery_sort_code: val };
    }
  }
  get sort_summery_name() {
    return this.query.summery_sort_name;
  }
  set sort_summery_name(val) {
    if (val == "" || val == null) {
      this.query = { summery_sort_name: undefined };
    } else {
      this.query = { summery_sort_name: val };
    }
  }
  get sort_summery_hiring_type() {
    return this.query.summery_sort_hiring_type;
  }
  set sort_summery_hiring_type(val) {
    if (val == "" || val == null) {
      this.query = { summery_sort_hiring_type: undefined };
    } else {
      this.query = { summery_sort_hiring_type: val };
    }
  }
  get sort_summery_branch() {
    return this.query.summery_sort_branch;
  }
  set sort_summery_branch(val) {
    if (val == "" || val == null) {
      this.query = { summery_sort_branch: undefined };
    } else {
      this.query = { summery_sort_branch: val };
    }
  }
  get sort_summery_payment() {
    return this.query.summery_sort_payment;
  }
  set sort_summery_payment(val) {
    if (val == "" || val == null) {
      this.query = { summery_sort_payment: undefined };
    } else {
      this.query = { summery_sort_payment: val };
    }
  }
  get sort_summery_carfare() {
    return this.query.summery_sort_carfare;
  }
  set sort_summery_carfare(val) {
    if (val == "" || val == null) {
      this.query = { summery_sort_carfare: undefined };
    } else {
      this.query = { summery_sort_carfare: val };
    }
  }
  get sort_summery_sum() {
    return this.query.summery_sort_sum;
  }
  set sort_summery_sum(val) {
    if (val == "" || val == null) {
      this.query = { summery_sort_sum: undefined };
    } else {
      this.query = { summery_sort_sum: val };
    }
  }

  get page() {
    var page = this.query.summery_page;
    if (page === undefined || page == "") {
      this.query = { summery_page: 1 };
      page = 1;
    }
    return page;
  }
  set page(val) {
    if (val == "" || val == null) {
      this.query = { summery_page: undefined };
    } else {
      this.query = { summery_page: val };
    }
  }

  get searched_sorted_summery() {
    var summery = util.deep_copy(this.summery);
    summery = this.search_summery(summery);
    summery = this.sort_summery(summery);
    return summery;
  }
  get managed_summery() {
    var summery = util.deep_copy(this.searched_sorted_summery);
    summery = this.page_summery(summery);
    return summery;
  }
  search_summery(summery) {
    if (this.search_key === null) {
      return summery;
    } else {
      const search_keys = this.search_key.split(/\s+/).filter((str) => str != "");
      console.log(search_keys);
      var _this = this;
      return summery.filter((each_summery) => {
        var flag = 0;
        search_keys.forEach((key) => {
          if (
            this.summery_employee_hash[each_summery.employee_id].name
              .toLowerCase()
              .indexOf(key.toLowerCase()) != -1 ||
            (this.summery_employee_hash[each_summery.employee_id].code &&
              this.summery_employee_hash[each_summery.employee_id].code
                .toLowerCase()
                .indexOf(key.toLowerCase()) != -1)
          ) {
            flag = 1;
            return;
          }
        });
        return flag;
      });
    }
  }
  sort_summery(summery) {
    if (this.sort_summery_code)
      summery.sort((x, y) =>
        util.sort_idx(
          this.summery_employee_hash[x.employee_id].code,
          this.summery_employee_hash[y.employee_id].code,
          this.sort_summery_code,
        ),
      );
    else if (this.sort_summery_name)
      summery.sort((x, y) =>
        util.sort_idx(
          this.summery_employee_hash[x.employee_id].name,
          this.summery_employee_hash[y.employee_id].name,
          this.sort_summery_name,
        ),
      );
    else if (this.sort_summery_hiring_type)
      summery.sort((x, y) =>
        util.sort_idx(
          this.summery_employee_hash[x.employee_id].hiring_type,
          this.summery_employee_hash[y.employee_id].hiring_type,
          this.sort_summery_hiring_type,
        ),
      );
    else if (this.sort_summery_branch)
      summery.sort((x, y) =>
        util.sort_idx(
          this.summery_employee_hash[x.employee_id].branch_id,
          this.summery_employee_hash[y.employee_id].branch_id,
          this.sort_summery_branch,
        ),
      );
    else if (this.sort_summery_payment)
      summery.sort((x, y) => util.sort_idx(x.payment, y.payment, this.sort_summery_payment));
    else if (this.sort_summery_carfare)
      summery.sort((x, y) => util.sort_idx(x.carfare, y.carfare, this.sort_summery_carfare));
    else if (this.sort_summery_sum)
      summery.sort((x, y) => util.sort_idx(x.sum, y.sum, this.sort_summery_sum));
    return summery;
  }

  get sort_daily_summery_code() {
    return this.query.daily_summery_sort_code;
  }
  set sort_daily_summery_code(val) {
    if (val == "" || val == null) {
      this.query = { daily_summery_sort_code: undefined };
    } else {
      this.query = { daily_summery_sort_code: val };
    }
  }
  get sort_daily_summery_name() {
    return this.query.daily_summery_sort_name;
  }
  set sort_daily_summery_name(val) {
    if (val == "" || val == null) {
      this.query = { daily_summery_sort_name: undefined };
    } else {
      this.query = { daily_summery_sort_name: val };
    }
  }
  get sort_daily_summery_hiring_type() {
    return this.query.daily_summery_sort_hiring_type;
  }
  set sort_daily_summery_hiring_type(val) {
    if (val == "" || val == null) {
      this.query = { daily_summery_sort_hiring_type: undefined };
    } else {
      this.query = { daily_summery_sort_hiring_type: val };
    }
  }
  get sort_daily_summery_branch() {
    return this.query.daily_summery_sort_branch;
  }
  set sort_daily_summery_branch(val) {
    if (val == "" || val == null) {
      this.query = { daily_summery_sort_branch: undefined };
    } else {
      this.query = { daily_summery_sort_branch: val };
    }
  }
  get sort_daily_summery_reg_pay() {
    return this.query.daily_summery_sort_reg_pay;
  }
  set sort_daily_summery_reg_pay(val) {
    if (val == "" || val == null) {
      this.query = { daily_summery_sort_reg_pay: undefined };
    } else {
      this.query = { daily_summery_sort_reg_pay: val };
    }
  }
  get sort_daily_summery_over_pay() {
    return this.query.daily_summery_sort_over_pay;
  }
  set sort_daily_summery_over_pay(val) {
    if (val == "" || val == null) {
      this.query = { daily_summery_sort_over_pay: undefined };
    } else {
      this.query = { daily_summery_sort_over_pay: val };
    }
  }
  get sort_daily_summery_hol_pay() {
    return this.query.daily_summery_sort_hol_pay;
  }
  set sort_daily_summery_hol_pay(val) {
    if (val == "" || val == null) {
      this.query = { daily_summery_sort_hol_pay: undefined };
    } else {
      this.query = { daily_summery_sort_hol_pay: val };
    }
  }
  get sort_daily_summery_mid_pay() {
    return this.query.daily_summery_sort_mid_pay;
  }
  set sort_daily_summery_mid_pay(val) {
    if (val == "" || val == null) {
      this.query = { daily_summery_sort_mid_pay: undefined };
    } else {
      this.query = { daily_summery_sort_mid_pay: val };
    }
  }
  get sort_daily_summery_carfare() {
    return this.query.daily_summery_sort_carfare;
  }
  set sort_daily_summery_carfare(val) {
    if (val == "" || val == null) {
      this.query = { daily_summery_sort_carfare: undefined };
    } else {
      this.query = { daily_summery_sort_carfare: val };
    }
  }
  get sort_daily_summery_sum() {
    return this.query.daily_summery_sort_sum;
  }
  set sort_daily_summery_sum(val) {
    if (val == "" || val == null) {
      this.query = { daily_summery_sort_sum: undefined };
    } else {
      this.query = { daily_summery_sort_sum: val };
    }
  }

  get daily_summery_page() {
    var page = this.query.daily_summery_page;
    if (page === undefined || page == "") {
      this.query = { daily_summery_page: 1 };
      page = 1;
    }
    return page;
  }
  set daily_summery_page(val) {
    if (val == "" || val == null) {
      this.query = { daily_summery_page: undefined };
    } else {
      this.query = { daily_summery_page: val };
    }
  }

  get searched_sorted_daily_summery() {
    let summery = util.deep_copy(this.summery_daily);
    summery = this.search_daily_summery(summery);
    return summery;
  }
  get managed_daily_summery() {
    let summery = util.deep_copy(this.searched_sorted_daily_summery);
    summery = this.page_daily_summery(summery);
    return summery;
  }
  search_daily_summery(summery) {
    if (this.search_key === null) {
      return summery;
    } else {
      const search_keys = this.search_key.split(/\s+/).filter((str) => str != "");
      console.log(search_keys);
      var _this = this;
      return summery.filter((each_summery) => {
        var flag = 0;
        search_keys.forEach((key) => {
          if (
            this.daily_summery_employee_hash[each_summery.employee_id].name
              .toLowerCase()
              .indexOf(key.toLowerCase()) != -1 ||
            (this.daily_summery_employee_hash[each_summery.employee_id].code &&
              this.daily_summery_employee_hash[each_summery.employee_id].code
                .toLowerCase()
                .indexOf(key.toLowerCase()) != -1)
          ) {
            flag = 1;
            return;
          }
        });
        return flag;
      });
    }
  }
  sort_daily_summery(summery) {
    if (this.sort_daily_summery_code)
      summery.sort((x, y) =>
        util.sort_idx(
          this.daily_summery_employee_hash[x.employee_id].code,
          this.daily_summery_employee_hash[y.employee_id].code,
          this.sort_daily_summery_code,
        ),
      );
    else if (this.sort_daily_summery_name)
      summery.sort((x, y) =>
        util.sort_idx(
          this.daily_summery_employee_hash[x.employee_id].name,
          this.daily_summery_employee_hash[y.employee_id].name,
          this.sort_daily_summery_name,
        ),
      );
    else if (this.sort_daily_summery_hiring_type)
      summery.sort((x, y) =>
        util.sort_idx(
          this.daily_summery_employee_hash[x.employee_id].hiring_type,
          this.daily_summery_employee_hash[y.employee_id].hiring_type,
          this.sort_daily_summery_hiring_type,
        ),
      );
    else if (this.sort_daily_summery_branch)
      summery.sort((x, y) =>
        util.sort_idx(
          this.daily_summery_employee_hash[x.employee_id].branch_id,
          this.daily_summery_employee_hash[y.employee_id].branch_id,
          this.sort_daily_summery_branch,
        ),
      );
    else if (this.sort_daily_summery_reg_pay)
      summery.sort((x, y) => util.sort_idx(x.payment, y.payment, this.sort_daily_summery_reg_pay));
    else if (this.sort_daily_summery_over_pay)
      summery.sort((x, y) => util.sort_idx(x.payment, y.payment, this.sort_daily_summery_over_pay));
    else if (this.sort_daily_summery_hol_pay)
      summery.sort((x, y) => util.sort_idx(x.payment, y.payment, this.sort_daily_summery_hol_pay));
    else if (this.sort_daily_summery_mid_pay)
      summery.sort((x, y) => util.sort_idx(x.payment, y.payment, this.sort_daily_summery_mid_pay));
    else if (this.sort_daily_summery_carfare)
      summery.sort((x, y) => util.sort_idx(x.carfare, y.carfare, this.sort_daily_summery_carfare));
    else if (this.sort_daily_summery_sum)
      summery.sort((x, y) => util.sort_idx(x.sum, y.sum, this.sort_daily_summery_sum));
    return summery;
  }

  sort_shift(shifts) {
    if (this.sort_name)
      shifts.sort((x, y) =>
        util.sort_idx(
          this.daily_employee_hash[x.employee_id],
          this.daily_employee_hash[y.employee_id],
          this.sort_name,
        ),
      );
    else if (this.sort_shift_started_at)
      shifts.sort((x, y) =>
        util.sort_idx(
          x.shift_shift.length == 0 || x.shift_shift[0].started_at === null
            ? 10000
            : x.shift_shift[0].started_at,
          y.shift_shift.length == 0 || y.shift_shift[0].started_at === null
            ? 10000
            : y.shift_shift[0].started_at,
          this.sort_shift_started_at,
        ),
      );
    else if (this.sort_shift_ended_at)
      shifts.sort((x, y) =>
        util.sort_idx(
          x.shift_shift.length == 0 || x.shift_shift[0].ended_at === null
            ? 10000
            : x.shift_shift[0].ended_at,
          y.shift_shift.length == 0 || y.shift_shift[0].ended_at === null
            ? 10000
            : y.shift_shift[0].ended_at,
          this.sort_shift_ended_at,
        ),
      );
    else if (this.sort_punch_started_at)
      shifts.sort((x, y) =>
        util.sort_idx(
          x.shift_punch.length == 0 || x.shift_punch[0].started_at === null
            ? 10000
            : x.shift_punch[0].started_at,
          y.shift_punch.length == 0 || y.shift_punch[0].started_at === null
            ? 10000
            : y.shift_punch[0].started_at,
          this.sort_punch_started_at,
        ),
      );
    else if (this.sort_punch_ended_at)
      shifts.sort((x, y) =>
        util.sort_idx(
          x.shift_punch.length == 0 || x.shift_punch[0].ended_at === null
            ? 10000
            : x.shift_punch[0].ended_at,
          y.shift_punch.length == 0 || y.shift_punch[0].ended_at === null
            ? 10000
            : y.shift_punch[0].ended_at,
          this.sort_punch_ended_at,
        ),
      );
    else if (this.sort_break_started_at)
      shifts.sort((x, y) =>
        util.sort_idx(
          x.shift_break.length == 0 || x.shift_break[0].started_at === null
            ? 10000
            : x.shift_break[0].started_at,
          y.shift_break.length == 0 || y.shift_break[0].started_at === null
            ? 10000
            : y.shift_break[0].started_at,
          this.sort_break_started_at,
        ),
      );
    else if (this.sort_break_ended_at)
      shifts.sort((x, y) =>
        util.sort_idx(
          x.shift_break.length == 0 || x.shift_break[0].ended_at === null
            ? 10000
            : x.shift_break[0].ended_at,
          y.shift_break.length == 0 || y.shift_break[0].ended_at === null
            ? 10000
            : y.shift_break[0].ended_at,
          this.sort_break_ended_at,
        ),
      );
    else
      shifts.sort((x, y) =>
        util.sort_idx(
          x.shift_shift.length == 0 || x.shift_shift[0].started_at === null
            ? 10000
            : x.shift_shift[0].started_at,
          y.shift_shift.length == 0 || y.shift_shift[0].started_at === null
            ? 10000
            : y.shift_shift[0].started_at,
          "asc",
        ),
      );

    return shifts;
  }
  sort_shift_employee(shifts) {
    Object.keys(shifts).forEach((branch_id) => {
      shifts[branch_id].sort((x, y) =>
        util.sort_idx(
          x.shift_shift.length == 0 || x.shift_shift[0].started_at === null
            ? 10000
            : x.shift_shift[0].started_at,
          y.shift_shift.length == 0 || y.shift_shift[0].started_at === null
            ? 10000
            : y.shift_shift[0].started_at,
          "asc",
        ),
      );
      shifts[branch_id].sort((x, y) =>
        util.sort_idx(
          x.employee_id == this.s_employee_id ? 0 : 1,
          y.employee_id == this.s_employee_id ? 0 : 1,
          "asc",
        ),
      );
    });
    return shifts;
  }
  page_summery(summery) {
    return summery.slice(this.disp_num * (this.page - 1), this.disp_num * this.page);
  }
  page_daily_summery(summery) {
    console.log(summery);
    return summery.slice(
      this.daily_summery_disp_num * (this.daily_summery_page - 1),
      this.daily_summery_disp_num * this.daily_summery_page,
    );
  }
  sort_click(sort_key) {
    if (this.s_auth == 0) return;
    sort_key = "sort_" + sort_key;
    var sort_mode = undefined;
    if (this[sort_key] === undefined) {
      sort_mode = "asc";
    } else if (this[sort_key] == "asc") {
      sort_mode = "desc";
    } else if (this[sort_key] == "desc") {
      sort_mode = "asc";
    }
    this.set_sort(sort_key, sort_mode);
  }
  sort_summery_click(sort_key) {
    this.page = 1;
    sort_key = "sort_summery_" + sort_key;
    var sort_mode = undefined;
    if (this[sort_key] === undefined) {
      sort_mode = "asc";
    } else if (this[sort_key] == "asc") {
      sort_mode = "desc";
    } else if (this[sort_key] == "desc") {
      sort_mode = "asc";
    }
    this.set_summery_sort(sort_key, sort_mode);
  }
  set_sort(sort_key, sort_mode) {
    this.query = {
      shift_sort_code: sort_key == "sort_code" ? sort_mode : undefined,
      shift_sort_name: sort_key == "sort_name" ? sort_mode : undefined,
      shift_sort_branch: sort_key == "sort_branch" ? sort_mode : undefined,
      shift_sort_shift_started_at: sort_key == "sort_shift_started_at" ? sort_mode : undefined,
      shift_sort_shift_ended_at: sort_key == "sort_shift_ended_at" ? sort_mode : undefined,
      shift_sort_punch_started_at: sort_key == "sort_punch_started_at" ? sort_mode : undefined,
      shift_sort_punch_ended_at: sort_key == "sort_punch_ended_at" ? sort_mode : undefined,
      shift_sort_break_started_at: sort_key == "sort_break_started_at" ? sort_mode : undefined,
      shift_sort_break_ended_at: sort_key == "sort_break_ended_at" ? sort_mode : undefined,
    };
  }
  set_summery_sort(sort_key, sort_mode) {
    this.query = {
      summery_sort_code: sort_key == "sort_summery_code" ? sort_mode : undefined,
      summery_sort_name: sort_key == "sort_summery_name" ? sort_mode : undefined,
      summery_sort_hiring_type: sort_key == "sort_summery_hiring_type" ? sort_mode : undefined,
      summery_sort_branch: sort_key == "sort_summery_branch" ? sort_mode : undefined,
      summery_sort_payment: sort_key == "sort_summery_payment" ? sort_mode : undefined,
      summery_sort_carfare: sort_key == "sort_summery_carfare" ? sort_mode : undefined,
      summery_sort_sum: sort_key == "sort_summery_sum" ? sort_mode : undefined,
    };
  }

  get disp_num() {
    return manager.disp_num;
  }
  set disp_num(val) {
    this.page = 1;
    util.setLocalStorage("summery_disp_num", val);
    manager.setDispNum(val);
  }

  get daily_summery_disp_num() {
    return manager.daily_summery_disp_num;
  }
  set daily_summery_disp_num(val) {
    this.page = 1;
    util.setLocalStorage("daily_summery_disp_num", val);
    manager.setDailySummeryDispNum(val);
  }

  get summery_daily() {
    return this.daily_summery.summery;
  }
  get daily_summery_employee_hash() {
    return this.daily_summery.employee_hash;
  }
  get daily_summery_branch_hash() {
    return this.daily_summery.branch_hash;
  }

  get summery() {
    return this.monthly_summery.summery;
  }
  get summery_employee_hash() {
    return this.monthly_summery.employee_hash;
  }
  get summery_branch_hash() {
    return this.monthly_summery.branch_hash;
  }
  get daily_employee_hash() {
    return this.daily.employee_hash;
  }

  get editable_shift() {
    let header = this.header as BranchHeaderData;
    return this.s_auth == 2 || (this.s_auth == 1 && header.auth_shift == 2);
  }
  get show_summery() {
    let header = this.header as BranchHeaderData;
    return this.s_auth == 2 || (this.s_auth == 1 && header.auth_summery == 1);
  }

  get mode() {
    if (!this.is_manager || this.is_sp) {
      return 0;
    }
    return this.m.mode;
  }
  set mode(val) {
    util.setLocalStorage("shift_mode", val);
    if (val == 1) {
      this.m.setIsOpenShiftDialog(false);
    }
    manager.setMode(val);
  }
}
