/**
* Vue Router
*
* @library
*
* https://router.vuejs.org/en/
*/

// Lib imports
import Vue from 'vue'
import store from '../store'
import Router from 'vue-router'
import Meta from 'vue-meta'
import before_login from "@/vuex/before_login.ts"
import header from "@/vuex/header.ts"
import * as util from '@/util';
import node from "@/vuex/node";

// Routes
import paths from './paths'
import dialog from '@/vuex/dialog';

function route (path, view, name, meta) {
  return {
    name: name || view,
    path,
    component: (resovle) => import(
      `@/views/${view}.vue`
    ).then(resovle),
    meta
  }
}

Vue.use(Router)

// Create a new router
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,//build_comment
  routes: paths.map(path => route(path.path, path.view, path.name, path.meta)).concat([
    { path: '*', redirect: '/illegal' }
  ]),
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { selector: to.hash }
    }
    if (to.name != from.name) {
      return { x: 0, y: 0 }
    }
  }
})

Vue.use(Meta)


// ここがルートガード
var now = router;

router.beforeEach(async (to, from, next) => {
  console.log(from);
  console.log(to);

  if (await node.is_edit_all_vuex()) {
    dialog.openOptionDialog({title: '確認', msg: 'ページを移動してよろしいですか？', detail: '行った変更は全て破棄されます', func: change_page_process, cancel_func: cancel_page_process, args: {from, to, func: next}, cancel_args: {from, to, func: next}, exec_button: '変更を破棄', });
  } else {
    change_page_process({from, to, func: next});
  }
});

function change_page_process({from, to, func}) {
  var is_change = false;

  Object.keys(from.query).forEach(key => {
    if (to.query[key] != from.query[key]) {
      is_change = true;
    }
  });

  if (from.name != to.name || is_change) {
    node.cancel_all_vuex();
  }
  func();
}

function cancel_page_process({from, to, func}) {
  func(false);
}


export default router
