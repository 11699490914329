import {
  SingleSystemNotificationData,
  SingleSystemNotificationValidateData,
} from "@/typesold/Notification";
import VuexDataControl from "@/vuex/VuexDataControl";

export default class NotificationSingleSystem extends VuexDataControl {
  api_url = "notification/read_single_system";
  indexes = ["system_notification_id"];
}
