import VuexManagerControl from "@/vuex/VuexManagerControl";
import {
  validationUpdateStdincome,
  validationUpdateStdincomeMasters,
} from "@/validations/update_stdincome";
import dialog from "@/vuex/dialog";
import * as util from "@/util";
import router from "@/router";
import global from "@/vuex/update_stdincome/global";

export default class Manager extends VuexManagerControl {
  create_descriptor = {
    update_stdincome: {
      url: "update_stdincome/create_update_stdincome",
      name: "月額変更届情報",
      validate: validationUpdateStdincome,
      dialog: {
        msg: "給与ロックがされていない月の分は計算されません。",
        detail: "必ず対象月の給与ロック後に開始してください。",
      },
      processing_dialog: {},
      finish_dialog: {},
      close_dialog: ["update_stdincome_create"],
      plus: {
        func: () => {
          location.href = "/update_stdincome";
        },
      },
    },
  };
  update_descriptor = {
    update_stdincome_masters: {
      url: "update_stdincome/update_update_stdincome_masters",
      name: "月額変更届情報",
      validate: validationUpdateStdincomeMasters,
      dialog: {},
      processing_dialog: {},
      finish_dialog: {},
    },
    update_stdincome_master_valid: {
      url: "update_stdincome/update_update_stdincome_master_valid",
      name: "月額変更届有効無効",
    },
    update_stdincome_reflect: {
      url: "update_stdincome/reflect_update_stdincome",
      name: "標準報酬月額反映",
      dialog: {
        msg: "計算された標準報酬月額を社員情報へ反映してよろしいですか？",
        detail: "反映前に勤怠ロックされた分の計算は変更されません。",
      },
      processing_dialog: {},
      finish_dialog: {},
    },
    update_stdincome_unreflect: {
      url: "update_stdincome/unreflect_update_stdincome",
      name: "標準報酬月額反映取消",
      dialog: {
        msg: "社員情報の標準報酬月額を反映前の値に戻してよろしいですか？",
        detail: "反映取消前に勤怠ロックされた分の計算は変更されません。",
      },
      processing_dialog: {},
      finish_dialog: {},
    },
    update_stdincomes_reflect: {
      url: "update_stdincome/reflect_update_stdincomes",
      name: "標準報酬月額反映",
      dialog: {
        msg: "表示中の店舗に所属している社員の計算された標準報酬月額を一括反映してよろしいですか？",
        detail:
          "「有効」かつ「未反映」の社員のみが対象です。<br>反映前に勤怠ロックされた分の計算は変更されません。",
      },
      force_dialog: {
        msg: "表示中の店舗に所属している社員の計算された標準報酬月額を一括反映してよろしいですか？",
        detail: "「有効」かつ「未反映」の社員のみが対象です。<br>本当によろしいですか？",
      },
      processing_dialog: {},
      finish_dialog: {},
    },
    update_stdincomes_unreflect: {
      url: "update_stdincome/unreflect_update_stdincomes",
      name: "標準報酬月額反映取消",
      dialog: {
        msg: "表示中の店舗に所属している社員の社員情報の標準報酬月額を反映前の値に戻してよろしいですか？",
        detail:
          "「有効」かつ「反映済」の社員のみが対象です。<br>反映取消前に勤怠ロックされた分の計算は変更されません。",
      },
      force_dialog: {
        msg: "表示中の店舗に所属している社員の社員情報の標準報酬月額を反映前の値に戻してよろしいですか？",
        detail: "「有効」かつ「反映済」の社員のみが対象です。<br>本当によろしいですか？",
      },
      processing_dialog: {},
      finish_dialog: {},
    },
  };
  delete_descriptor = {
    update_stdincome: {
      url: "update_stdincome/delete_update_stdincome",
      name: "月額変更届情報",
      dialog: {
        msg: "月額変更届のやり直しを行ってよろしいですか？",
        detail: "現在までの変更内容は全て削除されます。",
      },
      processing_dialog: {},
      finish_dialog: {},
      routing: { name: "update_stdincome", minus_args: ["update_stdincome_id"] },
      reset_latest: ["global"],
    },
  };

  document_descriptor = {
    update_stdincome_pdf: {
      url: "document/generate_update_stdincome_pdf",
      name: "月額変更届",
      type: "pdf",
      rollover: {
        key: "employee_ids",
        count: 100,
      },
      processing_dialog: {},
    },
    update_stdincome_csv: {
      url: "document/generate_update_stdincome_csv",
      name: "SHFD0006",
      type: "csv",
      processing_dialog: {},
    },
  };

  reflect_type: number = 0;
  step: number = 0.1;
}
