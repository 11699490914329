import { Mutation, Action, VuexModule, getModule, Module } from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import node from "@/vuex/node";
import header from "@/vuex/header";
import router from "@/router";
import dialog from "@/vuex/dialog";
import before_login from "@/vuex/before_login";

export type SessionData = {
  company_id: number;
  company_code: string;
  employee_id: number;
  token: string;
  auth: number;
};

function validate(errors: (false | string)[]) {
  const filtered = errors.filter((v) => v !== false);
  if (filtered.length > 0) throw { type: "validation_error", error: filtered };
}

@Module({ dynamic: true, store, name: "session", namespaced: true })
class SessionLogin extends VuexModule {
  // state
  data: SessionData | null = null;
  punch_token?: string = null; //punch

  popup_news: any = null;
  trial_remain_day: number = null;
  credit_yearmonth: string = null;

  // mutation
  @Mutation
  public set(session: SessionData) {
    this.data = session;
  }
  @Mutation
  public disable({}) {
    this.data = null;
  }
  @Mutation
  public set_punch_token(val: string) {
    this.punch_token = val;
  }
  @Mutation
  public set_trial_remain_day(val: number) {
    this.trial_remain_day = val;
  }
  @Mutation
  public set_credit_yearmonth(val: string) {
    this.credit_yearmonth = val;
  }

  @Action({ rawError: true })
  public async reset_demo() {
    dialog.openProgressDialog({ title: "リセット中", msg: "リセットしています..." });
    await util.post("reset_demo", {}, false);
    dialog.openAlertDialog({ msg: "リセットが完了しました" });
  }

  // actions
  @Action({ rawError: true })
  public async login({
    company_code,
    mail,
    pass,
  }: {
    company_code: string;
    mail: string;
    pass: string;
  }) {
    validate([
      mail == "" && util.$t("error.enter_mail"),
      pass == "" && util.$t("error.enter_pass"),
    ]);

    const res = await util.post("login/login", { company_code, mail, pass }, false);
    if (res.data.code != 0) {
      if (res.data.code == 2) {
        throw { type: "login_no_admit_error" };
      } else {
        throw { type: "login_error" };
      }
    } else {
      const auth = res.data.auth;
      if (auth != 0) {
        util.changeLanguage("ja");
      }
      this.setSession({
        company_code: res.data.company_code,
        company_id: res.data.company_id,
        branch_id: res.data.branch_id,
        employee_id: res.data.employee_id,
        auth: res.data.auth,
        is_root: res.data.is_root,
      });
      if (res.data.is_credit_popup !== null) {
        this.set_credit_yearmonth(res.data.is_credit_popup);
        header.setIsOpenCreditDialog(true);
      } else if (res.data.is_start_popup) {
        header.setIsOpenStartDialog(true);
      } else if (res.data.is_end_popup) {
        this.set_trial_remain_day(res.data.traial_remain_day);
        header.setIsOpenEndDialog(true);
      } else if (res.popup_news !== null) {
        this.setPopupNews(res.data.popup_news);
        header.setIsOpenPopupnewsDialog(true);
      }

      header.disable({});
      await node.disable_all();

      if (auth == 2) {
        if (res.data.wizard == 0) {
          router.push({ name: "wizard_config" });
        } else if (res.data.wizard == 1) {
          router.push({ name: "wizard_employee" });
        } else {
          router.push({ name: "shift", query: util.passquery["shift"] });
        }
      } else if (auth == 0) {
        if (res.data.contract) {
          router.push({ name: "document_waiting" });
        } else {
          const login_gps = Number(util.getLocalStorage("login_gps"));
          if (login_gps !== undefined && Number(login_gps) == 1) {
            router.push({ name: "punch_inside", query: { type: 2 } });
          } else {
            router.push({ name: "shift", query: util.passquery["shift"] });
          }
        }
      } else {
        router.push({ name: "shift", query: util.passquery["shift"] });
      }
    }
    this.set(res.data);

    node.connect();
  }

  @Action({ rawError: true })
  public async app_login({ token, toshift }: { token: string; toshift: boolean }) {
    const res = await util.post("login/login_app_token", { token }, false);
    if (res.data.code != 0) {
      if (res.data.code == 2) {
        throw { type: "login_no_admit_error" };
      } else {
        throw { type: "login_error" };
      }
    } else {
      this.setSession({
        company_code: res.data.company_code,
        company_id: res.data.company_id,
        branch_id: res.data.branch_id,
        employee_id: res.data.employee_id,
        auth: res.data.auth,
        is_root: false,
      });
      const auth = res.data.auth;
      if (res.data.is_credit_popup !== null) {
        this.set_credit_yearmonth(res.data.is_credit_popup);
        header.setIsOpenCreditDialog(true);
      } else if (res.data.is_start_popup) {
        header.setIsOpenStartDialog(true);
      } else if (res.data.is_end_popup) {
        this.set_trial_remain_day(res.data.traial_remain_day);
        header.setIsOpenEndDialog(true);
      } else if (this.popup_news !== null) {
        this.setPopupNews(res.data.popup_news);
        header.setIsOpenPopupnewsDialog(true);
      }

      header.disable({});
      await node.disable_all();

      if (auth == 0) {
        if (res.data.contract) {
          if (toshift) router.push({ name: "document_waiting" });
        } else {
          const login_gps = Number(util.getLocalStorage("login_gps"));
          if (login_gps !== undefined && Number(login_gps) == 1) {
            if (toshift) router.push({ name: "punch_inside", query: { type: 2 } });
          } else {
            if (toshift) router.push({ name: "shift", query: util.passquery["shift"] });
          }
        }
      } else {
        if (toshift) router.push({ name: "shift", query: util.passquery["shift"] });
      }
    }
    this.set(res.data);

    node.connect();
  }

  @Action({ rawError: true })
  public async punch_login({
    company_code,
    branch_code,
    mail,
    pass,
  }: {
    company_code: string;
    branch_code: string;
    mail: string;
    pass: string;
  }) {
    validate([
      branch_code == "" && util.$t("error.enter_branch"),
      mail == "" && util.$t("error.enter_mail"),
      pass == "" && util.$t("error.enter_pass"),
    ]);

    const res = await util.post("punch/login", { company_code, branch_code, mail, pass }, false);
    if (res.code == 2) throw { type: "login_error" };
    else if (res.code == 1) throw { type: "branch_error" };
    this.set_punch_token(res.token);
  }
  @Action({ rawError: true })
  public async get() {
    if (this.data === null) {
      console.log("from session");
      await util.gotoIllegal(11);
    } else {
      console.log("reconnect");
      return this.data;
    }
  }

  @Action({ rawError: true })
  public async setSession({ company_code, company_id, branch_id, employee_id, auth, is_root }) {
    util.setSessionStorage("company_code", company_code);
    util.setLocalStorage("company_code", company_code);
    util.setLocalStorage("company_id", company_id);
    util.setLocalStorage("branch_id", branch_id);
    util.setLocalStorage("employee_id", employee_id);
    util.setLocalStorage("auth", auth);
    util.setLocalStorage("is_root", is_root ? 1 : 0);
  }

  @Action({ rawError: true })
  public async deleteSession() {
    util.deleteLocalStorage("company_id");
    util.deleteLocalStorage("branch_id");
    util.deleteLocalStorage("employee_id");
    util.deleteLocalStorage("auth");
  }

  @Mutation
  public setPopupNews(val: string) {
    this.popup_news = val;
  }

  @Action({ rawError: true })
  public async switch_login({ auth }: { auth?: number }) {
    const res = await util.post("login/switch_login", { auth }, false);
    if (res.data.code != 0) {
      throw { type: "login_error" };
    } else {
      this.setSession({
        company_code: res.data.company_code,
        company_id: res.data.company_id,
        branch_id: res.data.branch_id,
        employee_id: res.data.employee_id,
        auth: res.data.auth,
        is_root: res.data.is_root,
      });
      before_login.disable({});
      if (res.data.is_credit_popup !== null) {
        this.set_credit_yearmonth(res.data.is_credit_popup);
        header.setIsOpenCreditDialog(true);
      } else if (res.data.is_start_popup) {
        header.setIsOpenStartDialog(true);
      } else if (res.data.is_end_popup) {
        this.set_trial_remain_day(res.data.traial_remain_day);
        header.setIsOpenEndDialog(true);
      } else if (res.popup_news !== null) {
        this.setPopupNews(res.data.popup_news);
        header.setIsOpenPopupnewsDialog(true);
      }

      header.disable({});
      await node.disable_all();

      if (res.data.auth != 0) {
        util.changeLanguage("ja");
      }

      if (res.data.auth == 2) {
        if (res.data.wizard == 0) {
          router.push({ name: "wizard_config" });
        } else if (res.data.wizard == 1) {
          router.push({ name: "wizard_employee" });
        } else {
          router.push({ name: "shift", query: util.passquery["shift"] });
        }
      } else if (res.data.auth == 0) {
        if (res.data.contract) {
          router.push({ name: "document_waiting" });
        } else {
          const login_gps = Number(util.getLocalStorage("login_gps"));
          if (login_gps !== undefined && Number(login_gps) == 1) {
            router.push({ name: "punch_inside", query: { type: 2 } });
          } else {
            router.push({ name: "shift", query: util.passquery["shift"] });
          }
        }
      } else {
        router.push({ name: "shift", query: util.passquery["shift"] });
      }
    }
    this.set(res.data);

    node.connect();
  }

  @Action({ rawError: true }) //switch_loginで使用(ログイン後の権限切り替え)
  public async setAuth({ auth }) {
    util.setLocalStorage("auth", auth);
  }
}

export default getModule(SessionLogin);
