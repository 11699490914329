import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import Vue from "vue";
import { EmployeePayment } from "@/typesold/employee";

export type BranchData = {
  id: number;
  name: string;
};

export type BranchObject = {
  [id: number]: string;
};

export type Data = {
  employee: EmployeePayment;
  belong_branch_hash: any;
  resident_tax_years: any;
  is_valid?: boolean;
  is_set: boolean;
  fetching?: Promise<void>;
};

export type EmployeelyData = {
  [employee_id: number]: Data;
};

@Module({ dynamic: true, store, name: "employee_each_payment", namespaced: true })
class Global extends VuexModule {
  data: EmployeelyData = {};

  @Mutation
  public disable({ employee_id }: { employee_id: number }) {
    if (this.data[employee_id]) {
      this.data[employee_id].is_valid = false;
    }
  }
  @Mutation
  public disable_all() {
    Object.keys(this.data).forEach((employee_id) => {
      this.data[employee_id].is_valid = false;
    });
  }
  @Mutation
  public setFetching({ employee_id, value }: { employee_id: number; value: Promise<void> }) {
    this.data[employee_id].fetching = value;
  }
  @Mutation
  public set({ employee_id, data }: { employee_id: number; data }) {
    Vue.set(this.data, employee_id, {
      ...data,
      belong_branch_hash: util.createHash(data.belong_branches, "id", "name"),
      is_valid: true,
      is_set: true,
      fetching: null,
    });
  }

  // actions
  @Action({ rawError: true })
  public async fetch({ employee_id }: { employee_id: number }) {
    //ないなら作る
    if (!this.data[employee_id]) {
      Vue.set(this.data, employee_id, {
        employee: {},
        belong_branch_hash: {},
        is_valid: false,
        is_set: false,
        fetching: null,
      });
    }

    if (this.data[employee_id].fetching) {
      await this.data[employee_id].fetching;
    } else {
      const promise = util.post("employee_each/read_payment", { employee_id }).then((res) => {
        this.set({ employee_id, data: res.data });
      });
      this.setFetching({ employee_id, value: promise });
      await promise;
    }
  }

  @Action({ rawError: true })
  public updateEmployee({ employee_id, employee }) {
    Vue.set(this.data[employee_id], "employee", employee);
  }
}

export default getModule(Global);
