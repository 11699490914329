import Able_pattern from "@/typesold/able_pattern";
import * as util from "@/util";

//validationは、OKならTrue, それ以外なら警告文を配列に入れる。
export default function validationAblePattern(able_pattern: Able_pattern) {
  var error = [];

  error.push(able_pattern.name != null || util.$t("error.submit_enter_pattern_name"));
  error.push(
    able_pattern.able_pattern_time[0].started_at != null ||
      util.$t("error.submit_enter_pattern_shift_time"),
  );

  for (var i = 0; i < able_pattern.able_pattern_time.length; i++) {
    if (!validate_time_start(able_pattern.able_pattern_time[i]))
      error.push(util.$t("error.submit_enter_pattern_shift_start", { row: i + 1 }));
    if (!validate_time_end(able_pattern.able_pattern_time[i]))
      error.push(util.$t("error.submit_pattern_no_shift_end", { row: i + 1 }));
    if (!validate_time_order(able_pattern.able_pattern_time[i]))
      error.push(util.$t("error.submit_pattern_shift_time_contradiction", { row: i + 1 }));
    error = error.concat(
      validate_time_duplicate(able_pattern.able_pattern_time, i).map((j) =>
        util.$t("error.submit_pattern_duplicate_shift", { row1: i + 1, row2: j + 1 }),
      ),
    );
  }

  for (var i = 0; i < able_pattern.able_pattern_break.length; i++) {
    if (!validate_time_start(able_pattern.able_pattern_break[i]))
      error.push(util.$t("error.submit_pattern_no_break_start", { row: i + 1 }));
    if (!validate_time_end(able_pattern.able_pattern_break[i]))
      error.push(util.$t("error.submit_pattern_no_break_end", { row: i + 1 }));
    if (!validate_time_order(able_pattern.able_pattern_break[i]))
      error.push(util.$t("error.submit_pattern_break_time_contradiction", { row: i + 1 }));
    error = error.concat(
      validate_time_duplicate(able_pattern.able_pattern_break, i).map((j) =>
        util.$t("error.submit_pattern_duplicate_break", { row1: i + 1, row2: j + 1 }),
      ),
    );
  }

  return error;
}

function validate_time_start(time): boolean {
  return time.started_at !== null || time.ended_at === null;
}
function validate_time_end(time): boolean {
  return time.started_at === null || time.ended_at !== null;
}
function validate_time_order(time): boolean {
  return time.started_at === null || time.ended_at === null || time.started_at < time.ended_at;
}
function validate_time_duplicate(times, i): number[] {
  var error_j = [];
  for (var j = i + 1; j < times.length; j++) {
    if (times[i].started_at !== null && times[j].started_at !== null) {
      if (
        times[i].started_at !== null &&
        times[j].started_at !== null &&
        times[j].ended_at !== null &&
        times[j].started_at <= times[i].started_at &&
        times[i].started_at < times[j].ended_at
      )
        error_j.push(j);
      else if (
        times[i].ended_at !== null &&
        times[j].started_at !== null &&
        times[j].ended_at !== null &&
        times[j].started_at < times[i].ended_at &&
        times[i].ended_at <= times[j].ended_at
      )
        error_j.push(j);
      else if (
        times[j].started_at !== null &&
        times[i].started_at !== null &&
        times[i].ended_at !== null &&
        times[i].started_at <= times[j].started_at &&
        times[j].started_at < times[i].ended_at
      )
        error_j.push(j);
      else if (
        times[j].ended_at !== null &&
        times[i].started_at !== null &&
        times[i].ended_at !== null &&
        times[i].started_at < times[j].ended_at &&
        times[j].ended_at <= times[i].ended_at
      )
        error_j.push(j);
      else if (times[i].started_at == times[j].started_at) error_j.push(j);
    }
  }
  return error_j;
}
