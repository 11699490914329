import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import daily from "@/vuex/shift/daily";
import monthly from "@/vuex/shift/monthly";
import * as util from "@/util";
import validate from "@/validations/validate";
import validationNeeds from "@/validations/needs";
import validationNeedPattern from "@/validations/need_pattern";
import dialog from "@/vuex/dialog";
import Need from "@/typesold/need";
import Need_pattern from "@/typesold/need_pattern";
import Vue from "vue";
import realtime from "@/vuex/shift/realtime";
import global from "@/vuex/shift/global";
import manager from "./manager";
import need_global from "@/vuex/shift/need_global";
import header from "@/vuex/header";

@Module({ dynamic: true, store, name: "need_manager", namespaced: true })
class Need_manager extends VuexModule {
  is_valid: boolean = false;
  needs: { [date: string]: Need } = {};

  need_control: Need = {
    id: null,
    branch_id: null,
    date: null,
    need_time: [
      {
        id: null,
        need_id: null,
        started_at: null,
        ended_at: null,
        person_num: null,
      },
    ],
  };
  days_control: number[] = [];

  add_need_pattern_flag: boolean = false;
  edit_need_pattern_flag: number = 0;
  is_open_need_pattern_dialog: boolean = false;

  @Mutation
  private setAddNeedPatternFlag(val: boolean) {
    this.add_need_pattern_flag = val;
  }
  @Mutation
  private setEditNeedPatternFlag(val: number) {
    this.edit_need_pattern_flag = val;
  }
  @Mutation
  public setIsOpenNeedPatternDialog(val) {
    this.is_open_need_pattern_dialog = val;
  }
  @Mutation
  public setIsValid(val: boolean) {
    this.is_valid = val;
  }

  @Action({ rawError: true })
  public async is_open_input() {
    return this.add_need_pattern_flag || this.edit_need_pattern_flag != 0;
  }
  @Action({ rawError: true })
  public async addNeedPattern() {
    if (await this.is_open_input()) return;
    this.setAddNeedPatternFlag(true);
  }
  @Action({ rawError: true })
  public async editNeedPattern(need_pattern_id: number) {
    if (await this.is_open_input()) return;
    this.setEditNeedPatternFlag(need_pattern_id);
  }
  @Action({ rawError: true })
  public cancelAll() {
    this.setAddNeedPatternFlag(false);
    this.setEditNeedPatternFlag(0);
  }

  @Mutation
  setNeeds({
    yearmonth,
    branch_id,
    needs,
  }: {
    yearmonth: string;
    branch_id: number;
    needs: Need[];
  }) {
    const date_array = util.month_date_array(yearmonth);
    this.needs = {};
    date_array.forEach((date) => {
      if (needs[date]) {
        Vue.set(this.needs, date, needs[date]);
      } else {
        const need: Need = {
          id: null,
          branch_id: branch_id,
          date: date,
          need_time: [],
        };
        Vue.set(this.needs, date, need);
      }
    });
  }

  @Action({ rawError: true })
  async getNeeds({ yearmonth, branch_id }: { yearmonth: string; branch_id: number }) {
    this.setIsValid(false);
    const res = await realtime.getNeed({ yearmonth, branch_id });
    const needs = util.createIndexObject(res.needs, "date");
    this.setNeeds({ yearmonth, branch_id, needs });
    this.setIsValid(true);
  }
  @Mutation
  setNeed({ date, need }: { date: string; need: Need }) {
    Vue.set(this.needs, date, need);
  }

  @Mutation
  setNeedControl({ need }: { need: Need }) {
    this.need_control = need;
  }

  @Mutation
  setDaysControl({ days }: { days: number[] }) {
    this.days_control = days;
  }

  @Action({ rawError: true })
  public async applyControl({
    yearmonth,
    national_holidays,
  }: {
    yearmonth: string;
    national_holidays: string[];
  }) {
    const date_array = util.month_date_array(yearmonth);
    date_array.forEach(async (date) => {
      if (util.isNationalHoliday(national_holidays, date)) {
        if (this.days_control.indexOf(7) !== -1) {
          Vue.set(this.needs[date], "need_time", util.deep_copy(this.need_control.need_time));
        }
      } else if (this.days_control.indexOf(util.date2day(date)) !== -1) {
        Vue.set(this.needs[date], "need_time", util.deep_copy(this.need_control.need_time));
      }
    });
  }

  @Action({ rawError: true })
  public updateNeeds({
    yearmonth,
    branch_id,
    comment,
  }: {
    yearmonth: string;
    branch_id: number;
    comment: string | null;
  }) {
    if (validate(validationNeeds(this.needs))) {
      dialog.openConfirmDialog({
        msg: "シフト募集を実行してよろしいですか？",
        detail: "対象社員にシフト提出依頼メールが送信されます。",
        cancel_button: "実行しない",
        exec_button: "実行する",
        func: this.updateNeedsProcess,
        is_next_dialog: true,
        args: { yearmonth, branch_id, comment },
      });
    }
  }
  @Action({ rawError: true })
  public async updateNeedsProcess({
    yearmonth,
    branch_id,
    comment,
  }: {
    yearmonth: string;
    branch_id: number;
    comment: string | null;
  }) {
    dialog.openProgressDialog({
      title: "処理中",
      msg: "この処理には時間がかかることがあります。",
      detail: "そのままお待ちください。",
    });
    await util.post("shift/update_needs", { yearmonth, branch_id, comment, needs: this.needs });
    manager.setIsOpenNeedDialog(false);
    monthly.disable({ yearmonth, branch_id });
    dialog.openAlertDialog({
      msg: "シフト募集が完了しました。",
      detail: "対象社員にシフト提出依頼メールが送信されました。",
    });
  }

  @Action({ rawError: true })
  public createNeedPattern({
    branch_id,
    need_pattern,
  }: {
    branch_id: number;
    need_pattern: Need_pattern;
  }) {
    if (validate(validationNeedPattern(need_pattern))) {
      dialog.openConfirmDialog({
        msg: "募集パターンを作成してよろしいですか？",
        cancel_button: "作成しない",
        exec_button: "作成する",
        func: this.createNeedPatternProcess,
        args: { branch_id, need_pattern },
      });
    }
  }
  @Action({ rawError: true })
  public async createNeedPatternProcess({
    branch_id,
    date,
    need_pattern,
  }: {
    branch_id: number;
    date: string;
    need_pattern;
  }) {
    need_global.createNeedPattern({ branch_id, need_pattern });
    this.cancelAll();
    await util.post("shift/create_need_pattern", { branch_id, date, need_pattern });
    need_global.disable({ branch_id });
  }

  @Action({ rawError: true })
  public updateNeedPattern({
    branch_id,
    need_pattern_id,
    need_pattern,
  }: {
    branch_id: number;
    need_pattern_id: number;
    need_pattern;
  }) {
    if (validate(validationNeedPattern(need_pattern))) {
      dialog.openConfirmDialog({
        msg: "募集パターンを更新してよろしいですか？",
        cancel_button: "更新しない",
        exec_button: "更新する",
        func: this.updateNeedPatternProcess,
        args: { branch_id, need_pattern_id, need_pattern },
      });
    }
  }
  @Action({ rawError: true })
  public async updateNeedPatternProcess({
    branch_id,
    need_pattern_id,
    need_pattern,
  }: {
    branch_id: number;
    need_pattern_id: number;
    need_pattern;
  }) {
    need_global.updateNeedPattern({ branch_id, need_pattern_id, need_pattern });
    this.cancelAll();
    await util.post("shift/update_need_pattern", { branch_id, need_pattern_id, need_pattern });
    need_global.disable({ branch_id });
  }

  @Action({ rawError: true })
  public async deleteNeedPattern({
    branch_id,
    need_pattern_id,
  }: {
    branch_id: number;
    need_pattern_id: number;
  }) {
    dialog.openForceConfirmDialog({
      msg: "募集パターンを削除してよろしいですか？",
      detail: "削除した募集パターンは戻すことができません",
      cancel_button: "削除しない",
      exec_button: "削除する",
      func: this.deleteNeedPatternProcess,
      args: { branch_id, need_pattern_id },
    });
  }
  @Action({ rawError: true })
  public async deleteNeedPatternProcess({
    branch_id,
    need_pattern_id,
  }: {
    branch_id: number;
    need_pattern_id: number;
  }) {
    need_global.deleteNeedPattern({ branch_id, need_pattern_id });
    await util.post("shift/delete_need_pattern", { branch_id, need_pattern_id });
    need_global.disable({ branch_id });
  }
}

export default getModule(Need_manager);
