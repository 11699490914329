import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import Vue from "vue";
import Shift from "@/typesold/shift";
import Able from "@/typesold/able";
import Shift_memo from "@/typesold/shift_memo";

export type EmployeeHash = {
  [id: number]: string;
};
export type Data = {
  contract_document: any;
  pdf: any;
  is_valid?: boolean;
  is_set: boolean;
  fetching?: Promise<void>;
};
export type ContractDocumentData = {
  [contract_document_id: number]: Data;
};

@Module({ dynamic: true, store, name: "contract_document", namespaced: true })
class Monthly extends VuexModule {
  data: ContractDocumentData = {};

  @Mutation
  public set({ contract_document_id, data }: { contract_document_id: number; data: Data }) {
    Vue.set(this.data, contract_document_id, {
      ...data,
      pdf: null,
      is_valid: true,
      is_set: true,
      fetching: null,
    });
  }

  @Mutation
  public setPdf({ contract_document_id, pdf }: { contract_document_id: number; pdf }) {
    Vue.set(this.data[contract_document_id], "pdf", pdf);
  }

  @Mutation
  public disable({ contract_document_id }: { contract_document_id?: number }) {
    if (contract_document_id === undefined) {
      Object.keys(this.data).forEach((contract_document_id) => {
        if (this.data[contract_document_id]) {
          this.data[contract_document_id].is_valid = false;
        }
      });
    } else {
      if (this.data[contract_document_id]) {
        this.data[contract_document_id].is_valid = false;
      }
    }
  }
  @Mutation
  public disable_all() {
    Object.keys(this.data).forEach((contract_document_id) => {
      this.data[contract_document_id].is_valid = false;
    });
  }
  @Mutation
  public setFetching({
    contract_document_id,
    value,
  }: {
    contract_document_id: number;
    value: Promise<void>;
  }) {
    this.data[contract_document_id].fetching = value;
  }

  @Action({ rawError: true })
  public async fetchPdf({ contract_document_id }: { contract_document_id: number }) {
    const promise = util
      .post_document_return("document/generate_contract_document", { contract_document_id })
      .then((res) => {
        this.setPdf({ contract_document_id, pdf: res });
      });
    await promise;
  }

  // actions
  @Action({ rawError: true })
  public async fetch({ contract_document_id }: { contract_document_id: number }) {
    //ないなら作る
    if (!this.data[contract_document_id]) {
      Vue.set(this.data, contract_document_id, {
        contract_document: {},
        pdf: null,
        is_valid: false,
        is_set: false,
        fetching: null,
      });
    }

    if (this.data[contract_document_id].fetching) {
      await this.data[contract_document_id].fetching;
    } else {
      const promise = util
        .post("document/read_contract_document", { contract_document_id })
        .then((res) => {
          this.set({ contract_document_id, data: res.data });
          this.fetchPdf({ contract_document_id });
        });
      this.setFetching({ contract_document_id, value: promise });
      await promise;
    }
  }
}

export default getModule(Monthly);
