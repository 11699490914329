import * as util from '@/util';
import * as vali from '@/validations/validationUtil';

export function validationUpdateStdincome(self, args) {
  var error = [];

  error.push(args.update_stdincome.started_yearmonth !== null || '計算期間開始日が正しく入力されていません');

  return error;
}

export function validationUpdateStdincomeMasters(self, args) {
  var error = [];
  Object.keys(args.update_stdincome_masters).forEach((id, i) => {
    error.push(args.update_stdincome_masters.hoge !== null || 'グループ'+(i + 1)+'のホゲを入力してください');
  });
  return error;
}
