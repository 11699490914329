import info_language from "./info_language";
import popup_language from "./popup_language";
import error_language from "./error_language";

const ja = {
  name: "名前",
  kana: "カナ",
  last_name: "姓",
  first_name: "名",
  last_name_kana: "セイ",
  first_name_kana: "メイ",
  auth: "権限",
  hiring_type: "区分",
  parttime_employee: "パート・アルバイト",
  fulltime_employee: "社員",
  board_employee: "役員",
  other_employee: "その他",
  auth_employee: "一般",
  auth_branch: "店舗管理者",
  auth_company: "会社管理者",
  main_branch: "主所属部署/店舗",
  sub_branch: "従所属部署/店舗",
  belong_branch: "所属部署/店舗",
  branch_name: "部署/店舗名",
  branch: "部署/店舗",
  branch_code: "部署/店舗ID",
  post: "役職",
  belong: "所属",
  employee: "社員",

  employee_type: "用途",
  all: "全て",
  shift: "シフト",
  attendance: "勤怠",
  shift_attendance: "シフト・勤怠",
  payment: "給与",
  payment_bonus: "給与・賞与",
  payment_bonus_nencho: "給与・賞与・年末調整",
  payment_bonus_nencho_short: "給与賞与年調",
  payment_bonus_tax_short: "給与賞与減税",
  bonus: "賞与",
  nencho: "年末調整",
  nencho_short: "年調",
  fixed_tax_reduction_short: "減税",
  over_allowance: "残業手当",
  notification: "通知",
  allowance: "手当",

  employee_page: "社員一覧",
  self_info: "本人情報",
  self_general: "本人情報全般",
  labor_general: "労務情報全般",
  general: "全般",
  labor_info: "労務情報",
  payment_config: "給与設定",
  base_info: "基本情報",
  taxins_config: "税・社会保険設定",
  family_dependant: "家族（扶養親族）",
  employee_bank: "振込口座",
  employee_bank1: "振込口座1",
  employee_bank2: "振込口座2",
  regist_employee_bank: "振込口座の登録",

  no_regist: "未登録",
  regist: "登録する",
  already_regist: "登録済",

  bank_regular: "普通",
  bank_current: "当座",
  bank_save: "貯蓄",

  bank_name: "金融機関名",
  yucho_branch_name: "記号(5桁)",
  bank_branch_name: "支店名",
  bank_type: "種類",
  bank_num: "口座番号",
  bank_holder: "口座名義",
  click_candidate: "候補をクリック",
  input_bank_name: "金融機関名を入力",
  input_branch_name: "支店名を入力",
  employee_bank1_limit: "振込口座1の上限金額",

  nickname: "シフトページの表示名",
  nickname_placeholder: "ブランクであれば本名を表示",
  sex: "性別",
  male: "男性",
  female: "女性",
  birth_date: "生年月日",
  year: "年",
  month: "月",
  day: "日",
  invalid_date: "無効な年月日",
  mynum: "マイナンバー",
  mail: "メールアドレス(ID)",
  pass: "パスワード",
  confirm: "確認",

  zip: "郵便番号",
  address: "住所",
  asof: "%{year}年12月31日時点での",
  tel: "自宅電話番号",
  mobile: "携帯電話番号",

  tax_type: "税額表区分（甲乙）",
  tax_type_detail: "現在、他でも働いていて、その勤務先に扶養控除等申告書を提出していますか",
  is_resident_fixed_tax_reduction: "2024年06月01日時点で居住者ですか",
  is_resident_spouce_fixed_tax_reduction: "配偶者は2024年06月01日時点で居住者ですか",
  is_resident_dependant_fixed_tax_reduction: "2024年06月01日時点で居住者ですか",

  kou: "甲",
  otsu: "乙",
  kou_no: "いいえ(甲)",
  otsu_yes: "はい(乙)",
  dependant_num: "扶養人数(自動計算)",
  different_real_dependant_num: "実際の扶養人数とは異なる場合があります。",
  householder_name: "世帯主の氏名",
  householder_relationship: "世帯主との続柄",
  relationship_name: "続柄名",
  relationship: "続柄",
  estimated_income: "今年の所得見積額",
  payment_income: "給与収入",
  retire_income: "退職所得金額",
  in_retire_income: "内退職所得金額",
  other_income: "給与収入以外の所得金額",
  other_income_without_retire_income: "給与収入・退職所得以外の所得金額",
  sum_income: "合計所得金額",
  your_estimated_income: "あなたの今年の所得見積額",
  yen: "円",
  person: "人",
  year_old: "歳",
  self: "本人",
  husband: "夫",
  wife: "妻",
  father: "父",
  mother: "母",
  other: "その他",
  eldest_son: "長男",
  second_son: "二男",
  third_son: "三男",
  forth_son: "四男",
  fifth_son: "五男",
  sixth_son: "六男",
  eldest_daughter: "長女",
  second_daughter: "二女",
  third_daughter: "三女",
  forth_daughter: "四女",
  fifth_daughter: "五女",
  sixth_daughter: "六女",
  child: "子",
  stepfather: "義父",
  stepmother: "義母",
  grandfather: "祖父",
  grandmother: "祖母",
  stepgrandfather: "義祖父",
  stepgrandmother: "義祖母",
  grandson: "孫",
  nephew: "甥",
  niece: "姪",
  uncle: "叔父",
  aunt: "叔母",
  older_brother: "兄",
  younger_brother: "弟",
  step_older_brother: "義兄",
  step_younger_brother: "義弟",
  older_sister: "姉",
  younger_sister: "妹",
  step_older_sister: "義姉",
  step_younger_sister: "義妹",

  there_isnt: "なし",
  there_is: "あり",
  config_is: "設定する",
  config_isnt: "設定しない",
  disability: "障害者",
  your_disability: "あなたに障害はありますか",

  applicable: "該当する",
  not_applicable: "該当しない",
  normal_disability: "一般障害者",
  special_disability: "特別障害者",
  disability_reason: "障害に該当する事実",

  single_child: "ひとり親",
  single_child_widow: "ひとり親・寡婦",
  widow: "寡婦",
  work_student: "勤労学生",
  your_single_child: "あなたはひとり親に該当しますか",
  your_widow: "あなたはひとり親、又は寡婦に該当しますか",
  your_work_student: "あなたは勤労学生に該当しますか",
  school_and_year: "学校名と入学年",

  housemate: "同居",
  not_housemate: "同居していない",
  yes_housemate: "同居している",
  housemate_with_spouce: "配偶者や生計を一にする親族との同居",
  not_resident: "非居住者",
  remittance_to_dependant: "扶養親族への送金額",
  remittance_to_spouce: "配偶者への送金額",
  housemate_with_livelihood: "生計を一にする親族との同居",
  international_student: "留学生",
  dependant_international_student: "扶養親族%{index}は留学生に該当しますか",
  nencho_employee_page: "本人情報等の確認",
  take_5_minutes: "所要時間 約5分",
  refer_document: "参照資料",
  refer_mynumber_document: "マイナンバーが確認できるもの",
  nencho_employee_finish: "本人情報等の確認完了",
  nencho_employee_finish_msg: "お疲れ様でした。",
  return_to_nencho_page: "年末調整ページへ戻る",

  fixed_tax_reduction_employee_page: "本人情報等の確認",
  fixed_tax_reduction_employee_finish: "本人情報等の確認完了",
  fixed_tax_reduction_employee_finish_msg: "お疲れ様でした。",
  return_to_fixed_tax_reduction_page: "定額減税ページへ戻る",

  employee_code: "社員コード(ID)",
  job_content: "業務内容",
  week_prescribed_day: "週所定労働日数",
  week_prescribed_time: "週所定労働時間",
  month_prescribed_day: "月所定労働日数",
  month_prescribed_time: "月所定労働時間",
  joined_on: "入社年月日",
  retired_on: "退社年月日",
  contract_started_on: "契約開始年月日",
  contract_ended_on: "契約終了年月日",
  memo: "メモ",
  carfare: "通勤手当",
  daily_money: "日額",
  monthly_money: "月額",
  do_you_use_carfare: "通勤に公共交通機関やマイカーを使用しますか",
  using_train_or_car: "公共交通機関やマイカーの使用",
  select_using: "使用しているものを選択してください(複数可)",
  route: "経路",
  no: "いいえ",
  yes: "はい",
  mycar_km: "マイカー使用部分の片道距離",

  yes_either: "いずれかに該当する",
  no_either: "いずれにも該当しない",
  spouce: "配偶者",
  spouce_info: "配偶者情報",
  is_spouce: "配偶者はいますか",
  is_spouce_either: "配偶者は次のいずれかに該当しますか",
  spouce_name: "配偶者の名前",
  spouce_birth_date: "配偶者の生年月日",
  spouce_mynum: "配偶者のマイナンバー",
  spouce_housemate: "配偶者との同居",
  spouce_address: "配偶者の住所",
  spouce_not_resident: "配偶者は非居住者に該当しますか",
  spouce_estimated_income: "配偶者の今年の所得の見積額",
  spouce_disability: "配偶者に障害はありますか",
  spouce_housemate_with_livelihood: "配偶者はあなたと生計を一にする親族と同居していますか",
  spouce_caution_1: "所得金額が133万円超(給与収入201.6万円超)",
  spouce_caution_2: "あなたと生計を一にしていない",
  spouce_caution_3: "青色事業専従者として給与の支払を受けている",
  spouce_caution_4: "白色事業専従者",
  spouce_caution_5: "他の人の扶養親族とされている",
  spouce_caution_6: "配偶者自身が配偶者特別控除の適用を受けている",

  how_many_dependants:
    '以下の<span style="color:red">全て</span>に該当する<span style="color:red">あなたが</span>扶養している親族(16歳未満も含む)は何人いますか',
  dependant_caution_1: "所得金額が48万円以下(給与収入103万円以下)",
  dependant_caution_2: "あなたと生計を一にしている",
  dependant_caution_3: "青色事業専従者として給与の支払いを受けていない",
  dependant_caution_4: "白色事業専従者ではない",
  dependant_caution_5: "他の人の扶養親族とされていない",
  dependants_num: "扶養親族の人数",

  dependant_info: "扶養親族情報",
  dependant: "扶養親族%{index}",
  dependant_name: "扶養親族%{index}の名前",
  dependant_birth_date: "扶養親族%{index}の生年月日",
  dependant_mynum: "扶養親族%{index}のマイナンバー",
  dependant_relationship: "扶養親族%{index}の続柄",
  dependant_housemate: "扶養親族%{index}との同居",
  dependant_address: "扶養親族%{index}の住所",
  dependant_not_resident: "扶養親族%{index}は非居住者に該当しますか",
  dependant_remittance_to_dependant: "扶養親族%{index}への送金額",
  dependant_estimated_income: "扶養親族%{index}の今年の所得の見積額",
  dependant_disability: "扶養親族%{index}に障害はありますか",
  dependant_housemate_with_livelihood:
    "扶養親族%{index}はあなたの配偶者かあなたと生計を一にする親族と同居していますか",

  work_type: "勤務形態",
  normal: "通常",
  deemed_over: "固定残業制",
  monthly_modifiedtime: "月単位変形労働制",
  monthly_flextime: "月単位フレックスタイム制",
  core_time: "コアタイム",

  base_wage: "基本給",
  base_wage_pres: "基本給/役員報酬",
  hourly_wage: "時給",
  monthly_wage: "月給",
  manual: "手動",
  main_hourly_wage: "メイン時給",
  delete: "削除",
  add_exception_hourly_wage: "例外時給追加",
  usable_after_add_employee: "社員を追加後に使用可能です。",
  usable_after_admit_employee: "社員を承認に使用可能です。",

  sunday: "日",
  monday: "月",
  tuesday: "火",
  wednesday: "水",
  thursday: "木",
  friday: "金",
  saturday: "土",
  holiday: "祝",

  sunday_full: "日曜日",
  monday_full: "月曜日",
  tuesday_full: "火曜日",
  wednesday_full: "水曜日",
  thursday_full: "木曜日",
  friday_full: "金曜日",
  saturday_full: "土曜日",
  holiday_full: "祝日",

  exception_hourly_wage: "例外時給",
  delete_confirm_join_dependant: "扶養親族%{index}を削除してよろしいですか？",
  not_delete: "削除しない",
  yes_delete: "削除する",
  join_admit: "入社承認",
  demand_admit: "社員情報変更承認",

  training_hourly_wage: "研修時給",
  time_count: "時間カウント",
  day_count: "日数カウント",
  time: "時間",
  total: "累計",
  within_work_valid: "以内の勤務で有効",
  base_pay: "基礎単価",
  auto: "自動",
  is_train: "公共交通機関",
  is_car: "マイカー",
  preover: "法定内残業",
  lawover: "法定外残業",
  by_time: "時間まで",
  mid_allowance: "深夜手当",
  holiday_allowance: "休日手当",
  deemed_over_allowance: "固定残業手当",
  outhol: "法定外休日",
  lawhol: "法定休日",
  disp_gensentax: "源泉徴収票への表示",
  paidhol: "有給休暇",
  paidhol_allowance: "有給手当",
  use_paid_hol_type: "有給休暇全日使用単価",
  buy_paid_hol_type: "有給休暇買取単価",
  gensentax: "源泉所得税",
  calc_gensentax: "源泉所得税の計算",

  no_use: "使用しない",
  yes_use: "使用する",
  no_calc: "計算しない",
  yes_calc: "計算する",
  restaxspan: "住民税(%{start_year}年%{start_month}月〜%{end_year}年%{end_month}月)",
  restax: "住民税",
  pay_zip: "納付先郵便番号",
  pay_place: "納付先市区町村",
  year_money: "年額",
  month_money: "月額",
  social_ins: "社会保険",
  union_ins: "組合国保",
  health_care_ins: "健康・介護保険",
  health_ins: "健康保険",
  care_ins: "介護保険",
  pension_ins: "厚生年金保険",
  social_ins_adj: "社会保険料等調整",
  income_tax: "所得税",
  fixed_tax_reduction: "定額減税",
  child_care: "産前産後・育児休業期間",
  thousand_yen: "千円",
  std_income: "標準報酬月額",
  double_biz: "二以上事業所勤務者",
  pension_ins_id: "基礎年金番号",
  social_ins_id: "被保険者整理番号",
  social_ins_got_on: "資格取得年月日",
  emp_ins: "雇用保険",
  emp_ins_id: "被保険者番号",
  emp_ins_got_on: "資格取得年月日",
  comp_ins: "労災保険",
  comp_ins_got_on: "資格取得年月日",

  initial_value: "初期値",
  auto_give: "自動付与",
  manual_give: "手動付与",
  use: "使用",
  buy: "買取",
  timeout: "時効",
  over40: "40超過",
  short_of_attendance: "出勤率不足",

  shiftgroup: "シフトグループ",
  paymentgroup: "給与グループ",
  group: "グループ",

  company_info: "会社情報",
  company: "会社",
  company_name: "会社名",
  company_code: "会社ID",
  office_name: "事業所名",
  biz_name: "事業種目",
  company_mynum: "法人番号",
  pres: "事業主",
  staff: "担当者",
  address_info: "住所情報",
  company_tel: "電話番号",
  fax: "FAX番号",
  url: "URL",
  bank_info: "口座情報",
  company_bank: "会社口座",
  bank_break: "全銀テキスト改行",
  bank_biz_code: "振込依頼人コード",
  transfer_type: "業務種別",
  comprehensive: "総合",
  taxjob_info: "税務情報",
  tax_office_id: "署番号",
  tax_office_num: "税務署番号",
  jurisdiction_tax_office: "所轄税務署",

  seiri_num: "整理番号",
  office_seiri_code: "事業所整理記号",
  office_num: "事業所番号",
  socialins_info: "社会保険情報",
  empins_info: "雇用保険情報",
  empins_office_num: "雇用保険適用事業所番号",
  hellowork: "安定所名",

  work_ins_kind: "事業の種類の分類",
  work_ins_type: "事業の種類",
  special_biz: "特例措置対象事業所",
  select_work_ins_kind: "事業の種類の分類を選択",

  branch_config: "店舗設定",
  cannot_delete_main_branch: "本社は削除できません。",

  config_page: "各種設定",
  base_config: "基本設定",
  monthly: "月ごと",
  calc_span: "給与計算期間",
  shift_summery: "シフトサマリー",
  show_other_shift: "マイページでの表示",
  only_mine: "自分のみ",
  if_is_shift: "自分のシフトが存在する日の全員",
  all_members: "全員",
  shift_punch_sync: "打刻からシフト作成",
  title: "タイトル",
  person_num: "人数",
  person_count: "人",
  edit_member: "メンバー編集",
  edit_member_of: "%{name}の所属編集",
  do_change: "変更する",

  holiday_config: "休日設定",
  round_config: "丸め設定",
  punch_round: "勤怠丸め",
  break_round: "休憩丸め",
  start_time: "開始時刻",
  end_time: "終了時刻",

  adjust_to_shift: "シフトに合わせる",
  minute_unit: "分単位で",

  math_ceil: "切り上げ",
  math_floor: "切り捨て",
  math_round: "四捨五入",
  disp_to_employee: "社員への表示",
  punch_time: "打刻時刻",
  round_time: "丸め時刻",
  punch_time_and_round_time: "打刻時刻と丸め時刻",
  punch_config: "打刻設定",
  config_punch_gps: "GPS打刻",
  config_punch_mypc: "MyPC打刻",
  config_punch_alert: "シフト外出勤アラート",
  other_config: "その他設定",
  add_paidhol_time: "有給・特別休暇時間を加算",

  no_add: "加算しない",
  yes_add: "加算する",
  show_attendance_chart: "社員へのチャート表示",
  show: "表示",
  not_show: "非表示",
  only_show: "表示のみ",
  editable: "編集可能",
  is_deem_preover: "固定残業に法定内残業を加算",
  count_only_lawover: "法定外残業のみをカウント",
  count_preover_and_lawover: "法定内残業と法定外残業をカウント",
  no_shift_error: "シフトなしエラー",
  pay_config: "支払設定",
  close_day: "締め日",
  last_day_of_the_month: "月末",
  pay_month: "支払月",
  this_month: "当月",
  next_month: "翌月",
  pay_day: "支払日",
  levy_month: "社会保険料徴収時期",
  levy_from_this_month: "当月徴収",
  levy_from_next_month: "翌月徴収",
  rate_config: "割増賃金設定",
  lawover_rate: "法定外残業",
  preover_rate: "法定内残業",
  sixtylawover_rate: "60時間超法定外残業",
  outhol_rate: "法定外休日",
  lawhol_rate: "法定休日",
  mid_rate: "深夜",

  slip_config: "明細設定",
  show_zero: "0円項目表示",
  remain_paid_hol: "有給休暇残日数表示",

  payment_allowance: "給与手当項目",
  payment_deduction: "給与控除項目",
  payment_other_allowance: "給与その他項目",
  bonus_allowance: "賞与手当項目",
  bonus_deduction: "賞与控除項目",
  bonus_other_allowance: "賞与その他項目",
  item_name: "項目名",
  item: "項目",
  apply_group: "適用グループ",
  is_tax_short: "課税",
  is_socialins_short: "社保",
  is_laborins_short: "労保",
  is_basepay_short: "賃金",
  is_cashpay_short: "現物",
  is_tax: "課税対象",
  is_tax_deduction_short: "課税上控除",
  is_tax_deduction: "課税上控除対象",
  mutualaid: "小規模企業共済等掛金",
  is_socialins: "社会保険対象",
  is_laborins: "労働保険対象",
  is_basepay: "基礎賃金の対象",
  is_cashpay: "現物支給",
  depend_on_employee_config: "社員設定による",
  times_number: "回",
  default: "デフォルト",

  cross: "×",
  circle: "◯",
  triangle: "△",

  same_as_prev_month: "前月と同じ",
  unit_and_num: "単価×回数",
  fixed_value: "固定値",
  basepay_and_num: "基礎単価×回数",
  individual_unit_and_num: "個人別単価×回数",
  minute: "分",
  hour: "時",
  hour_format: "%{hour}時間",

  manual_num: "手動(回数)",
  manual_hour: "手動(時間)",
  prescribed_day: "所定労働日数",
  sum_day: "合計勤務日数",
  reg_day: "通常勤務日数",
  lawhol_day: "法定休日勤務日数",
  outhol_day: "法定外休日勤務日数",
  mid_day: "深夜勤務日数",
  early_day: "早退日数",
  late_day: "遅刻日数",
  abs_day: "欠勤日数",
  paidhol_day: "有給休暇日数",
  specialhol_day: "特別休暇日数",
  transferhol_day: "振替休日日数",
  comphol_day: "代休日数",
  prescribed_time: "所定労働時間",
  sum_time: "合計勤務時間",
  reg_time: "通常勤務時間",
  lawhol_time: "法定休日勤務時間",
  outhol_time: "法定外休日勤務時間",
  mid_time: "深夜勤務時間",
  early_time: "早退時間",
  late_time: "遅刻時間",
  preover_time: "法定内残業時間",
  lawover_time: "法定外残業時間",

  basepay_plus_hour: "+ 基礎単価 / 時",
  basepay_minus_hour: "- 基礎単価 / 時",
  basepay_plus_day: "+ 基礎単価 / 日",
  basepay_minus_day: "- 基礎単価 / 日",
  already_top: "既に一番上です",
  already_bototm: "既に一番下です",

  bar: "ー",
  middle_mutualaid: "中小企業基盤整備機構の共済契約の掛金",
  company_mutualaid: "確定拠出年金法の企業型年金加入者掛金",
  personal_mutualaid: "確定拠出年金法の個人型年金加入者掛金",
  disability_mutualaid: "心身障害者扶養共済制度の掛金",
  settlement: "現物支給精算金",

  allowance_config: "手当・控除設定",

  transfer_unit: "振込単位",
  company_unit: "会社単位",
  branch_unit: "店舗単位",

  delay_alert: "遅刻アラート",
  attendance_error_alert: "勤怠エラーアラート",
  delay_time: "遅刻時間",

  system_config: "システム設定",
  alert_function: "通知機能",
  alert_destination: "通知先",

  myself: "本人",
  need_alert: "シフト募集",
  order: "申請",
  admit_auth: "承認権限",
  mail_alert: "メール通知",
  order_way: "申請方式",
  auth_branch_and_company: "店舗管理者と会社管理者",
  only_prev_demand: "事前申請のみ",
  no_limit: "制限なし",
  order_overtime: "残業申請",
  order_holiday: "休暇申請",
  order_change: "変更申請",
  order_punch: "打刻修正申請",
  order_join: "入社申請",

  advanced_config: "高度な設定",
  option_function: "オプション機能",
  use_nickname: "ニックネームの使用",
  gensentax_document: "源泉徴収票",
  document: "書類",
  bank_count: "口座数",
  bank_count_1: "1口座",
  bank_count_2: "2口座",
  branch_hourly_wage: "店舗別時給",
  is_income_tax_opt: "源泉所得税の計算の有無",
  use_double_biz: "二以上事業所勤務者の有無",
  is_day_over: "日残業の計算",
  is_week_over: "週残業の計算",
  is_month_over: "月残業の計算",
  custom_info_item: "カスタム情報",
  carfare_type: "通勤手当の有無",
  carfare_way: "通勤手段",

  employee_info_date_free: "日付-自由",
  employee_info_free_free: "自由-自由",
  employee_info_free: "自由入力欄",
  employee_info_option: "選択肢",
  employee_info_image: "画像",
  employee_info_link: "リンク",

  option1: "選択肢1",
  option2: "選択肢2",
  branch_auth: "店舗管理者権限",
  lock: "ロック",
  disp_type: "閲覧区分",
  gps_disp_type: "GPS閲覧区分",
  reflect: "反映",

  employee_self: "本人情報・所属",
  employee_company: "労務情報・例外設定",
  employee_paidhol: "有給休暇",

  config: "設定",
  config_company: "基本設定・手当控除設定・部署/店舗",
  config_group: "グループ",
  unable: "できない",
  able: "できる",
  exception_config: "例外設定",
  use_exception_config: "例外設定の使用",

  before_join: "入社前の社員",
  calc_paidhol: "有給休暇日数自動計算",
  continuoustime_error: "週勤務時間エラー",
  continuousday_error: "連続勤務日数エラー",
  allow: "許可",
  disallow: "許可しない",
  allow_login_app_without_notification: "通知許可をせずにアプリログイン",
  change_mail: "メールアドレス変更",
  change_pass: "パスワード変更",
  current_mail: "現在のメールアドレス",
  new_mail: "新しいメールアドレス",
  change_password: "パスワード変更",
  now_password: "現在のパスワード",
  new_password: "新しいパスワード",
  confirm_password: "新しいパスワード(確認)",
  regist_mypc: "MyPC登録",

  branch_bank_config: "店舗口座設定",
  use_bank: "使用口座",
  branch_bank: "店舗口座",
  prescribed: "所定労働",
  join_retire: "入社・退社",
  join_admit_name: "%{name}さんの入社承認",
  join_deny: "入社申請差し戻し",
  join_deny_reason: "差し戻し理由",
  join_content_name: "%{name}さんの入社申請確認",
  demand_admit_name: "%{name}さんの社員情報変更承認",

  not_target_of_fixed_tax_reduction: "定額減税の対象外です。",
  not_target_of_fixed_tax_reduction_spouce: "定額減税の条件を満たしていない配偶者です。",
  not_target_of_fixed_tax_reduction_dependant: "定額減税の条件を満たしていない扶養親族です。",

  earning: "収入と所得",
  add_employee: "社員登録",

  individual_unit: "個人別単価",
  branch_carfare: "店舗別通勤手当",

  exception_carfare: "例外通勤手当",
  add_exception_carfare: "例外通勤手当追加",

  attention_self_info_1: "本人情報を変更したい場合は、",
  attention_self_info_2: "から変更申請を行ってください。",
  attention_family_1: "家族情報を変更したい場合は、",
  attention_family_2: "から変更申請を行ってください。",
  attention_info_1: "カスタム情報を追加・変更したい場合は、",
  attention_info_2: "から変更申請を行ってください。",
  demand_change_page: "変更申請ページ",
  demand_holiday_page: "休暇申請ページ",
  call_to_manager: "誤りを見つけた場合は、管理者にお問い合わせください。",
  attention_paidhol_1: "有給休暇の申請を行いたい場合は、",
  attention_paidhol_2: "から休暇申請を行ってください。",

  other_info: "その他情報",
  no_input: "未入力",

  deny: "拒否",
  admit: "承認",
  custom_info: "カスタム情報",

  join_page: "入社手続き",
  take_10_minutes: "所要時間 約10分",
  start: "始める",
  authenticating: "認証しています",
  please_hold_on_for_a_while: "しばらくお待ち下さい",
  edit: "編集",
  back: "戻る",
  next: "次へ",

  route_placeholder_tojo: "大山駅〜池袋駅(東武東上線)",
  route_placeholder_fukutoshin: "池袋駅〜渋谷駅(副都心線)",
  route_placeholder_localroad: "自宅〜池袋(一般道)",
  route_placeholder_highway: "池袋〜渋谷(首都高)",
  route_placeholder_bike: "自宅〜大山駅(バイク)",

  //dialog
  processing: "処理中",
  error_message: "エラー",
  done: "完了",
  executable_error: "実行可能なエラー",
  cancel: "キャンセル",
  execute: "実行",

  unselected: "未選択",
  detail: "詳細",

  disability_reason_placeholder_book: "身体障害者手帳（平成25年6月1日交付）",
  disability_reason_placeholder_level1: "身体障害者1級",
  disability_reason_placeholder_level6: "身体障害者6級",
  arrow_university: "アロー大学",
  enter_school_date: "令和2年4月1日",

  increase: "増やす",
  determine: "確定",

  join_dialog_title: "入社申請を送信してよろしいですか？",
  join_dialog_msg: "送信すると入社承認待ち状態となります。",
  join_dialog_detail: "送信後、管理者が承認して入社手続き完了となります。",

  will_send: "送信する",
  will_not_send: "送信しない",

  processing_msg: "この処理には時間がかかることがあります。",
  waiting_please: "そのままお待ちください。",

  join_finish: "入社手続き完了",
  join_finish_msg1: "お疲れ様でした。",
  join_finish_msg2: "承認されると登録完了メールが送信されます。",
  join_finish_msg3: "承認までしばらくお待ちください。",
  join_finish_msg4: "このまま画面を閉じて終了してください。",

  send_finish: "送信が完了しました。",
  create_finish: "作成が完了しました。",

  will_create: "作成する",
  will_not_create: "作成しない",

  create_confirm_dialog: "%{content}を作成してよろしいですか？",
  create_force_confirm_dialog: "本当に%{content}を作成してよろしいですか？",
  making: "作成中",
  able_create_done_msg: "シフト提出が完了しました。",

  submit_regist_shift_confirm: "シフトを登録してよろしいですか？",
  submit_regist_shift_confirm_detail:
    "フレックスタイム制のため、提出されたシフトはそのまま登録されます。",
  create_submit_finish: "シフト登録が完了しました。",
  create_submit_pattern_confirm: "シフト提出パターンを作成してよろしいですか？",
  update_submit_pattern_confirm: "シフト提出パターンを更新してよろしいですか？",
  will_not_update: "更新しない",
  will_update: "更新する",
  will_not_delete: "削除しない",
  will_delete: "削除する",
  delete_submit_pattern_confirm: "シフト提出パターンを削除してよろしいですか？",
  delete_submit_pattern_confirm_detail: "削除したシフト提出パターンは戻すことができません",
  scrollable: "スクロールできます",
  submit_shift: "提出シフト",
  submit_wanting: "募集中",
  submit_entered: "入力済",
  submit_timespan: "時間帯",
  biko: "備考",
  save: "保存",
  shift_submit_caution: "入力後に一番下の提出ボタンを押してください。",
  shift_register_caution: "入力後に一番下の登録ボタンを押してください。",

  //ログインページ
  personal_login: "マイページ",
  branch_login: "店管理ページ",
  company_login: "管理ページ",
  personal_login_legacy: "マイページ",
  branch_login_legacy: "管理ページ(店舗)",
  company_login_legacy: "管理ページ(会社)",
  punch_login: "打刻ログイン",
  mail_code: "メールアドレスまたは社員コード",
  forget_pass: "パスワードがわからない場合は",
  here: "こちら",
  login: "ログイン",
  login_error: "メールアドレスまたはパスワードが違います",
  login_no_admit_error: "承認されるまでおまちください",
  branch_error: "この店舗には所属していません",

  //ログアウト
  logout_confirm: "ログアウトしますか？",

  //アプリ下部メニュー
  calendar: "カレンダー",
  submit: "提出",
  register: "登録",
  punch: "打刻",
  mypage: "マイページ",

  //アプリメニュー
  contact: "お問い合わせ",
  logout: "ログアウト",

  //シフトページ
  shift_calendar: "シフトカレンダー",
  self_shift: "自分のシフト",
  break: "休憩",
  there_isnt_your_shift: "あなたのシフトはありません",
  cannot_view_your_shift: "あなたのシフトが入っていないため閲覧できません",
  there_isnt_shift: "シフトはありません",
  other_member: "他のメンバー",
  there_isnt_other_member: "他のメンバーはいません",
  shift_name: "名前", //nameはFull nameとなっている

  //提出ページ
  shift_submit: "シフト提出",
  shift_register: "シフト登録",
  branch_select: "店舗",
  apply: "適用",
  edit_pattern: "パターンを編集",
  able_pattern: "シフトパターン",
  add_pattern: "パターンを追加",
  able_pattern_name: "名前",
  able_pattern_shift_time: "シフト時間",
  able_pattern_break_time: "休憩時間",
  there_isnt_able_pattern: "シフト提出パターンはありません",
  core_time_caution: "コアタイムを含むようにシフトを提出してください。",
  able_pattern_select: "選択",
  pattern: "パターン",
  date: "日付",
  able_shift_time: "シフト時間",
  able_break_time: "休憩時間",
  able_need_time: "募集時間",
  comment: "コメント",
  able_create_confirm_dialog: "シフトを提出してよろしいですか？",
  will_submit: "提出する",
  will_not_submit: "提出しない",

  //勤怠ページ
  attendance_each_page: "勤怠詳細",
  attendance_detail_calendar: "詳細カレンダー",
  ordering_delete: "(削除申請中)",
  attendance_punch_round: "勤怠(丸め)",
  attendance_break_round: "休憩(丸め)",
  //(.*)		(.*)
  //  $2: '$1',

  attendance_day_num: "日数",
  attendance_prescribed_day: "所定労働",
  attendance_all_day: "合計勤務",
  attendance_reg_day: "通常勤務",
  attendance_lawhol_day: "法定休日勤務",
  attendance_outhol_day: "法定外休日勤務",
  attendance_mid_day: "内深夜勤務",
  attendance_early_day: "早退",
  attendance_late_day: "遅刻",
  attendance_abs_day: "欠勤",
  attendance_paid_holiday: "有給休暇",
  attendance_special_holiday: "特別休暇",
  attendance_trans_holiday: "振替休日",
  attendance_comp_holiday: "代休",
  attendance_remain_paidhol: "有給残",
  attendance_after_lock: "ロック後",
  attendance_day: "日",
  attendance_time_num: "時間",
  attendance_prescribed_time: "所定労働",
  attendance_all_time: "合計勤務",
  attendance_reg_time: "通常勤務",
  attendance_lawhol_time: "法定休日勤務",
  attendance_outhol_time: "法定外休日勤務",
  attendance_preover_time: "法定内残業",
  attendance_lawover_time: "法定外残業",
  attendance_mid_time: "内深夜勤務",
  attendance_early_time: "早退",
  attendance_late_time: "遅刻",
  attendance_prescribed_lawhol_day: "法定休日日数",
  attendance_prescribed_outhol_day: "法定外休日日数",

  attendance_type: "形態",
  attendance_work_time: "勤務時間",
  attendance_no_work: "勤務がありません",
  attendance_detail: "詳細",
  attendance_no_data: "データがありません",
  attendance_carfare: "通勤手当",

  timespan: "%{hour}時間%{min}分",
  timespanhour: "%{hour}時間",
  timespanmin: "%{min}分",

  payment_each_page: "給与明細",
  paid_on: "%{year}年%{month}月%{day}日支給分",
  paid_on_yearmonth: "%{year}年%{month}月支給分",
  yearmonth: "%{year}年%{month}月",
  month_day: "%{month}月%{day}日",
  date_format: "%{year}年%{month}月%{day}日",
  day_format: "%{day}日",

  inform_news: "お知らせ",
  inform_importance: "重要度",
  inform_importance_high: "高",
  inform_importance_middle: "中",
  inform_importance_low: "低",
  inform_news_from_admin: "管理者からのお知らせ",
  inform_no_news: "お知らせはありません",
  inform_news_from_system: "システムからのお知らせ",
  inform_waiting_document: "合意待ちの書類があります",
  inform_waiting_nencho_kou: "年末調整入力のお願い",
  inform_waiting_nencho_otsu: "本人情報確認のお願い",
  datetime: "%{year}年%{month}月%{day}日%{hour}時%{minute}分",

  contact_destination: "送信先",
  contact_admin: "管理者",
  contact_company_manager: "会社管理者",
  contact_arrow_support: "ARROWサポート",
  contact_content: "問い合わせ内容",
  contact_send: "送信する",
  are_you_sure_to_send: "送信してよろしいですか？",
  reply_via_mail: "返信はメールを通じて行われます。",
  contact_no_send: "送信しない",
  contact_finish_send: "送信が完了しました。",
  contact_finish_attention: "返信をお待ちください。",

  document_page: "書類・集計",
  aggregation: "集計",
  document_select_document: "書類を選んでください",
  document_join_related: "入社関連",
  document_dependant_deduction: "扶養控除等申告書",
  document_socialins_get: "社会保険被保険者資格取得届",
  document_socialins_dependant: "扶養者(異動)届第3号被保険者関係届",
  document_empins_get: "雇用保険被保険者資格取得届",
  document_retire_related: "退社関連",
  document_gensen_employee: "源泉徴収票(受給者用)",
  document_empins_retire: "雇用保険被保険者離職票",
  document_socialins_lost: "社会保険被保険者資格喪失届",
  document_empins_lost: "雇用保険被保険者資格喪失届",
  document_transfer: "給与所得者異動届出書",
  document_payment_related: "給与関連",
  document_payment_slip: "給与明細書",
  document_bonus_slip: "賞与明細書",
  document_gensen: "源泉徴収票",
  document_gensenpay: "所得税徴収高計算書",
  document_daicho: "賃金台帳",
  document_gensen_book: "源泉徴収簿",
  document_bonus_pay: "賞与支払届",
  document_socialins_related: "社会保険関連",
  document_laborins: "労働保険料・一般拠出金申告書",
  document_renew_stdincome: "被保険者報酬月額算定基礎届",
  document_update_stdincome: "被保険者報酬月額変更届",
  document_nencho_related: "年末調整関連",
  document_insurance: "保険料控除申告書",
  document_loan: "住宅借入金等特別控除申告書",
  document_kihaisho: "基・配・所控除申告書",
  document_payment_table: "給与支払報告書",
  document_legal_table: "法定調書合計表",
  document_fixed_tax_reduction: "定額減税のための申告書",
  document_enter_yearmonth: "対象年月を入力してください",
  document_enter_year: "対象年を選択してください",
  document_enter_nendo: "対象年度を選択してください",
  document_choose_output_type: "出力方法を選んでください",
  document_download_pdf: "PDFダウンロード",
  document_nodata: "データがありません",
  document_gensen_attention:
    "年の途中にARROWを利用開始した場合は、退職者の源泉徴収票が正しくない可能性があります。",

  demand_punch_page: "打刻修正申請",

  demand_punch_shift_punch: "シフト・勤怠",

  demand_punch_ordering: "(申請中)",
  demand_punch_order_delete: "(削除申請中)",

  demand_holiday: "休暇申請",
  demand_holiday_no_demand: "申請はありません",
  demnad_holiday_new_demand: "新規申請",
  demand_holiday_preferred_date: "希望日",
  demand_holiday_kind: "種類",
  demand_holiday_paidhol_full: "有給全日",
  demand_holiday_paidhol_first_half: "有給全日（前半）",
  demand_holiday_paidhol_second_half: "有給全日（後半）",
  demand_holiday_paidhol_hourly: "時間有給",
  demand_holiday_special_holiday: "特別休暇",
  demand_holiday_demanding: "申請中",
  demand_holiday_admit: "承認済",
  demand_holiday_deny: "承認拒否",
  demand_holiday_time: "申請日時",
  demand_holiday_reason: "申請事由",
  demand_holiday_reason_optional: "申請事由(任意)",
  demand_holiday_no: "なし",
  demand_holiday_status: "状態",

  demand_holiday_create_confirm: "休暇申請を送信してよろしいですか？",
  demand_holiday_send: "申請しない",
  demand_holiday_no_send: "申請する",
  demand_holiday_finish_create: "休暇申請を送信しました。",

  demand_holiday_cannot_delete: "承認・拒否がされているので削除できません。",
  demand_holiday_add_new_demand: "新規休暇申請",
  demand_holiday_preferred_get_date: "取得希望日",
  demand_holiday_cancel_confirm: "休暇申請を取り消してよろしいですか？",
  demand_holiday_no_cancel: "取り消さない",
  demand_holiday_cancel: "取り消す",
  demand_punch_create_confirm: "この内容で申請してよろしいですか？",
  demand_punch_send: "申請する",
  demand_punch_no_send: "申請しない",
  demand_punch_cancel_confirm: "この申請を取り消してよろしいですか？",
  demand_punch_cancel_detail: "元に戻すことはできません",
  demand_punch_yes_cancel: "取り消す",
  demand_punch_no_cancel: "取り消さない",

  work_type_normal: "通常",
  work_type_outhol: "法定外休日",
  work_type_lawhol: "法定休日",
  work_type_paidhol_full: "有給全日",
  work_type_paidhol_half: "有給半日",
  work_type_specialhol: "特別休暇",
  work_type_transhol: "振替休日",
  work_type_comphol: "代休",
  work_type_abs: "欠勤",
  work_type_paidhol_hourly: "時間有給",

  demand_overtime_page: "残業申請",
  demand_overtime_new_order: "新規申請",
  demand_overtime_new_overtime_order: "新規残業申請",
  demand_overtime_dest: "申請先",
  demand_overtime_kind: "種類",
  demand_overtime_kind_over: "残業",
  demand_overtime_kind_early: "早出",
  demand_overtime_kind_out_shift: "シフト外出勤",
  demand_overtime_preferred_get_date: "取得希望日",
  demand_overtime_timespan: "時間帯",
  demand_overtime_reason: "申請事由",
  demand_overtime_reason_optional: "申請事由(任意)",
  demand_overtime_enter_above_mandatory: "上の必須項目を入力",
  demand_overtime_submit: "申請",
  demand_overtime_noshift: "シフトなし",
  demand_overtime_select_shift: "シフトを選択",
  demand_overtime_confirm: "残業申請を送信してよろしいですか？",
  demand_overtime_no_send: "申請しない",
  demand_overtime_send: "申請する",
  demand_overtime_finish: "残業申請を送信しました。",
  demand_overtime_cancel_confirm: "残業申請を取り消してよろしいですか？",
  demand_overtime_cancel: "取り消す",
  demand_overtime_no_cancel: "取り消さない",
  demand_overtime_preferred_date: "希望日",
  demand_overtime_order_datetime: "申請日時",
  demand_overtime_no: "なし",
  demand_overtime_no_order: "申請はありません",
  demand_overtime_cannot_delete: "承認・拒否がされているので削除できません。",
  demand_overtime_demanding: "申請中",
  demand_overtime_admit: "承認済",
  demand_overtime_deny: "承認拒否",

  demand_change_submit: "申請送信",
  demand_change_dialog_title: "申請を送信してよろしいですか？",
  demand_change_dialog_msg: "入力された内容で社員情報変更申請を行います。",
  demand_change_dialog_detail: "送信後、管理者が承認して変更完了となります。",
  demand_change_cancel: "申請しない",
  demand_change_exec: "申請する",
  demand_change_finish_dialog_msg: "社員情報変更申請が完了しました。",
  demand_change_finish_dialog_detail: "承認されるとメールが届きます",
  demand_change_admit_dialog_title: "申請を承認してよろしいですか？",
  demand_change_admit_dialog_msg: "承認すると個人情報が申請された内容で上書きされます。",
  demand_change_admit_cancel: "承認しない",
  demand_change_admit_exec: "承認する",
  demand_change_admit_finish_title: "承認が完了しました。",
  demand_change_admit_finish_msg: "個人情報が変更されました。",
  demand_change_admit_carfare_dialog_msg:
    "承認すると個人情報が申請された内容で上書きされます。<br>また、通勤手当に関係する項目が変更されているため、承認後必要に応じて社員詳細ページから通勤手当の変更を行ってください。",
  demand_change_admit_carfare_finish_msg:
    "個人情報が変更されました。<br>通勤手当に関係する項目が変更されているため、必要に応じて社員詳細ページから通勤手当の変更を行ってください。",
  demand_change_deny_dialog_title: "申請を拒否してよろしいですか？",
  demand_change_deny_dialog_msg: "一度拒否すると申請は破棄されます。",
  demand_change_deny_cancel: "拒否しない",
  demand_change_deny_exec: "拒否する",
  demand_change_deny_finish_msg: "拒否が完了しました。",

  paidhol_remain_day: "残日数",
  paidhol_history: "履歴",
  paidhol_day_count: "日数",
  paidhol_expiration: "使用期限",
  paidhol_nodata: "有給休暇はありません",

  payment_slip: "給与明細書",
  payment_each_belong: "所属",
  payment_each_name: "名前",
  payment_each_paidon: "支給日",

  payment_each_attendance: "勤怠",
  payment_each_day_num: "日数",
  payment_each_time: "時間",
  payment_each_reg_day: "通常出勤日数",
  payment_each_lawhol_day: "法定休日出勤日数",
  payment_each_outhol_day: "法定外休日出勤日数",
  payment_each_mid_day: "内深夜勤務日数",
  payment_each_early_day: "早退日数",
  payment_each_late_day: "遅刻日数",
  payment_each_abs_day: "欠勤日数",
  payment_each_paidhol_day: "有給休暇日数",
  payment_each_remain_paidhol_day: "有給残日数",
  payment_each_specialhol_day: "特別休暇日数",
  payment_each_transhol_day: "振替休日日数",
  payment_each_comphol_day: "代休日数",
  payment_each_reg_time: "通常勤務時間",
  payment_each_lawhol_time: "法定休日勤務時間",
  payment_each_outhol_time: "法定外休日勤務時間",
  payment_each_preover_time: "法定内残業時間",
  payment_each_lawover_time: "法定外残業時間",
  payment_each_mid_time: "内深夜勤務時間",
  payment_each_early_time: "早退時間",
  payment_each_late_time: "遅刻時間",
  payment_each_allowance: "支給",
  payment_each_reg_pay: "基本給",
  payment_each_pres_pay: "役員報酬",
  payment_each_hol_pay: "休日手当",
  payment_each_mid_pay: "深夜手当",
  payment_each_over_pay: "残業手当",
  payment_each_deemed_over_pay: "固定残業手当",
  payment_each_paidhol_pay: "有給手当",
  payment_each_sum: "合計",
  payment_each_deduction: "控除",
  payment_each_health_ins: "健康保険料",
  payment_each_care_ins: "介護保険料",
  payment_each_pension_ins: "厚生年金保険料",
  payment_each_emp_ins: "雇用保険料",
  payment_each_social_ins_adj: "社会保険料等調整",
  payment_each_income_tax: "所得税",
  payment_each_res_tax: "住民税",
  payment_each_other_allowance: "その他",
  payment_each_settlement: "精算金",
  payment_each_cash: "現物支給精算金",
  payment_each_nencho: "年末調整",
  payment_each_deducted_sum: "差引支給額",
  payment_each_bank_sum: "振込支給額",
  payment_each_cash_sum: "現金支給額",
  payment_each_cumulative_sum: "課税累計額",
  payment_each_cumulative_social_ins: "社会保険累計",
  payment_each_tax_type: "税額表区分",
  payment_each_kou: "甲",
  payment_each_otsu: "乙",

  bonus_each_page: "賞与明細",
  bonus_slip: "賞与明細書",
  bonus_each_calc_span: "計算期間",
  bonus_each_start: "開始",
  bonus_each_end: "終了",
  bonus_each_money: "賞与",
  bonus_each_no_bonus: "賞与データがありません。",
  bonus_each_no_employee_or_no_lock: "社員データがないか、賞与が確定しておりません。",

  punch_page: "%{kind}打刻",
  punch_branch: "打刻店舗",
  punch_attention: "「出勤」または「退勤」ボタンをタップしてください",
  punch_in: "出勤",
  punch_out: "退勤",
  break_start: "休憩開始",
  break_end: "休憩終了",
  login_page_after_login: "ログイン直後にこのページを表示する",
  punch_confirm_out_punch_in: "%{name}さんのシフト外出勤をしてよろしいですか？",
  punch_finish_punch_in: "%{name}さんの出勤が完了しました",
  punch_finish_punch_out: "%{name}さんの退勤が完了しました",
  punch_finish_break_start: "%{name}さんの休憩開始を記録しました。",
  punch_finish_break_end: "%{name}さんの休憩終了を記録しました。",
  punch_finish_out_punch_in: "%{name}さんのシフト外出勤が完了しました",
  punch_getting_location: "位置情報取得中...",
  document_thereis_waiting_document:
    '合意待ちの書類が<span class="font-red">%{num}件</span>あります。',
  document_link_to_waiting_document: "こちらからご確認ください。",
  waiting_document_page: "合意待ち書類",
  waiting_document_no_document: "合意待ちの書類はありません",
  waiting_document_received_date: "受信日時",
  contract_document_need_contract: "合意が必要な書類です。",
  contract_document_attention:
    "書類を確認後に書類の下にある署名を行い、合意・拒否ボタンを押してください。",
  contract_document_created_on: "作成日時",
  contract_document_waiting: "合意待ち書類",
  contract_document_admitted: "合意済み書類",
  contract_document_admitted_on: "合意日時",
  contract_document_denied: "拒否済み書類",
  contract_document_denied_on: "拒否日時",
  contract_document_auth: "署名",
  contract_document_deny: "拒否する",
  contract_document_admit: "合意する",
  contract_document_admit_confirm: "この書類に合意してよろしいですか？",
  contract_document_admit_confirm_detail:
    "一度合意するとキャンセルできません。<br>この書類を印刷しておくことをお勧めします。",
  contract_document_no_admit: "合意しない",
  contract_document_admit_finish: "合意が完了しました",
  contract_document_deny_confirm: "この書類を拒否してよろしいですか？",
  contract_document_deny_confirm_detail:
    "一度拒否するとキャンセルできません。<br>この書類を印刷しておくことをお勧めします。",
  contract_document_no_deny: "拒否しない",
  contract_document_deny_finish: "拒否しました",
  contract_document_detail: "書類詳細",

  reset_password: "パスワードの再設定",
  reset_password_attention1: "ARROWで登録したメールアドレスを入力してください。",
  reset_password_attention2:
    "メールアドレスを登録していない場合はパスワード再設定ができないため、管理者にお問い合わせください。",
  reset_password_attention3:
    "メールアドレスに送信される案内にしたがって、パスワードを再設定してください。",
  reset_password_mail: "ARROWに登録されているメールアドレス",
  reset_password_finish: "送信が完了しました。",
  reset_password_finish_detail1: "送信されるメールに従って、パスワードを再設定してください。",
  reset_password_finish_detail2:
    "メールが届かない場合は、メールアドレスが登録されていない可能性があります。",
  reset_password_finish_detail3: "管理者にお問い合わせください。",

  face_image: "顔写真",

  aplan: "Aプラン",
  bplan: "Bプラン",
  cplan: "Cプラン",

  regular_use: "正規使用中",
  trial_use: "お試し使用中",
  expired_use: "期限切れ",
  other_use: "その他",

  pay_method_no_regist: "登録なし",
  pay_method_card: "クレジットカード",
  pay_method_debit: "口座振替",
  pay_method_bill: "請求書",

  "5num": "5",
  "10num": "10",
  "15num": "15",
  "20num": "20",
  "30num": "30",
  "60num": "60",

  "1day": "1日",
  "2day": "2日",
  "3day": "3日",
  "4day": "4日",
  "5day": "5日",
  "6day": "6日",
  "7day": "7日",
  "8day": "8日",
  "9day": "9日",
  "10day": "10日",
  "11day": "11日",
  "12day": "12日",
  "13day": "13日",
  "14day": "14日",
  "15day": "15日",
  "16day": "16日",
  "17day": "17日",
  "18day": "18日",
  "19day": "19日",
  "20day": "20日",
  "21day": "21日",
  "22day": "22日",
  "23day": "23日",
  "24day": "24日",
  "25day": "25日",
  "26day": "26日",
  "27day": "27日",
  "28day": "28日",
  "29day": "29日",
  "30day": "30日",
  "31day": "31日",

  "5minute": "5分",
  "10minute": "10分",
  "15minute": "15分",
  "20minute": "20分",
  "25minute": "25分",
  "30minute": "30分",

  "1hour": "1時間",
  "2hour": "2時間",
  "3hour": "3時間",
  "4hour": "4時間",
  "5hour": "5時間",
  "6hour": "6時間",
  "7hour": "7時間",
  "8hour": "8時間",

  upload_image: "画像のアップロード",
  upload_image_attention1: "下の四角をクリックすると画像を選択できます。",
  upload_image_attention2: "画像を選択後にズームやドラッグで切り取りができます。",
  upload_image_attention3: "pngとjpegの画像のみアップロード可能です。",
  loading: "読み込み中",
  config_attendance_before_join: "入社以前の社員も表示",
  config_attendance_after_join: "入社済みの社員のみを表示",

  info: info_language.ja,
  popup: popup_language.ja,
  error: error_language.ja,
};

const en = {
  loading: "Loading",
  branch: "Branch name",

  join_page: "Joining procedure",
  take_10_minutes: "It takes about 10 minutes.",
  start: "Start",
  authenticating: "Authenticating",
  please_hold_on_for_a_while: "Please hold on for a while",
  base_info: "Personal information",

  name: "Full name",
  sex: "Sex",
  male: "Male",
  female: "Female",
  birth_date: "Date of birth",

  mynum: "Individual number (my number)",
  mail: "Email address",
  password: "Password",
  confirm: "Confirmation",
  back: "Back",
  next: "Next",
  error_message: "Error",

  address_info: "Address information",

  zip: "ZIP code",
  address: "Address",
  asof: "as of December 31, %{year}",
  tel: "Home phone number",
  mobile: "Mobile phone number",
  do_you_use_carfare: "Do you use public transportation or your private car for commuting?",
  yes: "Yes",
  no: "No",
  select_using: "Please select what you are using for commuting.",
  is_train: "Public transportation",
  is_car: "Private car",
  route: "Route",
  route_placeholder_tojo: "Oyama station ~ Ikebukuro station (Tobu Tojo Line)",
  route_placeholder_fukutoshin: "Ikebukuro station ~ Shibuya station (Fukutoshin Line)",
  mycar_km: "One-way distance of the part of using private car.",
  route_placeholder_localroad: "Home - Ikebukuro(Public Road)",
  route_placeholder_highway: "Ikebukuro - Shibuya(Highway)",
  route_placeholder_bike: "Home - Oyama station(Motorbike)",

  bank_info: "Bank account information",

  employee_bank: "Transfer account",
  employee_bank1: "Transfer account 1",
  regist: "Register",
  no_regist: "No data",
  bank_name: "Financial institution name (in Japanese)",
  click_candidate: "Click the candidate.",
  bank_branch_name: "Bank branch name (in Japanese)",
  yucho_branch_name: "Code number(5 digits)",
  input_bank_name: "Enter the financial institution name",
  bank_type: "Bank account type",
  bank_num: "Account number",
  bank_regular: "Savings account",
  bank_current: "Current account",
  bank_save: "Savings deposit account",
  bank_holder: "Account name",
  employee_bank2: "Transfer account 2",
  employee_bank1_limit: "Maximum amount of transfer account 1",

  yen: " yen",

  self_info: "Personal details",

  tax_type_detail:
    'Are you currently working elsewhere and have you submitted an "Application for Exemption for Dependents"(扶養控除等申告書)?',
  kou_no: "No (甲, Kou)",
  otsu_yes: "Yes (乙, Otsu)",
  householder_name: "The name of the head of household",
  householder_relationship: "Relationship with the head of household",

  unselected: "Unselected",
  self: "Yourself",
  husband: "Husband",
  wife: "Wife",
  father: "Father",
  mother: "Mother",
  other: "Other",
  relationship_name: "Relationship name",
  your_estimated_income: "Your estimated income for this year.",
  payment_income: "Salary income",
  other_income: "Income amount other than salary income",
  sum_income: "Total income amount",

  detail: "Detail",
  your_disability: "Do you have a disability?",
  not_applicable: "No",
  normal_disability: "Person with a disability",
  special_disability: "Person with a special disability",

  disability_reason: "Details of your disability",
  disability_reason_placeholder_book: "Physical disability certificate (issued on June 1, 2013)",
  disability_reason_placeholder_level1: "Physical disability level 1",
  disability_reason_placeholder_level6: "Physical disability level 6",
  your_single_child: "Are you a single parent?",
  your_widow: "Are you a single parent or a widow?",
  applicable: "Yes",
  widow: "Widow",
  single_child: "Single Parent",
  single_child_widow: "Single Parent / Widow",
  work_student: "Working Student",
  your_work_student: "Are you a working student?",
  school_and_year: "Name of school and year of admission",
  arrow_university: "ARROW University",
  enter_school_date: "April 1, 2020",
  earning: "Revenue and income",

  disability: "Person with a disability",

  spouce_info: "Spouse information",

  is_spouce: "Do you have a spouse?",
  is_spouce_either: "Does your spouse apply to any of these categories?",
  no_either: "No",
  yes_either: "Yes",
  spouce_caution_1:
    "The income is exceeding 1.33 million yen (salary income of 2.016 million yen).",
  spouce_caution_2: "You and your spouse are not living in the same household.",
  spouce_caution_3: "Receive salary as a family employee of blue return taxpayer.",
  spouce_caution_4: "Family employee of white return taxpayer.",
  spouce_caution_5: "Being a dependent relative of another person.",
  spouce_caution_6: "Spouse himself/herself is eligible for the special deduction for spouse.",
  spouce_name: "Spouse name",
  last_name: "Family name",
  first_name: "First name",
  spouce_birth_date: "Date of birth",
  spouce_mynum: "Individual number (my number)",
  spouce_housemate: "Living with spouse.",
  housemate: "Living together",
  not_housemate: "No",
  yes_housemate: "Yes",
  spouce_address: "Spouse address",
  spouce_not_resident: "Is your spouse non-resident?",

  housemate_with_spouce: "Lives with the your spouse or other dependent relatives of yours?",
  not_resident: "Non-resident",
  remittance_to_spouce: "Amount of remittance to spouse",
  spouce_estimated_income: "Spouse's estimated income for this year",
  spouce_disability: "Does your spouse have a disability?",
  spouce_housemate_with_livelihood:
    "Does your spouse live with your relatives who live in the same household as yours?",

  dependant_info: "Dependent information",
  how_many_dependants:
    'How many relatives (including under 16 years old) do you have who are dependent <span style="color:red">on you</span> and apply to <span style="color:red">all</span> of the following.',
  person_count: " ",
  increase: "Add",
  dependant_caution_1:
    "The income is less than 480 thousand yen (salary income of 1.3 million yen).",
  dependant_caution_2: "Living in the same household with you.",
  dependant_caution_3: "Dosen't receive salary as a family employee of blue return taxpayer.",
  dependant_caution_4: "Not a family employee of white return taxpayer.",
  dependant_caution_5: "Not being a dependent relative of another person.",
  dependant: "Dependent relative %{index}",
  delete: "Delete",
  dependant_name: "Dependent name",
  dependant_birth_date: "Date of birth",

  dependant_mynum: "Individual number",
  dependant_relationship: "Relationship to dependent relative %{index}",
  dependant_housemate: "Are you living with your dependent relative?",
  dependant_address: "Dependent relative's address",
  dependant_not_resident: "Is the dependent relative non-resident?",
  dependant_housemate_with_livelihood:
    "Is the dependent relative live with your spouse or other dependent relatives of yours?",
  dependant_remittance_to_dependant: "Amount of remittance to dependent relative",
  dependant_estimated_income: "Dependent relative's estimated income for this year",
  dependant_disability: "Does the dependent relative have a disability?",

  config_is: "Configure",
  config_isnt: "Not configure",
  remittance_to_dependant: "Amount of remittance to dependent relative.",
  housemate_with_livelihood: "Do you live with your relatives who live in the same household?",
  employee_code: "Employee code",
  job_content: "Work content",
  week_prescribed_day: "Weekly fixed work days",
  week_prescribed_time: "Weekly fixed work hours",
  month_prescribed_day: "Monthly fixed work days",
  month_prescribed_time: "Monthly fixed work hours",
  joined_on: "Date of joining",
  retired_on: "Date of leaving",
  contract_started_on: "Start of contract",
  contract_ended_on: "End of contract",
  memo: "Memo",
  daily_money: "Daily amount",
  monthly_money: "Monthly amount",
  using_train_or_car: "Using the public transportation or your private car.",
  spouce: "Spouce",
  dependants_num: "The number of dependent relatives.",
  work_type: "Working form",
  normal: "Normal",
  deemed_over: "Fixed overtime system",
  monthly_modifiedtime: "Monthly modified working hour system",
  monthly_flextime: "Monthly flextime system",
  core_time: "Core time",
  base_wage: "Basic salary",
  base_wage_pres: "Basic salary / Executive allowance",
  hourly_wage: "Hourly wage",
  monthly_wage: "Monthly wage",
  manual: "Manual",
  main_hourly_wage: "Main hourly wage",

  add_exception_hourly_wage: "Add exceptional hourly wage",
  usable_after_add_employee: "Available after adding the employee",
  usable_after_admit_employee: "Available after approving the employee",
  exception_hourly_wage: "Exceptional hourly wage",
  delete_confirm_join_dependant: "Are you sure to delete the dependent relative%{index}?",
  not_delete: "No",
  yes_delete: "Yes",
  join_admit: "Approval of joining",
  demand_admit: "Approve changes of employee information",
  training_hourly_wage: "Training hourly wage",
  time_count: "Time count",
  day_count: "Day count",
  total: "Total",
  within_work_valid: "Valid for working within",
  base_pay: "Basic wage",
  auto: "Auto",
  preover: "Legal overtime work",
  lawover: "Non-legal overtime work",
  by_time: "Up to time",
  mid_allowance: "Midnight allowance",
  holiday_allowance: "Holiday allowance",
  deemed_over_allowance: "Fixed overtime allowance",
  outhol: "Non-legal holiday",
  lawhol: "Legal holiday",
  disp_gensentax: "Indication on withholding slip",
  paidhol_allowance: "Paid holiday allowance",
  use_paid_hol_type: "Unit price of full-day use of paid holidays",
  buy_paid_hol_type: "Unit price of purchasing paid holidays",
  gensentax: "Withholding tax",
  calc_gensentax: "Calculate withholding tax",
  no_use: "Don't use",
  yes_use: "Use",
  no_calc: "Don't calculate",
  yes_calc: "Calculate",
  restaxspan: "Residence tax (%{start_month}%{start_year}~%{end_month}%{end_year})",
  restax: "Residence tax",
  pay_zip: "ZIP code of tax-payee address",
  pay_place: "Address of tax-payee",
  year_money: "Annual amount",
  month_money: "Monthly amount",
  social_ins: "Social insurance",
  union_ins: "National health insurance",
  health_care_ins: "Health/Long-term care insurance",
  health_ins: "Health insurance",
  care_ins: "Long-term care insurance",
  pension_ins: "Welfare pension insurance",
  social_ins_adj: "Adjustment of social insurance premium",
  income_tax: "Income tax",
  child_care: "Maternity and childcare leave period",
  thousand_yen: "Thousand yen",
  std_income: "Standard monthly wage",
  double_biz: "Employees working at two or more company",
  pension_ins_id: "Basic pension number",
  social_ins_id: "Reference number of insured person",
  social_ins_got_on: "Date of qualification ",
  emp_ins: "Employment insurance",
  emp_ins_id: "Reference number of insured person",
  emp_ins_got_on: "Date of qualification ",
  comp_ins: "Worker's accident insurance",
  comp_ins_got_on: "Date of qualification ",
  initial_value: "Initial value",
  auto_give: "Automatic giving",
  manual_give: "Manual giving",
  use: "Use",
  buy: "Buy",
  timeout: "Timeout",
  over40: "Over 40",
  bank_count: "Number of bank accounts",
  bank_count_1: "Bank account 1",
  bank_count_2: "Bank account 2",
  change_mail: "Change email address",
  change_pass: "Change password",
  current_mail: "Current email address",
  new_mail: "New email address",
  change_password: "Change password",
  now_password: "Current password",
  new_password: "New password",
  confirm_password: "New password (confirmation)",
  regist_mypc: "Register MyPC",
  prescribed: "Fixed work",
  join_retire: "Joining/Leaving",
  join_admit_name: "Approve joining of %{name}",
  demand_admit_name: "Approve employee information of %{name}",
  add_employee: "Employee registration",
  individual_unit: "Individual wage",
  branch_carfare: "Commutation allowance by each section",
  exception_carfare: "Exceptional commutation allowance",
  add_exception_carfare: "Add exceptional commutation allowance",
  attention_self_info_1: "If you want to edit the personal details",
  attention_self_info_2: 'Please change it from "Edit the information".',
  attention_family_1: "If you want to edit the family information",
  attention_family_2: 'Please change it from "Edit the information".',
  attention_info_1: "If you want to edit the custom information",
  attention_info_2: 'Please change it from "Edit the information".',
  demand_change_page: "Edit the information page",
  demand_holiday_page: "Holiday application page",
  call_to_manager: "If you find any mistakes, please contact the administrator.",
  attention_paidhol_1: "If you want to apply for paid holidays",
  attention_paidhol_2: "Please apply from ?.",
  no_input: "Blank",
  deny: "Reject",
  admit: "Approve",
  executable_error: "Executable error",
  execute: "Execute",
  register: "Register",
  able_pattern_break_time: "Break time",

  short_of_attendance: "Lack of attendance rate",
  custom_info: "Custom Information",
  edit: "Edit",
  determine: "OK",
  join_dialog_title: "Confirmation",
  join_dialog_msg: "The entered information will be used to apply for entry into the company.",
  join_dialog_detail:
    "After submitting the form, the manager will approve it to complete the joining process.",
  will_send: "Submit",
  will_not_send: "Cancel",
  processing_msg: "It may take some time.",
  done: "Done",
  join_finish: "Joining procedure completed",
  join_finish_msg1: "Thank you for your hard work.",
  join_finish_msg2: "If you are approved by the administrator, an email will be sent,",
  join_finish_msg3: "so please wait.",
  join_finish_msg4: "You may close the screen and finish.",
  send_finish: "Successfully submitted.",
  create_finish: "Successfully created.",
  will_create: "Create",
  will_not_create: "Cancel",
  create_confirm_dialog: "Are you sure to create %{content}?",
  create_force_confirm_dialog: "Are you really sure you want to create %{content}?",
  making: "Creating",

  company: "Company",

  personal_login: "Personal login",
  branch_login: "Section login (branch login)",
  company_login: "Administrative Login",
  personal_login_legacy: "Personal login",
  branch_login_legacy: "Section login (branch login)",
  company_login_legacy: "Administrative Login",
  punch_login: "Punch Login",
  mail_code: "E-mail address (ID)",
  branch_code: "Section/Branch Code",
  pass: "Password",
  forget_pass: "Forgot your password?",
  here: "Click Here",
  login: "Login",
  login_error: "E-mail or password is wrong.",
  login_no_admit_error: "Please wait until the administrator admit your joining procedure.",
  branch_error: "You don't belong to this section.",
  enter_branch: "Please enter section code.",
  enter_mail: "Please enter your e-mail address.",
  enter_pass: "Please enter your password.",
  reset_password: "Reset password",
  reset_password_attention1: "Please enter the email address that you registered in ARROW.",
  reset_password_attention2:
    "If you have not registered your email address, please contact the administrator.",
  reset_password_attention3: "Please reset the password according to the guidance of the email.",
  reset_password_mail: "Email address registered in ARROW.",

  reset_password_finish: "Submitted successfully",
  reset_password_finish_detail1:
    "Please reset the password according to the guidance of the email.",
  reset_password_finish_detail2:
    "If you do not receive an email, your email address may not be registered.",
  reset_password_finish_detail3: "Please contact the administrator.",
  app_company_code_attention:
    'If you don\'t know the company ID, please open the application from the "open" button from the login page for web version.',

  app_attention: "Caution",

  app_notification_continue: "Continue without allowing.",
  app_notification_change_setting: "Allow",
  close: "Close",
  change_company: "Change company",
  shift: "Shift",
  calendar: "Calendar",
  submit: "Submission",
  attendance: "Attendance",
  punch: "Record",
  payment_bonus: "Salary / Bonus",
  payment: "Salary",
  bonus: "Bonus",
  mypage: "My-page",
  shift_calendar: "Shift calendar",

  there_isnt_shift: "No shifts",
  there_isnt_your_shift: "You don't have any shifts today. Enjoy your day-off!",
  cannot_view_your_shift: "You cannot view the shifts because you don't have your shifts here.",
  other_member: "Other members",
  there_isnt_other_member: "No other members",

  break: "Break",

  self_shift: "Today's shift",

  shift_name: "Name",

  shift_submit: "Shift submission",
  shift_register: "Shift registration",
  edit_pattern: "Edit pattern",
  able_pattern: "Pattern",
  add_pattern: "Add pattern",
  able_pattern_name: "Pattern name",
  able_pattern_shift_time: "Available time",

  there_isnt_able_pattern: "There is no pattern.",

  core_time_caution: "Please submit shifts to include the core time.",
  shift_submit_caution:
    "Please push the submit button when you have finished filling out the forms.",
  shift_register_caution:
    "Please push the register button when you have finished filling out the forms.",
  branch_select: "Branches",
  monday: "Mon.",
  tuesday: "Tue.",
  wednesday: "Wed.",
  thursday: "Thur.",
  friday: "Fri.",
  saturday: "Sat.",
  sunday: "Sun.",
  holiday: "Hol.",
  monday_full: "Mon.",
  tuesday_full: "Tue.",
  wednesday_full: "Wed.",
  thursday_full: "Thur.",
  friday_full: "Fri.",
  saturday_full: "Sat.",
  sunday_full: "Sun.",
  holiday_full: "Hol.",
  pattern: "Pattern",
  time: "Available time",
  able_pattern_select: "Select",
  reset: "Reset",
  apply: "Adopt below",
  date: "Date",

  able_shift_time: "Available time",
  able_break_time: "Break time",
  able_need_time: "Working hours",
  comment: "Remarks",

  able_create_confirm_dialog: "Are you sure to submit the available date?",
  will_submit: "Submit",
  will_not_submit: "Cancel",
  waiting_please: "Please hold on for a while.",
  able_create_done_msg: "Shift submission completed.",

  submit_regist_shift_confirm: "Are you sure to register the shift?",
  submit_regist_shift_confirm_detail:
    "Shifts are registered as submitted due to the flextime system.",
  create_submit_finish: "Shift registration completed.",
  create_submit_pattern_confirm: "Are you sure to make the pattern?",
  update_submit_pattern_confirm: "Are you sure to update the pattern?",
  will_not_update: "No",
  will_update: "Yes",
  will_not_delete: "No ",
  will_delete: "Yes",
  delete_submit_pattern_confirm: "Are you sure to delete the pattern?",
  delete_submit_pattern_confirm_detail: "Once deleted, the pattern cannot be reverted.",
  processing: "Processing",
  scrollable: "Scrollable",
  submit_shift: "Submitted shifts",
  submit_wanting: "Available",
  submit_entered: "Done",
  submit_timespan: "Available time",
  biko: "Remarks",
  save: "Save",

  attendance_each_page: "Attendance details",
  attendance_day_num: "Days",
  attendance_prescribed_day: "Fixed work",
  attendance_all_day: "Total work",
  attendance_reg_day: "Regular work",
  attendance_lawhol_day: "Legal holiday work",
  attendance_outhol_day: "Non-legal holiday work",
  attendance_mid_day: "Midnight work",
  attendance_early_day: "Early leaving",
  attendance_late_day: "Late coming",
  attendance_abs_day: "Absent",
  attendance_paid_holiday: "Paid holidays",
  attendance_special_holiday: "Privilege leave",
  attendance_trans_holiday: "Transferred holiday",
  attendance_comp_holiday: "Compensatory leave",
  attendance_remain_paidhol: "Remaining paid holidays",
  attendance_after_lock: "After lock",
  attendance_day: " Day(s)",
  attendance_time_num: "Hours",
  attendance_prescribed_time: "Fixed work",
  attendance_all_time: "Total work",
  attendance_reg_time: "Regular work",
  attendance_lawhol_time: "Legal holiday work",
  attendance_outhol_time: "Non-legal holiday work",
  attendance_preover_time: "Legal overtime work",
  attendance_lawover_time: "Non-legal overtime work",
  attendance_mid_time: "Midnight work",
  attendance_early_time: "Early leaving",
  attendance_late_time: "Late coming",
  attendance_prescribed_lawhol_day: "Legal holiday",
  attendance_prescribed_outhol_day: "Non-legal holiday",

  attendance_detail_calendar: "Details",
  month_day: "%{month}/%{day}",
  attendance_type: "Working form",

  attendance_work_time: "Working hours",
  attendance_no_work: "None",
  attendance_detail: "Details",
  attendance_no_data: "None",
  attendance_carfare: "Commutation allowance",

  attendance_punch_round: "Clock-in/out (rounded)",
  attendance_break_round: "Break-in/out (rounded)",

  ordering_delete: "(Deletion pending)",

  punch_page: "GPS record",

  punch_branch: "Branch",
  punch_attention:
    'Please tap the "Clock-in" or "Clock-out" button, when you attend or leave work.',
  punch_in: "Clock-in",
  punch_out: "Clock-out",
  break_start: "Break-in",
  break_end: "Break-out",
  login_page_after_login: "Display this page immediately after you login.",
  cancel: "Cancel",

  punch_confirm_out_punch_in: 'Are you sure to record the "Clock-in" at out of your shift?',
  punch_finish_punch_in: 'Recorded "Clock-in" of %{name}.',

  punch_finish_punch_out: 'Recorded "Clock-out" of %{name}.',

  punch_finish_break_start: 'Recorded "Break-in" of %{name}.',
  punch_finish_break_end: 'Recorded "Break-out" of %{name}.',

  punch_finish_out_punch_in: 'Recorded "Clock-in" of %{name} at out of the shift.',

  punch_getting_location: "Searching location…",

  payment_each_page: "Payslip",
  paid_on: "For payment on %{month}/%{day}/%{year}",
  paid_on_yearmonth: "Payment for %{month}/%{year}",
  payment_slip: "Payslip",
  payment_each_belong: "Branch",
  payment_each_name: "Name",
  payment_each_paidon: "Payment date",

  payment_each_attendance: "Attendance",
  payment_each_day_num: "Days",
  payment_each_time: "Hours",

  payment_each_reg_day: "Regular work days",
  payment_each_lawhol_day: "Legal holiday work days",
  payment_each_outhol_day: "Non-legal holiday work days",
  payment_each_mid_day: "Midnight work days",
  payment_each_early_day: "Early leaving days",
  payment_each_late_day: "Late coming days",
  payment_each_abs_day: "Absent days",
  payment_each_paidhol_day: "Paid holidays",
  payment_each_remain_paidhol_day: "Remaining paid holidays",
  payment_each_specialhol_day: "Privilege leave days",
  payment_each_transhol_day: "Transferred holidays",
  payment_each_comphol_day: "Compensatory leave days",
  payment_each_reg_time: "Regular work hours",
  payment_each_lawhol_time: "Legal holiday work hours",
  payment_each_outhol_time: "Non-legal holiday work hours",
  payment_each_preover_time: "Legal overtime work hours",
  payment_each_lawover_time: "Non-legal overtime work hours",
  payment_each_mid_time: "Midnight work hours",
  payment_each_early_time: "Early leaving hours",
  payment_each_late_time: "Late hours",
  timespan: "%{hour} h %{min} min",
  timespanmin: "%{min} min",
  payment_each_allowance: "Payment",
  payment_each_reg_pay: "Basic salary",
  payment_each_pres_pay: "Executive Compensation",
  payment_each_hol_pay: "Holiday allowance",
  payment_each_mid_pay: "Midnight allowance",
  payment_each_over_pay: "Overtime allowance",
  payment_each_deemed_over_pay: "Fixed overtime allowance",
  payment_each_paidhol_pay: "Paid holiday allowance",

  carfare: "Commutation allowance",
  payment_each_sum: "Total",
  payment_each_deduction: "Deduction",
  payment_each_health_ins: "Health insurance premium",
  payment_each_care_ins: "Long-term care insurance premium",
  payment_each_pension_ins: "Welfare pension insurance premium",
  payment_each_emp_ins: "Employment insurance premium",
  payment_each_social_ins_adj: "Adjustment of social insurance premium",
  payment_each_income_tax: "Income tax",
  payment_each_res_tax: "Residence tax",

  payment_each_other_allowance: "Other",
  payment_each_settlement: "Settlement money",
  payment_each_cash: "In-kind payment",
  payment_each_nencho: "Year-end adjustment",

  payment_each_deducted_sum: "Deducted amount",
  payment_each_bank_sum: "Transfer amount",

  payment_each_cash_sum: "Cash payment amount",
  payment_each_cumulative_sum: "Cumulative taxation amount",
  payment_each_cumulative_social_ins: "Cumulative social insurance amount",
  payment_each_tax_type: "Tax table category",
  payment_each_kou: "甲(kou)",
  payment_each_otsu: "乙(otsu)",
  payment_each_no_payment: "No payment data.",
  payment_each_no_lock: "Salary has not been finalized.",
  payment_each_no_employee: "Employee data is missing.",

  bonus_each_page: "Bonus details",
  bonus_slip: "Bonus slip",
  bonus_each_calc_span: "Accounting period",
  bonus_each_start: "Start",
  bonus_each_end: "End",
  bonus_each_money: "Bonus",
  bonus_each_no_bonus: "No bonus data.",
  bonus_each_no_employee_or_no_lock: "Bonus has not been finalized or employee data is missing.",

  document: "Documents",
  order_punch: "Correct the attendance",
  order_change: "Edit the information",
  order_holiday: "Holiday application",
  order_overtime: "Overtime application",

  tax_type: "Tax related information",
  family_dependant: "Family information",
  labor_info: "Labor information",
  payment_config: "Payment settings",
  taxins_config: "Tax, social insurance",
  paidhol: "Paid holidays",
  other_info: "Other information",
  logout: "Logout",
  contact: "Contact",
  contact_destination: "Send to",
  contact_admin: "Administrator",
  contact_company_manager: "Company Administrator",
  contact_arrow_support: "System Support Desk",
  contact_content: "Details",
  contact_send: "Send",

  are_you_sure_to_send: "Are you sure to send it?",
  reply_via_mail: "You will receive the reply by email.",
  contact_no_send: "Send",
  contact_finish_send: "Your request has been submitted.",
  contact_finish_attention: "Please wait until you get your reply.",

  logout_confirm: "Are you sure to logout?",

  kana: "Kana",
  last_name_kana: "Family name (Kana)",
  first_name_kana: "First name (Kana)",
  auth: "Auth",
  parttime_employee: "Part-time worker",
  fulltime_employee: "Full-time employee",
  board_employee: "Executive",
  other_employee: "Others",
  auth_employee: "Staff",
  auth_branch: "Branch administrator",
  auth_company: "Company administrator",
  main_branch: "Main branch/section",
  sub_branch: "Sub branch/section",
  belong_branch: "Branch/Section",
  branch_name: "Branch/Section name",
  post: "Position",
  belong: "Belongs",
  employee: "Employee",
  employee_type: "Purpose",
  all: "All",
  shift_attendance: "Shift/Attendance",
  payment_bonus_nencho: "Salary/Bonus/Year-end adjustment",
  payment_bonus_nencho_short: "Salary/Bonus/Year-end adjustment",
  payment_bonus_tax_short: "Salary/Bonus/TaxReduction adjustment",
  nencho: "Year-end adjustment",
  over_allowance: "Overtime allowance",
  notification: "Notification",
  allowance: "Allowance",
  employee_page: "Employee page",
  self_general: "Personal details",
  labor_general: "Labor information",
  general: "General",
  regist_employee_bank: "Register of bank account",
  already_regist: "Registered",
  nickname: "Nickname",
  nickname_placeholder: "If blank, your name would be the nickname.",
  year: "Year",
  month: "Month",
  day: "Day",
  kou: "甲(kou)",
  otsu: "乙(otsu)",
  dependant_num: "Number of dependents (auto calculation)",
  different_real_dependant_num: "It could be different from the actual number.",
  relationship: "Relationship",
  estimated_income: "Estimated income for this year.",
  person: "Person",
  year_old: "Age",
  eldest_son: "Eldest son",
  second_son: "Second son",
  third_son: "Third son",
  forth_son: "Forth son",
  fifth_son: "Fifth son",
  sixth_son: "Sixth son",
  eldest_daughter: "Eldest daughter",
  second_daughter: "Second daughter",
  third_daughter: "Third daughter",
  forth_daughter: "Forth daughter",
  fifth_daughter: "Fifth daughter",
  sixth_daughter: "Sixth daughter",
  child: "Child",
  stepfather: "Stepfather",
  stepmother: "Stepmother",
  grandfather: "Grandfather",
  grandmother: "Grandmother",
  stepgrandfather: "Stepgrandfather",
  stepgrandmother: "Stepgrandmother",
  grandson: "Grandson",
  nephew: "Nephew",
  niece: "Niece",
  uncle: "Uncle",
  aunt: "Aunt",
  older_brother: "Brother (older)",
  younger_brother: "Brother (younger)",
  step_older_brother: "Stepbrother (older)",
  step_younger_brother: "Stepbrother (younger)",
  older_sister: "Sister (older)",
  younger_sister: "Sister (younger)",
  step_older_sister: "Stepsister (older)",
  step_younger_sister: "Stepsister (younger)",

  hiring_type: "Division",

  there_isnt: "None",

  there_is: "Yes",

  paidhol_remain_day: "Remaining",
  paidhol_history: "History",
  paidhol_day_count: "Days",
  paidhol_expiration: "Exp.",
  day_format: "%{day}",
  paidhol_nodata: "No history",

  demand_holiday: "Apply for vacation",
  demand_holiday_no_demand: "No application",
  demnad_holiday_new_demand: "New application",
  demand_holiday_add_new_demand: "New application for paid holiday",
  demand_holiday_preferred_get_date: "Request date",
  demand_holiday_preferred_date: "Request date",

  demand_holiday_kind: "Type",
  demand_holiday_paidhol_full: "Full-day pay",
  demand_holiday_paidhol_first_half: "Full-day pay (first half)",
  demand_holiday_paidhol_second_half: "Full-day pay (second half)",
  demand_holiday_paidhol_hourly: "Time paid",
  demand_holiday_special_holiday: "Privilege leave",
  demand_holiday_demanding: "Requesting",
  demand_holiday_admit: "Approved",
  demand_holiday_deny: "Rejected",
  demand_holiday_cannot_delete: "You cannot delete a request which is approved or rejected.",
  demand_holiday_cancel_confirm: "Are you sure to cancel your request?",
  demand_holiday_no_cancel: "Do not cancel",
  demand_holiday_cancel: "Do cancel",
  "1hour": "1 hour",

  demand_holiday_time: "Request Date",
  demand_holiday_reason: "Reason",
  demand_holiday_reason_optional: "Reason(optional)",
  demand_holiday_no: "None",
  demand_holiday_status: "Status",

  demand_holiday_create_confirm: "Are you sure to submit your request?",
  demand_holiday_send: "Cancel",
  demand_holiday_no_send: "OK",
  demand_holiday_finish_create: "Your request has been submitted.",

  date_format: "%{month}/%{day}/%{year}",
  yearmonth: "%{month}/%{year}",
  invalid_date: "Invalid Date",

  document_page: "Documents/Aggregation",
  aggregation: "Aggregation",
  document_select_document: "Please choose the document",
  document_join_related: "Joining related",
  document_dependant_deduction: "Application for Exemption for Dependents",
  document_socialins_get: "",
  document_socialins_dependant: "",
  document_empins_get: "",
  document_retire_related: "",
  document_gensen_employee: "",
  document_empins_retire: "",
  document_socialins_lost: "",
  document_empins_lost: "",
  document_transfer: "",
  document_payment_related: "Salary related",
  document_payment_slip: "Payslip",
  document_bonus_slip: "Bonus details",
  document_gensen: "Withholding slip",
  document_gensenpay: "",
  document_daicho: "",
  document_gensen_book: "",
  document_bonus_pay: "",
  document_socialins_related: "",
  document_laborins: "",
  document_renew_stdincome: "",
  document_update_stdincome: "",
  document_nencho_related: "Year-end adjustment related",

  document_insurance: "Deduction for insurance premiums",
  document_loan: "The special deduction for housing loans",
  document_kihaisho: "Basic, Spouse, Income Adjustment Deduction",
  document_payment_table: "Payment report",
  document_legal_table: "Legal record total table",

  document_enter_yearmonth: "Please enter the year and month that you want to download.",
  document_enter_year: "Please select the year that you want to download.",
  document_enter_nendo: "Please select the business year that you want to download.",

  document_choose_output_type: "Please choose the output method.",
  document_download_pdf: "PDF download",

  document_nodata: "No data.",

  document_gensen_attention:
    "If you start using ARROW in the middle of the year, the withholding slip for retired persons may not be correct.",

  demand_punch_page: "Correct the attendance",
  demand_punch_shift_punch: "Shift / Attendance",
  demand_punch_ordering: "(applying)",
  demand_punch_order_delete: "(applying deletion)",

  demand_punch_cancel_confirm: "Are you sure to cancel this application?",
  demand_punch_cancel_detail: "You cannot undo this operation.",
  demand_punch_yes_cancel: "Do cancel",
  demand_punch_no_cancel: "Do not cancel",
  work_type_normal: "Normal",
  work_type_outhol: "Non-legal holiday",
  work_type_lawhol: "Legal holiday",
  work_type_paidhol_full: "Full-day pay",
  work_type_paidhol_half: "Half-day pay",
  work_type_specialhol: "Privilege leave",
  work_type_transhol: "Transferred holiday",
  work_type_comphol: "Compensatory leave",
  work_type_abs: "Absent",
  work_type_paidhol_hourly: "Time paid",

  demand_punch_cannot_add_shift: "Shifts cannot be added because the attendance is locked.",
  demand_punch_cannot_cancel_order:
    "You cannot cancel your request because the attendance is locked.",

  demand_change_submit: "Submit Request",
  demand_change_dialog_title: "Are you sure to submit your request?",
  demand_change_dialog_msg:
    "An employee information change request will be submitted with this information.",
  demand_change_dialog_detail:
    "After submission, the administrator must approve the change to complete the change.",
  demand_change_cancel: "Submit",
  demand_change_exec: "Cancel",
  demand_change_finish_dialog_msg: "Your request has been submitted.",
  demand_change_finish_dialog_detail: "You will get an email once your request is approved.",
  demand_change_admit_dialog_title: "Are you sure to admit this request?",
  demand_change_admit_dialog_msg: "The personal information will be overwritten once you admit.",
  demand_change_admit_cancel: "Cancel",
  demand_change_admit_exec: "Approve",
  demand_change_admit_finish_title: "The request has been approved.",
  demand_change_admit_finish_msg: "The personal information has been changed.",
  demand_change_admit_carfare_dialog_msg:
    "The personal information will be overwritten once you admit.<br>In addition, since the items related to commutation allowance have been changed, please make changes to the commutation allowance from the Employee Details page as necessary after approval.",
  demand_change_admit_carfare_finish_msg:
    "The personal information has been changed.<br>Since the items related to commutation allowance have been changed, please make changes to the commutation allowance from the Employee Details page as necessary.",
  demand_change_deny_dialog_title: "Are you sure to reject this request?",
  demand_change_deny_dialog_msg: "Once rejected, the application will be discarded.",
  demand_change_deny_cancel: "Cancel",
  demand_change_deny_exec: "Reject",
  demand_change_deny_finish_msg: "The request has been rejected.",
  demand_overtime_page: "Overtime application",
  demand_overtime_new_order: "New application",
  demand_overtime_new_overtime_order: "New overtime application",
  demand_overtime_dest: "Application destination",
  demand_overtime_kind: "Type",
  demand_overtime_kind_over: "Overtime",
  demand_overtime_kind_early: "Early",
  demand_overtime_kind_out_shift: "Out of shift attendance",
  demand_overtime_preferred_get_date: "Desired date",
  demand_overtime_timespan: "Time",
  demand_overtime_reason: "Reason",
  demand_overtime_reason_optional: "Reason (optional)",
  demand_overtime_enter_above_mandatory: "Please enter the required fields above.",
  demand_overtime_submit: "Submit",
  demand_overtime_noshift: "No shift",
  demand_overtime_select_shift: "Select shift",
  demand_overtime_confirm: "Are you sure to submit the overtime application?",
  demand_overtime_no_send: "Cancel",
  demand_overtime_send: "Submit",
  demand_overtime_finish: "Your application has been submitted.",
  demand_overtime_cancel_confirm: "Are you sure to cancel your application?",
  demand_overtime_cancel: "Do cancel",
  demand_overtime_no_cancel: "Do not cancel",

  demand_overtime_preferred_date: "Desired date",
  demand_overtime_order_datetime: "Applied on",
  demand_overtime_no: "None",
  demand_overtime_no_order: "No data",
  demand_overtime_cannot_delete: "You cannot delete an application which is approved or rejected.",
  demand_overtime_demanding: "Applying",
  demand_overtime_admit: "Approved",
  demand_overtime_deny: "Rejected",
  inform_news: "Notification",
  inform_importance: "Importance",
  inform_importance_high: "High",
  inform_importance_middle: "Middle",
  inform_importance_low: "Low",
  inform_news_from_admin: "Notice from the administrator",
  inform_no_news: "There is no notification.",
  inform_news_from_system: "Notice from the system.",
  inform_waiting_document: "You have unagreed documents.",
  datetime: "%{hour}:%{minute} %{month}/%{day}/%{year}",

  document_thereis_waiting_document:
    'You have <span class=""font-red"">%{num}</span> documents awaiting agreement.',
  document_link_to_waiting_document: "Please check from here.",
  contract_document_detail: "Document details",
  waiting_document_page: "Document awaiting agreement",
  waiting_document_no_document: "There is no document",
  waiting_document_received_date: "Received date",
  contract_document_need_contract: "You need to agree to this document.",
  contract_document_attention:
    "After you confirm the document and signature your name, please tap the agree or reject button.",
  contract_document_created_on: "Creation date",
  contract_document_waiting: "Document awaiting agreement",
  contract_document_admitted: "Agreed documents",
  contract_document_admitted_on: "Agreed date",
  contract_document_denied: "Rejected documents",
  contract_document_denied_on: "Rejected date",
  contract_document_auth: "Signature",
  contract_document_deny: "Reject",
  contract_document_admit: "Agree",
  contract_document_admit_confirm: "Are you sure to agree to this document?",
  contract_document_admit_confirm_detail:
    "Once agreed, it cannot be cancelled. <br> I recommend to print out this document.",
  contract_document_no_admit: "No",

  contract_document_admit_finish: "Agreement completed.",
  contract_document_deny_confirm: "Are you sure to reject this document?",
  contract_document_deny_confirm_detail:
    "Once rejected, it cannot be cancelled. <br> I recommend to print out this document.",
  contract_document_no_deny: "No",
  contract_document_deny_finish: "Rejected",

  info: info_language.en,
  popup: popup_language.en,
  error: error_language.en,
};

export default {
  ja: ja,
  en: {
    ...ja,
    ...en,
  },
};
