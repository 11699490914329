import { Mutation, MutationAction, Action, VuexModule, getModule, Module } from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import Vue from 'vue';

export type BranchData = {
  id: number, name: string
}

export type BranchObject = {
  [id: number]: string,
}

export type Data = {
  branches: BranchData[],
  branch_hash: BranchObject,
  branch_hash_all: BranchObject,
  branch_hash_object: any,
}

@Module({ dynamic: true, store, name: "bundle_global_data", namespaced: true })
class NenchoGlobal extends VuexModule {
  data: Data | null = null;
  is_valid: boolean = false;
  is_set: boolean = false;
  fetching: Promise<void> = null;

  @Mutation
  public set({data, auth}: {data: Data, auth: number}) {
    var branch_hash = util.createHashReal(data.branches, 'id', 'name');
    if (auth == 2)
      branch_hash[0] = '全店舗';
    Vue.set(this, 'data', {
      branches: data.branches,
      branch_hash: branch_hash,
      branch_hash_all: util.createHash(data.branches, 'id', 'name'),
      branch_hash_object: util.createHashObject(data.branches, 'id'),
    });
    this.is_valid = true;
    this.is_set = true;
    this.fetching = null;
  }
  @Mutation
  public disable() {
    this.is_valid = false;
  }
  @Mutation
  public disable_all() {
    this.is_valid = false;
  }
  
  @Mutation
  public setFetching({value}: {value: Promise<void>}) {
    this.fetching = value;
  }


  // actions
  @Action({ rawError: true })
  public async fetch({auth}: {auth: number}) {
    //ないなら作る
    if (this.fetching) {
      await this.fetching;
    } else {
      const promise = util.post('bundle/read_global', {}).then((res) => {
        this.set({auth, data: res.data});
      });
      this.setFetching({value: promise});
      await promise;
    }
  }
}

export default getModule(NenchoGlobal);