import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import Vue from "vue";
import Shift from "@/typesold/shift";
import Able from "@/typesold/able";
import Shift_memo from "@/typesold/shift_memo";

export type Employee = {
  id: number;
  name: string;
};
export type EmployeeHash = {
  [id: number]: string;
};
export type Data = {
  branches: any;
  branch_hash: any;
  is_valid?: boolean;
  is_set: boolean;
  fetching?: Promise<void>;
};
export type TypelyData = {
  [type: number]: Data;
};
export type MonthlyData = {
  [year: number]: TypelyData;
};

@Module({ dynamic: true, store, name: "document_branch_yearly", namespaced: true })
class Monthly extends VuexModule {
  data: MonthlyData = {};

  @Mutation
  public set({ auth, year, type, data }: { auth: number; year: number; type: number; data: Data }) {
    var branch_hash = null;
    if (data.branches.length != 0) {
      branch_hash = util.createHash(data.branches, "id", "name");
      if (auth == 2 || auth == 1) branch_hash[0] = "全店舗";
    }
    Vue.set(this.data[year], type, {
      branches: data.branches,
      branch_hash: branch_hash,
      is_valid: true,
      is_set: true,
      fetching: null,
    });
  }

  @Mutation
  public disable({ year, type }: { year: number; type: number }) {
    if (type === undefined) {
      if (year === undefined) {
        Object.keys(this.data).forEach((year) => {
          Object.keys(this.data[year]).forEach((type) => {
            this.data[year][type].is_valid = false;
          });
        });
      } else {
        if (this.data[year]) {
          Object.keys(this.data[year]).forEach((type) => {
            this.data[year][type].is_valid = false;
          });
        }
      }
    } else {
      if (this.data[year] && this.data[year][type]) {
        this.data[year][type].is_valid = false;
      }
    }
  }
  @Mutation
  public disable_all() {
    Object.keys(this.data).forEach((year) => {
      Object.keys(this.data[year]).forEach((type) => {
        this.data[year][type].is_valid = false;
      });
    });
  }
  @Mutation
  public setFetching({ year, type, value }: { year: number; type: number; value: Promise<void> }) {
    this.data[year][type].fetching = value;
  }

  // actions
  @Action({ rawError: true })
  public async fetch({ auth, year, type }: { auth: number; year: number; type: number }) {
    //ないなら作る
    if (!this.data[year]) {
      Vue.set(this.data, year, {});
    }
    if (!this.data[year][type]) {
      Vue.set(this.data[year], type, {
        branches: [],
        branch_hash: {},
        is_valid: false,
        is_set: false,
        fetching: null,
      });
    }

    if (this.data[year][type].fetching) {
      await this.data[year][type].fetching;
    } else {
      const promise = util.post("document/read_branch_yearly", { year, type }).then((res) => {
        this.set({ auth, year, type, data: res.data });
      });
      this.setFetching({ year, type, value: promise });
      await promise;
    }
  }
}

export default getModule(Monthly);
