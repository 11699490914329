//MANAGER
//shift
import { default as shift_manager } from "@/vuex/shift/manager";
import { default as shift_bundle_manager } from "@/vuex/shift/shiftbundle_manager";
import { default as need_manager } from "@/vuex/shift/need_manager";

//submit
import { default as submit_manager } from "@/vuex/submit/manager";

//attendance
import { default as attendance_manager } from "@/vuex/attendance/manager";

//attendance_each
import { default as attendance_each_manager } from "@/vuex/attendance_each/manager";

//payment
import { default as payment_manager } from "@/vuex/payment/manager";

//payment_each
import { default as payment_each_manager } from "@/vuex/payment_each/manager";

//bonus
import { default as bonus_manager } from "@/vuex/bonus/manager";

//bonus_each
import { default as bonus_each_manager } from "@/vuex/bonus_each/manager";

//company
import { default as company_manager } from "@/vuex/company/manager";

//employee
import { default as employee_manager } from "@/vuex/employee/manager";

//employee_each
import { default as employee_each_manager } from "@/vuex/employee_each/manager";
import { default as employee_admit_manager } from "@/vuex/employee_admit/manager";
import { default as employee_add_manager } from "@/vuex/employee_add/manager";
import { default as employee_demand_manager } from "@/vuex/employee_demand/manager";

//config
import { default as config_manager } from "@/vuex/config/manager";
import { default as config_individualunit_manager } from "@/vuex/config/individualunit/manager";

//demand_holiday
import { default as demand_holiday_manager } from "@/vuex/demand_holiday/manager";

//demand_overtime
import { default as demand_overtime_manager } from "@/vuex/demand_overtime/manager";

//demand_punch
import { default as demand_punch_manager } from "@/vuex/demand_punch/manager";

//demand_change

//punch
import { default as punch_manager } from "@/vuex/punch/manager";

//header

//aggregation
import { default as aggregation_manager } from "@/vuex/aggregation/global";

//document
import { default as document_manager } from "@/vuex/document/manager";

//nencho
import { default as nencho_manager } from "@/vuex/nencho/manager";

//laborins
import { default as laborins_manager } from "@/vuex/laborins/manager";

//renew_stdincome
import { default as renew_stdincome_manager } from "@/vuex/renew_stdincome/manager";

//update_stdincome
import { default as update_stdincome_manager } from "@/vuex/update_stdincome/manager";

//laborins
import { default as notification_manager } from "@/vuex/notification/manager";

//almighty
import { default as almighty_manager } from "@/vuex/almighty/manager";

//passreset
import { default as passreset_manager } from "@/vuex/passreset/manager";

//employee_each
import { default as employee_each_individualunit_manager } from "@/vuex/employee_each/individualunit/manager";

//join
import { default as join_manager } from "@/vuex/join/manager";

//nencho
import { default as nencho_new_manager } from "@/vuex/nencho/new_manager";

//fixed_tax_reduction
import { default as fixed_tax_reduction_new_manager } from "@/vuex/fixed_tax_reduction/new_manager";

const manager_object = {
  // //MANAGER
  // //shift
  //   shift_manager,
  //   shift_bundle_manager,
  //   need_manager,

  // //submit
  //   submit_manager,

  // //attendance
  //   attendance_manager,

  // //attendance_each
  //   attendance_each_manager,

  // //payment
  //   payment_manager,

  // //payment_each
  //   payment_each_manager,

  // //bonus
  //   bonus_manager,

  // //bonus_each
  //   bonus_each_manager,

  // //company
  //   company_manager,

  // //employee
  //   employee_manager,

  // //employee_each
  //   employee_each_manager,

  // //config
  //   config_manager,

  // //demand_holiday
  //   demand_holiday_manager,

  // //demand_overtime
  //   demand_overtime_manager,

  // //demand_punch
  //   demand_punch_manager,

  // //demand_change

  // //punch

  // //header

  // //aggregation

  // //document

  //   //nencho
  //   nencho_manager,

  //laborins
  laborins_manager: new laborins_manager(),

  //renew_stdincome
  renew_stdincome_manager: new renew_stdincome_manager(),

  //update_stdincome
  update_stdincome_manager: new update_stdincome_manager(),

  //notification
  notification_manager: new notification_manager(),

  //almighty
  almighty_manager: new almighty_manager(),

  //passreset
  passreset_manager: new passreset_manager(),

  //config
  config_manager: new config_manager(),
  config_individualunit_manager: new config_individualunit_manager(),

  //employee_each
  employee_each_individualunit_manager: new employee_each_individualunit_manager(),

  //company
  company_manager: new company_manager(),

  //employee_each
  employee_each_manager: new employee_each_manager(),
  employee_admit_manager: new employee_admit_manager(),
  employee_add_manager: new employee_add_manager(),
  employee_demand_manager: new employee_demand_manager(),

  //join
  join_manager: new join_manager(),

  //nencho
  nencho_new_manager: new nencho_new_manager(),

  //fixed_tax_reduction
  fixed_tax_reduction_new_manager: new fixed_tax_reduction_new_manager(),
};

export default manager_object;
