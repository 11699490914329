import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";

export type BranchData = {
  id: number;
  name: string;
};

export type GlobalData = {
  branches: BranchData[];
  branch_hash_all: { [id: number]: string };
  branch_hash: { [id: number]: string };
  national_holidays: string[];
};

@Module({ dynamic: true, store, name: "shift_global", namespaced: true })
class Global extends VuexModule {
  data: GlobalData | null = null;
  is_valid: boolean = false;
  is_set: boolean = false;
  fetching: Promise<void> = null;

  @Mutation
  public disable({}) {
    this.is_valid = false;
  }
  @Mutation
  public disable_all() {
    this.is_valid = false;
  }

  @Mutation
  public setFetching({ value }: { value: Promise<void> }) {
    this.fetching = value;
  }
  @Mutation
  public set(data: GlobalData) {
    this.data = {
      ...data,
      branch_hash: util.createHashReal(data.branches, "id", "name"),
      branch_hash_all: util.createHash(data.branches, "id", "name"),
    };
    this.is_valid = true;
    this.is_set = true;
    this.fetching = null;
  }

  // actions
  @Action({ rawError: true })
  public async fetch() {
    if (this.fetching) {
      await this.fetching;
    } else {
      const promise = util.post("shift/read_global", {}).then((res) => {
        this.set(res.data);
      });
      this.setFetching({ value: promise });
      await promise;
    }
  }
}

export default getModule(Global);
