import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import Vue from "vue";
import { Document_template } from "@/typesold/Document_template";

export type Data = {
  document_template: Document_template;
  pdf: any;
  is_valid?: boolean;
  is_set: boolean;
  fetching?: Promise<void>;
};

export type DocumentTemplatelyData = {
  [document_template_id: number]: Data;
};

@Module({ dynamic: true, store, name: "template_each", namespaced: true })
class Global extends VuexModule {
  data: DocumentTemplatelyData = {};

  @Mutation
  public disable({ document_template_id }: { document_template_id?: number }) {
    if (document_template_id === undefined) {
      Object.keys(this.data).forEach((document_template_id) => {
        this.data[document_template_id].is_valid = false;
      });
    } else if (this.data[document_template_id]) {
      this.data[document_template_id].is_valid = false;
    }
  }
  @Mutation
  public disable_all() {
    Object.keys(this.data).forEach((document_template_id) => {
      this.data[document_template_id].is_valid = false;
    });
  }
  @Mutation
  public setFetching({
    document_template_id,
    value,
  }: {
    document_template_id: number;
    value: Promise<void>;
  }) {
    this.data[document_template_id].fetching = value;
  }
  @Mutation
  public set({ document_template_id, data }: { document_template_id: number; data }) {
    Vue.set(this.data, document_template_id, {
      ...data,
      pdf: null,
      is_valid: true,
      is_set: true,
      fetching: null,
    });
  }
  @Mutation
  public setPdf({ document_template_id, pdf }: { document_template_id: number; pdf }) {
    Vue.set(this.data[document_template_id], "pdf", pdf);
  }

  // actions
  @Action({ rawError: true })
  public async fetch({ document_template_id }: { document_template_id: number }) {
    //ないなら作る
    if (!this.data[document_template_id]) {
      Vue.set(this.data, document_template_id, {
        document_template: {},
        pdf: null,
        is_valid: false,
        is_set: false,
        fetching: null,
      });
    }

    if (this.data[document_template_id].fetching) {
      await this.data[document_template_id].fetching;
    } else {
      const promise = util
        .post("config/read_document_template_each", { document_template_id })
        .then((res) => {
          this.set({ document_template_id, data: res.data });
          this.fetchPdf({ document_template_id });
        });
      this.setFetching({ document_template_id, value: promise });
      await promise;
    }
  }

  @Action({ rawError: true })
  public async fetchPdf({ document_template_id }: { document_template_id: number }) {
    const promise = util
      .post_document_return("document/generate_sample_document", { document_template_id })
      .then((res) => {
        this.setPdf({ document_template_id, pdf: res });
      });
    await promise;
  }

  @Action({ rawError: true })
  public updateEmployee({ document_template_id, document_template }) {
    Vue.set(this.data[document_template_id], "document_template", document_template);
  }
}

export default getModule(Global);
