import { Component, Vue } from "vue-property-decorator";
import * as util from "@/util";
import header from "@/vuex/header";
import master_realtime from "@/vuex/master/realtime";
import * as vali from "@/validations/validationUtil";
import { EmployeeHeaderData, BranchHeaderData, CompanyHeaderData } from "@/typesold/header";
import node from "@/vuex/node";
import ScrollHint from "scroll-hint";
import dialog from "@/vuex/dialog";
import filter from "./library/filter";
import i18n from "@/plugins/i18n";
import option from "@/library/option";

@Component
export default class utilMixins extends Vue {
  PC_WIDTH: number = 1200;
  TAB_WIDTH: number = 960;
  SP_WIDTH: number = 500;

  get filter() {
    return filter;
  }
  get option() {
    return option;
  }
  get util() {
    return util;
  }

  scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  isInputting(): boolean {
    return this.m && (this.m.isEdittingNew() || this.m.isAddingNew());
  }

  get page() {
    return this.query.page;
  }
  set page(val) {
    this.changePage({ val });
    // if (val != this.page) {
    //   if (this.isInputting()) {
    //     dialog.openForceConfirmDialog({title: '確認', msg: 'ページを移動してよろしいですか？', detail: '行った変更は全て破棄されます', func: this.changePage, args: {val}, exec_button: '変更を破棄', });
    //   } else {
    //     this.changePage({val});
    //   }
    // }
  }
  changePage({ val }) {
    this.query = { page: val };
  }

  get tags() {
    return this.header.tags;
  }

  get page_name() {
    return this.$route.name;
  }
  get query() {
    return this.$route.query;
  }
  set query(query: any) {
    let changed: boolean = false;
    var before_query = Object.assign({}, this.$route.query);
    Object.keys(query).forEach((key) => {
      if (query[key]) {
        if (query[key] != before_query[key]) changed = true;
        Object.assign(before_query, { [key]: query[key] });
      } else {
        if (before_query[key]) {
          changed = true;
          delete before_query[key];
        }
      }
    });

    if (changed) this.$router.replace({ ...this.$route, query: before_query, hash: undefined });
  }
  get query_push() {
    return this.$route.query;
  }
  set query_push(query: any) {
    let changed: boolean = false;
    var before_query = Object.assign({}, this.$route.query);
    Object.keys(query).forEach((key) => {
      if (query[key]) {
        if (query[key] != before_query[key]) changed = true;
        Object.assign(before_query, { [key]: query[key] });
      } else {
        if (before_query[key]) {
          changed = true;
          delete before_query[key];
        }
      }
    });
    if (changed) this.$router.push({ ...this.$route, query: before_query, hash: undefined });
  }
  async change_page(name, query = {}) {
    this.$router.push({ name: name, query: { ...this.$route.query, ...query } });
  }
  change_page_process({ name, query }) {
    this.$router.push({ name: name, query: { ...this.$route.query, ...query } });
  }
  move_page(page, forcepassquery) {
    let query =
      util.passquery[page] === undefined
        ? { ...this.query }
        : {
            ...util.passquery[page],
            ...this.query,
          };
    query =
      forcepassquery === undefined
        ? query
        : {
            ...query,
            ...forcepassquery,
          };
    this.change_page(page, query);
  }

  get windowSize() {
    return { x: window.innerWidth, y: window.innerHeight };
  }

  get s_company_code() {
    return util.getLocalStorage("company_code");
  }
  get s_company_id() {
    return Number(util.getLocalStorage("company_id"));
  }
  get s_branch_id() {
    return Number(util.getLocalStorage("branch_id"));
  }
  get s_employee_id() {
    return Number(util.getLocalStorage("employee_id"));
  }
  get s_auth() {
    return Number(util.getLocalStorage("auth"));
  }
  get s_is_root() {
    return Number(util.getLocalStorage("is_root"));
  }

  isNowPage(page: string) {
    return this.$route.name == page;
  }

  get is_manager() {
    return this.s_auth == 1 || this.s_auth == 2;
  }

  get is_set_header(): boolean {
    return header.is_set;
  }
  get is_valid_header(): boolean {
    return header.is_valid;
  }
  async get_header() {
    await header.fetch();
  }
  get header() {
    if (!this.is_set_header || !this.is_valid_header) this.get_header();
    if (this.is_set_header) {
      if (this.s_auth == 0) {
        return header.data as any;
      } else if (this.s_auth == 1) {
        return header.data as any;
      } else if (this.s_auth == 2) {
        return header.data as any;
      }
    }
  }

  get is_disp_personal_payment() {
    return this.header.config ? (this.header.config.is_disp_personal_payment ?? 1) == 1 : true;
  }

  async read_bank(bank_name: null | string) {
    if (bank_name !== null) return master_realtime.readBank({ bank_name });
    else return [];
  }
  async read_branch(bank_code: null | string, branch_name: null | string) {
    if (bank_code !== null && branch_name !== null) {
      if (Number(bank_code) == 9900) {
        if (String(branch_name).length >= 3) {
          var branch_type = Number(String(branch_name).slice(0, 1));
          var branch_code = String(branch_name).slice(1, 3);

          //もし先頭が0なら振替, 2,3桁めに9をつける
          //そうでないなら総合や普通, 2,3桁目に8をつける
          if (branch_type == 0) {
            //振替口座
            branch_code = branch_code + "9";
          } else {
            //総合・普通口座
            branch_code = branch_code + "8";
          }

          return master_realtime.readBranchYucho({ bank_code, branch_code });
        } else {
          return [];
        }
      } else {
        return master_realtime.readBranch({ bank_code, branch_name });
      }
    } else {
      return [];
    }
  }
  async read_taxoffice(taxoffice_name: null | string) {
    if (taxoffice_name !== null) return await master_realtime.readTaxoffice({ taxoffice_name });
    else return [];
  }
  async read_zip(zip: null | string) {
    if (zip !== null) {
      if (!vali.is_zip(zip)) return null;
      return await master_realtime.readZip({ zip });
    } else {
      return null;
    }
  }

  get browser() {
    var userAgent = window.navigator.userAgent.toLowerCase();
    if (userAgent.indexOf("msie") != -1) {
      return "msie";
    } else if (userAgent.indexOf("trident") != -1) {
      return "trident";
    } else if (userAgent.indexOf("edge") != -1) {
      return "edge";
    } else if (userAgent.indexOf("edg") != -1) {
      return "edg";
    } else if (userAgent.indexOf("chrome") != -1) {
      return "chrome";
    } else if (userAgent.indexOf("safari") != -1) {
      return "safari";
    } else if (userAgent.indexOf("firefox") != -1) {
      return "firefox";
    }
  }

  get os() {
    if (
      navigator.userAgent.indexOf("Mac") > 0 ||
      navigator.userAgent.indexOf("iPhone") > 0 ||
      navigator.userAgent.indexOf("iPod") > 0 ||
      navigator.userAgent.indexOf("iPad") > 0
    ) {
      return "ios";
    } else if (navigator.userAgent.indexOf("Android") > 0) {
      return "android";
    } else {
      return "other";
    }
  }

  get ipad() {
    var ua = window.navigator.userAgent.toLowerCase();
    return ua.indexOf("ipad") > -1 || (ua.indexOf("macintosh") > -1 && "ontouchend" in document);
  }

  get is_multi_branch() {
    var header = { is_multi_branch: false };
    if (this.s_auth == 2) {
      header = this.header as CompanyHeaderData;
    } else if (this.s_auth == 1) {
      header = this.header as BranchHeaderData;
    } else {
      header = this.header as EmployeeHeaderData;
    }
    return header.is_multi_branch;
  }
  get is_flextime() {
    if (this.s_auth == 0) {
      var header = this.header as EmployeeHeaderData;
      return header.is_flex;
    } else {
      return false;
    }
  }

  cancel_all() {
    node.cancel_all_vuex();
  }

  get is_sp() {
    return this.windowSize.x <= this.SP_WIDTH;
  }
  get is_tab() {
    return !this.is_sp && this.windowSize.x <= this.TAB_WIDTH;
  }
  get is_pc() {
    return !this.is_sp && !this.is_tab;
  }

  enableScrollHint() {
    if (this.is_sp) {
      new ScrollHint([this.$el] as any, {
        i18n: {
          scrollable: util.$t("scrollable"),
        },
        offset: 0,
      });
    }
  }
}
