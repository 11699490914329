import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import { EmployeeHeaderData, BranchHeaderData, CompanyHeaderData } from "@/typesold/header";
import dialog from "./dialog";
import validate from "@/validations/validate";
import * as vali from "@/validations/validationUtil";

@Module({ dynamic: true, store, name: "header", namespaced: true })
class Header extends VuexModule {
  // state
  fetching: boolean = false;
  is_valid: boolean = false;
  is_set: boolean = false;
  data: null | EmployeeHeaderData | BranchHeaderData | CompanyHeaderData = null;

  is_open_logout_dialog: boolean = false;

  is_show_main_button: boolean = false;
  is_show_management_button: boolean = false;

  is_open_movie_dialog: boolean = false;
  is_open_popupnews_dialog: boolean = false;

  is_open_support_dialog: boolean = false;
  is_open_support_login_dialog: boolean = false;

  is_open_start_dialog: boolean = false;
  is_open_end_dialog: boolean = false;

  is_open_credit_dialog: boolean = false;

  is_open_pass_dialog: boolean = false;

  choices: any = [
    { time: null, date: null },
    { time: null, date: null },
    { time: null, date: null },
  ];
  mail: string = null;
  never_start_popup: boolean = false;

  // mutation
  @Mutation
  public setIsOpenLogoutDialog(val: boolean) {
    this.is_open_logout_dialog = val;
  }
  @Mutation
  public setIsShowMainButton(val: boolean) {
    this.is_show_main_button = val;
  }
  @Mutation
  public setIsShowManagementButton(val: boolean) {
    this.is_show_management_button = val;
  }
  @Mutation
  public setIsOpenMovieDialog(val: boolean) {
    this.is_open_movie_dialog = val;
  }
  @Mutation
  public setIsOpenPopupnewsDialog(val: boolean) {
    this.is_open_popupnews_dialog = val;
  }
  @Mutation
  public setIsOpenSupportDialog(val: boolean) {
    this.is_open_support_dialog = val;
  }
  @Mutation
  public setIsOpenSupportLoginDialog(val: boolean) {
    this.is_open_support_login_dialog = val;
  }
  @Mutation
  public setIsOpenStartDialog(val: boolean) {
    this.is_open_start_dialog = val;
  }
  @Mutation
  public setIsOpenCreditDialog(val: boolean) {
    this.is_open_credit_dialog = val;
  }
  @Mutation
  public setIsOpenEndDialog(val: boolean) {
    this.is_open_end_dialog = val;
  }
  @Mutation
  public setIsOpenPassDialog(val: boolean) {
    this.is_open_pass_dialog = val;
  }

  @Mutation
  public setChoices(val) {
    this.choices = val;
  }
  @Mutation
  public setMail(val) {
    this.mail = val;
  }
  @Mutation
  public setNeverStartPopup(val) {
    this.never_start_popup = val;
  }

  @Mutation
  public set(header) {
    this.data = header;
    this.is_valid = true;
    this.is_set = true;
  }
  @Mutation
  public disable({}) {
    this.is_valid = false;
  }
  @Mutation
  public disable_all() {
    //dataをnullにするのは挙動がおかしくなる disableと同じ動きをする
    this.is_valid = false;
  }
  @Mutation
  public disable_set() {
    this.is_set = false;
  }

  @Mutation
  public setFetching(value) {
    this.fetching = value;
  }

  // actions
  @Action({ rawError: true })
  public async fetch() {
    if (this.fetching) return;
    this.setFetching(true);
    const res = await util.post("login/read_header", {});
    this.set(res.data);
    this.setFetching(false);
  }

  // actions
  @Action({ rawError: true })
  public async sendSupportMail({ content, auth }) {
    if (content == null) {
      dialog.openErrorAlertDialog({ msg: util.$t("error.contact_enter_content") });
    } else {
      dialog.openConfirmDialog({
        msg: util.$t("are_you_sure_to_send"),
        detail: util.$t("reply_via_mail"),
        cancel_button: util.$t("contact_no_send"),
        exec_button: util.$t("contact_send"),
        func: this.sendSupportMailProcess,
        args: { content, auth },
      });
    }
  }

  // actions
  @Action({ rawError: true })
  public async sendSupportMailProcess({ content, auth }) {
    await util.post("support/send_support_mail", { content: content });
    this.setIsOpenSupportDialog(false);
    dialog.openAlertDialog({
      msg: util.$t("contact_finish_send"),
      detail: util.$t("contact_finish_attention"),
    });
    // dialog.openAlertDialog({msg: '送信が完了しました。', detail: '12/29〜1/3までは年末年始休業のため1/4以降の返答となります。ご了承ください。'});
  }

  // actions
  @Action({ rawError: true })
  public async sendSupportLoginMail({ content, mail, name, auth, company_code }) {
    var error = [];
    if (name == null) error.push("名前が入力されていません");
    if (mail == null) error.push("メールアドレスが入力されていません");
    if (auth == null) error.push("あなたの役職が入力されていません");
    if (content == null) error.push("内容が入力されていません");
    if (validate(error)) {
      dialog.openConfirmDialog({
        msg: "送信してよろしいですか？",
        detail: "返信はメールを通じて行われます。",
        cancel_button: "送信しない",
        exec_button: "送信する",
        func: this.sendSupportMailLoginProcess,
        args: { content, mail, name, auth, company_code },
      });
    }
  }

  // actions
  @Action({ rawError: true })
  public async sendSupportMailLoginProcess({ content, name, mail, auth, company_code }) {
    await util.post(
      "support/send_support_login_mail",
      { content: content, name: name, mail: mail, auth: auth, company_code: company_code },
      false,
    );
    this.setIsOpenSupportLoginDialog(false);
    dialog.openAlertDialog({ msg: "送信が完了しました。", detail: "返信をお待ちください。" });
    // dialog.openAlertDialog({msg: '送信が完了しました。', detail: '12/29〜1/3までは年末年始休業のため1/4以降の返答となります。ご了承ください。'});
  }

  @Action({ rawError: true })
  public async updateNeverStartPopup() {
    if (this.never_start_popup) {
      await util.post("support/update_never_start_popup", {});
    }
    this.setIsOpenStartDialog(false);
  }
  @Action({ rawError: true })
  public async submitChoice() {
    var error = [];
    if (
      this.choices[0].date == null ||
      this.choices[1].date == null ||
      this.choices[2].date == null ||
      this.choices[0].time == null ||
      this.choices[1].time == null ||
      this.choices[2].time == null
    )
      error.push("第1希望から第3希望全ての日付と時刻を入力してください。");
    if (validate(error)) {
      await util.post("support/submit_choice", { choices: this.choices });
      this.setIsOpenStartDialog(false);
      dialog.openAlertDialog({
        msg: "送信が完了しました。",
        detail: "返信が来るまでしばらくお待ちください。",
      });
    }
  }
  @Action({ rawError: true })
  public async sendPassreset(company_code) {
    var error = [];
    if (this.mail === null) error.push(util.$t("error.reset_password_enter_mail"));
    if (validate(error)) {
      await util.post(
        "login/send_reset_password",
        { company_code: company_code, mail: this.mail },
        false,
      );
      this.setIsOpenPassDialog(false);
      dialog.openAlertDialog({
        msg: util.$t("reset_password_finish"),
        detail:
          util.$t("reset_password_finish_detail1") +
          "<br>" +
          util.$t("reset_password_finish_detail2") +
          "<br>" +
          util.$t("reset_password_finish_detail3"),
      });
    }
  }
}

export default getModule(Header);
