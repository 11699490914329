
  import { Component, Mixins } from "vue-property-decorator";
  import dialog from "@/vuex/dialog";
  import utilMixins from "@/mixins";
  import * as util from "@/util";
  import header from "@/vuex/header";
  import UtilPopup from "@/components/util/Popup.vue";
  import CommonDate from "@/components/common/Date.vue";
  import CommonText from "@/components/common/Text.vue";
  import CommonCheck from "@/components/common/Check.vue";

  @Component({
    components: {
      UtilPopup,
      CommonDate,
      CommonText,
      CommonCheck,
    },
  })
  export default class Main extends Mixins(utilMixins) {
    get choices() {
      return header.choices;
    }
    set choices(val) {
      header.setChoices(val);
    }
    get never_start_popup() {
      return header.never_start_popup;
    }
    set never_start_popup(val) {
      header.setNeverStartPopup(val);
    }

    get is_display() {
      return header.is_open_start_dialog;
    }
    set is_display(val: boolean) {
      header.setIsOpenStartDialog(val);
    }

    cancel() {
      this.is_display = false;
    }
    submit_choice() {
      if (this.never_start_popup) {
        if (
          this.choices[0].date == null &&
          this.choices[0].time == null &&
          this.choices[1].date == null &&
          this.choices[1].time == null &&
          this.choices[2].date == null &&
          this.choices[2].time == null
        ) {
          //再度表示しない で送信
          header.updateNeverStartPopup();
        } else {
          if (this.validateChoice()) {
            header.submitChoice();
          }
        }
      } else {
        if (
          this.choices[0].date == null &&
          this.choices[0].time == null &&
          this.choices[1].date == null &&
          this.choices[1].time == null &&
          this.choices[2].date == null &&
          this.choices[2].time == null
        ) {
          //ただ閉じて次回再度表示
          this.cancel();
        } else {
          if (this.validateChoice()) {
            header.submitChoice();
          }
        }
      }
    }

    validateChoice() {
      const error = [];
      const date_list = [];
      let i = 1;
      this.choices.forEach((choice) => {
        if (choice.date !== null && choice.time !== null) {
          if (util.date2unix(choice.date) <= util.date2unix(util.today())) {
            error.push(`第${i}希望に明日以降の日付を入力してください`);
          }
          if (
            util.date2day(choice.date) == 0 ||
            util.date2day(choice.date) == 6 ||
            this.header.national_holidays.includes(
              util.formatDate(util.date2obj(choice.date), "yyyy-mm-dd"),
            )
          ) {
            error.push(`第${i}希望に土日祝以外の日付を入力してください`);
          }
          if (choice.time < 11 || 17 < choice.time) {
            error.push(`第${i}希望に11時〜17時の間の時間帯を入力してください`);
          }
          date_list.push(util.formatDate(util.date2obj(choice.date), "yyyy-mm-dd"));
        }
        i++;
      });
      if (new Set(date_list).size !== date_list.length) {
        error.push(`希望日は重複しないよう入力してください`);
      }
      if (error.length != 0) {
        dialog.openErrorDialog({ error });
      }
      return error.length == 0;
    }
  }
