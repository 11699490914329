import * as util from '@/util';
import * as vali from '@/validations/validationUtil';

//validationは、OKならTrue, それ以外なら警告文を配列に入れる。
export function validationNotification(self, args) {
  var error = [];

  console.log(util.datetime2unix(args.notification.datetime) + (3600 * 24 * 60 * 1000));
  console.log(util.datetime2unix(args.notification.end_datetime));


  error.push(args.notification.title !== null || 'タイトルが入力されていません');
  error.push((args.notification.is_login == true || args.notification.is_app == true || args.notification.is_mail == true) || '通知方法を1つ以上選択してください');
  error.push(args.notification.datetime !== null || '通知予定日時が正しく入力されていません');
  error.push(args.notification.end_datetime !== null || '通知終了日時が正しく入力されていません');
  error.push(args.notification.datetime === null || args.notification.end_datetime === null || util.datetime2unix(args.notification.datetime) + (3600 * 24 * 60 * 1000) >= util.datetime2unix(args.notification.end_datetime) || '通知終了日時は通知予定日時の60日先以内を設定してください');
  error.push(args.notification.content !== null || '内容が入力されていません');
  console.log(args.notification.employee_ids)
  error.push(args.notification.employee_ids.length !== 0 || '社員を1人以上選択してください');
  
  return error;
}