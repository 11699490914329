import Shift from "@/typesold/shift";
import * as util from "@/util";
import timediff from "@/entities/Timediff";

//validationは、OKならTrue, それ以外なら警告文を配列に入れる。
export function validationShifts(
  shifts: { [date: string]: Shift },
  coretime_type: number,
  coretime_started_at?: number,
  coretime_ended_at?: number,
) {
  var error = [];
  var soft_error = [];
  Object.keys(shifts).forEach((date) => {
    const shift = shifts[date];
    const daystr = util.date2monthdaystr(date);
    var is_shift = false;
    for (var i = 0; i < shift.shift_shift.length; i++) {
      is_shift =
        is_shift ||
        shift.shift_shift[i].started_at !== null ||
        shift.shift_shift[i].ended_at !== null;
      if (!validate_time_start(shift.shift_shift[i]))
        error.push(daystr + "の" + (i + 1) + "行目のシフトの開始時刻が入力されていません");
      if (!validate_time_end(shift.shift_shift[i]))
        error.push(daystr + "の" + (i + 1) + "行目のシフトの終了時刻が入力されていません");
      if (!validate_time_order(shift.shift_shift[i]))
        error.push(daystr + "の" + (i + 1) + "行目のシフトの開始時刻が終了時刻以後となっています");
      error = error.concat(
        validate_time_duplicate(shift.shift_shift, i).map(
          (j) =>
            daystr + "の" + (i + 1) + "行目のシフトが" + (j + 1) + "行目のシフトと重複しています",
        ),
      );
      if (!validate_time_start_within_today(shift.shift_shift[i]))
        error.push(daystr + "の" + (i + 1) + "行目のシフトの開始時刻は当日である必要があります");
      if (!validate_time_within_24(shift.shift_shift[i]))
        error.push(daystr + "の" + (i + 1) + "行目のシフト時間が24時間を超えています");
    }

    if (
      is_shift &&
      coretime_type == 1 &&
      coretime_started_at !== null &&
      coretime_ended_at !== null
    ) {
      soft_error.push(
        timediff(
          [{ started_at: coretime_started_at, ended_at: coretime_ended_at }],
          shift.shift_shift,
        ).length == 0 || daystr + "のシフトがコアタイムを含んでいません",
      );
    }

    for (var i = 0; i < shift.shift_break.length; i++) {
      if (!validate_time_start(shift.shift_break[i]))
        error.push(daystr + "の" + (i + 1) + "行目の休憩が終了時刻のみ入力されています");
      if (!validate_time_order(shift.shift_break[i]))
        error.push(daystr + "の" + (i + 1) + "行目の休憩の開始時刻が終了時刻以後となっています。");
      error = error.concat(
        validate_time_duplicate(shift.shift_break, i).map(
          (j) =>
            daystr + "の" + (i + 1) + "行目の休憩が" + (j + 1) + "行目の休憩と重複しています。",
        ),
      );
    }
  });

  return { error, soft_error };
}

export function validationShift(shift: Shift) {
  var error = [];
  error.push(shift.employee_id != null || "社員を選択してください");
  error.push(
    shift.shift_shift[0].started_at != null ||
      shift.shift_punch[0].started_at != null ||
      shift.shift_break[0].started_at != null ||
      "少なくとも一種類を入力してください。",
  );

  for (var i = 0; i < shift.shift_shift.length; i++) {
    if (!validate_time_start(shift.shift_shift[i]))
      error.push(i + 1 + "行目のシフトの開始時刻が入力されていません");
    if (!validate_time_end(shift.shift_shift[i]))
      error.push(i + 1 + "行目のシフトの終了時刻が入力されていません");
    if (!validate_time_order(shift.shift_shift[i]))
      error.push(i + 1 + "行目のシフトの開始時刻が終了時刻以後となっています");
    error = error.concat(
      validate_time_duplicate(shift.shift_shift, i).map(
        (j) => i + 1 + "行目のシフトが" + (j + 1) + "行目のシフトと重複しています",
      ),
    );
    if (!validate_time_start_within_today(shift.shift_shift[i]))
      error.push(i + 1 + "行目のシフトの開始時刻は当日である必要があります");
    if (!validate_time_within_24(shift.shift_shift[i]))
      error.push(i + 1 + "行目のシフト時間が24時間を超えています");
  }

  for (var i = 0; i < shift.shift_punch.length; i++) {
    if (!validate_time_start(shift.shift_punch[i]))
      error.push(i + 1 + "行目の勤怠が退勤時刻のみ入力されています");
    if (!validate_time_order(shift.shift_punch[i]))
      error.push(i + 1 + "行目の勤怠の出勤時刻が退勤時刻以後となっています");
    error = error.concat(
      validate_time_duplicate(shift.shift_punch, i).map(
        (j) => i + 1 + "行目の勤怠が" + (j + 1) + "行目の勤怠と重複しています。",
      ),
    );
    if (!validate_time_start_before_24(shift.shift_punch[i]))
      error.push(i + 1 + "行目の出勤時刻は24時より前である必要があります");
    if (!validate_time_end_after_0(shift.shift_punch[i]))
      error.push(i + 1 + "行目の退勤時刻は0時より後である必要があります");
    if (!validate_time_within_24(shift.shift_punch[i]))
      error.push(i + 1 + "行目の勤怠時間が24時間を超えています");
  }

  for (var i = 0; i < shift.shift_break.length; i++) {
    if (!validate_time_start(shift.shift_break[i]))
      error.push(i + 1 + "行目の休憩が終了時刻のみ入力されています");
    if (!validate_time_order(shift.shift_break[i]))
      error.push(i + 1 + "行目の休憩の開始時刻が終了時刻以後となっています。");
    error = error.concat(
      validate_time_duplicate(shift.shift_break, i).map(
        (j) => i + 1 + "行目の休憩が" + (j + 1) + "行目の休憩と重複しています。",
      ),
    );
  }

  return error;
}

export function validationDeleteShift(started_on?: string, ended_on?: string) {
  var error = [];
  error.push(started_on != null || "期間開始日を正しく入力してください");
  error.push(ended_on != null || "期間終了日を正しく入力してください");
  error.push(
    started_on == null ||
      ended_on == null ||
      util.date2obj(started_on).getTime() <= util.date2obj(ended_on).getTime() ||
      "期間終了日は期間開始日以後を入力してください",
  );
  return error;
}

function validate_time_start(time): boolean {
  return time.started_at !== null || time.ended_at === null;
}
function validate_time_end(time): boolean {
  return time.started_at === null || time.ended_at !== null;
}
function validate_time_order(time): boolean {
  return time.started_at === null || time.ended_at === null || time.started_at < time.ended_at;
}
function validate_time_duplicate(times, i): number[] {
  var error_j = [];
  for (var j = i + 1; j < times.length; j++) {
    if (times[i].started_at !== null && times[j].started_at !== null) {
      if (
        times[i].started_at !== null &&
        times[j].started_at !== null &&
        times[j].ended_at !== null &&
        times[j].started_at <= times[i].started_at &&
        times[i].started_at < times[j].ended_at
      )
        error_j.push(j);
      else if (
        times[i].ended_at !== null &&
        times[j].started_at !== null &&
        times[j].ended_at !== null &&
        times[j].started_at < times[i].ended_at &&
        times[i].ended_at <= times[j].ended_at
      )
        error_j.push(j);
      else if (
        times[j].started_at !== null &&
        times[i].started_at !== null &&
        times[i].ended_at !== null &&
        times[i].started_at <= times[j].started_at &&
        times[j].started_at < times[i].ended_at
      )
        error_j.push(j);
      else if (
        times[j].ended_at !== null &&
        times[i].started_at !== null &&
        times[i].ended_at !== null &&
        times[i].started_at < times[j].ended_at &&
        times[j].ended_at <= times[i].ended_at
      )
        error_j.push(j);
      else if (times[i].started_at == times[j].started_at) error_j.push(j);
    }
  }
  return error_j;
}
function validate_time_start_within_today(time): boolean {
  return (
    time.started_at === null ||
    time.ended_at === null ||
    (time.started_at >= 0 && time.started_at <= 1440)
  );
}
function validate_time_within_24(time): boolean {
  return (
    time.started_at === null || time.ended_at === null || time.ended_at - time.started_at <= 1440
  );
}
function validate_time_end_after_0(time): boolean {
  return time.ended_at === null || time.ended_at > 0;
}
function validate_time_start_before_24(time): boolean {
  return time.started_at === null || time.started_at < 1440;
}
