import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import Vue from "vue";
import { Spouce, Dependant } from "@/typesold/family";
import { Each_employee_info } from "@/typesold/employee_info";

export type GlobalData = {
  employee_base: any;
  employee_payment: any;
  branches: any;
  branch_hash: any;
  branch_hash_all: any;
  employee_infos: any;
  spouce?: Spouce;
  dependants: any;
  each_employee_infos: { [employee_info_id: number]: Each_employee_info[] };
  is_bank2: 0;
  is_income_tax_opt: number;
  is_nickname: 0;
  is_branch_hourly_wage: 0;
  is_employee_config: 0;
};

@Module({ dynamic: true, store, name: "change_global", namespaced: true })
class Global extends VuexModule {
  data: GlobalData | null = null;
  is_valid: boolean = false;
  is_set: boolean = false;
  fetching: Promise<void> = null;

  @Mutation
  public disable({}) {
    this.is_valid = false;
  }
  @Mutation
  public disable_all() {
    this.is_valid = false;
  }
  @Mutation
  public setFetching({ value }: { value: Promise<void> }) {
    this.fetching = value;
  }
  @Mutation
  public set(data: GlobalData) {
    this.data = {
      ...data,
      branch_hash: util.createHashReal(data.branches, "id", "name"),
      branch_hash_all: util.createHash(data.branches, "id", "name"),
    };
    this.is_valid = true;
    this.is_set = true;
    this.fetching = null;
  }

  // actions
  @Action({ rawError: true })
  public async fetch() {
    if (this.fetching) {
      await this.fetching;
    } else {
      const promise = util.post("demand_change/read_global", {}).then((res) => {
        this.set(res.data);
      });
      this.setFetching({ value: promise });
      await promise;
    }
  }
}

export default getModule(Global);
