
import { Component, Vue, Emit, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import $ from "jquery";
import CommonSelect from "@/components/common/Select.vue";
import CommonText from "@/components/common/Text.vue";

@Component({
  components: {
    CommonSelect,
    CommonText,
  }
})
export default class Pager extends Vue {
  @Prop() value: string | null;
  @Prop() error_cond: [{func, msg}];
  @Prop({default: '年'}) year_label;
  @Prop({default: '月'}) month_label;
  @Prop({default: '日'}) day_label;
  @Prop({default: '15px'}) year_label_width: string;
  @Prop({default: '15px'}) month_label_width: string;
  @Prop({default: '15px'}) day_label_width: string;
  @Prop({default: '231px'}) max_width: string;

  @Prop() max_year: number;
  @Prop({default: 1930}) min_year: number;
  @Prop({default: 0}) plus_max_year: number;
  @Prop() minus_min_year: number;
  @Prop() default_year: number;
  @Prop({default: false}) is_nendo: boolean;
  @Prop() year_array?: number[];

  once_input: boolean = false;//lazy

  year: number | string = '';
  month: number | string = '';
  day: number | string = '';

  no_watch: boolean = false;

  created() {
    this.set_date(this.value);
  }

  get is_nendo_year() {
    return this.$i18n.locale == 'ja' && this.is_nendo; 
  }

  get options() {
    let options = {};

    if (this.year_array) {
      this.year_array.forEach(year => {
        if (this.is_nendo_year) {
          options[year] = util.year2nengo(year);
        } else {
          options[year] = year;
        }
      });
    } else {
      const date = new Date();
      var max_year = this.max_year;
      if (max_year === undefined) {
        const date = new Date();
        max_year = Number(util.formatDate(date, 'yyyy'));
        max_year += this.plus_max_year;
      }

      var min_year = this.min_year;
      if (this.minus_min_year !== undefined) {
        const date = new Date();
        min_year = Number(util.formatDate(date, 'yyyy'));
        min_year -= this.minus_min_year;
      }

      for(var i = max_year; i >= min_year; i--) {
        if (this.is_nendo_year) {
          options[i] = util.year2nengo(i);
        } else {
          options[i] = i;
        }
      }
    }
    return options;
  }

  @Watch('value')
  on_change_value(value) {
    if (!this.no_watch)
      this.set_date(value);
  }

  set_date(value) {
    const date = new Date();

    if (value === null) {
      this.year = null
      this.month = null
      this.day = null
    } else {
      var split_date = value.split('-');
      if (split_date.length == 3) {
        this.year = split_date[0];
        this.month = split_date[1];
        this.day = split_date[2];
      }
    }
    

    if (this.year === null) {
      if (this.default_year) {
        this.year = this.default_year;
      } else {
        this.year = Number(util.formatDate(date, 'yyyy'));
      }
    }
  }

  emit_date() {
    if (this.year != '' && this.year != null && this.month != '' && this.month != null && this.month != 0 && this.day != '' && this.day != null && this.day != 0) {
      let date = this.year+"-"+this.month+"-"+this.day;
      if (util.is_valid_date(date)) {
        if (this.value != date) {
          this.no_watch = true;
        }
        this.$emit('input', date);
      } else {
        if (this.value != null) {
          this.no_watch = true;
        }
        this.$emit('input', null);
      }
    } else {
      if (this.value != null) {
        this.no_watch = true;
      }
      this.$emit('input', null);
    }
  }

  get styles () {
    return {
      '--yearlabelwidth': this.year_label_width,
      '--monthlabelwidth': this.month_label_width,
      '--daylabelwidth': this.day_label_width,
      '--datemaxwidth': this.$i18n.locale == 'en' ? '175px' : this.max_width,
    }
  }

  input_year(e) {
    var val = e.target.value;

    if (val == 'null') {
      val = null;
    }
    
    this.year = val;
    this.emit_date();
  }
  input_month(e) {
    var val = e.target.value;

    if (val != '' && (val > 12 || val < 0) || val.length > 2) {
      e.target.value = this.month;
    } else {
      this.month = val;
    }

    if (val > 1 || val.length >= 2) {
      this.next_focus(e);
    }

    this.emit_date();
  }
  input_day(e) {
    var val = e.target.value;

    if (val != '' && (val > 31 || val < 0) || val.length > 2) {
      e.target.value = this.day;
    } else {
      this.day = val;
    }

    if (val > 4 || val.length >= 2) {
      this.next_focus(e);
    }

    this.emit_date();
  }

  prev_focus(e) {
    const index = $('.textbox_focus').index(e.target);
    const prev_element = <HTMLInputElement>$('.textbox_focus')[index - 1];
    if (prev_element) {
      if (prev_element.type == 'text' || prev_element.type == 'url' || prev_element.type == 'tel') {
        prev_element.selectionStart = 0;
        prev_element.selectionEnd = prev_element.value.length;
        prev_element.focus();
      }
    }
  }
  next_focus(e) {
    const index = $('.textbox_focus').index(e.target);
    const next_element = <HTMLInputElement>$('.textbox_focus')[index + 1];
    if (next_element) {
      if (next_element.type == 'text' || next_element.type == 'url' || next_element.type == 'tel') {
        next_element.selectionStart = 0;
        next_element.selectionEnd = next_element.value.length;
        next_element.focus();
      }
    }
  }
}
