import { Vue } from 'vue-property-decorator';
import * as util from "@/util";

// utilDescriptorは、tableDescriptorがある場合は不要　m()を定義するだけ
// const utilDescriptor = {
//   manager: vuex_manager.laborins_manager,
//   data: [],
// }


// const globalDescriptor = {
//   name: 'global',//mixinでthis.globalのようにアクセスできるようにするための名前
//   vuex: vuex_data.notification_global,//vuex_dataに記載しておくことで、永続化したvuex_dataをここで取得
  
//   is_single: true,//apiに送信するパラメータによる階層構造になっていない場合にtrue　その場合、data: {0: データ}になる　ex) true or undefined
//   indexers: ['laborins_id'],//is_singleがfalse or undefinedの場合、apiへパラメータとして送るもののkeyを配列で指定　fetchの結果に対してindexersの各要素で参照したものを送信　ex) ['laborins_id']
//   fetch(self) {//indexersに指定したものをここで注入　ex) indexersに['branch_id']があるなら、branch_id: this.viewing_branch など
//     return {};
//   },
//   dependencies: [],//他のdescriptorで指定したnameの配列　それらのデータが全てある時にのみapiを叩いて取得　すでにデータがセットされている場合は一時的にそれを返す　ex) ['global']
// };


//tableDescriptorの中でm()の定義も行ってくれる
// const tableDescriptor = {
//   manager: vuex_manager.update_stdincome_manager,//vuex_managerに記載しておくことで、永続化したmanagerをここで取得
//   data: {
//     source: 'yearly',//テーブルのデータソースを指定
//     key: 'update_stdincome_masters',//したの'data'にあたる、このテーブルのメインとなる、sourceのなかのkeyを指定　これによって、以下'data'というのは'yearly.update_stdincome_masters'を示す
//     sort_key: [//ソート機能のデータ指定　keysはデータの位置を示す(データ指定表記と命名)
//       {key: 'status', keys: ['data', 'is_valid']},//yearly.update_stdincome_masters.is_validによってsort
//       {key: 'code', keys: ['yearly', 'employee_hash', ['data', 'employee_id'], 'code']},//yearly.employee_hash[yearly.update_stdincome_masters.employee_id].codeによってsort
//       {key: 'social_ins_id', keys: ['yearly', 'employee_hash', ['data', 'employee_id'], 'social_ins_id']},//yearly.employee_hash[yearly.update_stdincome_masters.employee_id].social_ins_idによってsort
//       {key: 'name', keys: ['yearly', 'employee_hash', ['data', 'employee_id'], 'kana']},//yearly.employee_hash[yearly.update_stdincome_masters.employee_id].kanaによってsort
//       {key: 'hiring_type', keys: ['yearly', 'employee_hash', ['data', 'employee_id'], 'hiring_type']},//yearly.employee_hash[yearly.update_stdincome_masters.employee_id].hiring_typeによってsort
//       {key: 'branch_id', keys: ['yearly', 'employee_hash', ['data', 'employee_id'], 'branch_id']},//yearly.employee_hash[yearly.update_stdincome_masters.employee_id].branch_idによってsort
//       {key: 'is_reflected', keys: ['data', 'is_reflected']},//yearly.update_stdincome_masters.is_reflectedによってsort
//     ], 
//     default_sort_key: [//sortごとのデフォルトソート このテーブルが表示された瞬間のデフォルトという意味
//       {key: 'is_valid', keys: ['data', 'is_valid'], sort_mode: 'desc'},//yearly.update_stdincome_masters.is_validはdescでデフォルト表示
//       {key: 'is_candidate', keys: ['func', 'is_candidate', [['data', 'employee_id']]], sort_mode: 'asc'},//this.is_candidate(yearly.update_stdincome_masters.employee_id)はascでデフォルト表示
//       {key: 'social_ins_id', keys: ['yearly', 'employee_hash', ['data', 'employee_id'], 'social_ins_id'], sort_mode: 'asc'}//yearly.
//     ],
//     search_targets: [//検索でヒットする条件を下に
//       {type: 'normal', keys: ['yearly', 'employee_hash', ['data', 'employee_id'], 'code']},
//       {type: 'normal', keys: ['yearly', 'employee_hash', ['data', 'employee_id'], 'name']}, 
//       {type: 'kana', keys: ['yearly', 'employee_hash', ['data', 'employee_id'], 'kana']},
//     ],
//   },
// }
// keysの指定方法



// 0番目の要素にのみ指定できる特殊指定
// func: 直後に関数名を指定して、さらにその直後に引数の配列を指定　引数の配列には、「データ指定表記(名付けたやつ)」が使用可能 ex) ['func', 'is_set', [0]]なら、this.is_set(0)の結果を使用
// self: this[key]のように取得したいものを直後に書いていく
// data: そのdescriptorに指定したsource[key]のものを指す

// 1番目以降に指定できる特殊指定
// それ以外 : 前のものに対して参照していく


export function latestDescript(descriptor) {
  return descriptor.vuex.latest_data;
}

export function validateDescript(self, descriptor) {
  const validate = lookupData(self, descriptor.vuex.validate_data, descriptor.indexers, descriptor.vuex.is_single);
  return validate;
}

export function dataDescript(self, descriptor) {
  var no_data = false;
  if (descriptor.dependencies !== undefined) {
    descriptor.dependencies.forEach(dependancy => {
      const validate = self[dependancy];
      if (!validate || !validate?.is_set || !validate?.is_valid) {
        no_data = true;
      }
    });
  }
  const data = lookupData(self, descriptor.vuex.data, descriptor.indexers, descriptor.vuex.is_single);
  const validate_data = lookupData(self, descriptor.vuex.validate_data, descriptor.indexers, descriptor.vuex.is_single);

  if (no_data) {
    if (validate_data?.is_set) {
      return data;
    } else {
      null;
    }
  }

  let fetchParam;
  if (descriptor.fetch !== undefined) {
    fetchParam = descriptor.fetch(self);
  } else {
    fetchParam = {};
  }

  console.log(descriptor.vuex.is_latest, descriptor.vuex.latest_data.latest_id, validate_data?.is_valid, validate_data?.is_set);
  
  
  if (!fetchParam) return null;
  if (fetchParam && !(descriptor.vuex.is_latest && descriptor.vuex.latest_data.latest_id === 0) && (!validate_data?.is_valid || !validate_data?.is_set)) descriptor.vuex.fetch(fetchParam, validate_data);
  if (validate_data?.is_set) return data;
}

export function tableDescript(descriptor) {
  return Vue.extend({
    data() {
      return {
        [descriptor.data.key+'_disp_num_tmp']: 10
      }
    },
    created() {
      this[descriptor.data.key+'_disp_num_tmp'] = 10;
      var disp_num = util.getLocalStorage(descriptor.data.key+'_disp_num');
      if (disp_num == null) {
        util.setLocalStorage(descriptor.data.key+'_disp_num', this[descriptor.data.key+'_disp_num_tmp']);
      } else {
        this[descriptor.data.key+'_disp_num_tmp'] = Number(disp_num);
      }
    },
    computed: {
      m() {
        return descriptor.manager;
      },

      [descriptor.data.key+'_is_open']: {
        get() {
          if (descriptor.data) {
            var tmp_is_open = this.query?.[descriptor.data.key+'_is_open'];
            if (tmp_is_open === undefined || tmp_is_open == '') {
              this.query = {[descriptor.data.key+'_is_open']: 0};
              tmp_is_open = 0;
            }
            return tmp_is_open;
          } else {
            return null;
          }
        },
        set(new_is_open) {
          if (descriptor.data) {
            var old_is_open = this[descriptor.data.key+'_is_open'];
            if (new_is_open != old_is_open) {
              if (new_is_open == '' || new_is_open == null) {
                this.query = {[descriptor.data.key+'_is_open']: undefined};
              } else {
                this.query = {[descriptor.data.key+'_is_open']: new_is_open};
              }
            }
          }
        }
      },
      
      [descriptor.data.key+'_search_key']: {
        get() {
          if (descriptor.data) {
            var tmp_search_key = this.query?.[descriptor.data.key+'_search_key'];
            if (tmp_search_key === undefined || tmp_search_key == '') {
              this.query = {[descriptor.data.key+'_search_key']: undefined};
              tmp_search_key = null;
            }
            return tmp_search_key;
          } else {
            return null;
          }
        },
        set(new_search_key) {
          console.log('1');
          if (descriptor.data) {
            console.log('2');
            var old_search_key = this[descriptor.data.key+'_search_key'];
            if (new_search_key != old_search_key) {
              this[descriptor.data.key+'_page'] = 1;
              
              if (new_search_key == '' || new_search_key == null) {
                this.query = {[descriptor.data.key+'_search_key']: undefined};
              } else {
                this.query = {[descriptor.data.key+'_search_key']: new_search_key};
              }
            }
          }
        }
      },
      [descriptor.data.key+'_page']: {
        get() {
          if (descriptor.data) {
            var tmp_page = this.query?.[descriptor.data.key+'_page'];
            if (tmp_page === undefined || tmp_page == '') {
              this.query = {[descriptor.data.key+'_page']: 1};
              tmp_page = 1;
            }
            return tmp_page;
          } else {
            return null;
          }
        },
        set(new_page) {
          if (descriptor.data) {
            var old_page = this[descriptor.data.key+'_page'];
            if (new_page != old_page) {
              if (new_page == '' || new_page == null) {
                this.query = {[descriptor.data.key+'_page']: undefined};
              } else {
                this.query = {[descriptor.data.key+'_page']: new_page};
              }
            }
          }
        },
      },
      [descriptor.data.key+'_disp_num']: {
        get() {
          if (descriptor.data) {
            return this[descriptor.data.key+'_disp_num_tmp'];
          } else {
            return 10;
          }
        },
        set(new_disp_num) {
          if (descriptor.data) {
            var old_disp_num = this[descriptor.data.key+'_disp_num'];
            if (new_disp_num != old_disp_num) {
              this[descriptor.data.key+'_page'] = 1;
              util.setLocalStorage(descriptor.data.key+'_disp_num', new_disp_num);
              this[descriptor.data.key+'_disp_num_tmp'] = new_disp_num;
            }
          }
        }
      },
      [descriptor.data.key+'_sort_key']: {
        get() {
          if (descriptor.data) {
            var sort_key = [];
            var self = this;
            descriptor.data.sort_key.forEach(key => {
              sort_key[key.key] = self.query?.[descriptor.data.key+'_'+key.key+'_sort'];
            });
            return sort_key;
          } else {
            return null;
          }
        },
        set(new_sort_key) {
          if (descriptor.data) {
            var self = this;
            var old_sort_key = this[descriptor.data.key+'_sort_key'];
            descriptor.data.sort_key.forEach(key => {
              if (new_sort_key && old_sort_key && new_sort_key[key.key] != old_sort_key[key.key]) {
                if (new_sort_key[key.key] == '' || new_sort_key[key.key] == null) {
                  self.query = {[descriptor.data.key+'_'+key+'_sort']: undefined};
                } else {
                  self.query = {[descriptor.data.key+'_'+key+'_sort']: new_sort_key[key.key]};
                }
              }
            });
          }
        }
      },
      [descriptor.data.key+'_all_index']() {
        return this[descriptor.data.key+'_searched_data'].length;
      },
      [descriptor.data.key+'_start_index']() {
        return this[descriptor.data.key+'_disp_num'] * (this[descriptor.data.key+'_page'] - 1) + 1;
      },
      [descriptor.data.key+'_end_index']() {
        return Math.min(this[descriptor.data.key+'_all_index'], this[descriptor.data.key+'_disp_num'] * this[descriptor.data.key+'_page']);
      },
      [descriptor.data.key+'_max_page']() {
        return Math.ceil(this[descriptor.data.key+'_all_index'] / this[descriptor.data.key+'_disp_num']);
      },
      [descriptor.data.key+'_page_num_list']() {
        var start_page = Math.max(1, this[descriptor.data.key+'_page'] - 2);
        if (this[descriptor.data.key+'_page'] > this [descriptor.data.key+'_max_page'] - 2)
          start_page = Math.max(1, this [descriptor.data.key+'_max_page'] - 4)
        var end_page = Math.min(this [descriptor.data.key+'_max_page'], this[descriptor.data.key+'_page'] + 2);
        if (this[descriptor.data.key+'_page'] < 3)
          end_page = Math.min(this[descriptor.data.key+'_max_page'], 5);
        var page_num_list = [];
        for (var i = start_page; i <= end_page; i++) {
          page_num_list.push(i);
        }
        return page_num_list;
      },
      [descriptor.data.key+'_searched_data']() {
        var contents = util.deep_copy(this[descriptor.data.source][descriptor.data.key]);
        contents = condition_data(this, this.condition_func, contents);
        contents = search_data(this, descriptor.data.key, descriptor.data.search_targets, contents);
        contents = sort_data(this, descriptor.data.key, descriptor.data.sort_key, descriptor.data.default_sort_key, contents);
        return contents;
      },
      [descriptor.data.key+'_managed_data']() {
        var contents = util.deep_copy(this[descriptor.data.key+'_searched_data']);
        contents = page_data(this, descriptor.data.key, contents);
        return contents;
      },
      [descriptor.data.key+'_row_num']() {
        return this[descriptor.data.key+'_managed_data'] ? this[descriptor.data.key+'_managed_data'].length : 100;
      }
    },
    methods: {
      sort_class(page_key, sort_key) {
        return this[page_key+'_sort_key'][sort_key] ?? null;
      },
      sort_click(page_key, sort_key) {
        this[page_key+'_page'] = 1;
        var sort_mode = undefined;
        if (this[page_key+'_sort_key'][sort_key] === undefined) {
          sort_mode = 'asc';
        } else if (this[page_key+'_sort_key'][sort_key] == 'asc') {
          sort_mode = 'desc';
        } else if (this[page_key+'_sort_key'][sort_key] == 'desc') {
          sort_mode = undefined;
        }
        this.set_sort(page_key, sort_key, sort_mode);
      },
      set_sort(page_key, sort_key, sort_mode) {
        var sort = {};
        
        descriptor.data.sort_key.forEach(key => {
          sort[page_key+'_'+key.key+'_sort'] = undefined;
        });
        sort[page_key+'_'+sort_key+'_sort'] = sort_mode;


        this.query = sort;
      }
    }
  });
}

//laborinsで使っている 削除予定
export function makeMixin(descriptor) {
  return Vue.extend({
    computed: {
      [descriptor.name]() {
        if (descriptor.dependencies !== undefined) {
          var no_data = false;
          descriptor.dependencies.forEach(dependancy => {
            if (this[dependancy] === undefined) {
              no_data = true;
            }
          });
          if (no_data) return null;
        }
        const data = lookupData(this, descriptor.vuex.data, descriptor.indexers, descriptor.vuex.is_single);
        const validate_data = lookupData(this, descriptor.vuex.validate_data, descriptor.indexers, descriptor.vuex.is_single);
        const fetchParam = descriptor.fetch(this);
        if (!fetchParam) return null;
        if (fetchParam && (!descriptor.vuex.is_latest || descriptor.vuex.latest_data.latest_id === null) && (!validate_data?.is_valid || !validate_data?.is_set)) descriptor.vuex.fetch(fetchParam, validate_data);
        if (validate_data?.is_set) return data;
      },
      [descriptor.name+'_latest_data']() {
        return descriptor.vuex.latest_data;
      },
      m() {
        return descriptor.manager;
      },
    }
  });
}


//テーブルmixin
export function makeTableMixin(descriptor) {
  return Vue.extend({
    data() {
      return {
        [descriptor.data.key+'_disp_num_tmp']: 10
      }
    },
    created() {
      this[descriptor.data.key+'_disp_num_tmp'] = 10;
      var disp_num = util.getLocalStorage(descriptor.data.key+'_disp_num');
      if (disp_num == null) {
        util.setLocalStorage(descriptor.data.key+'_disp_num', this[descriptor.data.key+'_disp_num_tmp']);
      } else {
        this[descriptor.data.key+'_disp_num_tmp'] = Number(disp_num);
      }
    },
    computed: {
      m() {
        return descriptor.manager;
      },

      [descriptor.data.key+'_is_open']: {
        get() {
          if (descriptor.data) {
            var tmp_is_open = this.query?.[descriptor.data.key+'_is_open'];
            if (tmp_is_open === undefined || tmp_is_open == '') {
              this.query = {[descriptor.data.key+'_is_open']: 0};
              tmp_is_open = 0;
            }
            return tmp_is_open;
          } else {
            return null;
          }
        },
        set(new_is_open) {
          if (descriptor.data) {
            var old_is_open = this[descriptor.data.key+'_is_open'];
            if (new_is_open != old_is_open) {
              if (new_is_open == '' || new_is_open == null) {
                this.query = {[descriptor.data.key+'_is_open']: undefined};
              } else {
                this.query = {[descriptor.data.key+'_is_open']: new_is_open};
              }
            }
          }
        }
      },
      
      [descriptor.data.key+'_search_key']: {
        get() {
          if (descriptor.data) {
            var tmp_search_key = this.query?.[descriptor.data.key+'_search_key'];
            if (tmp_search_key === undefined || tmp_search_key == '') {
              this.query = {[descriptor.data.key+'_search_key']: undefined};
              tmp_search_key = null;
            }
            return tmp_search_key;
          } else {
            return null;
          }
        },
        set(new_search_key) {
          console.log('1');
          if (descriptor.data) {
            console.log('2');
            var old_search_key = this[descriptor.data.key+'_search_key'];
            if (new_search_key != old_search_key) {
              this[descriptor.data.key+'_page'] = 1;
              
              if (new_search_key == '' || new_search_key == null) {
                this.query = {[descriptor.data.key+'_search_key']: undefined};
              } else {
                this.query = {[descriptor.data.key+'_search_key']: new_search_key};
              }
            }
          }
        }
      },
      [descriptor.data.key+'_page']: {
        get() {
          if (descriptor.data) {
            var tmp_page = this.query?.[descriptor.data.key+'_page'];
            if (tmp_page === undefined || tmp_page == '') {
              this.query = {[descriptor.data.key+'_page']: 1};
              tmp_page = 1;
            }
            return tmp_page;
          } else {
            return null;
          }
        },
        set(new_page) {
          if (descriptor.data) {
            var old_page = this[descriptor.data.key+'_page'];
            if (new_page != old_page) {
              if (new_page == '' || new_page == null) {
                this.query = {[descriptor.data.key+'_page']: undefined};
              } else {
                this.query = {[descriptor.data.key+'_page']: new_page};
              }
            }
          }
        },
      },
      [descriptor.data.key+'_disp_num']: {
        get() {
          if (descriptor.data) {
            return this[descriptor.data.key+'_disp_num_tmp'];
          } else {
            return 10;
          }
        },
        set(new_disp_num) {
          if (descriptor.data) {
            var old_disp_num = this[descriptor.data.key+'_disp_num'];
            if (new_disp_num != old_disp_num) {
              this[descriptor.data.key+'_page'] = 1;
              util.setLocalStorage(descriptor.data.key+'_disp_num', new_disp_num);
              this[descriptor.data.key+'_disp_num_tmp'] = new_disp_num;
            }
          }
        }
      },
      [descriptor.data.key+'_sort_key']: {
        get() {
          if (descriptor.data) {
            var sort_key = [];
            var self = this;
            descriptor.data.sort_key.forEach(key => {
              sort_key[key.key] = self.query?.[descriptor.data.key+'_'+key.key+'_sort'];
            });
            return sort_key;
          } else {
            return null;
          }
        },
        set(new_sort_key) {
          if (descriptor.data) {
            var self = this;
            var old_sort_key = this[descriptor.data.key+'_sort_key'];
            descriptor.data.sort_key.forEach(key => {
              if (new_sort_key && old_sort_key && new_sort_key[key.key] != old_sort_key[key.key]) {
                if (new_sort_key[key.key] == '' || new_sort_key[key.key] == null) {
                  self.query = {[descriptor.data.key+'_'+key+'_sort']: undefined};
                } else {
                  self.query = {[descriptor.data.key+'_'+key+'_sort']: new_sort_key[key.key]};
                }
              }
            });
          }
        }
      },
      [descriptor.data.key+'_all_index']() {
        return this[descriptor.data.key+'_searched_data'].length;
      },
      [descriptor.data.key+'_start_index']() {
        return this[descriptor.data.key+'_disp_num'] * (this[descriptor.data.key+'_page'] - 1) + 1;
      },
      [descriptor.data.key+'_end_index']() {
        return Math.min(this[descriptor.data.key+'_all_index'], this[descriptor.data.key+'_disp_num'] * this[descriptor.data.key+'_page']);
      },
      [descriptor.data.key+'_max_page']() {
        return Math.ceil(this[descriptor.data.key+'_all_index'] / this[descriptor.data.key+'_disp_num']);
      },
      [descriptor.data.key+'_page_num_list']() {
        var start_page = Math.max(1, this[descriptor.data.key+'_page'] - 2);
        if (this[descriptor.data.key+'_page'] > this [descriptor.data.key+'_max_page'] - 2)
          start_page = Math.max(1, this [descriptor.data.key+'_max_page'] - 4)
        var end_page = Math.min(this [descriptor.data.key+'_max_page'], this[descriptor.data.key+'_page'] + 2);
        if (this[descriptor.data.key+'_page'] < 3)
          end_page = Math.min(this[descriptor.data.key+'_max_page'], 5);
        var page_num_list = [];
        for (var i = start_page; i <= end_page; i++) {
          page_num_list.push(i);
        }
        return page_num_list;
      },
      [descriptor.data.key+'_searched_data']() {
        var contents = util.deep_copy(this[descriptor.data.source][descriptor.data.key]);
        contents = condition_data(this, this.condition_func, contents);
        contents = search_data(this, descriptor.data.key, descriptor.data.search_targets, contents);
        contents = sort_data(this, descriptor.data.key, descriptor.data.sort_key, descriptor.data.default_sort_key, contents);
        return contents;
      },
      [descriptor.data.key+'_managed_data']() {
        var contents = util.deep_copy(this[descriptor.data.key+'_searched_data']);
        contents = page_data(this, descriptor.data.key, contents);
        return contents;
      },
      [descriptor.data.key+'_row_num']() {
        return this[descriptor.data.key+'_managed_data'] ? this[descriptor.data.key+'_managed_data'].length : 100;
      }
    },
    methods: {
      sort_class(page_key, sort_key) {
        return this[page_key+'_sort_key'][sort_key] ?? null;
      },
      sort_click(page_key, sort_key) {
        this[page_key+'_page'] = 1;
        var sort_mode = undefined;
        if (this[page_key+'_sort_key'][sort_key] === undefined) {
          sort_mode = 'asc';
        } else if (this[page_key+'_sort_key'][sort_key] == 'asc') {
          sort_mode = 'desc';
        } else if (this[page_key+'_sort_key'][sort_key] == 'desc') {
          sort_mode = undefined;
        }
        this.set_sort(page_key, sort_key, sort_mode);
      },
      set_sort(page_key, sort_key, sort_mode) {
        var sort = {};
        
        descriptor.data.sort_key.forEach(key => {
          sort[page_key+'_'+key.key+'_sort'] = undefined;
        });
        sort[page_key+'_'+sort_key+'_sort'] = sort_mode;


        this.query = sort;
      }
    }
  });
}


//消去予定
export function makeUtilMixin(descriptor) {
  return Vue.extend({
    computed: {
      m() {
        return descriptor.manager;
      },
    },
    
  });
}


//以下使用中
function lookupData(self, obj, indexers, is_single) {
  let top = obj;
  if (is_single) {
    top = top ? top[0] : undefined;
  } else {
    for (const indexer of indexers) {
      if (!top) return undefined;
      top = top[self[indexer]];
    }
  }
  return top;
}

function condition_data(self, condition_func, contents) {
  if (condition_func) {
    return contents.filter(content => condition_func(content));
  } else {
    return contents;
  }
}

function search_data(self, key, search_targets, contents) {
  var search_key = self[key+'_search_key'];
  if (search_key === null) {
    return contents;
  } else {
    var search_keys = search_key.split(/\s+/).filter(str => str != '');
    return contents.filter(content => {
      var flag = 0;
      search_keys.forEach(key => {
        var targets = fetchTargets(self, content, search_targets);
        targets.forEach(target => {
          if (target.type == 'kana') {
            if (util.search_kana(target.value, key)) {
              flag = 1;
            }
          } else if (target.type == 'normal') {
            if (util.isset(target.value)) {
              if (target.value.toLowerCase().indexOf(key.toLowerCase()) != -1) {
                flag = 1;
              }
            }
          }
        });
      });
      return flag;
    });
  }
}

function fetchTargets(self, content, search_targets) {
  var targets = [];
  search_targets.forEach(target => {
    targets.push({type: target.type, value: fetchTarget(self, content, target.keys)});
  });
  return targets;
}
function fetchTarget(self, content, search_targets) {
  if (search_targets[0] == 'func') {
    return fetchTargetKeyFunc(self, content, search_targets[1], search_targets[2]);
  } else if (search_targets[0] == 'self') {
    return fetchTargetKey(self, content, self, search_targets.slice(1));
  } else if (search_targets[0] == 'data') {
    return fetchTargetKey(self, content, content, search_targets.slice(1));
  } else {
    return fetchTargetKey(self, content, self[search_targets[0]], search_targets.slice(1));
  }
}
function fetchTargetKey(self, content, parent, search_targets) {
  if (Array.isArray(search_targets[0])) {
    var key = fetchTarget(self, content, search_targets[0]);
  } else {
    var key = search_targets[0];
  }
  if (search_targets.length == 1) {
    if (parent) {
      return parent[key];
    } else {
      return null;
    }
  } else {
    return fetchTargetKey(self, content, parent[key], search_targets.slice(1));
  }
}
function fetchTargetKeyFunc(self, content, func_name, search_targets) {
  var args = [];
  search_targets.forEach(search_target => {
    args.push(fetchTarget(self, content, search_target));
  });
  return self[func_name](...args);
}

function sort_data(self, key, sort_key, default_sort_key, contents) {
  var is_sorted = false;
  if (sort_key) { 
    for (var i = 0; i < sort_key.length; i++) {
      if (self[key+'_sort_key'][sort_key[i].key]) {
        contents.sort((x, y) => util.multi_sort_idx([{x: fetchTarget(self, x, sort_key[i].keys), y: fetchTarget(self, y, sort_key[i].keys), mode: self[key+'_sort_key'][sort_key[i].key]}]));
        is_sorted = true;
        break;
      }
    }
  }
  
  if (!is_sorted && default_sort_key) {
    contents.sort((x, y) => {
      var sort_datas = [];
      default_sort_key.forEach(sort_key => {
        if (sort_key.func) {
          sort_datas.push({x: sort_key.func(self, fetchTarget(self, x, sort_key.keys)), y: sort_key.func(self, fetchTarget(self, y, sort_key.keys)), mode: sort_key.sort_mode})
        } else {
          sort_datas.push({x: fetchTarget(self, x, sort_key.keys), y: fetchTarget(self, y, sort_key.keys), mode: sort_key.sort_mode})
        }
      });
      return util.multi_sort_idx(sort_datas);
    });
  }
  return contents;
}

function page_data(self, key, contents) {
  return contents.slice(self[key+'_disp_num'] * (self[key+'_page'] - 1), self[key+'_disp_num'] * self[key+'_page']);
}