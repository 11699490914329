
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import mixinNencho from '@/mixins/mixinNencho';

@Component({
  components: {
  }
})
export default class Nenchosave extends Mixins(mixinNencho) {

  get is_nencho() {
    return this.isNowPage('nencho_employee') || this.isNowPage('nencho_insurance') || this.isNowPage('nencho_loan') || this.isNowPage('nencho_prev_payment');
  }

  get is_nencho_data() {
    if (!this.global || !this.employee_yearly || this.nencho_master_employee.reflected_to !== null) {
      return false;
    } else {
      if (this.isNowPage('nencho_insurance')) {
        return this.step >= 1 && this.insurance_yearly && this.nencho_master_employee.is_finish_insurance != 1;
      } else if (this.isNowPage('nencho_loan')) {
        return this.step >= 1 && this.loan_yearly && this.nencho_master_employee.is_finish_loan != 1;
      } else if (this.isNowPage('nencho_prev_payment')) {
        return this.step >= 1 && this.prev_payment_yearly && this.nencho_master_employee.is_finish_prev_payment != 1;
      } else if (this.isNowPage('nencho_employee')) {
        return !this.newm.state.step || this.newm.state.step >= 1 && this.nencho_master_employee.is_finish_employee != 1;
      } else {
        return false;
      }
    }
  }

  save_nencho() {
    if (this.isNowPage('nencho_insurance')) {
      this.m.setSave({nencho_id: this.nencho_id, is_manager: this.is_manager, employee_id: this.employee_id, kind: 'insurance', content: {
        life_insurances: this.m.life_insurances,
        care_insurances: this.m.care_insurances,
        pension_insurances: this.m.pension_insurances,
        earthquake_insurances: this.m.earthquake_insurances,
        social_insurances: this.m.social_insurances,
        mutualaid_insurances: this.m.mutualaid_insurances,
        step: this.m.step,
      }});
    } else if (this.isNowPage('nencho_loan')) {
      this.m.setSave({nencho_id: this.nencho_id, is_manager: this.is_manager, employee_id: this.employee_id, kind: 'loan', content: {
        new_loans: this.m.new_loans,
        reform_loans: this.m.reform_loans,
        step: this.m.step,
      }});
    } else if (this.isNowPage('nencho_prev_payment')) {
      this.m.setSave({nencho_id: this.nencho_id, is_manager: this.is_manager, employee_id: this.employee_id, kind: 'prev_payment', content: {
        prev_payments: this.m.prev_payments,
        step: this.m.step,
      }});
    } else if (this.isNowPage('nencho_employee')) {
      this.m.setSave({nencho_id: this.nencho_id, is_manager: this.is_manager, employee_id: this.employee_id, kind: 'employee', content: {
        employee: this.newm.edit_manager.nencho_employee.object.employee,
        spouce: this.newm.edit_manager.nencho_employee.object.spouce,
        dependants: this.newm.edit_manager.nencho_employee.object.dependants,
        step: this.newm.state.step,
      }});
    }
  }

  get step() {
    return this.m.step;
  }
}
