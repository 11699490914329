import Vue from "vue";
import * as util from "@/util";
import { LatestControlData } from "@/typesold/VuexControl";

export default class VuexDataControl {
  data = Vue.observable({}); //データ本体
  validate_data = Vue.observable({}); //検証用のデータ
  latest_data = Vue.observable<LatestControlData>({
    //is_latestがtrueの場合にセットするデータ
    latest_id: null,
    latest_fetching: null,
  });

  //セットするデータ
  api_url: string; //apiから取得するためのurl　ex) 'renew_stdincome/read_global'
  indexes: string[] = []; //is_singleがfalse or undefinedの場合、dataとvalidation_dataのオブジェクトkeyの値に使用するkeyの名前　ex) ['renew_stdincome_id', 'branch_id']
  params: { [key: string]: string } = {}; //apiに送信するパラメータの配列{branch_id: 'viewing_branch'}なら、self.viewing_branchをbranch_idとして渡すことになる また、branch_idのidとしてself.viewing_branchを使用する
  dependencies: string[] = []; //このデータを取得する前に取得してある必要があるvuex_data(selfで参照できるもの)

  is_single: boolean = false; //apiに送信するパラメータによる階層構造になっていない場合にtrue　その場合、data: {0: データ}になる　ex) true or undefined
  is_latest: boolean = false; //確定的にapiに送るパラメータ(idなど)が決められないものならtrue　ex) true or undefined
  latest_key: string[] = []; //is_latestがtrueの場合、latest_key{key: vakue, ...}をセットすることで、取得したデータをたどってその値をlatest_idとする　ex) ['renew_stdincome', 'id']
  is_need_session: boolean = true; //認証が不要なapiの場合はfalseに

  //データの取得
  public async fetch(args, validate_data) {
    var fetching = this.getFetching(args);
    if (fetching) {
      await fetching;
    } else {
      if (!validate_data) this.setInitial(args);
      if (this.is_latest && this.latest_data.latest_id === 0) return;
      const promise = util.post(this.api_url, args, this.is_need_session).then((res) => {
        //is_latestの場合は、latestなものがないとしても、responseをkey = 0に格納する validate_dataも同様
        this.setResponseData(res.data, args);
        this.setFetching(args, null);
      });
      this.setFetching(args, promise);
      await promise;
    }
  }

  //データ無効化
  public disable(args: any) {
    this.disableValidateData(this.validate_data, this.indexes, args);
    this.latest_data.latest_id = null;
    this.latest_data.latest_fetching = null;
  }
  //全データ無効化
  public disable_all() {
    this.disableValidateData(this.validate_data, this.indexes, []);
    this.latest_data.latest_id = null;
    this.latest_data.latest_fetching = null;
  }

  //fetchで使用
  private getFetching(args) {
    if (
      this.is_latest &&
      args[this.latest_key[0]] === undefined &&
      this.latest_data.latest_fetching
    ) {
      return this.latest_data.latest_fetching;
    } else {
      return this.getFetchingEach(this.validate_data, this.indexes, args);
    }
  }
  private getFetchingEach(validate_data, indexes, args) {
    if (this.is_single) {
      if (validate_data && validate_data[0]) return validate_data[0].fetching;
    } else {
      var index = indexes[0];
      var index_value = args[index];
      if (indexes.length == 1) {
        if (validate_data && validate_data[index_value]) return validate_data[index_value].fetching;
      } else {
        return this.getFetchingEach(validate_data[index_value], indexes.slice(1), args);
      }
    }
  }
  private setInitial(args: any) {
    this.setInitialData(this.indexes, args, {});
    this.setInitialValidateData(this.indexes, args, {});
  }
  private setResponseData(response_data: any, args: any) {
    var set_data = {
      is_valid: true,
      is_set: true,
      fetching: null,
    };
    this.setData(this.indexes, args, response_data);
    this.setValidateData(this.indexes, args, response_data, set_data);

    if (this.is_latest) {
      var index = this.indexes[0];
      var index_value = this.discoverIndex(index, {}, response_data);
      if (this.is_latest && index_value === null) {
        index_value = 0;
      }
      this.latest_data.latest_fetching = null;
      this.latest_data.latest_id = index_value;
    }
  }
  private setFetching(args?: any, value?: Promise<void>) {
    if (this.validate_data === null) Vue.set(this, "validate_data", {});
    this.setFetchingEach(this.validate_data, this.indexes, args, value);
  }
  private setInitialData(indexes: string[], args: any, response_data: any) {
    if (this.data === null) Vue.set(this, "data", {});
    if (this.is_single) {
      Vue.set(this.data, 0, response_data);
    } else {
      for (var i = 0; i < indexes.length; i++) {
        if (!this.isSetInitialData(this.data, this.indexes.slice(0, i + 1), args, response_data)) {
          this.setData(this.indexes.slice(0, i + 1), args, {});
        }
      }
    }
  }
  private setInitialValidateData(indexes: string[], args: any, response_data: any) {
    var set_data = {
      is_valid: false,
      is_set: false,
      fetching: null,
    };
    if (this.validate_data === null) Vue.set(this, "validate_data", {});
    if (this.is_single) {
      Vue.set(this.validate_data, 0, set_data);
    } else {
      for (var i = 0; i < indexes.length; i++) {
        if (
          !this.isSetInitialData(
            this.validate_data,
            this.indexes.slice(0, i + 1),
            args,
            response_data,
          )
        ) {
          this.setValidateData(
            this.indexes.slice(0, i + 1),
            args,
            response_data,
            i == indexes.length - 1 ? set_data : {},
          );
        }
      }
    }
  }
  private discoverIndex(index: string, args: any, response_data: any) {
    var extracted_index: any = null;

    if (args === undefined) {
      return null;
    } else if (this.is_latest && args[index] === undefined) {
      extracted_index = response_data;
      this.latest_key.forEach((key) => {
        if (extracted_index) extracted_index = extracted_index[key];
      });
    } else {
      extracted_index = args[index];
    }

    return extracted_index;
  }
  private isSetInitialData(data: any, indexes: string[], args: any, response_data: any) {
    var index = indexes[0];
    var index_value = this.discoverIndex(index, args, response_data);
    if (index_value === null) return false;
    if (indexes.length == 1) {
      return data[index_value] !== undefined;
    } else {
      return this.isSetInitialData(data[index_value], indexes.slice(1), args, response_data);
    }
  }
  private setData(indexes: string[], args: any, response_data: any) {
    if (this.is_single) {
      Vue.set(this.data, 0, response_data);
    } else {
      if (indexes.length == 1) {
        var index = indexes[0];
        var index_value = this.discoverIndex(index, args, response_data);
        if (this.is_latest && index_value === null) {
          index_value = 0;
        }
        Vue.set(this.data, index_value, response_data);
      } else if (indexes.length == 2) {
        Vue.set(
          this.data[this.discoverIndex(indexes[0], args, response_data)],
          this.discoverIndex(indexes[1], args, response_data),
          response_data,
        );
      } else if (indexes.length == 3) {
        Vue.set(
          this.data[this.discoverIndex(indexes[0], args, response_data)][
            this.discoverIndex(indexes[1], args, response_data)
          ],
          this.discoverIndex(indexes[2], args, response_data),
          response_data,
        );
      } else if (indexes.length == 4) {
        Vue.set(
          this.data[this.discoverIndex(indexes[0], args, response_data)][
            this.discoverIndex(indexes[1], args, response_data)
          ][this.discoverIndex(indexes[2], args, response_data)],
          this.discoverIndex(indexes[3], args, response_data),
          response_data,
        );
      } else if (indexes.length == 5) {
        Vue.set(
          this.data[this.discoverIndex(indexes[0], args, response_data)][
            this.discoverIndex(indexes[1], args, response_data)
          ][this.discoverIndex(indexes[2], args, response_data)][
            this.discoverIndex(indexes[3], args, response_data)
          ],
          this.discoverIndex(indexes[4], args, response_data),
          response_data,
        );
      }
    }
  }
  private setValidateData(indexes: string[], args: any, response_data: any, set_data: any) {
    if (this.is_single) {
      Vue.set(this.validate_data, 0, set_data);
    } else {
      if (indexes.length == 1) {
        var index = indexes[0];
        var index_value = this.discoverIndex(index, args, response_data);
        if (this.is_latest && index_value === null) {
          index_value = 0;
        }
        Vue.set(this.validate_data, index_value, set_data);
      } else if (indexes.length == 2) {
        Vue.set(
          this.validate_data[this.discoverIndex(indexes[0], args, response_data)],
          this.discoverIndex(indexes[1], args, response_data),
          set_data,
        );
      } else if (indexes.length == 3) {
        Vue.set(
          this.validate_data[this.discoverIndex(indexes[0], args, response_data)][
            this.discoverIndex(indexes[1], args, response_data)
          ],
          this.discoverIndex(indexes[2], args, response_data),
          set_data,
        );
      } else if (indexes.length == 4) {
        Vue.set(
          this.validate_data[this.discoverIndex(indexes[0], args, response_data)][
            this.discoverIndex(indexes[1], args, response_data)
          ][this.discoverIndex(indexes[2], args, response_data)],
          this.discoverIndex(indexes[3], args, response_data),
          set_data,
        );
      } else if (indexes.length == 5) {
        Vue.set(
          this.validate_data[this.discoverIndex(indexes[0], args, response_data)][
            this.discoverIndex(indexes[1], args, response_data)
          ][this.discoverIndex(indexes[2], args, response_data)][
            this.discoverIndex(indexes[3], args, response_data)
          ],
          this.discoverIndex(indexes[4], args, response_data),
          set_data,
        );
      }
    }
  }

  private disableValidateData(validate_data, indexes, args) {
    Vue.set(this, "validate_data", this.disableValidateDataEach(validate_data, indexes, args));
  }

  private disableValidateDataEach(validate_data, indexes, args) {
    if (this.is_single) {
      if (validate_data[0]) validate_data[0].is_valid = false;
    } else {
      var index = indexes[0];
      var index_value = args[index];
      if (index_value === undefined) {
        validate_data = this.disableValidateDataMulti(validate_data, indexes, args);
      } else {
        if (indexes.length == 1) {
          if (validate_data[index_value]) validate_data[index_value].is_valid = false;
        } else {
          if (validate_data[index_value])
            validate_data[index_value] = this.disableValidateDataEach(
              validate_data[index],
              indexes.slice(1),
              args,
            );
        }
      }
    }
    return validate_data;
  }
  private disableValidateDataMulti(validate_data, indexes, args) {
    if (indexes.length == 1) {
      Object.keys(validate_data).forEach((key) => {
        validate_data[key].is_valid = false;
      });
    } else {
      Object.keys(validate_data).forEach((key) => {
        validate_data[key] = this.disableValidateDataEach(
          validate_data[key],
          indexes.slice(1),
          args,
        );
      });
    }
    console.log(validate_data);
    return validate_data;
  }
  private setFetchingEach(validate_data, indexes, args, value) {
    if (this.is_single) {
      if (validate_data[0]) validate_data[0].fetching = value;
    } else {
      var index = indexes[0];
      var index_value = args[index];

      if (this.is_latest && index_value === undefined) {
        this.latest_data.latest_fetching = value;
      } else {
        if (indexes.length == 1) {
          if (validate_data[index_value]) validate_data[index_value].fetching = value;
        } else {
          if (validate_data[index_value])
            validate_data[index_value] = this.setFetchingEach(
              validate_data[index_value],
              indexes.slice(1),
              args,
              value,
            );
        }
      }
    }

    return validate_data;
  }

  //フロントからデータを取得する際に使用
  public getLatest() {
    return this.latest_data;
  }
  public get(self) {
    var no_data = false;
    if (this.dependencies !== undefined) {
      this.dependencies.forEach((dependancy) => {
        const validate = self[dependancy];
        if (!validate || !validate?.is_set || !validate?.is_valid) {
          no_data = true;
        }
      });
    }
    const data = lookupData(self, this.data, this.indexes, this.is_single);
    const validate_data = lookupData(self, this.validate_data, this.indexes, this.is_single);

    if (no_data) {
      if (validate_data?.is_set) {
        return data;
      }
    }

    var fetchParam = {};
    Object.keys(this.params).forEach((key) => {
      fetchParam[key] = self[this.params[key]];
    });

    if (
      !(this.is_latest && this.latest_data.latest_id === 0) &&
      (!validate_data?.is_valid || !validate_data?.is_set)
    ) {
      this.fetch(fetchParam, validate_data);
    }
    if (validate_data?.is_set) {
      return data;
    }

    function lookupData(self, obj, indexers, is_single) {
      let top = obj;
      if (is_single) {
        top = top ? top[0] : undefined;
      } else {
        for (const indexer of indexers) {
          if (!top) return undefined;
          top = top[self[indexer]];
        }
      }
      return top;
    }
  }
}
