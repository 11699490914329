import { hasCustomizeTag } from "@/customize";
import { EmployeeBase, EmployeePayment, EmployeeAuth, EmployeeConfig } from "@/typesold/employee";
import * as util from "@/util";
import * as vali from "@/validations/validationUtil";
import global from "@/vuex/employee_each/global";
import Earning from "@/entities/Earning";

export function validationWizardEmployee(employee: any) {
  var error = [];

  //Base
  error.push(employee.name !== null || util.$t("error.enter_name"));
  error.push(
    employee.name === null ||
      vali.is_split_equal(employee.name, " ", 2) ||
      util.$t("error.enter_name"),
  );
  error.push(employee.kana !== null || util.$t("error.enter_kana"));
  error.push(
    employee.kana === null ||
      vali.is_split_equal(employee.kana, " ", 2) ||
      util.$t("error.enter_kana"),
  );
  error.push(employee.sex !== null || util.$t("error.select_sex"));
  error.push(employee.birth_date !== null || util.$t("error.enter_birth_date"));
  error.push(employee.mail !== null || util.$t("error.enter_mail"));
  error.push(
    employee.mail === null ||
      vali.is_no_blank(employee.mail) ||
      util.$t("error.contain_space_in_mail"),
  );

  error.push(employee.zip !== null || util.$t("error.enter_zip"));
  error.push(employee.zip === null || vali.is_zip(employee.zip) || util.$t("error.incorrect_zip"));
  error.push(employee.address !== null || util.$t("error.enter_address"));

  return error;
}
export function validationEmployeeStep1(employee: EmployeeBase, is_password = false) {
  var error = [];

  //Base
  error.push(employee.name !== null || util.$t("error.enter_name"));
  error.push(
    employee.name === null ||
      vali.is_split_equal(employee.name, " ", 2) ||
      util.$t("error.enter_name"),
  );
  error.push(employee.kana !== null || util.$t("error.enter_kana"));
  error.push(
    employee.kana === null ||
      vali.is_split_equal(employee.kana, " ", 2) ||
      util.$t("error.enter_kana"),
  );
  error.push(employee.sex !== null || util.$t("error.select_sex"));
  error.push(employee.birth_date !== null || util.$t("error.enter_birth_date"));
  error.push(
    employee.mynum === null ||
      vali.is_equal(employee.mynum, 12) ||
      util.$t("error.enter_mynum_in_12char"),
  );
  error.push(employee.mail !== null || util.$t("error.enter_mail"));
  error.push(
    employee.mail === null ||
      vali.is_no_blank(employee.mail) ||
      util.$t("error.contain_space_in_mail"),
  );

  if (is_password && !hasCustomizeTag("apu")) {
    error.push(employee.pass !== null || util.$t("error.enter_pass"));
    error.push(employee.pass_conf !== null || util.$t("error.enter_pass_confirm"));
    error.push(
      employee.pass === null ||
        employee.pass_conf === null ||
        employee.pass == employee.pass_conf ||
        util.$t("error.not_match_pass_confirm"),
    );
  }

  return error;
}

export function validationEmployeeStep1ForFixedTaxReduction(
  employee: EmployeeBase,
  is_password = false,
) {
  var error = [];

  //Base
  error.push(employee.name !== null || util.$t("error.enter_name"));
  error.push(
    employee.name === null ||
      vali.is_split_equal(employee.name, " ", 2) ||
      util.$t("error.enter_name"),
  );
  error.push(employee.kana !== null || util.$t("error.enter_kana"));
  error.push(
    employee.kana === null ||
      vali.is_split_equal(employee.kana, " ", 2) ||
      util.$t("error.enter_kana"),
  );
  error.push(employee.sex !== null || util.$t("error.select_sex"));
  error.push(employee.birth_date !== null || util.$t("error.enter_birth_date"));
  error.push(
    employee.mynum === null ||
      vali.is_equal(employee.mynum, 12) ||
      util.$t("error.enter_mynum_in_12char"),
  );

  if (is_password && !hasCustomizeTag("apu")) {
    error.push(employee.pass !== null || util.$t("error.enter_pass"));
    error.push(employee.pass_conf !== null || util.$t("error.enter_pass_confirm"));
    error.push(
      employee.pass === null ||
        employee.pass_conf === null ||
        employee.pass == employee.pass_conf ||
        util.$t("error.not_match_pass_confirm"),
    );
  }

  return error;
}

export function validationEmployeeStep2(employee: EmployeeBase) {
  var error = [];

  //Address
  error.push(employee.zip !== null || util.$t("error.enter_zip"));
  error.push(employee.zip === null || vali.is_zip(employee.zip) || util.$t("error.incorrect_zip"));
  error.push(employee.address !== null || util.$t("error.enter_address"));
  error.push(
    employee.tel === null ||
      vali.is_plus_number(employee.tel) ||
      util.$t("error.tel_halfwidth_number"),
  );
  error.push(
    employee.mobile === null ||
      vali.is_plus_number(employee.mobile) ||
      util.$t("error.mobile_halfwidth_number"),
  );

  return error;
}

export function validationEmployeeRoute(employee: EmployeePayment) {
  var error = [];

  error.push(employee.carfare_type !== null || util.$t("error.select_carfare_type_detail"));
  if (employee.carfare_type != 0) {
    error.push(
      employee.is_train == 1 || employee.is_car == 1 || util.$t("error.select_carfare_way"),
    );
    if (employee.is_car) {
      error.push(employee.km !== null || util.$t("error.enter_km"));
      error.push(
        employee.km === null ||
          vali.is_plus_number(employee.km) ||
          util.$t("error.km_halfwidth_number"),
      );
    }
  }

  return error;
}

export function validationEmployeeStep3(employee: EmployeeBase) {
  var error = [];

  //Bank
  if (employee.is_bank1 == 1) {
    if (employee.is_bank2 == 1) {
      error.push(
        employee.bank1_id !== null ||
          util.$t("error.enter_multi_employee_bank_name", {
            employee_bank: util.$t("employee_bank1"),
          }),
      );
      error.push(
        employee.branch1_id !== null ||
          util.$t("error.enter_multi_employee_branch_name", {
            employee_bank: util.$t("employee_bank1"),
          }),
      );
      error.push(
        employee.bank1_type !== null ||
          util.$t("error.enter_multi_employee_bank_type", {
            employee_bank: util.$t("employee_bank1"),
          }),
      );
      error.push(
        employee.bank1_num !== null ||
          util.$t("error.enter_multi_employee_bank_num", {
            employee_bank: util.$t("employee_bank1"),
          }),
      );
      error.push(
        employee.bank1_holder !== null ||
          util.$t("error.enter_multi_employee_bank_holder", {
            employee_bank: util.$t("employee_bank1"),
          }),
      );

      error.push(
        employee.bank1_num === null ||
          vali.is_equal(employee.bank1_num, 7) ||
          util.$t("error.enter_multi_employee_bank_num_in_7char", {
            employee_bank: util.$t("employee_bank1"),
          }),
      );
      error.push(
        employee.bank1_num === null ||
          vali.is_plus_number(employee.bank1_num) ||
          util.$t("error.enter_multi_employee_bank_halfwidth_number", {
            employee_bank: util.$t("employee_bank1"),
          }),
      );
      error.push(
        employee.bank1_holder === null ||
          !vali.is_kanji(employee.bank1_holder) ||
          util.$t("error.enter_multi_employee_bank_holder_without_kanji", {
            employee_bank: util.$t("employee_bank1"),
          }),
      );

      error.push(
        employee.bank2_id !== null ||
          util.$t("error.enter_multi_employee_bank_name", {
            employee_bank: util.$t("employee_bank2"),
          }),
      );
      error.push(
        employee.branch2_id !== null ||
          util.$t("error.enter_multi_employee_branch_name", {
            employee_bank: util.$t("employee_bank2"),
          }),
      );
      error.push(
        employee.bank2_type !== null ||
          util.$t("error.enter_multi_employee_bank_type", {
            employee_bank: util.$t("employee_bank2"),
          }),
      );
      error.push(
        employee.bank2_num !== null ||
          util.$t("error.enter_multi_employee_bank_num", {
            employee_bank: util.$t("employee_bank2"),
          }),
      );
      error.push(
        employee.bank2_holder !== null ||
          util.$t("error.enter_multi_employee_bank_holder", {
            employee_bank: util.$t("employee_bank2"),
          }),
      );

      error.push(
        employee.bank2_num === null ||
          vali.is_equal(employee.bank2_num, 7) ||
          util.$t("error.enter_multi_employee_bank_num_in_7char", {
            employee_bank: util.$t("employee_bank1"),
          }),
      );
      error.push(
        employee.bank2_num === null ||
          vali.is_plus_number(employee.bank2_num) ||
          util.$t("error.enter_multi_employee_bank_halfwidth_number", {
            employee_bank: util.$t("employee_bank1"),
          }),
      );
      error.push(
        employee.bank2_holder === null ||
          !vali.is_kanji(employee.bank2_holder) ||
          util.$t("error.enter_multi_employee_bank_holder_without_kanji", {
            employee_bank: util.$t("employee_bank1"),
          }),
      );
      error.push(employee.bank1_limit !== null || util.$t("error.enter_employee_bank1_limit"));
      error.push(
        employee.bank1_limit === null ||
          vali.is_plus_number(employee.bank1_limit) ||
          util.$t("error.enter_employee_bank1_limit_halfwidth_number"),
      );
    } else {
      error.push(employee.bank1_id !== null || util.$t("error.enter_employee_bank_name"));
      error.push(employee.branch1_id !== null || util.$t("error.enter_employee_branch_name"));
      error.push(employee.bank1_type !== null || util.$t("error.enter_employee_bank_type"));
      error.push(employee.bank1_num !== null || util.$t("error.enter_employee_bank_num"));
      error.push(employee.bank1_holder !== null || util.$t("error.enter_employee_bank_holder"));

      error.push(
        employee.bank1_num === null ||
          vali.is_equal(employee.bank1_num, 7) ||
          util.$t("error.enter_employee_bank_num_in_7char"),
      );
      error.push(
        employee.bank1_num === null ||
          vali.is_plus_number(employee.bank1_num) ||
          util.$t("error.enter_employee_bank_halfwidth_number"),
      );
      error.push(
        employee.bank1_holder === null ||
          !vali.is_kanji(employee.bank1_holder) ||
          util.$t("error.enter_employee_holder_without_kanji"),
      );
    }
  }

  return error;
}

export function validationEmployeeStep4(employee: EmployeeBase, is_nencho: boolean) {
  var error = [];

  //Self
  error.push(employee.tax_type !== null || util.$t("error.select_tax_type"));
  if (employee.tax_type == 0) {
    error.push(
      employee.householder_relationship_id !== null ||
        util.$t("error.select_householder_relationship"),
    );
    error.push(
      employee.householder_relationship_id == 1 ||
        employee.householder_name !== null ||
        util.$t("error.enter_householder_name"),
    );
    error.push(
      employee.householder_relationship_id != 6 ||
        employee.householder_relationship_custom !== null ||
        util.$t("error.enter_householder_relationship"),
    );
    error.push(employee.income !== null || util.$t("error.enter_income"));
    error.push(
      employee.income === null ||
        vali.is_plus_number(employee.income) ||
        util.$t("error.enter_income_halfwidth"),
    );
    error.push(employee.other_income !== null || util.$t("error.enter_other_income"));
    error.push(
      employee.other_income === null ||
        vali.is_plus_number(employee.other_income) ||
        util.$t("error.enter_other_income_halfwidth"),
    );
    error.push(employee.disability_type !== null || util.$t("error.select_disability_type"));
    error.push(employee.is_work_student !== null || util.$t("error.select_work_student"));

    if (employee.disability_type != 0) {
      error.push(employee.disability_reason !== null || util.$t("error.enter_sdisability_reason"));
    }
    if (employee.sex == 0) {
      error.push(
        (employee.widow_type !== null && (employee.widow_type == 0 || employee.widow_type == 4)) ||
          util.$t("error.select_single_child"),
      );
    } else {
      error.push(
        (employee.widow_type !== null &&
          (employee.widow_type == 0 || employee.widow_type == 1 || employee.widow_type == 4)) ||
          util.$t("error.select_single_child_widow"),
      );
    }
    if (employee.is_work_student != 0) {
      error.push(
        employee.work_student_reason !== null || util.$t("error.enter_work_student_reason"),
      );
    }
  }

  return error;
}

export function validationEmployeeStep4ForFixedTaxReduction(employee: EmployeeBase) {
  var error = [];

  //Self
  error.push(employee.tax_type !== null || util.$t("error.select_tax_type"));
  if (employee.tax_type != 0) return error;
  error.push(
    employee.is_resident !== null || util.$t("error.select_is_resident_fixed_tax_reduction"),
  );
  if (employee.is_resident != 1) return error;

  error.push(employee.income !== null || util.$t("error.enter_income"));
  error.push(
    employee.income === null ||
      vali.is_plus_number(employee.income) ||
      util.$t("error.enter_income_halfwidth"),
  );
  error.push(employee.other_income !== null || util.$t("error.enter_other_income"));
  error.push(
    employee.other_income === null ||
      vali.is_plus_number(employee.other_income) ||
      util.$t("error.enter_other_income_halfwidth"),
  );

  if (employee.income === null || employee.other_income === null) return error;

  const earning = new Earning();
  const employee_earning =
    earning.income2earning(Number(employee.income)) + Number(employee.other_income);
  if (employee_earning <= 480000) return error;

  error.push(
    employee.householder_relationship_id !== null ||
      util.$t("error.select_householder_relationship"),
  );
  error.push(
    employee.householder_relationship_id == 1 ||
      employee.householder_name !== null ||
      util.$t("error.enter_householder_name"),
  );
  error.push(
    employee.householder_relationship_id != 6 ||
      employee.householder_relationship_custom !== null ||
      util.$t("error.enter_householder_relationship"),
  );
  error.push(employee.disability_type !== null || util.$t("error.select_disability_type"));
  error.push(employee.is_work_student !== null || util.$t("error.select_work_student"));

  if (employee.disability_type != 0) {
    error.push(employee.disability_reason !== null || util.$t("error.enter_sdisability_reason"));
  }
  if (employee.sex == 0) {
    error.push(
      (employee.widow_type !== null && (employee.widow_type == 0 || employee.widow_type == 4)) ||
        util.$t("error.select_single_child"),
    );
  } else {
    error.push(
      (employee.widow_type !== null &&
        (employee.widow_type == 0 || employee.widow_type == 1 || employee.widow_type == 4)) ||
        util.$t("error.select_single_child_widow"),
    );
  }
  if (employee.is_work_student != 0) {
    error.push(employee.work_student_reason !== null || util.$t("error.enter_work_student_reason"));
  }

  return error;
}

//validationは、OKならTrue, それ以外なら警告文を配列に入れる。

export async function validationEmployeeMail(self, args) {
  var error = [];

  if (args.employee.mail !== null) {
    const res = await util.post("employee_each/confirm_mail", {
      mail: args.employee.mail,
      employee_id: args.employee_id,
    });
    error.push(res.data.validate || util.$t("error.invalid_mail"));
  }

  return error;
}
export function validationMail(self, args) {
  var error = [];

  error.push(args.employee.mail !== null || "新しいメールアドレスが入力されていません。");
  error.push(
    args.employee.mail === null ||
      vali.is_no_blank(args.employee.mail) ||
      util.$t("error.contain_space_in_mail"),
  );

  return error;
}

export function validationPassword(self, args) {
  var error = [];

  error.push(args.employee.pass !== null || "新しいパスワードが入力されていません。");
  error.push(args.employee.pass_conf !== null || "新しいパスワード(確認)が入力されていません。");
  error.push(
    args.employee.pass == args.employee.pass_conf || "新しいパスワードが確認と一致していません。",
  );

  return error;
}

export async function validationCurrentPassword(self, args) {
  var error = [];

  if (args.employee.pass_now !== null) {
    const res = await util.post("employee_each/confirm_password", {
      pass: args.employee.pass_now,
      employee_id: args.employee_id,
    });
    error.push(res.data.validate || "現在のパスワードが違います。");
  } else {
    error.push("現在のパスワードが入力されていません。");
  }

  return error;
}

export async function validationAdministrator(self, args) {
  var error = [];

  if (args.employee.mail !== null && args.employee.pass !== null) {
    const res = await util.post("employee_each/confirm_auth", {
      mail: args.employee.mail,
      pass: args.employee.pass,
      employee_id: args.employee_id,
    });
    error.push(res.data.validate || "管理者権限が確認できません。");
  } else {
    error.push(args.employee.mail !== null || "管理者のメールアドレスが入力されていません。");
    error.push(args.employee.pass !== null || "管理者のパスワードが入力されていません。");
  }

  return error;
}

export async function validationEmployeeCode(self, args) {
  var error = [];

  if (args.employee.code !== null) {
    const res = await util.post("employee_each/confirm_mail", {
      mail: args.employee.code,
      employee_id: args.employee_id,
    });
    error.push(res.data.validate || "使用できない社員コード(ID)が入力されています。");
  }

  return error;
}
export function validationEmployeeBase(self, args) {
  var error = [];

  //必須項目
  error = error.concat(validationEmployeeStep1(args.employee));
  error = error.concat(validationEmployeeStep2(args.employee));
  error = error.concat(validationEmployeeStep3(args.employee));
  error = error.concat(validationEmployeeStep4(args.employee, false));

  return error;
}

export function validationEmployeeBasePass(self, args) {
  var error = [];

  //必須項目
  error = error.concat(validationEmployeeStep1(args.employee, true));
  error = error.concat(validationEmployeeStep2(args.employee));
  error = error.concat(validationEmployeeStep3(args.employee));
  error = error.concat(validationEmployeeStep4(args.employee, false));

  return error;
}

export function validationEmployeePayment(self, args) {
  console.log(self.global);
  var error = [];

  //必須項目
  //Hiring
  error.push(args.employee.hiring_type !== null || "区分を選択してください");
  error.push(args.employee.branch_id !== null || "所属部署/店舗を選択してください");

  //別ページなのでバリデーション不可能だったため削除した項目 これらが入力されていなくても問題ない
  // if (args.employee.wage_type == 0 && args.employee.use_paid_hol_type == 1) {
  //   error.push(args.employee.week_prescribed_day !== null || '週所定労働日数を選択してください');
  //   error.push(args.employee.week_prescribed_hour !== null || '週所定労時間を入力してください');
  // }

  error.push(
    args.employee.week_prescribed_hour === null ||
      vali.is_plus_number(args.employee.week_prescribed_hour) ||
      "週所定労時間は半角数字で入力してください",
  );
  error.push(
    args.employee.month_prescribed_hour_type == 0 ||
      args.employee.month_prescribed_hour !== null ||
      "月所定労時間を入力してください",
  );
  error.push(
    args.employee.month_prescribed_hour_type == 0 ||
      args.employee.month_prescribed_hour === null ||
      vali.is_plus_number(args.employee.month_prescribed_hour) ||
      "月所定労時間は半角数字で入力してください",
  );
  error.push(
    args.employee.joined_on === null ||
      args.employee.retired_on === null ||
      util.date2unix(args.employee.joined_on) <= util.date2unix(args.employee.retired_on) ||
      "入社年月日が退社年月日より後になっています",
  );

  //Restax
  //なし

  //Payment
  error.push(args.employee.wage_type !== null || "基本給を選択してください");
  if (args.employee.wage_type == 0) {
    var moneyerror = 0;
    var timeerror = 0;
    var beforetimeerror = 0;
    var endtimeerror = 0;
    Object.keys(args.employee.hourly_wages).forEach((branch_id) => {
      for (var k = 0; k < args.employee.hourly_wages[branch_id].length; k++) {
        for (
          var j = 0;
          j < args.employee.hourly_wages[branch_id][k]["each_hourly_wage"].length;
          j++
        ) {
          if (
            args.employee.hourly_wages[branch_id][k].each_hourly_wage[j].money === null ||
            !vali.is_plus_number(args.employee.hourly_wages[branch_id][k].each_hourly_wage[j].money)
          ) {
            moneyerror = 1;
          }
          if (args.employee.hourly_wages[branch_id][k].each_hourly_wage[j].ended_at === null) {
            timeerror = 1;
          }
          if (
            j != 0 &&
            args.employee.hourly_wages[branch_id][k].each_hourly_wage[j - 1].ended_at !== null &&
            args.employee.hourly_wages[branch_id][k].each_hourly_wage[j].ended_at !== null &&
            args.employee.hourly_wages[branch_id][k].each_hourly_wage[j - 1].ended_at >=
              args.employee.hourly_wages[branch_id][k].each_hourly_wage[j].ended_at
          ) {
            console.log(
              args.employee.hourly_wages[branch_id][k].each_hourly_wage[j - 1].ended_at,
              args.employee.hourly_wages[branch_id][k].each_hourly_wage[j].ended_at,
            );
            beforetimeerror = 1;
          }
          if (
            args.employee.hourly_wages[branch_id][k].each_hourly_wage[j].ended_at !== null &&
            args.employee.hourly_wages[branch_id][k].each_hourly_wage[j].ended_at > 1440
          ) {
            endtimeerror = 1;
          }
        }
      }
    });
    error.push(moneyerror == 0 || "時給が正しく入力されていません");
    error.push(timeerror == 0 || "時給の終了時刻が正しく入力されていません");
    error.push(endtimeerror == 0 || "時給の終了時刻は24時より前の時刻を入力してください");
    error.push(beforetimeerror == 0 || "時給の時間帯が逆転しています");

    error.push(args.employee.training_hourly_wage_type !== null || "研修時給を選択してください");
    if (args.employee.training_hourly_wage_type != 0) {
      error.push(
        args.employee.training_hourly_wage_unit !== null || "研修時給の有効条件を入力してください",
      );
      error.push(
        args.employee.training_hourly_wage_unit === null ||
          vali.is_plus_number(args.employee.training_hourly_wage_unit) ||
          "研修時給の有効条件は半角数字で入力してください",
      );

      var moneyerror = 0;
      var timeerror = 0;
      var beforetimeerror = 0;
      var endtimeerror = 0;
      for (var k = 0; k < args.employee.training_hourly_wages.length; k++) {
        for (
          var j = 0;
          j < args.employee.training_hourly_wages[k]["each_training_hourly_wage"].length;
          j++
        ) {
          if (
            args.employee.training_hourly_wages[k].each_training_hourly_wage[j].money === null ||
            !vali.is_plus_number(
              args.employee.training_hourly_wages[k].each_training_hourly_wage[j].money,
            )
          ) {
            moneyerror = 1;
          }
          if (
            args.employee.training_hourly_wages[k].each_training_hourly_wage[j].ended_at === null
          ) {
            timeerror = 1;
          }
          if (
            j != 0 &&
            args.employee.training_hourly_wages[k].each_training_hourly_wage[j - 1].ended_at !==
              null &&
            args.employee.training_hourly_wages[k].each_training_hourly_wage[j].ended_at !== null &&
            args.employee.training_hourly_wages[k].each_training_hourly_wage[j - 1].ended_at >=
              args.employee.training_hourly_wages[k].each_training_hourly_wage[j].ended_at
          ) {
            beforetimeerror = 1;
          }
          if (
            args.employee.training_hourly_wages[k].each_training_hourly_wage[j].ended_at !== null &&
            args.employee.training_hourly_wages[k].each_training_hourly_wage[j].ended_at > 1440
          ) {
            endtimeerror = 1;
          }
        }
      }
      error.push(moneyerror == 0 || "研修時給が正しく入力されていません");
      error.push(timeerror == 0 || "研修時給の終了時刻が正しく入力されていません");
      error.push(endtimeerror == 0 || "研修時給の終了時刻は24時より前の時刻を入力してください");
      error.push(beforetimeerror == 0 || "研修時給の時間帯が逆転しています");
    }
  } else if (args.employee.wage_type == 1) {
    error.push(args.employee.monthly_wage !== null || "月給を入力してください");
    error.push(
      args.employee.monthly_wage === null ||
        vali.is_plus_number(args.employee.monthly_wage) ||
        "月給は半角数字で入力してください",
    );
  }

  if (args.employee.wage_type == 2) {
    error.push(args.employee.basepay_type !== null || "基礎単価を選択してください");
  }
  if (
    (args.employee.wage_type == 1 && args.employee.basepay_type == 1) ||
    args.employee.wage_type == 2
  ) {
    error.push(args.employee.basepay !== null || "基礎単価を入力してください");
    error.push(
      args.employee.basepay === null ||
        vali.is_plus_number(args.employee.basepay) ||
        "基礎単価は半角数字で入力してください",
    );
  }

  error.push(args.employee.work_type !== null || "勤務形態を選択してください");
  if (args.employee.work_type == 4) {
    //月単位フレックスタイム制
    error.push(args.employee.coretime_type !== null || "コアタイムの有無を選択してください");
    if (args.employee.coretime_type == 1) {
      error.push(
        args.employee.coretime_started_at !== null || "コアタイム開始時刻が入力されていません",
      );
      error.push(
        args.employee.coretime_ended_at !== null || "コアタイム終了時刻が入力されていません",
      );
      error.push(
        args.employee.coretime_started_at === null ||
          args.employee.coretime_ended_at === null ||
          args.employee.coretime_started_at < args.employee.coretime_ended_at ||
          "コアタイム開始時刻が終了時刻以後となっています",
      );
      error.push(
        args.employee.coretime_started_at === null ||
          args.employee.coretime_started_at < 1440 ||
          "コアタイム開始時刻は24時より前である必要があります",
      );
    }
  }

  if (args.is_individual_unit) {
    args.individual_units.forEach((individual_unit) => {
      error.push(
        args.employee.each_individual_units[individual_unit.id] !== null ||
          individual_unit.name + "の金額を入力してください",
      );
      error.push(
        args.employee.each_individual_units[individual_unit.id] === null ||
          vali.is_number(args.employee.each_individual_units[individual_unit.id]) ||
          individual_unit.name + "の金額は半角数字で入力してください",
      );
    });
  }

  error.push(args.employee.carfare_type !== null || "通勤手当を選択してください");

  if (args.employee.carfare_type != 0) {
    error.push(
      args.employee.is_train == 1 || args.employee.is_car == 1 || "通勤方法を選択してください",
    );
    if (args.employee.carfare_type == 1 && args.is_branchly_carfare == 1) {
      var carfare_money_error = 0;
      var carfare_number_error = 0;
      Object.keys(args.employee.carfares).forEach((branch_id) => {
        if (args.employee.carfares[branch_id].money === null) {
          carfare_money_error = 1;
        }
        if (
          args.employee.carfares[branch_id] !== null &&
          !vali.is_plus_number(args.employee.carfares[branch_id].money)
        ) {
          carfare_number_error = 1;
        }
      });
      error.push(
        (args.employee.carfare !== null && carfare_money_error == 0) ||
          "通勤手当が正しく入力されていません",
      );
      error.push(
        ((args.employee.carfare === null || vali.is_plus_number(args.employee.carfare)) &&
          carfare_number_error == 0) ||
          "通勤手当が半角数字で入力されていません",
      );
    } else {
      error.push(args.employee.carfare !== null || "通勤手当を入力してください");
      error.push(
        args.employee.carfare === null ||
          vali.is_plus_number(args.employee.carfare) ||
          "通勤手当は半角数字で入力してください",
      );
    }
    if (args.employee.is_car) {
      error.push(args.employee.km !== null || "マイカー使用部分の片道距離を入力してください");
      error.push(
        args.employee.km === null ||
          vali.is_plus_number(args.employee.km) ||
          "マイカー使用部分の片道距離は半角数字で入力してください",
      );
    }
  }

  error.push(args.employee.is_bonus !== null || "賞与を選択してください");
  error.push(args.employee.over_type !== null || "残業手当を選択してください");
  error.push(args.employee.is_mid !== null || "賞与を選択してください");
  error.push(args.employee.is_hol !== null || "休日手当を選択してください");

  if (args.employee.over_type == 2) {
    error.push(args.employee.deemed_over_hour !== null || "固定残業制の時間を入力してください");
    error.push(
      args.employee.deemed_over_hour === null ||
        vali.is_plus_number(args.employee.deemed_over_hour) ||
        "固定残業制の時間は半角数字で入力してください",
    );
    error.push(args.employee.deemed_over_money !== null || "固定残業制の金額を入力してください");
    error.push(
      args.employee.deemed_over_money === null ||
        vali.is_plus_number(args.employee.deemed_over_money) ||
        "固定残業制の金額は半角数字で入力してください",
    );
  }

  if (self.global.is_income_tax_opt == 1) {
    error.push(args.employee.is_income_tax !== null || "源泉所得税の計算の有無を選択してください");
  }

  if (args.employee.wage_type == 0 || args.employee.wage_type == 2) {
    if (args.employee.wage_type == 0) {
      error.push(
        args.employee.use_paid_hol_type !== null || "有給休暇全日使用単価を選択してください",
      );
    }
    if (args.employee.wage_type == 2 || args.employee.use_paid_hol_type == 0) {
      error.push(
        args.employee.use_paid_hol_unit !== null || "有給休暇全日使用単価を入力してください",
      );
      error.push(
        args.employee.use_paid_hol_unit === null ||
          vali.is_plus_number(args.employee.use_paid_hol_unit) ||
          "有給休暇全日使用単価は半角数字で入力してください",
      );
    }
  }

  error.push(
    args.employee.use_paid_hol_unit === null ||
      vali.is_plus_number(args.employee.buy_paid_hol_unit) ||
      "有給休暇買取単価は半角数字で入力してください",
  );

  // //Tax
  error.push(args.employee.health_ins_type !== null || "健康・介護保険を選択してください");
  error.push(args.employee.pension_ins_type !== null || "厚生年金保険を選択してください");
  if (args.employee.health_ins_type == 1 || args.employee.pension_ins_type == 1) {
    error.push(args.employee.is_child_care !== null || "産前産後・育児休業を選択してください");
    if (args.employee.is_child_care == 1) {
      error.push(
        args.employee.child_care_started_on !== null ||
          "産前産後育児休業の開始年月日を正しく入力してください",
      );
      error.push(
        args.employee.child_care_ended_on !== null ||
          "産前産後育児休業の終了年月日を正しく入力してください",
      );
    }

    error.push(args.employee.std_income !== null || "標準報酬月額を選択してください");
    error.push(
      args.employee.is_double_biz !== null || "二以上事業所勤務者かどうかを選択してください",
    );
    if (args.employee.is_double_biz == 1) {
      error.push(
        args.employee.std_income_rate !== null || "この会社での報酬月額割合を入力してください",
      );
      error.push(
        args.employee.std_income_rate === null ||
          vali.is_float(args.employee.std_income_rate) ||
          "この会社での報酬月額割合は半角数字(小数点を含む)で入力してください",
      );
    }
    error.push(
      args.employee.pension_ins_id === null ||
        vali.is_number_hyphen(args.employee.pension_ins_id) ||
        "基礎年金番号は半角数字で入力してください",
    );
    error.push(
      args.employee.social_ins_id === null ||
        vali.is_number_hyphen(args.employee.social_ins_id) ||
        "被保険者番号(社会保険)は半角数字で入力してください",
    );
  } else if (args.employee.health_ins_type == 2) {
    error.push(args.employee.union_health_name !== null || "国保名を入力してください");
    error.push(args.employee.union_health_money !== null || "月額健康保険料を入力してください");
    error.push(
      args.employee.union_health_money === null ||
        vali.is_plus_number(args.employee.union_health_money) ||
        "月額健康保険料は半角数字で入力してください",
    );
    error.push(args.employee.union_care_money !== null || "月額介護保険料を入力してください");
    error.push(
      args.employee.union_care_money === null ||
        vali.is_plus_number(args.employee.union_care_money) ||
        "月額介護保険料は半角数字で入力してください",
    );
  }

  error.push(args.employee.emp_ins_type !== null || "雇用保険を選択してください");
  if (args.employee.emp_ins_type == 1) {
    error.push(
      args.employee.emp_ins_id === null ||
        vali.is_number_hyphen(args.employee.emp_ins_id) ||
        "被保険者番号(雇用保険)は半角数字で入力してください",
    );
  }

  error.push(args.employee.is_comp_ins !== null || "労災保険を選択してください");

  return error;
}

export function validationEmployeeAuth(employee: EmployeeAuth) {
  var error = [];

  //必須項目
  error.push(employee.auth !== null || "権限を選択してください");
  error.push(employee.type !== null || "用途を選択してください");

  return error;
}

export function validationEmployeeConfig(employee: EmployeeConfig) {
  var error = [];

  error.push(employee.over_rate !== null || "法定外残業の割増賃金割合を入力してください");
  error.push(
    employee.over_rate === null ||
      vali.is_plus_number(employee.over_rate) ||
      "法定外残業の割増賃金割合は半角数字で入力してください",
  );
  error.push(employee.pre_over_rate !== null || "法定内残業の割増賃金割合を入力してください");
  error.push(
    employee.pre_over_rate === null ||
      vali.is_plus_number(employee.pre_over_rate) ||
      "法定内残業の割増賃金割合は半角数字で入力してください",
  );
  error.push(
    employee.sixty_over_rate !== null || "60時間超法定外残業の割増賃金割合を入力してください",
  );
  error.push(
    employee.sixty_over_rate === null ||
      vali.is_plus_number(employee.sixty_over_rate) ||
      "60時間超法定外残業の割増賃金割合は半角数字で入力してください",
  );
  error.push(employee.out_hol_rate !== null || "法定外休日の割増賃金割合を入力してください");
  error.push(
    employee.out_hol_rate === null ||
      vali.is_plus_number(employee.out_hol_rate) ||
      "法定外休日の割増賃金割合は半角数字で入力してください",
  );
  error.push(employee.law_hol_rate !== null || "法定休日の割増賃金割合を入力してください");
  error.push(
    employee.law_hol_rate === null ||
      vali.is_plus_number(employee.law_hol_rate) ||
      "法定休日の割増賃金割合は半角数字で入力してください",
  );
  error.push(employee.mid_rate !== null || "深夜の割増賃金割合を入力してください");
  error.push(
    employee.mid_rate === null ||
      vali.is_plus_number(employee.mid_rate) ||
      "深夜の割増賃金割合は半角数字で入力してください",
  );

  return error;
}

export function validationEmployeeMails(employees) {
  var error = [];

  //必須項目
  //Base
  employees.forEach((employee, i) => {
    error.push(employee.name !== null || i + 1 + "行目の名前を入力してください");
    error.push(
      employee.name === null ||
        vali.is_split_equal(employee.name, " ", 2) ||
        i + 1 + "行目の名前を入力してください",
    );
    error.push(employee.kana !== null || i + 1 + "行目の名前(カナ)を入力してください");
    error.push(
      employee.kana === null ||
        vali.is_split_equal(employee.kana, " ", 2) ||
        i + 1 + "行目の名前(カナ)を入力してください",
    );
    error.push(employee.mail !== null || i + 1 + "行目のメールアドレス(ID)を入力してください");
    error.push(
      employee.mail === null ||
        vali.is_no_blank(employee.mail) ||
        i + 1 + "行目のメールアドレス(ID)に空白が含まれています",
    );
    error.push(employee.hiring_type !== null || "区分を選択してください");
    error.push(employee.branch_id !== null || "所属部署/店舗を選択してください");
  });
  return error;
}
export function validationEmployeeManual(employee) {
  var error = [];

  error.push(employee.name !== null || "名前を入力してください");
  error.push(
    employee.name === null ||
      vali.is_split_equal(employee.name, " ", 2) ||
      "名前を入力してください",
  );
  error.push(employee.kana !== null || "名前(カナ)を入力してください");
  error.push(
    employee.kana === null ||
      vali.is_split_equal(employee.kana, " ", 2) ||
      "名前(カナ)を入力してください",
  );
  error.push(employee.mail !== null || "メールアドレス(ID)を入力してください");
  error.push(employee.hiring_type !== null || "区分を選択してください");
  error.push(employee.branch_id !== null || "所属部署/店舗を選択してください");

  return error;
}
