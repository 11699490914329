import VuexManagerControlNew from "@/vuex/VuexManagerControlNew";
import validationPaidHoliday from "@/validations/paid_holiday";
import { validationEmployeeMail, validationEmployeeBase } from "@/validations/employee";
import { validationEachEmployeeInfos } from "@/validations/employee_info";
import { validationDependant, validationSpouce } from "@/validations/family";
import * as util from "@/util";
import i18n from "@/plugins/i18n";

export default class Manager extends VuexManagerControlNew {
  create_descriptor = {};

  update_descriptor = {
    join: {
      url: "join/update_employee_mail",
      name: "join_page",
      dialog: {
        title: "join_dialog_title",
        msg: "join_dialog_msg",
        detail: "join_dialog_detail",
        exec_button: "will_send",
        cancel_button: "will_not_send",
      },
      processing_dialog: {},
      finish_dialog: { msg: "send_finish" },
      continue_edit: true,
      plus: {
        func: (args, res, self) => {
          console.log(self.state.step);
          self.setState({ name: "step", val: 10 });
        },
        args: {},
      },
      is_need_session: false,
    },
  };

  delete_descriptor = {};
}
