
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import utilMixins from "@/mixins";
  import header from "@/vuex/header";
  import dialog from "@/vuex/dialog";

  @Component({
    components: {},
  })
  export default class Button extends Mixins(utilMixins) {
    @Prop() upper: string;
    @Prop() lower?: string;
    @Prop() img?: string;
    @Prop() img_height?: number;
    @Prop() img_width?: number;
  }
