import { Component, Vue, Mixins } from "vue-property-decorator";
import monthly from "@/vuex/payment/monthly";
import each_monthly from "@/vuex/payment_each/monthly";
import monthly_stdincome from "@/vuex/payment/monthly_stdincome";
import global from "@/vuex/payment/global";
import * as util from "@/util";
import utilMixins from "@/mixins";
import manager from "@/vuex/payment/manager";
import { BranchHeaderData } from "@/typesold/header";

@Component
export default class mixinPayment extends Mixins(utilMixins) {
  changed_disp_num: boolean = false;

  get is_set_global(): boolean {
    return global.data[this.date] && global.data[this.date].is_set;
  }
  get is_set_monthly(): boolean {
    return (
      monthly.data[this.date] &&
      monthly.data[this.date][this.viewing_branch] &&
      monthly.data[this.date][this.viewing_branch].is_set
    );
  }
  get is_set_monthly_stdincome(): boolean {
    return (
      monthly_stdincome.data[this.date] &&
      monthly_stdincome.data[this.date][this.viewing_branch] &&
      monthly_stdincome.data[this.date][this.viewing_branch].is_set
    );
  }

  get is_valid_global(): boolean {
    return global.data[this.date] && global.data[this.date].is_valid;
  }
  get is_valid_monthly(): boolean {
    return (
      monthly.data[this.date] &&
      monthly.data[this.date][this.viewing_branch] &&
      monthly.data[this.date][this.viewing_branch].is_valid
    );
  }
  get is_valid_monthly_stdincome(): boolean {
    return (
      monthly_stdincome.data[this.date] &&
      monthly_stdincome.data[this.date][this.viewing_branch] &&
      monthly_stdincome.data[this.date][this.viewing_branch].is_valid
    );
  }

  async get_global() {
    if (this.param_date == 0) await global.fetch({ yearmonth: null, auth: this.s_auth });
    else await global.fetch({ yearmonth: this.date, auth: this.s_auth });
  }
  async get_monthly() {
    await monthly.fetch({
      yearmonth: this.date,
      branch_id: this.viewing_branch,
      payment_info_id: this.global.payment_info.id,
    });
  }
  async get_monthly_stdincome() {
    await monthly_stdincome.fetch({
      yearmonth: this.date,
      branch_id: this.viewing_branch,
      payment_info_id: this.global.payment_info.id,
    });
  }

  get global() {
    if (!this.is_set_global || !this.is_valid_global) this.get_global();
    if (this.is_set_global) return global.data[this.date];
  }
  get monthly() {
    if (!this.is_set_global || !this.is_valid_global)
      this.get_global(); //get_monthlyでglobalを使用するため
    else if (!this.is_set_monthly || !this.is_valid_monthly) this.get_monthly();
    if (this.is_set_monthly) return monthly.data[this.date][this.viewing_branch];
  }
  get monthly_stdincome() {
    if (!this.is_set_global || !this.is_valid_global)
      this.get_global(); //get_monthly_stdincomeでglobalを使用するため
    else if (!this.is_set_monthly_stdincome || !this.is_valid_monthly_stdincome)
      this.get_monthly_stdincome();
    if (this.is_set_monthly_stdincome)
      return monthly_stdincome.data[this.date][this.viewing_branch];
  }

  get payment_info() {
    return this.global.payment_info;
  }
  get nencho() {
    //12月給与は年調後にロックできない仕組みを作成
    return this.global.nencho;
  }

  get saved_payment_allowances() {
    return this.global.saved_payment_allowances;
  }
  get saved_payment_deductions() {
    return this.global.saved_payment_deductions;
  }
  get saved_payment_other_allowances() {
    return this.global.saved_payment_other_allowances;
  }

  get is_deem_over() {
    return this.monthly.is_deem_over;
  }
  get is_fixed_tax_reduction() {
    return this.monthly.is_fixed_tax_reduction;
  }

  get viewing_branch() {
    var branch_id = this.query.viewing_branch;
    if (branch_id === undefined) {
      branch_id = Number(util.getLocalStorage("branch_id"));
    }
    if (branch_id != 0 && this.s_auth == 2 && !this.is_multi_branch) {
      branch_id = 0;
    }
    this.query = { viewing_branch: branch_id };
    return branch_id;
  }
  set viewing_branch(val) {
    this.page = 1;
    each_monthly.disable_all();
    this.query = { viewing_branch: val };
  }

  get m() {
    return manager;
  }

  get todayobj() {
    return new Date();
  }

  get dateobj() {
    return util.date2obj(this.date + "-01");
  }
  get date() {
    if (this.param_date == 0) {
      this.param_date = global.latest_date;
      return global.latest_date;
    } else {
      return this.param_date;
    }
  }
  set date(val) {
    this.param_date = val;
  }
  get param_date() {
    var date = this.query.payment_date;
    if (date === undefined) {
      date = 0;
      this.query = { payment_date: date };
    }
    return date;
  }
  set param_date(val) {
    if (val != this.param_date) {
      this.query_push = { payment_date: val };
    }
  }

  get branch_hash_object() {
    return this.global.branch_hash_object;
  }

  get search_key() {
    var search_key = this.query.payment_search_key;
    if (search_key === undefined || search_key == "") {
      this.query = { payment_search_key: undefined };
      return null;
    } else {
      return search_key;
    }
  }
  set search_key(val) {
    this.page = 1;
    if (val == "" || val == null) {
      this.query = { payment_search_key: undefined };
    } else {
      this.query = { payment_search_key: val };
    }
  }

  get is_open_allowance() {
    return this.query.is_open_payment_allowance;
  }
  set is_open_allowance(val) {
    if (val == "" || val == null) {
      this.query = { is_open_payment_allowance: undefined };
    } else {
      this.query = { is_open_payment_allowance: val };
    }
  }
  get is_open_deduction() {
    return this.query.is_open_payment_deduction;
  }
  set is_open_deduction(val) {
    if (val == "" || val == null) {
      this.query = { is_open_payment_deduction: undefined };
    } else {
      this.query = { is_open_payment_deduction: val };
    }
  }
  get is_open_other() {
    return this.query.is_open_payment_other;
  }
  set is_open_other(val) {
    if (val == "" || val == null) {
      this.query = { is_open_payment_other: undefined };
    } else {
      this.query = { is_open_payment_other: val };
    }
  }

  get sort_code() {
    return this.query.payment_sort_code;
  }
  set sort_code(val) {
    if (val == "" || val == null) {
      this.query = { payment_sort_code: undefined };
    } else {
      this.query = { payment_sort_code: val };
    }
  }
  get sort_name() {
    return this.query.payment_sort_name;
  }
  set sort_name(val) {
    if (val == "" || val == null) {
      this.query = { payment_sort_name: undefined };
    } else {
      this.query = { payment_sort_name: val };
    }
  }
  get sort_hiring_type() {
    return this.query.payment_sort_hiring_type;
  }
  set sort_hiring_type(val) {
    if (val == "" || val == null) {
      this.query = { payment_sort_hiring_type: undefined };
    } else {
      this.query = { payment_sort_hiring_type: val };
    }
  }
  get sort_branch_id() {
    return this.query.payment_sort_branch_id;
  }
  set sort_branch_id(val) {
    if (val == "" || val == null) {
      this.query = { payment_sort_branch_id: undefined };
    } else {
      this.query = { payment_sort_branch_id: val };
    }
  }

  get sort_allowance() {
    return this.query.payment_sort_allowance;
  }
  set sort_allowance(val) {
    if (val == "" || val == null) {
      this.query = { payment_sort_allowance: undefined };
    } else {
      this.query = { payment_sort_allowance: val };
    }
  }
  get sort_deduction() {
    return this.query.payment_sort_deduction;
  }
  set sort_deduction(val) {
    if (val == "" || val == null) {
      this.query = { payment_sort_deduction: undefined };
    } else {
      this.query = { payment_sort_deduction: val };
    }
  }
  get sort_other_allowance() {
    return this.query.payment_sort_other_allowance;
  }
  set sort_other_allowance(val) {
    if (val == "" || val == null) {
      this.query = { payment_sort_other_allowance: undefined };
    } else {
      this.query = { payment_sort_other_allowance: val };
    }
  }
  get sort_real_pay() {
    return this.query.payment_sort_real_pay;
  }
  set sort_real_pay(val) {
    if (val == "" || val == null) {
      this.query = { payment_sort_real_pay: undefined };
    } else {
      this.query = { payment_sort_real_pay: val };
    }
  }
  get sort_paid_cash() {
    return this.query.payment_sort_paid_cash;
  }
  set sort_paid_cash(val) {
    if (val == "" || val == null) {
      this.query = { payment_sort_paid_cash: undefined };
    } else {
      this.query = { payment_sort_paid_cash: val };
    }
  }
  get sort_sum_tax() {
    return this.query.payment_sort_sum_tax;
  }
  set sort_sum_tax(val) {
    if (val == "" || val == null) {
      this.query = { payment_sort_sum_tax: undefined };
    } else {
      this.query = { payment_sort_sum_tax: val };
    }
  }

  //給与の二行目
  get sort_reg_pay() {
    return this.query.payment_sort_reg_pay;
  }
  set sort_reg_pay(val) {
    if (val == "" || val == null) {
      this.query = { payment_sort_reg_pay: undefined };
    } else {
      this.query = { payment_sort_reg_pay: val };
    }
  }
  get sort_hol_pay() {
    return this.query.payment_sort_hol_pay;
  }
  set sort_hol_pay(val) {
    if (val == "" || val == null) {
      this.query = { payment_sort_hol_pay: undefined };
    } else {
      this.query = { payment_sort_hol_pay: val };
    }
  }
  get sort_mid_pay() {
    return this.query.payment_sort_mid_pay;
  }
  set sort_mid_pay(val) {
    if (val == "" || val == null) {
      this.query = { payment_sort_mid_pay: undefined };
    } else {
      this.query = { payment_sort_mid_pay: val };
    }
  }
  get sort_over_pay() {
    return this.query.payment_sort_over_pay;
  }
  set sort_over_pay(val) {
    if (val == "" || val == null) {
      this.query = { payment_sort_over_pay: undefined };
    } else {
      this.query = { payment_sort_over_pay: val };
    }
  }
  get sort_deemover_pay() {
    return this.query.payment_sort_deemover_pay;
  }
  set sort_deemover_pay(val) {
    if (val == "" || val == null) {
      this.query = { payment_sort_deemover_pay: undefined };
    } else {
      this.query = { payment_sort_deemover_pay: val };
    }
  }
  get sort_paid_hol_pay() {
    return this.query.payment_sort_paid_hol_pay;
  }
  set sort_paid_hol_pay(val) {
    if (val == "" || val == null) {
      this.query = { payment_sort_paid_hol_pay: undefined };
    } else {
      this.query = { payment_sort_paid_hol_pay: val };
    }
  }
  get sort_carfare_pay() {
    return this.query.payment_sort_carfare_pay;
  }
  set sort_carfare_pay(val) {
    if (val == "" || val == null) {
      this.query = { payment_sort_carfare_pay: undefined };
    } else {
      this.query = { payment_sort_carfare_pay: val };
    }
  }
  get sort_plus_sum() {
    return this.query.payment_sort_plus_sum;
  }
  set sort_plus_sum(val) {
    if (val == "" || val == null) {
      this.query = { payment_sort_plus_sum: undefined };
    } else {
      this.query = { payment_sort_plus_sum: val };
    }
  }

  get sort_health_ins() {
    return this.query.payment_sort_health_ins;
  }
  set sort_health_ins(val) {
    if (val == "" || val == null) {
      this.query = { payment_sort_health_ins: undefined };
    } else {
      this.query = { payment_sort_health_ins: val };
    }
  }
  get sort_care_ins() {
    return this.query.payment_sort_care_ins;
  }
  set sort_care_ins(val) {
    if (val == "" || val == null) {
      this.query = { payment_sort_care_ins: undefined };
    } else {
      this.query = { payment_sort_care_ins: val };
    }
  }
  get sort_pension_ins() {
    return this.query.payment_sort_pension_ins;
  }
  set sort_pension_ins(val) {
    if (val == "" || val == null) {
      this.query = { payment_sort_pension_ins: undefined };
    } else {
      this.query = { payment_sort_pension_ins: val };
    }
  }
  get sort_emp_ins() {
    return this.query.payment_sort_emp_ins;
  }
  set sort_emp_ins(val) {
    if (val == "" || val == null) {
      this.query = { payment_sort_emp_ins: undefined };
    } else {
      this.query = { payment_sort_emp_ins: val };
    }
  }
  get sort_social_ins_adj() {
    return this.query.payment_sort_social_ins_adj;
  }
  set sort_social_ins_adj(val) {
    if (val == "" || val == null) {
      this.query = { payment_sort_social_ins_adj: undefined };
    } else {
      this.query = { payment_sort_social_ins_adj: val };
    }
  }
  get sort_income_tax() {
    return this.query.payment_sort_income_tax;
  }
  set sort_income_tax(val) {
    if (val == "" || val == null) {
      this.query = { payment_sort_income_tax: undefined };
    } else {
      this.query = { payment_sort_income_tax: val };
    }
  }
  get sort_res_tax() {
    return this.query.payment_sort_res_tax;
  }
  set sort_res_tax(val) {
    if (val == "" || val == null) {
      this.query = { payment_sort_res_tax: undefined };
    } else {
      this.query = { payment_sort_res_tax: val };
    }
  }
  get sort_minus_sum() {
    return this.query.payment_sort_minus_sum;
  }
  set sort_minus_sum(val) {
    if (val == "" || val == null) {
      this.query = { payment_sort_minus_sum: undefined };
    } else {
      this.query = { payment_sort_minus_sum: val };
    }
  }

  get sort_yearend_adj() {
    return this.query.payment_sort_yearend_adj;
  }
  set sort_yearend_adj(val) {
    if (val == "" || val == null) {
      this.query = { payment_sort_yearend_adj: undefined };
    } else {
      this.query = { payment_sort_yearend_adj: val };
    }
  }
  get sort_settlement() {
    return this.query.payment_sort_settlement;
  }
  set sort_settlement(val) {
    if (val == "" || val == null) {
      this.query = { payment_sort_settlement: undefined };
    } else {
      this.query = { payment_sort_settlement: val };
    }
  }
  get sort_other_sum() {
    return this.query.payment_sort_other_sum;
  }
  set sort_other_sum(val) {
    if (val == "" || val == null) {
      this.query = { payment_sort_other_sum: undefined };
    } else {
      this.query = { payment_sort_other_sum: val };
    }
  }

  get sort_lock_type() {
    return this.query.payment_sort_lock_type;
  }
  set sort_lock_type(val) {
    if (val == "" || val == null) {
      this.query = { payment_sort_lock_type: undefined };
    } else {
      this.query = { payment_sort_lock_type: val };
    }
  }

  get sort_allowance_id() {
    return this.query.payment_sort_allowance_id;
  }
  set sort_allowance_id(val) {
    if (val == "" || val == null) {
      this.query = { payment_sort_allowance_id: undefined };
    } else {
      this.query = { payment_sort_allowance_id: val };
    }
  }
  get sort_deduction_id() {
    return this.query.payment_sort_deduction_id;
  }
  set sort_deduction_id(val) {
    if (val == "" || val == null) {
      this.query = { payment_sort_deduction_id: undefined };
    } else {
      this.query = { payment_sort_deduction_id: val };
    }
  }
  get sort_other_allowance_id() {
    return this.query.payment_sort_other_allowance_id;
  }
  set sort_other_allowance_id(val) {
    if (val == "" || val == null) {
      this.query = { payment_sort_other_allowance_id: undefined };
    } else {
      this.query = { payment_sort_other_allowance_id: val };
    }
  }

  get page() {
    var page = this.query.payment_page;
    if (page === undefined || page == "") {
      this.query = { payment_page: 1 };
      page = 1;
    }
    return page;
  }
  set page(val) {
    if (val == "" || val == null) {
      this.query = { payment_page: undefined };
    } else {
      this.query = { payment_page: val };
    }
  }

  get searched_sorted_each_payments() {
    var each_payments = util.deep_copy(this.each_payments);
    each_payments = this.search_each_payments(each_payments);
    each_payments = this.sort_each_payments(each_payments);
    return each_payments;
  }
  get managed_each_payments() {
    var each_payments = util.deep_copy(this.searched_sorted_each_payments);
    each_payments = this.page_each_payments(each_payments);
    return each_payments;
  }
  get searched_sorted_stdincomes() {
    var stdincomes = util.deep_copy(this.stdincomes);
    stdincomes = this.search_stdincomes(stdincomes);
    stdincomes = this.sort_stdincomes(stdincomes);
    return stdincomes;
  }
  get managed_stdincomes() {
    var stdincomes = util.deep_copy(this.searched_sorted_stdincomes);
    stdincomes = this.page_stdincomes(stdincomes);
    return stdincomes;
  }
  search_each_payments(each_payments) {
    if (this.search_key === null) {
      return each_payments;
    } else {
      const search_keys = this.search_key.split(/\s+/).filter((str) => str != "");
      console.log(search_keys);
      var _this = this;
      return each_payments.filter((each_payment) => {
        var flag = 0;
        search_keys.forEach((key) => {
          if (
            this.employee_hash[each_payment.employee_id].name
              .toLowerCase()
              .indexOf(key.toLowerCase()) != -1 ||
            util.search_kana(this.employee_hash[each_payment.employee_id].kana, key) ||
            (this.employee_hash[each_payment.employee_id].code &&
              this.employee_hash[each_payment.employee_id].code
                .toLowerCase()
                .indexOf(key.toLowerCase()) != -1)
          ) {
            flag = 1;
            return;
          }
        });
        return flag;
      });
    }
  }
  sort_each_payments(each_payments) {
    if (this.sort_code)
      each_payments.sort((x, y) =>
        util.sort_idx(
          this.employee_hash[x.employee_id].code,
          this.employee_hash[y.employee_id].code,
          this.sort_code,
        ),
      );
    else if (this.sort_name)
      each_payments.sort((x, y) =>
        util.sort_idx(
          this.employee_hash[x.employee_id].kana,
          this.employee_hash[y.employee_id].kana,
          this.sort_name,
        ),
      );
    else if (this.sort_hiring_type)
      each_payments.sort((x, y) =>
        util.sort_idx(
          this.employee_hash[x.employee_id].hiring_type,
          this.employee_hash[y.employee_id].hiring_type,
          this.sort_hiring_type,
        ),
      );
    else if (this.sort_branch_id)
      each_payments.sort((x, y) =>
        util.sort_idx(
          this.employee_hash[x.employee_id].branch_id,
          this.employee_hash[y.employee_id].branch_id,
          this.sort_branch_id,
        ),
      );
    else if (this.sort_reg_pay)
      each_payments.sort((x, y) => util.sort_idx(x.reg_pay, y.reg_pay, this.sort_reg_pay));
    else if (this.sort_hol_pay)
      each_payments.sort((x, y) => util.sort_idx(x.hol_pay, y.hol_pay, this.sort_hol_pay));
    else if (this.sort_mid_pay)
      each_payments.sort((x, y) => util.sort_idx(x.mid_pay, y.mid_pay, this.sort_mid_pay));
    else if (this.sort_over_pay)
      each_payments.sort((x, y) => util.sort_idx(x.over_pay, y.over_pay, this.sort_over_pay));
    else if (this.sort_deemover_pay)
      each_payments.sort((x, y) =>
        util.sort_idx(x.deemover_pay, y.deemover_pay, this.sort_deemover_pay),
      );
    else if (this.sort_paid_hol_pay)
      each_payments.sort((x, y) =>
        util.sort_idx(x.paid_hol_pay, y.paid_hol_pay, this.sort_paid_hol_pay),
      );
    else if (this.sort_carfare_pay)
      each_payments.sort((x, y) =>
        util.sort_idx(x.carfare_pay, y.carfare_pay, this.sort_carfare_pay),
      );
    else if (this.sort_plus_sum)
      each_payments.sort((x, y) => util.sort_idx(x.plus_sum, y.plus_sum, this.sort_plus_sum));
    else if (this.sort_health_ins)
      each_payments.sort((x, y) => util.sort_idx(x.health_ins, y.health_ins, this.sort_health_ins));
    else if (this.sort_care_ins)
      each_payments.sort((x, y) => util.sort_idx(x.care_ins, y.care_ins, this.sort_care_ins));
    else if (this.sort_pension_ins)
      each_payments.sort((x, y) =>
        util.sort_idx(x.pension_ins, y.pension_ins, this.sort_pension_ins),
      );
    else if (this.sort_emp_ins)
      each_payments.sort((x, y) => util.sort_idx(x.emp_ins, y.emp_ins, this.sort_emp_ins));
    else if (this.sort_social_ins_adj)
      each_payments.sort((x, y) =>
        util.sort_idx(x.social_ins_adj, y.social_ins_adj, this.sort_social_ins_adj),
      );
    else if (this.sort_res_tax)
      each_payments.sort((x, y) => util.sort_idx(x.res_tax, y.res_tax, this.sort_res_tax));
    else if (this.sort_income_tax)
      each_payments.sort((x, y) => util.sort_idx(x.income_tax, y.income_tax, this.sort_income_tax));
    else if (this.sort_minus_sum)
      each_payments.sort((x, y) => util.sort_idx(x.minus_sum, y.minus_sum, this.sort_minus_sum));
    else if (this.sort_yearend_adj)
      each_payments.sort((x, y) =>
        util.sort_idx(x.yearend_adj, y.yearend_adj, this.sort_yearend_adj),
      );
    else if (this.sort_settlement)
      each_payments.sort((x, y) => util.sort_idx(x.settlement, y.settlement, this.sort_settlement));
    else if (this.sort_other_sum)
      each_payments.sort((x, y) => util.sort_idx(x.other_sum, y.other_sum, this.sort_other_sum));
    else if (this.sort_real_pay)
      each_payments.sort((x, y) => util.sort_idx(x.real_pay, y.real_pay, this.sort_real_pay));
    else if (this.sort_paid_cash)
      each_payments.sort((x, y) => util.sort_idx(x.paid_cash, y.paid_cash, this.sort_paid_cash));
    else if (this.sort_sum_tax)
      each_payments.sort((x, y) => util.sort_idx(x.sum_tax, y.sum_tax, this.sort_sum_tax));
    else if (this.sort_allowance)
      each_payments.sort((x, y) =>
        util.sort_idx(
          x.each_payment_allowances[this.sort_allowance_id]
            ? x.each_payment_allowances[this.sort_allowance_id].money
            : 0,
          y.each_payment_allowances[this.sort_allowance_id]
            ? y.each_payment_allowances[this.sort_allowance_id].money
            : 0,
          this.sort_allowance,
        ),
      );
    else if (this.sort_deduction)
      each_payments.sort((x, y) =>
        util.sort_idx(
          x.each_payment_deductions[this.sort_deduction_id]
            ? x.each_payment_deductions[this.sort_deduction_id].money
            : 0,
          y.each_payment_deductions[this.sort_deduction_id]
            ? y.each_payment_deductions[this.sort_deduction_id].money
            : 0,
          this.sort_deduction,
        ),
      );
    else if (this.sort_other_allowance)
      each_payments.sort((x, y) =>
        util.sort_idx(
          x.each_payment_other_allowances[this.sort_other_allowance_id]
            ? x.each_payment_other_allowances[this.sort_other_allowance_id].money
            : 0,
          y.each_payment_other_allowances[this.sort_other_allowance_id]
            ? y.each_payment_other_allowances[this.sort_other_allowance_id].money
            : 0,
          this.sort_other_allowance,
        ),
      );
    else if (this.sort_lock_type)
      each_payments.sort((x, y) =>
        util.sort_idx(
          this.lock_types_hash[this.employee_hash[x.employee_id].branch_id],
          this.lock_types_hash[this.employee_hash[y.employee_id].branch_id],
          this.sort_lock_type,
        ),
      );
    else {
      each_payments.sort((x, y) =>
        util.sort_idx(
          this.employee_hash[x.employee_id].kana,
          this.employee_hash[y.employee_id].kana,
          "asc",
        ),
      );
      each_payments.sort((x, y) =>
        util.sort_idx(
          this.employee_hash[x.employee_id].code,
          this.employee_hash[y.employee_id].code,
          "asc",
        ),
      );
      if (this.viewing_branch == 0) {
        each_payments.sort((x, y) =>
          util.sort_idx(
            this.employee_hash[x.employee_id].branch_id,
            this.employee_hash[y.employee_id].branch_id,
            "asc",
          ),
        );
      }
    }

    return each_payments;
  }
  page_each_payments(each_payments) {
    return each_payments.slice(this.disp_num * (this.page - 1), this.disp_num * this.page);
  }
  search_stdincomes(stdincomes) {
    if (this.search_key === null) {
      return stdincomes;
    } else {
      const search_keys = this.search_key.split(/\s+/).filter((str) => str != "");
      console.log(search_keys);
      var _this = this;
      return stdincomes.filter((stdincome) => {
        var flag = 0;
        search_keys.forEach((key) => {
          if (
            this.employee_hash[stdincome.employee_id].name
              .toLowerCase()
              .indexOf(key.toLowerCase()) != -1 ||
            util.search_kana(this.employee_hash[stdincome.employee_id].kana, key) ||
            (this.employee_hash[stdincome.employee_id].code &&
              this.employee_hash[stdincome.employee_id].code
                .toLowerCase()
                .indexOf(key.toLowerCase()) != -1)
          ) {
            flag = 1;
            return;
          }
        });
        return flag;
      });
    }
  }
  sort_stdincomes(stdincomes) {
    if (this.sort_code)
      stdincomes.sort((x, y) =>
        util.sort_idx(
          this.employee_hash[x.employee_id].code,
          this.employee_hash[y.employee_id].code,
          this.sort_code,
        ),
      );
    else if (this.sort_name)
      stdincomes.sort((x, y) =>
        util.sort_idx(
          this.employee_hash[x.employee_id].kana,
          this.employee_hash[y.employee_id].kana,
          this.sort_name,
        ),
      );
    else if (this.sort_hiring_type)
      stdincomes.sort((x, y) =>
        util.sort_idx(
          this.employee_hash[x.employee_id].hiring_type,
          this.employee_hash[y.employee_id].hiring_type,
          this.sort_hiring_type,
        ),
      );
    else if (this.sort_branch_id)
      stdincomes.sort((x, y) =>
        util.sort_idx(
          this.employee_hash[x.employee_id].branch_id,
          this.employee_hash[y.employee_id].branch_id,
          this.sort_branch_id,
        ),
      );
    return stdincomes;
  }
  page_stdincomes(stdincomes) {
    return stdincomes.slice(this.disp_num * (this.page - 1), this.disp_num * this.page);
  }
  sort_click(sort_key, id) {
    this.page = 1;
    sort_key = "sort_" + sort_key;
    var sort_mode = undefined;
    if (this[sort_key] === undefined) {
      sort_mode = "asc";
    } else if (this[sort_key] == "asc") {
      sort_mode = "desc";
    } else if (this[sort_key] == "desc") {
      sort_mode = "asc";
    }
    if (id) {
      this.set_sort_id(sort_key, id);
    }
    this.set_sort(sort_key, sort_mode);
  }
  set_sort(sort_key, sort_mode) {
    this.query = {
      payment_sort_code: sort_key == "sort_code" ? sort_mode : undefined,
      payment_sort_name: sort_key == "sort_name" ? sort_mode : undefined,
      payment_sort_hiring_type: sort_key == "sort_hiring_type" ? sort_mode : undefined,
      payment_sort_branch_id: sort_key == "sort_branch_id" ? sort_mode : undefined,

      payment_sort_reg_pay: sort_key == "sort_reg_pay" ? sort_mode : undefined,
      payment_sort_hol_pay: sort_key == "sort_hol_pay" ? sort_mode : undefined,
      payment_sort_mid_pay: sort_key == "sort_mid_pay" ? sort_mode : undefined,
      payment_sort_over_pay: sort_key == "sort_over_pay" ? sort_mode : undefined,
      payment_sort_deemover_pay: sort_key == "sort_deemover_pay" ? sort_mode : undefined,
      payment_sort_paid_hol_pay: sort_key == "sort_paid_hol_pay" ? sort_mode : undefined,
      payment_sort_carfare_pay: sort_key == "sort_carfare_pay" ? sort_mode : undefined,
      payment_sort_allowance: sort_key == "sort_allowance" ? sort_mode : undefined,
      payment_sort_plus_sum: sort_key == "sort_plus_sum" ? sort_mode : undefined,

      payment_sort_health_ins: sort_key == "sort_health_ins" ? sort_mode : undefined,
      payment_sort_care_ins: sort_key == "sort_care_ins" ? sort_mode : undefined,
      payment_sort_pension_ins: sort_key == "sort_pension_ins" ? sort_mode : undefined,
      payment_sort_emp_ins: sort_key == "sort_emp_ins" ? sort_mode : undefined,
      payment_sort_social_ins_adj: sort_key == "sort_social_ins_adj" ? sort_mode : undefined,
      payment_sort_income_tax: sort_key == "sort_income_tax" ? sort_mode : undefined,
      payment_sort_res_tax: sort_key == "sort_res_tax" ? sort_mode : undefined,
      payment_sort_deduction: sort_key == "sort_deduction" ? sort_mode : undefined,
      payment_sort_minus_sum: sort_key == "sort_minus_sum" ? sort_mode : undefined,

      payment_sort_real_pay: sort_key == "sort_real_pay" ? sort_mode : undefined,
      payment_sort_paid_cash: sort_key == "sort_paid_cash" ? sort_mode : undefined,
      payment_sort_sum_tax: sort_key == "sort_sum_tax" ? sort_mode : undefined,

      payment_sort_yearend_adj: sort_key == "sort_yearend_adj" ? sort_mode : undefined,
      payment_sort_settlement: sort_key == "sort_settlement" ? sort_mode : undefined,
      payment_sort_other_sum: sort_key == "sort_other_sum" ? sort_mode : undefined,

      payment_sort_lock_type: sort_key == "sort_lock_type" ? sort_mode : undefined,
    };
  }
  set_sort_id(sort_key, id) {
    this.query = {
      payment_sort_allowance_id: sort_key == "sort_allowance" ? id : undefined,
      payment_sort_deduction_id: sort_key == "sort_deduction" ? id : undefined,
      payment_sort_other_allowance_id: sort_key == "sort_other_allowance" ? id : undefined,
    };
  }

  get each_payments() {
    return this.monthly.each_payments;
  }
  get stdincomes() {
    return this.monthly_stdincome.stdincomes;
  }

  get employee_hash() {
    return this.monthly.employee_hash;
  }
  get branch_hash() {
    return this.monthly.branch_hash;
  }
  get branch_hash_all() {
    return this.monthly.branch_hash_all;
  }
  get stdincome_employee_hash() {
    return this.monthly_stdincome.employee_hash;
  }
  get stdincome_branch_hash() {
    return this.monthly_stdincome.branch_hash;
  }
  get stdincome_branch_hash_all() {
    return this.monthly_stdincome.branch_hash_all;
  }
  get lock_types_hash() {
    return this.monthly.lock_types_hash;
  }

  get disp_num() {
    return manager.disp_num;
  }
  set disp_num(val) {
    this.page = 1;
    util.setLocalStorage("payment_disp_num", val);
    manager.setDispNum(val);
  }

  get lockable_payment() {
    let header = this.header as BranchHeaderData;
    return this.s_auth == 2 || (this.s_auth == 1 && header.auth_payment_lock == 1);
  }
  get editable_payment() {
    let header = this.header as BranchHeaderData;
    return this.s_auth == 2 || (this.s_auth == 1 && header.auth_payment == 2);
  }
}
