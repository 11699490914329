import { Mutation, MutationAction, Action, VuexModule, getModule, Module } from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import Vue from 'vue';

export type Data = {
  employees: any,
  is_valid?: boolean,
  is_set: boolean, 
  fetching?: Promise<void>
}
export type BranchlyData = {
  [branch: number]: Data
}

@Module({ dynamic: true, store, name: "paymentgroup_branchly_data", namespaced: true })
class Monthly extends VuexModule {
  data: BranchlyData = {};

  @Mutation
  public set({branch_id, data}: {branch_id: number, data: Data}) {
    Vue.set(this.data, branch_id, {
      employees: data.employees,
      is_valid: true,
      is_set: true,
      fetching: null,
    });
  }
  @Mutation
  public disable({branch_id}: {branch_id: number}) {
    if (this.data && this.data[branch_id]) {
      this.data[branch_id].is_valid = false;
    }
  }
  @Mutation
  public disable_all() {
    Object.keys(this.data).forEach(branch_id => {
      this.data[branch_id].is_valid = false;
    });
  }
  @Mutation
  public setFetching({branch_id, value}: {branch_id: number, value: Promise<void>}) {
    this.data[branch_id].fetching = value;
  }


  // actions
  @Action({ rawError: true })
  public async fetch({branch_id}: {branch_id: number}) {
    //ないなら作る
    if (!this.data[branch_id]) {
      Vue.set(this.data, branch_id, {
        employees: [],
        is_valid: false,
        is_set: false,
        fetching: null,
      });
    }
    
    if (this.data[branch_id].fetching) {
      await this.data[branch_id].fetching;
    } else {
      const promise = util.post('config/read_paymentgroup_branchly', {branch_id}).then((res) => {
        this.set({branch_id, data: res.data});
      });
      this.setFetching({branch_id, value: promise});
      await promise;
    }
  }
}

export default getModule(Monthly);