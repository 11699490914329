import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import validate from "@/validations/validate";
import { validationDemandOvertime } from "@/validations/demand";
import Vue from "vue";
import { Demand_overtime } from "@/typesold/demand";
import dialog from "@/vuex/dialog";
import global from "./global";
import node from "@/vuex/node";

@Module({ dynamic: true, store, name: "demand_overtime_manager", namespaced: true })
class Company_manager extends VuexModule {
  disp_num: number = 10;

  is_open_add_demand_overtime_dialog: boolean = false;
  demand_overtime: null | Demand_overtime = null;

  @Mutation
  public setDispNum(val) {
    this.disp_num = val;
  }

  @Action({ rawError: true })
  public async is_open_input() {
    return false;
  }

  @Action({ rawError: true })
  public cancelAll() {}

  //Base
  @Mutation
  public setIsOpenAddDemandOvertimeDialog(val: boolean) {
    this.is_open_add_demand_overtime_dialog = val;
  }
  @Mutation
  setDemandOvertime({ demand_overtime }: { demand_overtime: Demand_overtime }) {
    Vue.set(this, "demand_overtime", demand_overtime);
  }
  @Action({ rawError: true })
  public createDemandOvertime({ is_prev_overdemand }: { is_prev_overdemand: number }) {
    if (validate(validationDemandOvertime(this.demand_overtime, is_prev_overdemand))) {
      dialog.openConfirmDialog({
        msg: util.$t("demand_overtime_confirm"),
        cancel_button: util.$t("demand_overtime_no_send"),
        exec_button: util.$t("demand_overtime_send"),
        func: this.createDemandOvertimeProcess,
        args: { demand_overtime: this.demand_overtime },
      });
    }
  }
  @Action({ rawError: true })
  public async createDemandOvertimeProcess({
    demand_overtime,
  }: {
    demand_overtime: Demand_overtime;
  }) {
    global.createDemandOvertime({ demand_overtime });
    await util.post("demand_overtime/create_demand_overtime", { demand_overtime });
    this.setIsOpenAddDemandOvertimeDialog(false);
    dialog.openAlertDialog({ msg: util.$t("demand_overtime_finish") });
    node.disable();
  }

  @Action({ rawError: true })
  public deleteDemandOvertime({ demand_overtime_id }: { demand_overtime_id: number }) {
    dialog.openForceConfirmDialog({
      msg: util.$t("demand_overtime_cancel_confirm"),
      cancel_button: util.$t("demand_overtime_no_cancel"),
      exec_button: util.$t("demand_overtime_cancel"),
      func: this.deleteDemandOvertimeProcess,
      args: { demand_overtime_id },
    });
  }
  @Action({ rawError: true })
  public async deleteDemandOvertimeProcess({ demand_overtime_id }: { demand_overtime_id: number }) {
    global.deleteDemandOvertime({ demand_overtime_id });
    await util.post("demand_overtime/delete_demand_overtime", { demand_overtime_id });
    node.disable();
  }
}

export default getModule(Company_manager);
