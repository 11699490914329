
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import header from "@/vuex/header";
  import * as util from "@/util";
  import mixinShift from "@/mixins/mixinShift";
  import monthly from "@/vuex/shift/monthly";
  import daily from "@/vuex/shift/daily";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/shift/manager";
  import need_global from "@/vuex/shift/need_global";
  import Need from "@/typesold/need";
  import need_manager from "@/vuex/shift/need_manager";
  import realtime from "@/vuex/shift/realtime";
  import login from "@/vuex/login";
  import before_login from "@/vuex/before_login";
  import session from "@/vuex/session";
  import UtilPopup from "@/components/util/Popup.vue";
  import * as env from "@/env.js";

  @Component({
    components: {
      UtilPopup,
    },
  })
  export default class Logout extends Mixins(mixinShift) {
    get is_display() {
      return header.is_open_logout_dialog;
    }
    set is_display(val) {
      header.setIsOpenLogoutDialog(val);
    }
    close() {
      header.setIsOpenLogoutDialog(false);
    }
    async logout() {
      await login.logout();
      session.deleteSession();
      before_login.disable({});
      location.href = `/login/${util.getLocalStorage("company_code")}`;
      // location.href = `${env.authUrl}interaction/logout?redirect_uri=${env.baseUrl}postlogout`;
    }
  }
