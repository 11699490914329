import { Mutation, MutationAction, Action, VuexModule, getModule, Module } from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import Vue from 'vue';
import global from "@/vuex/bonus_each/global";

export type EmployeeHash = {
  [id: number]: string
}
export type LockTypeHash = {
  [id: number]: number
}
export type BranchObject = {
  [id: number]: string,
}
export type HolidayHash = {
  [date: string]: number,
}
export type CarfareHash = {
  [date: string]: number,
}
export type ShiftHash = {
  [date: string]: any,
}
export type Data = {
  employee: any,
  branch: any,
  each_attendance: any,
  each_bonus_payment: any,
  lock_type: number | null,
  prev_employee_id: number | null,
  next_employee_id: number | null,
  bank1_name: string | null,
  bank2_name: string | null,
  is_valid?: boolean,
  is_set: boolean,
  fetching?: Promise<void>
}
export type EmployeelyData = {
  [employee: number]: Data
}
export type MonthlyData = {
  [bonus_info_id: number]: EmployeelyData
}

@Module({ dynamic: true, store, name: "bonus_each_monthly_data", namespaced: true })
class BonusEachMonthly extends VuexModule {
  data: MonthlyData = {};

  @Mutation
  public set({bonus_info_id, employee_id, data}: {bonus_info_id: number, employee_id: number, data}) {
    Vue.set(this.data[bonus_info_id], employee_id, {
      employee: data.employee,
      branch: data.branch,
      each_bonus_payment: data.each_bonus_payment,
      lock_type: data.lock_type,
      prev_employee_id: data.prev_employee_id,
      next_employee_id: data.next_employee_id,
      bank1_name: data.bank1_name,
      bank2_name: data.bank2_name,
      is_valid: true,
      is_set: true,
      fetching: null,
    });
  }


  @Mutation
  public disable({bonus_info_id, employee_id}: {bonus_info_id: number, employee_id?: number}) {
    if (employee_id === undefined) {
      if (this.data[bonus_info_id]) {
        Object.keys(this.data[bonus_info_id]).forEach(employee_id => {
          this.data[bonus_info_id][employee_id].is_valid = false;
        });
      }
    } else {
      if (this.data[bonus_info_id] && this.data[bonus_info_id][employee_id]) {
        this.data[bonus_info_id][employee_id].is_valid = false;
      }
    }
  }
  @Mutation
  public disable_all() {
    Object.keys(this.data).forEach(bonus_info_id => {
      Object.keys(this.data[bonus_info_id]).forEach(employee_id => {
        this.data[bonus_info_id][employee_id].is_valid = false;
      });
    });
  }
  @Mutation
  public setFetching({bonus_info_id, employee_id, value}: {bonus_info_id: number, employee_id: number, value: Promise<void>}) {
    this.data[bonus_info_id][employee_id].fetching = value;
  }


  // actions
  @Action({ rawError: true })
  public async fetch({bonus_info_id, branch_id, employee_id}: {branch_id: number, employee_id: number, bonus_info_id: number}) {
    //ないなら作る
    if (!this.data[bonus_info_id]) {
      Vue.set(this.data, bonus_info_id, {});
    }
    if (!this.data[bonus_info_id][employee_id]) {
      Vue.set(this.data[bonus_info_id], employee_id, {
        employee: {},
        branch: {},
        each_attendance: {},
        each_bonus_payment: {},
        lock_type: null,
        prev_employee_id: null,
        next_employee_id: null,
        bank1_name: null,
        bank2_name: null,
        is_valid: false,
        is_set: false,
        fetching: null,
      });
    }

    if (this.data[bonus_info_id][employee_id].fetching) {
      await this.data[bonus_info_id][employee_id].fetching;
    } else {
      const promise = util.post('bonus_each/read_monthly', {bonus_info_id, employee_id, branch_id}).then((res) => {
        this.set({bonus_info_id, employee_id, data: res.data});
      });
      this.setFetching({bonus_info_id, employee_id, value: promise});
      await promise;
    }
  }
}

export default getModule(BonusEachMonthly);