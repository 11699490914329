import Bonus_info from "@/typesold/bonus_info";
import * as util from "@/util";

//validationは、OKならTrue, それ以外なら警告文を配列に入れる。
export default function validationBonusInfo(bonus_info) {
  var error = [];

  error.push(bonus_info.close_started_on !== null || "計算期間開始日が正しく入力されていません");
  error.push(bonus_info.close_ended_on !== null || "計算期間終了日が正しく入力されていません");
  error.push(bonus_info.paid_on !== null || "支払日が正しく入力されていません");
  error.push(
    bonus_info.close_started_on === null ||
      bonus_info.close_ended_on === null ||
      util.date2unix(bonus_info.close_started_on) <= util.date2unix(bonus_info.close_ended_on) ||
      "計算期間開始日は計算期間終了日以前の日付を入力してください",
  );

  return error;
}
