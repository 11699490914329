//VUEX
//shift
import { default as shift_global } from "@/vuex/shift/global";
import { default as shift_daily } from "@/vuex/shift/daily";
import { default as shift_daily_employee } from "@/vuex/shift/daily_employee";
import { default as shift_monthly } from "@/vuex/shift/monthly";
import { default as shift_monthly_employee } from "@/vuex/shift/monthly_employee";
import { default as shift_monthly_summery } from "@/vuex/shift/monthly_summery";
import { default as shift_need_global } from "@/vuex/shift/need_global";
import { default as shift_shiftbundle_global } from "@/vuex/shift/shiftbundle_global";

//submit
import { default as submit_global } from "@/vuex/submit/global";
import { default as submit_monthly } from "@/vuex/submit/monthly";

//attendance
import { default as attendance_global } from "@/vuex/attendance/global";
import { default as attendance_monthly } from "@/vuex/attendance/monthly";

//attendance_each
import { default as attendance_each_global } from "@/vuex/attendance_each/global";
import { default as attendance_each_monthly } from "@/vuex/attendance_each/monthly";

//payment
import { default as payment_global } from "@/vuex/payment/global";
import { default as payment_monthly } from "@/vuex/payment/monthly";

//payment_each
import { default as payment_each_global } from "@/vuex/payment_each/global";
import { default as payment_each_monthly } from "@/vuex/payment_each/monthly";

//bonus
import { default as bonus_global } from "@/vuex/bonus/global";
import { default as bonus_monthly } from "@/vuex/bonus/monthly";

//bonus_each
import { default as bonus_each_global } from "@/vuex/bonus_each/global";
import { default as bonus_each_monthly } from "@/vuex/bonus_each/monthly";

//company
import { default as company_global } from "@/vuex/company/global";
import { default as company_branch } from "@/vuex/company/branch";

//employee
import { default as employee_global } from "@/vuex/employee/global";
import { default as employee_now } from "@/vuex/employee/now";
import { default as employee_order } from "@/vuex/employee/order";
import { default as employee_join } from "@/vuex/employee/join";
import { default as employee_retire } from "@/vuex/employee/retire";

//employee_each
import { default as employee_each_global } from "@/vuex/employee_each/global";
import { default as employee_each_employeely } from "@/vuex/employee_each/employeely";
import { default as employee_each_base } from "@/vuex/employee_each/base";
import { default as employee_each_payment } from "@/vuex/employee_each/payment";
import { default as employee_each_family } from "@/vuex/employee_each/family";
import { default as employee_each_holiday } from "@/vuex/employee_each/holiday";
import { default as employee_each_belong } from "@/vuex/employee_each/belong";
import { default as employee_each_info } from "@/vuex/employee_each/info";
import { default as employee_each_config } from "@/vuex/employee_each/config";

//employee_demand
import { default as employee_demand_global } from "@/vuex/employee_demand/global";
import { default as employee_demand_employeely } from "@/vuex/employee_demand/employeely";

//config
import { default as config_global } from "@/vuex/config/global";
import { default as config_base } from "@/vuex/config/base";
import { default as config_allowance } from "@/vuex/config/allowance";
import { default as config_info } from "@/vuex/config/info";
import { default as config_template } from "@/vuex/config/template";
import { default as config_template_each } from "@/vuex/config/template_each";
import { default as config_group } from "@/vuex/config/group";
import { default as config_branch_global } from "@/vuex/config/branch_global";
import { default as config_branch } from "@/vuex/config/branch";
import { default as config_paymentgroup_branchly } from "@/vuex/config/paymentgroup_branchly";
import { default as config_shiftgroup_branchly } from "@/vuex/config/shiftgroup_branchly";
import { default as config_branch_bank } from "@/vuex/config/branch_bank";
import { default as config_individualunit_global } from "@/vuex/config/individualunit/global";

//demand_holiday
import { default as demand_holiday_global } from "@/vuex/demand_holiday/global";

//demand_overtime
import { default as demand_overtime_global } from "@/vuex/demand_overtime/global";
import { default as demand_overtime_shiftly } from "@/vuex/demand_overtime/shiftly";

//demand_punch
import { default as demand_punch_monthly_demand } from "@/vuex/demand_punch/monthly_demand";

//demand_change
import { default as demand_change_global } from "@/vuex/demand_change/global";
import { default as demand_change_employeely } from "@/vuex/demand_change/employeely";

//punch
import { default as punch_global } from "@/vuex/punch/global";

//header
import { default as header } from "@/vuex/header";

//aggregation
import { default as aggregation_global } from "@/vuex/aggregation/global";

//document
import { default as document_global } from "@/vuex/document/global";
import { default as document_branch_monthly } from "@/vuex/document/branch_monthly";
import { default as document_branch_yearly } from "@/vuex/document/branch_yearly";
import { default as document_employee_monthly } from "@/vuex/document/employee_monthly";
import { default as document_employee_yearly } from "@/vuex/document/employee_yearly";
import { default as document_employee_retired } from "@/vuex/document/employee_retired";
import { default as document_history } from "@/vuex/document/history";
import { default as document_history_employeely } from "@/vuex/document/history_employeely";
import { default as document_contract_document } from "@/vuex/document/contract_document";
import { default as document_waiting } from "@/vuex/document/waiting";

//nencho
import { default as nencho_global } from "@/vuex/nencho/global";
import { default as nencho_employeely } from "@/vuex/nencho/employeely";
import { default as nencho_yearly } from "@/vuex/nencho/yearly";
import { default as nencho_employee_yearly } from "@/vuex/nencho/employee_yearly";
import { default as nencho_insurance_yearly } from "@/vuex/nencho/insurance_yearly";
import { default as nencho_prev_payment_yearly } from "@/vuex/nencho/prev_payment_yearly";
import { default as nencho_loan_yearly } from "@/vuex/nencho/loan_yearly";

//FixedTaxReduction
import { default as fixed_tax_reduction_global } from "@/vuex/fixed_tax_reduction/global";
import { default as fixed_tax_reduction_employeely } from "@/vuex/fixed_tax_reduction/employeely";
import { default as fixed_tax_reduction_yearly } from "@/vuex/fixed_tax_reduction/yearly";
import { default as fixed_tax_reduction_employee_yearly } from "@/vuex/fixed_tax_reduction/employee_yearly";
//wizard
import { default as wizard_global } from "@/vuex/wizard/global";

//order
import { default as order_global } from "@/vuex/order/global";

//laborins
import { default as laborins_global } from "@/vuex/laborins/global";
import { default as laborins_yearly } from "@/vuex/laborins/yearly";

//renew_stdincome
import { default as renew_stdincome_global } from "@/vuex/renew_stdincome/global";
import { default as renew_stdincome_yearly } from "@/vuex/renew_stdincome/yearly";

//update_stdincome
import { default as update_stdincome_global } from "@/vuex/update_stdincome/global";
import { default as update_stdincome_yearly } from "@/vuex/update_stdincome/yearly";
import { default as update_stdincome_candidate } from "@/vuex/update_stdincome/candidate";

//pdfviewer
import { default as pdfviewer } from "@/vuex/pdfviewer";

//notification
import { default as notification_global } from "@/vuex/notification/global";
import { default as notification_single } from "@/vuex/notification/single";
import { default as notification_single_admin } from "@/vuex/notification/single_admin";
import { default as notification_single_system } from "@/vuex/notification/single_system";

//almighty
import { default as almighty_employee_now } from "@/vuex/almighty/employee_now";
import { default as almighty_employee_sub_now } from "@/vuex/almighty/employee_sub_now";
import { default as almighty_branch_now } from "@/vuex/almighty/branch_now";

//passreset
import { default as passreset_global } from "@/vuex/passreset/global";

//employee_each
import { default as employee_each_individualunit_employeely } from "@/vuex/employee_each/individualunit/employeely";

//join
import { default as join_global } from "@/vuex/join/global";

const vuex_object = {
  // shift_global,
  // shift_daily,
  // shift_daily_employee,
  // shift_monthly,
  // shift_monthly_employee,
  // shift_monthly_summery,
  // shift_need_global,
  // shift_shiftbundle_global,

  // //submit
  // submit_global,
  // submit_monthly,

  // //attendance
  // attendance_global,
  // attendance_monthly,

  // //attendance_each
  // attendance_each_global,
  // attendance_each_monthly,

  // //payment
  // payment_global,
  // payment_monthly,

  // //payment_each
  // payment_each_global,
  // payment_each_monthly,

  // //bonus
  // bonus_global,
  // bonus_monthly,

  // //bonus_each
  // bonus_each_global,
  // bonus_each_monthly,

  // //company
  // company_global,

  // //employee
  // employee_global,
  // employee_now,
  // employee_order,
  // employee_join,
  // employee_retire,

  // //employee_each
  // employee_each_global,
  // employee_each_employeely,
  // employee_each_base,
  // employee_each_payment,
  // employee_each_family,
  // employee_each_holiday,
  // employee_each_belong,
  // employee_each_info,
  // employee_each_config,

  // //config
  // config_base,
  // config_allowance,
  // config_info,
  // config_template,
  // config_template_each,
  // config_group,
  // config_branch_global,
  // config_branch,
  // config_paymentgroup_branchly,
  // config_shiftgroup_branchly,
  // config_branch_bank,

  // //demand_holiday
  // demand_holiday_global,

  // //demand_overtime
  // demand_overtime_global,
  // demand_overtime_shiftly,

  // //demand_punch
  // demand_punch_monthly_demand,

  // //demand_change
  // demand_change_global,
  // demand_change_employeely,

  // //punch
  // punch_global,

  // //header
  // header,

  // //aggregation
  // aggregation_global,

  // //document
  // document_global,
  // document_branch_monthly,
  // document_branch_yearly,
  // document_employee_monthly,
  // document_employee_yearly,
  // document_employee_retired,
  // document_history,
  // document_history_employeely,
  // document_contract_document,
  // document_waiting,

  // //nencho
  // nencho_global,
  // nencho_yearly,
  // nencho_employee_yearly,
  // nencho_insurance_yearly,
  // nencho_prev_payment_yearly,
  // nencho_loan_yearly,

  // //wizard
  // wizard_global,

  // //order
  // order_global,

  //laborins
  laborins_global: new laborins_global(),
  laborins_yearly: new laborins_yearly(),

  //stdincome
  renew_stdincome_global: new renew_stdincome_global(),
  renew_stdincome_yearly: new renew_stdincome_yearly(),
  update_stdincome_global: new update_stdincome_global(),
  update_stdincome_yearly: new update_stdincome_yearly(),
  update_stdincome_candidate: new update_stdincome_candidate(),

  //pdfviewer
  pdfviewer: new pdfviewer(),

  //notification
  notification_global: new notification_global(),
  notification_single: new notification_single(),
  notification_single_admin: new notification_single_admin(),
  notification_single_system: new notification_single_system(),

  //almighty
  almighty_employee_now: new almighty_employee_now(),
  almighty_employee_sub_now: new almighty_employee_sub_now(),
  almighty_branch_now: new almighty_branch_now(),

  //passreset
  passreset_global: new passreset_global(),

  //config
  config_individualunit_global: new config_individualunit_global(),

  //employee_each
  employee_each_individualunit_employeely: new employee_each_individualunit_employeely(),
  employee_each_global: new employee_each_global(),
  employee_each_employeely: new employee_each_employeely(),
  employee_each_base: new employee_each_base(),
  employee_each_holiday: new employee_each_holiday(),
  employee_each_info: new employee_each_info(),
  employee_each_family: new employee_each_family(),
  employee_each_belong: new employee_each_belong(),
  employee_each_config: new employee_each_config(),

  employee_demand_global: new employee_demand_global(),
  employee_demand_employeely: new employee_demand_employeely(),

  //config
  config_global: new config_global(),
  config_base: new config_base(),
  config_allowance: new config_allowance(),
  config_group: new config_group(),
  config_branch: new config_branch(),
  config_info: new config_info(),

  //company
  company_global: new company_global(),
  company_branch: new company_branch(),

  //join
  join_global: new join_global(),

  //nencho
  nencho_global: new nencho_global(),
  nencho_employeely: new nencho_employeely(),

  //fixed_tax_reduction
  fixed_tax_reduction_global: new fixed_tax_reduction_global(),
  fixed_tax_reduction_employeely: new fixed_tax_reduction_employeely(),
};

export default vuex_object;
