import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import Vue from "vue";
import Payment_info from "@/typesold/Payment_info";

export type BranchData = {
  id: number;
  name: string;
};

export type BranchObject = {
  [id: number]: string;
};

export type Data = {
  branches: BranchData[];
  branch_hash: BranchObject;
  branch_hash_all: BranchObject;
  payment_info: Payment_info;
  add_paidhol_time: boolean;
  use_five_hundred: boolean;
  use_counter: boolean;
  is_valid?: boolean;
  is_set: boolean;
  fetching?: Promise<void>;
};

export type MonthlyData = {
  [yearmonth: string]: Data;
};

@Module({ dynamic: true, store, name: "attendance_each_global_data", namespaced: true })
class AttendanceEachGlobal extends VuexModule {
  data: MonthlyData = {};

  @Mutation
  public set({ yearmonth, data }: { yearmonth: string; data: Data }) {
    Vue.set(this.data, yearmonth, {
      ...data,
      branch_hash: util.createHashReal(data.branches, "id", "name"),
      branch_hash_all: util.createHash(data.branches, "id", "name"),
      is_valid: true,
      is_set: true,
      fetching: null,
    });
  }
  @Mutation
  public disable({ yearmonth }: { yearmonth: string }) {
    if (this.data[yearmonth]) {
      this.data[yearmonth].is_valid = false;
    }
  }
  @Mutation
  public disable_all() {
    Object.keys(this.data).forEach((yearmonth) => {
      this.data[yearmonth].is_valid = false;
    });
  }
  @Mutation
  public setFetching({ yearmonth, value }: { yearmonth: string; value: Promise<void> }) {
    this.data[yearmonth].fetching = value;
  }

  // actions
  @Action({ rawError: true })
  public async fetch({ yearmonth }: { yearmonth: string }) {
    //ないなら作る
    if (!this.data[yearmonth]) {
      Vue.set(this.data, yearmonth, {
        payment_info: {},
        branches: [],
        branch_hash: {},
        branch_hash_all: {},
        add_paidhol_time: false,
        use_five_hundred: false,
        use_counter: false,
        is_valid: false,
        is_set: false,
        fetching: null,
      });
    }

    if (this.data[yearmonth].fetching) {
      await this.data[yearmonth].fetching;
    } else {
      const promise = util.post("attendance_each/read_global", { yearmonth }).then((res) => {
        this.set({ yearmonth, data: res.data });
      });
      this.setFetching({ yearmonth, value: promise });
      await promise;
    }
  }
}

export default getModule(AttendanceEachGlobal);
