//field_arrayからtarget_arrayにある時間範囲を取り除いた結果を配列として返す
export default function timediff(fields, targets) {
  var result = [];
  fields.forEach(field => {
    result = result.concat(field_targets(field, targets));
  });

  result = result_marge(result);

  return result;
}

function field_targets(field, targets) {
  var result = [field];
  targets.forEach(target => {
    result = fields_target(result, target)
  });
  return result;
}

function fields_target(fields, target) {
  var result = [];
  fields.forEach(tmp => {
    if (
      tmp.started_at <= target.started_at && target.started_at <= tmp.ended_at || 
      tmp.started_at <= target.ended_at && target.ended_at <= tmp.ended_at || 
      target.started_at <= tmp.started_at && tmp.started_at <= target.ended_at || 
      target.started_at <= tmp.ended_at && tmp.ended_at <= target.ended_at
    ) {
      if (target.started_at <= tmp.started_at && tmp.ended_at <= target.ended_at) {// s n n s
        //result.push({started_at: tmp.started_at, ended_at: tmp.ended_at});
      } else if (target.started_at <= tmp.started_at && target.ended_at <= tmp.ended_at) {// s n s n
        //result.push({started_at: tmp.started_at, ended_at: target.ended_at});
        result.push({started_at: target.ended_at, ended_at: tmp.ended_at});
      } else if (tmp.started_at <= target.started_at && tmp.ended_at <= target.ended_at) {// n s n s
        result.push({started_at: tmp.started_at, ended_at: target.started_at});
        //result.push({started_at: target.started_at, ended_at: tmp.ended_at});
      } else {// n s s n
        result.push({started_at: tmp.started_at, ended_at: target.started_at});
        //result.push({started_at: target.started_at, ended_at: target.ended_at});
        result.push({started_at: target.ended_at, ended_at: tmp.ended_at});
      }
    } else {
      result.push(tmp);
    }
  });
  return result;
}

function result_marge(result) {
  for (var i = 0; ; i++) {
    if (result.length <= i + 1) {
      break;
    } else {
      if (result[i].ended_at == result[i + 1].started_at) {
        result[i].ended_at = result[i + 1].ended_at;
        result.splice(i + 1, 1);
      }
    }
  }
  return result;
}