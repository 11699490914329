import { Component, Mixins } from 'vue-property-decorator';
import utilMixins from '@/mixins'
import {makeMixin, makeUtilMixin} from '@/mixins/Descriptor';
import vuex_data from '@/vuex/vuex_data';
import vuex_manager from '@/vuex/vuex_manager';
import {makeTableMixin, dataDescript, validateDescript} from '@/mixins/Descriptor';
import * as util from '@/util';

const tableDescriptor = {
  manager: vuex_manager.notification_manager,
  data: {
    source: 'global',
    key: 'notifications',
    sort_key: [
      {key: 'title', keys: ['data', 'title']},
      {key: 'created_at', keys: ['data', 'created_at']},
      {key: 'datetime', keys: ['data', 'datetime']},
      {key: 'importance', keys: ['data', 'importance']},
      {key: 'employee_id', keys: ['data', 'employee_id']},
    ],
    default_sort_key: [
      {key: 'employee_id', keys: ['data', 'employee_id'], func: (self, data) => {return data == self.s_employee_id ? 1 : -1}, sort_mode: 'desc'},
      {key: 'datetime', keys: ['data', 'datetime'], sort_mode: 'desc'},
    ],
    search_targets: [
      {type: 'normal', keys: ['data', 'title']}, 
    ],
  },
}

const globalDescriptor = {
  name: 'global',
  vuex: vuex_data.notification_global,
  is_single: true,
  fetch(self) {
    return {};
  },
  dependencies: [],
};
const singleDescriptor = {
  name: 'single',
  vuex: vuex_data.notification_single,
  indexers: ['notification_id'],
  fetch(self) {
    return { notification_id: self.notification_id };
  },
};
const singleAdminDescriptor = {
  name: 'single_admin',
  vuex: vuex_data.notification_single_admin,
  indexers: ['admin_notification_id'],
  fetch(self) {
    return { admin_notification_id: self.admin_notification_id };
  },
};
const singleSystemDescriptor = {
  name: 'single_system',
  vuex: vuex_data.notification_single_system,
  indexers: ['system_notification_id'],
  fetch(self) {
    return { system_notification_id: self.system_notification_id };
  },
};

@Component
export default class mixinNotification extends Mixins(makeTableMixin(tableDescriptor), utilMixins) {
  get global() {
    return dataDescript(this, globalDescriptor);
  }
  get global_validate() {
    return validateDescript(this, globalDescriptor);
  }
  get single() {
    return dataDescript(this, singleDescriptor);
  }
  get single_validate() {
    return validateDescript(this, singleDescriptor);
  }
  get single_admin() {
    return dataDescript(this, singleAdminDescriptor);
  }
  get single_admin_validate() {
    return validateDescript(this, singleAdminDescriptor);
  }
  get single_system() {
    return dataDescript(this, singleSystemDescriptor);
  }
  get single_system_validate() {
    return validateDescript(this, singleSystemDescriptor);
  }

  get notification_id() {
    return this.m.tmp_objects['notification_id'];
  }
  get admin_notification_id() {
    return this.m.tmp_objects['admin_notification_id'];
  }
  get system_notification_id() {
    return this.m.tmp_objects['system_notification_id'];
  }
}
