import * as util from '@/util';
import * as vali from '@/validations/validationUtil';

export function validationRenewStdincome(self, args) {
  var error = [];

  error.push(args.renew_stdincome.month !== null || '算定基礎届期間が選択されていません')
  args.renew_stdincome_masters.forEach((renew_stdincome_master, i) => {
    error.push(renew_stdincome_master.comp_rate !== null || 'グループ'+(i + 1)+'の確定労災保険料率を');
    error.push(renew_stdincome_master.comp_rate === null || vali.is_number(renew_stdincome_master.comp_rate) || 'グループ'+(i + 1)+'の確定労災保険料率は半角数字で入力してください');
    error.push(renew_stdincome_master.emp_rate !== null || 'グループ'+(i + 1)+'の確定雇用保険料率を入力してください');
    error.push(renew_stdincome_master.emp_rate === null || vali.is_number(renew_stdincome_master.emp_rate) || 'グループ'+(i + 1)+'の確定雇用保険料率は半角数字で入力してください');
    error.push(renew_stdincome_master.estimate_comp_rate !== null || 'グループ'+(i + 1)+'の概算・増加概算労災保険料率を入力してください');
    error.push(renew_stdincome_master.estimate_comp_rate === null || vali.is_number(renew_stdincome_master.estimate_comp_rate) || 'グループ'+(i + 1)+'の概算・増加概算労災保険料率は半角数字で入力してください');
    error.push(renew_stdincome_master.estimate_emp_rate !== null || 'グループ'+(i + 1)+'の概算・増加概算雇用保険料率を入力してください');
    error.push(renew_stdincome_master.estimate_emp_rate === null || vali.is_number(renew_stdincome_master.estimate_emp_rate) || 'グループ'+(i + 1)+'の概算・増加概算雇用保険料率は半角数字で入力してください');
    error.push(renew_stdincome_master.estimated_money !== null || 'グループ'+(i + 1)+'の申告済み概算保険料を入力してください');
    error.push(renew_stdincome_master.estimated_money === null || vali.is_number(renew_stdincome_master.estimated_money) || 'グループ'+(i + 1)+'の申告済み概算保険料は半角数字で入力してください');
  });
  return error;
}

export function validationRenewStdincomeMasters(self, args) {
  var error = [];
  Object.keys(args.renew_stdincome_masters).forEach((id, i) => {
    error.push(args.renew_stdincome_masters.hoge !== null || 'グループ'+(i + 1)+'のホゲを入力してください');
  });
  return error;
}
