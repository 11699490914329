import VuexManagerControl from "@/vuex/VuexManagerControl";
import validationPaidHoliday from "@/validations/paid_holiday";
import { validationEmployeeMail, validationEmployeeBase, validationEmployeePayment, validationEmployeeCode, validationMail, validationPassword, validationCurrentPassword, validationAdministrator, validationEmployeeBasePass } from "@/validations/employee";
import { validationEachEmployeeInfos } from "@/validations/employee_info";
import { validationDependant, validationSpouce } from "@/validations/family";

export default class Manager extends VuexManagerControl {
  create_descriptor = {
    add: {
      url: 'employee/create_employee_manual',
      validate: [validationEmployeeCode, validationEmployeeMail, validationEmployeeBasePass, validationEmployeePayment],
      dialog: {msg: '社員の登録を実行してよろしいですか？', detail: '一度登録すると、削除することはできません。', cancel_button: '登録しない', exec_button: '登録する'},
      processing_dialog: {},
      finish_dialog: {msg: '社員の登録が完了しました。'},
      routing: {
        name: 'employee',
        plus_args: {tab: 'now'},
      },
    },
  };

  update_descriptor = {
    
  }
  delete_descriptor = {

  }
}