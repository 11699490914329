import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import monthly from "@/vuex/bonus/monthly";
import global from "@/vuex/bonus/global";
import * as util from "@/util";
import validate from "@/validations/validate";
import { validationEachBonusPayments } from "@/validations/bonus";
import validationBonusInfo from "@/validations/bonus_info";
import dialog from "@/vuex/dialog";
import Vue from "vue";
import router from "@/router";
import Bonus_info from "@/typesold/Bonus_info";
import node from "@/vuex/node";

@Module({ dynamic: true, store, name: "bonus_manager", namespaced: true })
class Manager extends VuexModule {
  disp_num: number = 10;
  edit_each_bonus_payments_flag: boolean = false;
  is_open_add_bonus_info_dialog: boolean = false;
  is_open_edit_bonus_info_dialog: boolean = false;
  is_open_bonus_excel_dialog: boolean = false;

  bonus_info = {};

  each_bonus_payment = {}; //employee_id => each_bonus_payment_object

  @Mutation
  private setEditEachBonusPaymentsFlag(val: boolean) {
    if (!val) {
      Vue.set(this, "each_bonus_payment", {});
    }
    this.edit_each_bonus_payments_flag = val;
  }

  @Mutation
  public setIsOpenAddBonusInfoDialog(val) {
    this.is_open_add_bonus_info_dialog = val;
  }
  @Mutation
  public setIsOpenEditBonusInfoDialog(val) {
    this.is_open_edit_bonus_info_dialog = val;
  }
  @Mutation
  public setIsOpenBonusExcelDialog(val: boolean) {
    this.is_open_bonus_excel_dialog = val;
  }

  @Mutation
  setBonusInfo({ bonus_info }) {
    Vue.set(this, "bonus_info", bonus_info);
  }

  @Mutation
  public setDispNum(val) {
    this.disp_num = val;
  }

  @Action({ rawError: true })
  public cancelAll() {
    this.setEditEachBonusPaymentsFlag(false);
  }

  @Action({ rawError: true })
  public async is_open_input() {
    return this.edit_each_bonus_payments_flag;
  }

  @Action({ rawError: true })
  public async editEachBonusPayments() {
    if (await this.is_open_input()) return;
    this.setEditEachBonusPaymentsFlag(true);
  }

  @Action({ rawError: true })
  public updateEachBonusPayments({
    bonus_info_id,
    branch_id,
    employee_hash,
    saved_bonus_allowances,
    saved_bonus_deductions,
    saved_bonus_other_allowances,
  }: {
    bonus_info_id: number;
    branch_id: number;
    employee_hash: any;
    saved_bonus_allowances: any;
    saved_bonus_deductions: any;
    saved_bonus_other_allowances: any;
  }) {
    if (
      validate(
        validationEachBonusPayments(
          this.each_bonus_payment,
          employee_hash,
          saved_bonus_allowances,
          saved_bonus_deductions,
          saved_bonus_other_allowances,
        ),
      )
    ) {
      dialog.openConfirmDialog({
        msg: "賞与を更新してよろしいですか？",
        cancel_button: "更新しない",
        exec_button: "更新する",
        func: this.updateEachBonusPaymentsProcess,
        is_next_dialog: true,
        args: { bonus_info_id, branch_id },
      });
    }
  }
  @Action({ rawError: true })
  public async updateEachBonusPaymentsProcess({
    bonus_info_id,
    branch_id,
  }: {
    bonus_info_id: number;
    branch_id: number;
  }) {
    dialog.openProgressDialog({ title: "更新中", msg: "更新には時間がかかることがあります。" });
    await util.post_proxy("bonus/update_each_bonus_payments", {
      bonus_info_id,
      branch_id,
      object: this.each_bonus_payment,
    });
    this.cancelAll();
    node.disable();
    dialog.openAlertDialog({ msg: "更新が完了しました" });
  }

  @Action({ rawError: true })
  public createBonusInfo() {
    if (validate(validationBonusInfo(this.bonus_info))) {
      dialog.openConfirmDialog({
        msg: "賞与を作成してよろしいですか？",
        cancel_button: "作成しない",
        exec_button: "作成する",
        func: this.createBonusInfoProcess,
        args: {},
        is_next_dialog: true,
      });
    }
  }
  @Action({ rawError: true })
  public async createBonusInfoProcess({}) {
    dialog.openProgressDialog({ title: "作成中", msg: "この処理には時間がかかることがあります。" });
    await util.post_proxy("bonus/create_bonus_info", { object: this.bonus_info });
    this.setIsOpenAddBonusInfoDialog(false);
    router.push({ name: "bonus", query: {} });
    node.disable();
    dialog.openAlertDialog({ msg: "賞与を作成しました" });
  }
  @Action({ rawError: true })
  public updateBonusInfo({ bonus_info_id }: { bonus_info_id: number }) {
    if (validate(validationBonusInfo(this.bonus_info))) {
      dialog.openConfirmDialog({
        msg: "賞与を編集してよろしいですか？",
        cancel_button: "編集しない",
        exec_button: "編集する",
        func: this.updateBonusInfoProcess,
        args: { bonus_info_id },
        is_next_dialog: true,
      });
    }
  }
  @Action({ rawError: true })
  public async updateBonusInfoProcess({ bonus_info_id }: { bonus_info_id: number }) {
    dialog.openProgressDialog({ title: "編集中", msg: "この処理には時間がかかることがあります。" });
    await util.post_proxy("bonus/update_bonus_info", { bonus_info_id, object: this.bonus_info });
    this.setIsOpenEditBonusInfoDialog(false);
    node.disable();
    dialog.openAlertDialog({ msg: "賞与を編集しました" });
  }
  @Action({ rawError: true })
  public deleteBonusInfo({ bonus_info_id }: { bonus_info_id: number }) {
    dialog.openConfirmDialog({
      msg: "賞与を削除してよろしいですか？",
      cancel_button: "削除しない",
      exec_button: "削除する",
      func: this.deleteBonusInfoProcess,
      args: { bonus_info_id },
      is_next_dialog: true,
    });
  }
  @Action({ rawError: true })
  public async deleteBonusInfoProcess({ bonus_info_id }: { bonus_info_id: number }) {
    dialog.openProgressDialog({ title: "削除中", msg: "この処理には時間がかかることがあります。" });
    await util.post_proxy("bonus/delete_bonus_info", { bonus_info_id });
    node.disable();
    global.disable({ bonus_info_id: 0 });
    dialog.openAlertDialog({ msg: "賞与を削除しました" });
  }

  @Mutation
  setEachBonus({
    employee_id,
    each_bonus_payment_object,
  }: {
    employee_id: number;
    each_bonus_payment_object: any;
  }) {
    Vue.set(this.each_bonus_payment, employee_id, each_bonus_payment_object);
  }

  @Action({ rawError: true })
  public lock({
    bonus_info_id,
    yearmonth,
    branch_id,
    branch_name,
    is_nencho,
  }: {
    bonus_info_id: number;
    yearmonth: string;
    branch_id: number;
    branch_name: string;
    is_nencho: boolean;
  }) {
    console.log(yearmonth, is_nencho);
    if (util.yearmonth2month(yearmonth) != 12 || is_nencho) {
      dialog.openConfirmDialog({
        msg: branch_name + "の賞与ロックをしてよろしいですか？",
        detail: "賞与が確定され、以後変更はできなくなります。",
        cancel_button: "ロックしない",
        exec_button: "ロックする",
        func: this.lockProcess,
        args: { bonus_info_id, branch_id },
        is_next_dialog: true,
      });
    } else {
      dialog.openConfirmDialog({
        msg: branch_name + "の賞与ロックをしてよろしいですか？",
        detail:
          "賞与が確定され、以後変更はできなくなります。<br>年末調整が開始されていません。<br>年末調整を開始せずに12月給与・12月賞与をロックすると、年末調整未済の源泉徴収票が全社員出力可能になります。",
        cancel_button: "ロックしない",
        exec_button: "ロックする",
        func: this.lockProcess,
        args: { bonus_info_id, branch_id },
        is_next_dialog: true,
      });
    }
  }
  @Action({ rawError: true })
  public async lockProcess({
    bonus_info_id,
    branch_id,
  }: {
    bonus_info_id: number;
    branch_id: number;
  }) {
    dialog.openProgressDialog({
      title: "ロック中",
      msg: "この処理には時間がかかることがあります。",
    });
    await util.post_proxy("bonus/lock", { bonus_info_id, branch_id });
    node.disable();
    dialog.openAlertDialog({ msg: "賞与ロックが完了しました" });
  }

  @Action({ rawError: true })
  public recalc({ bonus_info_id }: { bonus_info_id: number }) {
    dialog.openConfirmDialog({
      msg: "データ再計算をしてよろしいですか？",
      detail:
        "再計算すると正しく計算されない可能性があります。<br>再計算では、賞与開始後に変更した会社・店舗・社員情報が反映されます。",
      cancel_button: "再計算しない",
      exec_button: "再計算する",
      func: this.recalcProcess,
      args: { bonus_info_id },
      is_next_dialog: true,
    });
  }
  @Action({ rawError: true })
  public async recalcProcess({ bonus_info_id }: { bonus_info_id: number }) {
    dialog.openProgressDialog({
      title: "再計算中",
      msg: "この処理には時間がかかることがあります。",
    });
    await util.post_proxy("bonus/recalc_bonus_info", { bonus_info_id });
    node.disable();
    dialog.openAlertDialog({ msg: "再計算が完了しました" });
  }

  @Action({ rawError: true })
  public unlock({
    bonus_info_id,
    branch_id,
    branch_name,
  }: {
    bonus_info_id: number;
    branch_id: number;
    branch_name: string;
  }) {
    dialog.openConfirmDialog({
      msg: branch_name + "の賞与ロックを解除してよろしいですか？",
      detail: "ロックを解除すると正しく計算されない可能性があります。",
      cancel_button: "ロック解除しない",
      exec_button: "ロック解除する",
      func: this.unlockProcess,
      args: { bonus_info_id, branch_id },
      is_next_dialog: true,
    });
  }
  @Action({ rawError: true })
  public async unlockProcess({
    bonus_info_id,
    branch_id,
  }: {
    bonus_info_id: number;
    branch_id: number;
  }) {
    dialog.openProgressDialog({
      title: "ロック解除中",
      msg: "この処理には時間がかかることがあります。",
    });
    await util.post_proxy("bonus/unlock", { bonus_info_id, branch_id });
    node.disable();
    dialog.openAlertDialog({ msg: "賞与ロック解除が完了しました" });
  }

  @Action({ rawError: true })
  public async zengin({
    branch_id,
    bonus_info_id,
    yearmonth,
  }: {
    branch_id: number;
    bonus_info_id: number;
    yearmonth: string;
  }) {
    dialog.openProgressDialog({ title: "作成中", msg: "作成には時間がかかることがあります。" });
    await util.post_document(
      "document/generate_bonus_zengin",
      { bonus_info_id, branch_id },
      util.formatDate(util.date2obj(yearmonth + "-01"), "yyyy年mm月") +
        "支給分賞与全銀テキスト.zip",
      "振込元口座が登録されていないか、支給額が存在しない可能性があります",
    );
    await util.post_document(
      "document/generate_bonus_cash",
      { bonus_info_id, branch_id },
      util.formatDate(util.date2obj(yearmonth + "-01"), "yyyy年mm月") + "支給分賞与現金支給分.csv",
      "",
      false,
      false,
    );
  }

  @Action({ rawError: true })
  public async bonus_csv({
    branch_id,
    bonus_info_id,
    yearmonth,
  }: {
    branch_id: number;
    bonus_info_id: number;
    yearmonth: string;
  }) {
    dialog.openProgressDialog({ title: "作成中", msg: "作成には時間がかかることがあります。" });
    await util.post_document(
      "document/generate_bonus_csv",
      { bonus_info_id, branch_id },
      util.formatDate(util.date2obj(yearmonth + "-01"), "yyyy年mm月") + "支給分賞与データ.csv",
    );
  }

  @Action({ rawError: true })
  public async gensen_pay({ yearmonth }) {
    dialog.openProgressDialog({
      title: "作成中",
      msg: "作成に時間がかかることがあります。",
      detail: "そのままお待ちください。",
    });
    await util.post_document(
      "document/generate_gensenpay",
      { yearmonth },
      util.yearmonth2str(yearmonth) + "分源泉税徴収高計算書.pdf",
    );
  }

  @Action({ rawError: true })
  public async bonus_excel({ paid_on, bonus_info_id }: { paid_on: string; bonus_info_id: number }) {
    dialog.openProgressDialog({ title: "作成中", msg: "作成には時間がかかることがあります。" });
    await util.post_document(
      "document/generate_bonus_excel",
      { bonus_info_id },
      util.formatDate(util.date2obj(paid_on), "yyyy年mm月dd日") + "支給分賞与インポート用.xlsx",
    );
  }

  //Excel
  @Action({ rawError: true })
  public async updateEachBonusPaymentsExcel({
    excel,
    branch_id,
    bonus_info_id,
  }: {
    excel;
    branch_id: number;
    bonus_info_id: number;
  }) {
    if (!excel) {
      validate(["Excelファイルを選択してください"]);
    } else {
      dialog.openForceConfirmDialog({
        msg: "給与Excel更新を実行してよろしいですか？",
        cancel_button: "登録しない",
        exec_button: "登録する",
        func: this.updateEachBonusPaymentsExcelProcess,
        is_next_dialog: true,
        args: { excel, branch_id, bonus_info_id },
      });
    }
  }
  @Action({ rawError: true })
  public async updateEachBonusPaymentsExcelProcess({
    excel,
    branch_id,
    bonus_info_id,
  }: {
    excel;
    branch_id: number;
    bonus_info_id: number;
  }) {
    dialog.openProgressDialog({
      title: "登録中",
      msg: "この処理には時間がかかることがあります。",
      detail: "そのままお待ちください。",
    });
    const res = await util.post_file_proxy("bonus/update_each_bonus_payments_excel", {
      excel,
      branch_id,
      bonus_info_id,
    });
    if (res.data.error_message) {
      dialog.openErrorAlertDialog({ msg: res.data.error_message });
    } else {
      this.setIsOpenBonusExcelDialog(false);
      node.disable();
      dialog.openAlertDialog({ msg: "更新が完了しました" });
    }
  }
}

export default getModule(Manager);
