import { ref, VNode, h as globalh } from "vue";
import { Modal, Spin } from "ant-design-vue";
import i18n from "@/plugins/i18n";

export type VNodeMaker = (h: typeof globalh) => VNode | string | (VNode | string)[];
function unwrapMaker(
  h: typeof globalh,
  val: string | VNodeMaker | undefined,
): string | VNode | (string | VNode)[] {
  if (typeof val === "function") {
    return val(h);
  } else if (!val) {
    return val;
  }
  const nodes = [] as (string | VNode)[];
  for (const s of val.replace(/<br\/?>/g, "\n").split("\n")) {
    nodes.push(s);
    nodes.push(h("br"));
  }
  nodes.pop();
  return nodes;
}

class Dialog {
  modal: any = ref(false);
  loading: any = ref([]);

  addLoading(key) {
    this.loading.value.push(key);
  }
  deleteLoading(key) {
    this.loading.value.splice(this.loading.value.indexOf(key), 1);
  }

  openProgressDialog({
    title,
    msg,
    detail,
    max_width,
  }: {
    title?: string;
    msg: string | VNodeMaker;
    detail?: string;
    max_width?: string;
  }) {
    Modal.destroyAll();
    this.modal.value = Modal.info({
      title: "",
      content: (h) => {
        return h("div", { style: { "text-align": "center" } }, [
          h(Spin, { size: "large" }),
          h(
            "p",
            {
              style: {
                "line-height": "35px",
                "font-size": "18px",
                "text-align": "center",
                "font-weight": "500",
                padding: "10px 10px 0",
              },
            },
            unwrapMaker(h, msg),
          ),
          detail
            ? h(
                "p",
                { style: { "line-height": "25px", "font-size": "14px", "text-align": "center" } },
                detail,
              )
            : null,
        ]);
      },
      icon: (h) => h("span", ""),
      width: max_width ? max_width.replace("px", "") : 750,
      class: "progress",
    });
  }

  openErrorDialog({
    title,
    error,
    max_width,
  }: {
    title?: string;
    error: string[];
    max_width?: string;
  }) {
    Modal.destroyAll();
    this.modal.value = Modal.error({
      title: (h) =>
        h(
          "span",
          {
            style: {
              display: "inline-block",
              width: "100%",
              color: "#fff",
              "line-height": "40px",
              "font-size": "20px",
              "margin-bottom": "10px",
              "text-align": "center",
              "font-weight": "600",
              "margin-top": "5px",
              "background-color": "#d10c1d",
            },
          },
          title ?? "エラー",
        ),
      icon: (h) => h("span", ""),
      content: (h) => {
        return h(
          "div",
          error.map((e) =>
            h(
              "p",
              {
                style: {
                  "line-height": "35px",
                  "font-size": "18px",
                  "text-align": "center",
                  "font-weight": "500",
                  padding: "10px 10px 0",
                },
              },
              e,
            ),
          ),
        );
      },
      width: max_width ? max_width.replace("px", "") : 750,
    });
  }

  openErrorAlertDialog({
    title,
    msg,
    detail,
    max_width,
  }: {
    title?: string;
    msg: string | VNodeMaker;
    detail?: string | VNodeMaker;
    max_width?: string;
  }) {
    Modal.destroyAll();
    this.modal.value = Modal.error({
      title: (h) =>
        h(
          "span",
          {
            style: {
              display: "inline-block",
              width: "100%",
              color: "#fff",
              "line-height": "40px",
              "font-size": "20px",
              "margin-bottom": "10px",
              "text-align": "center",
              "font-weight": "600",
              "margin-top": "5px",
              "background-color": "#d10c1d",
            },
          },
          title ?? i18n.t("error_message"),
        ),
      icon: (h) => h("span", ""),
      content: (h) => {
        return h("div", [
          h(
            "p",
            {
              style: {
                "line-height": "35px",
                "font-size": "18px",
                "text-align": "center",
                "font-weight": "500",
                padding: "10px 10px 0",
              },
            },
            unwrapMaker(h, msg),
          ),
          detail
            ? h(
                "p",
                {
                  style: { "line-height": "25px", "font-size": "14px", "text-align": "center" },
                },
                unwrapMaker(h, detail),
              )
            : null,
        ]);
      },
      width: max_width ? max_width.replace("px", "") : 750,
    });
  }

  openAlertDialog({
    title,
    msg,
    detail,
    max_width,
  }: {
    title?: string;
    msg: string;
    detail?: string;
    max_width?: string;
  }) {
    Modal.destroyAll();
    this.modal.value = Modal.info({
      title: (h) =>
        h(
          "span",
          {
            style: {
              display: "inline-block",
              width: "100%",
              color: "#fff",
              "line-height": "40px",
              "font-size": "20px",
              "margin-bottom": "10px",
              "text-align": "center",
              "font-weight": "600",
              "margin-top": "5px",
              "background-color": "#9b9a9b",
            },
          },
          title ?? i18n.t("done"),
        ),
      icon: (h) => h("span", ""),
      content: (h) => {
        return h("div", [
          h(
            "p",
            {
              style: {
                "line-height": "35px",
                "font-size": "18px",
                "text-align": "center",
                "font-weight": "500",
                padding: "10px 10px 0",
              },
            },
            msg,
          ),
          detail
            ? h(
                "p",
                { style: { "line-height": "25px", "font-size": "14px", "text-align": "center" } },
                unwrapMaker(h, detail),
              )
            : null,
        ]);
      },
      width: max_width ? max_width.replace("px", "") : 750,
    });
  }

  openConfirmDialog({
    title,
    msg,
    detail,
    func,
    args,
    cancel_button,
    exec_button,
    max_width,
    is_next_dialog,
  }: {
    title?: string;
    msg: string;
    detail?: string;
    func: any;
    args?: any;
    cancel_button?: string;
    exec_button?: string;
    is_next_dialog?: boolean;
    max_width?: string;
  }) {
    Modal.destroyAll();
    this.modal.value = Modal.confirm({
      title: (h) =>
        h(
          "span",
          {
            style: {
              display: "inline-block",
              width: "100%",
              color: "#fff",
              "line-height": "40px",
              "font-size": "20px",
              "margin-bottom": "10px",
              "text-align": "center",
              "font-weight": "600",
              "margin-top": "5px",
              "background-color": "#9b9a9b",
            },
          },
          title ?? i18n.t("confirm"),
        ),
      content: (h) => {
        return h("div", [
          h(
            "p",
            msg.split("<br>").map((m) =>
              h(
                "p",
                {
                  class: "msg",
                  style: {
                    "line-height": "35px",
                    "font-size": "18px",
                    "text-align": "center",
                    "font-weight": "500",
                    padding: "10px 10px 0",
                  },
                },
                m,
              ),
            ),
          ),
          detail
            ? h(
                "p",
                detail.split("<br>").map((m) =>
                  h(
                    "p",
                    {
                      class: "detail",
                      style: {
                        "line-height": "20px",
                        "font-size": "14px",
                        "text-align": "center",
                        "font-weight": "500",
                        padding: "10px 10px 0",
                      },
                    },
                    m,
                  ),
                ),
              )
            : null,
        ]);
      },
      icon: (h) => h("span", ""),
      width: max_width ? max_width.replace("px", "") : 750,
      cancelText: cancel_button ?? "キャンセル",
      okText: exec_button ?? "実行",
      onOk() {
        func(args ?? {});
      },
    });
  }

  openForceConfirmDialog({
    title,
    msg,
    detail,
    func,
    args,
    cancel_button,
    exec_button,
    max_width,
    is_next_dialog,
  }: {
    title?: string;
    msg: string;
    detail?: string;
    func: any;
    args: any;
    cancel_button?: string;
    exec_button?: string;
    max_width?: string;
    is_next_dialog?: boolean;
  }) {
    Modal.destroyAll();
    this.modal.value = Modal.confirm({
      title: (h) =>
        h(
          "span",
          {
            style: {
              display: "inline-block",
              width: "100%",
              color: "#fff",
              "line-height": "40px",
              "font-size": "20px",
              "margin-bottom": "10px",
              "text-align": "center",
              "font-weight": "600",
              "margin-top": "5px",
              "background-color": "#d10c1d",
            },
          },
          title ?? i18n.t("confirm"),
        ),
      content: (h) => {
        return h("div", [
          h(
            "p",
            msg.split("<br>").map((m) =>
              h(
                "p",
                {
                  style: {
                    "line-height": "35px",
                    "font-size": "18px",
                    "text-align": "center",
                    "font-weight": "500",
                    padding: "10px 10px 0",
                  },
                },
                m,
              ),
            ),
          ),
          detail
            ? h(
                "p",
                detail.split("<br>").map((m) =>
                  h(
                    "p",
                    {
                      style: {
                        "line-height": "20px",
                        "font-size": "14px",
                        "text-align": "center",
                        "font-weight": "500",
                        padding: "10px 10px 0",
                      },
                    },
                    m,
                  ),
                ),
              )
            : null,
        ]);
      },
      icon: (h) => h("span", ""),
      width: max_width ? max_width.replace("px", "") : 750,
      cancelText: cancel_button ?? i18n.t("cancel"),
      okText: exec_button ?? i18n.t("execute"),
      onOk() {
        func(args);
      },
    });
  }

  openErrorForceConfirmDialog({
    title,
    msg,
    error,
    detail,
    func,
    args,
    cancel_button,
    exec_button,
    max_width,
    is_next_dialog,
  }: {
    title?: string;
    msg: string;
    error: string[];
    detail?: string;
    func: any;
    args: any;
    cancel_button?: string;
    exec_button?: string;
    max_width?: string;
    is_next_dialog?: boolean;
  }) {
    Modal.destroyAll();
    this.modal.value = Modal.error({
      title: (h) =>
        h(
          "span",
          {
            style: {
              display: "inline-block",
              width: "100%",
              color: "#fff",
              "line-height": "40px",
              "font-size": "20px",
              "margin-bottom": "10px",
              "text-align": "center",
              "font-weight": "600",
              "margin-top": "5px",
              "background-color": "#d10c1d",
            },
          },
          title ?? i18n.t("executable_error"),
        ),
      icon: (h) => h("span", ""),
      content: (h) => {
        return h("div", [
          h(
            "p",
            {
              style: {
                "line-height": "35px",
                "font-size": "18px",
                "text-align": "center",
                "font-weight": "500",
                padding: "10px 10px 0",
              },
            },
            msg,
          ),
          error.map((e) =>
            h(
              "p",
              {
                style: {
                  "line-height": "35px",
                  "font-size": "18px",
                  "text-align": "center",
                  "font-weight": "500",
                  padding: "10px 10px 0",
                },
              },
              e,
            ),
          ),
          detail
            ? h(
                "p",
                detail.split("<br>").map((m) =>
                  h(
                    "p",
                    {
                      style: {
                        "line-height": "20px",
                        "font-size": "14px",
                        "text-align": "center",
                        "font-weight": "500",
                        padding: "10px 10px 0",
                      },
                    },
                    m,
                  ),
                ),
              )
            : null,
        ]);
      },
      cancelText: cancel_button ?? i18n.t("cancel"),
      okText: exec_button ?? i18n.t("execute"),
      onOk() {
        func(args);
      },
      width: max_width ? max_width.replace("px", "") : 750,
    });
  }

  openOptionDialog({
    title,
    msg,
    detail,
    func,
    cancel_func,
    args,
    cancel_args,
    cancel_button,
    exec_button,
    max_width,
    is_next_dialog,
  }: {
    title?: string;
    msg: string;
    detail?: string;
    func: any;
    cancel_func: any;
    args: any;
    cancel_args: any;
    cancel_button?: string;
    exec_button?: string;
    max_width?: string;
    is_next_dialog?: boolean;
  }) {
    Modal.destroyAll();
    this.modal.value = Modal.confirm({
      title: (h) =>
        h(
          "span",
          {
            style: {
              display: "inline-block",
              width: "100%",
              color: "#fff",
              "line-height": "40px",
              "font-size": "20px",
              "margin-bottom": "10px",
              "text-align": "center",
              "font-weight": "600",
              "margin-top": "5px",
              "background-color": "#9b9a9b",
            },
          },
          title ?? i18n.t("confirm"),
        ),
      content: (h) => {
        return h("div", [
          h(
            "p",
            msg.split("<br>").map((m) =>
              h(
                "p",
                {
                  style: {
                    "line-height": "35px",
                    "font-size": "18px",
                    "text-align": "center",
                    "font-weight": "500",
                    padding: "10px 10px 0",
                  },
                },
                m,
              ),
            ),
          ),
          detail
            ? h(
                "p",
                detail.split("<br>").map((m) =>
                  h(
                    "p",
                    {
                      style: {
                        "line-height": "20px",
                        "font-size": "14px",
                        "text-align": "center",
                        "font-weight": "500",
                        padding: "10px 10px 0",
                      },
                    },
                    m,
                  ),
                ),
              )
            : null,
        ]);
      },
      icon: (h) => h("span", ""),
      width: max_width ? max_width.replace("px", "") : 750,
      cancelText: cancel_button ?? "キャンセル",
      okText: exec_button ?? "実行",
      onOk() {
        func(args);
      },
      onCancel() {
        cancel_func(cancel_args);
      },
    });
  }

  closeDialog() {
    Modal.destroyAll();
  }
}

const dialog = new Dialog();
export default dialog;
